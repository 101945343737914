import gql from 'graphql-tag';


/**
 * Notifications
 */
export const MUTATION_ACKNOWLEDGE_NOTIFICATION = gql`
  mutation AcknowledgeNotification($id: ID!) {
    acknowledgeNotification(id: $id)
  }
`;
