import React, { Component, } from 'react';
import {
  func, arrayOf, shape, string, object, bool,
} from 'prop-types';
import { Query, } from 'react-apollo';

import { QUERY_ROLES, } from '../../gql/queries';
import { SUBSCRIPTION_ROLES, } from '../../gql/subscriptions';
import Table from '../../../../atoms/Table/Table';
import ButtonIcon from '../../../../atoms/Button/ButtonIcon';
import ButtonGrp from '../../../../atoms/Button/ButtonGrp';
import Tooltip from '../../../../atoms/Tooltip/Tooltip';
import TableLoading from '../../../../atoms/Table/TableLoading';
import TableError from '../../../../atoms/Table/TableError';
import TableNoData from '../../../../atoms/Table/TableNoData';

import Bin from '../../../../styles/icons/Bin';
import Search from '../../../../styles/icons/Search';


const COL_COUNT = 2;


const Header = ({ translations, }) => (
  <thead>
    <tr>
      <th>
        {translations.settings.thName}
      </th>
      <th className="table--text-align-right">
        {translations.settings.thActions}
      </th>
    </tr>
  </thead>
);


Header.propTypes = {
  translations: object.isRequired,
};


class Rows extends Component {
  componentDidMount() {
    const { subscribeToRoles, } = this.props;

    subscribeToRoles();
  }


  render() {
    const {
      // data
      loading,
      error,
      data,
      resources,
      translations,
      // methods
      onDetail,
      onDelete,
    } = this.props;

    if (loading) {
      return (
        <TableLoading
          colsCount={COL_COUNT}
          rowsCount={data && data.fetchRoles && data.fetchRoles.length}
        />
      );
    }
    if (error || !data.fetchRoles) {
      return (
        <TableError
          colsCount={COL_COUNT}
          error={error}
        />
      );
    }
    if (data.fetchRoles.length < 1) {
      return (
        <TableNoData
          colsCount={COL_COUNT}
          text={translations.settings.rolesEmpty}
        />
      );
    }

    return (
      <tbody>
        {data.fetchRoles.map((row) => (
          <tr key={row.id}>
            <td>
              {row.name}
            </td>
            <td
              className="table--text-align-right table--noPadding"
              style={{ minWidth: '5rem', }}
            >
              <ButtonGrp>
                <Tooltip text={translations.common.detail}>
                  {(events) => (
                    <ButtonIcon
                      size="sm"
                      color="tertiary"
                      onClick={() => onDetail(row.id)}
                      {...events}
                    >
                      <Search />
                    </ButtonIcon>
                  )}
                </Tooltip>
                {row.name.toLowerCase() !== 'admin' && resources.settings_users && (
                  <Tooltip text={translations.common.delete}>
                    {(events) => (
                      <ButtonIcon
                        size="sm"
                        color="error"
                        onClick={() => onDelete(row.id)}
                        {...events}
                      >
                        <Bin />
                      </ButtonIcon>
                    )}
                  </Tooltip>
                )}
              </ButtonGrp>
            </td>
          </tr>
        ))}
      </tbody>
    );
  }
}


Rows.propTypes = {
  loading: bool.isRequired,
  error: object,
  data: shape({
    fetchRoles: arrayOf(shape({
      id: string.isRequired,
      name: string.isRequired,
    })),
  }),
  resources: object.isRequired,
  translations: object.isRequired,
  subscribeToRoles: func.isRequired,
  onDetail: func.isRequired,
  onDelete: func.isRequired,
};

Rows.defaultProps = {
  error: undefined,
  data: undefined,
};


const RolesTable = ({
  // data
  resources,
  translations,
  // methods
  onDetail,
  onDelete,
}) => (
  <Query query={QUERY_ROLES}>
    {({ subscribeToMore, ...tableData }) => (
      <Table fillContent>
        <Header
          translations={translations}
        />
        <Rows
          // data
          {...tableData}
          resources={resources}
          translations={translations}
          // methods
          onDetail={onDetail}
          onDelete={onDelete}
          subscribeToRoles={() => subscribeToMore({
            document: SUBSCRIPTION_ROLES,
            updateQuery: (prev, { subscriptionData, }) => {
              if (!subscriptionData.data) return null;
              const newTransportation = subscriptionData.data.roles;
              return {
                fetchRoles: newTransportation,
              };
            },
          })}
        />
      </Table>
    )}
  </Query>
);


RolesTable.propTypes = {
  resources: object.isRequired,
  translations: object.isRequired,
  onDetail: func.isRequired,
  onDelete: func.isRequired,
};


export default RolesTable;
