import React from 'react';
import {
  shape, string, func, object,
} from 'prop-types';

import FormRow from '../../../../components/Form/FormRow';
import FormRowReadOnly from '../../../../components/Form/FormRowReadOnly';
import TextArea from '../../../../atoms/TextArea/TextArea';
import Col from '../../../../atoms/Col/Col';
import Row from '../../../../atoms/Row/Row';
import Input from '../../../../atoms/Input/Input';

const { REACT_APP_REST, } = process.env;


const RecordExtraForm = ({
  form,
  translations,
  values,
  onChange,
}) => (
  <>
    <FormRowReadOnly
      label={translations.gatehouse.rnPicture}
    >
      {values.picture && (
        <img width="346px" src={`${REACT_APP_REST}/file?filename=${values.picture}`} alt="" />
      )}
      {!values.picture && (
        <span>--</span>
      )}
    </FormRowReadOnly>

    <FormRow
      label={translations.form.note}
      input={form.note}
    >
      <TextArea
        type="text"
        value={values.note}
        onChange={(e) => onChange(form.note.name, e.target.value)}
        status={form.note.status}
      />
    </FormRow>

    {values.transportationId && (
    <Row>
      <Col HD={12}>
        <FormRowReadOnly
          label={translations.form.status}
        >
          <Input
            type="text"
            value={values.transportationStateId ? translations.monitoring[values.transportationStateId] : ''}
            disabled
          />
        </FormRowReadOnly>
      </Col>
      <Col HD={12}>
        <FormRowReadOnly
          label={translations.form.platform}
        >
          <Input
            type="text"
            value={values.platform}
            disabled
          />
        </FormRowReadOnly>
      </Col>
    </Row>
    )}
  </>
);


RecordExtraForm.propTypes = {
  translations: object.isRequired,
  form: shape({
    note: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
  }).isRequired,
  values: shape({
    picture: string,
    note: string,
  }).isRequired,
  onChange: func.isRequired,
};


export default RecordExtraForm;
