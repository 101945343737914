import gql from 'graphql-tag';


/**
 * Transportation
 */
export const MUTATION_CREATE_TRANSPORTATION = gql`
  mutation CreateTransportation($reservations: [reservationInputType], $company: companyInputType, $driver: driverInputType, $passenger: driverInputType, $truck: truckInputType, $trailer: trailerInputType, $extra: extraInputType) {
    createTransportation(reservations: $reservations, company: $company, driver: $driver, passenger: $passenger, truck: $truck, trailer: $trailer, extra: $extra) {
      id
    }
  }
`;

export const MUTATION_EDIT_TRANSPORTATION = gql`
  mutation UpdateTransportation($id: ID!, $isMonitoring: Boolean!, $reservation: reservationInputType, $company: companyInputType, $driver: driverInputType, $passenger: driverInputType, $truck: truckInputType, $trailer: trailerInputType, $extra: extraInputType, $products: [productInputType]) {
    updateTransportation(id: $id, isMonitoring: $isMonitoring, reservation: $reservation, company: $company, driver: $driver, passenger: $passenger, truck: $truck, trailer: $trailer, extra: $extra, products: $products)
  }
`;

export const MUTATION_DELETE_TRANSPORTATION = gql`
  mutation deleteTransportation($id: ID!) {
    deleteTransportation(id: $id)
  }
`;

export const MUTATION_UPDATE_GEN_FORM = gql`
  mutation CreateOrUpdateGenericFormValues($values: JSON, $transportationId: ID!, $genericFormId: ID!, $actionId: ID!) {
    createOrUpdateGenericFormValues(values: $values, transportationId: $transportationId, genericFormId: $genericFormId, actionId: $actionId)
  }
`;


/**
 * Common
 */
export const MUTATION_SCHEDULE_TIMELINE_FILTER = gql`
  mutation UpdateScheduleTimelineFilter($filter: ScheduleTimelineFilter!) {
    updateScheduleTimelineFilter(filter: $filter) @client
  }
`;

export const MUTATION_UPDATE_TECHNICAL_CHECK = gql`
  mutation UpdateTechnicalCheck($transportationId: ID!, $type: String!, $data: technicalCheckInputType!, $pin: String) {
    updateTechnicalCheck(transportationId: $transportationId, type: $type, data: $data, pin: $pin)
  }
`;

export const MUTATION_SEND_RS_PIN = gql`
  mutation SendSmsWithRsPin($transportationId: ID!) {
    sendSmsWithRsPin(transportationId: $transportationId)
  }
`;
