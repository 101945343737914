// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
import React, { useCallback, useEffect, useState, } from 'react';

import ReportAssignBox from './ReportAssignBox';
import Button from '../../../atoms/Button/Button';
import Card from '../../../atoms/Card/Card';


const getLabelById = (id, list) => {
  const item = list.find((item) => parseInt(item.id, 10) === parseInt(id, 10));
  return item ? item.label : id.toString();
};

const ReportUserGroupDetail = (props) => {
  const [ availableReports, setAvailableReports, ] = useState([]);
  const [ availableUsers, setAvailableUsers, ] = useState([]);
  const [ availableTransportationTypes, setAvailableTransportationTypes, ] = useState([]);

  const [ assignedReports, setAssignedReports, ] = useState([]);
  const [ assignedUsers, setAssignedUsers, ] = useState([]);
  const [ assignedTransportationTypes, setAssignedTransportationTypes, ] = useState([]);

  useEffect(() => {
    const reportItems = props.reports.map((r) => ({ id: r.id, label: r.name, }));
    const userItems = props.users.map((u) => ({ id: u.id, label: u.username, }));
    const transportationTypeItems = props.transportationTypes.map((tt) => ({ id: tt.id, label: tt.name, }));

    const assignedReportsList = props.reportUserGroup.reportIds.map((r) => ({ id: r, label: getLabelById(r, reportItems), }));
    const assignedUsersList = props.reportUserGroup.userIds.map((u) => ({ id: u, label: getLabelById(u, userItems), }));
    const assignedTransportationTypesList = props.reportUserGroup.transportationTypeIds.map((tt) => ({ id: tt, label: getLabelById(tt, transportationTypeItems), }));

    setAvailableReports(reportItems.filter((r) => !props.reportUserGroup.reportIds.includes(parseInt(r.id, 10))));
    setAssignedReports(assignedReportsList);

    setAvailableUsers(userItems.filter((u) => !props.reportUserGroup.userIds.includes(parseInt(u.id, 10))));
    setAssignedUsers(assignedUsersList);

    setAvailableTransportationTypes(transportationTypeItems.filter((tt) => !props.reportUserGroup.transportationTypeIds.includes(parseInt(tt.id, 10))));
    setAssignedTransportationTypes(assignedTransportationTypesList);
  }, [ props.reportUserGroup, ]);

  const assignReportItem = useCallback((item) => {
    setAssignedReports([ ...assignedReports, item, ]);
    setAvailableReports(availableReports.filter((r) => r.id !== item.id));
  }, [ availableReports, assignedReports, ]);

  const unassignReportItem = useCallback((item) => {
    setAssignedReports(assignedReports.filter((r) => r.id !== item.id));
    setAvailableReports([ ...availableReports, item, ]);
  }, [ availableReports, assignedReports, ]);

  const assignUserItem = useCallback((item) => {
    setAssignedUsers([ ...assignedUsers, item, ]);
    setAvailableUsers(availableUsers.filter((r) => r.id !== item.id));
  }, [ availableUsers, assignedUsers, ]);

  const unassignUserItem = useCallback((item) => {
    setAssignedUsers(assignedUsers.filter((r) => r.id !== item.id));
    setAvailableUsers([ ...availableUsers, item, ]);
  }, [ availableUsers, assignedUsers, ]);

  const assignTransportationTypeItem = useCallback((item) => {
    setAssignedTransportationTypes([ ...assignedTransportationTypes, item, ]);
    setAvailableTransportationTypes(availableTransportationTypes.filter((r) => r.id !== item.id));
  }, [ availableTransportationTypes, assignedTransportationTypes, ]);

  const unassignTransportationTypeItem = useCallback((item) => {
    setAssignedTransportationTypes(assignedTransportationTypes.filter((r) => r.id !== item.id));
    setAvailableTransportationTypes([ ...availableTransportationTypes, item, ]);
  }, [ availableTransportationTypes, assignedTransportationTypes, ]);

  const save = useCallback(() => {
    props.onUpdate(props.reportUserGroup.id, {
      name: props.reportUserGroup.name,
      reportIds: assignedReports.map((r) => parseInt(r.id, 10)),
      transportationTypeIds: assignedTransportationTypes.map((tt) => parseInt(tt.id, 10)),
      userIds: assignedUsers.map((u) => parseInt(u.id, 10)),
    });
  }, [ assignedReports, assignedTransportationTypes, assignedUsers, ]);

  return (
    <div className="report-user-group-detail">
      <h1>{props.reportUserGroup.name}</h1>
      <div className="report-user-group-detail--boxes">
        <Card className="report-user-group-detail--box">
          <ReportAssignBox
            title="Nastavení přístupů k reportům"
            assignedItems={assignedReports}
            availableItems={availableReports}
            onAssign={assignReportItem}
            onUnassign={unassignReportItem}
          />
        </Card>
        <Card className="report-user-group-detail--box">
          <ReportAssignBox
            title="Nastavení přístupů pro uživatele"
            assignedItems={assignedUsers}
            availableItems={availableUsers}
            onAssign={assignUserItem}
            onUnassign={unassignUserItem}
          />
        </Card>
        <Card className="report-user-group-detail--box">
          <ReportAssignBox
            title="Nastavení přístupů ke službám"
            assignedItems={assignedTransportationTypes}
            availableItems={availableTransportationTypes}
            onAssign={assignTransportationTypeItem}
            onUnassign={unassignTransportationTypeItem}
          />
        </Card>
      </div>
      <div>
        <Button
          color="success"
          type="button"
          onClick={save}
        >
          Uložit
        </Button>
      </div>
    </div>
  );
};


export default ReportUserGroupDetail;
