import React, { Fragment, } from 'react';
import {
  func, object,
} from 'prop-types';

import FormRow from '../../../../components/Form/FormRow';
import Input from '../../../../atoms/Input/Input';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import InputDateSelect from '../../../../atoms/InputDateSelect/InputDateSelect';


const TechnicalCheckDriverForm = ({
  form,
  translations,
  values,
  onChange,
}) => (
  <Fragment>

    <Row>
      <Col SM={12}>
        <FormRow
          label={translations.form.driver}
          input={form.driverName}
        >
          <Input
            type="text"
            value={values.driverName}
            onChange={(e) => onChange(form.driverName.name, e.target.value)}
            status={form.driverName.status}
          />
        </FormRow>
      </Col>
    </Row>

    <Row>
      <Col SM={12}>
        <FormRow
          label={translations.form.driverNationality}
          input={form.driverNationality}
        >
          <Input
            type="text"
            value={values.driverNationality}
            onChange={(e) => onChange(form.driverNationality.name, e.target.value)}
            status={form.driverNationality.status}
          />
        </FormRow>
      </Col>
    </Row>

    <Row>
      <Col SM={12}>
        <FormRow
          label={translations.form.driverIdentityDocumentNumber}
          input={form.driverIdentityDocumentNumber}
        >
          <Input
            type="text"
            value={values.driverIdentityDocumentNumber}
            onChange={(e) => onChange(form.driverIdentityDocumentNumber.name, e.target.value)}
            status={form.driverIdentityDocumentNumber.status}
          />
        </FormRow>
      </Col>
    </Row>

    <Row>
      <Col SM={12}>
        <FormRow
          label={translations.form.adrDriverNo}
          input={form.adrDriverNo}
        >
          <Input
            type="text"
            value={values.adrDriverNo}
            onChange={(e) => onChange(form.adrDriverNo.name, e.target.value)}
            status={form.adrDriverNo.status}
          />
        </FormRow>
      </Col>
    </Row>

    <Row>
      <Col SM={12}>
        <FormRow
          label={translations.form.adrDriverDate}
          input={form.adrDriverDate}
        >
          <InputDateSelect
            value={values.adrDriverDate}
            onChange={(newValue) => onChange(form.adrDriverDate.name, newValue)}
            status={form.adrDriverDate.status}
          />
        </FormRow>
      </Col>
    </Row>

    <Row>
      <Col SM={12}>
        <FormRow
          label={translations.form.passengerName}
          input={form.passengerName}
        >
          <Input
            type="text"
            value={values.passengerName}
            onChange={(e) => onChange(form.passengerName.name, e.target.value)}
            status={form.passengerName.status}
          />
        </FormRow>
      </Col>
    </Row>

    <Row>
      <Col SM={12}>
        <FormRow
          label={translations.form.passengerNationality}
          input={form.passengerNationality}
        >
          <Input
            type="text"
            value={values.passengerNationality}
            onChange={(e) => onChange(form.passengerNationality.name, e.target.value)}
            status={form.passengerNationality.status}
          />
        </FormRow>
      </Col>
    </Row>

    <Row>
      <Col SM={12}>
        <FormRow
          label={translations.form.passengerIdentityDocumentNumber}
          input={form.passengerIdentityDocumentNumber}
        >
          <Input
            type="text"
            value={values.passengerIdentityDocumentNumber}
            onChange={(e) => onChange(form.passengerIdentityDocumentNumber.name, e.target.value)}
            status={form.passengerIdentityDocumentNumber.status}
          />
        </FormRow>
      </Col>
    </Row>

    <Row>
      <Col SM={12}>
        <FormRow
          label={translations.form.adrPassengerNo}
          input={form.adrPassengerNo}
        >
          <Input
            type="text"
            value={values.adrPassengerNo}
            onChange={(e) => onChange(form.adrPassengerNo.name, e.target.value)}
            status={form.adrPassengerNo.status}
          />
        </FormRow>
      </Col>
    </Row>

    <Row>
      <Col SM={12}>
        <FormRow
          label={translations.form.adrPassengerDate}
          input={form.adrPassengerDate}
        >
          <InputDateSelect
            value={values.adrPassengerDate}
            onChange={(newValue) => onChange(form.adrPassengerDate.name, newValue)}
            status={form.adrPassengerDate.status}
          />
        </FormRow>
      </Col>
    </Row>

  </Fragment>
);


TechnicalCheckDriverForm.propTypes = {
  translations: object.isRequired,
  form: object.isRequired,
  values: object.isRequired,
  onChange: func.isRequired,
};


export default TechnicalCheckDriverForm;
