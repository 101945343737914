import React from 'react';
import { number, node, string, func, } from 'prop-types';


const ColumnChartColumn = ({
  element, label, columnsCount, onClick,
}) => (
  <div
    className="column-chart-column"
    style={{ margin: `0 ${24 / columnsCount}px`, }}
    onClick={onClick}
  >
    {element}
    {label && (
      <div className="column-chart-column--label">
        {label}
      </div>
    )}
  </div>
);

ColumnChartColumn.defaultProps = {
  label: undefined,
  onClick: undefined,
};


ColumnChartColumn.propTypes = {
  element: node.isRequired,
  label: string,
  columnsCount: number.isRequired,
  onClick: func,
};


export default ColumnChartColumn;
