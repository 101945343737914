import React from 'react';
import { object, string, } from 'prop-types';

import FormBackground from '../../../../components/Form/FormBackground';
import GFText from '../../../../components/GenericForm/ReadOnly/GFText';
import StyledPictures from './styles/StyledPictures';
import FormRowReadOnly from '../../../../components/Form/FormRowReadOnly';
import GFDownload from '../../../../components/GenericForm/ReadOnly/GFDownload';


const { REACT_APP_REST, } = process.env;

const FlowChartTechnicalCheckAction = ({
  translations,
  transportation,
  action,
}) => (
  <FormBackground>
    <GFText
      input={{
        type: 'text',
        disabled: false,
        translation: {
          label: translations.form.note,
          placeholder: '',
          help: '',
        },
      }}
      value={transportation.technicalCheckNote}
    />
    <GFText
      input={{
        type: 'text',
        disabled: false,
        translation: {
          label: translations.form.technicalCheckResult,
          placeholder: '',
          help: '',
        },
      }}
      value={transportation.technicalCheckResult
        ? translations.form.technicalCheckResultSuccess
        : translations.form.technicalCheckResultFail
      }
    />
    {action === 'inputTechnicalCheck' && transportation.inTcPdf && (
      <GFDownload
        linkLabel={translations.common.downloadPdfLabel}
        input={{ translation: { label: translations.form.inputTechnicalCheckDocument, }, }}
        link={`${process.env.REACT_APP_REST}/file?filename=${transportation.inTcPdf}`}
      />
    )}
    {action === 'outputTechnicalCheck' && transportation.outTcPdf && (
      <GFDownload
        linkLabel={translations.common.downloadPdfLabel}
        input={{ translation: { label: translations.form.outputTechnicalCheckDocument, }, }}
        link={`${process.env.REACT_APP_REST}/file?filename=${transportation.outTcPdf}`}
      />
    )}
    {Array.isArray(transportation.technicalCheckPictures)
      && transportation.technicalCheckPictures.length > 0
    && (
      <StyledPictures>
        <FormRowReadOnly label={translations.transportation.technicalCheck.titleFilesForm}>
          <ul>
            {transportation.technicalCheckPictures.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <li key={index}>
                <a href={`${REACT_APP_REST}/file?filename=${item}`} target="_blank" rel="noopener noreferrer">
                  <img
                    src={`${REACT_APP_REST}/file?filename=${item}`}
                    alt={index}
                    width="100"
                    height="100"
                  />
                </a>
              </li>
            ))}
          </ul>
        </FormRowReadOnly>
      </StyledPictures>
    )}
  </FormBackground>
);


FlowChartTechnicalCheckAction.propTypes = {
  translations: object.isRequired,
  transportation: object.isRequired,
  action: string.isRequired,
};


export default FlowChartTechnicalCheckAction;
