import React from 'react';

import StyledSvg from './styles/StyledSvg';


const LockOpened = ({ ...props }) => (
  <StyledSvg viewBox="0 0 446.25 446.25" {...props}>
    <path d="M318.75,280.5h-20.4l-7.649-7.65c25.5-28.05,40.8-66.3,40.8-107.1C331.5,73.95,257.55,0,165.75,0S0,73.95,0,165.75
      S73.95,331.5,165.75,331.5c40.8,0,79.05-15.3,107.1-40.8l7.65,7.649v20.4L408,446.25L446.25,408L318.75,280.5z M165.75,280.5
      C102,280.5,51,229.5,51,165.75S102,51,165.75,51S280.5,102,280.5,165.75S229.5,280.5,165.75,280.5z"
    />
  </StyledSvg>
);

export default LockOpened;
