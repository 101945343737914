import React from 'react';
import {
  arrayOf, shape, node, string, func,
} from 'prop-types';

import { StyledColumnChart, } from './StyledColumnChart';
import ColumnChartColumn from './ColumnChartColumn';

/* eslint-disable react/no-array-index-key */

const ColumnChart = ({ levels, columns, }) => (
  <StyledColumnChart>
    <div className="column-chart">
      {levels.length > 0 && (
        <div className="column-chart--levels">
          {levels.map(({ label, }, i) => (<span key={i}>{label}</span>))}
        </div>
      )}
      <div
        className={`column-chart--columns ${levels.length > 0 ? 'with-levels' : ''}`}
      >
        {levels.filter((_, i) => i > 1).map((_, i) => (
          <div
            key={i}
            className="column-chart--columns-level-line"
            style={{ height: `${100 / (levels.length - 1) * (i + 1)}%`, }}
          />
        ))}
        {columns.map(({ element, label, onClick, }, i) => (
          <ColumnChartColumn
            key={i}
            element={element}
            label={label}
            columnsCount={columns.length}
            onClick={onClick}
          />
        ))}
      </div>
    </div>
  </StyledColumnChart>
);


ColumnChart.defaultProps = {
  levels: [],
};


ColumnChart.propTypes = {
  columns: arrayOf(
    shape({
      element: node.isRequired,
      label: string,
      onClick: func,
    }).isRequired,
  ).isRequired,
  levels: arrayOf(
    shape({
      label: string.isRequired,
    }).isRequired,
  ),
};


export default ColumnChart;
