import { FORM_TYPES, FORM_ROOT, } from '../../globals';
import { initInput, } from './common';
import { getInitValue, parseFormValueToApiValue, } from './commonGenForm';


export const parseFromApi = (
  genericForm,
  gfValues,
) => {
  if (genericForm === null) {
    return {
      id: null,
      name: null,
      form: null,
      tree: null,
      values: null,
    };
  }

  const {
    id,
    name,
    inputs,
    structure = null,
    translation,
  } = genericForm;

  // 1. inputs, values -> form, values
  const form = {};
  const values = {};
  for (let i = 0; i < inputs.length; i++) {
    const input = inputs[i];
    const currValue = gfValues[input.name];

    // 1.1 - input
    form[input.name] = initInput(input);
    // 1.2 - value
    values[input.name] = getInitValue({
      type: input.type,
      defaultValue: input.defaultValue,
      currValue,
    });
  }

  // 2. structure, translation -> tree
  const tree = {};
  const structKeys = Object.keys(structure);
  for (let j = 0; j < structKeys.length; j++) {
    const structKey = structKeys[j];
    const struct = structure[structKey];

    switch (struct.type) {
      case FORM_TYPES.NUMBER:
      case FORM_TYPES.CHECKBOX:
      case FORM_TYPES.RADIO:
      case FORM_TYPES.SELECT:
      case FORM_TYPES.MULTISELECT:
      case FORM_TYPES.TEXT:
      case FORM_TYPES.TIME:
      case FORM_TYPES.DATE:
      case FORM_TYPES.TIMESTAMP: {
        // 2.1. check input
        if (!Object.prototype.hasOwnProperty.call(form, structKey)) throw new Error('no input data for form');
        // 2.2. structure -> tree
        tree[structKey] = struct;
        break;
      }

      case FORM_ROOT:
      case FORM_TYPES.FIELDSET:
      case FORM_TYPES.GROUP: {
        // 2.1. structure, translation -> tree
        tree[structKey] = {
          ...struct,
          texts: translation.texts ? translation.texts[structKey] : undefined,
        };
        break;
      }

      default:
        break;
    }
  }

  return {
    id,
    name,
    form,
    tree,
    values,
  };
};


//
// Parse values to api
//
export const parseToApi = ({ tree, form, values, }) => {
  if (!tree) return null;

  const ret = {};
  const keys = Object.keys(values);
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];

    const value = values[key];

    if (Object.prototype.hasOwnProperty.call(form, key)) {
      const { type, } = form[key];

      ret[key] = parseFormValueToApiValue({ type, value, });
    } else {
      ret[key] = value;
    }
  }

  return ret;
};
