import React, { Fragment, } from 'react';
import { object, func, } from 'prop-types';

import HeadingWihButtons from '../../../../atoms/HeadingWihButtons/HeadingWihButtons';
import Button from '../../../../atoms/Button/Button';
import UsersTable from './UsersTable';


const UsersView = ({
  // data
  translations,
  // methods
  onCreate,
  onDetail,
  onDelete,
  onBlock,
  onBlockCompleted,
  onBlockError,
}) => (
  <Fragment>

    <HeadingWihButtons>
      <h2>{translations.settings.usersTitle}</h2>
      <Button
        onClick={onCreate}
        color="success"
        size="sm"
      >
        {translations.settings.createUser}
      </Button>
    </HeadingWihButtons>

    <UsersTable
      // data
      translations={translations}
      // methods
      onDetail={onDetail}
      onDelete={onDelete}
      onBlock={onBlock}
      onBlockCompleted={onBlockCompleted}
      onBlockError={onBlockError}
    />

  </Fragment>
);


UsersView.propTypes = {
  // data
  translations: object.isRequired,
  // methods
  onCreate: func.isRequired,
  onDetail: func.isRequired,
  onDelete: func.isRequired,
  onBlock: func.isRequired,
  onBlockCompleted: func.isRequired,
  onBlockError: func.isRequired,
};


export default UsersView;
