import React from 'react';
import {
  object, shape, string, func, arrayOf,
} from 'prop-types';

import RoleResource from './RoleResource';


const RoleGroup = ({
  // data
  group: {
    name,
    resources,
  },
  resourcesValue,
  translations,
  languageId,
  // methods
  onChangeResourceValue,
}) => (
  <li className="settings--tree-group">
    <h5>
      {name}
    </h5>
    <ul className="settings--tree-list">
      {resources.map((resource) => (
        <RoleResource
          // data
          key={resource.id}
          resource={resource}
          value={resourcesValue[resource.id]}
          translations={translations}
          languageId={languageId}
          // methods
          onChangeResourceValue={onChangeResourceValue}
        />
      ))}
    </ul>
  </li>
);


RoleGroup.propTypes = {
  group: shape({
    name: string.isRequired,
    resources: arrayOf(shape({
      id: string.isRequired,
    })).isRequired,
  }).isRequired,
  translations: object.isRequired,
  languageId: string.isRequired,
  resourcesValue: object.isRequired,
  onChangeResourceValue: func.isRequired,
};


export default RoleGroup;
