import React from 'react';
import {
  shape, string, func, object, bool,
} from 'prop-types';
import { Query, } from 'react-apollo';

import { QUERY_DD_GATE_VEHICLE_GROUPS, } from '../../gql/queries';
import FormRow from '../../../../components/Form/FormRow';
import Input from '../../../../atoms/Input/Input';
import InputSelect from '../../../../atoms/InputSelect/InputSelect';
import InputTimeSelect from '../../../../atoms/InputTimeSelect/InputTimeSelect';
import { getGateTypesByGatehouseType, } from '../enums/gateTypeEnum';
import FormRowReadOnly from '../../../../components/Form/FormRowReadOnly';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import ButtonIcon from '../../../../atoms/Button/ButtonIcon';
import FormGroup from '../../../../atoms/FormGroup/FormGroup';
import Check from '../../../../styles/icons/Check';


const RecordBasicForm = ({
  form,
  languageId,
  values,
  translations,
  gatehouseType,
  onChange,
  onFindTransportation,
  isEditMode,
}) => (
  <>
    <FormRow
      label={translations.form.personName}
      input={form.driverName}
    >
      <Input
        type="text"
        value={values.driverName}
        onChange={(e) => onChange(form.driverName.name, e.target.value)}
        status={form.driverName.status}
        disabled={values.transportationId}
      />
    </FormRow>

    <FormRow
      label={translations.form.phoneNumber}
      input={form.driverPhoneNumber}
    >
      <Input
        type="text"
        value={values.driverPhoneNumber}
        onChange={(e) => onChange(form.driverPhoneNumber.name, e.target.value)}
        status={form.driverPhoneNumber.status}
        disabled={values.transportationId}
      />
    </FormRow>

    <FormRow
      label={translations.form.transportationType}
      input={form.gateVehicleGroupId}
    >
      {values.transportationId && (
        <Input
          type="text"
          value={values.transportationTypeName}
          disabled
        />
      )}

      {!values.transportationId && (
        <Query
          query={QUERY_DD_GATE_VEHICLE_GROUPS}
          variables={{ languageId, }}
        >
          {({ data, loading, error, }) => (
            <InputSelect
              value={values.gateVehicleGroupId}
              options={
                (data && data.fetchGateVehicleGroups)
                  ? data.fetchGateVehicleGroups
                  : []
              }
              onChange={(option) => onChange(form.gateVehicleGroupId.name, option)}
              status={form.gateVehicleGroupId.status}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              isClearable
              isLoading={loading}
              error={error}
              placeholder={translations.common.select}
            />
          )}
        </Query>
      )}
    </FormRow>

    <FormRowReadOnly
      label={translations.form.reservationNumber}
    >
      <FormGroup>
        <Input
          type="text"
          value={values.transportationNo}
          onChange={(e) => onChange(form.transportationNo.name, e.target.value)}
          status={form.transportationNo.status}
          disabled={isEditMode}
        />
        {!isEditMode && !values.transportationId && (
          <ButtonIcon
            size="sm"
            color="success"
            style={{ height: '36px', width: '36px', minWidth: '36px', }}
            onClick={() => { onFindTransportation({ transportationNo: values.transportationNo, }); }}
          >
            <Check />
          </ButtonIcon>
        )}
      </FormGroup>
    </FormRowReadOnly>

    <Row>
      <Col HD={12}>
        <FormRow
          label={translations.form.registrationNumber}
          input={form.registrationNumber}
        >
          <FormGroup>
            <Input
              type="text"
              value={values.registrationNumber}
              onChange={(e) => onChange(form.registrationNumber.name, e.target.value)}
              status={form.registrationNumber.status}
            />
            {((!isEditMode && !values.transportationId) || (isEditMode && values.gateType.id === 'entrance')) && (
              <ButtonIcon
                size="sm"
                color="success"
                position="bottomRight"
                style={{ height: '36px', width: '36px', minWidth: '36px', }}
                onClick={() => { onFindTransportation({ registrationNumber: values.registrationNumber, }); }}
              >
                <Check />
              </ButtonIcon>
            )}
          </FormGroup>
        </FormRow>
      </Col>
      <Col HD={12}>
        <FormRow
          label={translations.form.trailerRegistrationNo}
          input={form.trailerRegistrationNumber}
        >
          <Input
            type="text"
            value={values.trailerRegistrationNumber}
            onChange={(e) => onChange(form.trailerRegistrationNumber.name, e.target.value)}
            status={form.trailerRegistrationNumber.status}
          />
        </FormRow>
      </Col>
    </Row>

    <FormRow
      label={translations.gatehouse.direction}
      input={form.gateType}
    >
      <InputSelect
        value={values.gateType}
        options={getGateTypesByGatehouseType(gatehouseType).map((gt) => (
          { id: gt.id, name: translations.form[gt.translationKey], }))
        }
        onChange={(option) => onChange(form.gateType.name, option)}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        status={form.gateType.status}
        placeholder={translations.common.select}
        disabled={isEditMode}
      />
    </FormRow>

    <FormRow
      label={translations.form.time}
      input={form.time}
    >
      <InputTimeSelect
        value={values.time}
        showTime
        onChange={(newTime) => onChange(form.time.name, newTime)}
        status={form.time.status}
        disabled={isEditMode}
      />
    </FormRow>
  </>
);


RecordBasicForm.propTypes = {
  languageId: string.isRequired,
  translations: object.isRequired,
  isEditMode: bool.isRequired,
  form: shape({
    driverName: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    driverPhoneNumber: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    gateVehicleGroupId: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    registrationNumber: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    trailerRegistrationNumber: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    gateType: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    time: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
  }).isRequired,
  values: shape({
    driverName: string,
    driverPhoneNumber: string,
    gateVehicleGroupId: object,
    registrationNumber: string,
    gateType: object,
    time: object,
  }).isRequired,
  gatehouseType: string.isRequired,
  onChange: func.isRequired,
  onFindTransportation: func.isRequired,
};


export default RecordBasicForm;
