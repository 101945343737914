import React, { Fragment, } from 'react';
import {
  func, object,
} from 'prop-types';

import FormRow from '../../../../components/Form/FormRow';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import InputSelect from '../../../../atoms/InputSelect/InputSelect';
import { getGoodsLoadedOptions, getYesNoOptions, } from '../InputTechnicalCheck/utils';
import Input from "../../../../atoms/Input/Input";


const TechnicalCheckEntranceForm = ({
  form,
  translations,
  values,
  onChange,
}) => (
  <Fragment>

    <Row>
      <Col SM={12}>
        <FormRow
          label={translations.form.otherSenderAtEntrance}
          input={form.otherSenderAtEntrance}
        >
          <InputSelect
            value={values.otherSenderAtEntrance}
            options={getYesNoOptions(translations)}
            onChange={(option) => onChange(form.otherSenderAtEntrance.name, option)}
            status={form.otherSenderAtEntrance.status}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            placeholder={translations.common.select}
          />
        </FormRow>
      </Col>
    </Row>

    <Row>
      <Col SM={12}>
        <FormRow
          label={translations.form.goodsLoaded}
          input={form.goodsLoaded}
        >
          <InputSelect
            value={values.goodsLoaded}
            options={getGoodsLoadedOptions(translations)}
            onChange={(option) => onChange(form.goodsLoaded.name, option)}
            status={form.goodsLoaded.status}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            placeholder={translations.common.select}
          />
        </FormRow>
      </Col>
    </Row>

    {values.goodsLoaded && values.goodsLoaded.id === 'dangerous' && (
      <Row>
        <Col SM={12}>
          <FormRow
            label={translations.form.goodsLoadedUn}
            input={form.goodsLoadedUn}
          >
            <Input
              type="text"
              value={values.goodsLoadedUn}
              onChange={(e) => onChange(form.goodsLoadedUn.name, e.target.value)}
              status={form.goodsLoadedUn.status}
            />
          </FormRow>
        </Col>
      </Row>
    )}

  </Fragment>
);


TechnicalCheckEntranceForm.propTypes = {
  translations: object.isRequired,
  form: object.isRequired,
  values: object.isRequired,
  onChange: func.isRequired,
};


export default TechnicalCheckEntranceForm;
