import gql from 'graphql-tag';


export const QUERY_INPUT_SELECT_TRANSLATIONS = gql`
  query Dictionary($languageId: ID) {
    dictionary(languageId: $languageId) {
      dictionary {
        centralApp {
          common {
            loading
            noOptions
          }
        }
      }
    }
  }
`;
