import React from 'react';
import { func, bool, } from 'prop-types';

import Container from '../../atoms/Container/Container';
import Menu from '../../styles/icons/Menu';
import MenuClose from '../../styles/icons/MenuClose';
import DDLanguages from './DDLanguages';
import DDUser from './DDUser';
import ButtonIcon from '../../atoms/Button/ButtonIcon';

import StyledHeader from './styles/StyledHeader';
import logo from '../../dlm-logo.png';


const Header = ({ onToggleSidebar, isOpenSidebar, }) => (
  <StyledHeader isOpenSidebar={isOpenSidebar}>
    <div className="header--title">
      <img src={logo} alt="logo" width={100} />
    </div>

    <div className="header--content">
      <Container fluid className="header--content-container">
        <ul className="header--content--list">
          <li>
            <ButtonIcon
              className="header--content--button"
              onClick={onToggleSidebar}
              transparent
            >
              {isOpenSidebar ? <MenuClose /> : <Menu />}
            </ButtonIcon>
          </li>


          <li className="header--content-list--right">
            <DDUser />
          </li>
          <li>
            <DDLanguages />
          </li>
        </ul>
      </Container>
    </div>

  </StyledHeader>
);


Header.propTypes = {
  onToggleSidebar: func.isRequired,
  isOpenSidebar: bool.isRequired,
};


export default Header;
