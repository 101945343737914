import React from 'react';

import StyledSvg from '../styles/StyledSvg';


const Monitoring = ({ ...props }) => (
  <StyledSvg viewBox="0 0 512 512" {...props}>
    <path d="M176,422.002c0,24.75-20.25,45-45,45H46v30h420v-30h-85c-24.75,0-45-20.25-45-45v-2H176V422.002z" />
    <polygon points="340.19,150.002 320.188,89.998 191.812,89.998 171.811,150.002" />
    <rect x="166" y="180.002" width="180" height="30" />
    <rect y="345.002" width="512" height="45" />
    <path d="M512,315.002V14.998H0v300.004H512z M376,270.002h-30v-30H166v30h-30V162.568l34.188-102.57h171.623L376,162.563V270.002z" />
  </StyledSvg>
);

export default Monitoring;
