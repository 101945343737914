import React from 'react';
import {
  string, shape, bool, func,
} from 'prop-types';

import { onlyNumbersInString, } from '../../../logic/form/parseInput';
import FormRow from '../../Form/FormRow';
import Input from '../../../atoms/Input/Input';


const GFNumber = ({
  input, value, onChange,
}) => (
  <FormRow
    label={input.translation.label}
    input={input}
    info={input.translation.help}
  >
    <Input
      type="text"
      value={value}
      onChange={(e) => onChange(input.name, onlyNumbersInString(e.target.value))}
      status={input.status}
      disabled={input.disabled}
      placeholder={input.translation.placeholder}
    />
  </FormRow>
);


GFNumber.propTypes = {
  input: shape({
    name: string.isRequired,
    type: string.isRequired,
    disabled: bool.isRequired,
    translation: shape({
      label: string,
      placeholder: string,
      help: string,
    }).isRequired,
  }).isRequired,
  value: string.isRequired,
  onChange: func.isRequired,
};


export default GFNumber;
