import React from 'react';
import {
  bool, func, object, string,
} from 'prop-types';
import { Mutation, } from 'react-apollo';

import Modal from '../../atoms/Modal/Modal';
import Row from '../../atoms/Row/Row';
import Col from '../../atoms/Col/Col';
import Button from '../../atoms/Button/Button';
import ButtonLoader from '../../atoms/Button/ButtonLoader';
import { withTranslations, } from '../../logic/translations/withTranslations';
import { TRANSLATIONS_YES_NO_MODAL, } from './translations';


const YesNoModal = ({
  // data
  isOpen,
  title,
  text,
  mutation,
  variables,
  translations,
  // methods
  onToggle,
  onComplete,
  onError,
}) => (
  <Modal
    isOpen={isOpen}
    onClose={onToggle}
    title={title}
    size="SM"
  >
    <Row formGroup>
      <Col>
        {text}
      </Col>
    </Row>
    <Row>
      <Col XS={12} SM={12}>
        <Button
          onClick={onToggle}
          shape="outline"
        >
          {translations.common.no}
        </Button>
      </Col>

      <Col XS={12} SM={12} textAlign="right">
        <Mutation
          mutation={mutation}
          onCompleted={onComplete}
          onError={onError}
        >
          {(mutationFunc, { loading, }) => (
            <ButtonLoader
              color="success"
              onClick={() => {
                onToggle();
                mutationFunc({ variables, });
              }}
              isLoading={loading}
            >
              {translations.common.yes}
            </ButtonLoader>
          )}
        </Mutation>
      </Col>
    </Row>
  </Modal>
);


YesNoModal.propTypes = {
  // data
  isOpen: bool.isRequired,
  title: string.isRequired,
  text: string.isRequired,
  mutation: object.isRequired,
  variables: object.isRequired,
  translations: object.isRequired,
  // methods
  onToggle: func.isRequired,
  onComplete: func,
  onError: func,
};


YesNoModal.defaultProps = {
  onComplete: undefined,
  onError: undefined,
};


export default withTranslations(TRANSLATIONS_YES_NO_MODAL)(YesNoModal);
