import React from 'react';
import {
  arrayOf, number, string, shape, object, func,
} from 'prop-types';

import TopPanel from './TopPanel';
import Section from './Section';
import DashedTimeline from './DashedTimeline';


const MainPanel = ({
  sections,
  params,
  getItemNameAndStyle,
  onClickItem,
}) => (
  <div className="timeline--main">
    <DashedTimeline
      params={params}
    >
      <TopPanel
        params={params}
      />
      <ul className="timeline--sections">
        {sections.map((item) => (
          <Section
            key={item.id}
            {...item}
            params={params}
            getItemNameAndStyle={getItemNameAndStyle}
            onClickItem={onClickItem}
          />
        ))}
      </ul>
    </DashedTimeline>
  </div>
);


MainPanel.propTypes = {
  params: shape({
    leftPanelWidth: number.isRequired,
  }).isRequired,
  sections: arrayOf(shape({
    id: string.isRequired,
    payload: object.isRequired,
    rows: arrayOf(object).isRequired,
  })).isRequired,
  getItemNameAndStyle: func.isRequired,
  onClickItem: func.isRequired,
};


export default MainPanel;
