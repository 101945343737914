import React, { Component, } from 'react';
import {
  string, func, object, shape, bool,
} from 'prop-types';

import SortIcon from './SortIcon';


class THSortable extends Component {
  handleOnSort = () => {
    const { name, onSort, } = this.props;

    onSort(name);
  }


  render() {
    const {
      title,
      name,
      isActiveFilter,
      filter,
      style,
    } = this.props;

    const isActive = name === filter.sortBy;

    return (
      <th
        className={`table--header-title table--sortable ${isActive ? 'table--sortable-active' : ''}`}
        onClick={this.handleOnSort}
        style={style}
      >
        <div className="table--header-title-wrapper">
          <span className={isActiveFilter ? 'table--header-title-text-active' : undefined}>
            {title}
          </span>
          <span className="table--header-title-icon">
            <SortIcon name={name} filter={filter} />
          </span>
        </div>
      </th>
    );
  }
}


THSortable.propTypes = {
  // data
  title: string.isRequired,
  name: string.isRequired,
  isActiveFilter: bool,
  filter: shape({
    sortBy: string.isRequired,
    order: string.isRequired,
  }).isRequired,
  style: object,
  // func
  onSort: func.isRequired,
};

THSortable.defaultProps = {
  style: undefined,
  isActiveFilter: false,
};


export default THSortable;
