import styled from 'styled-components';


const StyledModalDelete = styled.div`
  margin-bottom: ${(p) => p.theme.formGroup.marginBottom};

  .errorModal--info {
    display: flex;
  }

  .errorModal--icon {
    text-align: left;
    width: 3rem;
    font-size: 1.5rem;
    color: ${(p) => p.theme.error.t700};
  }

  .errorModal--text {
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
  }

  .errorModal--list {
    padding-left: 2rem;
  }
`;


export default StyledModalDelete;
