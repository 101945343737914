import React from 'react';
import { object, func, string, } from 'prop-types';
import { Query, } from 'react-apollo';

import { QUERY_USER, } from '../../gql/queries';
import { TRANSLATIONS_USER, } from '../../gql/translations';
import { withTranslations, } from '../../../../logic/translations/withTranslations';
import Modal from '../../../../atoms/Modal/Modal';
import ModalLoadingPart from '../../../../components/modal/ModalLoadingPart';
import ModalErrorPart from '../../../../components/modal/ModalErrorPart';
import UserUpdateLogic from './UserUpdateLogic';


const UserUpdate = ({
  userId,
  translations,
  onToggle,
}) => (
  <Modal
    title={translations.settings.detailUserTitle}
    isOpen
    onClose={onToggle}
    size="MD"
    disablePadding
  >
    <Query
      query={QUERY_USER}
      variables={{
        id: userId,
      }}
      fetchPolicy="network-only"
    >
      {({ data, loading, error, }) => {
        if (loading) {
          return (
            <div className="app--padding">
              <ModalLoadingPart
                onBack={onToggle}
                btnBack={translations.common.back}
              />
            </div>
          );
        }

        if (error || !data.fetchUser) {
          return (
            <div className="app--padding">
              <ModalErrorPart
                onBack={onToggle}
                error={error}
                btnBack={translations.common.back}
              />
            </div>
          );
        }

        return (
          <UserUpdateLogic
            // data
            data={data.fetchUser}
            translations={translations}
            // methods
            onToggle={onToggle}
          />
        );
      }}
    </Query>
  </Modal>
);


UserUpdate.propTypes = {
  userId: string.isRequired,
  translations: object.isRequired,
  onToggle: func.isRequired,
};


export default withTranslations(TRANSLATIONS_USER)(UserUpdate);
