import React, { Fragment, } from 'react';
import {
  object, func, string, shape, bool, number, arrayOf,
} from 'prop-types';

import { SUBSCRIPTION_TRANSPORTATION_TABLE_DATA, SUBSCRIPTION_VISITORS, } from '../../gql/subscriptions';
import { QUERY_TRANSPORTATION_TABLE_DATA, QUERY_VISITORS, } from '../../gql/queries';
import TabPanel from '../../../../atoms/TabPanel/TabPanel';
import Tab from '../../../../atoms/TabPanel/Tab';
import HeadingWihButtons from '../../../../atoms/HeadingWihButtons/HeadingWihButtons';
import Button from '../../../../atoms/Button/Button';
import DynamicTableSettings from './DynamicTableSettings';
import ButtonGrp from '../../../../atoms/Button/ButtonGrp';
import ErrorBoundary from '../../../../components/ErrorBoundary/ErrorBoundary';
import PartError from '../../../../components/Parts/PartError';


const StandView = ({
  // data
  resources,
  data: {
    standId,
    createButton,
    visitorTab,
    tabs,
  },
  stand,
  translations,
  standTranslations,
  languageId,
  isAnyTabAvailable,
  activeTab,
  // methods
  onChangeTab,
  onToggleCreateVisitor,
  onToggleCreateTransportation,
  onTransportAction,
  onToggleDetail,
  onDepartureVisitor,
  onToggleSoeCallStand,
  onToggleTechnicalCheckCallStand,
}) => {
  if (!isAnyTabAvailable) {
    return (
      <PartError
        msg={translations.stand.errorStandMissingTab}
      />
    );
  }

  const showCreateTransportationButton = createButton && resources.create_transportation;
  const showButtons = showCreateTransportationButton || visitorTab.enable;
  if (activeTab === 0 && standId === '1') {
    const dateColumn = tabs[activeTab].table.find((column) => column.attribute === 'date');
    if (dateColumn) {
      const from = new Date();
      from.setHours(0, 0, 0, 0);
      const to = new Date();
      to.setHours(23, 59, 59, 999);
      dateColumn.filtrable.from = from;
      dateColumn.filtrable.to = to;
    }
  }

  return (
    <Fragment>

      <HeadingWihButtons>
        <h2>{stand.name}</h2>
        {showButtons && (
          <ButtonGrp>
            {showCreateTransportationButton && (
              <Button
                onClick={onToggleCreateTransportation}
                color="success"
                size="sm"
              >
                {translations.transportation.createTransportation}
              </Button>
            )}
            {visitorTab.enable && (
              <Button
                onClick={onToggleCreateVisitor}
                color="success"
                size="sm"
              >
                {translations.visitor.createVisitor}
              </Button>
            )}
          </ButtonGrp>
        )}
      </HeadingWihButtons>

      <TabPanel>
        {tabs.map((item, index) => {
          if (item.enable) {
            return (
              <Tab
                key={item.name}
                name={index}
                activeTab={activeTab}
                onClick={onChangeTab}
              >
                {standTranslations.stands[item.name]}
              </Tab>
            );
          }

          return null;
        })}

        {visitorTab.enable && (
          <Tab
            name={tabs.length}
            activeTab={activeTab}
            onClick={onChangeTab}
          >
            {standTranslations.stands.visitorTab}
          </Tab>
        )}
      </TabPanel>

      <ErrorBoundary
        renderError={() => <PartError />}
      >
        {(activeTab === tabs.length) ? (
          <DynamicTableSettings
            languageId={languageId}
            standId={standId}
            stand={stand}
            onToggleSoeCallStand={onToggleSoeCallStand}
            onToggleTechnicalCheckCallStand={onToggleTechnicalCheckCallStand}
            /**
             * Table actions
             *  - onAction: on row button click, args: row
             */
            onAction={onDepartureVisitor}
            /**
             * Table settings
             */
            tabData={visitorTab}
            /**
             * Translations
             *  - translationsCols: columns
             *  - translations: translations
             *  - actionNameActive
             *  - actionNameInactive
             */
            translationsCols={standTranslations.standVisitorsDataColumns}
            translations={translations}
            actionNameActive={translations.stand.btnVisitorAction}
            actionNameInactive={translations.stand.btnVisitorAction}
            /**
             * Query
             *  - query
             *  - variables
             *  - getRows: parse data from query
             */
            query={QUERY_VISITORS(visitorTab.columns)}
            getRows={(data) => (data.fetchStandDashboardVisitorsData
              ? data.fetchStandDashboardVisitorsData.rows
              : undefined
            )}
            /**
             * Subscriptions
             *  - subscribeVariables: document, variables
             *  - getRowsSubscription: parse data from subscription
             */
            subscribeVariables={{
              document: SUBSCRIPTION_VISITORS(visitorTab.columns),
            }}
            getRowsSubscription={(data) => (data && data.standDashboardVisitorsData
              ? data.standDashboardVisitorsData.rows
              : undefined
            )}
          />
        ) : (
          <DynamicTableSettings
            key={`${activeTab}-${languageId}`}
            languageId={languageId}
            standId={standId}
            stand={stand}
            /**
             * Table settings
             */
            tabData={tabs[activeTab]}
            /**
             * Table actions
             *  - onAction: on row button click, args: row
             *  - onDetail: on row click, args: row
             */
            onAction={onTransportAction}
            onDetail={onToggleDetail}
            onToggleSoeCallStand={onToggleSoeCallStand}
            onToggleTechnicalCheckCallStand={onToggleTechnicalCheckCallStand}
            /**
             * Translations
             *  - translationsCols: columns
             *  - translations: translations
             *  - actionNameActive
             *  - actionNameInactive
             */
            translationsCols={standTranslations.standDataColumns}
            translations={translations}
            actionNameActive={translations.stand.btnTransportationActionActive}
            actionNameInactive={translations.stand.btnTransportationActionInactive}
            /**
             * Query
             *  - query
             *  - variables
             *  - getRows: parse data from query
             */
            query={QUERY_TRANSPORTATION_TABLE_DATA(tabs[activeTab].columns)}
            variables={{
              languageId: languageId === null ? undefined : languageId,
              type: tabs[activeTab].name,
              standId,
            }}
            getRows={(data) => (data.fetchStandDashboardTransportationData
              ? data.fetchStandDashboardTransportationData.rows
              : undefined
            )}
            /**
             * Subscriptions
             *  - subscribeVariables: document, variables
             *  - getRowsSubscription: parse data from subscription
             */
            subscribeVariables={{
              document: SUBSCRIPTION_TRANSPORTATION_TABLE_DATA(tabs[activeTab].columns),
              variables: {
                transportationTypeIds:
                  resources.stand_access_by_transportation_type_id
                  && resources.stand_access_by_transportation_type_id.constraint
                    ? resources.stand_access_by_transportation_type_id.constraint : undefined,
                languageId: languageId === null ? undefined : languageId,
                type: tabs[activeTab].name,
                standId,
              },
            }}
            getRowsSubscription={(data) => (data && data.standDashboardTransportationData
              ? data.standDashboardTransportationData.rows
              : undefined
            )}
          />
        )}
      </ErrorBoundary>
    </Fragment>
  );
};


StandView.propTypes = {
  resources: object.isRequired,
  data: shape({
    visitorTab: object.isRequired,
    tabs: arrayOf(object).isRequired,
    standId: string.isRequired,
    createButton: bool.isRequired,
  }).isRequired,
  stand: shape({
    name: string.isRequired,
  }).isRequired,
  standTranslations: object.isRequired,
  translations: object.isRequired,
  languageId: string.isRequired,
  activeTab: number.isRequired,
  isAnyTabAvailable: bool.isRequired,
  onChangeTab: func.isRequired,
  onToggleCreateVisitor: func.isRequired,
  onToggleCreateTransportation: func.isRequired,
  onTransportAction: func.isRequired,
  onToggleDetail: func.isRequired,
  onDepartureVisitor: func.isRequired,
  onToggleSoeCallStand: func.isRequired,
  onToggleTechnicalCheckCallStand: func.isRequired,
};


export default StandView;
