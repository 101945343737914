import styled from 'styled-components';


const FormBackground = styled.div`
  background: ${(p) => p.theme.grey.t50};
  padding: ${(p) => p.theme.common.padding};
`;


export default FormBackground;
