// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
import React, { useState, } from 'react';
import InputSelect from '../../../atoms/InputSelect/InputSelect';
import FormRowSimple from '../../../components/Form/FormRowSimple';


const ReportNotificationGroupInterval = (props) => {
  const intervals = [ 'day', 'week', 'month', ];
  const daysOfSending = [ ...Array(7).keys(), ];
  const hours = [ ...Array(24).keys(), ];

  const intervalTranslations = [ 'denně', 'týdně', 'měsíčně', ];
  const daysOfSendingTranslations = [ 'neděle', 'pondělí', 'úterý', 'středa', 'čtvrtek', 'pátek', 'sobota', ];

  return (
    <div className="report-assign-box">
      <span className="ral-title">{props.title}</span>
      <FormRowSimple>
        <div>
          Odesílat
        </div>
        <div>
          <InputSelect
            value={{ id: props.sendInterval, name: intervalTranslations[intervals.indexOf(props.sendInterval)], }}
            options={intervals.map((interval, index) => ({ id: interval, name: intervalTranslations[index], }))}
            onChange={(option) => props.onUpdateSendInterval(option.id)}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
          />
        </div>
      </FormRowSimple>
      <FormRowSimple>
        <div>
          Hodina odeslání
        </div>
        <div>
          <InputSelect
            value={{ id: props.sendIntervalHour, name: props.sendIntervalHour, }}
            options={hours.map((hour) => ({ id: hour, name: hour, }))}
            onChange={(option) => props.onUpdateSendIntervalHour(option.id)}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            maxMenuHeight={120}
          />
        </div>
      </FormRowSimple>
      {props.sendInterval === 'week' && (
        <FormRowSimple>
          <div>
            Den odeslání
          </div>
          <div>
            <InputSelect
              value={{ id: props.sendIntervalDay, name: daysOfSendingTranslations[daysOfSending.indexOf(props.sendIntervalDay)], }}
              options={daysOfSending.map((day, index) => ({ id: day, name: daysOfSendingTranslations[index], }))}
              onChange={(option) => props.onUpdateSendIntervalDay(option.id)}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              maxMenuHeight={120}
            />
          </div>
        </FormRowSimple>
      )}
    </div>
  );
};


export default ReportNotificationGroupInterval;
