import React from 'react';
import { string, object, } from 'prop-types';

import Row from '../../../atoms/Row/Row';
import Col from '../../../atoms/Col/Col';
import RenderValue from './RenderValue';


const GFFieldSet = ({
  // data
  genForm,
  genForm: {
    tree,
  },
  languageId,
  translations,
  nodeId,
}) => {
  const node = tree[nodeId];

  return (
    <Row formGroup>
      <Col>
        <fieldset>
          <legend>{node.texts.title}</legend>
          <RenderValue
            languageId={languageId}
            translations={translations}
            genForm={genForm}
            nodeId={nodeId}
          />
        </fieldset>
      </Col>
    </Row>
  );
};


GFFieldSet.propTypes = {
  translations: object.isRequired,
  languageId: string.isRequired,
  genForm: object.isRequired,
  nodeId: string.isRequired,
};


export default GFFieldSet;
