import React from 'react';
import styled from 'styled-components';
import { string, bool, object, } from 'prop-types';


const StyledLabel = styled.label`
  padding-left: 3px;
  font-weight: 500;
  color: ${(p) => p.theme.grey.t700};
`;

const Label = ({ label, isRequired, style, }) => ((label === '')
  ? null
  : (
    <StyledLabel style={style}>
      {`${label}${isRequired ? ' *' : ''}`}
    </StyledLabel>
  )
);


Label.propTypes = {
  label: string,
  isRequired: bool,
  style: object,
};


Label.defaultProps = {
  label: '',
  isRequired: false,
  style: {},
};


export default Label;
