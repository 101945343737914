const theme = {
  error: {
    t50: '#FFEBEE',
    t100: '#FFCDD2',
    t200: '#EF9A9A',
    t300: '#E57373',
    t400: '#EF5350',
    t500: '#F44336',
    t600: '#E53935',
    t700: '#D32F2F',
    t800: '#C62828',
    t900: '#B71C1C',
  },
  warning: {
    t50: '#FFF3E0',
    t100: '#FFE0B2',
    t200: '#FFCC80',
    t300: '#FFB74D',
    t400: '#FFA726',
    t500: '#FF9800',
    t600: '#FB8C00',
    t700: '#F57C00',
    t800: '#EF6C00',
    t900: '#E65100',
  },
  success: {
    t50: '#E8F5E9',
    t100: '#C8E6C9',
    t200: '#A5D6A7',
    t300: '#81C784',
    t400: '#66BB6A',
    t500: '#4CAF50',
    t600: '#43A047',
    t700: '#388E3C',
    t800: '#2E7D32',
    t900: '#1B5E20',
  },
  white: '#ffffff',
  black: '#000000',
  grey: {
    t50: '#f1f2f7',
    t100: '#dbe0e9',
    t200: '#c5cbd8',
    t300: '#aeb5c7',
    t400: '#9aa3b8',
    t500: '#8892aa',
    t600: '#788398',
    t700: '#666e80',
    t800: '#555b69',
    t900: '#414550',
  },
  primary: {
    t50: '#f0e5f0',
    t100: '#dabddb',
    t200: '#f99c3c',
    t300: '#ab6aac',
    t400: '#994d9b',
    t500: '#88348b',
    t600: '#f57c00',
    t700: '#f57c00',
    t800: '#c16609',
    t900: '#1b1b1b',
  },
  secondary: {
    t50: '#e0f2f1',
    t100: '#b3e0db',
    t200: '#80cbc4',
    t300: '#4db6ac',
    t400: '#26a69a',
    t500: '#009688',
    t600: '#008e80',
    t700: '#008375',
    t800: '#00796b',
    t900: '#006858',
  },
  tertiary: {
    t50: '#e3f2fd',
    t100: '#bbdefb',
    t200: '#90caf9',
    t300: '#64b5f6',
    t400: '#42a5f5',
    t500: '#2196f3',
    t600: '#1e88e5',
    t700: '#2196f3',
    t800: '#1565c0',
    t900: '#0d47a1',
  },
  sidebar: {
    color: '#c3c3c3',
    background: '#383838',
  },
  common: {
    padding: '1.5rem',
    paddingHalf: '0.75rem',
    shadow: '0px 0px 30px 0px rgba(20, 20, 20, 0.25)',
  },
  formGroup: {
    marginBottom: '1.5rem',
  },
  input: {
    borderRadius: '2px',
    borderWidthTopBottom: '1px',
    borderWidthLeftRight: '1px',
    borderStyle: 'solid',
    fontFamily: 'Arial, Roboto, sans-serif',
  },
};

export default theme;
