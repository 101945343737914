import styled from 'styled-components';


const ButtonGrp = styled.div`
  display: inline-flex;
  align-items: center;

  ${(p) => p.block && `
    display: flex;
    flex-wrap: wrap;
  `}

  button:not(:last-child) {
    margin-right: 0.5rem;
  }

  .buttonGrp--right {
    margin-left: auto; 
  };
`;


export default ButtonGrp;
