import React from 'react';

import StyledSvg from '../styles/StyledSvg';

const Notification = ({ ...props }) => (
  <StyledSvg viewBox="0 0 512 512" {...props}>
    <path d="M438.21,415.136l-24.977-46.878V252.489c0-71.565-48.062-132.111-113.603-151.069V43.632C299.631,19.573,280.059,0,256,0
      c-24.059,0-43.631,19.573-43.631,43.632v57.789C146.83,120.38,98.766,180.925,98.766,252.49v115.768l-24.976,46.878
      c-4.81,9.027-4.538,19.652,0.726,28.423c5.265,8.769,14.514,14.005,24.743,14.005h99.158C200.106,487.865,225.283,512,256,512
      c30.716,0,55.894-24.135,57.583-54.436h99.159c10.228,0,19.477-5.236,24.743-14.005
      C442.747,434.788,443.019,424.163,438.21,415.136z M235.455,43.632c0-11.33,9.216-20.546,20.544-20.546
      s20.544,9.216,20.544,20.546V96.6c-6.726-0.882-13.583-1.342-20.544-1.342c-6.963,0-13.819,0.461-20.544,1.342V43.632z
       M121.853,252.489c0-73.968,60.178-134.147,134.147-134.147s134.147,60.179,134.147,134.147v106.217H121.853V252.489z
       M256,488.914c-17.981,0-32.795-13.792-34.435-31.35h68.872C288.795,475.122,273.979,488.914,256,488.914z M417.69,431.676
      c-0.506,0.843-1.999,2.802-4.948,2.802H99.259c-2.948,0-4.443-1.959-4.948-2.802c-0.506-0.841-1.532-3.082-0.145-5.685
      l23.549-44.199h276.572l23.549,44.199C419.221,428.594,418.194,430.834,417.69,431.676z"
    />
    <path d="M256,149.113c-57.002,0-103.378,46.376-103.378,103.378c0,6.374,5.168,11.543,11.543,11.543
      c6.375,0,11.543-5.169,11.543-11.543c0-44.272,36.019-80.292,80.292-80.292c6.375,0,11.543-5.169,11.543-11.543
      C267.543,154.28,262.375,149.113,256,149.113z"
    />
  </StyledSvg>
);

export default Notification;
