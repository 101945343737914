import { RESERVATION_TYPES, } from '../../../globals';
import { clearSecondsAndMilliseconds, setDateTodayMaxTime, } from '../../../logic/date';
import { QUERY_FETCH_RESERVATION_TIME_BLOCKS, } from '../gql/queries';

export const SC_ENABLED = true;
const { REACT_APP_REST, } = process.env;

/**
 * Dropdown - Reservation Types
 */
export const getReservationTypesOptions = (translations, allOptions = true) => {
  const reservationTypes = [
    {
      id: RESERVATION_TYPES.DAY,
      name: translations.dropDownOptions.transportationReservationTypeDropDown.registration,
    },
    {
      id: RESERVATION_TYPES.INTERVAL,
      name: translations.dropDownOptions.transportationReservationTypeDropDown.reservationTimeWindow, // eslint-disable-line
    },
    {
      id: RESERVATION_TYPES.INTERVAL_PLATFORM,
      name: translations.dropDownOptions.transportationReservationTypeDropDown.reservationTimeWindowWithPlatform, // eslint-disable-line
    },
  ];

  if (allOptions) {
    return reservationTypes;
  }

  return [ reservationTypes[0], ];
};


/**
 * Map values to mutation Create Transportation
 */
export const parseCreateTransportationMutationVariables = (reservationForms, detailValues, files) => {
  let fillDate = false;
  let filTimeFromTo = false;
  let fillPlatform = false;

  return {
    // Reservation
    reservations: reservationForms.map((item) => {
      const { values, } = item;
      if (values.resReservationType) {
        fillDate = values.resReservationType.id === RESERVATION_TYPES.DAY;
        filTimeFromTo = values.resReservationType.id === RESERVATION_TYPES.INTERVAL_PLATFORM
          || values.resReservationType.id === RESERVATION_TYPES.INTERVAL;
        fillPlatform = values.resReservationType.id === RESERVATION_TYPES.INTERVAL_PLATFORM;
      }

      return ({
        no: values.resNo,
        transportationTypeId: values.resTransportationType
          ? values.resTransportationType.id
          : undefined,
        reservationTypeId: values.resReservationType
          ? values.resReservationType.id
          : undefined,
        platformId: fillPlatform && values.resPlatform
          ? values.resPlatform.id
          : undefined,
        date: fillDate ? values.resDate : undefined,
        timeFrom: filTimeFromTo ? clearSecondsAndMilliseconds(values.resTimeFrom) : undefined,
        timeTo: filTimeFromTo ? clearSecondsAndMilliseconds(values.resTimeTo) : undefined,
        note: values.note,
      });
    }),

    // Company
    company: {
      name: detailValues.compName,
      identificationNumber: detailValues.compIdentificationNumber,
      vatNumber: detailValues.compVatNumber,
      street: detailValues.compStreet,
      city: detailValues.compCity,
      zip: detailValues.compZip,
      state: detailValues.compState,
      contactPerson: detailValues.compContactPerson,
      email: detailValues.compEmail,
      phoneNumber: detailValues.compPhoneNumber,
      id: detailValues.compId ? detailValues.compId.id : null,
    },

    // Driver
    driver: {
      name: detailValues.driverName,
      phoneNumber: detailValues.driverPhoneNumber,
      languageId: detailValues.languageId ? detailValues.languageId.id : undefined,
      nationality: detailValues.driverNationality,
    },

    // Passenger
    passenger: {
      name: detailValues.passengerName,
      phoneNumber: detailValues.passengerPhoneNumber,
      nationality: detailValues.passengerNationality,
    },

    // Truck
    truck: {
      registrationNumber: detailValues.truckRegistrationNumber,
    },

    // trailer
    trailer: {
      registrationNumber: detailValues.trailerRegistrationNumber,
    },

    extra: {
      uuid: detailValues.uuid,
      isAdr: detailValues.isAdr,
      isMultipleOrder: detailValues.isMultipleOrder,
      multipleOrderExpiration: detailValues.multipleOrderExpiration ? setDateTodayMaxTime(detailValues.multipleOrderExpiration) : undefined,
      adrDriverNo: detailValues.adrDriverFileNo,
      adrDriverDate: detailValues.adrDriverFileDate,
      adrPassengerNo: detailValues.adrPassengerFileNo,
      adrPassengerDate: detailValues.adrPassengerFileDate,
      adrTruckNo: detailValues.adrTruckFileNo,
      adrTruckDate: detailValues.adrTruckFileDate,
      adrTrailerNo: detailValues.adrTrailerFileNo,
      adrTrailerDate: detailValues.adrTrailerFileDate,
      adrContainerNo: detailValues.adrContainerFileNo,
      adrContainerDate: detailValues.adrContainerFileDate,
      ...files,
    },

    // other
    autoStartOnCombinedTransportation: detailValues.autoStartOnCombinedTransportation,
  };
};


/**
 * Map values to mutation Update Transportation
 */
export const parseTransportationMutationVariables = (values, uploadedFiles) => {
  let fillDate = false;
  let filTimeFromTo = false;
  let fillPlatform = false;

  if (values.resReservationType) {
    fillDate = values.resReservationType.id === RESERVATION_TYPES.DAY;
    filTimeFromTo = values.resReservationType.id === RESERVATION_TYPES.INTERVAL_PLATFORM
      || values.resReservationType.id === RESERVATION_TYPES.INTERVAL;
    fillPlatform = values.resReservationType.id === RESERVATION_TYPES.INTERVAL_PLATFORM;
  }

  return {
    // Reservation
    reservation: {
      no: values.resNo,
      transportationTypeId: values.resTransportationType
        ? values.resTransportationType.id
        : undefined,
      reservationTypeId: values.resReservationType
        ? values.resReservationType.id
        : undefined,
      platformId: fillPlatform && values.resPlatform
        ? values.resPlatform.id
        : undefined,
      date: fillDate ? values.resDate : undefined,
      timeFrom: filTimeFromTo ? clearSecondsAndMilliseconds(values.resTimeFrom) : undefined,
      timeTo: filTimeFromTo ? clearSecondsAndMilliseconds(values.resTimeTo) : undefined,
      note: values.note,
    },

    // Company
    company: {
      name: values.compName,
      identificationNumber: values.compIdentificationNumber,
      vatNumber: values.compVatNumber,
      street: values.compStreet,
      city: values.compCity,
      zip: values.compZip,
      state: values.compState,
      contactPerson: values.compContactPerson,
      email: values.compEmail,
      phoneNumber: values.compPhoneNumber,
      id: values.compId ? values.compId.id : null,
    },

    // Driver
    driver: {
      name: values.driverName,
      phoneNumber: values.driverPhoneNumber,
      nationality: values.driverNationality,
      languageId: values.languageId ? values.languageId.id : undefined,
    },

    // Passenger
    passenger: {
      name: values.passengerName,
      phoneNumber: values.passengerPhoneNumber,
      nationality: values.passengerNationality,
    },

    // Truck
    truck: {
      registrationNumber: values.truckRegistrationNumber,
    },

    // trailer
    trailer: {
      registrationNumber: values.trailerRegistrationNumber,
    },

    extra: {
      uuid: values.uuid,
      isAdr: values.isAdr,
      isMultipleOrder: values.isMultipleOrder,
      multipleOrderExpiration: values.multipleOrderExpiration ? setDateTodayMaxTime(values.multipleOrderExpiration) : undefined,
      adrDriverNo: values.adrDriverNo,
      adrDriverDate: values.adrDriverDate,
      adrPassengerNo: values.adrPassengerNo,
      adrPassengerDate: values.adrPassengerDate,
      adrTruckNo: values.adrTruckNo,
      adrTruckDate: values.adrTruckDate,
      adrTrailerNo: values.adrTrailerNo,
      adrTrailerDate: values.adrTrailerDate,
      adrContainerNo: values.adrContainerNo,
      adrContainerDate: values.adrContainerDate,
      ...uploadedFiles,
    },
    // products
    products: (Array.isArray(values.products) ? values.products : []).map((p) => ({
      id: p.id,
      transportationId: p.transportationId,
      transportationTypeId: p.transportationTypeId,
      order: p.order,
      name: p.name,
      destination: p.destination,
      quantity: p.quantity,
      unit: p.unit,
      package: p.package,
      adrClass: p.adrClass,
      un: p.un,
      weightIn: parseFloat(p.weightIn) || 0,
      weightOut: parseFloat(p.weightOut) || 0,
    })),
  };
};


/**
 * Reservation Types - Badge Color
 */
export const getReservationTypeColor = (reservationTypeId) => {
  const colors = {
    default: {
      color: undefined,
      background: undefined,
    },
    1: {
      color: 'white',
      background: '#e49b15',
    },
    2: {
      color: 'white',
      background: '#18a7a0',
    },
    3: {
      color: 'white',
      background: '#249bbf',
    },
  };

  if (Object.prototype.hasOwnProperty.call(colors, reservationTypeId)) {
    return colors[reservationTypeId];
  }
  return colors.default;
};

export const isLoadingType = (transportationTypeId) => [ 1, 2, 5, 6, 8, 9, 10, 15, 16, 19, 20, 23, 24, 27, 28, 31, 33, 35, 37, 39, 41, 43, 45, 46, 49, 50, 53, 54, 57, 58, 61, 62, 65, 67, 69, 71, 73, 75, 77, 79, 81, 83, 87, 89, 91, 93, 95, ].includes(parseInt(transportationTypeId, 10));

export const isTypeWOReservation = (transportationTypeId) => [ 12, 13, ].includes(parseInt(transportationTypeId, 10));

export const isCollectionService = (serviceType) => serviceType === 'COLLECTION_SERVICE';

export const hasSomeReservationFormLoadingType = (reservationForms) => reservationForms.some(
  (rf) => (rf.values.resTransportationType ? isLoadingType(rf.values.resTransportationType.id) : false)
);


export const ATTACHMENTS = [ 'weightListFile', 'crmFile', 'deliveryListFile', 'invoiceFile', 'adrDriverFile', 'adrPassengerFile', 'adrTruckFile', 'adrTrailerFile', 'adrContainerFile', ];
export const ADR_ATTACHMENTS = [ 'adrDriverFile', 'adrPassengerFile', 'adrTruckFile', 'adrTrailerFile', 'adrContainerFile', ];

export const PRODUCT_FIELDS = [
  'id',
  'transportationTypeId',
  'transportationTypeName',
  'order',
  'name',
  'destination',
  'quantity',
  'unit',
  'package',
  'adrClass',
  'un',
  'sample',
  'imoDocument',
  'weightIn',
  'weightInUnit',
  'weightOut',
  'weightOutUnit',
];

export const uploadFiles = async (detailForm, uuid) => {
  const formData = new FormData();
  for (let i = 0; i < ATTACHMENTS.length; i++) {
    const attachment = ATTACHMENTS[i];
    if (detailForm.values[`${attachment}Checkbox`] && detailForm.values[`${attachment}File`]) {
      formData.append(attachment, detailForm.values[`${attachment}File`]);
    }
  }
  if (Array.from(formData.keys()).length === 0) {
    return {};
  }

  const response = await fetch(`${REACT_APP_REST}/file?uuid=${uuid}`, {
    method: 'POST',
    body: formData,
  });

  const result = await response.json();
  return result.data;
};

export const getReservationTimeBlocks = async (client, transportationTypeId, date) => {
  const result = await client.query({
    query: QUERY_FETCH_RESERVATION_TIME_BLOCKS,
    fetchPolicy: 'network-only',
    variables: { transportationTypeId, date, },
  });
  return result.data.fetchReservationTimeBlocks;
};
