import gql from 'graphql-tag';


export const QUERY_LOGIN_TRANSLATION = gql`
  query Dictionary($languageId: ID) {
    dictionary(languageId: $languageId) {
      dictionary {
        centralApp {
          form {
            username
            password
          }
          common {
            appTitle
          }
          authentication {
            login
            loginInfoTitle
            loginInfoText
          }
        }
      }
    }
  }
`;


export const TRANSLATIONS_NOT_FOUND = gql`
  query Dictionary($languageId: ID) {
    dictionary(languageId: $languageId) {
      dictionary {
        centralApp {
          notFound {
            infoTitle
            infoText
            linkText
          }
        }
      }
    }
  }
`;


export const TRANSLATIONS_NOT_AUTHORIZED = gql`
  query Dictionary($languageId: ID) {
    dictionary(languageId: $languageId) {
      dictionary {
        centralApp {
          notAuthorized {
            infoTitle
            infoText
            linkText
          }
        }
      }
    }
  }
`;
