import styled from 'styled-components';

import media from '../../../styles/media';


const StyledModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 10;
  background: transparent;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  &:focus {
    outline: none;
  }

  ${(p) => p.fadeIn && `
    background-color: rgba(0, 0, 0, 0.64);
    transition: background-color linear 0.15s;
  `}

  .modal--content {
    background-color: #FFFFFF;
    border-radius: ${(p) => p.theme.input.borderRadius};
    box-shadow: ${(p) => p.theme.common.shadow};
    
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3rem;

    transform: none;
    ${(p) => p.fadeIn && `
      transition: transform 0.4s ease;
      transform: translate(0, 5rem);
    `}

    ${media.XS`
      width: 95vw;
      max-width: 540px;
    `}

    ${media.SM`
      width: 540px;
    `}

    ${(p) => (p.size === 'MD' || p.size === 'LG' || p.size === 'XL') && media.MD`
      width: 720px;
    `}

    ${(p) => (p.size === 'LG' || p.size === 'XL') && media.LG`
      width: 960px;
    `}

    ${(p) => p.size === 'XL' && media.HD`
      width: 1180px;
    `}
  }
`;


export default StyledModal;
