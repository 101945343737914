import React from 'react';

import StyledSvg from './styles/StyledSvg';


const Timer = ({ ...props }) => (
  <StyledSvg viewBox="0 0 283.000000 276.000000" {...props}>
    <g transform="translate(0.000000,276.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
      <path d="M1130 2488 c-54 -32 -60 -115 -13 -160 21 -20 37 -23 119 -26 l94 -4
        0 -109 0 -109 -27 -5 c-168 -34 -293 -85 -401 -162 -172 -123 -286 -284 -349
        -494 -26 -86 -28 -108 -28 -254 0 -145 2 -168 27 -251 46 -152 111 -267 212
        -373 122 -127 274 -220 433 -263 115 -31 344 -31 465 0 235 59 454 231 574
        452 75 137 98 239 98 440 1 144 -2 175 -22 245 -25 87 -67 180 -107 239 l-26
        38 58 64 c108 120 119 145 88 204 -18 35 -43 50 -82 50 -36 0 -57 -15 -129
        -90 -58 -61 -82 -72 -104 -45 -22 27 -144 102 -218 134 -40 18 -111 40 -159
        51 -47 10 -90 23 -95 28 -5 5 -7 53 -6 108 l3 99 92 5 c78 4 95 8 116 28 45
        42 43 119 -4 154 -22 17 -50 19 -303 21 -245 2 -282 0 -306 -15z m462 -633
        c255 -53 475 -273 532 -532 40 -180 3 -397 -92 -538 -116 -172 -286 -286 -482
        -321 -28 -5 -195 -6 -225 -1 -286 46 -524 267 -590 547 -28 121 -13 325 31
        408 8 15 14 31 14 36 0 27 99 162 164 223 103 99 212 156 347 182 72 14 222
        12 301 -4z"
      />
      <path d="M1106 1595 c-70 -25 -146 -122 -146 -187 0 -34 51 -78 91 -78 23 0
        36 10 64 49 52 72 90 78 120 20 20 -38 16 -46 -113 -194 -136 -156 -154 -192
        -160 -316 -7 -155 11 -169 221 -169 151 0 191 10 212 51 21 40 19 64 -9 96
        -23 27 -28 28 -133 31 -59 2 -113 5 -120 7 -25 8 10 66 119 192 60 70 119 146
        131 168 43 80 17 226 -50 287 -57 51 -152 70 -227 43z"
      />
      <path d="M1630 1589 c-44 -18 -90 -59 -111 -102 -21 -39 -22 -55 -21 -325 1
        -283 1 -284 26 -326 14 -24 44 -56 68 -72 39 -26 51 -29 123 -29 72 0 84 3
        122 29 29 19 51 46 70 85 28 56 28 57 30 291 3 260 -6 325 -50 384 -38 49
        -100 76 -173 75 -32 0 -70 -5 -84 -10z m130 -178 c6 -12 10 -110 10 -250 0
        -195 -2 -232 -16 -245 -18 -18 -47 -20 -75 -6 -18 10 -19 24 -19 253 0 176 3
        246 12 255 20 20 76 15 88 -7z"
      />
    </g>
  </StyledSvg>
);

export default Timer;
