import React, { Fragment, } from 'react';
import {
  shape, string, func, object, arrayOf,
} from 'prop-types';

import { Query, } from 'react-apollo';
import FormRow from '../../../../components/Form/FormRow';
import Input from '../../../../atoms/Input/Input';
import InputSelect from '../../../../atoms/InputSelect/InputSelect';
import { QUERY_ALL_COMPANIES, } from '../../../Transportation/gql/queries';
import { getRoles, } from './role';


const UserForm = ({
  form,
  translations,
  values,
  onChange,
  languages,
}) => (
  <Fragment>

    <FormRow
      label={translations.form.email}
      input={form.email}
    >
      <Input
        type="text"
        value={values.email}
        onChange={(e) => onChange(form.email.name, e.target.value)}
        status={form.email.status}
      />
    </FormRow>

    <FormRow
      label={translations.form.phoneNumber}
      input={form.phoneNum}
    >
      <Input
        type="text"
        value={values.phoneNum}
        onChange={(e) => onChange(form.phoneNum.name, e.target.value)}
        status={form.phoneNum.status}
      />
    </FormRow>

    <FormRow
      label={translations.form.company}
      input={form.company}
    >
      <Query query={QUERY_ALL_COMPANIES} fetchPolicy="network-only">
        {({ data, loading, error, }) => (
          <InputSelect
            value={values.company}
            options={data.fetchAllCompanies ? (() => {
              const collator = new Intl.Collator();
              return data.fetchAllCompanies
                .sort((company1, company2) => collator.compare(company1.name, company2.name));
            })() : []}
            onChange={(options) => onChange(form.company.name, options === null ? null : options)}
            status={form.company.status}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option}
            placeholder={translations.common.select}
            loading={loading}
            error={error}
          />
        )}
      </Query>
    </FormRow>

    <FormRow
      label={translations.form.roles}
      input={form.role}
    >
      <InputSelect
        value={values.role}
        options={getRoles(translations)}
        onChange={(options) => onChange(form.role.name, options === null ? [] : options)}
        status={form.role.status}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        placeholder={translations.common.select}
      />
    </FormRow>

    <FormRow
      label={translations.form.language}
      input={form.role}
    >
      <InputSelect
        value={values.language}
        options={languages}
        onChange={(options) => onChange(form.language.name, options === null ? [] : options)}
        status={form.language.status}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        placeholder={translations.common.select}
      />
    </FormRow>

  </Fragment>
);


UserForm.propTypes = {
  translations: object.isRequired,
  form: shape({
    email: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    phoneNum: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    company: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    role: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    language: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
  }).isRequired,
  values: shape({
    email: string,
    phoneNum: string,
    company: object,
    role: object,
    language: object,
  }).isRequired,
  onChange: func.isRequired,
  languages: arrayOf(shape({
    id: string,
    code: string,
    name: string,
  })).isRequired,
};


export default UserForm;
