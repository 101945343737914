import React from 'react';

import StyledSvg from '../styles/StyledSvg';


const OneWay = ({ ...props }) => (
  <StyledSvg viewBox="0 0 336 336" {...props}>
    <path
      d="M168,336a169.241,169.241,0,0,1-33.858-3.413A167.081,167.081,0,0,1,74.07,307.308,168.49,168.49,0,0,1,13.2,233.393a167.15,167.15,0,0,1-9.789-31.535,169.637,169.637,0,0,1,0-67.716A167.08,167.08,0,0,1,28.692,74.07,168.491,168.491,0,0,1,102.607,13.2a167.153,167.153,0,0,1,31.535-9.789,169.637,169.637,0,0,1,67.716,0A167.079,167.079,0,0,1,261.93,28.692,168.49,168.49,0,0,1,322.8,102.607a167.155,167.155,0,0,1,9.789,31.535,169.638,169.638,0,0,1,0,67.716,167.08,167.08,0,0,1-25.278,60.072A168.489,168.489,0,0,1,233.393,322.8a167.152,167.152,0,0,1-31.535,9.789A169.24,169.24,0,0,1,168,336ZM58,133a12.013,12.013,0,0,0-12,12v47a12.014,12.014,0,0,0,12,12H279a12.014,12.014,0,0,0,12-12V145a12.013,12.013,0,0,0-12-12Z"
    />
  </StyledSvg>
);

export default OneWay;
