import React from 'react';
import { string, } from 'prop-types';

import { QUERY_GATE_RECORDS, } from '../../gql/queries';
import FilteredTable from '../../../../components/FilteredTable/FilteredTable';
import TableLogic from './TableLogic';
import { setDateTodayMaxTime, setDateTodayMinTime, } from '../../../../logic/date';


const INIT_FILTER = {
  offset: 0,
  limit: 20,
  sortBy: '',
  order: '',
  params: {
    timeFrom: null,
    timeTo: null,
    registrationNumber: '',
    gateVehicleGroupId: null,
    state: null,
  },
};


const TabInArea = ({
  languageId,
  ...rest
}) => (
  <FilteredTable
    initFilter={INIT_FILTER}
    query={QUERY_GATE_RECORDS}
    filterToVariables={(filter) => ({
      ...filter,
      languageId,
      section: 'inArea',
      params: {
        timeFrom: filter.params.timeFrom ? setDateTodayMinTime(filter.params.timeFrom) : null,
        timeTo: filter.params.timeTo ? setDateTodayMaxTime(filter.params.timeTo) : null,
        registrationNumber: filter.params.registrationNumber
          ? filter.params.registrationNumber
          : null,
        transportationStateId: filter.params.state ? filter.params.state.id : null,
        gateVehicleGroupId: filter.params.gateVehicleGroupId
          ? filter.params.gateVehicleGroupId.id
          : null,
      },
    })}
  >
    {(tableData) => (
      <TableLogic
        languageId={languageId}
        {...tableData}
        {...rest}
      />
    )}
  </FilteredTable>
);


TabInArea.propTypes = {
  languageId: string.isRequired,
};


export default TabInArea;
