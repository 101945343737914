import React from 'react';
import { object, func, string, } from 'prop-types';

import { withErrors, } from '../../logic/errorManager/withErrors';

import StyledPart from './styles/StyledPart';


const PartError = ({
  error,
  msg,
  translateGraphQLError,
  ...rest
}) => {
  const parsedError = translateGraphQLError(error);

  return (
    <StyledPart error {...rest}>
      <span className="parts-error noSelect">
        {msg || parsedError}
      </span>
    </StyledPart>
  );
};


PartError.propTypes = {
  error: object,
  msg: string,
  translateGraphQLError: func.isRequired,
};


PartError.defaultProps = {
  msg: undefined,
  error: undefined,
};


export default withErrors(PartError);
