import React from 'react';
import { object, } from 'prop-types';
import { NavLink, } from 'react-router-dom';

import { TRANSLATIONS_NOT_AUTHORIZED, } from '../gql/translations';
import { withTranslations, } from '../../../logic/translations/withTranslations';

import StyledNotAuthorized from '../components/NotAuthorized/StyledNotAuthorized';
import LockClosed from '../../../styles/icons/LockClosed';


const NotAuthorizedPage = ({ translations, }) => (
  <StyledNotAuthorized>

    <div className="notAuthorized">
      <div className="notAuthorized--lock">
        <LockClosed />
      </div>
      <div className="notAuthorized--info">
        <div className="notAuthorized--info-title">
          {translations.notAuthorized.infoTitle}
        </div>
        <div className="notAuthorized--info-text">
          {translations.notAuthorized.infoText}
        </div>
      </div>
    </div>

    <div className="notAuthorized--redirect">
      <NavLink
        className="notAuthorized--redirect-link"
        to="/"
      >
        {translations.notAuthorized.linkText}
      </NavLink>
    </div>

  </StyledNotAuthorized>
);


NotAuthorizedPage.propTypes = {
  translations: object.isRequired,
};


export default withTranslations(TRANSLATIONS_NOT_AUTHORIZED)(NotAuthorizedPage);
