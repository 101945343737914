import React from 'react';

import Context from './Context';


export const withNotifications = (Comp) => {
  const Receiver = (props) => (
    <Context.Consumer>
      {(contexts) => <Comp {...props} {...contexts} />}
    </Context.Consumer>
  );

  return Receiver;
};
