import React, { Fragment, } from 'react';
import {
  arrayOf, func, object, string,
} from 'prop-types';
import { Mutation, } from 'react-apollo';

import { MUTATION_SEND_SMS, } from '../../../gql/mutations';
import Button from '../../../../../atoms/Button/Button';
import ButtonLoader from '../../../../../atoms/Button/ButtonLoader';
import Row from '../../../../../atoms/Row/Row';
import Col from '../../../../../atoms/Col/Col';
import FormError from '../../../../../components/Form/FormError';
import SendSmsForm from './SendSmsForm';
import FormBackground from '../../../../../components/Form/FormBackground';
import { parseDateToHuman, } from '../../../../../logic/date';

const renderSms = (sms) => {
  switch (sms.type) {
    case 'inbound':
      return (
        <div style={{ display: 'inline-block', float: 'left', margin: '8px', }}>
          <div style={{
            color: '#6f6f6f', fontSize: '0.775rem', fontWeight: 'bold', textAlign: 'left',
          }}
          >
            {parseDateToHuman(new Date(sms.createdAt))}
          </div>
          <div style={{ background: '#ccc', padding: '10px', }}>
            {sms.text}
          </div>
        </div>
      );
    case 'outbound':
      return (
        <div style={{ display: 'inline-block', float: 'right', margin: '8px', }}>
          <div style={{
            color: '#6f6f6f', fontSize: '0.775rem', fontWeight: 'bold', textAlign: 'right',
          }}
          >
            {parseDateToHuman(new Date(sms.createdAt))}
          </div>
          <div style={{ background: '#2196f3', color: 'white', padding: '10px', }}>
            {sms.text}
          </div>
        </div>
      );
    default:
      return '';
  }
};


const renderChat = (smsData) => (
  smsData.map((sms) => (
    <div key={sms.id} style={{ width: '100%', display: 'table', }}>
      {renderSms(sms)}
    </div>
  ))
);


const ModalSmsChatView = ({
  // data
  smsData,
  detailForm,
  translations,
  languageId,
  // methods
  onChange,
  onToggle,
  onCreate,
  onCreateError,
  onCreateComplete,
}) => (
  <FormBackground>
    <Fragment>
      <div style={{
        height: '400px',
        width: '100%',
        background: 'white',
        border: '1px solid #ccc',
        overflow: 'auto',
        marginBottom: '2em',
        display: 'flex',
        flexDirection: 'column-reverse',
      }}
      >
        {renderChat(smsData)}
      </div>
      <SendSmsForm
        {...detailForm}
        languageId={languageId}
        translations={translations}
        onChange={onChange}
      />

      <Mutation
        mutation={MUTATION_SEND_SMS}
        onCompleted={onCreateComplete}
        onError={onCreateError}
      >
        {(mutate, { loading, error, }) => (
          <Fragment>

            <FormError
              isValid={detailForm.isValid}
              error={error}
            />

            <Row>

              <Col XS={12} SM={12}>
                <Button
                  shape="outline"
                  onClick={onToggle}
                >
                  {translations.common.back}
                </Button>
              </Col>

              <Col XS={12} SM={12} textAlign="right">
                <ButtonLoader
                  color="success"
                  onClick={() => onCreate(mutate)}
                  disabled={!detailForm.isValid}
                  isLoading={loading}
                >
                  {translations.form.sendSms}
                </ButtonLoader>
              </Col>
            </Row>

          </Fragment>
        )}
      </Mutation>
    </Fragment>
  </FormBackground>
);


ModalSmsChatView.propTypes = {
  smsData: arrayOf(object).isRequired,
  translations: object.isRequired,
  languageId: string.isRequired,
  detailForm: object.isRequired,
  onChange: func.isRequired,
  onToggle: func.isRequired,
  onCreate: func.isRequired,
  onCreateError: func.isRequired,
  onCreateComplete: func.isRequired,
};


export default ModalSmsChatView;
