import gql from 'graphql-tag';


export const QUERY_GENERIC_FORM_DIAL = gql`
  query FetchGenericFormDial($languageId: ID!, $genericFormDialId: ID!) {
    fetchGenericFormDial(languageId: $languageId, genericFormDialId: $genericFormDialId,) {
      id
      name
    }
  }
`;


export const TRANSLATION_GEN_FORM = gql`
  query Dictionary($languageId: ID) {
    dictionary(languageId: $languageId) {
      dictionary {
        centralApp {
          common {
            yes
            no
          }
        }
      }
    }
  }
`;
