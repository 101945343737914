import React from 'react';
import {
  bool, arrayOf, node, number, oneOfType, func, string,
} from 'prop-types';

import Arrow from '../../styles/icons/Arrow';


const SidebarCollapseTitle = ({
  level, children, isOpen, onToggleCollapse, toggleName,
}) => (
  <div
    role="none"
    className={`sidebar--item sidebar--item-level-${level}`}
    onClick={() => onToggleCollapse(toggleName)}
  >
    {children}
    <span
      className={`sidebar--item-collapse-icon ${isOpen ? 'sidebar--item-collapse-icon-open' : 'sidebar--item-collapse-icon-close'}`}
    >
      <Arrow />
    </span>
  </div>
);


SidebarCollapseTitle.propTypes = {
  isOpen: bool.isRequired,
  onToggleCollapse: func.isRequired,
  toggleName: string.isRequired,
  level: number,
  children: oneOfType([
    arrayOf(node),
    node,
  ]).isRequired,
};

SidebarCollapseTitle.defaultProps = {
  level: 0,
};

export default SidebarCollapseTitle;
