import React, { Component, } from 'react';
import {
  shape, string, object, func, bool,
} from 'prop-types';

import { SUBSCRIPTION_PLATFORM, } from '../../../gql/subscriptions';
import { pipe, } from '../../../../../logic/utils';
import { withErrors, } from '../../../../../logic/errorManager/withErrors';
import { withNotifications, } from '../../../../../logic/notifications/withNotifications';
import PlatformView from './PlatformView';


class Platform extends Component {
  constructor(props) {
    super(props);

    this.unsubscribe = null;
    this.notificationOpen = true;
  }


  componentDidMount() {
    const { platform, languageId, subscribeToMore, } = this.props;

    this.unsubscribe = subscribeToMore({
      document: SUBSCRIPTION_PLATFORM,
      variables: {
        platformId: platform.id,
        languageId,
      },
      updateQuery: (prev, { subscriptionData, }) => {
        if (!subscriptionData.data) return null;
        const newData = subscriptionData.data.storeKeeperPlatform;
        return {
          ...prev,
          fetchCentralPlatforms: prev.fetchCentralPlatforms.map((p) => {
            if (p.id === newData.id) {
              return ({
                ...p,
                ...newData,
              });
            }
            return p;
          }),
        };
      },
    });
  }


  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }


  /**
   * Call Transportation
   */
  handleCallTransportation = (mutationCall, transportationId = undefined) => {
    const { platform, } = this.props;

    mutationCall({
      variables: {
        platformId: platform.id,
        transportationId,
      },
    });
  }

  /**
   * Call Transportation - Completed
   */
  handleCallTransportationCompleted = () => {
    const { translations, addNotification, } = this.props;

    addNotification({
      status: 'success',
      title: translations.centralAppStorekeeper.notifTitleCallSuccess,
    });
  }

  /**
   * Call Transportation - Error
   */
  handleCallTransportationError = (graphQLError) => {
    const { translations, addNotification, translateGraphQLError, } = this.props;

    addNotification({
      status: 'error',
      title: translations.centralAppStorekeeper.notifTitleCallError,
      text: translateGraphQLError(graphQLError),
    });
  }


  /**
   * Set Platform State
   */
  handleSetPlatformState = (mutationOpen) => {
    const { platform, } = this.props;

    this.notificationOpen = !platform.opened;

    mutationOpen({
      variables: {
        platformId: platform.id,
        opened: !platform.opened,
      },
    });
  }

  /**
   * Set Platform State - Completed
   */
  handleSetPlatformStateCompleted = () => {
    const { translations, addNotification, } = this.props;

    addNotification({
      status: 'success',
      title: this.notificationOpen
        ? translations.centralAppStorekeeper.notifTitleSetPlatformOpenSuccess
        : translations.centralAppStorekeeper.notifTitleSetPlatformCloseSuccess,
    });
  }

  /**
   * Set Platform State - Error
   */
  handleSetPlatformStateError = (graphQLError) => {
    const { translations, addNotification, translateGraphQLError, } = this.props;

    addNotification({
      status: 'error',
      title: this.notificationOpen
        ? translations.centralAppStorekeeper.notifTitleSetPlatformOpenError
        : translations.centralAppStorekeeper.notifTitleSetPlatformCloseError,
      text: translateGraphQLError(graphQLError),
    });
  }


  render() {
    const {
      platform,
      languageId,
      translations,
      translationsCommon,
      resources,
    } = this.props;

    const occupied = platform.clearance.length;

    return (
      <PlatformView
        // data
        platform={platform}
        occupied={occupied}
        languageId={languageId}
        translations={translations}
        translationsCommon={translationsCommon}
        resources={resources}
        // functions
        onSetPlatformState={this.handleSetPlatformState}
        onSetPlatformStateCompleted={this.handleSetPlatformStateCompleted}
        onSetPlatformStateError={this.handleSetPlatformStateError}
        onCallTransportation={this.handleCallTransportation}
        onCallTransportationCompleted={this.handleCallTransportationCompleted}
        onCallTransportationError={this.handleCallTransportationError}
      />
    );
  }
}


Platform.propTypes = {
  platform: shape({
    id: string.isRequired,
    opened: bool.isRequired,
  }).isRequired,
  languageId: string.isRequired,
  translations: object.isRequired,
  translationsCommon: object.isRequired,
  resources: object.isRequired,
  subscribeToMore: func.isRequired,
  addNotification: func.isRequired,
  translateGraphQLError: func.isRequired,
};


export default pipe(
  withNotifications,
  withErrors,
)(Platform);
