export const changeArrayElement = (array, index, newElement) => [
  ...(array.slice(0, index)),
  newElement,
  ...(array.slice(index + 1)),
];


export const removeArrayElement = (array, index) => [
  ...(array.slice(0, index)),
  ...(array.slice(index + 1)),
];
