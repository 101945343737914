import React, { Component, } from 'react';
import {
  string, func, object, any,
} from 'prop-types';
import { withApollo, } from 'react-apollo';

import { pipe, } from '../../../../logic/utils';
import { withNotifications, } from '../../../../logic/notifications/withNotifications';
import {
  changeAndValidateInput,
  validateAndMergeWholeForm,
  mergeValidationObjectIntoForm,
} from '../../../../logic/form/common';
import {
  initInputTechnicalCheckForm,
} from '../forms/structure';
import InputTechnicalCheckView from './InputTechnicalCheckView';
import { resizeArray, } from '../../../../logic/array';
import { changeArrayElement, } from '../TransportationCreate/utils';
import {
  parseVariablesForUpdateMutation, getInitialState, getChamberForms, getChamberFillingForms, getFillingProductForms,
} from './utils';


class InputTechnicalCheckLogic extends Component {
  constructor(props) {
    super(props);

    const {
      data,
      translations,
    } = props;


    this.state = getInitialState(data, initInputTechnicalCheckForm, translations);
  }

  /**
   * Form - onChange
   */
  handleChangeForm = (name, value, index) => {
    const {
      detailForm, chamberForms, chamberFillingForms, loadProductForms, chamberFillingInForms,
    } = this.state;

    if (name.startsWith('chamberFillingInForm.')) {
      const form = chamberFillingInForms[index];
      const newFillingProductForm = changeAndValidateInput(form, name.replace('chamberFillingInForm.', ''), value);
      this.setState({
        chamberFillingInForms: changeArrayElement(chamberFillingInForms, index, newFillingProductForm),
      });
      return;
    }

    if (name.startsWith('loadProduct.')) {
      const form = loadProductForms[index];
      const newLoadProductForm = changeAndValidateInput(form, name.replace('loadProduct.', ''), value);
      this.setState({
        loadProductForms: changeArrayElement(loadProductForms, index, newLoadProductForm),
      });
      return;
    }

    if (name.startsWith('chamberFilling.')) {
      const form = chamberFillingForms[index];
      const newChamberFillingForm = changeAndValidateInput(form, name.replace('chamberFilling.', ''), value);
      this.setState({
        chamberFillingForms: changeArrayElement(chamberFillingForms, index, newChamberFillingForm),
      });
      return;
    }

    if (name.startsWith('chambers.')) {
      const form = chamberForms[index];
      const newChamberForm = changeAndValidateInput(form, name.replace('chambers.', ''), value);
      this.setState({
        chamberForms: changeArrayElement(chamberForms, index, newChamberForm),
      });
      return;
    }

    switch (name) {
      case 'chamberCount': {
        const chamberFormValues = resizeArray(chamberForms.map((cf) => cf.values), parseInt(value.id, 10), { volume: '', breakwaterCount: '', });
        this.setState({ chamberForms: getChamberForms(chamberFormValues), });
        break;
      }

      case 'chamberFillingInCount': {
        const chamberFormValues = resizeArray(chamberFillingInForms.map((cf) => cf.values), parseInt(value.id, 10), { product: null, chamberSelect: null, quantity: 0, });
        this.setState({ chamberFillingInForms: getFillingProductForms(chamberFormValues, []), });
        break;
      }

      case 'chamberFilling': {
        const chamberFormValues = resizeArray(chamberForms.map((cf) => cf.values), parseInt(value.id, 10), { volume: '', breakwaterCount: '', });
        this.setState({ chamberForms: getChamberForms(chamberFormValues), });
        break;
      }

      case 'chamberFillingCount': {
        const chamberFillingFormValues = resizeArray(chamberFillingForms.map((cf) => cf.values), parseInt(value.id, 10), { product: '', cleared: false, });
        this.setState({ chamberFillingForms: getChamberFillingForms(chamberFillingFormValues), });
        break;
      }

      default:
        break;
    }

    const newDetailForm = changeAndValidateInput(detailForm, name, value);
    this.setState({ detailForm: newDetailForm, });
  }


  handleUpdateAndClose = (updateMutation) => {
    this.handleUpdate(updateMutation, null);
  }

  handleUpdateAndContinue = (updateMutation) => {
    const { detailForm, } = this.state;

    this.handleUpdate(updateMutation, detailForm.values.pin || '');
  }

  handleUpdate = (updateMutation, pin) => {
    const {
      detailForm, chamberForms, chamberFillingForms, loadProductForms,
      chamberFillingInForms, chamberFillingOutForms, newTechnicalCheckPictures,
    } = this.state;
    const { id, data, } = this.props;
    const newDetailForm = validateAndMergeWholeForm(detailForm);

    if (!newDetailForm.isValid) {
      this.setState({
        detailForm: newDetailForm,
      });
    } else {
      updateMutation({
        variables: {
          pin,
          transportationId: id,
          type: 'in',
          data: parseVariablesForUpdateMutation(
            data,
            detailForm,
            chamberForms,
            chamberFillingForms,
            loadProductForms,
            chamberFillingInForms,
            chamberFillingOutForms,
            newTechnicalCheckPictures
          ),
        },
      });
    }
  }


  handleUpdateComplete = () => {
    const {
      translations,
      onToggle,
      addNotification,
    } = this.props;

    onToggle();
    addNotification({
      status: 'success',
      title: translations.common.updated,
    });
  }


  handleUpdateError = (mutationError) => {
    console.log(mutationError);
    try {
      const { detailForm, } = this.state;
      const { graphQLErrors, } = mutationError;

      if (graphQLErrors && graphQLErrors.length > 0) {
        const { message, extensions, } = graphQLErrors[0];

        switch (message) {
          case 'UNPROCESSABLE_ENTITY': {
            if (extensions.exception.data) {
              this.setState({
                detailForm: mergeValidationObjectIntoForm(detailForm, extensions.exception.data),
              });
            }
            break;
          }

          default: {
            break;
          }
        }
      }
    } catch {
      // continue regardless of error
    }
  }

  render() {
    const {
      detailForm,
      chamberForms,
      chamberFillingForms,
      loadProductForms,
      chamberFillingInForms,
    } = this.state;
    const {
      data, languageId, translations, onToggle, resources,
    } = this.props;

    return (
      <>
        <InputTechnicalCheckView
          // data
          data={data}
          detailForm={detailForm}
          chamberForms={chamberForms}
          chamberFillingForms={chamberFillingForms}
          loadProductForms={loadProductForms}
          chamberFillingInForms={chamberFillingInForms}
          languageId={languageId}
          translations={translations}
          resources={resources}
          // methods
          onToggle={onToggle}
          onChangeForm={this.handleChangeForm}
          onUpdateAndClose={this.handleUpdateAndClose}
          onUpdateAndContinue={this.handleUpdateAndContinue}
          onUpdateComplete={this.handleUpdateComplete}
          onUpdateError={this.handleUpdateError}
        />
      </>
    );
  }
}


InputTechnicalCheckLogic.propTypes = {
  // data
  id: any.isRequired,
  data: object.isRequired,
  translations: object.isRequired,
  languageId: string.isRequired,
  resources: object.isRequired,
  // methods
  addNotification: func.isRequired,
  onToggle: func.isRequired,
};

export default pipe(
  withNotifications,
  withApollo,
)(InputTechnicalCheckLogic);
