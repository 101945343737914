import React from 'react';
import {
  string, func, object,
} from 'prop-types';

import Row from '../../../atoms/Row/Row';
import Col from '../../../atoms/Col/Col';
import GenericForm from '../GenericForm';


const GFFieldSet = ({
  // data
  genForm,
  genForm: {
    tree,
  },
  nodeId,
  languageId,
  // methods
  onChange,
}) => {
  const node = tree[nodeId];

  return (
    <Row formGroup>
      <Col>
        <fieldset>
          <legend>{node.texts.title}</legend>
          <GenericForm
            genForm={genForm}
            nodeId={nodeId}
            languageId={languageId}
            onChange={onChange}
          />
        </fieldset>
      </Col>
    </Row>
  );
};


GFFieldSet.propTypes = {
  genForm: object.isRequired,
  nodeId: string.isRequired,
  languageId: string.isRequired,
  onChange: func.isRequired,
};


export default GFFieldSet;
