/* eslint-disable react/no-array-index-key */

import React, { Component, Fragment, } from 'react';
import {
  arrayOf, func, string, object,
} from 'prop-types';
import { Mutation, } from 'react-apollo';

import { MUTATION_REMOVE_ROLE, } from '../../gql/mutations';
import { TRANSLATIONS_ROLE_DELETE, } from '../../gql/translations';
import { pipe, } from '../../../../logic/utils';
import { withNotifications, } from '../../../../logic/notifications/withNotifications';
import { withTranslations, } from '../../../../logic/translations/withTranslations';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import Modal from '../../../../atoms/Modal/Modal';
import Button from '../../../../atoms/Button/Button';
import ButtonLoader from '../../../../atoms/Button/ButtonLoader';
import FormError from '../../../../components/Form/FormError';

import StyledModalDelete from '../../../../components/modal/styles/StyledModalDelete';
import Error from '../../../../styles/icons/Error';


class RoleDeleteForce extends Component {
  handleDeleteCompleted = () => {
    const { translations, addNotification, onToggle, } = this.props;

    addNotification({
      status: 'success',
      title: translations.common.deleted,
    });
    onToggle();
  }


  render() {
    const {
      // data
      roleId,
      translations,
      users,
      // methods
      onToggle,
    } = this.props;

    return (
      <Modal
        title={translations.settings.removeRoleForceTitle}
        isOpen
        onClose={onToggle}
        size="SM"
      >

        <StyledModalDelete>
          <div className="errorModal--info">
            <span className="errorModal--icon">
              <Error />
            </span>
            <span className="errorModal--text">
              {translations.settings.removeRoleForceText}
            </span>
          </div>

          <h4>{translations.settings.removeRoleForceUsersHeader}</h4>
          <ul className="errorModal--list">
            {users.map((user, index) => (
              <li key={index}>
                {user}
              </li>
            ))}
          </ul>
        </StyledModalDelete>

        <Mutation
          mutation={MUTATION_REMOVE_ROLE}
          variables={{
            id: roleId,
            force: true,
          }}
          onCompleted={this.handleDeleteCompleted}
        >
          {(mutateDelete, { loading, error, }) => (
            <Fragment>
              <FormError
                error={error}
              />
              <Row>
                <Col XS={12} SM={12} textAlign="left">
                  <Button
                    type="button"
                    shape="outline"
                    onClick={onToggle}
                  >
                    {translations.common.back}
                  </Button>
                </Col>
                <Col XS={12} SM={12} textAlign="right">
                  <ButtonLoader
                    type="button"
                    color="error"
                    onClick={mutateDelete}
                    isLoading={loading}
                  >
                    {translations.settings.removeRoleForce}
                  </ButtonLoader>
                </Col>
              </Row>
            </Fragment>
          )}
        </Mutation>
      </Modal>
    );
  }
}


RoleDeleteForce.propTypes = {
  roleId: string.isRequired,
  users: arrayOf(string).isRequired,
  translations: object.isRequired,
  onToggle: func.isRequired,
  addNotification: func.isRequired,
};


export default pipe(
  withNotifications,
  withTranslations(TRANSLATIONS_ROLE_DELETE),
)(RoleDeleteForce);
