import styled from 'styled-components';


const StyledFlowChart = styled.div`
  .transpDetail--flow-start {
    font-weight: 500;
  }
  
  .transpDetail--flow-end {
    font-size: 0.9em;
    color: ${(p) => p.theme.grey.t600};
  }

  .transpDetail--flow-right-toggle {
    margin-left: 0.5rem;
    position: absolute;
  }

  .transpDetail--flow-right-icon {
    transition: transform .2s ease-out;
  }

  .transpDetail--flow-right-icon-open {
    transform: rotate(0deg);
  }

  .transpDetail--flow-right-icon-close {
    transform: rotate(-180deg);
  }
`;


export default StyledFlowChart;
