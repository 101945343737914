import styled from 'styled-components';


const StyledTimelineFilter = styled.ul`
  padding: ${(p) => p.theme.common.padding};
  background: ${(p) => p.theme.grey.t50};

  .timelineFilter--form {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -${(p) => p.theme.common.paddingHalf};
  }

  .timelineFilter--input-select {
    min-width: 10rem;
  }

  .timelineFilter--input{
    padding: 0 ${(p) => p.theme.common.paddingHalf};
    margin-bottom: ${(p) => p.theme.common.paddingHalf};
  }
  
  .timelineFilter--input-date {
    width: 7rem;
    display: inline-block;
  }

  .timeline--input-date-date {
    padding-right: 0.5rem;
  }
`;


export default StyledTimelineFilter;
