import React from 'react';
import { shape, string, object, } from 'prop-types';
import { Switch, Route, } from 'react-router-dom';

import PrivateRoute from '../../../components/PrivateRoute/PrivateRoute';
import SchedulePage from './SchedulePage';
import HistoryPage from './HistoryPage';
import NotFoundPage from '../../Main/pages/NotFoundPage';


const TransportationLayout = ({ match, resources, }) => (
  <Switch>
    <PrivateRoute resource={resources.logistic_plan_read} path={`${match.path}/schedule`} component={SchedulePage} />
    <PrivateRoute resource={resources.logistic_history} path={`${match.path}/history`} component={HistoryPage} />
    <Route component={NotFoundPage} />
  </Switch>
);


TransportationLayout.propTypes = {
  match: shape({
    path: string.isRequired,
  }).isRequired,
  resources: object,
};

TransportationLayout.defaultProps = {
  resources: {},
};


export default TransportationLayout;
