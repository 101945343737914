import React, { Component, } from 'react';
import {
  string, func, object, any,
} from 'prop-types';
import { withApollo, } from 'react-apollo';

import { pipe, } from '../../../../logic/utils';
import { withNotifications, } from '../../../../logic/notifications/withNotifications';
import {
  changeAndValidateInput,
  validateAndMergeWholeForm,
  mergeValidationObjectIntoForm,
} from '../../../../logic/form/common';
import {
  initOutputTechnicalCheckForm,
} from '../forms/structure';
import {
  getFillingProductForms,
  getInitialState,
  parseVariablesForUpdateMutation,
} from '../InputTechnicalCheck/utils';
import OutputTechnicalCheckView from './OutputTechnicalCheckView';
import { resizeArray, } from '../../../../logic/array';
import { changeArrayElement, } from '../TransportationCreate/utils';


class OutputTechnicalCheckLogic extends Component {
  constructor(props) {
    super(props);

    const {
      data,
      translations,
    } = props;


    this.state = getInitialState(data, initOutputTechnicalCheckForm, translations);
  }

  /**
   * Form - onChange
   */
  handleChangeForm = (name, value, index) => {
    const {
      detailForm, newTechnicalCheckPictures, chamberFillingOutForms,
    } = this.state;

    if (name.startsWith('chamberFillingOutForm.')) {
      const form = chamberFillingOutForms[index];
      const newFillingProductForm = changeAndValidateInput(form, name.replace('chamberFillingOutForm.', ''), value);
      this.setState({
        chamberFillingOutForms: changeArrayElement(chamberFillingOutForms, index, newFillingProductForm),
      });
      return;
    }

    switch (name) {
      case 'chamberFillingOutCount': {
        const chamberFormValues = resizeArray(chamberFillingOutForms.map((cf) => cf.values), parseInt(value.id, 10), { product: null, chamberSelect: null, quantity: 0, });
        this.setState({ chamberFillingOutForms: getFillingProductForms(chamberFormValues, []), });

        const newDetailForm = changeAndValidateInput(detailForm, name, value);
        this.setState({ detailForm: newDetailForm, });
        return;
      }

      case 'technicalCheckPictures':
        newTechnicalCheckPictures.push(value);
        this.setState({ newTechnicalCheckPictures, });
        return;

      case 'plombs':
        const { plombs, } = detailForm.values;
        plombs[index] = value;
        const newDetailForm = changeAndValidateInput(detailForm, name, plombs);
        this.setState({ detailForm: newDetailForm, });
        return;

      case 'plombCount': {
        const newDetailForm = changeAndValidateInput(changeAndValidateInput(detailForm, name, value), 'plombs', resizeArray(
          detailForm.values.plombs,
          value,
          ''
        ));
        this.setState({ detailForm: newDetailForm, });
        return;
      }

      case 'hasPlombs': {
        let newDetailForm = changeAndValidateInput(detailForm, name, value);
        if (value.id === false) {
          newDetailForm = changeAndValidateInput(newDetailForm, 'plombCount', 0);
          newDetailForm = changeAndValidateInput(newDetailForm, 'plombs', []);
        }
        this.setState({ detailForm: newDetailForm, });
        return;
      }

      default: {
        const newDetailForm = changeAndValidateInput(detailForm, name, value);
        this.setState({ detailForm: newDetailForm, });
      }
    }
  }


  handleUpdateAndClose = (updateMutation) => {
    this.handleUpdate(updateMutation, null);
  }

  handleUpdateAndContinue = (updateMutation) => {
    const { detailForm, } = this.state;

    this.handleUpdate(updateMutation, detailForm.values.pin || '');
  }

  handleUpdate = (updateMutation, pin) => {
    const {
      detailForm, chamberForms, chamberFillingForms, loadProductForms, chamberFillingInForms, chamberFillingOutForms, newTechnicalCheckPictures,
    } = this.state;
    const { id, data, } = this.props;
    const newDetailForm = validateAndMergeWholeForm(detailForm);

    if (!newDetailForm.isValid) {
      this.setState({
        detailForm: newDetailForm,
      });
    } else {
      updateMutation({
        variables: {
          pin,
          transportationId: id,
          type: 'out',
          data: parseVariablesForUpdateMutation(
            data,
            detailForm,
            chamberForms,
            chamberFillingForms,
            loadProductForms,
            chamberFillingInForms,
            chamberFillingOutForms,
            newTechnicalCheckPictures,
          ),
        },
      });
    }
  }

  handleUpdateComplete = () => {
    const {
      translations,
      onToggle,
      addNotification,
    } = this.props;

    onToggle();
    addNotification({
      status: 'success',
      title: translations.common.updated,
    });
  }


  handleUpdateError = (mutationError) => {
    console.log(mutationError);
    try {
      const { detailForm, } = this.state;
      const { graphQLErrors, } = mutationError;

      if (graphQLErrors && graphQLErrors.length > 0) {
        const { message, extensions, } = graphQLErrors[0];

        switch (message) {
          case 'UNPROCESSABLE_ENTITY': {
            if (extensions.exception.data) {
              this.setState({
                detailForm: mergeValidationObjectIntoForm(detailForm, extensions.exception.data),
              });
            }
            break;
          }

          default: {
            break;
          }
        }
      }
    } catch {
      // continue regardless of error
    }
  }

  render() {
    const {
      detailForm,
      newTechnicalCheckPictures,
      chamberFillingOutForms,
    } = this.state;
    const {
      data, languageId, translations, onToggle, resources,
    } = this.props;

    return (
      <>
        <OutputTechnicalCheckView
          // data
          data={data}
          detailForm={detailForm}
          newTechnicalCheckPictures={newTechnicalCheckPictures}
          languageId={languageId}
          translations={translations}
          resources={resources}
          chamberFillingOutForms={chamberFillingOutForms}
          // methods
          onToggle={onToggle}
          onChangeForm={this.handleChangeForm}
          onUpdateAndClose={this.handleUpdateAndClose}
          onUpdateAndContinue={this.handleUpdateAndContinue}
          onUpdateComplete={this.handleUpdateComplete}
          onUpdateError={this.handleUpdateError}
        />
      </>
    );
  }
}


OutputTechnicalCheckLogic.propTypes = {
  // data
  id: any.isRequired,
  data: object.isRequired,
  translations: object.isRequired,
  languageId: string.isRequired,
  resources: object.isRequired,
  // methods
  addNotification: func.isRequired,
  onToggle: func.isRequired,
};

export default pipe(
  withNotifications,
  withApollo,
)(OutputTechnicalCheckLogic);
