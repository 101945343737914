import React from 'react';
import {
  string, shape, bool,
} from 'prop-types';

import ValueText from '../../../atoms/Values/ValueText';
import FormRowReadOnly from '../../Form/FormRowReadOnly';


const GFText = ({
  input, value,
}) => (
  <FormRowReadOnly label={input.translation.label}>
    <ValueText>
      {value || '-'}
    </ValueText>
  </FormRowReadOnly>
);


GFText.propTypes = {
  input: shape({
    name: string.isRequired,
    type: string.isRequired,
    disabled: bool.isRequired,
    translation: shape({
      label: string,
      placeholder: string,
      help: string,
    }).isRequired,
  }).isRequired,
  value: string,
};

GFText.defaultProps = {
  value: undefined,
};


export default GFText;
