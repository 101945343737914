import styled from 'styled-components';


const StyledTechnicalCheckFiles = styled.div`
  > ul {
    list-style-type: none;
    padding: 0;
    margin-bottom: 2px;
  } 
  
  > ul li {
    position:relative;
    display: inline-block;
    margin-right: 2px;
    margin-bottom: 2px;
    border: 1px solid #464646;
    width: 72px;
    height: 72px;
  }

`;

export default StyledTechnicalCheckFiles;
