import React, { Component, Fragment, } from 'react';
import {
  arrayOf, shape, number, string, object,
} from 'prop-types';

import StatsGridCard from './StatsGridCard';
import RatioChart from '../../../../atoms/RatioChart/RatioChart';
import Button from '../../../../atoms/Button/Button';


const RAMP_COUNT_ON_PAGE = 8;

class RampLoadingCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstDisplayedPage: 0,
      rampCount: props.rampLoadingStats.length,
    };
  }

  /**
   * @param dir {number} +1 or -1
   */
  handlePageChange = (dir) => {
    this.setState((prev) => ({
      ...prev,
      firstDisplayedPage: prev.firstDisplayedPage + dir * RAMP_COUNT_ON_PAGE,
    }));
  }

  render() {
    const { rampLoadingStats, translations, } = this.props;
    const { firstDisplayedPage, rampCount, } = this.state;

    const hasMultiplePages = rampCount > RAMP_COUNT_ON_PAGE;
    const hasPrevPage = firstDisplayedPage > 0;
    const hasNextPage = (firstDisplayedPage + RAMP_COUNT_ON_PAGE) < rampCount;
    const lastDisplayedPage = Math.min(firstDisplayedPage + RAMP_COUNT_ON_PAGE, rampCount);

    return (
      <StatsGridCard>
        <header>
          <span>
            {translations.dashboard.stats.rampLoadingTitle}
            {hasMultiplePages && ` (${firstDisplayedPage + 1}-${lastDisplayedPage} / ${rampCount})`}
          </span>
          {hasMultiplePages && (
            <Fragment>
              <Button
                className="ramp-loading-pagination-button"
                size="xs"
                onClick={() => this.handlePageChange(-1)}
                disabled={!hasPrevPage}
              >
                {'<'}
              </Button>
              <Button
                className="ramp-loading-pagination-button"
                size="xs"
                onClick={() => this.handlePageChange(+1)}
                disabled={!hasNextPage}
              >
                {'>'}
              </Button>
            </Fragment>
          )}
        </header>
        <div className="stats--ramp-loading-card--body">
          <RatioChart
            data={
            rampLoadingStats
              .filter((_, i) => firstDisplayedPage <= i && i < lastDisplayedPage)
              .map(({ loadingRatio, ramp, }) => ({
                ratio: loadingRatio,
                label: ramp,
              }))
            }
            legendDown={translations.dashboard.stats.used}
            legendTop={translations.dashboard.stats.free}
          />
        </div>
      </StatsGridCard>
    );
  }
}

RampLoadingCard.propTypes = {
  rampLoadingStats: arrayOf(shape({
    loadingRatio: number.isRequired,
    ramp: string.isRequired,
  })).isRequired,
  translations: object.isRequired,
};

export default RampLoadingCard;
