import React, { Fragment, } from 'react';
import {
  shape, string, func, object,
} from 'prop-types';

import FormRow from '../../../../components/Form/FormRow';
import Input from '../../../../atoms/Input/Input';
import TextArea from '../../../../atoms/TextArea/TextArea';


const Form = ({
  form,
  translations,
  values,
  onChange,
}) => (
  <Fragment>
    <FormRow
      label={translations.form.name}
      input={form.name}
    >
      <Input
        type="text"
        value={values.name}
        onChange={(e) => onChange(form.name.name, e.target.value)}
        status={form.name.status}
      />
    </FormRow>

    <FormRow
      label={translations.form.licensePlate}
      input={form.licensePlate}
    >
      <Input
        type="text"
        value={values.licensePlate}
        onChange={(e) => onChange(form.licensePlate.name, e.target.value)}
        status={form.licensePlate.status}
      />
    </FormRow>

    <FormRow
      label={translations.form.info}
      input={form.notice}
    >
      <TextArea
        type="text"
        value={values.notice}
        onChange={(e) => onChange(form.notice.name, e.target.value)}
        status={form.notice.status}
      />
    </FormRow>

  </Fragment>
);


Form.propTypes = {
  translations: object.isRequired,
  form: shape({
    name: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    licensePlate: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    notice: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
  }).isRequired,
  values: shape({
    name: string,
    licensePlate: string,
    notice: string,
  }).isRequired,
  onChange: func.isRequired,
};


export default Form;
