import React from 'react';

import StyledSvg from '../styles/StyledSvg';


const Users = ({ ...props }) => (
  <StyledSvg viewBox="0 0 283 267" {...props}>
    <path
      d="M30,267a5.006,5.006,0,0,1-5-5V245H85a23.026,23.026,0,0,0,23-23h65a23.026,23.026,0,0,0,23,23h60v17a5.006,5.006,0,0,1-5,5ZM5,207a5.006,5.006,0,0,1-5-5V188a5.006,5.006,0,0,1,5-5H278a5.005,5.005,0,0,1,5,5v14a5.005,5.005,0,0,1-5,5Zm0-41a5.005,5.005,0,0,1-5-5V5A5.006,5.006,0,0,1,5,0H278a5.005,5.005,0,0,1,5,5V161a5.005,5.005,0,0,1-5,5ZM10,14V150a5.005,5.005,0,0,0,5,5H268a5.005,5.005,0,0,0,5-5V14a5.005,5.005,0,0,0-5-5H15A5.006,5.006,0,0,0,10,14ZM86,144a3,3,0,0,1-3-3V26a3,3,0,0,1,3-3H201a3,3,0,0,1,3,3V141a3,3,0,0,1-3,3ZM90,33V134a3,3,0,0,0,3,3H194a3,3,0,0,0,3-3V33a3,3,0,0,0-3-3H93A3,3,0,0,0,90,33Zm73,93V102h23v24Zm-31,0V102h23v24Zm-31,0V102h23v24Zm62-32V70h23V94Zm-31,0V70h23V94Zm-31,0V70h23V94Zm62-32V38h23V62Zm-31,0V38h23V62Zm-31,0V38h23V62Z"
    />
  </StyledSvg>
);

export default Users;
