import gql from 'graphql-tag';


export const QUERY_DD_USER_TRANSLATION = gql`
  query Dictionary($languageId: ID) {
    dictionary(languageId: $languageId) {
      dictionary {
        centralApp {
          authentication {
            logout
          }
        }
      }
    }
  }
`;


export const QUERY_DD_LANGUAGES_TRANSLATION = gql`
  query Dictionary($languageId: ID) {
    dictionary(languageId: $languageId) {
      language {
        id
        name
        code
      }
    }
  }
`;
