import React, { Component, } from 'react';
import {
  shape, object, func, string, arrayOf,
} from 'prop-types';

import { GraphQLErrorsGlobal, } from '../../../../globals';
import { initRoleForm, } from '../utils';
import {
  changeAndValidateInput, validateAndMergeWholeForm, mergeValidationObjectIntoForm,
} from '../../../../logic/form/common';
import { withNotifications, } from '../../../../logic/notifications/withNotifications';
import RoleUpdateView from './RoleUpdateView';


class RoleUpdateLogic extends Component {
  constructor(props) {
    super(props);

    const {
      data: {
        id,
        name,
        resources,
      },
    } = props;

    this.state = {
      id,
      roleForm: {
        ...initRoleForm,
        values: {
          ...initRoleForm.values,
          name,
        },
      },
      resourcesValue: this.parseResourcesToState(resources),
    };
  }


  parseResourcesToState = (resources) => {
    const ret = {};

    if (!resources) return ret;

    for (let i = 0; i < resources.length; i++) {
      const { id, constraint, } = resources[i];
      if (constraint) {
        const all = constraint.length < 1;
        ret[id] = {
          id,
          all,
          constraint: true,
          payload: constraint.reduce(
            (accumulator, item) => {
              accumulator[item] = true;
              return accumulator;
            },
            {},
          ),
        };
      } else {
        ret[id] = {
          id,
          all: false,
          constraint: false,
          payload: true,
        };
      }
    }
    return ret;
  }


  handleChangeForm = (name, value) => {
    this.setState((prevState) => ({
      roleForm: changeAndValidateInput(prevState.roleForm, name, value),
    }));
  }


  handleChangeResourceValue = (name, value) => {
    this.setState((prevState) => ({
      resourcesValue: {
        ...prevState.resourcesValue,
        [name]: value,
      },
    }));
  }


  parseResourcesValue = () => {
    const { resourcesValue, } = this.state;

    const ret = [];
    const keys = Object.keys(resourcesValue);

    for (let i = 0; i < keys.length; i++) {
      const {
        id, all, constraint, payload,
      } = resourcesValue[keys[i]];

      if (constraint) {
        if (all) {
          ret.push({
            resourceId: id,
            constraint: [],
          });
        } else {
          const addedConstraints = Object.keys(payload).reduce(
            (accumulator, key) => {
              if (payload[key]) accumulator.push(key);
              return accumulator;
            },
            [],
          );

          if (addedConstraints.length > 0) {
            ret.push({
              resourceId: id,
              constraint: addedConstraints,
            });
          }
        }
      } else {
        if (payload) { // eslint-disable-line
          ret.push({
            resourceId: id,
            constraint: undefined,
          });
        }
      }
    }

    return ret;
  }


  handleUpdate = (mutationUpdate) => {
    const { roleForm, id, } = this.state;
    const newRoleForm = validateAndMergeWholeForm(roleForm);

    if (!newRoleForm.isValid) {
      this.setState({
        roleForm: newRoleForm,
      });
    } else {
      const variables = {
        id,
        values: {
          name: roleForm.values.name,
          resources: this.parseResourcesValue(),
        },
      };
      mutationUpdate({
        variables,
      });
    }
  }


  handleUpdateComplete = () => {
    const {
      translations, onToggle, addNotification,
    } = this.props;

    onToggle();
    addNotification({
      status: 'success',
      title: translations.common.updated,
    });
  }


  handleUpdateError = (mutationError) => {
    try {
      const { roleForm, } = this.state;

      const { graphQLErrors, } = mutationError;

      if (graphQLErrors && graphQLErrors.length > 0) {
        const { message, extensions, } = graphQLErrors[0];

        switch (message) {
          case GraphQLErrorsGlobal.UNPROCESSABLE_ENTITY: {
            if (extensions.exception.data) {
              const newFormData = mergeValidationObjectIntoForm(
                roleForm, extensions.exception.data
              );

              this.setState({
                roleForm: newFormData,
              });
            }
            break;
          }

          default: {
            break;
          }
        }
      }
    } catch (err) {
      // continue regardless of error
    }
  }


  render() {
    const { roleForm, resourcesValue, } = this.state;
    const {
      resources, translations,
      languageId, onToggle,
    } = this.props;

    return (
      <RoleUpdateView
        // data
        roleForm={roleForm}
        resourcesValue={resourcesValue}
        resources={resources}
        languageId={languageId}
        translations={translations}
        // methods
        onChangeForm={this.handleChangeForm}
        onChangeResourceValue={this.handleChangeResourceValue}
        onUpdate={this.handleUpdate}
        onClose={onToggle}
        onUpdateCompleted={this.handleUpdateComplete}
        onUpdateError={this.handleUpdateError}
      />
    );
  }
}


RoleUpdateLogic.propTypes = {
  data: shape({
    id: string.isRequired,
    name: string.isRequired,
    resources: arrayOf(object),
  }).isRequired,
  resources: object.isRequired,
  translations: object.isRequired,
  languageId: string.isRequired,
  addNotification: func.isRequired,
  onToggle: func.isRequired,
};


export default withNotifications(RoleUpdateLogic);
