import React from 'react';
import {
  shape, oneOfType, arrayOf, node, string, object, bool,
} from 'prop-types';

import Label from './Label';
import FormMessage from './FormMessage';


const FormRow = ({
  info,
  input: {
    status,
    statusMsg,
    validation: {
      required,
    },
  },
  label,
  children,
}) => (
  <div>
    <Label
      label={label}
      isRequired={required}
    />
    <div>
      {children}
    </div>
    <FormMessage
      status={status}
      msg={statusMsg}
      info={info}
    />
  </div>
);


FormRow.propTypes = {
  label: string,
  info: string,
  input: shape({
    status: string.isRequired,
    statusMsg: object.isRequired,
    validation: shape({
      required: bool,
    }).isRequired,
  }).isRequired,
  children: oneOfType([
    arrayOf(node),
    node,
  ]).isRequired,
};


FormRow.defaultProps = {
  label: '',
  info: '',
};


export default FormRow;
