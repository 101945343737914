import React from 'react';
import {
  bool, func, object, string,
} from 'prop-types';

import { Mutation, } from 'react-apollo';
import Button from '../../../../atoms/Button/Button';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import ButtonGrp from '../../../../atoms/Button/ButtonGrp';
import FormBackground from '../../../../components/Form/FormBackground';
import RecordExtraForm from '../forms/RecordExtraForm';
import RecordBasicForm from '../forms/RecordBasicForm';
import RecordCheckpoints from '../forms/RecordCheckpoints';
import DriverFilter from '../../../Transportation/components/filters/DriverFilter';
import { MUTATION_CONFIRM_ENTRANCE, MUTATION_REJECT_ENTRANCE, } from '../../gql/mutations';
import ButtonLoader from '../../../../atoms/Button/ButtonLoader';
import FormError from '../../../../components/Form/FormError';
import { showActionButtons, } from '../utils';


const RecordEditView = ({
  // data
  isEditMode,
  detailForm,
  languageId,
  translations,
  gatehouseType,
  data,
  // methods
  onToggle,
  onChangeForm,
  onFindTransportation,
  onFillFormValues,
  onConfirmEntrance,
  onConfirmEntranceError,
  onConfirmEntranceComplete,
  onRejectEntrance,
  onRejectEntranceError,
  onRejectEntranceComplete,
}) => (
  <FormBackground>
    <Row>
      <Col HD={8} LG={12} MD={24}>
        <fieldset>
          <legend>{translations.gatehouse.titleBasicForm}</legend>
          <DriverFilter
            onApply={onFillFormValues}
            placeholder={translations.transportation.placeholderFilterDriver}
            translations={translations}
          />

          <RecordBasicForm
            {...detailForm}
            languageId={languageId}
            translations={translations}
            onChange={onChangeForm}
            onFindTransportation={onFindTransportation}
            isEditMode={isEditMode}
            gatehouseType={gatehouseType}
          />
        </fieldset>
      </Col>

      <Col HD={8} LG={12} MD={24}>
        <fieldset>
          <legend>{translations.gatehouse.titleExtraForm}</legend>
          <RecordExtraForm
            {...detailForm}
            translations={translations}
            onChange={onChangeForm}
          />
        </fieldset>
      </Col>

      {detailForm.values.transportationId && (
      <Col HD={8} LG={12} MD={24}>
        <fieldset>
          <legend>{translations.gatehouse.checkpoints}</legend>
          <RecordCheckpoints
            {...detailForm}
            translations={translations}
          />
        </fieldset>
      </Col>
      )}
    </Row>

    <ButtonGrp block>
      <Button
        shape="outline"
        onClick={onToggle}
      >
        {translations.common.back}
      </Button>

      {showActionButtons(gatehouseType, data) && (
      <>
        {isEditMode
              && (
              <Mutation
                mutation={MUTATION_REJECT_ENTRANCE}
                onCompleted={onRejectEntranceComplete}
                onError={onRejectEntranceError}
              >
                {(rejectMutation, { loading, }) => (
                  <ButtonLoader
                    color="error"
                    onClick={() => onRejectEntrance(rejectMutation)}
                    disabled={!detailForm.isValid}
                    isLoading={loading}
                  >
                    {translations.gatehouse.rejectEntrance}
                  </ButtonLoader>
                )}
              </Mutation>
              )
            }

        <Mutation
          mutation={MUTATION_CONFIRM_ENTRANCE}
          onCompleted={onConfirmEntranceComplete}
          onError={onConfirmEntranceError}
        >
          {(confirmMutation, { loading, }) => (
            <ButtonLoader
              color="success"
              onClick={() => onConfirmEntrance(confirmMutation)}
              disabled={!detailForm.isValid}
              isLoading={loading}
            >
              {translations.gatehouse.confirmEntrance}
            </ButtonLoader>
          )}
        </Mutation>
      </>
      )}
    </ButtonGrp>

    <Row>
      <FormError
        isValid={detailForm.isValid}
        customError={detailForm.customError}
      />
    </Row>

  </FormBackground>
);


RecordEditView.propTypes = {
  // data
  isEditMode: bool.isRequired,
  detailForm: object.isRequired,
  languageId: string.isRequired,
  translations: object.isRequired,
  gatehouseType: string.isRequired,
  data: object.isRequired,
  // methods
  onChangeForm: func.isRequired,
  onFindTransportation: func.isRequired,
  onFillFormValues: func.isRequired,
  onToggle: func.isRequired,
  onConfirmEntrance: func.isRequired,
  onConfirmEntranceError: func.isRequired,
  onConfirmEntranceComplete: func.isRequired,
  onRejectEntrance: func.isRequired,
  onRejectEntranceError: func.isRequired,
  onRejectEntranceComplete: func.isRequired,
};


export default RecordEditView;
