import React, { Fragment, } from 'react';
import {
  func, shape, bool, string, object, arrayOf,
} from 'prop-types';

import { Query, } from 'react-apollo';
import Input from '../../../../atoms/Input/Input';
import Table from '../../../../atoms/Table/Table';
import Pagination from '../../../../components/Pagination/Pagination';
import TableLoading from '../../../../atoms/Table/TableLoading';
import TableError from '../../../../atoms/Table/TableError';
import TableNoData from '../../../../atoms/Table/TableNoData';
import FilteredTable from '../../../../components/FilteredTable/FilteredTable';
import Tooltip from '../../../../atoms/Tooltip/Tooltip';
import ButtonIcon from '../../../../atoms/Button/ButtonIcon';
import ButtonGrp from '../../../../atoms/Button/ButtonGrp';
import THSortable from '../../../../atoms/Table/THSortable';

import Bin from '../../../../styles/icons/Bin';
import Search from '../../../../styles/icons/Search';
import { QUERY_DD_PLATFORMS, QUERY_DD_TRANSPORTATION_TYPES, } from '../../../Transportation/gql/queries';
import InputSelect from '../../../../atoms/InputSelect/InputSelect';
import { QUERY_SMS_SETTINGS, } from '../../gql/queries';
import SmsSettingsCreate from '../SmsSettingsCreate/SmsSettingsCreate';
import ModalDelete from '../../../../components/modal/ModalDelete';
import { MUTATION_DELETE_SMS_SETTINGS, } from '../../gql/mutations';
import SmsSettingsUpdate from '../SmsSettingsUpdate/SmsSettingsUpdate';


const COL_COUNT = 6;
const INIT_FILTER = {
  offset: 0,
  limit: 25,
  sortBy: '',
  order: '',
  params: {
    recipient: '',
    type: null,
    transportationType: null,
    platform: null,
  },
};


const Header = ({
  // data
  filter,
  translations,
  languageId,
  // methods
  onChangeSort,
  onChangeParam,
}) => (
  <thead>

    <tr>
      <th>
          ID
      </th>

      <THSortable
        title={translations.smsSettings.recipient}
        name="recipient"
        isActiveFilter={filter.params.recipient !== ''}
        filter={filter}
        style={{ minWidth: '12rem', width: '12rem', }}
        onSort={onChangeSort}
      />

      <THSortable
        title={translations.smsSettings.type}
        name="type"
        isActiveFilter={filter.params.type}
        filter={filter}
        style={{ minWidth: '12rem', width: '12rem', }}
        onSort={onChangeSort}
      />

      <THSortable
        title={translations.smsSettings.transportationType}
        name="transportationTypeName"
        isActiveFilter={filter.params.transportationType}
        filter={filter}
        style={{ minWidth: '18rem', width: '18rem', }}
        onSort={onChangeSort}
      />

      <THSortable
        title={translations.smsSettings.platform}
        name="platformName"
        isActiveFilter={filter.params.platform}
        filter={filter}
        style={{ minWidth: '18rem', width: '18rem', }}
        onSort={onChangeSort}
      />

      <th
        className="table--text-align-right"
        style={{ minWidth: '5rem', width: '5rem', }}
      >
        {translations.smsSettings.actions}
      </th>
    </tr>

    <tr>
      <th />

      <th>
        <Input
          type="text"
          placeholder={translations.common.filter}
          autoComplete="off"
          value={filter.params.recipient}
          active={filter.params.recipient !== ''}
          onChange={(e) => onChangeParam('recipient', e.target.value)}
          onClear={() => onChangeParam('recipient', '')}
          size="sm"
        />
      </th>

      <th>
        <InputSelect
          value={filter.params.type}
          active={!!filter.params.type}
          options={[
            { id: 'queue', name: translations.smsSettings.typeQueue, },
            { id: 'calling', name: translations.smsSettings.typeCalling, },
          ]}
          onChange={(option) => onChangeParam('type', option, false)}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          isClearable
          placeholder={translations.common.filter}
          size="sm"
        />
      </th>

      <th>
        <Query
          query={QUERY_DD_TRANSPORTATION_TYPES}
          variables={{
            languageId,
          }}
        >
          {(dropdownData) => (
            <InputSelect
              value={filter.params.transportationType}
              active={!!filter.params.transportationType}
              options={(dropdownData.data && dropdownData.data.fetchAllTransportationTypes)
                ? dropdownData.data.fetchAllTransportationTypes
                : []
              }
              onChange={(option) => onChangeParam('transportationType', option, false)}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              isClearable
              isLoading={dropdownData.loading}
              error={dropdownData.error}
              placeholder={translations.common.filter}
              size="sm"
            />
          )}
        </Query>
      </th>

      <th>
        <Query
          query={QUERY_DD_PLATFORMS}
          variables={{
            languageId,
          }}
        >
          {(dropdownData) => (
            <InputSelect
              value={filter.params.platform}
              active={!!filter.params.platform}
              options={(dropdownData.data && dropdownData.data.fetchAllPlatforms)
                ? dropdownData.data.fetchAllPlatforms
                : []
              }
              onChange={(option) => onChangeParam('platform', option, false)}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              isClearable
              isLoading={dropdownData.loading}
              error={dropdownData.error}
              placeholder={translations.common.filter}
              size="sm"
            />
          )}
        </Query>
      </th>

      <th />
    </tr>

  </thead>
);


Header.propTypes = {
  // data
  filter: object.isRequired,
  translations: object.isRequired,
  languageId: string.isRequired,
  // methods
  onChangeSort: func.isRequired,
  onChangeParam: func.isRequired,
};


const Rows = ({
  // data
  loading,
  error,
  data,
  translations,
  // methods
  onToggleUpdate,
  onToggleDelete,
}) => {
  if (!data.filterSmsSettings && loading) {
    return (
      <TableLoading
        colsCount={COL_COUNT}
        rowsCount={data && data.filterSmsSettings && data.filterSmsSettings.rows.length}
      />
    );
  }
  if (error || !data.filterSmsSettings) {
    return (
      <TableError
        colsCount={COL_COUNT}
        error={error}
      />
    );
  }
  if (data.filterSmsSettings.rows.length < 1) {
    return (
      <TableNoData
        colsCount={COL_COUNT}
        text={translations.smsSettings.noSettings}
      />
    );
  }

  return (
    <tbody>
      {data.filterSmsSettings.rows.map((item) => (
        <tr key={item.id}>
          <td>
            {item.id}
          </td>
          <td>
            {item.recipient}
          </td>
          <td>
            {item.type === 'queue' ? translations.smsSettings.typeQueue : translations.smsSettings.typeCalling}
          </td>
          <td>
            {item.transportationTypeName}
          </td>
          <td>
            {item.platformName}
          </td>
          <td className="table--text-align-right table--noPadding">
            <ButtonGrp>
              <Tooltip text={translations.common.detail}>
                {(events) => (
                  <ButtonIcon
                    size="sm"
                    color="tertiary"
                    onClick={(e) => onToggleUpdate(e, item.id)}
                    {...events}
                  >
                    <Search />
                  </ButtonIcon>
                )}
              </Tooltip>
              <Tooltip text={translations.common.delete}>
                {(events) => (
                  <ButtonIcon
                    size="sm"
                    color="error"
                    onClick={(e) => onToggleDelete(e, item.id)}
                    {...events}
                  >
                    <Bin />
                  </ButtonIcon>
                )}
              </Tooltip>
            </ButtonGrp>
          </td>
        </tr>
      ))}
    </tbody>
  );
};


Rows.propTypes = {
  loading: bool.isRequired,
  error: object,
  data: shape({
    filterSmsSettings: shape({
      rows: arrayOf(shape({
        id: string.isRequired,
        recipient: string,
        type: string,
        transportationTypeName: string,
        platformName: string,
      })).isRequired,
    }),
  }),
  translations: object.isRequired,
  onToggleUpdate: func.isRequired,
  onToggleDelete: func.isRequired,
};

Rows.defaultProps = {
  error: undefined,
  data: undefined,
};


const SmsSettingsTable = ({
  // data
  translations,
  languageId,
  createModal,
  updateModal,
  deleteModal,
  // methods
  onToggleCreate,
  onToggleUpdate,
  onToggleDelete,
}) => (
  <FilteredTable
    initFilter={INIT_FILTER}
    query={QUERY_SMS_SETTINGS}
    filterToVariables={(filter) => ({
      ...filter,
      languageId,
      params: {
        ...filter.params,
        platform: undefined,
        transportationType: undefined,
        type: filter.params.type
          ? filter.params.type.id
          : undefined,
        platformId: filter.params.platform
          ? filter.params.platform.id
          : undefined,
        transportationTypeId: filter.params.transportationType === null
          ? undefined
          : (filter.params.transportationType.id).toString(),
      },
    })}
  >
    {({
      // filter
      queryData,
      filter,
      // filter handlers
      onChangeSort,
      onChangeFilter,
      onChangeParam,
    }) => (
      <Fragment>

        {createModal.isOpen && (
          <SmsSettingsCreate
            modalData={createModal}
            languageId={languageId}
            onToggle={onToggleCreate}
            onCreated={() => queryData.refetch()}
          />
        )}

        {updateModal.isOpen && (
          <SmsSettingsUpdate
            modalData={updateModal}
            languageId={languageId}
            onToggle={onToggleUpdate}
            onUpdated={() => queryData.refetch()}
          />
        )}

        {deleteModal.isOpen && (
          <ModalDelete
            // data
            title={translations.smsSettings.deleteModalTitle}
            text={translations.smsSettings.deleteModalText}
            mutation={MUTATION_DELETE_SMS_SETTINGS}
            variables={{ id: deleteModal.id, }}
            // methods
            onToggle={onToggleDelete}
            onCompleted={queryData.refetch}
          />
        )}

        <Table fillContent>
          <Header
            // data
            filter={filter}
            translations={translations}
            languageId={languageId}
            // methods
            onChangeSort={onChangeSort}
            onChangeParam={onChangeParam}
          />
          <Rows
            // data
            {...queryData}
            translations={translations}
            // methods
            onToggleDelete={onToggleDelete}
            onToggleUpdate={onToggleUpdate}
          />
        </Table>

        <Pagination
          loading={queryData.loading}
          dataFilter={queryData.data && queryData.data.filterSmsSettings
            ? queryData.data.filterSmsSettings.filter
            : undefined
          }
          onChangePage={onChangeFilter}
        />

      </Fragment>
    )}
  </FilteredTable>
);


SmsSettingsTable.propTypes = {
  translations: object.isRequired,
  languageId: string.isRequired,
  createModal: shape({
    isOpen: bool.isRequired,
  }).isRequired,
  updateModal: shape({
    isOpen: bool.isRequired,
    id: string,
  }).isRequired,
  deleteModal: shape({
    isOpen: bool.isRequired,
    id: string,
  }).isRequired,
  onToggleCreate: func.isRequired,
  onToggleUpdate: func.isRequired,
  onToggleDelete: func.isRequired,
};


export default SmsSettingsTable;
