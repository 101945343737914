import React, { Fragment, } from 'react';
import {
  func, object,
} from 'prop-types';

import FormRow from '../../../../components/Form/FormRow';
import Input from '../../../../atoms/Input/Input';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import InputDateSelect from '../../../../atoms/InputDateSelect/InputDateSelect';
import InputTimeSelect from '../../../../atoms/InputTimeSelect/InputTimeSelect';


const TechnicalCheckBasicForm = ({
  form,
  translations,
  values,
  onChange,
}) => (
  <Fragment>

    <Row>
      <Col SM={12}>
        <FormRow
          label={translations.form.no}
          input={form.transportationNo}
        >
          <Input
            type="text"
            value={values.transportationNo}
            onChange={(e) => onChange(form.transportationNo.name, e.target.value)}
            status={form.transportationNo.status}
            disabled
          />
        </FormRow>
      </Col>
    </Row>

    <Row>
      <Col SM={12}>
        <FormRow
          label={translations.form.transportationType}
          input={form.transportationTypeName}
        >
          <Input
            type="text"
            value={values.transportationTypeName}
            onChange={(e) => onChange(form.transportationTypeName.name, e.target.value)}
            status={form.transportationTypeName.status}
            disabled
          />
        </FormRow>
      </Col>
    </Row>

    <Row>
      <Col SM={6}>
        <FormRow
          label={translations.form.date}
          input={form.transportationDate}
        >
          <InputDateSelect
            value={values.transportationDate}
            onChange={(newValue) => onChange(form.transportationDate.name, newValue)}
            status={form.transportationDate.status}
            disabled
          />
        </FormRow>
      </Col>
      <Col SM={6}>
        <FormRow
          label={' '}
          input={form.transportationDate}
        >
          <InputTimeSelect
            value={values.transportationDate}
            onChange={(e) => onChange(form.transportationDate.name, e.target.value)}
            status={form.transportationDate.status}
            disabled
          />
        </FormRow>
      </Col>
    </Row>

    <Row>
      <Col SM={12}>
        <FormRow
          label={translations.form.carrier}
          input={form.transportCompany}
        >
          <Input
            type="text"
            value={values.transportCompany}
            onChange={(e) => onChange(form.transportCompany.name, e.target.value)}
            status={form.transportCompany.status}
            disabled
          />
        </FormRow>
      </Col>
    </Row>

    <Row>
      <Col SM={12}>
        <FormRow
          label={translations.form.carrierAddress}
          input={form.transportCompanyAddress}
        >
          <Input
            type="text"
            value={values.transportCompanyAddress}
            onChange={(e) => onChange(form.transportCompanyAddress.name, e.target.value)}
            status={form.transportCompanyAddress.status}
            disabled
          />
        </FormRow>
      </Col>
    </Row>

    <Row>
      <Col SM={12}>
        <FormRow
          label={translations.form.driverPhoneNumber}
          input={form.driverPhoneNumber}
        >
          <Input
            type="text"
            value={values.driverPhoneNumber}
            onChange={(e) => onChange(form.driverPhoneNumber.name, e.target.value)}
            status={form.driverPhoneNumber.status}
          />
        </FormRow>
      </Col>
    </Row>

  </Fragment>
);


TechnicalCheckBasicForm.propTypes = {
  translations: object.isRequired,
  form: object.isRequired,
  values: object.isRequired,
  onChange: func.isRequired,
};


export default TechnicalCheckBasicForm;
