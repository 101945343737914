import React, { Component, } from 'react';
import {
  shape, object, func, string, arrayOf,
} from 'prop-types';

import { GraphQLErrorsGlobal, } from '../../../../globals';
import { initUserUpdateForm, } from '../utils';
import {
  changeAndValidateInput, validateAndMergeWholeForm, mergeValidationObjectIntoForm,
} from '../../../../logic/form/common';
import { withNotifications, } from '../../../../logic/notifications/withNotifications';
import UserUpdateView from './UserUpdateView';


class UserUpdateLogic extends Component {
  constructor(props) {
    super(props);

    const {
      data: {
        id,
        username,
        chip,
        pin,
        roles,
        email,
      },
    } = props;

    this.state = {
      id,
      userForm: {
        ...initUserUpdateForm,
        values: {
          ...initUserUpdateForm.values,
          username: username || '',
          password: '',
          chip: chip || '',
          pin: pin || '',
          email: email || '',
          roles: roles === null ? [] : roles,
        },
      },
    };
  }

  handleChangeForm = (name, value) => {
    this.setState((prevState) => ({
      userForm: changeAndValidateInput(prevState.userForm, name, value),
    }));
  }


  handleChangeResourceValue = (name, value) => {
    this.setState((prevState) => ({
      resourcesValue: {
        ...prevState.resourcesValue,
        [name]: value,
      },
    }));
  }


  handleUpdate = (mutationUpdate) => {
    const { userForm, id, } = this.state;
    const newUserForm = validateAndMergeWholeForm(userForm);

    if (!newUserForm.isValid) {
      this.setState({
        userForm: newUserForm,
      });
    } else {
      const variables = {
        id,
        values: {
          ...userForm.values,
          password: userForm.values.password === '' ? undefined : userForm.values.password,
          roles: userForm.values.roles.map((role) => ({ roleId: role.id, })),
          block: undefined,
        },
      };
      mutationUpdate({
        variables,
      });
    }
  }


  handleUpdateComplete = () => {
    const {
      translations, onToggle, addNotification,
    } = this.props;

    onToggle();
    addNotification({
      status: 'success',
      title: translations.common.updated,
    });
  }


  handleUpdateError = (mutationError) => {
    try {
      const { userForm, } = this.state;

      const { graphQLErrors, } = mutationError;

      if (graphQLErrors && graphQLErrors.length > 0) {
        const { message, extensions, } = graphQLErrors[0];

        switch (message) {
          case GraphQLErrorsGlobal.UNPROCESSABLE_ENTITY: {
            if (extensions.exception.data) {
              const newFormData = mergeValidationObjectIntoForm(
                userForm, extensions.exception.data
              );

              this.setState({
                userForm: newFormData,
              });
            }
            break;
          }

          default: {
            break;
          }
        }
      }
    } catch (err) {
      // continue regardless of error
    }
  }


  render() {
    const { userForm, } = this.state;
    const { translations, onToggle, } = this.props;

    return (
      <UserUpdateView
        // data
        userForm={userForm}
        translations={translations}
        // methods
        onChangeForm={this.handleChangeForm}
        onUpdate={this.handleUpdate}
        onClose={onToggle}
        onUpdateCompleted={this.handleUpdateComplete}
        onUpdateError={this.handleUpdateError}
      />
    );
  }
}


UserUpdateLogic.propTypes = {
  data: shape({
    id: string.isRequired,
    username: string.isRequired,
    chip: string,
    pin: string,
    roles: arrayOf(shape({
      id: string.isRequired,
      name: string.isRequired,
    })).isRequired,
  }).isRequired,
  translations: object.isRequired,
  addNotification: func.isRequired,
  onToggle: func.isRequired,
};


export default withNotifications(UserUpdateLogic);
