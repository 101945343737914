import React, { Component, Fragment, } from 'react';
import {
  func, shape, string, object,
} from 'prop-types';
import { Mutation, } from 'react-apollo';

import { MUTATION_COMPLETE_ACTION, } from '../../gql/mutations';
import { WIZARD_ACTION_TYPES, } from './TabAction';
import Modal from '../../../../atoms/Modal/Modal';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import Button from '../../../../atoms/Button/Button';
import ButtonLoader from '../../../../atoms/Button/ButtonLoader';
import FormError from '../../../../components/Form/FormError';


class ModalSaveChanges extends Component {
  handleActionNo = () => {
    const {
      // data
      modalData: {
        actionType,
        changeTabData,
      },
      // methods
      onChangeTab,
      onCloseWizard,
    } = this.props;

    if (actionType === WIZARD_ACTION_TYPES.CHANGE_TAB) {
      onChangeTab(changeTabData);
    } else {
      onCloseWizard();
    }
  }


  handleActionYesCompleted = () => {
    // same behavior
    this.handleActionNo();
  }


  render() {
    const {
      // data
      translations,
      // methods
      onToggle,
      onCompleteAction,
      onCompleteActionError,
    } = this.props;

    return (
      <Modal
        isOpen
        onClose={onToggle}
        size="SM"
        title={translations.stand.modalSaveChangesTitle}
      >
        <Row formGroup>
          <Col>
            {translations.stand.modalSaveChangesText}
          </Col>
        </Row>

        <Mutation
          mutation={MUTATION_COMPLETE_ACTION}
          onCompleted={this.handleActionYesCompleted}
          onError={onCompleteActionError}
        >
          {(mutateSaveChanges, { loading, error, }) => (
            <Fragment>
              <FormError
                error={error}
              />
              <Row>
                <Col SM={12} MD={12}>
                  <Button
                    onClick={this.handleActionNo}
                    shape="outline"
                  >
                    {translations.common.no}
                  </Button>
                </Col>
                <Col SM={12} MD={12} textAlign="right">
                  <ButtonLoader
                    onClick={() => onCompleteAction(mutateSaveChanges, true)}
                    disabled={loading}
                    color="success"
                  >
                    {translations.common.yes}
                  </ButtonLoader>
                </Col>
              </Row>
            </Fragment>
          )}
        </Mutation>
      </Modal>
    );
  }
}


ModalSaveChanges.propTypes = {
  // data
  modalData: shape({
    actionType: string.isRequired,
    changeTabData: object.isRequired,
  }).isRequired,
  translations: object.isRequired,
  // methods
  onToggle: func.isRequired,
  onChangeTab: func.isRequired,
  onCloseWizard: func.isRequired,
  onCompleteAction: func.isRequired,
  onCompleteActionError: func.isRequired,
};


export default ModalSaveChanges;
