import styled from 'styled-components';


const StyledNotificationsArray = styled.div`
    position: fixed;
    z-index: 100;
    pointer-events: none;

    right: 12px;
    bottom: 12px;

    .notifications--trans-enter {
        opacity: 0;
        margin-bottom: -15px;
    }

    .notifications--trans-enter-active {
        opacity: 0.9;
        margin-bottom: 0px;
        transition: all 350ms ease-in;
    }

    .notifications--trans-exit {
        opacity: 0.9;
    }

    .notifications--trans-exit-active {
        opacity: 0;
        transition: all 350ms ease-in;
    }
`;


export default StyledNotificationsArray;
