import styled from 'styled-components';

const backgroundColor = (p) => p.theme.grey.t400;
const topColor = (p) => p.theme.grey.t400;
const downColor = (p) => p.theme.success.t600;

export const StyledColumnChart = styled.div`
  .column-chart {
    display: flex;
    flex-direction: row;
    
    height: 5rem;
    width: 100%;
    
    margin-bottom: 1rem;
    
    .column-chart--levels {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      
      margin: -0.5rem 0 -0.5rem 0;
      width: 2.5rem;
      
      color: ${backgroundColor};
    }
    
    .column-chart--columns {
      position: relative;
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      
      border-bottom: 1px solid ${backgroundColor};

      &.with-levels {
        border-top: 1px solid ${backgroundColor};
      }
      
      .column-chart--columns-level-line {
        position: absolute;
        width: 100%;
        box-sizing: border-box;
        border-bottom: 1px solid ${backgroundColor};
      }
    }
  }
  
  .column-chart-column {
    position: relative;
    height: 100%;
    flex-grow: 1;
    
    .column-chart-column--down {
      height: 100%;
      background: ${downColor};
    
      .column-chart-column--top {
        background: ${topColor};
      }
    }
    
    .column-chart-column--label {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      text-align: center;
      color: ${backgroundColor};
    }
  }
`;
