import React from 'react';
import {
  arrayOf, number, shape, string,
} from 'prop-types';

import RatioChartColumn from './RatioChartColumn';
import { StyledRatioChart, } from './StyledRatioChart';
import ColumnChart from '../ColumnChart/ColumnChart';


const RatioChart = ({ data, legendDown, legendTop, }) => (
  <StyledRatioChart>
    <div className="ratio-chart">
      <div className="ratio-chart--legend">
        {legendDown && (<span className="ratio-chart--legend-color down" />)}
        {legendDown && (<span className="ratio-chart--legend-text">{legendDown}</span>)}
        {legendTop && (<span className="ratio-chart--legend-color top" />)}
        {legendTop && (<span className="ratio-chart--legend-text">{legendTop}</span>)}
      </div>
      <ColumnChart
        columns={data.map(({ ratio, label, }) => ({
          label,
          element: (<RatioChartColumn ratio={ratio} />),
        }))}
        levels={[
          { label: '100%', },
          { label: '50%', },
          { label: '0%', },
        ]}
      />
    </div>
  </StyledRatioChart>
);


RatioChart.defaultProps = {
  legendDown: undefined,
  legendTop: undefined,
};


RatioChart.propTypes = {
  data: arrayOf(shape({
    ratio: number.isRequired,
    label: string.isRequired,
  })).isRequired,
  legendDown: string,
  legendTop: string,
};


export default RatioChart;
