// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
import React, { Component, } from 'react';

import { arrayOf, func, object, } from 'prop-types';
import ListAdd from '../../../styles/icons/ListAdd';


const WRAPPER_ID = 'report-select-box';

class ReportSelectBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: false, // boolean
    };
  }

  componentDidMount() {
    window.addEventListener('click', this.toggleListener);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.toggleListener);
  }

  toggleListener = (ev) => {
    if (ev.target) {
      const wrapperElem = document.getElementById(WRAPPER_ID);

      if (wrapperElem === ev.target) {
        this.setState((state) => ({ opened: !state.opened, }));
      } else if (!wrapperElem?.contains(ev.target)) {
        this.setState({ opened: false, });
      }
    }
  }

  handleSelect = (ev) => {
    const { options, onSelect, } = this.props;

    const id = ev.target.value;
    const report = options.find((r) => r.id === id);

    if (report) {
      onSelect(report);
    }
  }

  render() {
    const { selected, options, } = this.props;
    const { opened, } = this.state;

    return (
      <div
        id={WRAPPER_ID}
        className={`control-panel-item report-select-box ${opened ? 'opened' : ''}`}
      >
        <ListAdd
          className="control-panel-icon"
        />
        {selected ? selected.name : 'Volba reportu'}
        <div
          className={`menu-pop-up ${opened ? 'opened' : ''}`}
        >
          {options.map((report) => (
            <div
              key={report.id}
              className="report-select-box-item"
            >
              {/* eslint-disable-next-line jsx-a11y/label-has-for */}
              <label
                htmlFor={`report-select-box-radio-${report.id}`}
              >
                {report.name}
              </label>
              <input
                id={`report-select-box-radio-${report.id}`}
                type="radio"
                value={report.id}
                name="report-select-box-radio"
                onChange={this.handleSelect}
                checked={selected?.id === report.id}
              />
            </div>
          ))}
          {options.length === 0 && (
            <div
              className="report-select-box-item no-options"
            >
              Žádné dostupné reporty
            </div>
          )}
        </div>
      </div>
    );
  }
}

ReportSelectBox.propTypes = {
  options: arrayOf(object.isRequired).isRequired,
  selected: object,
  onSelect: func.isRequired,
};

ReportSelectBox.defaultProps = {
  selected: undefined,
};


export default ReportSelectBox;
