import React, { Component, } from 'react';
import { object, } from 'prop-types';
import { Query, } from 'react-apollo';

import { QUERY_DASHBOARD_STATS, } from '../../gql/queries';
import PartLoading from '../../../../components/Parts/PartLoading';
import PartError from '../../../../components/Parts/PartError';
import StyledStatsGrid from './StyledStatsGrid';
import ActualSystemStateCard from './ActualSystemStateCard';
import TodayPlanCard from './TodayPlanCard';
import RampLoadingCard from './RampLoadingCard';
import AverageWaitingTimeCard from './AverageWaitingTimeCard';
import AverageClearanceTimeCard from './AverageClearanceTimeCard';
import RegistrationMethodCard from './RegistrationMethodCard';
import { SUBSCRIPTION_DASHBOARD, } from '../../gql/subscriptions';


class StatsGrid extends Component {
  constructor(props) {
    super(props);

    this.unsubscribe = null;
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  handleSubscription = (subscribeToMore) => {
    if (this.unsubscribe) {
      return;
    }

    this.unsubscribe = subscribeToMore({
      document: SUBSCRIPTION_DASHBOARD,
      updateQuery: (_, { subscriptionData, }) => {
        if (!subscriptionData.data) return null;
        return { fetchDashboardStats: subscriptionData.data.subscribeToDashboardUpdates, };
      },
    });
  }

  render() {
    const { translations, translationsCommon, } = this.props;

    return (
      <StyledStatsGrid>
        <Query query={QUERY_DASHBOARD_STATS}>
          {({
            loading, data, error, subscribeToMore,
          }) => {
            if (loading) {
              return <PartLoading />;
            }

            if (error) {
              console.error(error);
              return <PartError error={error} />;
            }

            const {
              actualSystemStateStats, averageClearanceTimeStats, averageWaitingTimeStats,
              todayPlanStats, rampLoadingStats, registrationMethodStats,
            } = data.fetchDashboardStats;

            this.handleSubscription(subscribeToMore);

            return (
              <ul className="stats--list">
                {averageWaitingTimeStats && (
                  <AverageWaitingTimeCard
                    averageWaitingTimeStats={averageWaitingTimeStats}
                    translations={translations}
                    translationsCommon={translationsCommon}
                  />
                )}
                {actualSystemStateStats && (
                  <ActualSystemStateCard
                    actualSystemStateStats={actualSystemStateStats}
                    translations={translations}
                  />
                )}

                <RegistrationMethodCard
                  registrationMethodStats={registrationMethodStats}
                  translations={translations}
                  translationsCommon={translationsCommon}
                />

                {averageClearanceTimeStats && (
                  <AverageClearanceTimeCard
                    averageClearanceTimeStats={averageClearanceTimeStats}
                    translations={translations}
                    translationsCommon={translationsCommon}
                  />
                )}
                {todayPlanStats && (
                  <TodayPlanCard
                    todayPlanStats={todayPlanStats}
                    translations={translations}
                  />
                )}
                {rampLoadingStats && (
                  <RampLoadingCard
                    rampLoadingStats={rampLoadingStats}
                    translations={translations}
                  />
                )}
              </ul>
            );
          }}
        </Query>
      </StyledStatsGrid>
    );
  }
}

StatsGrid.propTypes = {
  translations: object.isRequired,
  translationsCommon: object.isRequired,
};

export default StatsGrid;
