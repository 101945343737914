import styled from 'styled-components';

import { getColorBadge, } from './utils';


/**
 * BadgeIcon
 *  - color: 'grey'
 *  - size: 'md'
 *  - round: false
 *  - customColor: undefined - { color: '', background: '', }
 */


const getSizeBadge = ({ size, }) => {
  const sizes = {
    sm: `
      width: 0.875rem;
      height: 0.875rem;
      font-size: 0.5rem;
    `,
    md: `
      width: 1.25rem;
      height: 1.25rem;
      font-size: 0.75rem;
      `,
    lg: `
      width: 1.25rem;
      height: 1.25rem;
      font-size: 0.875rem;
    `,
  };

  if (Object.prototype.hasOwnProperty.call(sizes, size)) {
    return sizes[size];
  }
  return sizes.md;
};


const BadgeIcon = styled.div`
  margin: 0;
  padding: 0;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  
  border-radius: ${(p) => p.theme.input.borderRadius}; 
  
  user-select: none;

  line-height: 1;
  font-weight: 400;

  ${(p) => p.round && `
    border-radius: 50%;
  `}
  ${getSizeBadge}
  ${getColorBadge}

  ${(p) => p.customColor && `
    color: ${p.customColor.color};
    background: ${p.customColor.background};
  `}
`;


export default BadgeIcon;
