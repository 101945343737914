import React, { Component, } from 'react';
import {
  object, shape, func, string,
} from 'prop-types';
import { withRouter, } from 'react-router-dom';

import { logoutClearTokenResetConnection, } from '../../logic/graphql/apollo';
import { pipe, } from '../../logic/utils';
import { withTranslations, } from '../../logic/translations/withTranslations';
import { withUser, } from '../../logic/auth/withUser';
import { QUERY_DD_USER_TRANSLATION, } from './gql/translations';
import Dropdown from '../../atoms/Dropdown/Dropdown';
import DDList from '../../atoms/Dropdown/DDList';
import DDItem from '../../atoms/Dropdown/DDItem';
import ButtonIcon from '../../atoms/Button/ButtonIcon';

import Avatar from '../../styles/icons/Avatar';

class DDUser extends Component {
  state = {
    isOpen: false,
  }


  handelToggle = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  }


  handleLogout = () => {
    const { history, } = this.props;

    history.push('/login');
    logoutClearTokenResetConnection();
  }


  renderUserDDMenu = () => {
    const { translations, } = this.props;

    return (
      <DDList minWidth={8}>
        <DDItem
          onClick={this.handleLogout}
        >
          {translations.authentication.logout}
        </DDItem>
      </DDList>
    );
  }


  render() {
    const { isOpen, } = this.state;
    const { user, } = this.props;

    return (
      <Dropdown
        menu={this.renderUserDDMenu()}
        isOpen={isOpen}
        toggle={this.handelToggle}
        position="right"
      >
        <ButtonIcon
          className="header--content--button"
          transparent
          onClick={this.handelToggle}
          grow
        >
          <span style={{ marginRight: '0.5rem', fontSize: '0.75rem', }}>
            {user ? user.username : '-'}
          </span>
          <Avatar />
        </ButtonIcon>
      </Dropdown>
    );
  }
}


DDUser.propTypes = {
  translations: object.isRequired,
  user: shape({
    username: string.isRequired,
  }).isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
};


export default pipe(
  withTranslations(QUERY_DD_USER_TRANSLATION),
  withRouter,
  withUser,
)(DDUser);
