import React, { Component, } from 'react';
import { func, object, string, } from 'prop-types';

import {
  changeAndValidateInput,
  validateAndMergeWholeForm,
} from '../../../../../logic/form/common';
import { forwardFormStructure, forwardToWarehouseFormStructure, } from '../forms';
import { withNotifications, } from '../../../../../logic/notifications/withNotifications';
import Modal from '../../../../../atoms/Modal/Modal';
import ModalForwardView from './ModalForwardView';


export const FORWARD_TYPES = {
  PLATFORM: 'platform',
  WAREHOUSE: 'warehouse',
};

class ModalForward extends Component {
  state = {
    forwardType: FORWARD_TYPES.PLATFORM,
    forwardForm: forwardFormStructure,
    forwardToWarehouseForm: forwardToWarehouseFormStructure,
  }


  handleChangeForwardType = (forwardType) => {
    this.setState({
      forwardType,
    });
  }

  handleChangeForwardForm = (name, value) => {
    const { forwardForm, } = this.state;

    this.setState({
      forwardForm: changeAndValidateInput(forwardForm, name, value),
    });
  }


  handleChangeForwardToWarehouseForm = (name, value) => {
    const { forwardToWarehouseForm, } = this.state;

    this.setState({
      forwardToWarehouseForm: changeAndValidateInput(forwardToWarehouseForm, name, value),
    });
  }

  handleForwardToWarehouse = (mutationForward) => {
    const {
      forwardToWarehouseForm,
      forwardToWarehouseForm: {
        values,
      },
    } = this.state;
    const {
      platformId,
      transportationId,
    } = this.props;

    const newDetailForm = validateAndMergeWholeForm(forwardToWarehouseForm);
    if (!newDetailForm.isValid) {
      this.setState({
        forwardToWarehouseForm: newDetailForm,
      });
    } else {
      mutationForward({
        variables: {
          targetTransportationTypeId: values.transportationType === null
            ? undefined
            : values.transportationType.id,
          sourcePlatformId: platformId,
          transportationId,
        },
      });
    }
  }

  /**
   * Forward
   */
  handleForward = (mutationForward) => {
    const {
      forwardForm,
      forwardForm: {
        values,
      },
    } = this.state;
    const {
      platformId,
      transportationId,
    } = this.props;

    const newDetailForm = validateAndMergeWholeForm(forwardForm);

    if (!newDetailForm.isValid) {
      this.setState({
        forwardForm: newDetailForm,
      });
    } else {
      mutationForward({
        variables: {
          sourcePlatformId: platformId,
          targetPlatformId: values.platform === null
            ? undefined
            : values.platform.id,
          transportationId,
        },
      });
    }
  }

  /**
   * Forward - Completed
   */
  handleForwardCompleted = () => {
    const { translations, addNotification, onToggle, } = this.props;

    onToggle();
    addNotification({
      status: 'success',
      title: translations.centralAppStorekeeper.notifTitleForwardSuccess,
    });
  }

  /**
   * Forward - Error
   */
  handleForwardError = () => {
    const { translations, addNotification, } = this.props;

    addNotification({
      status: 'error',
      title: translations.centralAppStorekeeper.notifTitleForwardError,
    });
  }


  render() {
    const { forwardForm, forwardType, forwardToWarehouseForm, } = this.state;
    const {
      // data
      platformId,
      transportationId,
      languageId,
      translations,
      // methods
      onToggle,
    } = this.props;

    return (
      <Modal
        isOpen
        onClose={onToggle}
        size="SM"
        title={translations.centralAppStorekeeper.titleModalForward}
        disablePadding
      >
        <ModalForwardView
          // data
          forwardType={forwardType}
          platformId={platformId}
          forwardForm={forwardForm}
          forwardToWarehouseForm={forwardToWarehouseForm}
          transportationId={transportationId}
          languageId={languageId}
          translations={translations}
          // methods
          onToggle={onToggle}
          onChangeForwardType={this.handleChangeForwardType}
          onChangeForwardForm={this.handleChangeForwardForm}
          onChangeForwardToWarehouseForm={this.handleChangeForwardToWarehouseForm}
          onForward={this.handleForward}
          onForwardToWarehouse={this.handleForwardToWarehouse}
          onForwardCompleted={this.handleForwardCompleted}
          onForwardError={this.handleForwardError}
        />
      </Modal>
    );
  }
}


ModalForward.propTypes = {
  // data
  platformId: string.isRequired,
  transportationId: string.isRequired,
  languageId: string.isRequired,
  translations: object.isRequired,
  // methods
  onToggle: func.isRequired,
  addNotification: func.isRequired,
};


export default withNotifications(ModalForward);
