import React from 'react';

import StyledSvg from '../styles/StyledSvg';

const Folder = ({ ...props }) => (
  <StyledSvg viewBox="0 0 60 60" {...props}>
    <path d="M26.515,10.5l-5-7H0v53h60v-37v-2v-7H26.515z M31.515,17.5l-3.571-5H58v5H31.515z" />
  </StyledSvg>
);

export default Folder;
