import React, { Fragment, } from 'react';
import { func, object, } from 'prop-types';
import { Mutation, } from 'react-apollo';

import { MUTATION_CREATE_COMPANY, } from '../../gql/mutations';
import Button from '../../../../atoms/Button/Button';
import ButtonLoader from '../../../../atoms/Button/ButtonLoader';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import FormError from '../../../../components/Form/FormError';
import CompanyForm from '../forms/CompanyForm';
import FormBackground from '../../../../components/Form/FormBackground';


const CompanyCreateView = ({
  // data
  detailForm,
  translations,
  // methods
  onChange,
  onToggle,
  onCreate,
  onCreateError,
  onCreateComplete,
}) => (
  <FormBackground>
    <CompanyForm
      {...detailForm}
      translations={translations}
      onChange={onChange}
      isEdit={false}
    />

    <Mutation
      mutation={MUTATION_CREATE_COMPANY}
      onCompleted={onCreateComplete}
      onError={onCreateError}
    >
      {(mutate, { loading, error, }) => (
        <Fragment>
          <FormError
            isValid={detailForm.isValid}
            error={error}
          />
          <Row>

            <Col XS={12} SM={12}>
              <Button
                type="button"
                shape="outline"
                onClick={onToggle}
              >
                {translations.common.back}
              </Button>
            </Col>

            <Col XS={12} SM={12} textAlign="right">
              <ButtonLoader
                type="button"
                color="success"
                onClick={() => onCreate(mutate)}
                disabled={!detailForm.isValid}
                isLoading={loading}
              >
                {translations.common.create}
              </ButtonLoader>
            </Col>

          </Row>
        </Fragment>
      )}
    </Mutation>
  </FormBackground>
);


CompanyCreateView.propTypes = {
  translations: object.isRequired,
  detailForm: object.isRequired,
  onChange: func.isRequired,
  onToggle: func.isRequired,
  onCreate: func.isRequired,
  onCreateError: func.isRequired,
  onCreateComplete: func.isRequired,
};


export default CompanyCreateView;
