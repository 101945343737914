import React from 'react';
import {
  arrayOf, number, string, shape, object, func, bool,
} from 'prop-types';

import Item from './Item';


const Row = ({
  items,
  params,
  getItemNameAndStyle,
  onClickItem,
}) => (
  <div className="timeline--sections-row">
    {items.map((item) => (
      <Item
        key={item.id}
        {...item}
        params={params}
        getItemNameAndStyle={getItemNameAndStyle}
        onClickItem={onClickItem}
      />
    ))}
  </div>
);


Row.propTypes = {
  params: object.isRequired,
  items: arrayOf(shape({
    id: string.isRequired,
    from: number.isRequired,
    to: number.isRequired,
    width: number.isRequired,
    overFlowLeft: bool.isRequired,
    overFlowRight: bool.isRequired,
    payload: object.isRequired,
  })).isRequired,
  getItemNameAndStyle: func.isRequired,
  onClickItem: func.isRequired,
};


export default Row;
