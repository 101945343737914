import React from 'react';
import {
  func, object,
} from 'prop-types';
import FormRow from '../../../../components/Form/FormRow';
import Input from '../../../../atoms/Input/Input';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';


const ProductForm = ({
  form,
  translations,
  values,
  resources,
  onChange,
}) => {
  const isEditable = !!resources.loading_form_write && onChange !== undefined;
  const weightIn = values.weightIn === null || values.weightIn === '' ? 0 : parseFloat(values.weightIn.toString().replaceAll(',', '.'));
  const weightOut = values.weightOut === null || values.weightOut === '' ? 0 : parseFloat(values.weightOut.toString().replaceAll(',', '.'));
  const diff = !isNaN(weightIn) && !isNaN(weightOut) ? Math.abs(weightIn - weightOut).toFixed(2) : 0;

  return <>
    <Row>
      <Col SM={4}>
        <FormRow
          input={form.name}
          label={`${values.order + 1}. ${translations.transportation.products.name}`}
        />
      </Col>
      <Col SM={8}>
        <Input
          type="text"
          value={values.name}
          onChange={(e) => onChange(form.name.name, e.target.value)}
          status={form.name.status}
          disabled
        />
      </Col>
      <Col SM={4}>
        <FormRow
          input={form.destination}
          label={translations.transportation.products.destination}
        />
      </Col>
      <Col SM={8}>
        <Input
          type="text"
          value={values.destination}
          onChange={(e) => onChange(form.destination.name, e.target.value)}
          status={form.destination.status}
          disabled
        />
      </Col>
    </Row>
    <Row>
      <Col SM={4} />
      <Col SM={4}>
        <FormRow
          input={form.quantity}
          label={translations.transportation.products.quantity}
        >
          <Input
            type="text"
            value={values.quantity}
            onChange={(e) => onChange(form.quantity.name, e.target.value)}
            status={form.quantity.status}
            disabled
          />
        </FormRow>
      </Col>
      <Col SM={4}>
        <FormRow
          input={form.unit}
          label={translations.transportation.products.unit}
        >
          <Input
            type="text"
            value={values.unit}
            onChange={(e) => onChange(form.unit.name, e.target.value)}
            status={form.unit.status}
            disabled
          />
        </FormRow>
      </Col>
      <Col SM={4}>
        <FormRow
          input={form.package}
          label={translations.transportation.products.package}
        >
          <Input
            type="text"
            value={values.package}
            onChange={(e) => onChange(form.package.name, e.target.value)}
            status={form.package.status}
            disabled
          />
        </FormRow>
      </Col>
      <Col SM={4}>
        <FormRow
          input={form.adrClass}
          label={translations.transportation.products.adrClass}
        >
          <Input
            type="text"
            value={values.adrClass}
            onChange={(e) => onChange(form.adrClass.name, e.target.value)}
            status={form.adrClass.status}
            disabled
          />
        </FormRow>
      </Col>
      <Col SM={4}>
        <FormRow
          input={form.un}
          label={translations.transportation.products.un}
        >
          <Input
            type="text"
            value={values.un}
            onChange={(e) => onChange(form.un.name, e.target.value)}
            status={form.un.status}
            disabled
          />
        </FormRow>
      </Col>
    </Row>
    <Row>
      <Col SM={4} />
      <Col SM={4}>
        <FormRow
          input={form.weightIn}
          label={translations.transportation.products.weightIn}
        >
          <Input
            type="text"
            value={values.weightIn}
            onChange={(e) => onChange(form.weightIn.name, e.target.value)}
            status={form.weightIn.status}
            disabled={!isEditable}
          />
        </FormRow>
      </Col>
      <Col SM={4}>
        <FormRow
          input={form.weightInUnit}
          label={translations.transportation.products.unit}
        >
          <Input
            type="text"
            value={values.weightInUnit}
            disabled
          />
        </FormRow>
      </Col>
      <Col SM={4}>
        <FormRow
          input={form.weightOut}
          label={translations.transportation.products.weightOut}
        >
          <Input
            type="text"
            value={values.weightOut}
            onChange={(e) => onChange(form.weightOut.name, e.target.value)}
            status={form.weightOut.status}
            disabled={!isEditable}
          />
        </FormRow>
      </Col>
      <Col SM={4}>
        <FormRow
          input={form.weightOutUnit}
          label={translations.transportation.products.unit}
        >
          <Input
            type="text"
            value={values.weightOutUnit}
            disabled
          />
        </FormRow>
      </Col>
      <Col SM={4}>
        <FormRow
          input={{ validation: { required: false, }, }}
          label={translations.transportation.products.diff}
        >
          <Input
            type="text"
            value={diff}
            disabled
          />
        </FormRow>
      </Col>
    </Row>
    <Row>
      <Col SM={4} />
      <Col SM={12}>
        <FormRow
          input={form.transportationTypeId}
          label={translations.form.transportationType}
        >
          <Input
            type="text"
            value={values.transportationTypeName}
            status={form.transportationTypeId.status}
            disabled
          />
        </FormRow>
      </Col>
      <Col SM={4}>
        <FormRow
          input={{ validation: { required: false, }, }}
          label={translations.transportation.products.sample}
        >
          {values.sample ? translations.common.yes : translations.common.no}
        </FormRow>
      </Col>
      <Col SM={4}>
        <FormRow
          input={{ validation: { required: false, }, }}
          label={translations.transportation.products.imoDocument}
        >
          {values.imoDocument ? translations.common.yes : translations.common.no}
        </FormRow>
      </Col>
    </Row>
  </>;
};


ProductForm.propTypes = {
  translations: object.isRequired,
  form: object.isRequired,
  values: object.isRequired,
  resources: object.isRequired,
  onChange: func,
};

ProductForm.defaultProps = {
  onChange: undefined,
};

export default ProductForm;
