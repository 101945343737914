import gql from 'graphql-tag';


/**
 * Platform
 */
export const QUERY_PLATFORMS = gql`
  query FetchCentralPlatforms($platformIds: [ID]!, $languageId: ID!) {
    fetchCentralPlatforms(platformIds: $platformIds, languageId: $languageId) {
      id
      name
      opened
      capacity
      clearance {
        id
        lastCall
        transportationId
        transportationTypeId
        platformId
        platformName
        transportationTypeName
        no
        truckRegistration
        trailerRegistration
        state
        startTime
        endTime
        genericFormId
      }
    }
  }
`;


/**
 * Queue
 */
export const QUERY_STOREKEEPER_QUEUE = gql`
  query FetchCentralQueueTransportation($languageId: ID!) {
    fetchCentralQueueTransportation(languageId: $languageId) {
      id
      truckRegistration
      trailerRegistration
      driverName
      driverPhone
      no
      date
      timeFrom
      timeTo
      transportationTypeName
      reservationTypeName
      reservationTypeId
      clearanceState
      actionType
      platformName
      platformId
      possiblePlatforms
    }
  }
`;

export const GET_STOREKEEPER_QUEUE_DATA = gql`
  query {
    storekeeperQueue @client {
      activeTab
      platform {
        id
        name
      }
    }
  }
`;

export const QUERY_POSSIBLE_PLATFORMS = gql`
  query FetchPossiblePlatforms($transportationId: ID!, $languageId:ID!) {
    fetchPossiblePlatforms(transportationId: $transportationId, languageId: $languageId) {
      id
      name
      opened
    }
  }
`;


/**
 * Common
 */
export const QUERY_NEXT_STANDS = gql`
  query FetchStoreKeeperStandsForNextAction($transportationId: ID!, $languageId: ID!) {
    fetchStoreKeeperStandsForNextAction(transportationId: $transportationId, languageId: $languageId) {
      id
      name
    }
  }
`;

export const QUERY_FORWARD_PLATFORMS = gql`
  query FetchStoreKeeperForwardPossiblePlatforms($transportationId: ID!, $platformId: ID!, $languageId: ID!) {
    fetchStoreKeeperForwardPossiblePlatforms(transportationId: $transportationId, platformId: $platformId, languageId: $languageId) {
      id
      name
      capacity
    }
  }
`;

export const QUERY_TRANSPORTATION_GENERIC_FORM = gql`
  query FetchStoreKeeperGenericFormConfiguration($transportationId: ID!, $languageId: ID!) {
    fetchStoreKeeperGenericFormConfiguration(transportationId: $transportationId, languageId: $languageId) {
      inProgress
      genericForm {
        id
        name
        structure
        translation
        inputs {
          name
          type
          disabled
          defaultValue
          action
          map
          index
          genericFormDialId
          translation {
            label
            placeholder
            help
          }
          validation {
            required
            min
            max
            regex {
              name
              expression
              code
              params
            }
          }
        }
      }
      genericFormValues
    }
  }
`;

export const QUERY_FETCH_SMS_FOR_TRANSPORT = gql`
  query FetchSmsForTransport($transportationId: ID!) {
    fetchSmsForTransport(transportationId: $transportationId) {
      id
      type
      recipient
      from
      transportationId
      text
      sent
      failed_count
      sentError
      createdAt
    }
  }
`;

export const QUERY_FORWARD_TRANSPORTATION_TYPE = gql`
  query FetchTransportationTypesForForwarding($transportationId: ID!, $languageId: ID!) {
    fetchTransportationTypesForForwarding(transportationId: $transportationId, languageId: $languageId) {
      id
      name
    }
  }
`;

export const QUERY_CHECK_CLEARING = gql`
  query CheckClearing($transportationId: ID!) {
    checkClearing(transportationId: $transportationId)
  }
`;
