import React, { Component, } from 'react';
import {
  arrayOf, number, shape, string,
} from 'prop-types';

import DurationChartColumn from './DurationChartColumn';
import { StyledDurationChart, } from './StyledDurationChart';
import ColumnChart from '../ColumnChart/ColumnChart';
import {
  createLevelsForMaxDuration, getMaxDuration, parseSecondsToTime,
} from './utils';


class DurationChart extends Component {
  constructor(props) {
    super(props);

    const { staticIndex, } = props;

    this.state = {
      selectedColumn: staticIndex !== undefined ? staticIndex : 0,
      maxDuration: 0,
      levels: [],
    };
  }

  componentDidMount = () => {
    this.updateMaxDurationAndLevels();
  }

  componentDidUpdate = (prevProps) => {
    if (this.props !== prevProps) {
      this.updateMaxDurationAndLevels();
    }
  }

  updateMaxDurationAndLevels = () => {
    const { data, } = this.props;
    const maxDuration = getMaxDuration(data);

    // eslint-disable-next-line react/no-did-update-set-state
    this.setState({
      maxDuration,
      levels: createLevelsForMaxDuration(maxDuration),
    });
  }

  handleColumnClick = (index) => {
    this.setState({
      selectedColumn: index,
    });
  }

  render() {
    const { data, selectedLabel, staticIndex, } = this.props;
    const { selectedColumn, maxDuration, levels, } = this.state;

    return (
      <StyledDurationChart>
        <div className="duration-chart">
          <div className="duration-chart--actual-duration">
            <span className="duration-chart--actual-duration-text">
              {selectedLabel}
              :
            </span>
            <span className="duration-chart--actual-duration-value">
              {parseSecondsToTime(data[selectedColumn].duration)}
            </span>
          </div>
          <ColumnChart
            columns={data.map(({ duration, label, }, i) => ({
              label,
              element: (
                <DurationChartColumn
                  duration={duration}
                  maxDuration={maxDuration}
                  selected={i === selectedColumn}
                  clickable={staticIndex === undefined}
                />
              ),
              onClick: staticIndex !== undefined ? undefined : () => this.handleColumnClick(i),
            }))}
            levels={levels}
          />
        </div>
      </StyledDurationChart>
    );
  }
}


DurationChart.defaultProps = {
  staticIndex: undefined,
};


DurationChart.propTypes = {
  data: arrayOf(shape({
    duration: number.isRequired,
    label: string,
  })).isRequired,
  selectedLabel: string.isRequired,
  // if given, columns cannot be clicked
  staticIndex: number,
};


export default DurationChart;
