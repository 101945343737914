import React from 'react';
import { string, } from 'prop-types';

import {
  setDateTodayMinTime,
  setDateTodayMaxTime,
} from '../../../../../logic/date';
import { QUERY_TRANSPORTATION_FUTURE, } from '../../../gql/queries';
import FilteredTable from '../../../../../components/FilteredTable/FilteredTable';
import TableLogic from './TableLogic';
import { SUBSCRIPTION_REMOVE_TRANSPORTATION_FROM_PLAN, } from '../../../gql/subscriptions';
import SubscribeComponent from '../../../../../atoms/SubscribeComponent/SubscribeComponent';


const INIT_FILTER = {
  offset: 0,
  limit: 20,
  sortBy: '',
  order: '',
  params: {
    platform: null,
    no: '',
    from: null,
    to: null,
    truckRegistrationNumber: '',
    transportationType: null,
    companyName: '',
    reservationType: null,
  },
};


const TabAll = ({
  languageId,
  ...rest
}) => (
  <FilteredTable
    initFilter={INIT_FILTER}
    query={QUERY_TRANSPORTATION_FUTURE}
    filterToVariables={(filter) => ({
      ...filter,
      languageId,
      params: {
        ...filter.params,
        platform: undefined,
        platformId: filter.params.platform
          ? filter.params.platform.id
          : null,
        from: filter.params.from === null
          ? undefined
          : setDateTodayMinTime(filter.params.from),
        to: filter.params.to === null
          ? undefined
          : setDateTodayMaxTime(filter.params.to),
        transportationType: undefined,
        transportationTypeId: filter.params.transportationType === null
          ? undefined
          : (filter.params.transportationType.id).toString(),
        reservationType: undefined,
        reservationTypeId: filter.params.reservationType
          ? filter.params.reservationType.id
          : null,
      },
    })}
  >
    {(tableData) => (
      <>
        <SubscribeComponent
          subscribe={() => tableData.queryData.subscribeToMore({
            document: SUBSCRIPTION_REMOVE_TRANSPORTATION_FROM_PLAN,
            variables: { },
            updateQuery: (prev) => {
              tableData.queryData.refetch();
              return prev;
            },
          })}
        />
        <TableLogic
          languageId={languageId}
          {...tableData}
          {...rest}
        />
      </>
    )}
  </FilteredTable>
);


TabAll.propTypes = {
  languageId: string.isRequired,
};


export default TabAll;
