import styled from 'styled-components';


const StyledTab = styled.span`
  margin: 0px 0.2rem -1px 0.2rem;
  min-width: 5rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  padding: 0.75em 1em;
  vertical-align: middle;
  cursor: pointer;

  font: inherit;
  text-decoration: none;
  color: inherit;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  white-space: nowrap;
  user-select: none;
  transition:all 0.3s ease;

  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-top-left-radius: ${(p) => p.theme.input.borderRadius};
  border-top-right-radius: ${(p) => p.theme.input.borderRadius};

  &:hover {
    ${(p) => !p.isActive && `
      border-color: ${p.theme.grey.t100};
      border-bottom: 1px solid ${p.theme.grey.t300};
    `}
  }
  
  ${(p) => p.isActive && `
    background: ${p.theme.white};
    border-top: 1px solid ${p.theme.grey.t300};
    border-left: 1px solid ${p.theme.grey.t300};
    border-right: 1px solid ${p.theme.grey.t300};
    border-bottom: 1px solid ${p.theme.white};
  `}


  ${(p) => p.disabled && `
    cursor: default;
    color: ${p.theme.grey.t500};
    background: ${p.theme.grey.t200};
    border-top: 1px solid ${p.theme.grey.t200};
    border-left: 1px solid ${p.theme.grey.t200};
    border-right: 1px solid ${p.theme.grey.t200};
    border-bottom: 1px solid ${p.theme.black};
  `}
`;


export default StyledTab;
