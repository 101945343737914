import React, { Component, } from 'react';
import {
  shape, string, func, bool,
} from 'prop-types';

import Success from '../../styles/icons/Check';
import Error from '../../styles/icons/Error';
import { NOTIFICATIONS, } from '../../globals';

import StyledNotification from './styles/StyledNotification';


class Notification extends Component {
  constructor(props) {
    super(props);

    this.killMyself = null;
  }


  componentDidMount() {
    this.killMyself = setTimeout(this.handleOnTimeout, 3000);
  }


  componentWillUnmount() {
    if (this.killMyself !== null) {
      clearTimeout(this.killMyself);
      this.killMyself = null;
    }
  }


  handleOnTimeout = () => {
    const { data, onRemove, } = this.props;

    this.killMyself = null;
    onRemove(data.id);
  }


  handleOnMouseEnter = () => {
    if (this.killMyself !== null) {
      clearTimeout(this.killMyself);
      this.killMyself = null;
    }
  }


  handleOnMouseLeave = () => {
    if (this.killMyself !== null) {
      clearTimeout(this.killMyself);
      this.killMyself = null;
    }
    this.killMyself = setTimeout(this.handleOnTimeout, 1000);
  }


  handleOnClick = () => {
    if (this.killMyself !== null) {
      clearTimeout(this.killMyself);
      this.killMyself = null;
    }
    this.handleOnTimeout();
  }


  renderIcon = (status) => {
    switch (status) {
      case NOTIFICATIONS.ERROR: {
        return <Error />;
      }

      case NOTIFICATIONS.SUCCESS: {
        return <Success />;
      }

      case NOTIFICATIONS.WARNING: {
        return <Error />;
      }

      default:
        return null;
    }
  }


  render() {
    const {
      data: {
        status,
        title,
        text,
        showIcon,
      },
    } = this.props;

    const icon = this.renderIcon(status);

    return (
      <StyledNotification
        onMouseEnter={this.handleOnMouseEnter}
        onMouseLeave={this.handleOnMouseLeave}
        onClick={this.handleOnClick}
        status={status}
        className="noSelect"
      >
        {(showIcon && icon !== null) && (
          <div className="notifications--icon">
            {icon}
          </div>
        )}
        <div>
          <div className="notifications--title">
            {title}
          </div>
          <div className="notifications--text">
            {text}
          </div>
        </div>
      </StyledNotification>
    );
  }
}


Notification.propTypes = {
  data: shape({
    id: string.isRequired,
    status: string.isRequired,
    title: string.isRequired,
    text: string.isRequired,
    showIcon: bool.isRequired,
  }).isRequired,
  onRemove: func.isRequired,
};


export default Notification;
