import React, { Fragment, } from 'react';
import {
  func, node, oneOfType, arrayOf, string, shape, number, object,
} from 'prop-types';

import ModalBody from '../../../../atoms/Modal/ModalBody';
import ModalHeader from '../../../../atoms/Modal/ModalHeader';
import WizardPanel from '../../../../atoms/Wizard/WizardPanel';
import WizardTab from '../../../../atoms/Wizard/WizardTab';
import FormBackground from '../../../../components/Form/FormBackground';
import StyledWizard from './styles/StyledWizard';

const WizardView = ({
  // data
  tabs,
  activeTab,
  completedUntilTab,
  children,
  translations,
  // methods
  onClose,
  onChangeTab,
}) => (
  <Fragment>
    <ModalHeader onClose={onClose}>
      {translations.stand.actionWizardTitle}
    </ModalHeader>
    <ModalBody disablePadding>
      <StyledWizard>

        <div className="standWizard--wizard">
          <WizardPanel>
            {tabs.map((tab, index) => (
              <WizardTab
                key={tab.actionId}
                preText={index + 1}
                name={index}
                activeTab={activeTab}
                onClick={onChangeTab}
                disabled={index > completedUntilTab}
              >
                {tab.title}
              </WizardTab>
            ))}
            <WizardTab
              preText={tabs.length + 1}
              name={tabs.length}
              activeTab={activeTab}
              onClick={onChangeTab}
              disabled={tabs.length > completedUntilTab}
            >
              {translations.stand.actionWizardTabFinish}
            </WizardTab>
          </WizardPanel>
        </div>

        <FormBackground>
          {children}
        </FormBackground>

      </StyledWizard>
    </ModalBody>
  </Fragment>
);


WizardView.propTypes = {
  // data
  tabs: arrayOf(shape({
    actionId: string.isRequired,
    title: string.isRequired,
  })).isRequired,
  activeTab: number.isRequired,
  completedUntilTab: number.isRequired,
  children: oneOfType([ node, arrayOf(node), ]).isRequired,
  translations: object.isRequired,
  // methods
  onClose: func.isRequired,
  onChangeTab: func.isRequired,
};


export default WizardView;
