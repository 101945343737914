import React from 'react';

import Loader from '../../atoms/Loader/Loader';

import StyledPart from './styles/StyledPart';


const PartLoading = ({ ...rest }) => (
  <StyledPart {...rest}>
    <span className="parts-watermark noSelect">
      <Loader />
    </span>
  </StyledPart>
);


export default PartLoading;
