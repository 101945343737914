import React from 'react';
import { object, arrayOf, } from 'prop-types';

import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import ValueText from '../../../../atoms/Values/ValueText';


const OrderView = ({
  translations,
  orders,
}) => (
  <>
    <fieldset>
      <legend>{translations.transportation.titleOrderNumbers}</legend>

      <div>
        <Row>
          <Col MD={12}>
            {translations.transportation.orderNumber}
          </Col>
          <Col MD={12}>
            {translations.transportation.orderCountry}
          </Col>
        </Row>

        {orders.length === 0 && (
          <Row>
            <Col MD={12}>
              <ValueText>-</ValueText>
            </Col>
          </Row>
        )}

        {orders.length > 0 && orders.map((order) => (
          <Row>
            <Col MD={12}>
              {order.no}
            </Col>
            <Col MD={12}>
              {order.country}
            </Col>
          </Row>
        ))}
      </div>

    </fieldset>
  </>
);


OrderView.propTypes = {
  translations: object.isRequired,
  orders: arrayOf(object),
};


export default OrderView;
