import React from 'react';
import {
  arrayOf, oneOfType, node,
} from 'prop-types';


const FlowLineLeft = ({ children, }) => (
  <div className="flowLine--left">
    {children}
  </div>
);


FlowLineLeft.propTypes = {
  children: oneOfType([
    arrayOf(node),
    node,
  ]),
};

FlowLineLeft.defaultProps = {
  children: null,
};


export default FlowLineLeft;
