export const getRoles = (translations) => [
  { id: 'driver', name: translations.rs.users.driver, },
  { id: 'admin', name: translations.rs.users.admin, },
];

export const getRoleText = (role, translations) => {
  if (role === 'driver') {
    return translations.rs.users.driver;
  }
  if (role === 'admin') {
    return translations.rs.users.admin;
  }
  return '-';
};
