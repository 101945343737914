import gql from 'graphql-tag';


export const MUTATION_PARKING_DELETE_CAR = gql`
  mutation RemoveCarFromParking($id: ID) {
    removeCarFromParking(id: $id)
  }
`;

export const MUTATION_PARKING_ADD_CAR = gql`
  mutation AddCarToParking($registrationNumber: String) {
    addCarToParking(registrationNumber: $registrationNumber)
  }
`;


export const MUTATION_PARKING_UPDATE_CAR = gql`
  mutation UpdateCarOnParking($id: ID, $registrationNumber: String) {
    updateCarOnParking(id: $id, registrationNumber: $registrationNumber)
  }
`;

export const MUTATION_INTERNAL_PARKING_DELETE_CAR = gql`
  mutation RemoveCarFromInternalParking($id: ID) {
    removeCarFromInternalParking(id: $id)
  }
`;
