import styled from 'styled-components';


const StyledFilterStandardView = styled.div`
  .filterStandard--divider {
    margin: 0.5rem 0;
    height: 1px;
    background: ${(p) => p.theme.grey.t400};
  }
`;


export default StyledFilterStandardView;
