import React from 'react';
import {
  shape, string,
} from 'prop-types';

import { ORDER, } from '../../globals';

import SortDefault from '../../styles/icons/SortDefault';
import SortAsc from '../../styles/icons/SortAsc';
import SortDesc from '../../styles/icons/SortDesc';


const SortIcon = ({ name, filter, }) => {
  if (filter.sortBy !== name) return <SortDefault />;
  if (filter.order === ORDER.ASC) return <SortAsc />;
  return <SortDesc />;
};


SortIcon.propTypes = {
  // data
  name: string.isRequired,
  filter: shape({
    sortBy: string.isRequired,
    order: string.isRequired,
  }).isRequired,
};


export default SortIcon;
