import styled from 'styled-components';


const StyledModuleGrid = styled.div`
  .module--list {
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin-top: 0;
    margin-right: -${(p) => p.theme.common.paddingHalf};
    margin-left: -${(p) => p.theme.common.paddingHalf};
    margin-bottom: 3rem;
  }

  .module--grid {
    padding-right: ${(p) => p.theme.common.paddingHalf};
    padding-left: ${(p) => p.theme.common.paddingHalf};
  }

  .module--grid-content {
    display: flex;
    width: 22.5rem;
    height: 7rem;
    margin-bottom: ${(p) => p.theme.common.padding};
    overflow: hidden;
    align-items: center;

    color: ${(p) => p.theme.white};
    text-decoration: none;
    background: ${(p) => p.theme.sidebar.background};
    box-shadow: 0 2px 2px rgba(0,0,0,.24), 0 0 2px rgba(0,0,0,.3);
    cursor: pointer;
  }

  .module--grid-content:hover {
    background: ${(p) => p.theme.primary.t900};
  }

  .module--grid-col1 {
    font-size: 2.5rem;
    padding: 0 ${(p) => p.theme.common.padding};
    color: ${(p) => p.theme.primary.t700};
  }

  .module--grid-col2 {
    width: 100%;
    padding-right: ${(p) => p.theme.common.padding};
    overflow: hidden;
  }

  .module--grid-title {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 0.15rem;
    color: ${(p) => p.theme.grey.t50};
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden; 
    white-space: nowrap;
  }

  .module--grid-text {
    color: ${(p) => p.theme.sidebar.color};
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden; 
    white-space: nowrap;
  }
`;


export default StyledModuleGrid;
