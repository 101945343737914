import React, { Fragment, } from 'react';
import {
  bool, shape, arrayOf, string, object, func, number,
} from 'prop-types';

import { QUERY_RESERVATIONS_BY_DAY, } from '../../gql/queries';
import { parseTimeToHuman, } from '../../../../logic/date';
import Input from '../../../../atoms/Input/Input';
import Table from '../../../../atoms/Table/Table';
import Pagination from '../../../../components/Pagination/Pagination';
import TableLoading from '../../../../atoms/Table/TableLoading';
import TableError from '../../../../atoms/Table/TableError';
import TableNoData from '../../../../atoms/Table/TableNoData';
import FilteredTable from '../../../../components/FilteredTable/FilteredTable';
import Tooltip from '../../../../atoms/Tooltip/Tooltip';
import ButtonIcon from '../../../../atoms/Button/ButtonIcon';
import ButtonGrp from '../../../../atoms/Button/ButtonGrp';
import THSortable from '../../../../atoms/Table/THSortable';
import Search from '../../../../styles/icons/Search';
import InputTimeSelect from '../../../../atoms/InputTimeSelect/InputTimeSelect';
import Bin from '../../../../styles/icons/Bin';
import { setTimeFromDateToDate, } from './utils';


const COL_COUNT = 6;
const INIT_FILTER = {
  offset: 0,
  limit: 20,
  sortBy: '',
  order: '',
  params: {
    transportationType: '',
    reservationTimeFrom: null,
    reservationTimeTo: null,
    truckRegistrationNumber: '',
    driverPhoneNumber: '',
  },
};


const Header = ({
  // data
  selectedDate,
  filter,
  translations,
  // methods
  onChangeSort,
  onChangeParam,
}) => (
  <thead>
    <tr>
      <THSortable
        title={translations.form.transportationTypeName}
        name="transportationType"
        isActiveFilter={!!filter.params.transportationType}
        filter={filter}
        style={{ minWidth: '10rem', width: '10rem', }}
        onSort={onChangeSort}
      />

      <THSortable
        title={translations.form.arrivalTime}
        name="reservationTime"
        isActiveFilter={!!filter.params.reservationTimeFrom || !!filter.params.reservationTimeTo}
        filter={filter}
        style={{ minWidth: '10rem', width: '10rem', }}
        onSort={onChangeSort}
      />

      <THSortable
        title={translations.form.truckRegistrationNo}
        name="truckRegistrationNumber"
        isActiveFilter={filter.params.truckRegistrationNumber !== ''}
        filter={filter}
        style={{ minWidth: '10rem', width: '10rem', }}
        onSort={onChangeSort}
      />

      <THSortable
        title={translations.form.driverPhoneNumber}
        name="driverPhoneNumber"
        isActiveFilter={filter.params.driverPhoneNumber !== ''}
        filter={filter}
        style={{ minWidth: '10rem', width: '10rem', }}
        onSort={onChangeSort}
      />

      <th
        className="table--header-title table--text-align-right"
        style={{ minWidth: '4rem', width: '4rem', }}
      >
        {translations.transportation.thActions}
      </th>
    </tr>

    <tr>
      <th>
        <Input
          type="text"
          placeholder={translations.common.filter}
          autoComplete="off"
          value={filter.params.transportationType}
          active={filter.params.transportationType !== ''}
          onChange={(e) => onChangeParam('transportationType', e.target.value)}
          size="sm"
        />
      </th>

      <th>
        <InputTimeSelect
          placeholder={translations.common.from}
          size="sm"
          value={filter.params.reservationTimeFrom}
          active={!!filter.params.reservationTimeFrom}
          clearable
          onChange={(newDate) => {
            if (newDate) {
              const dateWithTime = setTimeFromDateToDate(selectedDate, newDate);
              onChangeParam('reservationTimeFrom', dateWithTime, false);
            }
          }}
        />
      </th>

      <th>
        <Input
          type="text"
          placeholder={translations.common.filter}
          autoComplete="off"
          value={filter.params.truckRegistrationNumber}
          active={filter.params.truckRegistrationNumber !== ''}
          onChange={(e) => onChangeParam('truckRegistrationNumber', e.target.value)}
          size="sm"
        />
      </th>

      <th>
        <Input
          type="text"
          placeholder={translations.common.filter}
          autoComplete="off"
          value={filter.params.driverPhoneNumber}
          active={filter.params.driverPhoneNumber !== ''}
          onChange={(e) => onChangeParam('driverPhoneNumber', e.target.value)}
          size="sm"
        />
      </th>

      <th />
    </tr>

    <tr>
      <th />

      <th>
        <InputTimeSelect
          placeholder={translations.common.to}
          size="sm"
          value={filter.params.reservationTimeTo}
          active={!!filter.params.reservationTimeTo}
          clearable
          onChange={(newDate) => {
            if (newDate) {
              const dateWithTime = setTimeFromDateToDate(selectedDate, newDate);
              onChangeParam('reservationTimeTo', dateWithTime, false);
            }
          }}
        />
      </th>

      <th />

      <th />

      <th />
    </tr>
  </thead>
);


Header.propTypes = {
  // data
  selectedDate: object.isRequired,
  filter: object.isRequired,
  translations: object.isRequired,
  // methods
  onChangeSort: func.isRequired,
  onChangeParam: func.isRequired,
};


const Rows = ({
  // data
  loading,
  error,
  data,
  translations,
  resources,
  // methods
  onDetail,
  toggleRemove,
}) => {
  if (error || !data.fetchReservationsFromRSByDay) {
    return (
      <TableError
        colsCount={COL_COUNT}
        error={error}
      />
    );
  }
  if (!data.fetchReservationsFromRSByDay && loading) {
    return (
      <TableLoading
        colsCount={COL_COUNT}
        rowsCount={
          data && data.fetchReservationsFromRSByDay && data.fetchReservationsFromRSByDay.rows.length
        }
      />
    );
  }
  if (data.fetchReservationsFromRSByDay.rows.length < 1) {
    return (
      <TableNoData
        colsCount={COL_COUNT}
        text={translations.transportation.historyEmpty}
      />
    );
  }

  return (
    <tbody>
      {data.fetchReservationsFromRSByDay.rows.map((item) => (
        <tr key={item.id}>
          <td>
            {item.transportationType}
          </td>

          <td>
            {`
              ${item.timeFrom ? parseTimeToHuman(new Date(item.timeFrom)) : ''}
              -
              ${item.timeTo ? parseTimeToHuman(new Date(item.timeTo)) : ''}
            `}
          </td>

          <td>
            {item.truckRegistrationNumber}
          </td>

          <td>
            {item.driverPhoneNumber}
          </td>

          <td className="table--text-align-right table--noPadding">
            <ButtonGrp>
              <Tooltip text={translations.common.detail}>
                {(events) => (
                  <ButtonIcon
                    size="sm"
                    color="tertiary"
                    onClick={() => onDetail(item)}
                    {...events}
                  >
                    <Search />
                  </ButtonIcon>
                )}
              </Tooltip>
              {resources.delete_transportation && (
              <Tooltip text={translations.common.delete}>
                {(events) => (
                  <ButtonIcon
                    size="sm"
                    color="error"
                    onClick={() => toggleRemove(item.id)}
                    {...events}
                  >
                    <Bin />
                  </ButtonIcon>
                )}
              </Tooltip>
              )}
            </ButtonGrp>
          </td>
        </tr>
      ))}
    </tbody>
  );
};

Rows.propTypes = {
  loading: bool.isRequired,
  error: object,
  data: shape({
    fetchReservationsFromRSByDay: shape({
      rows: arrayOf(shape({
        id: string.isRequired,
        transportationType: string.isRequired,
        timeFrom: string.isRequired,
        timeTo: string.isRequired,
        truckRegistrationNumber: string.isRequired,
        driverPhoneNumber: string.isRequired,
      })).isRequired,
    }),
  }),
  translations: object.isRequired,
  resources: object.isRequired,
  onDetail: func.isRequired,
  toggleRemove: func.isRequired,
};

Rows.defaultProps = {
  error: undefined,
  data: undefined,
};


const ReservationOverviewTable = ({
  // data
  selectedDate,
  translations,
  languageId,
  resources,
  refetch,
  // methods
  onDetail,
  toggleRemove,
}) => (
  <FilteredTable
    initFilter={INIT_FILTER}
    query={QUERY_RESERVATIONS_BY_DAY}
    filterToVariables={(filter) => ({
      refetch,
      date: selectedDate,
      ...filter,
      languageId: languageId !== null ? parseInt(languageId, 10) : undefined,
      params: {
        ...filter.params,
      },
    })}
  >
    {({
      // filter
      queryData,
      filter,
      // filter handlers
      onChangeSort,
      onChangeFilter,
      onChangeParam,
    }) => (
      <Fragment>
        <Table fillContent>
          <Header
            // data
            selectedDate={selectedDate}
            filter={filter}
            translations={translations}
            languageId={languageId}
            // methods
            onChangeSort={onChangeSort}
            onChangeParam={onChangeParam}
          />
          <Rows
            // data
            {...queryData}
            translations={translations}
            resources={resources}
            // methods
            onDetail={onDetail}
            toggleRemove={toggleRemove}
          />
        </Table>

        <Pagination
          loading={queryData.loading}
          dataFilter={queryData.data && queryData.data.fetchReservationsFromRSByDay
            ? queryData.data.fetchReservationsFromRSByDay.filter
            : undefined
          }
          onChangePage={onChangeFilter}
        />
      </Fragment>
    )}
  </FilteredTable>
);

ReservationOverviewTable.propTypes = {
  selectedDate: object.isRequired,
  translations: object.isRequired,
  languageId: string.isRequired,
  resources: object.isRequired,
  refetch: number.isRequired,
  onDetail: func.isRequired,
  toggleRemove: func.isRequired,
};

export default ReservationOverviewTable;
