// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
import React from 'react';

import StyledSvg from './styles/StyledSvg';


const ProgressConst = ({ ...props }) => (
  // eslint-disable-next-line react/prop-types
  <StyledSvg viewBox="0 0 264 264" fill={(props?.fill) ?? 'white'} {...props}>
    <g transform="matrix(0, 1, -1, 0, 264, 60)">
      <rect x="53.663" y="32.239" width="32" height="200" rx="16" ry="16" />
      <rect
        x="79.671"
        y="69.223"
        width="32"
        height="99.999"
        rx="16"
        ry="16"
        transform="matrix(0.707107, -0.707107, 0.71097, 0.703244, -58.500164, 54.933941)"
      />
      <rect
        x="79.671"
        y="69.223"
        width="32"
        height="99.999"
        rx="16"
        ry="16"
        transform="matrix(0.707107, 0.707107, -0.703244, 0.71097, 61.96571, -81.091194)"
      />
    </g>
  </StyledSvg>
);

export default ProgressConst;
