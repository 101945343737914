import React, { Component, } from 'react';
import { func, object, string, } from 'prop-types';

import { withNotifications, } from '../../../../../logic/notifications/withNotifications';
import Modal from '../../../../../atoms/Modal/Modal';
import ModalStopClearingView from './ModalStopClearingView';
import { stopClearingFormStructure, } from '../forms';
import { changeAndValidateInput, validateAndMergeWholeForm, } from '../../../../../logic/form/common';


class ModalStopClearing extends Component {
  state = {
    stopClearingForm: { ...stopClearingFormStructure, },
  }


  handleChangeStopClearingForm = (name, value) => {
    const { stopClearingForm, } = this.state;


    this.setState({
      stopClearingForm: changeAndValidateInput(stopClearingForm, name, value),
    });
  }


  handleStopClearingTransportation = (mutationStopClearingTransportation) => {
    const { platformId, transportationId, } = this.props;
    const {
      stopClearingForm,
    } = this.state;

    const form = validateAndMergeWholeForm(stopClearingForm);

    // check form
    if (!form.isValid) {
      this.setState({
        stopClearingForm: form,
      });
    } else {
      mutationStopClearingTransportation({
        variables: {
          platformId,
          transportationId,
          note: form.values.note,
        },
      });
    }
  }


  handleStopClearingTransportationCompleted = () => {
    const { translations, addNotification, onToggle, } = this.props;

    onToggle();
    addNotification({
      status: 'success',
      title: translations.centralAppStorekeeper.notifTitleInterruptSuccess,
    });
  }


  handleStopClearingTransportationError = () => {
    const { translations, addNotification, } = this.props;

    addNotification({
      status: 'error',
      title: translations.centralAppStorekeeper.notifTitleInterruptError,
    });
  }

  render() {
    const {
      // data
      translations,
      // methods
      onToggle,
    } = this.props;
    const { stopClearingForm, } = this.state;

    return (
      <Modal
        isOpen
        onClose={onToggle}
        size="SM"
        title={translations.centralAppStorekeeper.titleModalInterrupt}
        disablePadding
      >
        <ModalStopClearingView
          // data
          translations={translations}
          stopForm={stopClearingForm}
          // methods
          onToggle={onToggle}
          onChangeForm={this.handleChangeStopClearingForm}
          // delay
          onStopClearingTransportation={this.handleStopClearingTransportation}
          onStopClearingTransportationCompleted={this.handleStopClearingTransportationCompleted}
          onStopClearingTransportationError={this.handleStopClearingTransportationError}
        />
      </Modal>
    );
  }
}


ModalStopClearing.propTypes = {
  // data
  platformId: string.isRequired,
  transportationId: string.isRequired,
  translations: object.isRequired,
  // methods
  onToggle: func.isRequired,
  addNotification: func.isRequired,
};


export default withNotifications(ModalStopClearing);
