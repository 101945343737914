/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import React, { Fragment, } from 'react';
import { func, object, string, } from 'prop-types';
import { Mutation, } from 'react-apollo';

import { MUTATION_PARKING_UPDATE_CAR, } from '../../gql/mutations';
import Button from '../../../../atoms/Button/Button';
import ButtonLoader from '../../../../atoms/Button/ButtonLoader';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import Label from '../../../../components/Form/Label';
import FormError from '../../../../components/Form/FormError';
import AddCarToParking from '../forms/AddCarToParking';
import FormBackground from '../../../../components/Form/FormBackground';

const { REACT_APP_REST, } = process.env;

const UpdateCarOnParkingView = ({
  // data
  resources,
  detailForm,
  translations,
  languageId,
  picture,
  // methods
  onChange,
  onToggle,
  onUpdate,
  onUpdateCompleted,
  onUpdateError,
}) => (
  <FormBackground>
    <AddCarToParking
      {...detailForm}
      languageId={languageId}
      translations={translations}
      onChange={onChange}
    />

    <div style={{ marginBottom: '1.5rem', }}>
      <Label label={translations.common.pictureLabelParkIn} />
      { picture && (
        <div>
          <a href={`${REACT_APP_REST}/images/${picture}`} target="_blank">
            <img
              src={`${REACT_APP_REST}/images/${picture}`}
              alt="park_in_picture"
              width="150"
              height="150"
            />
          </a>
        </div>
      )}
    </div>

    <Mutation
      mutation={MUTATION_PARKING_UPDATE_CAR}
      onCompleted={onUpdateCompleted}
      onError={onUpdateError}
    >
      {(mutate, { loading, error, }) => (
        <Fragment>
          <FormError
            isValid={detailForm.isValid}
            error={error}
          />
          <Row>
            <Col XS={12} SM={12}>
              <Button
                type="button"
                shape="outline"
                onClick={onToggle}
              >
                {translations.common.back}
              </Button>
            </Col>
            <Col XS={12} SM={12} textAlign="right">
              {resources.directory_truck_write && (
                <ButtonLoader
                  type="button"
                  color="success"
                  onClick={() => onUpdate(mutate)}
                  disabled={!detailForm.isValid}
                  isLoading={loading}
                >
                  {translations.common.update}
                </ButtonLoader>
              )}
            </Col>
          </Row>
        </Fragment>
      )}
    </Mutation>
  </FormBackground>
);


UpdateCarOnParkingView.propTypes = {
  resources: object.isRequired,
  translations: object.isRequired,
  languageId: string.isRequired,
  picture: string,
  detailForm: object.isRequired,
  onChange: func.isRequired,
  onToggle: func.isRequired,
  onUpdate: func.isRequired,
  onUpdateError: func.isRequired,
  onUpdateCompleted: func.isRequired,
};

UpdateCarOnParkingView.defaultProps = {
  picture: '',
};


export default UpdateCarOnParkingView;
