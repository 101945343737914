import React, { Component, } from 'react';
import {
  func, object, string, shape,
} from 'prop-types';
import { withApollo, } from 'react-apollo';

import { getInitTimelineFilter, getFilterHoursOptions, } from './utils';
import { addHoursToDate, } from '../../../../logic/date';
import TimelineFilterView from './TimelineFilterView';
import { MUTATION_SCHEDULE_TIMELINE_FILTER, } from '../../gql/mutations';


class TimelineFilter extends Component {
  constructor(props) {
    super(props);

    const { initFilter, translations, } = props;

    const optionsRange = getFilterHoursOptions(translations);
    const filter = getInitTimelineFilter(initFilter, optionsRange);

    this.state = {
      from: new Date(filter.from),
      to: new Date(filter.to),
      transportationType: filter.transportationType,
      range: filter.range,
      optionsRange,
    };
  }


  handleChangeFilter = (name, value) => {
    const { from, range, } = this.state;

    switch (name) {
      case 'from': {
        if (!value) break;
        this.setState({
          [name]: value,
          to: addHoursToDate(value, range.value),
        });
        break;
      }

      case 'range': {
        if (!value) break;
        this.setState({
          [name]: value,
          to: addHoursToDate(from, value.value),
        });
        break;
      }

      default: {
        this.setState({ [name]: value, });
        break;
      }
    }
  }


  /**
   * onConfirm
   */
  handleConfirm = () => {
    const {
      from, to, transportationType, range,
    } = this.state;
    const { client, } = this.props;

    client.mutate({
      mutation: MUTATION_SCHEDULE_TIMELINE_FILTER,
      variables: {
        filter: {
          from,
          to,
          transportationType,
          range,
        },
      },
    });
  }


  render() {
    const {
      transportationType,
      from,
      range,
      optionsRange,
    } = this.state;
    const {
      languageId,
      translations,
    } = this.props;

    return (
      <TimelineFilterView
        // data
        translations={translations}
        languageId={languageId}
        transportationType={transportationType}
        from={from}
        range={range}
        optionsRange={optionsRange}
        // methods
        onChange={this.handleChangeFilter}
        onConfirm={this.handleConfirm}
      />
    );
  }
}


TimelineFilter.propTypes = {
  // data
  translations: object.isRequired,
  languageId: string.isRequired,
  initFilter: object,
  client: shape({
    mutate: func.isRequired,
  }).isRequired,
};

TimelineFilter.defaultProps = {
  initFilter: {},
};


export default withApollo(TimelineFilter);
