import React from 'react';
import { object, } from 'prop-types';

import StyledTimelineLegendLegend from './styles/StyledTimelineLegendLegend';
import Circle from '../../../../styles/icons/Circle';


const TimelineLegend = ({ translations, }) => (
  <StyledTimelineLegendLegend>

    <h5>{translations.transportation.titleScheduleTimelineLegend}</h5>

    <ul>
      <li>
        <div>
          <Circle className="timelineLegend--circle timelineLegend--circle1" />
        </div>
        <div>
          {translations.dropDownOptions.arrivalStateDropDown.planned}
        </div>
      </li>
      <li>
        <div>
          <Circle className="timelineLegend--circle timelineLegend--circle2" />
        </div>
        <div>
          {translations.dropDownOptions.arrivalStateDropDown.ok}
        </div>
      </li>
      <li>
        <div>
          <Circle className="timelineLegend--circle timelineLegend--circle3" />
        </div>
        <div>
          {translations.dropDownOptions.arrivalStateDropDown.premature}
        </div>
      </li>
      <li>
        <div>
          <Circle className="timelineLegend--circle timelineLegend--circle4" />
        </div>
        <div>
          {translations.dropDownOptions.arrivalStateDropDown.delayed}
        </div>
      </li>
      <li>
        <div>
          <Circle className="timelineLegend--circle timelineLegend--circle5" />
        </div>
        <div>
          {translations.dropDownOptions.arrivalStateDropDown.notComing}
        </div>
      </li>
      <li>
        <div>
          <Circle className="timelineLegend--circle timelineLegend--circle6" />
        </div>
        <div>
          {translations.dropDownOptions.arrivalStateDropDown.inactive}
        </div>
      </li>
    </ul>

  </StyledTimelineLegendLegend>
);


TimelineLegend.propTypes = {
  translations: object.isRequired,
};


export default TimelineLegend;
