import React from 'react';

import StyledSvg from './styles/StyledSvg';


const Calendar = ({ ...props }) => (
  // eslint-disable-next-line react/prop-types
  <StyledSvg viewBox="0 0 1025 1024" fill={(props?.fill) ?? 'black'} {...props}>
    <path
      d="M924.903226 1024H100.097752A100.097752 100.097752 0 0 1 0 923.902248V222.217009a100.097752 100.097752 0 0 1
      100.097752-100.097752h824.805474a100.097752 100.097752 0 0 1 100.097752 100.097752v701.685239a100.097752
      100.097752 0 0 1-100.097752 100.097752zM100.097752 202.197458a20.01955 20.01955 0 0 0-20.019551
      20.019551v701.685239a20.01955 20.01955 0 0 0 20.019551 20.019551h824.805474a20.01955 20.01955 0 0 0
      20.01955-20.019551V222.217009a20.01955 20.01955 0 0 0-20.01955-20.019551z"
    />
    <path
      d="M237.231672 282.27566a97.094819 97.094819 0 0 1-97.09482-97.094819V97.094819a97.094819 97.094819 0 0 1
      194.189639 0 40.039101 40.039101 0 0 1-80.078202 0 17.016618 17.016618 0 0 0-34.033235 0v88.086022a17.016618
      17.016618 0 0 0 17.016618 17.016617 40.039101 40.039101 0 0 1 0 80.078202zM787.769306 282.27566a97.094819
      97.094819 0 0 1-97.094819-97.094819V97.094819a97.094819 97.094819 0 0 1 194.189638 0 40.039101 40.039101 0 0
      1-80.078201 0 17.016618 17.016618 0 0 0-34.033236 0v88.086022a17.016618 17.016618 0 0 0 17.016618 17.016617
      40.039101 40.039101 0 0 1 0 80.078202zM512.500489 282.27566a97.094819 97.094819 0 0
      1-97.094819-97.094819V97.094819a97.094819 97.094819 0 0 1 194.189638 0 40.039101 40.039101 0 0 1-80.078201 0
      17.016618 17.016618 0 0 0-34.033236 0v88.086022a17.016618 17.016618 0 0 0 17.016618 17.016617 40.039101 40.039101
      0 0 1 0 80.078202zM950.928641 428.418377H65.063539a40.039101 40.039101 0 0 1 0-80.078201h885.865102a40.039101
      40.039101 0 0 1 0 80.078201zM213.208211 653.638319a73.071359 73.071359 0 1 1 73.071359-72.070382 73.071359
      73.071359 0 0 1-73.071359 72.070382zM412.402737 653.638319a73.071359 73.071359 0 1 1 73.071359-72.070382
      73.071359 73.071359 0 0 1-73.071359 72.070382zM612.59824 653.638319a73.071359 73.071359 0 1 1 73.071359-72.070382
      73.071359 73.071359 0 0 1-73.071359 72.070382zM812.793744 653.638319a73.071359 73.071359 0 1 1 73.071359-72.070382
      73.071359 73.071359 0 0 1-73.071359 72.070382zM213.208211 860.840665a73.071359 73.071359 0 1 1 73.071359-72.070382
      73.071359 73.071359 0 0 1-73.071359 72.070382zM412.402737 860.840665a73.071359 73.071359 0 1 1 73.071359-72.070382
      73.071359 73.071359 0 0 1-73.071359 72.070382zM612.59824 860.840665a73.071359 73.071359 0 1 1 73.071359-72.070382
      73.071359 73.071359 0 0 1-73.071359 72.070382z"
    />
  </StyledSvg>
);

export default Calendar;
