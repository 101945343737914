import React, { Component, Fragment, } from 'react';
import {
  object, shape, string,
} from 'prop-types';

import StandView from './StandView';
import VisitorCreate from '../VisitorCreate/VisitorCreate';
import Wizard from '../Wizard/Wizard';
import ConfirmWizard from '../ConfirmWizard/ConfirmWizard';
import TransportationCreate from '../../../Transportation/components/TransportationCreate/TransportationCreate';
import TransportationDetail from '../../../Transportation/components/TransportationDetail/TransportationDetail';
import VisitorDeparture from '../VisitorDeparture/VisitorDeparture';
import StandCall from '../StandCall/StandCall';


class StandLogic extends Component {
  constructor(props) {
    super(props);

    let isAnyTabAvailable = false;
    let activeTab = 0;
    for (let i = 0; i < props.standConfiguration.tabs.length; i++) {
      const { enable, } = props.standConfiguration.tabs[i];
      if (enable) {
        isAnyTabAvailable = true;
        activeTab = i;
        break;
      }
    }

    this.state = {
      activeTab,
      isAnyTabAvailable,
      createVisitorModal: {
        isOpen: false,
      },
      departureVisitorModal: {
        isOpen: false,
        id: null,
      },
      createTransportationModal: {
        isOpen: false,
      },
      confirmStandWizardModal: {
        isOpen: false,
        id: null,
        isLoading: null,
      },
      standWizardModal: {
        isOpen: false,
        id: null,
        isLoading: null,
      },
      detailModal: {
        isOpen: false,
        id: null,
      },
      soeCallModal: {
        isOpen: false,
        standId: null,
        transportationId: null,
      },
      technicalCheckCallModal: {
        isOpen: false,
        standId: null,
        transportationId: null,
      },
    };
  }


  /**
   * Tab Change
   */
  handleChangeTab = (tab) => {
    this.setState({
      activeTab: tab,
    });
  }


  /**
   * Transportation Action
   *  - first time (!active) -> confirm modal
   *  - continue (active) -> wizard
   */
  handleTransportAction = (transport) => {
    if (transport.active && transport.active === true) {
      this.handleToggleStandWizard(transport.id, transport.isLoading);
    } else {
      this.handleToggleConfirmStandWizard(transport.id, transport.isLoading);
    }
  }


  /**
   * Create Visitor
   */
  handleToggleCreateVisitor = () => {
    this.setState((prevState) => ({
      createVisitorModal: {
        ...prevState.createVisitorModal,
        isOpen: !prevState.createVisitorModal.isOpen,
      },
    }));
  }


  /**
   * Departure Visitor
   */
  handleToggleDepartureVisitor = (visitor) => {
    this.setState((prevState) => ({
      departureVisitorModal: {
        ...prevState.departureVisitorModal,
        isOpen: !prevState.departureVisitorModal.isOpen,
        id: visitor ? visitor.id : null,
      },
    }));
  }


  /**
   * Create Transport
   */
  handleToggleCreateTransportation = () => {
    this.setState((prevState) => ({
      createTransportationModal: {
        ...prevState.createTransportationModal,
        isOpen: !prevState.createTransportationModal.isOpen,
      },
    }));
  }


  /**
   * Wizard
   */
  handleToggleStandWizard = (id = null, isLoading = null) => {
    this.setState((prevState) => ({
      standWizardModal: {
        ...prevState.standWizardModal,
        isOpen: !prevState.standWizardModal.isOpen,
        id,
        isLoading,
      },
    }));
  }


  /**
   * Confirm Wizard
   */
  handleToggleConfirmStandWizard = (id = null, isLoading = null) => {
    this.setState((prevState) => ({
      confirmStandWizardModal: {
        ...prevState.confirmStandWizardModal,
        isOpen: !prevState.confirmStandWizardModal.isOpen,
        id,
        isLoading,
      },
    }));
  }


  /**
   * Transportation Detail
   */
  handleToggleDetailModal = (transportation) => {
    this.setState((prevState) => ({
      detailModal: {
        ...prevState.detailModal,
        isOpen: !prevState.detailModal.isOpen,
        id: transportation ? transportation.id : null,
      },
    }));
  }


  handleTransportationCreatedAndCallIt = (response) => {
    if (response.createTransportation && response.createTransportation.id) {
      this.handleToggleStandWizard(response.createTransportation.id);
    }
  }

  /**
   * Soe Call
   */
  handleToggleSoeCallModal = (data) => {
    this.setState((prevState) => ({
      soeCallModal: {
        ...prevState.soeCallModal,
        isOpen: !prevState.soeCallModal.isOpen,
        transportationId: data && data.transportationId ? data.transportationId : null,
        standId: data && data.standId ? data.standId : null,
      },
    }));
  }

  /**
   * Soe Call
   */
  handleToggleTechnicalCheckCallModal = (data) => {
    this.setState((prevState) => ({
      technicalCheckCallModal: {
        ...prevState.technicalCheckCallModal,
        isOpen: !prevState.technicalCheckCallModal.isOpen,
        transportationId: data && data.transportationId ? data.transportationId : null,
        standId: data && data.standId ? data.standId : null,
      },
    }));
  }

  render() {
    const {
      activeTab,
      isAnyTabAvailable,
      createVisitorModal,
      departureVisitorModal,
      createTransportationModal,
      confirmStandWizardModal,
      standWizardModal,
      detailModal,
      soeCallModal,
      technicalCheckCallModal,
    } = this.state;
    const {
      resources,
      languageId,
      stand,
      translations,
      standTranslations,
      standConfiguration,
    } = this.props;

    return (
      <Fragment>
        {createVisitorModal.isOpen && (
          <VisitorCreate
            modalData={createVisitorModal}
            onToggle={this.handleToggleCreateVisitor}
          />
        )}

        {departureVisitorModal.isOpen && (
          <VisitorDeparture
            modalData={departureVisitorModal}
            onToggle={this.handleToggleDepartureVisitor}
          />
        )}

        {standWizardModal.isOpen && (
          <Wizard
            modalData={standWizardModal}
            standId={stand.id}
            onToggle={this.handleToggleStandWizard}
          />
        )}

        {confirmStandWizardModal.isOpen && (
          <ConfirmWizard
            modalData={confirmStandWizardModal}
            translations={translations}
            // methods
            onConfirm={this.handleToggleStandWizard}
            onToggle={this.handleToggleConfirmStandWizard}
          />
        )}

        {createTransportationModal.isOpen && (
          <TransportationCreate
            modalData={createTransportationModal}
            extraCreateButton={{
              text: translations.stand.btnCreateTransportationAndRegistration,
              btnProps: {
                color: 'warning',
              },
            }}
            onToggle={this.handleToggleCreateTransportation}
            onCreatedExtraCreateBtn={this.handleTransportationCreatedAndCallIt}
          />
        )}

        {detailModal.isOpen && (
          <TransportationDetail
            transportationId={detailModal.id}
            onToggle={this.handleToggleDetailModal}
          />
        )}

        {soeCallModal.isOpen && (
          <StandCall
            transportationId={soeCallModal.transportationId}
            standId={soeCallModal.standId}
            isOpen={soeCallModal.isOpen}
            options={[ 1, 2, 3, 4, ].map((n) => ({
              id: `soe${n}`,
              name: `SOE ${n}`,
            })).concat({ id: 'izd', name: 'IZD', })}
            onToggle={this.handleToggleSoeCallModal}
          />
        )}

        {technicalCheckCallModal.isOpen && (
          <StandCall
            transportationId={technicalCheckCallModal.transportationId}
            standId={technicalCheckCallModal.standId}
            isOpen={technicalCheckCallModal.isOpen}
            options={[ 1, 2, ].map((n) => ({
              id: `tk${n}`,
              name: `TK ${n}`,
            }))}
            onToggle={this.handleToggleTechnicalCheckCallModal}
          />
        )}

        <StandView
          // data
          resources={resources}
          translations={translations}
          standTranslations={standTranslations}
          languageId={languageId}
          isAnyTabAvailable={isAnyTabAvailable}
          activeTab={activeTab}
          data={standConfiguration}
          stand={stand}
          // methods
          onChangeTab={this.handleChangeTab}
          onToggleCreateVisitor={this.handleToggleCreateVisitor}
          onDepartureVisitor={this.handleToggleDepartureVisitor}
          onToggleCreateTransportation={this.handleToggleCreateTransportation}
          onTransportAction={this.handleTransportAction}
          onToggleDetail={this.handleToggleDetailModal}
          onToggleSoeCallStand={this.handleToggleSoeCallModal}
          onToggleTechnicalCheckCallStand={this.handleToggleTechnicalCheckCallModal}
        />
      </Fragment>
    );
  }
}


StandLogic.propTypes = {
  resources: object.isRequired,
  languageId: string.isRequired,
  translations: object.isRequired,
  standTranslations: object.isRequired,
  standConfiguration: object.isRequired,
  stand: shape({
    id: string.isRequired,
    name: string.isRequired,
  }).isRequired,
};


export default StandLogic;
