// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
import React from 'react';
import { Line, } from 'recharts';

import {
  LineChartLineDefault,
  LineChartLineDotDefault,
  LineChartLineLabelDefault,
  findConditionalConfig,
} from '../../utils/config';
import { createFieldOrDefault, } from '../../../../logic/object';


const getLabelProps = (config) => {
  const getField = createFieldOrDefault(config, LineChartLineLabelDefault);

  if (getField('hidden')) {
    return false;
  }

  return {
    fill: getField('color'),
    fontSize: getField('fontSize'),
  };
};

const getDotProps = (
  props,
  configs,
) => {
  const {
    cx, cy, value, dataKey, payload,
  } = props;

  const config = findConditionalConfig(configs, value, payload[`${dataKey}Prev`]);
  const getField = createFieldOrDefault(config, LineChartLineDotDefault);

  if (getField('hidden')) {
    return <></>;
  }

  const color = getField('color');
  const size = getField('size');
  const sizeHalf = size / 2;

  return (
    <svg key={cx} x={cx - sizeHalf} y={cy - sizeHalf} width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
      <circle cx={sizeHalf} cy={sizeHalf} r={sizeHalf} fill={color} />
    </svg>
  );
};

/**
 * Do not use it as component <LineChartLine {...config} /> but as a function LineCartLine(config).
 * Custom components does not work in Recharts.
 */
const LineChartLine = (config) => {
  const getField = createFieldOrDefault(config, LineChartLineDefault);

  return (
    <Line
      key={getField('dataKey')}
      type={getField('type')}
      dataKey={getField('dataKey')}
      stroke={getField('color')}
      strokeWidth={`${getField('lineWidth')}px`}
      label={getLabelProps(config?.label)}
      dot={(props) => getDotProps(props, config?.dot)}
    />
  );
};


export default LineChartLine;
