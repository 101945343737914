import React, { Component, } from 'react';
import { object, string, } from 'prop-types';

import { withTranslations, } from '../../../logic/translations/withTranslations';
import { QUERY_SMS_VIEW_TRANSLATIONS, } from '../gql/translations';
import Breadcrumb from '../../../atoms/Breadcrumb/Breadcrumb';
import BreadcrumbItem from '../../../atoms/Breadcrumb/BreadcrumbItem';
import PageFullScreen from '../../../atoms/PageView/PageFullScreen';
import SmsView from '../components/SmsAdministration/SmsView';


class SmsAdministrationPage extends Component {
  state = {
    updateModal: {
      isOpen: false,
      id: null,
    },
  }

  handleToggleUpdate = (e, id = null) => {
    this.setState((prevState) => ({
      updateModal: {
        ...prevState.updateModal,
        isOpen: !prevState.updateModal.isOpen,
        id,
      },
    }));
  }

  render() {
    const { updateModal, } = this.state;
    const { resources, translations, languageId, } = this.props;

    return (
      <PageFullScreen>

        <Breadcrumb>
          <BreadcrumbItem to="/">{translations.common.home}</BreadcrumbItem>
          <BreadcrumbItem>{translations.common.smsAdministration}</BreadcrumbItem>
        </Breadcrumb>

        <SmsView
          translations={translations}
          resources={resources}
          languageId={languageId}
          onToggleUpdate={this.handleToggleUpdate}
          updateModal={updateModal}
        />

      </PageFullScreen>
    );
  }
}


SmsAdministrationPage.propTypes = {
  resources: object.isRequired,
  translations: object.isRequired,
  languageId: string.isRequired,
};


export default withTranslations(QUERY_SMS_VIEW_TRANSLATIONS)(SmsAdministrationPage);
