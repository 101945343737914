export const MILLIS_IN_MINUTE = 60 * 1000;
export const MILLIS_IN_HOUR = 60 * MILLIS_IN_MINUTE;
export const MILLIS_IN_DAY = 24 * MILLIS_IN_HOUR;
export const MILLIS_IN_WEEK = 7 * MILLIS_IN_DAY;

/**
 * Date - addHours
 */
export const addHoursToDate = (d, h) => {
  const newDate = new Date();
  newDate.setTime(d.getTime() + (h * 60 * 60 * 1000));
  return newDate;
};


/**
 * Date - Validation
 */
export const isValidDate = (d) => d && Object.prototype.toString.call(d) === '[object Date]' && !Number.isNaN(d);


/**
 * Time -> Human
 */
export const parseTimeToHuman = (d, localization = 'cs-CZ') => {
  if (!d) return '';
  return d.toLocaleTimeString(
    localization,
    { hour: '2-digit', minute: '2-digit', }
  );
};


/**
 * Date -> Human
 */
export const parseDateToHuman = (d, showTime = true, localization = 'cs-CZ') => {
  if (!d) return '';
  const date = d.toLocaleDateString(localization);
  const time = parseTimeToHuman(d, localization);

  return (`${date}${showTime ? ` ${time}` : ''}`);
};


/**
 * Milliseconds -> Human
 */
export const parseMillisecondsToHumanTime = (milliseconds) => {
  const minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
  const hours = Math.floor((milliseconds / (1000 * 60 * 60)) % 24);

  return `${hours}h ${(minutes < 10) ? `0${minutes}` : minutes}m`;
};


export const roundToLowerHour = (date) => {
  const ignore = 60 * 60 * 1000;
  return new Date(Math.floor(date.getTime() / ignore) * ignore);
};


export const clearSecondsAndMilliseconds = (d) => {
  if (!d) return d;
  const newDate = new Date(d);
  newDate.setSeconds(0);
  newDate.setMilliseconds(0);
  return newDate;
};


export const clearTime = (d) => {
  if (!d) return d;
  const newDate = new Date(d);
  newDate.setHours(0);
  newDate.setMinutes(0);
  newDate.setSeconds(0);
  newDate.setMilliseconds(0);
  return newDate;
};


export const setDateTodayMinTime = (d) => new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0, 0); // eslint-disable-line


export const setDateTodayMaxTime = (d) => new Date(d.getFullYear(), d.getMonth(), d.getDate(), 23, 59, 59, 999); // eslint-disable-line

/**
 * Returns new date with the same date, month and year but null hours, minutes, seconds and millis.
 */
export const getDayStart = (date) => new Date(date.getFullYear(), date.getMonth(), date.getDate());

/**
 * Floored to integer.
 */
export const differenceInDays = (a, b) => {
  const nullA = getDayStart(a);
  const nullB = getDayStart(b);
  return Math.floor((nullA.getTime() - nullB.getTime()) / MILLIS_IN_DAY);
};

export const parseDayMonthFromISO = (iso, localization = 'cs-CZ') => {
  const d = new Date(iso);
  return d.toLocaleDateString(localization, { day: '2-digit', month: '2-digit', year: undefined, }).replace(' ', '');
};

export const createEndOfMonth = (date) => new Date(
  date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59, 999,
);

export const areSameDates = (a, b) => a.getDate() === b.getDate()
    && a.getMonth() === b.getMonth()
    && a.getFullYear() === b.getFullYear();

/**
 * Creates a Date object with time of given YYYY/MM/DD HH:MM:SS in given time zone.
 */
export const dateInTimeZone = (
  timeZone, year, month, day, hour = 0, minute = 0, second = 0,
) => {
  const date = new Date(Date.UTC(year, month, day, hour, minute, second));

  const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC', }));
  const tzDate = new Date(date.toLocaleString('en-US', { timeZone, }));
  const offset = utcDate.getTime() - tzDate.getTime();

  date.setTime(date.getTime() + offset);

  return date;
};

/**
 * Creates a Date object with the same date and time as the given local object
 * but in the given time zone.
 */
export const toTimezone = (timeZone, dateLocal) => dateInTimeZone(
  timeZone,
  dateLocal.getFullYear(), dateLocal.getMonth(), dateLocal.getDate(),
  dateLocal.getHours(), dateLocal.getMinutes(), dateLocal.getSeconds(),
);

export const getDateOnlyString = (date) => {
  const month = `${date.getMonth() + 1}`.padStart(2, '0');
  const day = `${date.getDate()}`.padStart(2, '0');
  return `${date.getFullYear()}-${month}-${day}`;
};

export const msToMinutes = (ms) => Math.floor(ms / MILLIS_IN_MINUTE);
