import React from 'react';
import {
  func, object, arrayOf, string,
} from 'prop-types';

import StyledTechnicalCheckFiles from './styles/StyledTechnicalCheckFiles';
import Input from '../../../../atoms/Input/Input';
import { imageToBase64, } from '../../../../logic/file';

const { REACT_APP_REST, } = process.env;


const loadPicture = async (e, onChange) => {
  const file = e.target.files[0];

  if (file) {
    const image = await imageToBase64(file);
    onChange('technicalCheckPictures', image);
  }
};


const TechnicalCheckFilesForm = ({
  files,
  newFiles,
  onChange,
}) => (
  <>
    <StyledTechnicalCheckFiles>
      <ul>
        {files.map((file, index) => (
          <li key={index}>
            <a href={`${REACT_APP_REST}/file?filename=${file}`} target="_blank" rel="noopener noreferrer">
              <img
                src={`${REACT_APP_REST}/file?filename=${file}`}
                alt=""
                width="70"
                height="70"
              />
            </a>
          </li>
        ))}
        {newFiles.map((file, index) => (
          <li key={`newFile${index}`}>
            <img
              src={file}
              alt=""
              width="70"
              height="70"
            />
          </li>
        ))}
      </ul>
    </StyledTechnicalCheckFiles>
    <Input
      type="file"
      onChange={(e) => loadPicture(e, onChange)}
      accept="image/jpeg,image/png"
    />
  </>
);


TechnicalCheckFilesForm.propTypes = {
  files: arrayOf(string).isRequired,
  newFiles: arrayOf(string).isRequired,
  translations: object.isRequired,
  onChange: func.isRequired,
};


export default TechnicalCheckFilesForm;
