import gql from 'graphql-tag';
import { ATTACHMENTS, PRODUCT_FIELDS, } from '../components/utils';


/**
 * Common
 */
export const QUERY_DD_TRANSPORTATION_TYPES = gql`
  query FetchAllTransportationTypes($languageId: ID) {
    fetchAllTransportationTypes(languageId: $languageId) {
      id
      name
    }
  }
`;

export const QUERY_GENERIC_FORM = gql`
  query FetchGenericFormConfiguration($languageId: ID, $actionId: ID!, $genericFormId: ID!, $transportationId: ID!, $standId: ID) {
    fetchGenericFormConfiguration(languageId: $languageId, actionId: $actionId, genericFormId: $genericFormId, transportationId: $transportationId, standId: $standId) {
      genericForm {
        id
        name
        structure
        translation
        inputs {
          name
          type
          disabled
          defaultValue
          action
          map
          index
          genericFormDialId
          translation {
            label
            placeholder
            help
          }
          validation {
            required
            min
            max
            regex {
              name
              expression
              code
              params
            }
          }
        }
      }
      genericFormValues
    }
  }
`;


export const QUERY_DROPDOWN = gql`
  query FetchDropDown($languageId: ID, $type: String) {
    fetchDropDown(languageId: $languageId, type: $type) {
      id
      data {
        id
        name
      }
    }
  }
`;


export const QUERY_DD_PLATFORMS = gql`
  query FetchAllPlatforms($languageId: ID, $transportationTypeId: ID) {
    fetchAllPlatforms(languageId: $languageId, transportationTypeId: $transportationTypeId) {
      id
      name
    }
  }
`;


/**
 * History
 */
export const QUERY_HISTORY = gql`
  query FilterTransportationHistory($languageId: ID, $limit: Int, $offset: Int, $sortBy: String, $order: String, $params: transportationHistoryInputFilterParams) {
    filterTransportationHistory(languageId: $languageId, limit: $limit, offset: $offset, sortBy: $sortBy, order: $order, params: $params) {
      filter {
        limit
        offset
        sortBy
        order
        count
        params {
          id
        }
      }
      rows {
        id
        date
        no
        transportationTypeName
        reservationTypeId
        reservationTypeName
        truckRegistrationNumber
        trailerRegistrationNumber
        arrivalState
        clearanceLength
        platformName
        reserved
        arrivalTime
        departureTime
      }
    }
  }
`;


/**
 * Schedule
 */
export const QUERY_TRANSPORTATION_FUTURE = gql`
  query FilterTransportationFuture($languageId: ID, $limit: Int, $offset: Int, $sortBy: String, $order: String, $params: transportationFutureInputFilterParams) {
    filterTransportationFuture(languageId: $languageId, limit: $limit, offset: $offset, sortBy: $sortBy, order: $order, params: $params) {
      filter {
        limit
        offset
        sortBy
        order
        count
        params {
          id
        }
      }
      rows {
        id
        no
        date 
        reserved
        companyName
        transportationTypeName
        truckRegistrationNumber
        trailerRegistrationNumber
        platformName
        reservationTimeFrom
        reservationTimeTo
        reservationTypeId
        reservationTypeName
      }
    }
  }
`;


export const QUERY_FILTER_TIMELINE_BY_PLATFORMS = gql`
  query FilterTimelineByPlatforms($languageId: ID, $from: String, $to: String, $platformIds: [ID], $transportationTypeId: ID) {
    filterTimelineByPlatforms(languageId: $languageId, from: $from, to: $to, platformIds: $platformIds, transportationTypeId: $transportationTypeId) {
      filter {
        languageId
        from
        to
        platformIds
        transportationTypeId
      }
      platforms {
        id
        name
        reservation {
          id
          no
          from
          to
          truckRegistrationNumber
          platformId
          platformCode
          platformName
          transportationTypeId
          transportationTypeName
          arrivalState
        }
      }
    }
  }
`;


export const QUERY_FILTER_TIMELINE_BY_TRANSPORTATION_TYPE = gql`
  query FilterTimelineByTransportationType($languageId: ID, $from: String, $to: String, $platformIds: [ID], $transportationTypeId: ID) {
    filterTimelineByTransportationType(languageId: $languageId, from: $from, to: $to, platformIds: $platformIds, transportationTypeId: $transportationTypeId) {
      filter {
        languageId
        from
        to
        platformIds
        transportationTypeId
      }
      transportationTypes {
        id
        name
      }
      reservation {
        id
        no
        from
        to
        truckRegistrationNumber
        platformId
        platformCode
        platformName
        transportationTypeId
        transportationTypeName
        arrivalState
        state
      }
    }
  }
`;


/**
 * Transportation
 */
export const QUERY_TRANSPORTATION_DETAIL = gql`
  query fetchOneTransportation($id: ID, $languageId: ID) {
    fetchOneTransportation(id: $id, languageId: $languageId) {
      id
      reservation {
        resNo: no
        resTransportationType: transportationType {
          id
          name
        }
        resReservationType: reservationType {
          id
          name
        }
        resPlatform: platform {
          id
          name
        }
        resTimeFrom: timeFrom
        resTimeTo: timeTo
        resDate: date
        note
      }
      company {
        compId: id
        compName: name
        compIdentificationNumber: identificationNumber
        compVatNumber: vatNumber
        compStreet: street
        compCity: city
        compZip: zip
        compState: state
        compContactPerson: contactPerson
        compEmail: email
        compPhoneNumber: phoneNumber
      }
      driver {
        driverName: name
        driverPhoneNumber: phoneNumber
        driverNationality: nationality
        languageId
        languageName
      }
      passenger {
        passengerName: name
        passengerPhoneNumber: phoneNumber
        passengerNationality: nationality
      }
      truck {
        truckRegistrationNumber: registrationNumber
        carRegistrationCountry
        tankerCode
        tankerCheckExpiration
        carConstruction
        chamberCount
        chambers {
          volume
          breakwaterCount
        }
      }
      trailer {
        trailerRegistrationNumber: registrationNumber
        carTrailerRegistrationCountry
      }
      extra {
        uuid
        isAdr
        isMultipleOrder
        multipleOrderExpiration
        adrDriverNo
        adrDriverDate
        adrPassengerNo
        adrPassengerDate
        adrTruckNo
        adrTruckDate
        adrTrailerNo
        adrTrailerDate
        adrContainerNo
        adrContainerDate
        ${ATTACHMENTS.map((a) => `
          ${a}
        `).join('')}
      }
      prevTransport
      nextTransport
      gateHistoryIn
      gateHistoryOut
      gateHistoryInTime
      gateHistoryOutTime
      orders
      products {
        ${PRODUCT_FIELDS.map((a) => `
          ${a}
        `).join('')}
      }
      technicalCheckResult
      technicalCheckNote
      technicalCheckPictures
      inTcPdf
      outTcPdf
      serviceType
      sampleConfirmed
      sampleConfirmedAt
      hasOwnTransport
    }
  }
`;


export const QUERY_TRANSPORTATION_DETAIL_ADMIN = gql`
  query fetchOneTransportation($id: ID, $languageId: ID) {
    fetchOneTransportation(id: $id, languageId: $languageId) {
      id
      reservation {
        resNo: no
        resTransportationType: transportationType {
          id
          name
        }
        resReservationType: reservationType {
          id
          name
        }
        resPlatform: platform {
          id
          name
        }
        resTimeFrom: timeFrom
        resTimeTo: timeTo
        resDate: date
        note
      }
      company {
        compId: id
        compName: name
        compIdentificationNumber: identificationNumber
        compVatNumber: vatNumber
        compStreet: street
        compCity: city
        compZip: zip
        compState: state
        compContactPerson: contactPerson
        compEmail: email
        compPhoneNumber: phoneNumber
      }
      driver {
        driverName: name
        driverPhoneNumber: phoneNumber
        driverNationality: nationality
        languageId
        languageName
      }
      passenger {
        passengerName: name
        passengerPhoneNumber: phoneNumber
        passengerNationality: nationality
      }
      truck {
        truckRegistrationNumber: registrationNumber
        carRegistrationCountry
        tankerCode
        tankerCheckExpiration
        carConstruction
        chamberCount
        chambers {
          volume
          breakwaterCount
        }
      }
      trailer {
        trailerRegistrationNumber: registrationNumber
        carTrailerRegistrationCountry
      }
      extra {
        uuid
        isAdr
        isMultipleOrder
        multipleOrderExpiration
        adrDriverNo
        adrDriverDate
        adrPassengerNo
        adrPassengerDate
        adrTruckNo
        adrTruckDate
        adrTrailerNo
        adrTrailerDate
        adrContainerNo
        adrContainerDate
        ${ATTACHMENTS.map((a) => `
          ${a}
        `).join('')}
      }
      prevTransport
      nextTransport
      gateHistoryIn
      gateHistoryOut
      gateHistoryInTime
      gateHistoryOutTime
      gateInPict
      gateOutPict
      cargoSpacePictIn
      cargoSpacePictOut
      otherPictsIn
      otherPictsOut
      orders
      products {
        ${PRODUCT_FIELDS.map((a) => `
          ${a}
        `).join('')}
      }
      technicalCheckResult
      technicalCheckNote
      technicalCheckPictures
      inTcPdf
      outTcPdf
      serviceType
      sampleConfirmed
      sampleConfirmedAt
      hasOwnTransport
    }
    fetchTransportationActionsTabsConfiguration(languageId: $languageId, transportationId: $id) {
      actionId
      action
      genericFormId
      title
      completed
      start
      end
    }
  }
`;


/**
 * Filters
 */
export const QUERY_ALL_COMPANIES = gql`
  query FetchAllCompanies {
    fetchAllCompanies {
      id
      name
      identificationNumber
    }
  }
`;

export const QUERY_ONE_COMPANY = gql`
  query FetchOneCompany($id: ID) {
    fetchOneCompany(id: $id) {
      id
      name
      email
      info
      contactPerson
      phoneNumber
      identificationNumber
      vatNumber
      street
      city
      zip
      state
    }
  }
`;


export const QUERY_ALL_DRIVERS = gql`
  query FetchAllDrivers {
    fetchAllDrivers {
      id
      name
      phoneNumber
    }
  }
`;


export const QUERY_ALL_TRUCKS = gql`
  query FetchAllTrucks {
    fetchAllTrucks {
      id
      registrationNumber
    }
  }
`;


export const QUERY_SCHEDULE_TIMELINE_FILTER = gql`
  {
    scheduleFilter @client {
      from
      to
      transportationType {
        id
        name
      }
      range {
        id
        value
        name
      }
    }
  }
`;

export const QUERY_DD_LANGUAGES = gql`
  query FetchLanguages {
    fetchLanguages {
      id
      name
    }
  }
`;

export const QUERY_FETCH_TECHNICAL_CHECK = gql`
  query FetchTechnicalCheck($transportationId: ID!, $languageId: ID!) {
    fetchTechnicalCheck(transportationId: $transportationId, languageId: $languageId) {
      transportationNo
      transportationTypeName
      transportationDate
      carrierId
      carrierName
      carrierAddress
      driverName
      driverPhoneNumber
      driverNationality
      driverIdentityDocumentNumber
      adrDriverNo
      adrDriverDate
      passengerName
      passengerNationality
      passengerIdentityDocumentNumber
      adrPassengerNo
      adrPassengerDate
      carType
      truckRegistrationNumber
      adrTruckNo
      adrTruckDate
      adrTrailerNo
      adrTrailerDate
      carRegistrationCountry
      trailerRegistrationNumber
      carTrailerRegistrationCountry
      carConstruction
      tankerCode
      tankerCheckExpiration
      chamberCount
      chambers {
        volume
        breakwaterCount
      }
      chamberFillingCount
      chamberFilling {
        product
        cleared
      }
      chamberFillingIn {
        productId
        quantity
        chamber
      }
      chamberFillingOut {
        productId
        quantity
        chamber
      }
      otherSenderAtEntrance
      goodsLoaded
      goodsLoadedUn
      products {
        id
        name
        destination
        order
        lowerLimitQuantity
        recalculationOfTotalLimitQuantity
        transportationIn450LPackage
        chamber
        chamberQuantity
      }
      companyEligibility
      valveCheck
      outputTechnicalCheck
      equipment {
        fireExtinguisher2KG
        fireExtinguisher6KG
        wedges
        standingWarningDevices
        warningVest
        handFlashlight
        eyewash
        sealingGlasses
        gloves
        appropriateShoes
        apron
        shovel
        faceguard
        collectionContainer
        firstAidKit
        writtenInstructions
        stopperSewerage
      }
      dangerousGoods {
        lowerLimitQuantity
        combinedTransport
        technicalConditionsOfContainer
        warningOrangeADRTable
        warningOrangeIMOTable
        safetyPictograms
      }
      technicalDocuments {
        soe
        customs
        delivery
        cmr
        atests
        imo
        weight
        proformDelivery
        security
      }
      vehicleMarkings {
        orangeTables
        chamber0
        chamber1
        chamber2
        chamber3
        safetySignsContainer
        unNumberContainer
      }
      hasPlombs
      plombCount
      plombs
      transportCompany
      transportCompanyAddress
      isAdr
      technicalCheckResult
      technicalCheckNote
      technicalCheckPictures
    }
  }
`;

export const QUERY_FETCH_RESERVATION_TIME_BLOCKS = gql`
  query FetchReservationTimeBlocks($transportationTypeId: ID!, $date: String!) {
    fetchReservationTimeBlocks(transportationTypeId: $transportationTypeId, date: $date) {
      duration
      timeBlocks {
        time
        value
      }
    }
  }
`;
