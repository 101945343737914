import React from 'react';

import StyledSvg from '../styles/StyledSvg';


const Dashboard = ({ ...props }) => (
  <StyledSvg viewBox="0 0 283.000000 276.000000" {...props}>
    <g
      transform="translate(0.000000,276.000000) scale(0.100000,-0.100000)"
      fill="#C3C3C3"
      stroke="none"
    >
      <path d="M1442 1999 c-70 -21 -139 -117 -188 -263 -37 -108 -38 -107 52 -104
l76 3 22 61 c13 34 35 82 50 108 l27 46 359 0 360 0 25 -39 c15 -21 37 -70 50
-107 l24 -69 81 -3 81 -3 -7 28 c-33 137 -109 276 -175 320 l-43 28 -380 2
c-223 1 -395 -2 -414 -8z"
      />
      <path d="M416 1716 c-49 -18 -94 -60 -117 -111 -17 -37 -19 -71 -19 -412 l0
-373 -70 0 -70 0 0 -80 0 -80 353 2 352 3 3 78 3 77 -70 0 -70 0 -3 378 -3
377 -28 47 c-51 87 -167 129 -261 94z m147 -177 c34 -37 35 -78 2 -117 -32
-39 -74 -48 -114 -28 -36 19 -51 44 -51 86 0 43 16 69 53 85 42 18 76 10 110
-26z"
      />
      <path d="M462 1508 c-27 -27 -6 -68 35 -68 28 0 43 28 32 58 -9 24 -47 30 -67
10z"
      />
      <path d="M780 1465 l0 -85 49 0 c47 0 50 2 122 76 41 41 76 79 77 85 2 5 -48
9 -122 9 l-126 0 0 -85z"
      />
      <path d="M1077 1472 c-42 -42 -77 -81 -77 -85 0 -4 60 -7 133 -7 l133 0 72 79
c40 44 72 82 72 85 0 3 -57 6 -128 6 l-127 0 -78 -78z"
      />
      <path d="M1456 1468 l-75 -83 131 -3 132 -3 39 38 c21 21 57 59 79 86 l40 47
-135 0 -135 0 -76 -82z"
      />
      <path d="M1843 1471 c-40 -44 -73 -82 -73 -85 0 -3 57 -6 128 -6 l127 0 78 78
c42 42 77 81 77 85 0 4 -60 7 -132 7 l-133 0 -72 -79z"
      />
      <path d="M2227 1472 c-42 -42 -77 -81 -77 -85 0 -4 60 -7 133 -7 l133 0 72 79
c40 44 72 82 72 85 0 3 -57 6 -128 6 l-127 0 -78 -78z"
      />
      <path d="M2606 1468 l-76 -83 100 -3 c132 -4 140 1 140 83 0 53 -3 66 -19 75
-45 24 -67 12 -145 -72z"
      />
      <path d="M1100 1102 l0 -199 25 -23 c23 -22 25 -31 25 -111 0 -77 2 -90 19
-99 27 -14 144 -13 159 2 8 8 12 45 12 100 l0 88 500 0 500 0 0 -88 c0 -55 4
-92 12 -100 16 -16 150 -16 166 0 7 7 12 44 12 96 0 77 2 87 25 108 l25 23 0
201 0 200 -740 0 -740 0 0 -198z m351 108 c15 -8 19 -21 19 -58 0 -27 -5 -53
-12 -60 -8 -8 -48 -12 -115 -12 -114 0 -123 5 -123 71 0 57 21 69 122 69 49 0
98 -5 109 -10z m963 -12 c20 -29 20 -82 0 -102 -20 -20 -203 -23 -222 -4 -7 7
-12 33 -12 58 0 25 5 51 12 58 8 8 47 12 109 12 90 0 99 -2 113 -22z"
      />
    </g>
  </StyledSvg>
);

export default Dashboard;
