import React from 'react';
import {
  string, func, oneOfType, arrayOf, node,
} from 'prop-types';

import { withModalManager, } from './withModalManager';

import StyledModalHeader from './styles/StyledModalHeader';
import Cross from '../../styles/icons/Cross';


const ModalHeader = ({
  onClose, color, children,
}) => (
  <StyledModalHeader
    color={color}
  >
    <span className="modal-content--title">
      {children}
    </span>
    <button
      type="button"
      className="modal-content--exit"
      onClick={onClose}
    >
      <Cross />
    </button>
  </StyledModalHeader>
);


ModalHeader.propTypes = {
  children: oneOfType([
    arrayOf(node),
    node,
    string,
  ]),
  color: string,
  onClose: func.isRequired,
};


ModalHeader.defaultProps = {
  children: '',
  color: 'default',
};


export default withModalManager(ModalHeader);
