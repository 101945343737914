// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
import React from 'react';

import StyledSvg from './styles/StyledSvg';


const ProgressDown = ({ ...props }) => (
  // eslint-disable-next-line react/prop-types
  <StyledSvg viewBox="0 0 264 264" fill={(props?.fill) ?? 'white'} {...props}>
    <rect x="145.338" y="34.237" width="115" height="32" rx="16" ry="16" />
    <rect x="145.338" y="89.965" width="90" height="32" rx="16" ry="16" />
    <rect x="147.199" y="201.422" width="40" height="32" rx="16" ry="16" />
    <rect x="145.338" y="145.693" width="65" height="32" rx="16" ry="16" />
    <rect x="53.663" y="31.239" width="32" height="200" rx="16" ry="16" />
    <rect
      x="79.671"
      y="69.223"
      width="32"
      height="99.999"
      rx="16"
      ry="16"
      transform="matrix(0.707107, -0.707107, 0.71097, 0.703244, -107.575288, 176.330325)"
    />
    <rect
      x="79.671"
      y="69.223"
      width="32"
      height="99.999"
      rx="16"
      ry="16"
      transform="matrix(0.707107, 0.707107, -0.703244, 0.71097, 110.179849, 40.735627)"
    />
  </StyledSvg>
);

export default ProgressDown;
