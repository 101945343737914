import React from 'react';
import { func, string, object, } from 'prop-types';

import RecordEditLogic from './RecordEditLogic';
import Modal from '../../../../atoms/Modal/Modal';


const emptyData = {
  id: null,
  driverName: '',
  driverPhoneNumber: '',
  gateVehicleGroupId: null,
  gateVehicleGroupName: null,
  registrationNumber: '',
  gateTypeId: null,
  gateTypeName: null,
  time: null,
  // extra
  rnPicture: '',
  note: '',
};

const RecordCreate = ({
  // data
  languageId,
  translations,
  gatehouseType,
  // methods
  onToggle,
}) => (
  <Modal
    isOpen
    title={translations.gatehouse.titleRecordEditModal}
    size="XL"
    onClose={onToggle}
    disablePadding
  >
    <RecordEditLogic
      // data
      data={{ ...emptyData, }}
      isEditMode={false}
      translations={translations}
      languageId={languageId}
      gatehouseType={gatehouseType}
      // methods
      onToggle={onToggle}
      onEntranceResolution={() => {}}
    />
  </Modal>
);


RecordCreate.propTypes = {
  // data
  languageId: string.isRequired,
  translations: object.isRequired,
  gatehouseType: string.isRequired,
  // methods
  onToggle: func.isRequired,
};


export default RecordCreate;
