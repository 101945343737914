import React from 'react';
import { object, shape, string, } from 'prop-types';
import { Switch, Route, } from 'react-router-dom';

import NotFoundPage from '../../Main/pages/NotFoundPage';
import ParkingPage from './ParkingPage';
import InternalParkingPage from './InternalParkingPage';
import PrivateRoute from '../../../components/PrivateRoute/PrivateRoute';


const ParkingLayout = ({ match, resources, }) => (
  <Switch>
    <PrivateRoute resource={resources.parking_read} path={`${match.path}/internal`} component={InternalParkingPage} />
    <PrivateRoute resource={resources.parking_read} exact path={`${match.path}`} component={ParkingPage} />
    <Route component={NotFoundPage} />
  </Switch>
);


ParkingLayout.propTypes = {
  match: shape({
    path: string.isRequired,
  }).isRequired,
  resources: object,
};

ParkingLayout.defaultProps = {
  resources: {},
};

export default ParkingLayout;
