import gql from 'graphql-tag';


/**
 * Companies
 */
export const QUERY_COMPANY_DETAIL = gql`
  query FetchOneCompany($id: ID) {
    fetchOneCompany(id: $id) {
      id
      name
      contactPerson
      email
      phoneNumber
      identificationNumber
      vatNumber
      street
      city
      zip
      state
      info
    }
  }
`;


export const QUERY_COMPANIES = gql`
  query FilterCompanies($limit: Int, $offset: Int, $sortBy: String, $order: String, $params: companiesInputFilterParams) {
    filterCompanies(limit: $limit, offset: $offset, sortBy: $sortBy, order: $order, params: $params) {
      filter {
        limit
        offset
        sortBy
        order
        count
        params {
          id
          name
          email
          phoneNumber
          identificationNumber
          vatNumber
          street
          city
          zip
          state
        }
      }
      rows {
        id
        name
        email
        phoneNumber
        identificationNumber
        vatNumber
        street
        city
        zip
        state
        createdAt
        updatedAt
      }
    }
  }
`;


/**
 * Drivers
 */
export const QUERY_DRIVER_DETAIL = gql`
  query FetchOneDriver($id: ID) {
    fetchOneDriver(id: $id) {
      id
      name
      phoneNumber
      email
      identityDocumentNumber
      travelDocumentNumber
      identityDocumentNumberChecked
      travelDocumentNumberChecked
      oshTimeEnd
      onBlacklist
      info
    }
  }
`;


export const QUERY_DRIVERS = gql`
  query FilterDrivers($limit: Int, $offset: Int, $sortBy: String, $order: String, $params: driverInputFilterParams) {
    filterDrivers(limit: $limit, offset: $offset, sortBy: $sortBy, order: $order, params: $params) {
      filter {
        limit
        offset
        sortBy
        order
        count
        params {
          id
          name
          phoneNumber
        }
      }
      rows {
        id
        name
        phoneNumber
        createdAt
        updatedAt
      }
    }
  }
`;


/**
 * Truck
 */
export const QUERY_TRUCK_DETAIL = gql`
  query FetchOneTruck($id: ID!, $languageId: ID!) {
    fetchOneTruck(id: $id, languageId: $languageId) {
      id
      registrationNumber
      vehicleGroupId
      vehicleGroupName
    }
  }
`;


export const QUERY_TRUCKS = gql`
  query FilterTrucks($limit: Int, $offset: Int, $sortBy: String, $order: String, $params: truckInputFilterParams, $languageId: ID) {
    filterTrucks(limit: $limit, offset: $offset, sortBy: $sortBy, order: $order, params: $params, languageId: $languageId) {
      filter {
        limit
        offset
        sortBy
        order
        count
        params {
          id
          registrationNumber
          vehicleGroupName
        }
      }
      rows {
        id
        registrationNumber
        vehicleGroupId
        vehicleGroupName
        createdAt
        updatedAt
      }
    }
  }
`;

/**
 * Vehicle Groups
 */
export const QUERY_VEHICLE_GROUPS_DETAIL = gql`
  query FetchOneVehicleGroup($id: ID, $languageId: ID) {
    fetchOneVehicleGroup(id: $id, languageId: $languageId) {
      id
      name
      configuration {
        residents
      }
      createdAt
      updatedAt
    }
  }
`;

export const QUERY_VEHICLE_GROUPS = gql`
  query FilterVehicleGroups($languageId: ID, $limit: Int, $offset: Int, $sortBy: String, $order: String, $params: vehicleGroupInputFilterParams) {
    filterVehicleGroups(languageId: $languageId, limit: $limit, offset: $offset, sortBy: $sortBy, order: $order, params: $params) {
      filter {
        limit
        offset
        sortBy
        order
        count
        params {
          id
          name
        }
      }
      rows {
        id
        name
        createdAt
        updatedAt
      }
    }
  }
`;

export const QUERY_DD_VEHICLE_GROUPS = gql`
  query FetchAllVehicleGroups($languageId: ID) {
    fetchAllVehicleGroups(languageId: $languageId) {
      id
      name
    }
  }
`;
