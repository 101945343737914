import React, { Component, } from 'react';
import styled from 'styled-components';
import {
  oneOfType, number, bool, string, func,
} from 'prop-types';


const StyledInputRadio = styled.label`
  .radio--container {
    display: inline-block;
    vertical-align: middle;
    margin: 0.25rem 0;
  }

  .radio--icon {
    fill: none;
    stroke: white;
    stroke-width: 3px;
    vertical-align: top;
    visibility: ${(p) => (p.checked ? 'visible' : 'hidden')};
  }

  .radio--hidden-radio {
    border: 0;
    clip: rect(0 0 0 0);
    clippath: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  .radio--radio {
    cursor: pointer;
    display: inline-block;
    width: 1.25rem;
    height: 1.25rem;
    border: 2px solid ${(p) => p.theme.tertiary.t700};
    border-radius: 50%;
    background: ${(p) => (p.checked ? p.theme.tertiary.t700 : p.theme.white)};
    transition: all 150ms;
  }

  .radio--text {
    margin-left: 8px;
  }

  ${(p) => p.disabled && `
    .radio--radio {
      cursor: unset;
      background: ${p.theme.grey.t300};
    }
  `}
`;


class InputRadio extends Component {
  handle = () => {
    const { disabled, name, onChange, } = this.props;

    if (disabled) return;

    onChange(name);
  }


  render() {
    const {
      disabled,
      value,
      name,
      text,
    } = this.props;

    const checked = name === value;

    return (
      <StyledInputRadio
        disabled={disabled}
        checked={checked}
      >
        <div className="radio--container">
          <input className="radio--hidden-radio" type="radio" checked={checked} onChange={this.handle} />
          <div className="radio--radio">
            <svg className="radio--icon" viewBox="0 0 24 24">
              <circle cx="12" cy="12" r="10" />
            </svg>
          </div>
        </div>
        {text && (
          <span className="radio--text">{text}</span>
        )}
      </StyledInputRadio>
    );
  }
}


InputRadio.propTypes = {
  value: oneOfType([ string, number, ]),
  name: oneOfType([ string, number, ]),
  text: string,
  disabled: bool,
  onChange: func,
};

InputRadio.defaultProps = {
  value: null,
  name: null,
  text: undefined,
  disabled: false,
  onChange: () => {},
};


export default InputRadio;
