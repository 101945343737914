import { v4, } from 'uuid';

import { FORM_ROOT, } from '../../globals';
import { initInput, } from './common';
import { createStructureNode, getInitValue, parseFormValueToApiValue, } from './commonGenForm';


export const parseFromApiTransportationEditForm = (inForm, inValues) => {
  if (inForm === null) {
    return {
      tree: null,
      form: null,
      values: null,
    };
  }

  const structure = {
    [FORM_ROOT]: createStructureNode({
      type: FORM_ROOT,
    }),
  };
  const form = {};
  const values = {};
  const mapValueToInputName = {};

  // Form
  for (let i = 0; i < inForm.length; i++) {
    const { config: input, translation, } = inForm[i];
    const name = v4();

    // 1. add to structure
    structure[name] = createStructureNode({
      id: name,
      parent: FORM_ROOT,
      type: input.type,
      data: null,
      childs: [],
    });
    structure[FORM_ROOT].childs.push(name);
    // 2. add to form
    form[name] = initInput({
      name,
      ...input,
      translation,
      disabled: false,
    });
    // 3. map Value to Input Name;
    mapValueToInputName[input.table] = {
      ...mapValueToInputName[input.table],
      [input.parameter]: {
        name,
        type: input.type,
      },
    };
    // 4. init Values
    values[name] = getInitValue({
      type: input.type,
      defaultValue: undefined,
      currValue: undefined,
    });
  }

  // Values
  for (let j = 0; j < inValues.length; j++) {
    const {
      value,
      table,
      parameter,
    } = inValues[j];

    if (
      Object.prototype.hasOwnProperty.call(mapValueToInputName, table)
      && Object.prototype.hasOwnProperty.call(mapValueToInputName[table], parameter)
    ) {
      const { name, type, } = mapValueToInputName[table][parameter];

      values[name] = getInitValue({
        type,
        defaultValue: undefined,
        currValue: value,
      });
    }
  }

  return {
    tree: structure,
    form,
    values,
  };
};


export const parseToApiTransportationEditForm = ({ tree, form, values, }) => {
  if (!tree) return null;

  const keysForm = Object.keys(form);
  const ret = [];

  for (let i = 0; i < keysForm.length; i++) {
    const {
      name,
      type,
      table,
      parameter,
    } = form[keysForm[i]];

    ret.push({
      table,
      parameter,
      value: parseFormValueToApiValue({ type, value: values[name], }),
    });
  }

  return ret;
};
