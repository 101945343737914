import React, { Fragment, } from 'react';
import { func, object, } from 'prop-types';

import FormRow from '../../../../components/Form/FormRow';
import Input from '../../../../atoms/Input/Input';
import InputSelect from '../../../../atoms/InputSelect/InputSelect';
import { getYesNoOptions, } from '../InputTechnicalCheck/utils';


const TechnicalCheckPlombForm = ({
  form,
  translations,
  values,
  onChange,
}) => (
  <Fragment>
    <FormRow
      label={translations.form.hasPlombs}
      input={form.hasPlombs}
    >
      <InputSelect
        value={values.hasPlombs}
        options={getYesNoOptions(translations)}
        onChange={(option) => onChange(form.hasPlombs.name, option)}
        status={form.hasPlombs.status}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        placeholder={translations.common.select}
      />
    </FormRow>

    <FormRow
      label={translations.form.plombCount}
      input={form.plombCount}
    >
      <Input
        type="number"
        min="0"
        value={values.plombCount}
        onChange={(e) => onChange(form.plombCount.name, e.target.value)}
        status={form.plombCount.status}
      />
    </FormRow>

    {values.plombs.map((plomb, index) => (
      <FormRow
        label={`${translations.form.plomb} ${index + 1}`}
        input={form.plombs}
        key={index}
      >
        <Input
          value={plomb}
          onChange={(e) => onChange(form.plombs.name, e.target.value, index)}
          status={form.plombs.status}
        />
      </FormRow>
    ))}

  </Fragment>
);


TechnicalCheckPlombForm.propTypes = {
  translations: object.isRequired,
  form: object.isRequired,
  values: object.isRequired,
  onChange: func.isRequired,
};


export default TechnicalCheckPlombForm;
