import styled from 'styled-components';


const HeadingWihButtons = styled.div`
  margin-bottom: 1.5rem;
 
  h1 h2 h3 h4 {
    margin: 0 0 0.5rem 0;
  }
`;


export default HeadingWihButtons;
