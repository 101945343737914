import React from 'react';
import { object, } from 'prop-types';

import { withTranslations, } from '../../../logic/translations/withTranslations';
import { TRANSLATIONS_SETTINGS, } from '../gql/translations';
import Card from '../../../atoms/Card/Card';
import Row from '../../../atoms/Row/Row';
import Col from '../../../atoms/Col/Col';
import Breadcrumb from '../../../atoms/Breadcrumb/Breadcrumb';
import BreadcrumbItem from '../../../atoms/Breadcrumb/BreadcrumbItem';
import PageView from '../../../atoms/PageView/PageView';
import Roles from '../components/Roles/Roles';
import Users from '../components/Users/Users';


const UserSettingsPage = ({ translations, resources, }) => (
  <PageView>

    <Breadcrumb>
      <BreadcrumbItem to="/">{translations.common.home}</BreadcrumbItem>
      <BreadcrumbItem>{translations.common.settings}</BreadcrumbItem>
    </Breadcrumb>

    <Row>
      <Col SM={24} LG={12} HD={10} HDPlus={8}>
        <Card>
          <Roles resources={resources} translations={translations} />
        </Card>
      </Col>
      <Col SM={24} LG={16} HD={14} HDPlus={12}>
        <Card>
          <Users resources={resources} translations={translations} />
        </Card>
      </Col>
    </Row>

  </PageView>
);


UserSettingsPage.propTypes = {
  resources: object,
  translations: object.isRequired,
};

UserSettingsPage.defaultProps = {
  resources: {},
};


export default withTranslations(TRANSLATIONS_SETTINGS)(UserSettingsPage);
