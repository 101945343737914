import gql from 'graphql-tag';


export const TRANSLATIONS_SCANNER_INPUT = gql`
  query Dictionary($languageId: ID) {
    dictionary(languageId: $languageId) {
      dictionary {
        centralApp {
          common {
            home
            close
            storekeeper
            btnSave
            yes
            no
            confirm
          }
          form {
            no
            truckRegistrationNumber
            transportationType
            platform
          }
          centralAppStorekeeper {
            notifTitleClearedSuccess
            notifTitleClearedError
            notifTitleFinishSuccess
            notifTitleFinishError
            noGateInText
            noGateInConfirmBtn
            noGateInCancelBtn
            shortIntervalText
            shortIntervalConfirmBtn
            shortIntervalCancelBtn
            countdownModalClearText
            countdownModalFinishText
            countdownModalConfirmBtn
            countdownModalCancelBtn
          }
        }
      }
    }
  }
`;
