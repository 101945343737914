import React from 'react';
import { shape, string, object, } from 'prop-types';
import { Switch, Route, } from 'react-router-dom';

import PrivateRoute from '../../../components/PrivateRoute/PrivateRoute';
import CompaniesPage from './CompaniesPage';
import DriversPage from './DriversPage';
import TrucksPage from './TrucksPage';
import VehicleGroupsPage from './VehicleGroupsPage';
import NotFoundPage from '../../Main/pages/NotFoundPage';


const DirectoryLayout = ({ match, resources, }) => (
  <Switch>
    <PrivateRoute resource={resources.directory_company_read} path={`${match.path}/companies`} component={CompaniesPage} />
    <PrivateRoute resource={resources.directory_driver_read} path={`${match.path}/drivers`} component={DriversPage} />
    <PrivateRoute resource={resources.directory_truck_read} path={`${match.path}/trucks`} component={TrucksPage} />
    <PrivateRoute resource={resources.directory_truck_read} path={`${match.path}/vehicle-groups`} component={VehicleGroupsPage} />
    <Route component={NotFoundPage} />
  </Switch>
);


DirectoryLayout.propTypes = {
  match: shape({
    path: string.isRequired,
  }).isRequired,
  resources: object,
};

DirectoryLayout.defaultProps = {
  resources: {},
};


export default DirectoryLayout;
