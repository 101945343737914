export const parseSecondsToTime = (seconds) => {
  const hs = Math.floor(seconds / 3600);
  const mins = Math.ceil((seconds - hs * 3600) / 60);

  return `${hs}:${(mins < 10) ? '0' : ''}${mins}`;
};

const HALF_HOUR = 30 * 60;
const ONE_HOUR = 60 * 60;
const ONE_AND_HALF_HOUR = 90 * 60;
const TWO_HOURS = 120 * 60;

export const getMaxDuration = (data) => {
  const maxDataDuration = Math.max(...data.map((d) => d.duration));
  let maxDuration;

  if (maxDataDuration < HALF_HOUR) {
    maxDuration = HALF_HOUR;
  } else if (maxDataDuration < ONE_HOUR) {
    maxDuration = ONE_HOUR;
  } else if (maxDataDuration < ONE_AND_HALF_HOUR) {
    maxDuration = ONE_AND_HALF_HOUR;
  } else if (maxDataDuration < TWO_HOURS) {
    maxDuration = TWO_HOURS;
  } else {
    const hours = Math.ceil(maxDataDuration / ONE_HOUR);
    maxDuration = hours * ONE_HOUR;
  }

  return maxDuration;
};

export const createLevelsForMaxDuration = (maxDuration) => {
  let levelsCount = 2;
  // 3 levels
  if (maxDuration === HALF_HOUR || maxDuration === ONE_AND_HALF_HOUR) {
    levelsCount = 3;
  } else if (maxDuration === 0) {
    return 1;
  }

  const levelDiff = maxDuration / levelsCount;
  const levels = [];
  for (let i = levelsCount; i >= 0; --i) {
    levels.push({ label: parseSecondsToTime(i * levelDiff), });
  }

  return levels;
};
