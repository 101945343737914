import { addHoursToDate, } from '../../../../logic/date';


/**
 * Timeline Filter Init
 */
export const getInitTimelineFilter = (filter, optionsRange) => {
  const {
    from, to, range,
  } = filter;

  const defaultFrom = new Date();
  const defaultRange = optionsRange[3];
  const defaultTo = addHoursToDate(new Date(), defaultRange.value);

  if (!from || !to || !range) {
    return {
      from: defaultFrom,
      to: defaultTo,
      transportationType: null,
      range: defaultRange,
    };
  }

  return filter;
};


/**
 * Setup timeline
 */
export const getTimelineSettings = (payload, theme, showText3 = false) => {
  const timelineColors = {
    inactive: {
      background: theme.grey.t200,
      border: 'unset',
      color: theme.black,
    },
    default: {
      background: theme.tertiary.t100,
      border: 'unset',
      color: theme.black,
    },
    ok: {
      background: theme.success.t100,
      border: 'unset',
      color: theme.black,
    },
    premature: {
      background: theme.success.t300,
      border: 'unset',
      color: theme.black,
    },
    delayed: {
      background: theme.warning.t600,
      border: 'unset',
      color: theme.black,
    },
    notComing: {
      background: theme.error.t500,
      border: 'unset',
      color: theme.black,
    },
  };

  const text1 = payload.no;
  const text2 = payload.truckRegistrationNumber;
  const text3 = showText3 ? payload.platformCode : null;

  if (!payload.isActive) {
    return ({
      style: timelineColors.inactive,
      text1,
      text2,
      text3,
    });
  }

  const style = (Object.prototype.hasOwnProperty.call(timelineColors, payload.arrivalState))
    ? timelineColors[payload.arrivalState]
    : timelineColors.default;

  return ({
    style,
    text1,
    text2,
    text3,
  });
};


export const getFilterHoursOptions = (translations) => [
  {
    id: '0',
    value: 6,
    name: translations.transportation.range6Hours,
  },
  {
    id: '1',
    value: 12,
    name: translations.transportation.range12Hours,
  },
  {
    id: '2',
    value: 18,
    name: translations.transportation.range18Hours,
  },
  {
    id: '3',
    value: 24,
    name: translations.transportation.range24Hours,
  },
  {
    id: '4',
    value: 30,
    name: translations.transportation.range30Hours,
  },
];
