import React from 'react';
import { Query, } from 'react-apollo';
import {
  string, func, object,
} from 'prop-types';

import { QUERY_TRANSPORTATION_DETAIL_ADMIN, } from '../../gql/queries';
import { TRANSLATIONS_TRANSPORTATION, } from '../../gql/translations';
import { withTranslations, } from '../../../../logic/translations/withTranslations';
import ModalLoadingPart from '../../../../components/modal/ModalLoadingPart';
import ModalErrorPart from '../../../../components/modal/ModalErrorPart';
import Modal from '../../../../atoms/Modal/Modal';
import TransportationAdminView from './TransportationAdminView';
import { pipe, } from '../../../../logic/utils';
import { withUser, } from '../../../../logic/auth/withUser';
import Header from '../TransportationDetail/Header';


const TransportationAdmin = ({
  // data
  transportationId,
  translations,
  languageId,
  resources,
  // methods
  onToggle,
  onEdited,
  onDeleted,
}) => (
  <Modal
    title={translations.transportation.detailTransportationModalTitle}
    isOpen
    size="XL"
    onClose={onToggle}
    headerChild={<Header transportationId={transportationId} translations={translations} wide />}
  >
    <Query
      query={QUERY_TRANSPORTATION_DETAIL_ADMIN}
      variables={{
        id: transportationId,
        languageId: languageId === null ? undefined : languageId,
      }}
      fetchPolicy="no-cache"
    >
      {({ loading, error, data, }) => {
        if (loading) {
          return (
            <ModalLoadingPart
              onBack={onToggle}
              btnBack={translations.common.close}
            />
          );
        }

        if (
          error
          || !data
          || !data.fetchOneTransportation
          || !data.fetchTransportationActionsTabsConfiguration
        ) {
          return (
            <ModalErrorPart
              error={error}
              onBack={onToggle}
              btnBack={translations.common.close}
            />
          );
        }

        return (
          <TransportationAdminView
            // data
            transportationId={transportationId}
            detail={data.fetchOneTransportation}
            tabs={data.fetchTransportationActionsTabsConfiguration}
            languageId={languageId}
            translations={translations}
            resources={resources}
            // methods
            onToggle={onToggle}
            onEdited={onEdited}
            onDeleted={onDeleted}
          />
        );
      }}
    </Query>
  </Modal>
);


TransportationAdmin.propTypes = {
  // data
  transportationId: string.isRequired,
  translations: object.isRequired,
  languageId: string.isRequired,
  resources: object.isRequired,
  // methods
  onToggle: func.isRequired,
  onEdited: func,
  onDeleted: func,
};

TransportationAdmin.defaultProps = {
  onEdited: undefined,
  onDeleted: undefined,
};

export default pipe(
  withTranslations(TRANSLATIONS_TRANSPORTATION),
  withUser,
)(TransportationAdmin);
