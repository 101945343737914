import React from 'react';
import { shape, number, object, } from 'prop-types';

import StatsGridCard from './StatsGridCard';
import StatsNumberCard from './StatsNumberCard';


const ActualSystemStateCard = ({ actualSystemStateStats, translations, }) => (
  <StatsGridCard>
    <header>
      <span>{translations.dashboard.stats.actualSystemStateTitle}</span>
    </header>
    <div className="stats--actual-system-state-card--body">
      <StatsNumberCard
        num={actualSystemStateStats.registered}
        title={translations.dashboard.stats.registered}
        color="grey"
      />
      <StatsNumberCard
        num={actualSystemStateStats.clearing}
        title={translations.dashboard.stats.clearing}
        color="orange"
      />
      <StatsNumberCard
        num={actualSystemStateStats.done}
        title={translations.dashboard.stats.done}
        color="green"
      />
    </div>
  </StatsGridCard>
);

ActualSystemStateCard.propTypes = {
  actualSystemStateStats: shape({
    registered: number.isRequired,
    clearing: number.isRequired,
    done: number.isRequired,
  }).isRequired,
  translations: object.isRequired,
};

export default ActualSystemStateCard;
