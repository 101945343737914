import React, { Fragment, } from 'react';
import {
  shape, string, func, object,
} from 'prop-types';

import { Query, } from 'react-apollo';
import FormRow from '../../../../components/Form/FormRow';
/*
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import Input from '../../../../atoms/Input/Input';
 */
import { QUERY_ALL_COMPANIES, } from '../../gql/queries';
import InputSelect from '../../../../atoms/InputSelect/InputSelect';


const TransportationCompanyForm = ({
  form,
  translations,
  values,
  onChange,
}) => (
  <Fragment>
    <FormRow
      label={translations.form.compId}
      input={form.compId}
    >
      <Query query={QUERY_ALL_COMPANIES} fetchPolicy="network-only">
        {({ data, loading, error, }) => (
          <InputSelect
            value={values.compId}
            options={data.fetchAllCompanies ? (() => {
              const collator = new Intl.Collator();
              return [ {
                id: null,
                name: '--',
              }, ...data.fetchAllCompanies.sort((company1, company2) => collator.compare(company1.name, company2.name)), ];
            })() : []}
            onChange={(options) => onChange(form.compId.name, options === null ? null : options)}
            status={form.compId.status}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            placeholder={translations.common.select}
            loading={loading}
            error={error}
          />
        )}
      </Query>
    </FormRow>

    {/*
      <FormRow
        label={translations.form.name}
        input={form.compName}
      >
        <Input
          type="text"
          value={values.compName}
          onChange={(e) => onChange(form.compName.name, e.target.value)}
          status={form.compName.status}
        />
      </FormRow>

      <Row>
        <Col SM={12}>
          <FormRow
            label={translations.form.identificationNumber}
            input={form.compIdentificationNumber}
          >
            <Input
              type="text"
              value={values.compIdentificationNumber}
              onChange={(e) => onChange(form.compIdentificationNumber.name, e.target.value)}
              status={form.compIdentificationNumber.status}
            />
          </FormRow>
        </Col>
        <Col SM={12}>
          <FormRow
            label={translations.form.vatNumber}
            input={form.compVatNumber}
          >
            <Input
              type="text"
              value={values.compVatNumber}
              onChange={(e) => onChange(form.compVatNumber.name, e.target.value)}
              status={form.compVatNumber.status}
            />
          </FormRow>
        </Col>
      </Row>

      <FormRow
        label={translations.form.street}
        input={form.compStreet}
      >
        <Input
          type="text"
          value={values.compStreet}
          onChange={(e) => onChange(form.compStreet.name, e.target.value)}
          status={form.compStreet.status}
        />
      </FormRow>

      <Row>
        <Col SM={18}>
          <FormRow
            label={translations.form.city}
            input={form.compCity}
          >
            <Input
              type="text"
              value={values.compCity}
              onChange={(e) => onChange(form.compCity.name, e.target.value)}
              status={form.compCity.status}
            />
          </FormRow>
        </Col>
        <Col SM={6}>
          <FormRow
            label={translations.form.zip}
            input={form.compZip}
          >
            <Input
              type="text"
              value={values.compZip}
              onChange={(e) => onChange(form.compZip.name, e.target.value)}
              status={form.compZip.status}
            />
          </FormRow>
        </Col>
      </Row>

      <Row>
        <Col SM={18}>
          <FormRow
            label={translations.form.state}
            input={form.compState}
          >
            <Input
              type="text"
              value={values.compState}
              onChange={(e) => onChange(form.compState.name, e.target.value)}
              status={form.compState.status}
            />
          </FormRow>
        </Col>
      </Row>

      <h5>{translations.form.contactPerson}</h5>

      <FormRow
        input={form.compContactPerson}
        label={translations.form.personName}
      >
        <Input
          type="text"
          value={values.compContactPerson}
          onChange={(e) => onChange(form.compContactPerson.name, e.target.value)}
          status={form.compContactPerson.status}
        />
      </FormRow>

      <FormRow
        label={translations.form.email}
        input={form.compEmail}
      >
        <Input
          type="text"
          value={values.compEmail}
          onChange={(e) => onChange(form.compEmail.name, e.target.value)}
          status={form.compEmail.status}
        />
      </FormRow>

      <Row>
        <Col SM={12}>
          <FormRow
            label={translations.form.phoneNumber}
            input={form.compPhoneNumber}
          >
            <Input
              type="text"
              value={values.compPhoneNumber}
              onChange={(e) => onChange(form.compPhoneNumber.name, e.target.value)}
              status={form.compPhoneNumber.status}
            />
          </FormRow>
        </Col>
      </Row>
      */}
  </Fragment>
);


TransportationCompanyForm.propTypes = {
  translations: object.isRequired,
  form: shape({
    compId: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    /*
    compName: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    compIdentificationNumber: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    compVatNumber: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    compStreet: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    compCity: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    compZip: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    compState: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    compContactPerson: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    compEmail: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    compPhoneNumber: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
     */
  }).isRequired,
  values: shape({
    compId: object,
    /*
    compName: string,
    compIdentificationNumber: string,
    compVatNumber: string,
    compStreet: string,
    compCity: string,
    compZip: string,
    compState: string,
    compContactPerson: string,
    compEmail: string,
    compPhoneNumber: string,
     */
  }).isRequired,
  onChange: func.isRequired,
};


export default TransportationCompanyForm;
