import React from 'react';
import {
  number, arrayOf, oneOfType, node, bool,
} from 'prop-types';

import Collapse from '../../atoms/Collapse/Collapse';


const SidebarCollapse = ({
  children, level, isOpen,
}) => (
  <Collapse
    isOpen={isOpen}
    className={`sidebar--item-collapse-${level}`}
  >
    {children}
  </Collapse>
);


SidebarCollapse.propTypes = {
  level: number,
  isOpen: bool.isRequired,
  children: oneOfType([
    arrayOf(node),
    node,
  ]).isRequired,
};

SidebarCollapse.defaultProps = {
  level: 0,
};


export default SidebarCollapse;
