import React, { Component, } from 'react';
import { object, func, string, } from 'prop-types';

import InputDate from '../InputDate/InputDate';
import Calendar from '../Calendar/Calendar';

import { clearTime, } from '../../logic/date';
import StyledInputDateSelect from './styles/StyledInputDateSelect';


const initState = {
  isOpen: false,
  inputDate: {
    isFocused: false,
    value: null,
  },
};


class InputDateSelect extends Component {
  constructor(props) {
    super(props);

    this.refDD = React.createRef();

    this.state = {
      ...initState,
    };
  }


  componentWillUnmount() {
    this.removeEvents();
  }


  /**
   * Add Click Event
   */
  addEvents = () => {
    [ 'click', 'touchend', ].forEach((event) => (
      document.addEventListener(event, this.handleDocumentClick)
    ));
  }


  /**
   * Remove Click Event
   */
  removeEvents = () => {
    [ 'click', 'touchend', ].forEach((event) => (
      document.removeEventListener(event, this.handleDocumentClick)
    ));
  }


  /**
   * onClick
   */
  handleDocumentClick = (e) => {
    if (this.refDD.current && !this.refDD.current.contains(e.target)) {
      this.handleClose();
    }
  }


  /**
   * Close dropdown
   */
  handleClose = () => {
    const { isOpen, } = this.state;

    if (isOpen) {
      this.removeEvents();
      this.setState({
        isOpen: false,
      });
    }
  }


  /**
   * Input - onFocus
   *  - open dropdown
   */
  handleInputFocus = () => {
    const { isOpen, } = this.state;
    const { value, } = this.props;

    if (!isOpen) {
      this.addEvents();
      this.setState({
        isOpen: true,
        inputDate: {
          isFocused: true,
          value,
        },
      });
    }
  }


  /**
   * Input - onChangeInputDate
   */
  handleOnChangeInputDate = (newDate) => {
    this.setState((prevState) => ({
      inputDate: {
        ...prevState.inputDate,
        value: newDate,
      },
    }));
  }


  /**
   * Calendar - onChange
   */
  handleOnChangeCalendar = (newDate) => {
    const { inputDate, } = this.state;
    const { value, onChange, } = this.props;

    const oldDate = inputDate.isFocused ? inputDate.value : value;

    if (onChange) {
      if (!oldDate) {
        onChange(clearTime(newDate));
      }

      const retDate = new Date(oldDate);

      const day = newDate.getDate();
      const month = newDate.getMonth();
      const year = newDate.getFullYear();

      retDate.setFullYear(year);
      retDate.setMonth(month);
      retDate.setDate(day);

      onChange(retDate);
    }
    this.handleClose();
  }


  /**
   * onKeyDown
   */
  handleKeyDown = (e) => {
    // enter
    switch (e.keyCode) {
      case 13:
      case 9: {
        this.handleClose();
        break;
      }
      default: {
        break;
      }
    }
  }


  render() {
    const { isOpen, inputDate, } = this.state;
    const {
      value,
      verticalPosition,
      horizontalPosition,
      onChange,
      ...rest
    } = this.props;

    return (
      <StyledInputDateSelect
        ref={this.refDD}
        onKeyDown={this.handleKeyDown}
        verticalPosition={verticalPosition}
        horizontalPosition={horizontalPosition}
      >

        <div className="inputDateSelect--inputWrapper">
          <InputDate
            {...rest}
            value={value}
            onChange={onChange}
            onChangeInputDate={this.handleOnChangeInputDate}
            onFocus={this.handleInputFocus}
          />
        </div>

        {isOpen && (
          <div className="inputDateSelect--dropdown">
            <Calendar
              value={inputDate.isFocused ? inputDate.value : value}
              onChange={this.handleOnChangeCalendar}
            />
          </div>
        )}

      </StyledInputDateSelect>
    );
  }
}


InputDateSelect.propTypes = {
  value: object,
  verticalPosition: string,
  horizontalPosition: string,
  onChange: func,
};

InputDateSelect.defaultProps = {
  value: null,
  verticalPosition: 'bottom',
  horizontalPosition: 'right',
  onChange: () => {},
};


export default InputDateSelect;
