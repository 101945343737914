import styled from 'styled-components';


const StyledWizard = styled.div`
  .standWizard--wizard {
    padding: ${(p) => p.theme.common.padding} ${(p) => p.theme.common.padding} 0.5rem ${(p) => p.theme.common.padding};
  }

  .standWizard--finish-form {
    display: flex;
    align-items: center;
  }

  .standWizard--finish-manual {
    margin-left: 0.5rem;
    width: 14rem;
  }
`;


export default StyledWizard;
