import React from 'react';
import {
  func, shape, bool, string, object,
} from 'prop-types';
import { Query, } from 'react-apollo';

import { TRANSLATION_STAND_WIZARD, } from '../../gql/translations';
import { QUERY_STAND_WIZARD_CONFIG, } from '../../gql/queries';
import { withTranslations, } from '../../../../logic/translations/withTranslations';
import ModalBase from '../../../../atoms/Modal/ModalBase';
import ErrorBoundary from '../../../../components/ErrorBoundary/ErrorBoundary';
import ErrorView from './ErrorView';
import LoadingView from './LoadingView';
import WizardLogic from './WizardLogic';


const StandWizardModal = ({
  // data
  modalData: {
    isOpen,
    id,
    isLoading,
  },
  standId,
  translations,
  languageId,
  // methods
  onToggle,
}) => (
  <ModalBase
    isOpen={isOpen}
    onClose={onToggle}
    size="MD"
  >
    <ErrorBoundary
      renderError={() => (
        <ErrorView
          onToggle={onToggle}
          translations={translations}
        />
      )}
    >
      <Query
        query={QUERY_STAND_WIZARD_CONFIG}
        fetchPolicy="no-cache"
        variables={{
          languageId: languageId === null ? undefined : languageId,
          standId,
          transportationId: id,
        }}
      >
        {({ loading, error, data, }) => {
          if (loading) {
            return (
              <LoadingView
                onToggle={onToggle}
                translations={translations}
              />
            );
          }

          if (error || !data || !data.fetchStandActionsTabsConfiguration) {

            return (
              <ErrorView
                error={error}
                onToggle={onToggle}
                translations={translations}
              />
            );
          }

          return (
            <WizardLogic
              // data
              configuration={data.fetchStandActionsTabsConfiguration}
              standId={standId}
              languageId={languageId}
              transportationId={id}
              isLoading={isLoading}
              translations={translations}
              // methods
              onToggle={onToggle}
            />
          );
        }}
      </Query>
    </ErrorBoundary>
  </ModalBase>
);


StandWizardModal.propTypes = {
  modalData: shape({
    isOpen: bool.isRequired,
  }).isRequired,
  onToggle: func.isRequired,
  standId: string.isRequired,
  translations: object.isRequired,
  languageId: string.isRequired,
};


export default withTranslations(TRANSLATION_STAND_WIZARD)(StandWizardModal);
