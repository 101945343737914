import React from 'react';
import { shape, func, object, } from 'prop-types';

import InputCheckbox from '../../../../atoms/InputCheckbox/InputCheckbox';
import { ADR_ATTACHMENTS, ATTACHMENTS, } from '../utils';
import Input from '../../../../atoms/Input/Input';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import InputDateSelect from '../../../../atoms/InputDateSelect/InputDateSelect';


const TransportationDocumentForm = ({
  form,
  translations,
  values,
  onChange,
}) => <>

  <div>
    {ATTACHMENTS.map((attachment) => (
      <>
        <Row>
          <Col MD={6} style={{ textAlign: 'right', }}>{translations.form.attachments[attachment]}</Col>
          <Col MD={18}>
            <InputCheckbox
              value={values[`${attachment}Checkbox`]}
              onChange={(newValue) => onChange(form[`${attachment}Checkbox`].name, newValue)}
            />
          </Col>
        </Row>

        {values[`${attachment}Checkbox`] && (
          <>
            <Row>
              <Col MD={6} />
              <Col MD={3} style={{ display: 'flex', alignItems: 'center', }}>
                {translations.form.file}
:
              </Col>
              <Col MD={15}>
                <Input
                  type="file"
                  onChange={(e) => onChange(form[`${attachment}File`].name, e.target.files[0])}
                  status={form[`${attachment}File`].status}
                  style={{ fontSize: '12px', }}
                />
              </Col>
            </Row>

            {ADR_ATTACHMENTS.indexOf(attachment) !== -1 && (
              <>
                <Row>
                  <Col MD={6} />
                  <Col MD={3} style={{ display: 'flex', alignItems: 'center', }}>
                    {translations.form.certificateNumber}
:
                  </Col>
                  <Col MD={15}>
                    <Input
                      type="text"
                      value={values[`${attachment}No`]}
                      onChange={(e) => onChange(form[`${attachment}No`].name, e.target.value)}
                      status={form[`${attachment}No`].status}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col MD={6} />
                  <Col MD={3} style={{ display: 'flex', alignItems: 'center', }}>
                    {translations.form.expirationDate}
:
                  </Col>
                  <Col MD={15}>
                    <InputDateSelect
                      value={values[`${attachment}Date`]}
                      onChange={(newDate) => onChange(form[`${attachment}Date`].name, newDate)}
                      status={form[`${attachment}Date`].status}
                    />
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
      </>
    ))}

  </div>

</>;


TransportationDocumentForm.propTypes = {
  translations: object.isRequired,
  form: shape({
  }).isRequired,
  values: shape({
  }).isRequired,
  onChange: func.isRequired,
};


export default TransportationDocumentForm;
