import React, { Component, } from 'react';
import {
  object, func, number,
} from 'prop-types';
import DayPicker from 'react-day-picker';

import { TRANSLATIONS_CALENDAR, } from './gql';
import { withTranslations, } from '../../logic/translations/withTranslations';
import YearMonthForm from './YearMonthForm';

import StyledCalendar from './styles/StyledCalendar';


class Calendar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectYearMonth: props.value,
    };
  }


  /**
   * Update selectYearMonth if value changed
   */
  componentDidUpdate(prevProps) {
    const { value, } = this.props;
    if (value && value !== prevProps.value) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        selectYearMonth: value,
      });
    }
  }


  /**
   * Select Year Month: onChange
   */
  handleYearMonthChange = (newSelectYearMonth) => {
    this.setState({ selectYearMonth: newSelectYearMonth, });
  }


  /**
   * Translations
   */
  getTexts = () => {
    const { translationsCommon, } = this.props;

    return {
      WEEKDAYS_SHORT: [
        translationsCommon.calendar.dayShortSu,
        translationsCommon.calendar.dayShortMo,
        translationsCommon.calendar.dayShortTu,
        translationsCommon.calendar.dayShortWe,
        translationsCommon.calendar.dayShortTh,
        translationsCommon.calendar.dayShortFr,
        translationsCommon.calendar.dayShortSa,
      ],
      WEEKDAYS_LONG: [
        translationsCommon.calendar.sunday,
        translationsCommon.calendar.monday,
        translationsCommon.calendar.tuesday,
        translationsCommon.calendar.wednesday,
        translationsCommon.calendar.thursday,
        translationsCommon.calendar.friday,
        translationsCommon.calendar.saturday,
      ],
      MONTHS: [
        translationsCommon.calendar.january,
        translationsCommon.calendar.february,
        translationsCommon.calendar.march,
        translationsCommon.calendar.april,
        translationsCommon.calendar.may,
        translationsCommon.calendar.june,
        translationsCommon.calendar.july,
        translationsCommon.calendar.august,
        translationsCommon.calendar.september,
        translationsCommon.calendar.october,
        translationsCommon.calendar.november,
        translationsCommon.calendar.december,
      ],
      LABELS: {
        nextMonth: '>',
        previousMonth: '<',
      },
    };
  }


  render() {
    const { selectYearMonth, } = this.state;
    const { value, firstDayOfWeek, onChange, } = this.props;

    const texts = this.getTexts();

    return (
      <StyledCalendar>
        <DayPicker
          month={selectYearMonth}
          onDayClick={onChange}
          selectedDays={value}
          months={texts.MONTHS}
          weekdaysLong={texts.WEEKDAYS_LONG}
          weekdaysShort={texts.WEEKDAYS_SHORT}
          firstDayOfWeek={firstDayOfWeek}
          labels={texts.LABELS}
          captionElement={(captionElementData) => (
            <YearMonthForm
              monthsNames={texts.MONTHS}
              date={captionElementData.date}
              localeUtils={captionElementData.localeUtils}
              onChange={this.handleYearMonthChange}
            />
          )}
        />
      </StyledCalendar>
    );
  }
}


Calendar.propTypes = {
  value: object,
  firstDayOfWeek: number,
  translationsCommon: object.isRequired,
  onChange: func,
};

Calendar.defaultProps = {
  value: null,
  firstDayOfWeek: 1,
  onChange: undefined,
};


export default withTranslations(TRANSLATIONS_CALENDAR)(Calendar);
