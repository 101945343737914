import gql from 'graphql-tag';


/**
 * Visitors
 */
export const MUTATION_CREATE_VISITOR = gql`
  mutation CreateVisitor($name: String, $licensePlate: String, $type: String, $notice: String) {
    createVisitor(name: $name, licensePlate: $licensePlate, type: $type, notice: $notice) {
      id
    } 
  }
`;

export const MUTATION_DEPARTURE_VISITOR = gql`
  mutation DepartureVisitor($id: ID) {
    departureVisitor(id: $id)
  }
`;


/**
 * Actions
 */
export const MUTATION_COMPLETE_ACTION = gql`
  mutation CompleteAction($values: JSON, $transportationEditFormValues: [JSON], $transportationId: ID!, $genericFormId: ID, $actionId: ID!, $standId: ID!, $inProgress: Boolean!) {
    completeAction(values: $values, transportationEditFormValues: $transportationEditFormValues, transportationId: $transportationId, genericFormId: $genericFormId, actionId: $actionId, standId: $standId, inProgress: $inProgress)
  }
`;


export const MUTATION_MOVE_TO_NEXT_STAND = gql`
  mutation MoveToNextStand($sourceStandId: ID!, $targetStandId: ID, $transportationId: ID!) {
    moveToNextStand(sourceStandId: $sourceStandId, targetStandId: $targetStandId, transportationId: $transportationId)
  }
`;

export const MUTATION_COMPLETE_MOVE_TO_NEXT_STAND = gql`
  mutation CompleteMoveToNextStand($sourceStandId: ID!, $actionId: ID!, $transportationId: ID!) {
    completeMoveToNextStand(sourceStandId: $sourceStandId, actionId: $actionId, transportationId: $transportationId)
  }
`;

export const MUTATION_DO_STAND_CALL = gql`
  mutation DoStandCall($transportationId: ID!, $standId: ID!) {
    doStandCall(transportationId: $transportationId, standId: $standId)
  }
`;

export const MUTATION_SOE_STAND_CALL = gql`
  mutation SoeStandCall($transportationId: ID!, $standId: ID!, $platform: String!) {
    soeStandCall(transportationId: $transportationId, standId: $standId, platform: $platform)
  }
`;

export const MUTATION_UNDO_STAND_CALL = gql`
  mutation UndoStandCall($transportationId: ID!, $standId: ID!) {
    undoStandCall(transportationId: $transportationId, standId: $standId)
  }
`;
