import gql from 'graphql-tag';


/**
 * History
 */
export const QUERY_HISTORY_TRANSLATIONS = gql`
  query Dictionary($languageId: ID) {
    dictionary(languageId: $languageId) {
      dictionary {
        centralApp {
          common {
            history
            filter
            home
            detail
            yes
            no
            from
            to
            greaterThan
            lessThan
            timeWindowDuration
            minutes
          }
          form {
            transportationType
            truckRegistrationNo
            trailerRegistrationNo
            no
            arrivalState
            arrivalTime
            departureTime
            reserved
            clearanceTime
            clearanceLength
            reservationType
          }
          transportation {
            historyEmpty
            thActions
            placeholderHistoryClearanceGreaterThan
            placeholderHistoryClearanceLessThan
          }
          dropDownOptions {
            arrivalStateDropDown {
              delayed
              premature
              notComing
              ok
              inactive
            }
            yesNoAllDropDown {
              yes
              no
              all
            }
            transportationReservationTypeDropDown {
              registration
              reservationTimeWindow
              reservationTimeWindowWithPlatform
            }
          }
        }
      }
    }
  }
`;


/**
 * Schedule
 */
export const QUERY_SCHEDULE_VIEW_TRANSLATIONS = gql`
  query Dictionary($languageId: ID) {
    dictionary(languageId: $languageId) {
      dictionary {
        centralApp {
          common {
            schedule
            filter
            home
            detail
            from
            to
            timeWindowDuration
            minutes
          }
          form {
            timeWindow
            no
            transportationType
            truckRegistrationNo
            trailerRegistrationNo
            platform
            arrivalTime
            carrier
            transportationType
            reservationType
            date
          }
          transportation {
            printTicketBtn
            showChatBtn
            sendRsPin
            sendRsPinSuccess
            sendRsPinError
            thActions
            scheduleEmpty
            titleScheduleTimelineLegend
            tabScheduleAll
            tabScheduleTimelinePlatforms
            tabScheduleTimelineTransportationTypes
            tabScheduleTableReservationDay
            btnNewTransportation
            btnApplyFilter
            labelRange
            range6Hours
            range12Hours
            range18Hours
            range24Hours
            range30Hours
            headerTimelinePlatforms
            headerTimelineTransportationType
            placeholderTransportationType
            labelReservationOnDayAproxArrivalTime
            connectedTransports {
              titleSettings
              labelAutostartAfterFinishPrev
              buttonAddNext
              titleDetail
              titlePrevTransport
              titleNextTransport
            }
            titleOrderNumbers
            orderNumber
            orderCountry
          }
          dropDownOptions {
            arrivalStateDropDown {
              planned
              delayed
              premature
              notComing
              ok
              inactive
            }
            transportationReservationTypeDropDown {
              registration
              reservationTimeWindow
              reservationTimeWindowWithPlatform
            }
          }
        }
      }
    }
  }
`;


/**
 * Transportation
 */
export const TRANSLATIONS_TRANSPORTATION = gql`
  query Dictionary($languageId: ID) {
    dictionary(languageId: $languageId) {
      dictionary {
        centralApp {
          common {
            close
            back
            create
            created
            select
            from
            to
            unit
            update
            updated
            delete
            yes
            no
            overwrite
            downloadPdfLabel
            timeWindowDuration
            minutes
          }
          transportation {
            printTicketBtn
            showChatBtn
            sendRsPin
            sendRsPinSuccess
            sendRsPinError
            detailTransportationModalTitle
            titleTransportationFlow
            titleTransportationActions
            createModalTransportationTitle
            subHeaderReservation
            subHeaderVehicle
            subHeaderCargo
            infoNotSelectedReservationType
            editModalTransportationTitle
            placeholderPlatform
            transportationNotStartedYet
            titleDeleteTransportation
            textDeleteTransportation
            placeholderFilterCompany
            placeholderFilterDriver
            placeholderFilterTruck
            btnApplyFilter
            btnSaveAsNewCompany
            titleReservationForm
            titleCompanyForm
            titleDriverForm
            titleTruckForm
            titleTrailerForm
            titleExtraForm
            titleDocumentForm
            titleLoadingForm
            btnApplyFilter
            gateInText
            gateOutText
            sampleText
            labelReservationOnDayAproxArrivalTime
            connectedTransports {
              titleSettings
              labelAutostartAfterFinishPrev
              buttonAddNext
              titleDetail
              titlePrevTransport
              titleNextTransport
            }
            titleOrderNumbers
            orderNumber
            orderCountry
            products {
              transportationTypeId
              name
              destination
              quantity
              unit
              package
              adrClass
              un
              sample
              imoDocument
              weightIn
              weightInUnit
              weightOut
              weightOutUnit
              diff
            }
            technicalCheck {
              inputTechnicalCheckTitle
              outputTechnicalCheckTitle
              titleBasicForm
              titleDriverForm
              titleTruckAndTrailerForm
              titleCisternForm
              titleEntranceForm
              titleLoadForm
              titleChamberFillingForm
              titleDangerousGoodsForm
              titleEquipmentForm
              titleVehicleMarkingsForm
              titleTechnicalDocumentsForm
              titlePlombForm
              titleResultForm
              titleFilesForm
            }
          }
          dropDownOptions {
            transportationReservationTypeDropDown {
              registration
              reservationTimeWindow
              reservationTimeWindowWithPlatform
            }
          }
          form {
            no
            personName
            carrier
            driver
            driverPhoneNumber
            passenger
            recipients
            transportationType
            truckRegistrationNo
            trailerRegistrationNo
            cargoCode
            cargoWeight
            cargoWeightUnit
            cargoHeight
            cargoHeightUnit
            cargoLength
            cargoLengthUnit
            cargoPalettesCount
            reservationType
            date
            platform
            reservationNumber
            truckRegistrationNumber
            trailerRegistrationNumber
            phoneNumber
            name
            contactPerson
            email
            identificationNumber
            vatNumber
            street
            city
            zip
            state
            nationality
            timeWindow
            language
            carRegistrationCountry
            carTrailerRegistrationCountry
            tankerCode
            tankerCheckExpiration
            carConstruction
            carConstructionNone
            carType
            carTypeTarping
            carTypeCabinet
            carTypeRefrigerator
            carTypeContainerTrailer
            carTypeCistern
            carTypeSilo
            carTypeVan
            carTypePassengerCar
            carTypeOther
            chamberCount
            chamberVolume
            breakwaterCount
            chamberFillingCount
            chamberFillingProduct
            chamberFillingVolume
            chamberFillingCleared
            otherSenderAtEntrance
            goodsLoaded
            goodsLoadedDangerous
            goodsLoadedSafe
            goodsLoadedBoth
            goodsLoadedUn
            lowerLimitQuantity
            recalculationOfTotalLimitQuantity
            transportationIn450LPackage
            order
            chamber
            chamberQuantity
            isAdr
            isMultipleOrder
            multipleOrderExpiration
            file
            certificateNumber
            expirationDate
            carrier
            carrierAddress
            driverNationality
            driverIdentityDocumentNumber
            adrDriverNo
            adrDriverDate
            passengerName
            passengerNationality
            passengerIdentityDocumentNumber
            adrPassengerNo
            adrPassengerDate
            adrTruckNo
            adrTruckDate
            adrTrailerNo
            adrTrailerDate
            hasPlombs
            plombCount
            plomb
            product
            companyEligibility
            valveCheck
            outputTechnicalCheck
            technicalCheckResult
            technicalCheckResultSuccess
            technicalCheckResultFail
            technicalCheckPin
            technicalCheckPinInfo
            technicalCheckSaveAndCloseBtn
            technicalCheckSaveAndContinueBtn
            note
            inputTechnicalCheckDocument
            outputTechnicalCheckDocument
            hasOwnTransport
            adr {
              dangerousGoods {
                isAdr
                lowerLimitQuantity
                combinedTransport
                technicalConditionsOfContainer
                warningOrangeADRTable
                warningOrangeIMOTable
                safetyPictograms
              }
              equipment {
                fireExtinguisher2KG
                fireExtinguisher6KG
                wedges
                standingWarningDevices
                warningVest
                handFlashlight
                eyewash
                sealingGlasses
                gloves
                appropriateShoes
                apron
                shovel
                faceguard
                collectionContainer
                firstAidKit
                writtenInstructions
                stopperSewerage
              }
              technicalDocuments {
                soe
                customs
                delivery
                cmr
                atests
                imo
                weight
                proformDelivery
                security
              }
              vehicleMarkings {
                orangeTables
                chamber0
                chamber1
                chamber2
                chamber3
                safetySignsContainer
                unNumberContainer
              }
            }
            attachments {
              weightListFile
              crmFile
              deliveryListFile
              invoiceFile
              adrDriverFile
              adrPassengerFile
              adrTruckFile
              adrTrailerFile
              adrContainerFile
            }
          }
        }
      }
    }
  }
`;
