import React, { Fragment, } from 'react';
import {
  shape, string, object, func,
} from 'prop-types';
import { Switch, Redirect, Route, } from 'react-router-dom';
import {Query, Subscription,} from 'react-apollo';

import { QUERY_USER, } from '../gql/queries';
import { SUBSCRIPTION_USER, } from '../gql/subscriptions';
import { getAccessToken, } from '../../../logic/localStorage/auth';
import PrivateRoute from '../../../components/PrivateRoute/PrivateRoute';
import AppTemplate from '../../../components/AppTemplate/AppTemplate';
import LoadingPage from './LoadingPage';
import TransportationLayout from '../../Transportation/pages/TransportationLayout';
import DashboardPage from '../../Dashboard/pages/DashboardPage';
import DirectoryLayout from '../../Directory/pages/DirectoryLayout';
import StandsLayout from '../../Stands/pages/StandsLayout';
import MonitoringLayout from '../../Monitoring/pages/MonitoringLayout';
import StorekeeperPage from '../../Storekeeper/pages/StorekeeperPage';
import SettingsLayout from '../../Settings/pages/SettingsLayout';
import NotFoundPage from './NotFoundPage';
import NotAuthorizedPage from './NotAuthorizedPage';
import ErrorBoundary from '../../../components/ErrorBoundary/ErrorBoundary';
import Card from '../../../atoms/Card/Card';
import PartError from '../../../components/Parts/PartError';
import SubscriberNotifications from '../../Monitoring/components/SubscriberNotifications/SubscriberNotifications';
import ParkingLayout from '../../Parking/pages/ParkingLayout';
import ReservationSystemLayout from '../../ReservationSystem/pages/ReservationSystemLayout';
import GatehousePage from '../../Gatehouse/pages/GatehousePage';
import IncomingSmsNotifier from '../../Transportation/components/IncomingSmsNotifier/IncomingSmsNotifier';
import ModalSmsChat from '../../Storekeeper/components/Platforms/ModalSmsChat/ModalSmsChat';
import { withTranslations, } from '../../../logic/translations/withTranslations';
import { TRANSLATIONS_STOREKEEPER, } from '../../Storekeeper/gql/translations';
import GatehouseSelectorPage from '../../Gatehouse/pages/GatehouseSelectorPage';
import ReportingLayout from '../../Reporting/pages/ReportingLayout';
import SamplePage from '../../Sample/pages/SamplePage';


class ModulesLayout extends React.Component {
  render() {
    const {
      location, smsChatModal, onToggleSmsChatModal, translations, languageId,
    } = this.props;
    const accessToken = getAccessToken();

    if (!accessToken) {
      return (
        <Redirect to="/login" />
      );
    }

    return (
      <Query
        query={QUERY_USER}
        variables={{ accessToken, }}
      >
        {({
          loading, error, data, subscribeToMore,
        }) => {
          if (loading) {
            return <LoadingPage />;
          }

          if (error || !data.getUser) {
            return <Redirect to="/login" />;
          }

          const { resources, } = data.getUser;

          return (
            <Fragment>

              <SubscriberNotifications />
              <IncomingSmsNotifier resources={resources} />

              <AppTemplate
                resources={resources}
                subscribeToUser={() => subscribeToMore({
                  document: SUBSCRIPTION_USER,
                  variables: { id: data.getUser.user.id, },
                  updateQuery: (prev, { subscriptionData, }) => {
                    if (!subscriptionData.data) return null;
                    const newData = subscriptionData.data.user;
                    return {
                      getUser: newData,
                    };
                  },
                })}
              >
                <ErrorBoundary
                  key={location.pathname === undefined ? 'initKey' : location.pathname}
                  renderError={() => (
                    <Card>
                      <PartError />
                    </Card>
                  )}
                >
                  {smsChatModal.isOpen && (
                    <ModalSmsChat
                      // data
                      transportationId={smsChatModal.transportationId}
                      languageId={languageId}
                      translations={translations}
                      // methods
                      onToggle={onToggleSmsChatModal}
                    />
                  )}

                  <Switch>
                    <PrivateRoute resource exact path="/" component={DashboardPage} />
                    <PrivateRoute
                      resource={resources.logistic}
                      path="/transportation"
                      component={TransportationLayout}
                    />
                    <PrivateRoute resource={resources.directory} path="/directory" component={DirectoryLayout} />
                    <PrivateRoute resource={resources.stand} path="/stands" component={StandsLayout} />
                    <PrivateRoute resource={resources.monitoring} path="/monitoring" component={MonitoringLayout} />
                    <PrivateRoute
                      resource={resources.storekeeper_central_app}
                      path="/storekeeper"
                      component={StorekeeperPage}
                    />
                    <PrivateRoute
                      resource={resources.rs_user || resources.rs_blocking_day}
                      path="/reservation-system"
                      component={ReservationSystemLayout}
                    />
                    <PrivateRoute resource={resources.settings} path="/settings" component={SettingsLayout} />
                    <PrivateRoute resource={resources.gatehouse_module} path="/gatehouse/:type" component={GatehousePage} />
                    <PrivateRoute resource={resources.gatehouse_module} path="/gatehouse" component={GatehouseSelectorPage} />
                    <PrivateRoute resource={resources.reporting} path="/reporting" component={ReportingLayout} />
                    <PrivateRoute resource={resources.sample} path="/samples" component={SamplePage} />
                    <Route path="/notauthorized" component={NotAuthorizedPage} />
                    <PrivateRoute resource component={NotFoundPage} />
                  </Switch>
                </ErrorBoundary>
              </AppTemplate>
            </Fragment>
          );
        }}
      </Query>
    );
  }
}


ModulesLayout.propTypes = {
  location: shape({
    key: string,
  }).isRequired,
  smsChatModal: object.isRequired,
  translations: object.isRequired,
  onToggleSmsChatModal: func.isRequired,
};

export default withTranslations(TRANSLATIONS_STOREKEEPER)(ModulesLayout);
