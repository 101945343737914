import React, { Component, } from 'react';
import { oneOfType, arrayOf, node, } from 'prop-types';

import ModalContext from './ModalContext';


class ModalProvider extends Component {
  state = {
    modals: [],
    scrollDisabled: false,
  }

  addModal = (modalId) => {
    const { modals, scrollDisabled, } = this.state;

    if (modals.indexOf(modalId) === -1) {
      if (!scrollDisabled) {
        this.disableScroll();
      }

      this.setState({
        modals: [
          ...modals,
          modalId,
        ],
        scrollDisabled: true,
      });
    }
  }

  removeModal = (modalId) => {
    const { modals, } = this.state;
    const index = modals.indexOf(modalId);
    const newModals = modals;

    if (index !== -1) {
      newModals.splice(index, 1);

      const scrollEnable = newModals.length === 0;
      if (scrollEnable) {
        this.enableScroll();
      }

      this.setState({
        modals: newModals,
        scrollDisabled: !scrollEnable,
      });
    }
  }

  disableScroll = () => {
    document.querySelector('html').classList.add('u-lock-scroll');
  }

  enableScroll = () => {
    document.querySelector('html').classList.remove('u-lock-scroll');
  }


  render() {
    const { children, } = this.props;
    const { modals, } = this.state;

    return (
      <ModalContext.Provider
        value={{
          addModal: this.addModal,
          removeModal: this.removeModal,
          modals,
        }}
      >
        {children}
      </ModalContext.Provider>
    );
  }
}


ModalProvider.propTypes = {
  children: oneOfType([
    arrayOf(node),
    node,
  ]).isRequired,
};


export default ModalProvider;
