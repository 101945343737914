import gql from 'graphql-tag';

import { QUEUE_TABS, } from '../../modules/Storekeeper/components/Queue/Queue';
import { QUERY_SCHEDULE_TIMELINE_FILTER, } from '../../modules/Transportation/gql/queries';
import { GET_STOREKEEPER_QUEUE_DATA, } from '../../modules/Storekeeper/gql/queries';


export const initData = {
  languageId: null,
  scheduleFilter: {
    from: null,
    to: null,
    transportationType: null,
    range: null,
    __typename: 'ScheduleTimelineFilter',
  },
  storekeeperQueue: {
    activeTab: QUEUE_TABS.ALL,
    platform: null,
    __typename: 'StorekeeperQueueFilter',
  },
};


export const typeDefs = gql`
  type ScheduleTimelineFilter {
    from: String
    to: String
    transportationType: TransportationType
    range: TimelineFilterRange
  }

  type TransportationType {
    id: ID
    name: String
  }

  type TimelineFilterRange {
    id: ID
    value: Number
    name: String
  }

  type StorekeeperQueueFilter {
    activeTab: String
    platform: Platform
  }

  type Platform {
    id: String
    name: string
  }

  type Query {
    scheduleFilter: ScheduleTimelineFilter
    storekeeperQueue: StorekeeperQueueFilter
  }

  type Mutation {
    updateScheduleTimelineFilter(filter: ScheduleTimelineFilter!): Boolean
    changeStorekeeperQueue(tab: String!, id: String!, name: String!): Boolean
    changeTabStorekeeperQueue(tab: String!): Boolean
    changePlatformStorekeeperQueue(id: String!, name: String!): Boolean
  }
`;


// TODO - there should be better way how to ass __typename
export const resolvers = {
  Mutation: {
    updateScheduleTimelineFilter: (_, { filter, }, { cache, }) => {
      const queryResult = cache.readQuery({
        query: QUERY_SCHEDULE_TIMELINE_FILTER,
      });

      // TODO probably don't need to write __typename everywhere
      const data = {
        scheduleFilter: {
          ...queryResult.scheduleFilter,
          ...filter,
          from: filter.from.toUTCString(),
          to: filter.to.toUTCString(),
          transportationType: filter.transportationType
            ? {
              ...filter.transportationType,
              __typename: 'TransportationType',
            } : null,
          range: {
            ...filter.range,
            __typename: 'TimelineFilterRange',
          },
          __typename: 'ScheduleTimelineFilter',
        },
      };

      cache.writeQuery({ query: QUERY_SCHEDULE_TIMELINE_FILTER, data, });
    },
    changeStorekeeperQueue: (_, { tab, id, name, }, { cache, }) => {
      const queryResult = cache.readQuery({
        query: GET_STOREKEEPER_QUEUE_DATA,
      });

      const data = {
        storekeeperQueue: {
          ...queryResult.storekeeperQueue,
          activeTab: tab,
          platform: {
            id,
            name,
            __typename: 'Platform',
          },
          __typename: 'StorekeeperQueueFilter',
        },
      };

      cache.writeQuery({ query: GET_STOREKEEPER_QUEUE_DATA, data, });
    },
    changeTabStorekeeperQueue: (_, { tab, }, { cache, }) => {
      const queryResult = cache.readQuery({
        query: GET_STOREKEEPER_QUEUE_DATA,
      });

      const data = {
        storekeeperQueue: {
          ...queryResult.storekeeperQueue,
          activeTab: tab,
          __typename: 'StorekeeperQueueFilter',
        },
      };

      cache.writeQuery({ query: GET_STOREKEEPER_QUEUE_DATA, data, });
    },
    changePlatformStorekeeperQueue: (_, { id, name, }, { cache, }) => {
      const queryResult = cache.readQuery({
        query: GET_STOREKEEPER_QUEUE_DATA,
      });

      const data = {
        storekeeperQueue: {
          ...queryResult.storekeeperQueue,
          platform: {
            id,
            name,
            __typename: 'Platform',
          },
          __typename: 'StorekeeperQueueFilter',
        },
      };

      cache.writeQuery({ query: GET_STOREKEEPER_QUEUE_DATA, data, });
    },
  },
};
