import React from 'react';
import {
  shape, string, func, object, bool, arrayOf,
} from 'prop-types';

import FormRow from '../../../../components/Form/FormRow';
import InputDateSelect from '../../../../atoms/InputDateSelect/InputDateSelect';
import InputCheckbox from '../../../../atoms/InputCheckbox/InputCheckbox';
import { hasSomeReservationFormLoadingType, } from '../utils';


const TransportationExtraForm = ({
  form,
  translations,
  values,
  reservationForms,
  isEdit,
  onChange,
}) => <>

  <div>
    <InputCheckbox
      value={values.isAdr}
      text={translations.form.isAdr}
      onChange={(newValue) => onChange(form.isAdr.name, newValue)}
      disabled={isEdit}
    />

  </div>

  {!hasSomeReservationFormLoadingType(reservationForms) && (
      <>
        <div>
          <InputCheckbox
            value={values.isMultipleOrder}
            text={translations.form.isMultipleOrder}
            onChange={(newValue) => onChange(form.isMultipleOrder.name, newValue)}
            disabled={isEdit}
          />

        </div>

        <FormRow
          label={translations.form.multipleOrderExpiration}
          input={form.multipleOrderExpiration}
        >
          <InputDateSelect
            value={values.multipleOrderExpiration}
            onChange={(newDate) => onChange(form.multipleOrderExpiration.name, newDate)}
            status={form.multipleOrderExpiration.status}
            disabled={!values.isMultipleOrder}
          />

        </FormRow>
      </>
  )}
  </>;


TransportationExtraForm.propTypes = {
  translations: object.isRequired,
  form: shape({
    isAdr: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    isMultipleOrder: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    multipleOrderExpiration: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
  }).isRequired,
  reservationForms: arrayOf(object),
  values: shape({
    isAdr: bool,
    isMultipleOrder: bool,
    multipleOrderExpiration: object,
  }).isRequired,
  isEdit: bool,
  onChange: func.isRequired,
};


export default TransportationExtraForm;
