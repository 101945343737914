import React, { Fragment, } from 'react';
import {
  shape, string, func, object,
} from 'prop-types';

import FormRow from '../../../../../components/Form/FormRow';
import Input from '../../../../../atoms/Input/Input';

const SendSmsForm = ({
  form,
  translations,
  values,
  onChange,
}) => (
  <Fragment>

    <FormRow
      label={translations.form.smsText}
      input={form.text}
    >
      <Input
        type="text"
        value={values.text}
        onChange={(e) => onChange(form.text.name, e.target.value)}
        status={form.text.status}
      />
    </FormRow>

  </Fragment>
);


SendSmsForm.propTypes = {
  translations: object.isRequired,
  form: shape({
    text: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
  }).isRequired,
  values: shape({
    text: string,
  }).isRequired,
  onChange: func.isRequired,
};


export default SendSmsForm;
