import React from 'react';
import { shape, string, object, } from 'prop-types';
import { Query, } from 'react-apollo';

import { QUERY_STAND_CONFIGURATION, } from '../../gql/queries';
import { TRANSLATION_STAND_CONFIGURATION, } from '../../gql/translations';
import Card from '../../../../atoms/Card/Card';
import PartLoading from '../../../../components/Parts/PartLoading';
import PartError from '../../../../components/Parts/PartError';
import StandLogic from './StandLogic';


const Stand = ({
  // data
  resources,
  stand,
  languageId,
  translations,
}) => (
  <Card>
    <Query
      query={QUERY_STAND_CONFIGURATION}
      variables={{ standId: stand.id, }}
    >
      {(configurationData) => {
        if (configurationData.loading) {
          return <PartLoading />;
        }

        if (
          configurationData.error
          || !configurationData.data
          || !configurationData.data.fetchStandDashboardConfiguration
        ) {
          return <PartError error={configurationData.error} />;
        }

        return (
          <Query
            query={TRANSLATION_STAND_CONFIGURATION(
              configurationData.data.fetchStandDashboardConfiguration
            )}
            variables={{ languageId, }}
          >
            {({ loading, data, error, }) => {
              if (loading) {
                return <PartLoading />;
              }

              if (
                error
                || !data
                || !data.dictionary
              ) {
                return <PartError error={error} />;
              }

              return (
                <StandLogic
                  // data
                  resources={resources}
                  stand={stand}
                  standConfiguration={configurationData.data.fetchStandDashboardConfiguration}
                  standTranslations={data.dictionary.dictionary.centralApp}
                  languageId={languageId}
                  translations={translations}
                />
              );
            }}
          </Query>
        );
      }}
    </Query>
  </Card>
);


Stand.propTypes = {
  resources: object.isRequired,
  languageId: string.isRequired,
  stand: shape({
    id: string.isRequired,
  }).isRequired,
  translations: object.isRequired,
};


export default Stand;
