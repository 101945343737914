import React, { Fragment, } from 'react';
import {
  shape, func, object, bool,
} from 'prop-types';
import { Mutation, } from 'react-apollo';

import { MUTATION_UPDATE_USER, } from '../../gql/mutations';
import Button from '../../../../atoms/Button/Button';
import ButtonLoader from '../../../../atoms/Button/ButtonLoader';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import FormError from '../../../../components/Form/FormError';
import UserForm from '../UserForm/UserForm';
import FormBackground from '../../../../components/Form/FormBackground';


const UserUpdateView = ({
  // data
  userForm,
  translations,
  // methods
  onChangeForm,
  onClose,
  onUpdate,
  onUpdateCompleted,
  onUpdateError,
}) => (
  <FormBackground>
    <UserForm
      // data
      isCreate={false}
      userForm={userForm}
      translations={translations}
      // methods
      onChangeForm={onChangeForm}
    />

    <Mutation
      mutation={MUTATION_UPDATE_USER}
      onCompleted={onUpdateCompleted}
      onError={onUpdateError}
    >
      {(mutate, { loading, error, }) => (
        <Fragment>
          <FormError
            isValid={userForm.isValid}
            error={error}
          />
          <Row>
            <Col SM={12} MD={12} textAlign="left">
              <Button
                onClick={onClose}
              >
                {translations.common.back}
              </Button>
            </Col>
            <Col SM={12} MD={12} textAlign="right">
              <ButtonLoader
                color="success"
                onClick={() => onUpdate(mutate)}
                disabled={!userForm.isValid}
                isLoading={loading}
              >
                {translations.common.update}
              </ButtonLoader>
            </Col>
          </Row>
        </Fragment>
      )}
    </Mutation>
  </FormBackground>
);


UserUpdateView.propTypes = {
  translations: object.isRequired,
  userForm: shape({
    isValid: bool.isRequired,
  }).isRequired,
  onChangeForm: func.isRequired,
  onClose: func.isRequired,
  onUpdate: func.isRequired,
  onUpdateCompleted: func.isRequired,
  onUpdateError: func.isRequired,
};


export default UserUpdateView;
