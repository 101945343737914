import React from 'react';
import { object, } from 'prop-types';


const PlatformSectionClosed = ({
  translations,
}) => (
  <div className="storekeeperPlatformSection--closed">
    {translations.centralAppStorekeeper.infoPlatformClosed}
  </div>
);


PlatformSectionClosed.propTypes = {
  translations: object.isRequired,
};


export default PlatformSectionClosed;
