import React from 'react';
import styled from 'styled-components';

import Loader from '../../../atoms/Loader/Loader';


const StyledLoading = styled.div`
  background-image: url("../../../../../../images/login.jpg");
  background-repeat: no-repeat;
  background-size: cover;

  .loadingPage-view {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: rgba(0,0,0,0.65);

    color: ${(p) => p.theme.white};
    font-size: 4rem;
  }
`;


const LoadingPage = () => (
  <StyledLoading>
    <div className="loadingPage-view">
      <span>
        <Loader />
      </span>
    </div>
  </StyledLoading>
);


export default LoadingPage;
