// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
import React, { Component, } from 'react';
import {
  Bar, BarChart as BarReChart, Cell, ResponsiveContainer,
} from 'recharts';
import { arrayOf, object, } from 'prop-types';

import {
  BarChartDefault, BarDefault, findConditionalConfig,
} from '../../utils/config';
import XAxis from './XAxis';
import YAxis from './YAxis';
import Grid from './Grid';
import { getMetricTranslation, } from '../../utils/translation';
import { createFieldOrDefault, } from '../../../../logic/object';


/**
 * Maps data from format [{ name: A, x: 1, y: 2 }, { name: B, x: 5, y: 6 }, { name: C, x: 0, y: 3 }]
 * to format [{ group: x, 0: 1, 1: 5, 2: 0 }, { group: y, 0: 2, 1: 6, 2: 3 }].
 */
const mapMetricsToBarData = (
  metrics,
  groups,
) => groups
  .map(({ group, usageLongDiffName, }) => {
    const data = {
      group: getMetricTranslation(group, usageLongDiffName),
    };

    metrics.forEach((metric, i) => {
      data[i] = metric[group];
    });

    return data;
  });

const getFill = (groupConfig, value, prevValue) => {
  const config = findConditionalConfig(groupConfig.bar, value, prevValue);

  return createFieldOrDefault(config, BarDefault)('color');
};


class BarChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mappedData: mapMetricsToBarData(
        props.data,
        props.config.barGroups,
      ), // BarMappedData[]
    };
  }

  render() {
    const { config, data, } = this.props;
    const { mappedData, } = this.state;
    const getField = createFieldOrDefault(config, BarChartDefault);

    return (
      <>
        {config.title ? <h3>{config.title}</h3> : null}
        <ResponsiveContainer height={getField('height')}>
          <BarReChart data={mappedData}>
            {Grid(getField('grid'))}
            {XAxis(getField('xAxis'))}
            {YAxis(getField('yAxis'))}
            {data.map((_, barInGroupIndex) => (
              // eslint-disable-next-line react/no-array-index-key
              <Bar key={`bar-${barInGroupIndex}`} dataKey={barInGroupIndex}>
                {mappedData.map((value, groupIndex) => (
                  // manually adds cells to change colors by values
                  <Cell
                    // eslint-disable-next-line react/no-array-index-key
                    key={`cell-${groupIndex}`}
                    fill={getFill(
                      config.barGroups[groupIndex],
                      value[barInGroupIndex],
                      value[barInGroupIndex - 1],
                    )}
                  />
                ))}
              </Bar>
            ))}
          </BarReChart>
        </ResponsiveContainer>
      </>
    );
  }
}

BarChart.propTypes = {
  config: object.isRequired,
  data: arrayOf(object.isRequired).isRequired,
};


export default BarChart;
