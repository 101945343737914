export const getArrivalStateOptions = (translations) => (
  [
    {
      id: 'delayed',
      name: translations.dropDownOptions.arrivalStateDropDown.delayed,
    },
    {
      id: 'premature',
      name: translations.dropDownOptions.arrivalStateDropDown.premature,
    },
    {
      id: 'notComing',
      name: translations.dropDownOptions.arrivalStateDropDown.notComing,
    },
    {
      id: 'ok',
      name: translations.dropDownOptions.arrivalStateDropDown.ok,
    },
  ]
);


export const getYesNoAllOptions = (translations) => (
  [
    {
      id: 'yes',
      name: translations.dropDownOptions.yesNoAllDropDown.yes,
      value: true,
    },
    {
      id: 'no',
      name: translations.dropDownOptions.yesNoAllDropDown.no,
      value: false,
    },
  ]
);
