import React, { Component, } from 'react';
import { TransitionGroup, CSSTransition, } from 'react-transition-group';
import {
  oneOfType, arrayOf, node, bool, func, string,
} from 'prop-types';

import StyledDropdown from './styles/StyledDropdown';


class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.refDD = React.createRef();
  }

  componentDidMount() {
    this.addEvents();
  }

  componentWillUnmount() {
    this.removeEvents();
  }

  addEvents = () => {
    [ 'click', 'touchend', ].forEach((event) => (
      document.addEventListener(event, this.handleDocumentClick)
    ));
  }

  removeEvents = () => {
    [ 'click', 'touchend', ].forEach((event) => (
      document.removeEventListener(event, this.handleDocumentClick)
    ));
  }

  handleDocumentClick = (e) => {
    const { isOpen, toggle, } = this.props;

    if (!isOpen) return;

    if (this.refDD.current && !this.refDD.current.contains(e.target)) {
      toggle();
    }
  }

  render() {
    const {
      children, isOpen, menu, position,
    } = this.props;

    return (
      <div ref={this.refDD}>
        {children}
        <StyledDropdown position={position}>
          <TransitionGroup>
            {isOpen && (
              <CSSTransition
                classNames="appear-dropdown"
                timeout={350}
              >
                <div className="dropdown--collapse noSelect">
                  {menu}
                </div>
              </CSSTransition>
            )}
          </TransitionGroup>
        </StyledDropdown>
      </div>
    );
  }
}


Dropdown.propTypes = {
  isOpen: bool.isRequired,
  toggle: func.isRequired,
  children: oneOfType([
    arrayOf(node),
    node,
  ]).isRequired,
  position: string,
  menu: oneOfType([
    arrayOf(node),
    node,
  ]).isRequired,
};

Dropdown.defaultProps = {
  position: 'left',
};


export default Dropdown;
