import React, { Component, } from 'react';
import { string, object, func, } from 'prop-types';

import Pagination from '../../../../components/Pagination/Pagination';
import TableView from './TableView';
import {
  SUBSCRIPTION_ADD_RECORD_TO_GATE_HISTORY,
  SUBSCRIPTION_REMOVE_RECORD_FROM_GATE_HISTORY,
} from '../../gql/subscriptions';
import RecordView from '../RecordEdit/RecordView';


class TableLogic extends Component {
  state = {
    detailRecordModal: {
      isOpen: false,
      id: null,
    },
  }

  componentDidMount() {
    const { queryData, } = this.props;

    queryData.subscribeToMore({
      document: SUBSCRIPTION_ADD_RECORD_TO_GATE_HISTORY,
      updateQuery: () => {
        // refetch because of pagination
        queryData.refetch();
        return null;
      },
    });

    queryData.subscribeToMore({
      document: SUBSCRIPTION_REMOVE_RECORD_FROM_GATE_HISTORY,
      updateQuery: () => {
        // refetch because of pagination
        queryData.refetch();
        return null;
      },
    });
  }

  handleToggleRecordDetail = (id = null) => {
    this.setState((prevState) => ({
      detailRecordModal: {
        isOpen: !prevState.detailRecordModal.isOpen,
        id,
      },
    }));
  }

  render() {
    const { detailRecordModal, } = this.state;
    const {
      // data
      filter,
      queryData,
      languageId,
      translations,
      // methods
      onChangeFilter,
      onChangeSort,
      onChangeParam,
    } = this.props;

    return (
      <>
        {detailRecordModal.isOpen && (
          <RecordView
            // data
            recordId={detailRecordModal.id}
            languageId={languageId}
            translations={translations}
            // methods
            onToggle={this.handleToggleRecordDetail}
          />
        )}

        <TableView
          // data
          filter={filter}
          queryData={queryData}
          translations={translations}
          languageId={languageId}
          // methods
          onDetail={this.handleToggleRecordDetail}
          onChangeSort={onChangeSort}
          onChangeParam={onChangeParam}
        />

        <Pagination
          loading={queryData.loading}
          dataFilter={queryData.data && queryData.data.filterGateRecords
            ? queryData.data.filterGateRecords.filter
            : undefined
              }
          onChangePage={onChangeFilter}
        />
      </>
    );
  }
}

TableLogic.propTypes = {
  // data
  filter: object.isRequired,
  queryData: object.isRequired,
  languageId: string.isRequired,
  translations: object.isRequired,
  // methods
  onChangeFilter: func.isRequired,
  onChangeSort: func.isRequired,
  onChangeParam: func.isRequired,
};

export default TableLogic;
