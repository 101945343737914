import { FORM_TYPES, FORM_ROOT, } from '../../globals';
import { isValidDate, } from '../date';
import { isValidString, } from '../string';
import { isValidNumber, parseInputToNumber, } from '../number';
import { isValidInputOption, } from '../object';
import { isValidArray, } from '../array';
import { isValidTime, } from '../time';


export const createStructureNode = ({
  id = '',
  parent = null,
  type = FORM_ROOT,
  data = null,
  childs = [],
  ...extraProps
}) => ({
  id,
  parent,
  type,
  data,
  childs,
  ...extraProps,
});


export const getInitValue = ({
  type,
  defaultValue,
  currValue,
}) => {
  switch (type) {
    case FORM_TYPES.TEXT: {
      const initValue = isValidString(defaultValue) ? defaultValue : '';
      return isValidString(currValue) ? currValue : initValue;
    }

    case FORM_TYPES.NUMBER: {
      const initValue = isValidNumber(defaultValue) ? defaultValue.toString() : '';
      return isValidNumber(currValue) ? currValue.toString() : initValue;
    }

    case FORM_TYPES.CHECKBOX: {
      return !!currValue;
    }

    case FORM_TYPES.RADIO:
    case FORM_TYPES.SELECT: {
      return isValidInputOption(currValue) ? currValue : null;
    }

    case FORM_TYPES.MULTISELECT: {
      return isValidArray(currValue) ? currValue : [];
    }

    case FORM_TYPES.TIMESTAMP:
    case FORM_TYPES.DATE: {
      const parsedDate = new Date(currValue);

      return currValue && parsedDate !== 'Invalid Date' && isValidDate(parsedDate)
        ? parsedDate
        : null;
    }

    case FORM_TYPES.TIME: {
      return isValidTime(currValue) ? currValue : null;
    }

    default: {
      return null;
    }
  }
};


export const parseFormValueToApiValue = ({
  type,
  value,
}) => {
  switch (type) {
    case FORM_TYPES.TEXT: {
      return isValidString(value) ? value : '';
    }

    case FORM_TYPES.NUMBER: {
      return parseInputToNumber(value, 10);
    }

    case FORM_TYPES.CHECKBOX: {
      return !!value;
    }

    case FORM_TYPES.RADIO:
    case FORM_TYPES.SELECT: {
      return isValidInputOption(value) ? value : null;
    }

    case FORM_TYPES.MULTISELECT: {
      return isValidArray(value) ? value : [];
    }

    case FORM_TYPES.TIMESTAMP:
    case FORM_TYPES.DATE: {
      return isValidDate(value) ? value.toISOString() : null;
    }

    case FORM_TYPES.TIME: {
      return isValidTime(value) ? value : null;
    }

    default: {
      return value;
    }
  }
};
