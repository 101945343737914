import React, { Fragment, } from 'react';
import {
  shape, string, func, object,
} from 'prop-types';

import FormRow from '../../../../components/Form/FormRow';
import Input from '../../../../atoms/Input/Input';
import TextArea from '../../../../atoms/TextArea/TextArea';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import InputCheckbox from '../../../../atoms/InputCheckbox/InputCheckbox';
import InputDateSelect from '../../../../atoms/InputDateSelect/InputDateSelect';


const DriverForm = ({
  form,
  translations,
  values,
  onChange,
}) => (
  <Fragment>

    <FormRow
      label={translations.form.personName}
      input={form.name}
    >
      <Input
        type="text"
        value={values.name}
        onChange={(e) => onChange(form.name.name, e.target.value)}
        status={form.name.status}
      />
    </FormRow>

    <FormRow
      label={translations.form.email}
      input={form.email}
    >
      <Input
        type="text"
        value={values.email}
        onChange={(e) => onChange(form.email.name, e.target.value)}
        status={form.email.status}
      />
    </FormRow>

    <FormRow
      label={translations.form.phoneNumber}
      input={form.phoneNumber}
    >
      <Input
        type="text"
        value={values.phoneNumber}
        onChange={(e) => onChange(form.phoneNumber.name, e.target.value)}
        status={form.phoneNumber.status}
      />
    </FormRow>

    <Row>
      <Col SM={12}>
        <FormRow
          label={translations.form.identityDocumentNumber}
          input={form.identityDocumentNumber}
        >
          <Input
            type="text"
            value={values.identityDocumentNumber}
            onChange={(e) => onChange(form.identityDocumentNumber.name, e.target.value)}
            status={form.identityDocumentNumber.status}
          />
        </FormRow>
      </Col>
      <Col SM={12}>
        <FormRow
          label=" "
          input={form.identityDocumentNumberChecked}
        >
          <InputCheckbox
            value={values.identityDocumentNumberChecked}
            text={translations.form.identityDocumentNumberChecked}
            onChange={(newValue) => onChange(form.identityDocumentNumberChecked.name, newValue)}
          />
        </FormRow>
      </Col>
    </Row>

    <Row>
      <Col SM={12}>
        <FormRow
          label={translations.form.travelDocumentNumber}
          input={form.travelDocumentNumber}
        >
          <Input
            type="text"
            value={values.travelDocumentNumber}
            onChange={(e) => onChange(form.travelDocumentNumber.name, e.target.value)}
            status={form.travelDocumentNumber.status}
          />
        </FormRow>
      </Col>
      <Col SM={12}>
        <FormRow
          label=" "
          input={form.travelDocumentNumberChecked}
        >
          <InputCheckbox
            value={values.travelDocumentNumberChecked}
            text={translations.form.travelDocumentNumberChecked}
            onChange={(newValue) => onChange(form.travelDocumentNumberChecked.name, newValue)}
          />
        </FormRow>
      </Col>
    </Row>

    <FormRow
      label={translations.form.oshTimeEnd}
      input={form.oshTimeEnd}
    >
      <InputDateSelect
        value={values.oshTimeEnd}
        onChange={(newDate) => onChange(form.oshTimeEnd.name, newDate)}
        status={form.oshTimeEnd.status}
      />
    </FormRow>

    <FormRow
      label=" "
      input={form.onBlacklist}
    >
      <InputCheckbox
        value={values.onBlacklist}
        text={translations.form.onBlacklist}
        onChange={(newValue) => onChange(form.onBlacklist.name, newValue)}
      />
    </FormRow>

    <FormRow
      label={translations.form.info}
      input={form.info}
    >
      <TextArea
        type="text"
        value={values.info}
        onChange={(e) => onChange(form.info.name, e.target.value)}
        status={form.info.status}
      />
    </FormRow>

  </Fragment>
);


DriverForm.propTypes = {
  translations: object.isRequired,
  form: shape({
    name: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    phoneNumber: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    info: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
  }).isRequired,
  values: shape({
    name: string,
    phoneNumber: string,
    info: string,
  }).isRequired,
  onChange: func.isRequired,
};


export default DriverForm;
