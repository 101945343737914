import { clearTime, } from '../../logic/date';


const isValidDate = (year, month, day) => {
  const d = new Date(year, month, day);

  return (
    d.getFullYear() === year
    && d.getMonth() === month
    && d.getDate() === day
  );
};


/**
 * parse Input to Date object
 */
export const parseInputDate = (inputText, dateValue, localization) => {
  const ret = {
    isValid: false,
    text: inputText,
    date: null,
  };

  try {
    const date = inputText.trim();

    if (date === '') {
      return {
        ...ret,
        isValid: true,
      };
    }

    const regValidInput = /^[0-3]?[0-9][\/\-:,._\s]+[0-3]?[0-9][\/\-:,._\s]+[0-9]{4}$/; // eslint-disable-line no-useless-escape
    if (!regValidInput.test(date)) return ret;

    const divided = date.split(/[\/\-:,._\s]+/); // eslint-disable-line no-useless-escape
    const part0 = parseInt(divided[0], 10);
    const part1 = parseInt(divided[1], 10);
    const part2 = parseInt(divided[2], 10);

    // Set Date by date format
    switch (localization) {
      // DD:MM:YYYY or D:M:YYYY
      default: {
        if (isValidDate(part2, part1 - 1, part0)) {
          const newDate = new Date(dateValue || clearTime(new Date()));
          newDate.setFullYear(part2);
          newDate.setMonth(part1 - 1);
          newDate.setDate(part0);

          return ({
            ...ret,
            isValid: true,
            date: newDate,
          });
        }
        return ret;
      }
    }
  } catch {
    return ret;
  }
};
