// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
import React from 'react';
import Input from '../../../atoms/Input/Input';
import InputSelect from '../../../atoms/InputSelect/InputSelect';
import FormRowSimple from '../../../components/Form/FormRowSimple';


const ReportNotificationGroupParameters = (props) => {
  const intervals = [ 'day', 'week', 'month', ];

  const intervalTranslations = [ 'denně', 'týdně', 'měsíčně', ];

  return (
    <div>
      <span className="ral-title">{props.title}</span>
      {props.report?.parameters.filter((parameter) => parameter.type === 'integer').map((parameter) => (
        <FormRowSimple>
          <div>{parameter.label}</div>
          <div>
            <Input
              type="text"
              value={props.parameters.find((p) => p.id === parameter.id).value}
              onChange={(e) => props.onUpdateParameter(parameter.id, e.target.value)}
            />
          </div>
        </FormRowSimple>
      ))}
      <div>
        Interval pro porovnávání období
      </div>
      <div>
        <InputSelect
          value={{ id: props.compareInterval, name: intervalTranslations[intervals.indexOf(props.compareInterval)], }}
          options={intervals.map((interval, index) => ({ id: interval, name: intervalTranslations[index], }))}
          onChange={(option) => props.onUpdateCompareInterval(option.id)}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
        />
      </div>
    </div>
  );
};


export default ReportNotificationGroupParameters;
