import gql from 'graphql-tag';

export const QUERY_PARKING = gql`
  query filterCarsInParking($languageId: ID, $sortBy: String, $order: String, $params: parkingInputFilterParams) {
    filterCarsInParking(languageId: $languageId, sortBy: $sortBy, order: $order, params: $params) {
      filter {
        sortBy
        order
        count
        params {
          id
          registrationNumber
          status
          arrival
        }
      }
      rows {
        id
        registrationNumber
        arrival
        status
      }
      maxCount
      actualCount
    }
  }
`;

export const QUERY_CAR_ON_PARKING_DETAIL = gql`
  query fetchCarOnParking($id: ID) {
    fetchCarOnParking(id: $id) {
      id
      registrationNumber
      picture
    }
  }
`;

// INTERNAL PARKING -------------------------------

export const QUERY_INTERNAL_PARKING = gql`
  query filterCarsInInternalParking($languageId: ID, $sortBy: String, $order: String, $params: internalParkingInputFilterParams) {
    filterCarsInInternalParking(languageId: $languageId, sortBy: $sortBy, order: $order, params: $params) {
      filter {
        sortBy
        order
        count
        params {
          id
          registrationNumber
          arrival
        }
      }
      rows {
        id
        registrationNumber
        arrival
      }
      maxCount
      actualCount
    }
  }
`;
