import styled from 'styled-components';
import { bool, } from 'prop-types';


const ModalBody = styled.div`
  box-sizing: border-box;

  ${(p) => !p.disablePadding && `
    padding: ${p.theme.common.padding};
  `}
`;


ModalBody.propTypes = {
  disablePadding: bool,
};

ModalBody.defaultProps = {
  disablePadding: false,
};


export default ModalBody;
