import React from 'react';

import StyledSvg from '../styles/StyledSvg';


const Stand = ({ ...props }) => (
  <StyledSvg viewBox="-81 -21 682 682.66669" {...props}>
    <path
      d="m516.425781 477.558594c-2.460937-10.144532-12.679687-16.382813-22.824219-13.921875l-228.367187 55.324219c-13.738281-27.328126-42.015625-46.09375-74.691406-46.09375-.289063 0-.570313.019531-.859375.019531l-95.644532-393.296875c-1.1875-4.871094-4.261718-9.082032-8.550781-11.691406l-60.417969-36.742188c-8.921874-5.421875-20.550781-2.59375-25.980468 6.328125-5.425782 8.921875-2.59375 20.554687 6.332031 25.984375l53.726563 32.671875 93.789062 385.671875c-27.257812 13.765625-45.964844 41.996094-45.964844 74.617188 0 46.15625 37.417969 83.570312 83.570313 83.570312 46.152343 0 83.566406-37.417969 83.566406-83.570312 0-.234376-.015625-.46875-.015625-.707032l228.40625-55.332031c10.148438-2.460937 16.382812-12.679687 13.925781-22.832031zm-325.882812 123.773437c-24.800781 0-44.902344-20.101562-44.902344-44.902343 0-24.796876 20.101563-44.902344 44.902344-44.902344 24.796875 0 44.902343 20.105468 44.902343 44.902344 0 24.800781-20.105468 44.902343-44.902343 44.902343zm0 0"
    />
    <path d="m432.246094 235.90625-75.398438 18.242188 8.96875 34.375c2.089844 8.585937-3.183594 17.234374-11.765625 19.3125l-41.515625 10.058593c-8.570312 2.074219-17.207031-3.1875-19.292968-11.757812l-8.242188-34.339844-74.582031 17.964844c-9.355469 2.25-15.09375 11.683593-12.792969 21.023437l37.019531 150.425782c1.789063 7.246093 8.277344 12.105468 15.425781 12.105468 1.238282 0 228.480469-54.898437 228.480469-54.898437 8.554688-2.070313 13.792969-10.699219 11.691407-19.246094l-37.050782-150.535156c-2.285156-9.285157-11.652344-14.972657-20.945312-12.730469zm0 0" />
    <path d="m181.519531 244.925781c2.285157 6.785157 8.667969 11.242188 15.648438 11.242188 1.289062 0 2.597656-.152344 3.90625-.46875l223.484375-54.144531c8.417968-2.042969 13.738281-10.179688 12.441406-18.582032-.074219-.464844-41.355469-170.339844-41.355469-170.339844-1.835937-7.558593-8.601562-12.632812-16.054687-12.632812-1.289063 0-2.597656.152344-3.90625.46875l-75.460938 18.28125 14.261719 58.648438c2.089844 8.582031-3.183594 17.230468-11.769531 19.3125l-41.511719 10.050781c-8.574219 2.082031-17.207031-3.183594-19.292969-11.753907l-14.265625-58.671874-75.441406 18.277343c-8.875 2.148438-14.332031 11.09375-12.171875 19.976563 0 0 41.335938 169.882812 41.488281 170.335937zm0 0" />
  </StyledSvg>
);

export default Stand;
