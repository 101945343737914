// eslint-disable-next-line no-unused-vars
import React, { Component, } from 'react';
import { object, } from 'prop-types';
import { withApollo, } from 'react-apollo';

import { pipe, } from '../../logic/utils';
import { SUBSCRIPTION_RELOAD_CENTRAL_APP, } from './gql/subscriptions';

class AppReloader extends Component {

  componentDidMount() {
    const { client, } = this.props;

    client.subscribe({
      query: SUBSCRIPTION_RELOAD_CENTRAL_APP,
      variables: {},
    }).subscribe({
      next() {
        window.location.reload();
      },
      error(err) { console.error('err', err); },
    });
  }

  render() {
    return <></>;
  }
}

AppReloader.propTypes = {
  client: object.isRequired,
};

export default pipe(
  withApollo,
)(AppReloader);
