const AUTH_ACCESS = 'accessToken';
const LANGUAGE_ID = 'languageCode';


/**
 * Tokens
 */
export const setTokens = ({ accessToken, }) => {
  localStorage.setItem(AUTH_ACCESS, accessToken);
};

export const getAccessToken = () => localStorage.getItem(AUTH_ACCESS);

export const clearTokens = () => {
  localStorage.removeItem(AUTH_ACCESS);
};


/**
 * Language
 * TODO - persist storage
 */
export const setLanguageId = (languageCode) => localStorage.setItem(LANGUAGE_ID, languageCode);

export const getLanguageId = () => localStorage.getItem(LANGUAGE_ID);
