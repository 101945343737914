import React from 'react';
import {
  bool, func, object, shape, string,
} from 'prop-types';

import Card from '../../../../atoms/Card/Card';
import HeadingWihButtons from '../../../../atoms/HeadingWihButtons/HeadingWihButtons';
import SamplesTable from './SamplesTable';


const SamplesView = ({
  // data
  resources,
  translations,
  languageId,
  confirmModal,
  detailModal,
  // methods
  onToggleConfirm,
  onToggleDetail,
  onConfirmSample,
}) => (
  <Card>
    <HeadingWihButtons>
      <h2>
        {translations.common.samples}
      </h2>
    </HeadingWihButtons>

    <SamplesTable
      // data
      resources={resources}
      translations={translations}
      languageId={languageId}
      confirmModal={confirmModal}
      detailModal={detailModal}
      // methods
      onToggleConfirm={onToggleConfirm}
      onToggleDetail={onToggleDetail}
      onConfirmSample={onConfirmSample}
    />
  </Card>
);


SamplesView.propTypes = {
  resources: object.isRequired,
  translations: object.isRequired,
  languageId: string.isRequired,
  confirmModal: object.isRequired,
  detailModal: object.isRequired,
  onToggleDetail: func.isRequired,
  onToggleConfirm: func.isRequired,
  onConfirmSample: func.isRequired,
};


export default SamplesView;
