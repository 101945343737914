import gql from 'graphql-tag';


export const TRANSLATIONS_PARKING = gql`
  query Dictionary($languageId: ID) {
    dictionary(languageId: $languageId) {
      dictionary {
        centralApp {
          common {
            home
            close
            storekeeper
            btnSave
            parking
            internalParkingHeader
            mainParkingHeader
            detail
            delete
            pictureLabelParkIn
          }
          form {
            no
            name
            truckRegistrationNumber
            transportationType
            platform
            parkingStatus
            parkingArrival
          }
          centralAppParking {
            btnAddNew
            infoParkingEmpty
            titleRemoveCarModal
            textRemoveCarModal
            removeButtonTip
            countHeader
          }
          directory {
            thActions
          }
        }
        shared {
          storekeeperClearingStates {
            called
            clearing
            interrupted
            solving
            delayed
            forwarded
          }
        }
      }
    }
  }
`;

export const QUERY_PARKING_CAR_DETAIL_TRANSLATIONS = gql`
  query Dictionary($languageId: ID) {
    dictionary(languageId: $languageId) {
      dictionary {
        centralApp {
          common {
            back
            create
            update
            created
            updated
            pictureLabelParkIn
          }
          centralAppParking {
            titleAddCarToParking
            titleUpdateCarOnParking
            parkingForm {
              registrationNumber
              type
            }
          }
        }
      }
    }
  }
`;
