import React, { Component, } from 'react';
import {
  object, func, string, number,
} from 'prop-types';


class TimeSelectOption extends Component {
  constructor(props) {
    super(props);

    this.ref = React.createRef();
  }


  componentDidMount() {
    this.scrollToMe();
  }


  componentDidUpdate(prevProps) {
    const { scrollToMinutes, scrollToHours, } = this.props;

    if (
      scrollToHours !== prevProps.scrollToHours
      || scrollToMinutes !== prevProps.scrollToMinutes
    ) {
      this.scrollToMe();
    }
  }


  scrollToMe = () => {
    const {
      minutes,
      hours,
      scrollToMinutes,
      scrollToHours,
      onScrollToOption,
    } = this.props;

    if (scrollToMinutes === minutes && scrollToHours === hours) {
      const node = this.ref.current;
      if (node) onScrollToOption(node.offsetTop);
    }
  }


  handleChange = () => {
    const {
      value,
      minutes,
      hours,
      onChange,
    } = this.props;

    const newValue = value ? new Date(value) : new Date();
    newValue.setHours(hours);
    newValue.setMinutes(minutes);
    newValue.setSeconds(0);
    newValue.setMilliseconds(0);

    onChange(newValue);
  }


  isSelected = () => {
    const {
      value,
      minutes,
      hours,
    } = this.props;

    const valueHours = value ? value.getHours() : null;
    const valueMinutes = value ? value.getMinutes() : null;
    return minutes === valueMinutes && hours === valueHours;
  }


  render() {
    const {
      label,
    } = this.props;

    const isSelected = this.isSelected();
    /* eslint-disable jsx-a11y/click-events-have-key-events */
    return (
      <li
        ref={this.ref}
        role="none"
        className={isSelected ? 'inputTimeSelect--option-selected' : ''}
        onClick={this.handleChange}
      >
        {label}
      </li>
    );
    /* eslint-enable jsx-a11y/click-events-have-key-events */
  }
}


TimeSelectOption.propTypes = {
  value: object,
  label: string.isRequired,
  hours: number.isRequired,
  minutes: number.isRequired,
  scrollToMinutes: number.isRequired,
  scrollToHours: number.isRequired,
  onChange: func.isRequired,
  onScrollToOption: func.isRequired,
};

TimeSelectOption.defaultProps = {
  value: null,
};


export default TimeSelectOption;
