import React from 'react';
import {
  func, object, shape, string,
} from 'prop-types';
import { Mutation, } from 'react-apollo';

import {
  MUTATION_STOP_CLEARING_TRANSPORTATION,
} from '../../../gql/mutations';
import Row from '../../../../../atoms/Row/Row';
import Col from '../../../../../atoms/Col/Col';
import ButtonLoader from '../../../../../atoms/Button/ButtonLoader';
import Button from '../../../../../atoms/Button/Button';
import FormBackground from '../../../../../components/Form/FormBackground';

import StyledModalInterrupt from './styles/StyledModalInterrupt';
import FormRow from '../../../../../components/Form/FormRow';
import Input from '../../../../../atoms/Input/Input';
import FormError from '../../../../../components/Form/FormError';


const ModalStopClearingView = ({
  // data
  stopForm: {
    isValid,
    form,
    values,
  },
  translations,
  // methods
  onToggle,
  onChangeForm,
  // stop clearing
  onStopClearingTransportation,
  onStopClearingTransportationCompleted,
  onStopClearingTransportationError,
}) => (
  <StyledModalInterrupt>
    <FormBackground>

      <FormRow
        label={translations.centralAppStorekeeper.interruptNote}
        input={form.note}
      >
        <Input
          value={values.note}
          onChange={(e) => onChangeForm(form.note.name, e.target.value)}
          text={translations.centralAppStorekeeper.textSolve}
        />
      </FormRow>

      <Mutation
        mutation={MUTATION_STOP_CLEARING_TRANSPORTATION}
        onCompleted={onStopClearingTransportationCompleted}
        onError={onStopClearingTransportationError}
      >
        {(mutationStopClearingTransportation, { loading, error, }) => (
          <>
            <FormError
              isValid={isValid}
              error={error}
            />
            <Row>
              <Col XS={12} SM={12}>
                <Button
                  onClick={onToggle}
                >
                  {translations.common.close}
                </Button>
              </Col>
              <Col XS={12} SM={12} textAlign="right">
                <ButtonLoader
                  className="buttonGrp--right"
                  color="error"
                  disabled={!isValid}
                  onClick={() => onStopClearingTransportation(mutationStopClearingTransportation)}
                  isLoading={loading}
                >
                  {translations.centralAppStorekeeper.btnInterrupt}
                </ButtonLoader>
              </Col>
            </Row>
          </>
        )}
      </Mutation>

    </FormBackground>
  </StyledModalInterrupt>
);


ModalStopClearingView.propTypes = {
  // data
  stopForm: shape({
    form: shape({
      note: object.isRequired,
    }).isRequired,
    values: shape({
      note: string,
    }).isRequired,
  }).isRequired,
  translations: object.isRequired,
  // methods
  onToggle: func.isRequired,
  onChangeForm: func.isRequired,
  onStopClearingTransportation: func.isRequired,
  onStopClearingTransportationCompleted: func.isRequired,
  onStopClearingTransportationError: func.isRequired,
};


export default ModalStopClearingView;
