import React from 'react';
import { shape, object, array, } from 'prop-types';

import FormRowReadOnly from '../../../../components/Form/FormRowReadOnly';
import { parseDateToHuman, } from '../../../../logic/date';
import Input from '../../../../atoms/Input/Input';
import { getActionIconForRecord, } from '../utils';

const getDateValue = (values, action) => {
  const checkpoints = values.checkpoints ? values.checkpoints : [];
  const checkpoint = checkpoints.find((c) => c.action === action);
  return checkpoint && checkpoint.date ? parseDateToHuman(new Date(checkpoint.date)) : '';
};

const style = {
  position: 'absolute',
  right: 0,
  top: '1.7rem',
};

const RecordCheckpoints = ({ values, translations, }) => {
  const isIzd = (values.inputSoe || '').toLowerCase() === 'izd';
  return <>
    <FormRowReadOnly label={translations.gatehouse.inputSoe} style={{position: 'relative',}}>
      <Input
        type="text"
        value={getDateValue(values, 'inputSoe')}
        disabled
      />
      {getActionIconForRecord(values.gateType === 'entrance' && isIzd ? [] : values.checkpoints, 'inputSoe', style, !(values.gateType === 'entrance' && isIzd))}
    </FormRowReadOnly>

    <FormRowReadOnly label={translations.gatehouse.clearance} style={{position: 'relative',}}>
      <Input
        type="text"
        value={getDateValue(values, 'clearance')}
        disabled
      />
      {getActionIconForRecord(values.gateType === 'entrance' && isIzd ? [] : values.checkpoints, 'clearance', style, !(values.gateType === 'entrance' && isIzd))}
    </FormRowReadOnly>

    {values.hasQualityControl && (
      <FormRowReadOnly label={translations.gatehouse.qualityControl} style={{position: 'relative',}}>
        <Input
          type="text"
          value={getDateValue(values, 'qualityControl')}
          disabled
        />
        {getActionIconForRecord(values.checkpoints, 'qualityControl', style, true)}
      </FormRowReadOnly>
    )}

    {values.hasOutputSoe && (
      <FormRowReadOnly label={translations.gatehouse.outputSoe} style={{position: 'relative',}}>
        <Input
          type="text"
          value={getDateValue(values, 'outputSoe')}
          disabled
        />
        {getActionIconForRecord(values.checkpoints, 'outputSoe', style, true)}
      </FormRowReadOnly>
    )}

    {values.hasOutputTechnicalCheck && (
      <FormRowReadOnly label={translations.gatehouse.outputTechnicalCheck} style={{position: 'relative',}}>
        <Input
          type="text"
          value={getDateValue(values, 'outputTechnicalCheck')}
          disabled
        />
        {getActionIconForRecord(values.checkpoints, 'outputTechnicalCheck', style, true)}
      </FormRowReadOnly>
    )}
  </>;
};


RecordCheckpoints.propTypes = {
  translations: object.isRequired,
  values: shape({
    checkpoints: array,
  }).isRequired,
};


export default RecordCheckpoints;
