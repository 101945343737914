import React, { Component, } from 'react';
import {
  object, func, oneOfType, array, string,
} from 'prop-types';

import { generateTimes, calculateScrollTo, } from './utils';
import TimeSelectOption from './TimeSelectOption';


class TimeSelect extends Component {
  constructor(props) {
    super(props);

    const { localization, value, } = this.props;

    const { hours, minutes, } = calculateScrollTo(value);


    this.ref = React.createRef();

    this.state = {
      times: generateTimes(localization),
      scrollToMinutes: minutes,
      scrollToHours: hours,
    };

    // for scroll when mounted
    this.offsetTop = null;
  }


  componentDidMount() {
    this.handleScrollToMe(this.offsetTop);
  }


  componentDidUpdate(prevProps) {
    const { value, } = this.props;
    if (value && value !== prevProps.value) {
      const { hours, minutes, } = calculateScrollTo(value);
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        scrollToMinutes: minutes,
        scrollToHours: hours,
      });
    }
  }


  handleScrollToMe = (newOffsetTop) => {
    this.offsetTop = newOffsetTop;
    if (this.ref.current) {
      // TODO: scrollTo is polyfilled, find better solution
      this.ref.current.scrollTo({
        top: newOffsetTop,
        left: 0,
      });
    }
  }


  render() {
    const { times, scrollToMinutes, scrollToHours, } = this.state;
    const { value, onChange, } = this.props;

    return (
      <ul ref={this.ref}>
        {times.map((option) => (
          <TimeSelectOption
            key={option.id}
            {...option}
            value={value}
            scrollToHours={scrollToHours}
            scrollToMinutes={scrollToMinutes}
            onScrollToOption={this.handleScrollToMe}
            onChange={onChange}
          />
        ))}
      </ul>
    );
  }
}


TimeSelect.propTypes = {
  value: object,
  localization: oneOfType([ array, string, ]),
  onChange: func.isRequired,
};

TimeSelect.defaultProps = {
  value: null,
  localization: 'cs-CZ',
};


export default TimeSelect;
