import React, { Component, } from 'react';
import { object, } from 'prop-types';
import { Query, } from 'react-apollo';

import { withTranslations, } from '../../logic/translations/withTranslations';
import { QUERY_LANGUAGES, QUERY_DICTIONARY, } from '../../modules/Main/gql/queries';
import { QUERY_DD_LANGUAGES_TRANSLATION, } from './gql/translations';
import { setLanguageId, } from '../../logic/localStorage/auth';
import Dropdown from '../../atoms/Dropdown/Dropdown';
import DDList from '../../atoms/Dropdown/DDList';
import DDItem from '../../atoms/Dropdown/DDItem';
import ButtonIcon from '../../atoms/Button/ButtonIcon';


class Languages extends Component {
  state = {
    isOpen: false,
    disabled: false,
  }


  handleToggle = (disabled = false) => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
      disabled,
    }));
  }


  handleSelectLanguage = async (id, client) => {
    try {
      this.handleToggle(true);

      await client.query({
        query: QUERY_DICTIONARY,
        variables: { languageId: id, },
      });
      // HOTFIX - languageId should be persisted in cache
      setLanguageId(id);
      client.writeData({ data: { languageId: id, }, });
    } catch (err) {
      // TODO - show error set language
    }
    this.setState({
      disabled: false,
    });
  }


  renderLanguagesMenu = () => (
    <Query
      query={QUERY_LANGUAGES}
      fetchPolicy="cache-only"
    >
      {({ data, client, }) => (
        <DDList minWidth={8}>
          {data.languages.centralAppLangs.map((item) => (
            <DDItem
              key={item.id}
              onClick={() => this.handleSelectLanguage(item.id, client)}
            >
              {`${item.code} - ${item.name}`}
            </DDItem>
          ))}
        </DDList>
      )}
    </Query>
  )


  render() {
    const { isOpen, disabled, } = this.state;
    const { language, } = this.props;

    return (
      <Dropdown
        menu={this.renderLanguagesMenu()}
        isOpen={isOpen}
        toggle={this.handleToggle}
        position="right"
      >
        <ButtonIcon
          className="header--content--button"
          isActive={isOpen}
          transparent
          onClick={() => this.handleToggle()}
          disabled={disabled}
        >
          <b>{language.code}</b>
        </ButtonIcon>
      </Dropdown>
    );
  }
}


Languages.propTypes = {
  language: object.isRequired,
};


export default withTranslations(QUERY_DD_LANGUAGES_TRANSLATION)(Languages);
