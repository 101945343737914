import React, { Component, } from 'react';
import {
  shape, func, object,
} from 'prop-types';

import IncomingSmsStyledNotification from './styles/IncomingSmsStyledNotification';
import Button from '../../atoms/Button/Button';
import Cross from '../../styles/icons/Cross';
import { TRANSLATIONS_NOTIFICATIONS, } from './translations';
import { withTranslations, } from '../translations/withTranslations';


class IncomingSmsNotification extends Component {
  constructor(props) {
    super(props);

    this.killMyself = null;
  }


  componentDidMount() {
    this.killMyself = setTimeout(this.handleOnTimeout, 30000);
  }


  componentWillUnmount() {
    if (this.killMyself !== null) {
      clearTimeout(this.killMyself);
      this.killMyself = null;
    }
  }


  handleOnTimeout = () => {
    const { data, onRemove, } = this.props;

    this.killMyself = null;
    onRemove(data.id);
  }

  handleShowChat = () => {
    const { data, onShowChat, } = this.props;

    if (this.killMyself !== null) {
      clearTimeout(this.killMyself);
      this.killMyself = null;
    }

    onShowChat(data.data.transportationId);
    this.handleOnTimeout();
  }


  render() {
    const { data, translations, } = this.props;

    return (
      <IncomingSmsStyledNotification
        className="noSelect"
      >
        <div className="incoming-sms-notification--panel">
          <div>{translations.common.incomingSmsNotificationTitle}</div>
          <button
            type="button"
            className="incoming-sms-notification--panel--exit-btn"
            onClick={this.handleOnTimeout}
          >
            <Cross />
          </button>
        </div>
        <div className="incoming-sms-notification--content">
          <div>
            {translations.common.incomingSmsNotificationMessage}
            {' '}
            {data.data.transportationNo}
          </div>
          <Button
            size="xs"
            color="success"
            onClick={this.handleShowChat}
          >
            {translations.common.incomingSmsNotificationShowChatBtn}
          </Button>
        </div>
      </IncomingSmsStyledNotification>
    );
  }
}


IncomingSmsNotification.propTypes = {
  data: shape({
  }).isRequired,
  translations: object.isRequired,
  onRemove: func.isRequired,
  onShowChat: func.isRequired,
};


export default withTranslations(TRANSLATIONS_NOTIFICATIONS)(IncomingSmsNotification);
