import React from 'react';

import StyledSvg from './styles/StyledSvg';


const CircleWithCross = ({ ...props }) => (
  <StyledSvg viewBox="0 0 612 612" {...props}>
    <path
      d="M306,0C137.012,0,0,136.992,0,306s137.012,306,306,306c168.988,0,306-136.992,306-306S474.988,0,306,0z M306,535.5
      C179.45,535.5,76.5,432.55,76.5,306S179.45,76.5,306,76.5S535.5,179.45,535.5,306S432.55,535.5,306,535.5z M410.098,202.419
      l-0.517-0.517c-14.19-14.19-37.218-14.19-51.408,0L306,254.076l-52.192-52.173c-14.191-14.191-37.198-14.191-51.408,0
      l-0.517,0.516c-14.19,14.191-14.19,37.198,0,51.408L254.076,306l-52.192,52.192c-14.19,14.189-14.19,37.197,0,51.407
      l0.517,0.517c14.191,14.19,37.217,14.19,51.408,0L306,357.925l52.173,52.191c14.19,14.19,37.198,14.19,51.408,0l0.517-0.517
      c14.19-14.19,14.19-37.218,0-51.407L357.925,306l52.191-52.192C424.308,239.617,424.308,216.61,410.098,202.419z"
    />
  </StyledSvg>
);

export default CircleWithCross;
