import React, { Component, } from 'react';
import { object, func, } from 'prop-types';

import { GraphQLErrorsGlobal, } from '../../../../globals';
import { TRANSLATIONS_USER, } from '../../gql/translations';
import { initUserForm, } from '../utils';
import {
  changeAndValidateInput, validateAndMergeWholeForm, mergeValidationObjectIntoForm,
} from '../../../../logic/form/common';
import { pipe, } from '../../../../logic/utils';
import { withNotifications, } from '../../../../logic/notifications/withNotifications';
import { withTranslations, } from '../../../../logic/translations/withTranslations';
import Modal from '../../../../atoms/Modal/Modal';
import UserCreateView from './UserCreateView';


class UserCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userForm: initUserForm,
    };
  }


  handleChangeForm = (name, value) => {
    this.setState((prevState) => ({
      userForm: changeAndValidateInput(prevState.userForm, name, value),
    }));
  }


  handleCreate = (mutationCreate) => {
    const { userForm, } = this.state;
    const newUserForm = validateAndMergeWholeForm(userForm);

    if (!newUserForm.isValid) {
      this.setState({
        userForm: newUserForm,
      });
    } else {
      const variables = {
        values: {
          ...userForm.values,
          roles: userForm.values.roles.map((role) => ({ roleId: role.id, })),
          block: false,
        },
      };
      mutationCreate({
        variables,
      });
    }
  }


  handleCreateComplete = () => {
    const {
      translations, onToggle, addNotification,
    } = this.props;

    onToggle();
    addNotification({
      status: 'success',
      title: translations.common.created,
    });
  }


  handleCreateError = (mutationError) => {
    try {
      const { userForm, } = this.state;

      const { graphQLErrors, } = mutationError;

      if (graphQLErrors && graphQLErrors.length > 0) {
        const { message, extensions, } = graphQLErrors[0];

        switch (message) {
          case GraphQLErrorsGlobal.UNPROCESSABLE_ENTITY: {
            if (extensions.exception.data) {
              const newFormData = mergeValidationObjectIntoForm(
                userForm, extensions.exception.data
              );

              this.setState({
                userForm: newFormData,
              });
            }
            break;
          }

          default: {
            break;
          }
        }
      }
    } catch (err) {
      // continue regardless of error
    }
  }


  render() {
    const { userForm, } = this.state;
    const { translations, onToggle, } = this.props;

    return (
      <Modal
        isOpen
        title={translations.settings.createUserTitle}
        size="MD"
        onClose={onToggle}
        disablePadding
      >
        <UserCreateView
          // data
          userForm={userForm}
          translations={translations}
          // methods
          onChangeForm={this.handleChangeForm}
          onClose={onToggle}
          onCreate={this.handleCreate}
          onCreateCompleted={this.handleCreateComplete}
          onCreateError={this.handleCreateError}
        />
      </Modal>
    );
  }
}


UserCreate.propTypes = {
  translations: object.isRequired,
  addNotification: func.isRequired,
  onToggle: func.isRequired,
};


export default pipe(
  withNotifications,
  withTranslations(TRANSLATIONS_USER),
)(UserCreate);
