import React, { Fragment, } from 'react';
import { object, func, } from 'prop-types';

import HeadingWihButtons from '../../../../atoms/HeadingWihButtons/HeadingWihButtons';
import Button from '../../../../atoms/Button/Button';
import RolesTable from './RolesTable';


const RolesView = ({
  resources,
  translations,
  onCreate,
  onDetail,
  onDelete,
}) => (
  <Fragment>

    <HeadingWihButtons>
      <h2>{translations.settings.rolesTitle}</h2>
      {resources.settings_users && (
        <Button
          onClick={onCreate}
          color="success"
          size="sm"
        >
          {translations.settings.createRole}
        </Button>
      )}
    </HeadingWihButtons>

    <RolesTable
      // data
      resources={resources}
      translations={translations}
      // methods
      onDetail={onDetail}
      onDelete={onDelete}
    />

  </Fragment>
);


RolesView.propTypes = {
  // data
  resources: object.isRequired,
  translations: object.isRequired,
  // methods
  onCreate: func.isRequired,
  onDetail: func.isRequired,
  onDelete: func.isRequired,
};


export default RolesView;
