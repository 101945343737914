import React from 'react';
import { func, object, } from 'prop-types';

import Button from '../../../../atoms/Button/Button';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import ButtonGrp from '../../../../atoms/Button/ButtonGrp';
import FormBackground from '../../../../components/Form/FormBackground';
import RecordBasicReadonly from '../forms/RecordBasicReadonly';
import RecordExtraReadonly from '../forms/RecordExtraReadonly';
import RecordCheckpoints from '../forms/RecordCheckpoints';


const RecordViewView = ({
  // data
  detailForm,
  translations,
  // methods
  onToggle,
}) => (
  <FormBackground>
    <Row>
      <Col HD={8} LG={12} MD={24}>
        <fieldset>
          <legend>{translations.gatehouse.titleBasicForm}</legend>

          <RecordBasicReadonly
            {...detailForm}
            translations={translations}
          />
        </fieldset>
      </Col>

      <Col HD={8} LG={12} MD={24}>
        <fieldset>
          <legend>{translations.gatehouse.titleExtraForm}</legend>
          <RecordExtraReadonly
            {...detailForm}
            translations={translations}
          />
        </fieldset>
      </Col>

      {detailForm.values.transportationId && (
        <Col HD={8} LG={12} MD={24}>
          <fieldset>
            <legend>{translations.gatehouse.checkpoints}</legend>
            <RecordCheckpoints
              {...detailForm}
              translations={translations}
            />
          </fieldset>
        </Col>
      )}

    </Row>

    <ButtonGrp block>
      <Button
        shape="outline"
        onClick={onToggle}
      >
        {translations.common.back}
      </Button>

    </ButtonGrp>

  </FormBackground>
);


RecordViewView.propTypes = {
  // data
  detailForm: object.isRequired,
  translations: object.isRequired,
  // methods
  onToggle: func.isRequired,
};


export default RecordViewView;
