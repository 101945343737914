import React from 'react';
import { func, object, } from 'prop-types';

import Modal from '../../../../../atoms/Modal/Modal';
import Row from '../../../../../atoms/Row/Row';
import Col from '../../../../../atoms/Col/Col';
import Button from '../../../../../atoms/Button/Button';


const ModalFinishConfirm = ({
  // data
  translations,
  // methods
  onToggle,
  onConfirm,
}) => (
  <Modal
    isOpen
    onClose={onToggle}
    title={translations.centralAppStorekeeper.titleModalFinishConfirm}
    size="SM"
  >
    <Row formGroup>
      <Col>
        {translations.centralAppStorekeeper.textModalFinishConfirm}
      </Col>
    </Row>
    <Row>
      <Col XS={12} SM={12}>
        <Button
          onClick={onToggle}
          shape="outline"
          color="error"
        >
          {translations.common.no}
        </Button>
      </Col>

      <Col XS={12} SM={12} textAlign="right">
        <Button
          color="success"
          onClick={() => {
            onToggle();
            onConfirm();
          }}
          shape="outline"
        >
          {translations.common.yes}
        </Button>
      </Col>
    </Row>
  </Modal>
);


ModalFinishConfirm.propTypes = {
  // data
  translations: object.isRequired,
  // methods
  onToggle: func.isRequired,
  onConfirm: func.isRequired,
};


export default ModalFinishConfirm;
