export const getColorModal = ({ color, theme, }) => {
  const colors = {
    default: `
      color: inherit;
      background: inherit;
      .modal-content--exit:hover, .modal-content--exit:focus {
        outline: none;
        color: ${theme.grey.t500};
      }
      border-bottom: 1px solid ${theme.grey.t400};
    `,
    grey: `
      color: ${theme.white};
      background: ${theme.grey.t700};
      .modal-content--exit:hover, .modal-content--exit:focus {
        outline: none;
        color: ${theme.grey.t900};
      }
    `,
    primary: `
      color: ${theme.white};
      background: ${theme.primary.t700};
      .modal-content--exit:hover, .modal-content--exit:focus {
        outline: none;
        color: ${theme.grey.t900};
      }
    `,
    secondary: `
      color: ${theme.white};
      background: ${theme.secondary.t700};
      .modal-content--exit:hover, .modal-content--exit:focus {
        outline: none;
        color: ${theme.grey.t900};
      }
    `,
    tertiary: `
      color: ${theme.white};
      background: ${theme.tertiary.t700};
      .modal-content--exit:hover, .modal-content--exit:focus {
        outline: none;
        color: ${theme.grey.t900};
      }
    `,
    error: `
      color: ${theme.white};
      background: ${theme.error.t700};
      .modal-content--exit:hover, .modal-content--exit:focus {
        outline: none;
        color: ${theme.grey.t900};
      }
    `,
    warning: `
      color: ${theme.white};
      background: ${theme.warning.t700};
      .modal-content--exit:hover, .modal-content--exit:focus {
        outline: none;
        color: ${theme.grey.t900};
      }
    `,
    success: `
      color: ${theme.white};
      background: ${theme.success.t700};
      .modal-content--exit:hover, .modal-content--exit:focus {
        outline: none;
        color: ${theme.grey.t900};
      }
    `,
  };

  if (Object.prototype.hasOwnProperty.call(colors, color)) {
    return colors[color];
  }
  return colors.default;
};
