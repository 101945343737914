// eslint-disable-next-line no-unused-vars
import React, { Component, } from 'react';
import { func, object, } from 'prop-types';
import { withApollo, } from 'react-apollo';

import {
  SUBSCRIPTION_RECIEVE_SMS_BY_TRANSPORTATION_TYPE_IDS,
} from '../../gql/subscriptions';
import { withNotifications, } from '../../../../logic/notifications/withNotifications';
import { pipe, } from '../../../../logic/utils';


class IncomingSmsNotifier extends Component {
  componentDidMount() {
    const { client, resources, addNotification, } = this.props;

    if (!Object.keys(resources).includes('storekeeper_access_by_transportation_type_id')) {
      return;
    }

    client.subscribe({
      query: SUBSCRIPTION_RECIEVE_SMS_BY_TRANSPORTATION_TYPE_IDS,
      variables: {
        transportationTypeIds: resources.storekeeper_access_by_transportation_type_id.constraint,
      },
    }).subscribe({
      next(data) {
        addNotification({
          data: data.data.recieveSmsByTransportationTypeIds,
          type: 'incomingSms',
        });
      },
      error(err) { console.error('err', err); },
    });
  }

  render() {
    return <></>;
  }
}

IncomingSmsNotifier.propTypes = {
  client: object.isRequired,
  resources: object.isRequired,
  addNotification: func.isRequired,
};

export default pipe(
  withNotifications,
  withApollo,
)(IncomingSmsNotifier);
