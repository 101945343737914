import React from 'react';
import { NavLink, } from 'react-router-dom';
import { object, } from 'prop-types';

import { QUERY_GATEHOUSE_TRANSLATIONS, } from '../gql/translations';
import PageFullScreen from '../../../atoms/PageView/PageFullScreen';
import { withTranslations, } from '../../../logic/translations/withTranslations';
import Breadcrumb from '../../../atoms/Breadcrumb/Breadcrumb';
import BreadcrumbItem from '../../../atoms/Breadcrumb/BreadcrumbItem';
import Card from '../../../atoms/Card/Card';
import HeadingWihButtons from '../../../atoms/HeadingWihButtons/HeadingWihButtons';
import Row from '../../../atoms/Row/Row';
import Col from '../../../atoms/Col/Col';
import Button from '../../../atoms/Button/Button';
import { GATEHOUSE_TYPE_EAST, GATEHOUSE_TYPE_WEST, } from '../components/utils';

const GatehouseSelectorPage = ({ translations, }) => (
  <PageFullScreen>
    <Breadcrumb>
      <BreadcrumbItem to="/">{translations.common.home}</BreadcrumbItem>
      <BreadcrumbItem>{translations.common.gatehouse}</BreadcrumbItem>
    </Breadcrumb>

    <Card>

      <HeadingWihButtons>
        <h2>{translations.common.gatehouse}</h2>
      </HeadingWihButtons>

      <Row>
        <Col>
          <span style={{ margin: '1rem 6rem 2rem 0', display: 'block', }}>{translations.gatehouse.chooseGatehouseText}</span>
        </Col>
      </Row>

      <Row>
        <Col SM={12}>
          <NavLink to={`/gatehouse/${GATEHOUSE_TYPE_EAST}`}>
            <Button
              color="warning"
              shape="outline"
              size="md"
            >
              {translations.gatehouse.eastBtn}
            </Button>
          </NavLink>
        </Col>
        <Col SM={12} textAlign="right">
          <NavLink to={`/gatehouse/${GATEHOUSE_TYPE_WEST}`}>
            <Button
              color="warning"
              shape="outline"
              size="md"
            >
              {translations.gatehouse.westBtn}
            </Button>
          </NavLink>
        </Col>
      </Row>
    </Card>
  </PageFullScreen>
);

GatehouseSelectorPage.propTypes = {
  translations: object.isRequired,
};

export default withTranslations(QUERY_GATEHOUSE_TRANSLATIONS)(GatehouseSelectorPage);
