import React from 'react';
import {
  arrayOf, number, object, shape,
} from 'prop-types';

import StatsGridCard from './StatsGridCard';
import DurationChart from '../../../../atoms/DurationChart/DurationChart';
import Timer from '../../../../styles/icons/Timer';


const AverageClearanceTimeCard = (
  { averageClearanceTimeStats, translations, translationsCommon, }
) => (
  <StatsGridCard>
    <header>
      <span>
        {translations.dashboard.stats.averageClearanceTimeTitle}
        &nbsp;(hh:mm)
      </span>
      <Timer />
    </header>
    <div className="stats--average-clearance-time-card--body">
      <DurationChart
        data={averageClearanceTimeStats.map(({ clearanceTime, day, }) => ({
          duration: clearanceTime,
          label: Object.values(translationsCommon.calendar)[day],
        }))}
        selectedLabel={translations.dashboard.stats.actual}
        staticIndex={averageClearanceTimeStats.length - 1}
      />
    </div>
  </StatsGridCard>
);

AverageClearanceTimeCard.propTypes = {
  averageClearanceTimeStats: arrayOf(shape({
    clearanceTime: number.isRequired,
    day: number.isRequired,
  })).isRequired,
  translations: object.isRequired,
  translationsCommon: object.isRequired,
};

export default AverageClearanceTimeCard;
