export const getColorTransportation = ({ state, theme, }) => {
  const states = {
    default: theme.grey.t800,
    called: theme.warning.t700,
    clearing: theme.success.t700,
    interrupted: theme.error.t700,
    solving: '#4e51b3',
  };

  if (Object.prototype.hasOwnProperty.call(states, state)) {
    return states[state];
  }
  return states.default;
};

export const getColorBackgroundTransportation = ({ state, theme, }) => {
  const states = {
    default: theme.grey.t50,
    called: theme.warning.t50,
    clearing: theme.success.t50,
    interrupted: theme.error.t50,
    solving: '#e0e1f6',
  };

  if (Object.prototype.hasOwnProperty.call(states, state)) {
    return states[state];
  }
  return states.default;
};
