// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
import React, { Component, } from 'react';
import {
  arrayOf, bool, func, object, string,
} from 'prop-types';

import CalendarIcon from '../../../styles/icons/Calendar';
import ReportPeriodCalendar from './ReportPeriodCalendar';

/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */

function PeriodTypeSelector({ onChange, selected, }) {
  return (
    <div className="period-type-selector">
      <div
        className={`period-type-selector-item ${selected === 'day' ? 'selected' : ''}`}
        onClick={() => onChange('day')}
      >
        Den
      </div>
      <div
        className={`period-type-selector-item ${selected === 'week' ? 'selected' : ''}`}
        onClick={() => onChange('week')}
      >
        Týden
      </div>
      <div
        className={`period-type-selector-item ${selected === 'month' ? 'selected' : ''}`}
        onClick={() => onChange('month')}
      >
        Měsíc
      </div>
    </div>
  );
}

PeriodTypeSelector.propTypes = {
  selected: string.isRequired,
  onChange: func.isRequired,
};

const WRAPPER_ID = 'report-period-input';

class ReportPeriodInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: false, // boolean
      periodType: 'day', // "day" | "week" | "month"
    };
  }

  componentDidMount() {
    window.addEventListener('click', this.toggleListener);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.toggleListener);
  }

  toggleListener = (ev) => {
    const { disabled, } = this.props;

    if (disabled) {
      this.setState({ opened: false, });
    } else if (ev.target) {
      const wrapperElem = document.getElementById(WRAPPER_ID);

      if (wrapperElem === ev.target) {
        this.setState((state) => ({ opened: !state.opened, }));
      } else if (!ev.target.classList.contains('react-datepicker__day') && !wrapperElem?.contains(ev.target)) {
        this.setState({ opened: false, });
      }
    }
  }

  handlePeriodTypeChange = (type) => {
    const { onChange, } = this.props;
    const { periodType, } = this.state;

    if (type !== periodType) {
      this.setState({ periodType: type, });
      onChange([]);
    }
  }

  render() {
    const { selected, disabled, onChange, } = this.props;
    const { opened, periodType, } = this.state;

    return (
      <div
        id={WRAPPER_ID}
        className={`
        control-panel-item
        report-period-input
        ${disabled ? 'disabled' : ''}
        ${opened ? 'opened' : ''}
      `}
      >
        <CalendarIcon
          className="control-panel-icon"
        />
        Volba intervalu
        <div
          className={`menu-pop-up report-period-pop-up ${opened ? 'opened' : ''}`}
        >
          <span className="menu-label">
            Interval pro porovnávání období
          </span>
          <PeriodTypeSelector
            selected={periodType}
            onChange={this.handlePeriodTypeChange}
          />
          <ReportPeriodCalendar
            periodType={periodType}
            periods={selected}
            onPeriodsChange={onChange}
          />
        </div>
      </div>
    );
  }
}

ReportPeriodInput.propTypes = {
  disabled: bool.isRequired,
  selected: arrayOf(object.isRequired).isRequired,
  onChange: func.isRequired,
};

export default ReportPeriodInput;
