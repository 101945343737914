import styled from 'styled-components';


const WizardPanel = styled.div`
  display: flex;
  margin-bottom: 1rem;
  border-bottom: 1px solid ${(p) => p.theme.grey.t200};

  div:not(last-childs) {
    margin-right: 1rem;
  }
`;


export default WizardPanel;
