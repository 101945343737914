import gql from 'graphql-tag';


export const TRANSLATIONS_CALENDAR = gql`
  query Dictionary($languageId: ID) {
    dictionary(languageId: $languageId) {
      dictionary {
        shared {
          calendar {
            dayShortSu
            dayShortMo
            dayShortTu
            dayShortWe
            dayShortTh
            dayShortFr
            dayShortSa
            sunday
            monday
            tuesday
            wednesday
            thursday
            friday
            saturday
            january
            february
            march
            april
            may
            june
            july
            august
            september
            october
            november
            december
            nextMonth
            prevMonth
          }
        }
      }
    }
  }
`;
