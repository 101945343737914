import React, { Fragment, Component, } from 'react';
import { func, number, string, } from 'prop-types';
import { Mutation, } from 'react-apollo';

import { withNotifications, } from '../../../../logic/notifications/withNotifications';
import Modal from '../../../../atoms/Modal/Modal';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import Button from '../../../../atoms/Button/Button';
import ButtonLoader from '../../../../atoms/Button/ButtonLoader';

import StyledModalDelete from '../../../../components/modal/styles/StyledModalDelete';
import Error from '../../../../styles/icons/Error';
import FormError from '../../../../components/Form/FormError';
import { MUTATION_DELETE_PLATFORM_BLOCK, MUTATION_DELETE_PLATFORM_BLOCK_BY_GROUP_ID, } from '../../gql/mutations';


class ModalDelete extends Component {
  handleCompleted = (title) => {
    const {
      onToggle,
      addNotification,
      onCompleted,
    } = this.props;

    if (onCompleted) onCompleted();
    onToggle();
    addNotification({
      status: 'success',
      title,
    });
  }


  render() {
    const {
      // data
      blockId,
      blockDefinitionId,
      groupId,
      // methods
      onToggle,
    } = this.props;
    return (
      <Modal
        isOpen
        size="SM"
        title="Smazat blokaci"
        onClose={onToggle}
      >

        <StyledModalDelete>
          <div className="errorModal--info">
            <span className="errorModal--icon">
              <Error />
            </span>
            <span className="errorModal--text">
              {blockDefinitionId
                ? 'Daná blokace byla vytvořena jako opakovaná. '
                  + 'Přejete si odstranit pouze tuto konkrétní nebo všechny opakované?'
                : 'Opravdu si přejete smazat danou blokaci?'
              }
            </span>
          </div>
        </StyledModalDelete>

        <Mutation
          mutation={MUTATION_DELETE_PLATFORM_BLOCK}
          variables={{ id: blockId, }}
          onCompleted={() => this.handleCompleted('Blokace smazána')}
        >
          {(mutateRemoveBlock, removeBlockResult) => (
            <Mutation
              mutation={MUTATION_DELETE_PLATFORM_BLOCK_BY_GROUP_ID}
              variables={{ groupId, }}
              onCompleted={(result) => {
                this.handleCompleted(`Bylo smazáno celkem ${result.deletePlatformBlockByGroupId.count} blokací`);
              }}
            >
              {(mutateRemoveBlockByGroupId, mutateRemoveBlockByGroupIdResult) => (
                <Fragment>
                  <FormError error={removeBlockResult.error || mutateRemoveBlockByGroupIdResult.error} />
                  <Row>
                    <Col XS={8} SM={8}>
                      <Button
                        type="button"
                        shape="outline"
                        onClick={onToggle}
                      >
                        Zpět
                      </Button>
                    </Col>

                    <Col XS={groupId ? 8 : 16} SM={groupId ? 8 : 16} textAlign="right">
                      <ButtonLoader
                        type="button"
                        color="error"
                        onClick={mutateRemoveBlock}
                        isLoading={removeBlockResult.loading}
                      >
                        {groupId ? 'Smazat konkrétní' : 'Smazat'}
                      </ButtonLoader>
                    </Col>

                    {groupId && (
                      <Col XS={8} SM={8} textAlign="right">
                        <ButtonLoader
                          type="button"
                          color="error"
                          onClick={mutateRemoveBlockByGroupId}
                          isLoading={mutateRemoveBlockByGroupId.loading}
                        >
                          Smazat všechny
                        </ButtonLoader>
                      </Col>
                    )}
                  </Row>
                </Fragment>
              )}
            </Mutation>
          )}
        </Mutation>

      </Modal>
    );
  }
}


ModalDelete.propTypes = {
  // data
  blockId: number.isRequired,
  blockDefinitionId: number,
  groupId: string,
  // methods
  onToggle: func.isRequired,
  onCompleted: func,
  addNotification: func.isRequired,
};

ModalDelete.defaultProps = {
  blockDefinitionId: undefined,
  onCompleted: undefined,
};


export default withNotifications(ModalDelete);
