import React from 'react';
import { node, } from 'prop-types';


const StatsGridCard = ({ children, }) => (
  <li className="stats--grid-card noSelect">
    <div className="stats--grid-card-content">
      {children}
    </div>
  </li>
);


StatsGridCard.propTypes = {
  children: node.isRequired,
};


export default StatsGridCard;
