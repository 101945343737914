import { type, } from 'ramda';


const init = {
  code: '',
  params: {},
};


export const parseMessage = (data = init) => {
  const ret = {
    code: type(data.code) === 'String' ? data.code : '',
    params: type(data.params) === 'Object' ? data.params : {},
  };

  return ret;
};
