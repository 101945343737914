import styled from 'styled-components';


const StyledMonitoringItem = styled.li`
  display: flex;
  flex-direction: column;
  border: 1px solid ${(p) => p.theme.grey.t200};
  box-shadow: 0 2px 2px rgba(0, 0, 0, .24), 0 0 2px rgba(0, 0, 0, .12);
  min-height: 4rem;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background: ${(p) => p.theme.grey.t50};
  cursor: pointer;

  &:hover {

  }

  .monitoring--item-cols {
    display: flex;
  }

  .monitoring--item-checkpoints {
    border-top: solid 1px ${(p) => p.theme.grey.t700};
    color: ${(p) => p.theme.grey.t700};
    margin-top: 7px;
    padding-top: 7px;
    position: relative;
    display: flex;
    flex-direction: row;
  }

  .monitoring--item-checkpoints > div {
    width: 2.2em;
    display: flex;
    justify-content: center;
  }
    
  .monitoring--item-product-info {
    border-top: solid 1px ${(p) => p.theme.grey.t700};
    color: ${(p) => p.theme.grey.t700};
    margin-top: 7px;
    padding-top: 7px;
    position: relative;
  }

  .monitoring--item-product-info-item {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 90%;
  }
    
  .monitoring--item-product-info-count {
    position: absolute;
    top: 5px;
    right: 0;
    background-color: white;
    width: 26px;
    height: 26px;
    border: solid 1px ${(p) => p.theme.grey.t700};
    border-radius: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .monitoring--item-col1 {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-height: 100%;
    justify-content: flex-start;
  }

  .monitoring--item-col2 {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    min-height: 100%;
    justify-content: flex-start;
    color: ${(p) => p.theme.grey.t700};
    text-align: right;
  }

  .monitoring--item-col-text {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }


  .monitoring--item-col-no {
    font-weight: bold;
  }

  .monitoring--item-col-truckNo {

  }

  .monitoring--item-col-type {

  }


  .monitoring--item-col-state {

  }

  .monitoring--item-col-time {

  }

  .monitoring--item-col-time-date {
    font-weight: 500;
  }

  .monitoring--item-col-time-time {
    margin-left: 0.5rem;
  }
`;


export default StyledMonitoringItem;
