import gql from 'graphql-tag';

export const QUERY_LICENSE_DETAIL = gql`
  query GetLicenseDetail {
    getLicenseDetail {
      validTo
      validityStatus
    }
  }
`;

export const QUERY_LICENSE_TRANSLATION = gql`
  query Dictionary($languageId: ID) {
    dictionary(languageId: $languageId) {
      dictionary {
        centralApp {
          licensing {
            licenseTo
            remainingDays
            licenseExpiresSoon
            contactMsg
            contactEmail
            contactPhone
          }
        }
      }
    }
  }
`;
