import gql from 'graphql-tag';


export const TRANSLATIONS_MONITORING = gql`
  query Dictionary($languageId: ID) {
    dictionary(languageId: $languageId) {
      dictionary {
        centralApp {
          common {
            home
            monitoring
            monitoringModule
          }
          monitoring {
            plan
            register
            waiting
            clearing
            cleared
            finished
            statusDelayed
            statusOk
          }
        }
        shared {
          storekeeperClearingStates {
            called
            clearing
            interrupted
            solving
            delayed
            forwarded
          }
        }
      }
    }
  }
`;

export const TRANSLATIONS_NOTIFICATIONS = gql`
  query Dictionary($languageId: ID) {
    dictionary(languageId: $languageId) {
      dictionary {
        centralApp {
          common {
            delete
            notifications
            detail
            home
            monitoringModule
          }
          monitoring {
            notificationTypes {
              delayedTransportationArrival
              delayedTransportationClearance
              transportationStuck
              help
              printerEmptyPaperError
              printerOpenCaseError
              printerAlmostEmptyPaperError
              printerCutterError
              printerError
            }
            stand
            transportation
            printer
            plan
            register
            waiting
            clearing
            cleared
            finished
            modalRemoveNotificationTitle
            modalRemoveNotificationText
            titleNotifications
            other
            thType
            thTransportationType
            thTime
            thActions
            notificationsEmpty
            thInfo
          }
        }
      }
    }
  }
`;
