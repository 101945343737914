import React, { Component, } from 'react';
import {
  object, func, oneOfType, array, string,
} from 'prop-types';

import InputTime from '../InputTime/InputTime';
import TimeSelect from './TimeSelect';

import StyledInputTimeSelect from './styles/StyledInputTimeSelect';


const initState = {
  isOpen: false,
  inputDate: {
    isFocused: false,
    value: null,
  },
};


class InputTimeSelect extends Component {
  constructor(props) {
    super(props);

    this.refDD = React.createRef();

    this.state = {
      ...initState,
    };
  }


  componentWillUnmount() {
    this.removeEvents();
  }


  /**
   * Add Click Event
   */
  addEvents = () => {
    [ 'click', 'touchend', ].forEach((event) => (
      document.addEventListener(event, this.handleDocumentClick)
    ));
  }


  /**
   * Remove Click Event
   */
  removeEvents = () => {
    [ 'click', 'touchend', ].forEach((event) => (
      document.removeEventListener(event, this.handleDocumentClick)
    ));
  }


  /**
   * onClick
   */
  handleDocumentClick = (e) => {
    if (this.refDD.current && !this.refDD.current.contains(e.target)) {
      this.handleClose();
    }
  }


  /**
   * Close dropdown
   */
  handleClose = () => {
    const { isOpen, } = this.state;

    if (isOpen) {
      this.removeEvents();
      this.setState({
        isOpen: false,
      });
    }
  }


  /**
   * Input - onFocus
   *  - open dropdown
   */
  handleInputFocus = () => {
    const { value, } = this.props;

    this.addEvents();
    this.setState({
      isOpen: true,
      inputDate: {
        isFocused: true,
        value,
      },
    });
  }


  /**
   * Input - onChangeInputDate
   */
  handleOnChangeInputDate = (newDate) => {
    this.setState((prevState) => ({
      inputDate: {
        ...prevState.inputDate,
        value: newDate,
      },
    }));
  }


  /**
   * Time Select - onChange
   */
  handleOnChangeTimeSelect = (newDate) => {
    const { onChange, } = this.props;
    if (onChange) onChange(newDate);
    this.handleClose();
  }


  /**
   * onKeyDown
   */
  handleKeyDown = (e) => {
    // enter
    switch (e.keyCode) {
      case 13:
      case 9: {
        this.handleClose();
        break;
      }
      default: {
        break;
      }
    }
  }


  render() {
    const { isOpen, inputDate, } = this.state;
    const {
      value,
      verticalPosition,
      horizontalPosition,
      localization,
      onChange,
      ...rest
    } = this.props;

    return (
      <StyledInputTimeSelect
        ref={this.refDD}
        verticalPosition={verticalPosition}
        horizontalPosition={horizontalPosition}
        onKeyDown={this.handleKeyDown}
      >

        <div className="inputTimeSelect--inputWrapper">
          <InputTime
            {...rest}
            value={value}
            localization={localization}
            onChange={onChange}
            onChangeInputDate={this.handleOnChangeInputDate}
            onFocus={this.handleInputFocus}
          />
        </div>

        {isOpen && (
          <div className="inputTimeSelect--dropdown">
            <TimeSelect
              value={inputDate.isFocused ? inputDate.value : value}
              localization={localization}
              onChange={this.handleOnChangeTimeSelect}
            />
          </div>
        )}

      </StyledInputTimeSelect>
    );
  }
}


InputTimeSelect.propTypes = {
  value: object,
  localization: oneOfType([ array, string, ]),
  verticalPosition: string,
  horizontalPosition: string,
  onChange: func,
};

InputTimeSelect.defaultProps = {
  value: null,
  localization: 'cs-CZ',
  verticalPosition: 'bottom',
  horizontalPosition: 'right',
  onChange: () => {},
};


export default InputTimeSelect;
