import React, { Component, } from 'react';
import {
  object, string, func, oneOfType, array,
} from 'prop-types';

import { parseTimeToHuman, } from '../../logic/date';
import { parseInputTime, } from './utils';
import Input from '../Input/Input';


const initState = {
  isFocused: false,
  input: {
    isValid: true,
    text: '',
    date: null,
  },
};


class InputTime extends Component {
  /**
   * State
   */
  state = {
    ...initState,
  };


  /**
   * onChange
   */
  handleChange = (e) => {
    const { value, onChangeInputDate, } = this.props;

    const parsedInput = parseInputTime(e.target.value, value);
    this.setState({
      input: parsedInput,
    });

    if (onChangeInputDate) onChangeInputDate(parsedInput.date);
  }


  /**
   * onFocus
   */
  handleFocus = (e) => {
    const { value, onFocus, } = this.props;

    // select input text
    e.target.select();

    this.setState({
      isFocused: true,
      input: {
        ...initState.input,
        text: this.parseValue(),
        date: value,
      },
    });

    if (onFocus) onFocus(e);
  }


  /**
   * onKeyDown
   */
  handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.changeValue();
    }
  }


  /**
   * onBlur
   */
  handleBlurInput = (e) => {
    const { onBlur, } = this.props;
    this.changeValue();

    this.setState({ ...initState, });

    if (onBlur) onBlur(e);
  }


  /**
   * Change Value
   */
  changeValue = () => {
    const { input: { isValid, date, }, } = this.state;
    const { clearable, onChange, } = this.props;

    if (!clearable && !date) return;

    if (isValid) {
      onChange(date);
    }
  }


  /**
   * Parse Value to Input
   */
  parseValue = () => {
    const { value, localization, } = this.props;

    if (!value) return '';
    return parseTimeToHuman(value, localization);
  }


  render() {
    const {
      isFocused,
      input,
    } = this.state;
    const {
      value,
      localization,
      onChange,
      onChangeInputDate,
      onFocus,
      onBlur,
      ...rest
    } = this.props;

    return (
      <Input
        {...rest}
        // data
        value={isFocused ? input.text : this.parseValue()}
        // events
        onChange={this.handleChange}
        onFocus={this.handleFocus}
        onBlur={this.handleBlurInput}
        onKeyDown={this.handleKeyDown}
      />
    );
  }
}


InputTime.propTypes = {
  value: object,
  localization: oneOfType([ array, string, ]),
  onChange: func,
  onFocus: func,
  onBlur: func,
  onChangeInputDate: func,
};

InputTime.defaultProps = {
  value: null,
  localization: 'cs-CZ',
  onChange: undefined,
  onFocus: undefined,
  onBlur: undefined,
  onChangeInputDate: undefined,
};


export default InputTime;
