import React, { Component, } from 'react';
import {
  shape, string, func, object, number, bool,
} from 'prop-types';

import { withApollo, } from 'react-apollo';
import Button from '../../../../atoms/Button/Button';
import { withNotifications, } from '../../../../logic/notifications/withNotifications';
import { pipe, } from '../../../../logic/utils';
import { MUTATION_SEND_RS_PIN, } from '../../gql/mutations';
import ModalSmsChat from '../../../Storekeeper/components/Platforms/ModalSmsChat/ModalSmsChat';

const { REACT_APP_REST, } = process.env;

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      smsChatModal: {
        isOpen: false,
      },
    };
  }

  sendRsPin = async () => {
    const {
      transportationId, translations, addNotification, client,
    } = this.props;

    try {
      const result = await client.mutate({
        mutation: MUTATION_SEND_RS_PIN,
        variables: { transportationId, },
      });
      if (result.data.sendSmsWithRsPin) {
        addNotification({
          status: 'success',
          title: translations.transportation.sendRsPinSuccess,
        });
      } else {
        addNotification({
          status: 'error',
          title: translations.transportation.sendRsPinError,
        });
      }
    } catch (e) {
      addNotification({
        status: 'error',
        title: translations.transportation.sendRsPinError,
      });
    }
  };

  handleToggleSmsChatModal = () => {
    this.setState((prevState) => ({
      smsChatModal: {
        ...prevState.smsChatModal,
        isOpen: !prevState.smsChatModal.isOpen,
      },
    }));
  }

  render() {
    const {
      transportationId, translations, wide,
    } = this.props;
    const { smsChatModal, } = this.state;

    return (
      <>
        {smsChatModal.isOpen && (
          <ModalSmsChat
            // data
            transportationId={transportationId}
            languageId="1"
            translations={translations}
            // methods
            onToggle={this.handleToggleSmsChatModal}
          />
        )}

        <Button
          size="sm"
          color="success"
          onClick={() => { this.sendRsPin(); }}
          style={{ marginLeft: wide ? '490px' : '390px', }}
        >
          {translations.transportation.sendRsPin}
        </Button>
        <Button
          size="sm"
          color="success"
          onClick={() => {
            window.open(`${REACT_APP_REST}/spolchemie/ticket/transportation/${transportationId}`, '_blank');
          }}
          style={{ marginLeft: '10px', }}
        >
          {translations.transportation.printTicketBtn}
        </Button>
        <Button
          size="sm"
          color="success"
          onClick={this.handleToggleSmsChatModal}
          style={{ marginLeft: '10px', }}
        >
          {translations.transportation.showChatBtn}
        </Button>
      </>);
  }
}

Header.propTypes = {
  translations: object.isRequired,
  transportationId: string.isRequired,
  wide: bool.isRequired,
};

export default pipe(
  withNotifications,
  withApollo
)(Header);
