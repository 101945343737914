import { playAudio, } from './player';

import correctAnswerTone from './files/correct-answer-tone.mp3';

export const AUDIO_NOTIFICATION = {
  SCANNER_MOVE_TO_NEXT_STAND: 'SCANNER_MOVE_TO_NEXT_STAND',
};

export const audioNotification = (type, volume = 1) => {
  switch (type) {
    case AUDIO_NOTIFICATION.SCANNER_MOVE_TO_NEXT_STAND:
      playAudio(correctAnswerTone, volume);
      break;

    default:
      console.log(`Unknown audio type: ${type}`);
  }
};
