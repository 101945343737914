import React, { Component, } from 'react';
import {
  func, shape, string, object, arrayOf,
} from 'prop-types';
import { INPUT_TYPE, } from 'sofair-form-validation';

import {
  changeAndValidateInput, validateAndMergeWholeForm,
  mergeValidationObjectIntoForm, initForm, initInput,
} from '../../../../logic/form/common';
import SmsUpdateView from './SmsUpdateView';


const initSmsTemplateForm = {
  detailForm: {
    ...initForm,
    form: {
      text: initInput({
        name: 'text',
        type: INPUT_TYPE.TEXT,
        validation: {
          required: true,
          min: 3,
          max: null,
        },
      }),
    },
    values: {
      text: '',
    },
  },
};

class SmsUpdateLogic extends Component {
  constructor(props) {
    super(props);

    const { id, text, } = props.data.fetchOneSmsTemplate;

    this.state = {
      ...initSmsTemplateForm,
      id,
      detailForm: {
        ...initSmsTemplateForm.detailForm,
        values: {
          ...initSmsTemplateForm.values,
          text,
        },
      },
    };
  }

  /**
   * Form - onChange
   */
  handleValueChange = (name, value, callback) => {
    const { detailForm, } = this.state;
    const newState = changeAndValidateInput(detailForm, name, value);

    this.setState({ detailForm: newState, }, callback);
  }

  /**
   * onUpdate
   */
  handleUpdate = (mutate) => {
    const { id, detailForm, } = this.state;
    const newDetailForm = validateAndMergeWholeForm(detailForm);

    if (!newDetailForm.isValid) {
      this.setState({ detailForm: newDetailForm, });
    } else {
      mutate({ variables: { id, text: detailForm.values.text, }, });
    }
  }

  /**
   * onUpdate - Completed
   */
  handleUpdateComplete = (responseData) => {
    const {
      translations, onToggle, onUpdated, addNotification,
    } = this.props;

    onToggle();
    addNotification({
      status: 'success',
      title: translations.common.updated,
    });

    if (onUpdated) onUpdated(responseData);
  }

  /**
   * onUpdate - Error
   */
  handleUpdateError = (mutationError) => {
    try {
      const { detailForm, } = this.state;

      const { graphQLErrors, } = mutationError;

      if (graphQLErrors && graphQLErrors.length > 0) {
        const { message, extensions, } = graphQLErrors[0];

        switch (message) {
          case 'UNPROCESSABLE_ENTITY': {
            if (extensions.exception.data) {
              const newState = mergeValidationObjectIntoForm(detailForm, extensions.exception.data);

              this.setState({
                detailForm: newState,
              });
            }
            break;
          }

          default: {
            break;
          }
        }
      }
    } catch {
      // continue regardless of error
    }
  }

  render() {
    const { detailForm, } = this.state;
    const {
      data, resources, onToggle, translations,
    } = this.props;

    return (
      <SmsUpdateView
        // data
        resources={resources}
        staticSmsData={data.fetchOneSmsTemplate}
        detailForm={detailForm}
        translations={translations}
        // methods
        onChange={this.handleValueChange}
        onToggle={onToggle}
        onUpdate={this.handleUpdate}
        onUpdateCompleted={this.handleUpdateComplete}
        onUpdateError={this.handleUpdateError}
      />
    );
  }
}


SmsUpdateLogic.propTypes = {
  resources: object.isRequired,
  data: shape({
    fetchOneSmsTemplate: shape({
      id: string.isRequired,
      typeTranslation: string.isRequired,
      languageCode: string.isRequired,
      text: string.isRequired,
      placeholders: arrayOf(shape({
        id: string.isRequired,
        placeholder: string.isRequired,
        placeholderTranslation: string.isRequired,
      })).isRequired,
    }).isRequired,
  }).isRequired,
  translations: object.isRequired,
  onToggle: func.isRequired,
  onUpdated: func,
  addNotification: func.isRequired,
};

SmsUpdateLogic.defaultProps = {
  onUpdated: undefined,
};


export default SmsUpdateLogic;
