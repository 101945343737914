import React, { Component, } from 'react';
import {
  string, func, object, arrayOf,
} from 'prop-types';
import { withApollo, } from 'react-apollo';

import { pipe, } from '../../../../logic/utils';
import { withNotifications, } from '../../../../logic/notifications/withNotifications';
import {
  validateAndMergeWholeForm,
  mergeValidationObjectIntoForm,
  changeAndValidateInput,
} from '../../../../logic/form/common';
import { parseTransportationMutationVariables, } from '../utils';
import {
  initProductForm, initTransportationForm,
} from '../forms/structure';
import TransportationDetailView from './TransportationDetailView';
import { changeArrayElement, } from '../TransportationCreate/utils';


class TransportationDetailLogic extends Component {
  constructor(props) {
    super(props);

    const {
      detail: {
        reservation,
        company,
        driver,
        passenger,
        truck,
        trailer,
        id,
        extra,
        products,
      },
    } = props;

    this.state = {
      detailForm: {
        ...initTransportationForm,
        values: {
          ...initTransportationForm.values,
          ...reservation,
          resDate: reservation.resDate ? new Date(reservation.resDate) : null,
          resTimeFrom: reservation.resTimeFrom ? new Date(reservation.resTimeFrom) : null,
          resTimeTo: reservation.resTimeTo ? new Date(reservation.resTimeTo) : null,
          note: reservation.note ? reservation.note : '',
          ...company,
          ...driver,
          ...passenger,
          ...truck,
          ...trailer,
          ...extra,
          id,
          languageId: driver.languageId ? {
            id: driver.languageId,
            name: driver.languageName,
          } : null,
          tankerCheckExpiration: truck.tankerCheckExpiration ? new Date(truck.tankerCheckExpiration) : null,
          carConstruction: truck.carConstruction ? { id: truck.carConstruction, name: truck.carConstruction, } : null,
          compId: company.compId ? { id: company.compId, name: company.compName, } : null,
          multipleOrderExpiration: extra.multipleOrderExpiration ? new Date(extra.multipleOrderExpiration) : null,
        },
      },
      productForms: (Array.isArray(products) ? products : []).map((product) => ({
        ...initProductForm,
        values: {
          ...initProductForm.values,
          ...product,
          weightIn: product.weightIn || '',
          weightInUnit: product.weightInUnit || '',
          weightOut: product.weightOut || '',
          weightOutUnit: product.weightOutUnit || '',
        },
      })),
    };
  }

  handleChangeLoadingForm = (index, name, value) => {
    const { productForms, } = this.state;

    if (productForms.length < 0 || productForms.length <= index) return;

    const form = productForms[index];

    switch (name) {
      // Default
      default: {
        const newProductForm = changeAndValidateInput(form, name, value);
        this.setState({
          productForms: changeArrayElement(productForms, index, newProductForm),
        });
        break;
      }
    }
  }

  /**
   * onEdit
   */
  handleEdit = (editMutation) => {
    const { productForms, detailForm: { values, }, } = this.state;
    const newProductForms = productForms.map((item) => validateAndMergeWholeForm(item));
    const isProductFormsValid = !newProductForms.some((item) => item.isValid === false);

    if (!isProductFormsValid) {
      this.setState({
        productForms: newProductForms,
      });
    } else {
      const parsedValues = parseTransportationMutationVariables({ ...values, products: productForms.map((pf) => pf.values), });
      editMutation({
        variables: {
          ...parsedValues,
          id: values.id,
          // TODO I don't like this - probably make two queries - monitoring and edit
          isMonitoring: false,
        },
      });
    }
  }


  /**
   * onEdit - Completed
   */
  handleEditComplete = (responseData) => {
    const {
      translations,
      onToggle,
      addNotification,
      onEdited,
    } = this.props;

    onToggle();
    addNotification({
      status: 'success',
      title: translations.common.updated,
    });

    if (onEdited) onEdited(responseData);
  }


  /**
   * onEdit - Error
   */
  handleEditError = (mutationError) => {
    try {
      const { detailForm, } = this.state;
      const { graphQLErrors, } = mutationError;

      if (graphQLErrors && graphQLErrors.length > 0) {
        const { message, extensions, } = graphQLErrors[0];

        switch (message) {
          case 'UNPROCESSABLE_ENTITY': {
            if (extensions.exception.data) {
              this.setState({
                detailForm: mergeValidationObjectIntoForm(detailForm, extensions.exception.data),
              });
            }
            break;
          }

          default: {
            break;
          }
        }
      }
    } catch {
      // continue regardless of error
    }
  }

  render() {
    const {
      productForms,
    } = this.state;
    const {
      transportationId, languageId, detail, tabs, translations, resources, onToggle,
    } = this.props;


    return (
      <>
        <TransportationDetailView
          transportationId={transportationId}
          detail={detail}
          tabs={tabs}
          productForms={productForms}
          languageId={languageId}
          translations={translations}
          resources={resources}
          // methods
          onToggle={onToggle}
          onLoadingFormChange={this.handleChangeLoadingForm}
          onEdit={this.handleEdit}
          onEditComplete={this.handleEditComplete}
          onEditError={this.handleEditError}
        />

      </>
    );
  }
}


TransportationDetailLogic.propTypes = {
  // data
  transportationId: string.isRequired,
  languageId: string.isRequired,
  detail: object.isRequired,
  tabs: arrayOf(object).isRequired,
  translations: object.isRequired,
  resources: object.isRequired,
  // methods
  onToggle: func.isRequired,
  addNotification: func.isRequired,
  onEdited: func,
};

TransportationDetailLogic.defaultProps = {
  onEdited: undefined,
};


export default pipe(
  withNotifications,
  withApollo,
)(TransportationDetailLogic);
