import React, { Fragment, } from 'react';
import {
  arrayOf, string, object, func,
} from 'prop-types';
import { Mutation, } from 'react-apollo';

import Button from '../../../../atoms/Button/Button';
import InfoView from './InfoView';
import FlowChart from './FlowChart';

import StyledTransportationDetail from './styles/StyledTransportationDetail';
import { MUTATION_EDIT_TRANSPORTATION, } from '../../gql/mutations';
import ButtonGrp from '../../../../atoms/Button/ButtonGrp';
import ButtonLoader from '../../../../atoms/Button/ButtonLoader';
import FormBackground from '../../../../components/Form/FormBackground';

const mergePictures = (detail, type) => {
  const {
    gateInPict,
    gateOutPict,
    cargoSpacePictIn,
    cargoSpacePictOut,
    otherPictsIn,
    otherPictsOut,
  } = detail;

  const pictures = [];

  if (type === 'in') {
    if (gateInPict) {
      pictures.push(gateInPict);
    }
    if (cargoSpacePictIn) {
      pictures.push(cargoSpacePictIn);
    }
    if (otherPictsIn) {
      pictures.push(...otherPictsIn);
    }
  } else {
    if (gateOutPict) {
      pictures.push(gateOutPict);
    }
    if (cargoSpacePictOut) {
      pictures.push(cargoSpacePictOut);
    }
    if (otherPictsOut) {
      pictures.push(...otherPictsOut);
    }
  }

  return pictures;
};

const TransportationDetailView = ({
  // data
  detail,
  tabs,
  productForms,
  transportationId,
  languageId,
  translations,
  resources,
  // methods
  onToggle,
  onLoadingFormChange,
  onEdit,
  onEditComplete,
  onEditError,
}) => (
  <StyledTransportationDetail>
    <InfoView
      {...detail}
      translations={translations}
      productForms={productForms}
      resources={resources}
      onLoadingFormChange={onLoadingFormChange}
    />

    <FormBackground className="transportationDetail--form">
      <Mutation
        mutation={MUTATION_EDIT_TRANSPORTATION}
        onCompleted={onEditComplete}
        onError={onEditError}
      >
        {(editMutation, { loading, error, }) => (
          <Fragment>
            <ButtonGrp block>
              <Button
                shape="outline"
                onClick={onToggle}
              >
                {translations.common.back}
              </Button>
              <ButtonLoader
                color="success"
                onClick={() => onEdit(editMutation)}
                isLoading={loading}
                className="buttonGrp--right"
              >
                {translations.common.update}
              </ButtonLoader>
            </ButtonGrp>
          </Fragment>
        )}
      </Mutation>
    </FormBackground>

    <FlowChart
      tabs={tabs}
      gateIn={detail.gateHistoryIn}
      gateOut={detail.gateHistoryOut}
      gateHistoryInTime={detail.gateHistoryInTime}
      gateHistoryOutTime={detail.gateHistoryOutTime}
      picturesIn={mergePictures(detail, 'in')}
      picturesOut={mergePictures(detail, 'out')}
      transportationId={transportationId}
      translations={translations}
      languageId={languageId}
      transportation={detail}
    />


  </StyledTransportationDetail>
);


TransportationDetailView.propTypes = {
  // data
  transportationId: string.isRequired,
  languageId: string.isRequired,
  detail: object.isRequired,
  tabs: arrayOf(object).isRequired,
  productForms: arrayOf(object).isRequired,
  translations: object.isRequired,
  resources: object.isRequired,
  // methods
  onToggle: func.isRequired,
  onLoadingFormChange: func,
  onEdit: func.isRequired,
  onEditError: func.isRequired,
  onEditComplete: func.isRequired,
};

TransportationDetailView.defaultProps = {
  onLoadingFormChange: () => {},
};

export default TransportationDetailView;
