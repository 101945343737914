import React from 'react';
import { number, string, } from 'prop-types';

import CircleWithCross from '../../styles/icons/CircleWithCross';


const TableNoData = ({ colsCount, text, }) => (
  <tbody>
    <tr>
      <td colSpan={colsCount}>
        <div className="table--noData">
          <span className="table--noData-icon">
            <CircleWithCross />
          </span>
          <span className="table--noData-text">
            {text}
          </span>
        </div>
      </td>
    </tr>
  </tbody>
);


TableNoData.propTypes = {
  colsCount: number.isRequired,
  text: string.isRequired,
};


export default TableNoData;
