import React from 'react';

import StyledSvg from './styles/StyledSvg';


const Arrow = ({ ...props }) => (
  <StyledSvg viewBox="0 0 306 306" {...props}>
    <path d="M 270.3,58.65 153,175.95 35.7,58.65 0,94.35 l 153,153 153,-153 z" />
  </StyledSvg>
);

export default Arrow;
