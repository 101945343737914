import React from 'react';
import styled from 'styled-components';
import { string, bool, } from 'prop-types';


export const getColor = ({ color, theme, }) => {
  const colors = {
    grey: theme.grey.t300,
    primary: theme.primary.t700,
    secondary: theme.secondary.t700,
    tertiary: theme.tertiary.t700,
    error: theme.error.t700,
    warning: theme.warning.t700,
    success: theme.success.t700,
  };

  if (Object.prototype.hasOwnProperty.call(colors, color)) {
    return colors[color];
  }
  return colors.grey;
};


export const getSizeCircle = ({ size, }) => {
  const sizes = {
    sm: `
      width: 0.75rem;
      height: 0.75rem;
      border-width: 3px;
    `,
    md: `
      width: 1rem;
      height: 1rem;
      border-width: 4px;
      `,
    lg: `
      width: 1.25rem;
      height: 1.25rem;
      border-width: 5px;
    `,
  };

  if (Object.prototype.hasOwnProperty.call(sizes, size)) {
    return sizes[size];
  }
  return sizes.md;
};


export const getSizeCircleWrapper = ({ size, }) => {
  const sizes = {
    sm: `
      left: -0.5rem;
      width: 1rem;
      height: 1rem;
    `,
    md: `
      left: -0.75rem;
      width: 1.5rem;
      height: 1.5rem;
      `,
    lg: `
      left: -0.875rem;
      width: 1.75rem;
      height: 1.75rem;
    `,
  };

  if (Object.prototype.hasOwnProperty.call(sizes, size)) {
    return sizes[size];
  }
  return sizes.md;
};


const StyledFlowLineMid = styled.div`
  border: 2px solid ${(p) => p.theme.grey.t300};
  position: relative;
  min-height: 4rem;

  ${(p) => p.hideLine && `
    border-color: transparent;
  `}

  .flowLine--mid-circle-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -2px;
    ${getSizeCircleWrapper}
    background: ${(p) => p.theme.white};
  }

  .flowLine--mid-circle {
    ${getSizeCircle}
    border-style: solid;
    border-radius: ${(p) => (p.round ? '50%' : p.theme.input.borderRadius)}; 
    ${(p) => {
    const color = getColor(p);

    return `
        border-color: ${color};
        ${p.full && `background: ${color};`}
      `;
  }}
  }
`;


const FlowLineMid = (props) => (
  <StyledFlowLineMid {...props}>
    <div className="flowLine--mid-circle-wrapper">
      <div className="flowLine--mid-circle" />
    </div>
  </StyledFlowLineMid>
);


FlowLineMid.propTypes = {
  full: bool,
  round: bool,
  hideLine: bool,
  color: string,
  size: string,
};

FlowLineMid.defaultProps = {
  full: false,
  round: false,
  color: undefined,
  size: 'md',
  hideLine: false,
};


export default FlowLineMid;
