import React, { Component, } from 'react';
import { object, string, } from 'prop-types';

import { QUERY_GATEHOUSE_TRANSLATIONS, } from '../gql/translations';
import TabActual from '../components/TabActual/TabActual';
import TabInArea from '../components/TabInArea/TabInArea';
import TabHistory from '../components/TabHistory/TabHistory';
import TabNotifications from '../components/TabNotifications/TabNotifications';
import PageFullScreen from '../../../atoms/PageView/PageFullScreen';
import GatehouseView from '../components/GatehouseView';
import RecordCreate from '../components/RecordEdit/RecordCreate';
import { withTranslations, } from '../../../logic/translations/withTranslations';

export const GATEHOUSE_TABS = {
  ACTUAL: 'actual',
  IN_AREA: 'in_area',
  HISTORY: 'history',
  NOTIFICATIONS: 'notifications',
};

class GatehousePage extends Component {
  state = {
    activeTab: GATEHOUSE_TABS.ACTUAL,
    createRecordModal: {
      isOpen: false,
    },
  };

  handleChangePage = (page) => {
    this.setState({ activeTab: page, });
  }

  handleToggleRecordCreate = () => {
    this.setState((prevState) => ({
      createRecordModal: {
        isOpen: !prevState.createRecordModal.isOpen,
      },
    }));
  }

  render() {
    const { activeTab, createRecordModal, } = this.state;
    const { translations, languageId, match, } = this.props;

    return (
      <PageFullScreen>
        {createRecordModal.isOpen && (
          <RecordCreate
            // data
            languageId={languageId}
            translations={translations}
            modalData={createRecordModal}
            gatehouseType={match.params.type}
            // methods
            onToggle={this.handleToggleRecordCreate}
          />
        )}

        <GatehouseView
          activeTab={activeTab}
          translations={translations}
          gatehouseType={match.params.type}
          // methods
          onChangePage={this.handleChangePage}
          onCreateRecord={this.handleToggleRecordCreate}
        >
          {activeTab === GATEHOUSE_TABS.ACTUAL && (
            <TabActual
              // data
              languageId={languageId}
              translations={translations}
              gatehouseType={match.params.type}
            />
          )}
          {activeTab === GATEHOUSE_TABS.IN_AREA && (
            <TabInArea
              // data
              languageId={languageId}
              translations={translations}
            />
          )}
          {activeTab === GATEHOUSE_TABS.HISTORY && (
            <TabHistory
              // data
              languageId={languageId}
              translations={translations}
            />
          )}
          {activeTab === GATEHOUSE_TABS.NOTIFICATIONS && (
            <TabNotifications
              // data
              languageId={languageId}
              translations={translations}
            />
          )}
        </GatehouseView>
      </PageFullScreen>
    );
  }
}

GatehousePage.propTypes = {
  translations: object.isRequired,
  languageId: string.isRequired,
};

export default withTranslations(QUERY_GATEHOUSE_TRANSLATIONS)(GatehousePage);
