/**
 * Parse string to number
 * @param {String} value
 */
export const onlyNumbersInString = (value) => {
  const regex = /[^0-9]/g;
  let numbers = value.replace(regex, '');

  while (numbers.length > 1 && numbers[0] === '0') {
    numbers = numbers.substring(1);
  }

  return value[0] === '-' ? `-${numbers}` : numbers;
};
