import React, { Component, } from 'react';
import { string, } from 'prop-types';
import { Query, } from 'react-apollo';

import { QUERY_GENERIC_FORM, } from '../../gql/queries';
import { parseFromApi, } from '../../../../logic/form/genericForm';
import PartLoading from '../../../../components/Parts/PartLoading';
import PartError from '../../../../components/Parts/PartError';
import GenericFormReadOnly from '../../../../components/GenericForm/GenericFormReadOnly';


class FlowChartGenForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      genForm: {
        form: null,
        tree: null,
        values: null,
      },
    };
  }


  handleFetchGenericFormComplete = (data) => {
    const { genericForm, genericFormValues, } = data.fetchGenericFormConfiguration;

    const parsedGenForm = parseFromApi(genericForm, genericFormValues);
    this.setState({
      genForm: parsedGenForm,
    });
  }


  render() {
    const { genForm, } = this.state;
    const {
      genericFormId,
      actionId,
      transportationId,
      languageId,
    } = this.props;

    return (
      <Query
        query={QUERY_GENERIC_FORM}
        variables={{
          languageId,
          actionId,
          genericFormId,
          transportationId,
        }}
        onCompleted={this.handleFetchGenericFormComplete}
        fetchPolicy="no-cache"
      >
        {({ loading, error, data, }) => {
          if (loading) {
            return <PartLoading minHeight={5} />;
          }

          if (error || !data || !data.fetchGenericFormConfiguration) {
            return <PartError error={error} minHeight={5} />;
          }

          return (
            <GenericFormReadOnly
              genForm={genForm}
              nodeId="formRoot"
            />
          );
        }}
      </Query>
    );
  }
}


FlowChartGenForm.propTypes = {
  genericFormId: string.isRequired,
  actionId: string.isRequired,
  transportationId: string.isRequired,
  languageId: string.isRequired,
};


export default FlowChartGenForm;
