import React from 'react';
import {
  string, shape, bool, func, oneOfType, arrayOf,
} from 'prop-types';
import { Query, } from 'react-apollo';

import { QUERY_GENERIC_FORM_DIAL, } from '../queries';
import FormRow from '../../Form/FormRow';
import InputSelect from '../../../atoms/InputSelect/InputSelect';


const GFSelect = ({
  input,
  value,
  isMulti,
  languageId,
  onChange,
}) => (
  <FormRow
    label={input.translation.label}
    input={input}
    info={input.translation.help}
  >
    <Query
      query={QUERY_GENERIC_FORM_DIAL}
      variables={{
        languageId,
        genericFormDialId: input.genericFormDialId,
      }}
    >
      {({ loading, error, data, }) => (
        <InputSelect
          value={value}
          options={(data && data.fetchGenericFormDial) ? data.fetchGenericFormDial : []}
          onChange={(option) => onChange(input.name, option)}
          status={input.status}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          isClearable
          isDisabled={input.disabled}
          isMulti={isMulti}
          isLoading={loading}
          error={error}
          placeholder={input.translation.placeholder}
        />
      )}
    </Query>
  </FormRow>
);


GFSelect.propTypes = {
  isMulti: bool,
  languageId: string.isRequired,
  input: shape({
    genericFormDialId: string.isRequired,
    name: string.isRequired,
    type: string.isRequired,
    disabled: bool.isRequired,
    translation: shape({
      label: string,
      placeholder: string,
      help: string,
    }).isRequired,
  }).isRequired,
  value: oneOfType([
    shape({
      id: string.isRequired,
      name: string.isRequired,
    }),
    arrayOf(shape({
      id: string.isRequired,
      name: string.isRequired,
    })),
  ]),
  onChange: func.isRequired,
};

GFSelect.defaultProps = {
  isMulti: false,
  value: null,
};


export default GFSelect;
