import React, { Component, } from 'react';
import {
  func, shape, string, object,
} from 'prop-types';

import {
  changeAndValidateInput, validateAndMergeWholeForm, mergeValidationObjectIntoForm,
} from '../../../../logic/form/common';
import { initSmsSettingsForm, } from '../forms/structure';
import SmsSettingsUpdateView from './SmsSettingsUpdateView';


class SmsSettingsUpdateLogic extends Component {
  constructor(props) {
    super(props);

    const values = props.data.fetchOneSmsSetting;

    this.state = {
      ...initSmsSettingsForm,
      id: values.id,
      detailForm: {
        ...initSmsSettingsForm.detailForm,
        values: {
          ...initSmsSettingsForm.detailForm.values,
          ...values,
          type: { id: values.type, name: values.type === 'queue' ? props.translations.smsSettings.typeQueue : props.translations.smsSettings.typeCalling },
          transportationType: { id: values.transportationTypeId, name: values.transportationTypeName, },
          platform: values.platformId ? { id: values.platformId, name: values.platformName, } : null,
        },
      },
    };
  }


  /**
   * Form - onChange
   */
  handleValueChange = (name, value) => {
    const { detailForm, } = this.state;
    const newState = changeAndValidateInput(detailForm, name, value);

    this.setState({
      detailForm: newState,
    });
  }


  /**
   * onUpdate
   */
  handleUpdate = (mutate) => {
    const { addNotification, translations, } = this.props;
    const { id, detailForm, detailForm: { values, }, } = this.state;
    const newDetailForm = validateAndMergeWholeForm(detailForm);

    if (!newDetailForm.isValid) {
      this.setState({
        detailForm: newDetailForm,
      });
    } else {
      if (values.type && values.type.id === 'calling' && values.platform === null) {
        addNotification({
          status: 'error',
          title: translations.smsSettings.platformError,
        });
        return;
      }

      mutate({
        variables: {
          id,
          recipient: values.recipient,
          type: values.type.id,
          transportationTypeId: values.transportationType.id,
          platformId: values.type && values.type.id === 'calling' ? values.platform.id : null,
        },
      });
    }
  }


  /**
   * onUpdate - Completed
   */
  handleUpdateComplete = (responseData) => {
    const {
      translations, onToggle, onUpdated, addNotification,
    } = this.props;

    onToggle();
    addNotification({
      status: 'success',
      title: translations.common.updated,
    });

    if (onUpdated) onUpdated(responseData);
  }


  /**
   * onUpdate - Error
   */
  handleUpdateError = (mutationError) => {
    try {
      const { detailForm, } = this.state;

      const { graphQLErrors, } = mutationError;

      if (graphQLErrors && graphQLErrors.length > 0) {
        const { message, extensions, } = graphQLErrors[0];

        switch (message) {
          case 'UNPROCESSABLE_ENTITY': {
            if (extensions.exception.data) {
              const newState = mergeValidationObjectIntoForm(detailForm, extensions.exception.data);

              this.setState({
                detailForm: newState,
              });
            }
            break;
          }

          default: {
            break;
          }
        }
      }
    } catch {
      // continue regardless of error
    }
  }


  render() {
    const { detailForm, } = this.state;
    const { onToggle, translations, languageId, } = this.props;

    return (
      <SmsSettingsUpdateView
        // data
        detailForm={detailForm}
        translations={translations}
        languageId={languageId}
        // methods
        onChange={this.handleValueChange}
        onToggle={onToggle}
        onUpdate={this.handleUpdate}
        onUpdateCompleted={this.handleUpdateComplete}
        onUpdateError={this.handleUpdateError}
      />
    );
  }
}


SmsSettingsUpdateLogic.propTypes = {
  data: shape({
    fetchOneSmsSetting: object.isRequired,
  }).isRequired,
  translations: object.isRequired,
  languageId: string.isRequired,
  onToggle: func.isRequired,
  onUpdated: func,
  addNotification: func.isRequired,
};

SmsSettingsUpdateLogic.defaultProps = {
  onUpdated: undefined,
};


export default SmsSettingsUpdateLogic;
