import React from 'react';
import {
  oneOfType, arrayOf, node, string, bool,
} from 'prop-types';

import Label from './Label';
import FormMessage from './FormMessage';


const FormRowSimple = ({
  label,
  info,
  showInfo,
  children,
}) => (
  <div>
    <Label
      label={label}
    />
    <div>
      {children}
    </div>
    {showInfo && (
      <FormMessage
        info={info}
      />
    )}
  </div>
);


FormRowSimple.propTypes = {
  label: string,
  info: string,
  children: oneOfType([
    arrayOf(node),
    node,
  ]).isRequired,
  showInfo: bool,
};


FormRowSimple.defaultProps = {
  label: '',
  info: '',
  showInfo: true,
};


export default FormRowSimple;
