import React from 'react';
import {
  arrayOf, oneOfType, node,
} from 'prop-types';


const FlowLineRight = ({ children, }) => (
  <div className="flowLine--right">
    {children}
  </div>
);


FlowLineRight.propTypes = {
  children: oneOfType([
    arrayOf(node),
    node,
  ]),
};

FlowLineRight.defaultProps = {
  children: null,
};


export default FlowLineRight;
