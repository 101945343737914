import React, { Fragment, } from 'react';
import {
  func, shape, bool, string, object, arrayOf,
} from 'prop-types';

import { Query, } from 'react-apollo';
import { QUERY_FETCH_TRANSPORTATIONS_WITH_NO_SAMPLE_CONFIRMED, } from '../../gql/queries';
import Table from '../../../../atoms/Table/Table';
import TableLoading from '../../../../atoms/Table/TableLoading';
import TableError from '../../../../atoms/Table/TableError';
import TableNoData from '../../../../atoms/Table/TableNoData';
import Tooltip from '../../../../atoms/Tooltip/Tooltip';
import ButtonIcon from '../../../../atoms/Button/ButtonIcon';
import ButtonGrp from '../../../../atoms/Button/ButtonGrp';
import Search from '../../../../styles/icons/Search';
import Check from '../../../../styles/icons/Check';
import ConfirmModal from '../../../../components/modal/ConfirmModal';
import { parseDateToHuman, } from '../../../../logic/date';
import { SUBSCRIPTION_REFRESH_TRANSPORTATION_SAMPLES, } from '../../gql/subscriptions';
import TransportationDetail from '../../../Transportation/components/TransportationDetail/TransportationDetail';


const COL_COUNT = 7;


const Header = ({
  // data
  translations,
}) => (
  <thead>

    <tr>
      <th
        style={{ minWidth: '5rem', width: '5rem', }}
      >
        #
      </th>

      <th
        style={{ minWidth: '10rem', }}
      >
        {translations.form.no}
      </th>

      <th
        style={{ minWidth: '9rem', }}
      >
        {translations.form.date}
      </th>

      <th
        style={{ minWidth: '7rem', }}
      >
        {translations.form.truckRegistrationNo}
      </th>

      <th
        style={{ minWidth: '7rem', }}
      >
        {translations.form.carrier}
      </th>

      <th>
        {translations.form.transportationType}
      </th>

      <th
        className="table--text-align-right"
        style={{ minWidth: '5rem', width: '5rem', }}
      >
        {translations.sample.thActions}
      </th>
    </tr>

  </thead>
);


Header.propTypes = {
  // data
  translations: object.isRequired,
};


class Rows extends React.Component {
  componentDidMount() {
    const { subscribeToMore, refetch, subscription, } = this.props;

    subscribeToMore({
      document: subscription,
      updateQuery: () => {
        refetch();
        return null;
      },
    });
  }

  render() {
    const {
      // data
      loading,
      error,
      data,
      translations,
      // methods
      onToggleConfirm,
      onToggleDetail,
    } = this.props;
    if (!data.fetchTransportationsWithNoSampleConfirmed && loading) {
      return (
        <TableLoading
          colsCount={COL_COUNT}
          rowsCount={data && data.fetchTransportationsWithNoSampleConfirmed && data.fetchTransportationsWithNoSampleConfirmed.length}
        />
      );
    }
    if (error || !data.fetchTransportationsWithNoSampleConfirmed) {
      return (
        <TableError
          colsCount={COL_COUNT}
          error={error}
        />
      );
    }
    if (data.fetchTransportationsWithNoSampleConfirmed.length < 1) {
      return (
        <TableNoData
          colsCount={COL_COUNT}
          text={translations.sample.noSamples}
        />
      );
    }

    return (
      <tbody>
        {data.fetchTransportationsWithNoSampleConfirmed.map((item) => (
          <tr key={item.id}>
            <td>
              {item.id}
            </td>
            <td>
              {item.no}
            </td>
            <td>
              {parseDateToHuman(new Date(item.date))}
            </td>
            <td>
              {item.truckRegistrationNumber}
            </td>
            <td>
              {item.companyName}
            </td>
            <td>
              {item.transportationTypeName}
            </td>
            <td className="table--text-align-right table--noPadding">
              <ButtonGrp>
                <Tooltip text={translations.common.detail}>
                  {(events) => (
                    <ButtonIcon
                      size="sm"
                      color="tertiary"
                      onClick={() => onToggleDetail(item.id)}
                      {...events}
                    >
                      <Search />
                    </ButtonIcon>
                  )}
                </Tooltip>
                <Tooltip text={translations.sample.confirmModalTitle}>
                  {(events) => (
                    <ButtonIcon
                      size="sm"
                      color="success"
                      onClick={() => onToggleConfirm(item.id)}
                      {...events}
                    >
                      <Check />
                    </ButtonIcon>
                  )}
                </Tooltip>
              </ButtonGrp>
            </td>
          </tr>
        ))}
      </tbody>
    );
  }
}

Rows.propTypes = {
  loading: bool.isRequired,
  error: object,
  data: object.isRequired,
  translations: object.isRequired,
  subscribeToMore: func.isRequired,
  refetch: func.isRequired,
  subscription: object.isRequired,
  onToggleDetail: func.isRequired,
  onToggleConfirm: func.isRequired,
};

Rows.defaultProps = {
  error: undefined,
};


const SamplesTable = ({
  // data
  resources,
  languageId,
  confirmModal,
  detailModal,
  translations,
  // methods
  onToggleConfirm,
  onToggleDetail,
  onConfirmSample,
}) => (
  <Query
    query={QUERY_FETCH_TRANSPORTATIONS_WITH_NO_SAMPLE_CONFIRMED}
    variables={{ languageId, }}
  >
    {(queryData) => (
      <Fragment>
        {confirmModal.isOpen && (
          <ConfirmModal
            isOpen
            title={translations.sample.confirmModalTitle}
            text={translations.sample.confirmModalText}
            cancelTextBtn={translations.common.no}
            confirmTextBtn={translations.common.yes}
            translations={translations}
            onToggle={onConfirmSample}
          />
        )}

        {detailModal.isOpen && (
          <TransportationDetail
            // data
            transportationId={detailModal.id}
            languageId={languageId}
            // methods
            onToggle={onToggleDetail}
          />
        )}

        <Table fillContent>
          <Header
            // data
            translations={translations}
          />
          <Rows
            // data
            {...queryData}
            resources={resources}
            translations={translations}
            subscription={SUBSCRIPTION_REFRESH_TRANSPORTATION_SAMPLES}
            // methods
            onToggleConfirm={onToggleConfirm}
            onToggleDetail={onToggleDetail}
          />
        </Table>

      </Fragment>
    )}
  </Query>
);


SamplesTable.propTypes = {
  resources: object.isRequired,
  translations: object.isRequired,
  languageId: string.isRequired,
  confirmModal: object.isRequired,
  detailModal: object.isRequired,
  onToggleDetail: func.isRequired,
  onToggleConfirm: func.isRequired,
  onConfirmSample: func.isRequired,
};


export default SamplesTable;
