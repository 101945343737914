import React, { Fragment, } from 'react';
import {
  shape, string, func, object,
} from 'prop-types';


import FormRow from '../../../../components/Form/FormRow';
import Input from '../../../../atoms/Input/Input';

const AddCarToParkingFrom = ({
  form,
  translations,
  languageId,
  values,
  onChange,
}) => (
  <Fragment>

    <FormRow
      label={translations.centralAppParking.parkingForm.registrationNumber}
      input={form.registrationNumber}
    >
      <Input
        type="text"
        value={values.registrationNumber}
        onChange={(e) => onChange(form.registrationNumber.name, e.target.value)}
        status={form.registrationNumber.status}
      />
    </FormRow>

  </Fragment>
);


AddCarToParkingFrom.propTypes = {
  translations: object.isRequired,
  languageId: string.isRequired,
  form: shape({
    registrationNumber: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
  }).isRequired,
  values: shape({
    registrationNumber: string,
    vehicleGroup: object,
  }).isRequired,
  onChange: func.isRequired,
};


export default AddCarToParkingFrom;
