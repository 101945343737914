import { parseDateToHuman, } from '../../../../logic/date';

/**
 * @param date {Date}
 * @return {Date}
 */
export const getFirstDateOfMonth = (date) => {
  const firstDate = new Date(date);
  firstDate.setDate(1);
  return firstDate;
};

/**
 * @param date {Date}
 * @return {Date}
 */
export const getLastDateOfNextYear = (date) => {
  const lastDate = new Date(date);
  lastDate.setFullYear(lastDate.getFullYear() + 1, 11, 31);
  return lastDate;
};

/**
 * Returns a new date with one added hour.
 *
 * @param date {Date}
 * @return {Date}
 */
const shiftGMT0100 = (date) => new Date(date.getTime() + 60 * 60 * 1000);

const monthToNameMap = [
  'january', 'february', 'march', 'april', 'may', 'june',
  'july', 'august', 'september', 'october', 'november', 'december',
];

/**
 * Returns translated "Month YYYY" for given date.
 *
 * @param date {Date}
 * @param monthTranslations {object}
 * @return {string}
 */
export const formatMonthYear = (date, monthTranslations) => `${monthTranslations[monthToNameMap[date.getMonth()]]} ${date.getFullYear()}`;

const weekToNamedMap = [
  'dayShortSu', 'dayShortMo', 'dayShortTu', 'dayShortWe', 'dayShortTh', 'dayShortFr', 'dayShortSa',
];

/**
 * Returns translation for given weekday.
 *
 * @param date {Date}
 * @param weekdayTranslations {string[]}
 * @return {string}
 */
export const formatWeekdayShort = (date, weekdayTranslations) => `${weekdayTranslations[weekToNamedMap[shiftGMT0100(date).getDay()]]}`;

/**
 * Parses minutes from hh:mm string.
 * @param time {string} time in hh:mm string format
 * @return {number}
 */
export const formattedTimeToMinutes = (time) => {
  const parts = time.split(':');
  return Number(parts[0]) * 60 + Number(parts[1]);
};

/**
 * Formats minutes to hh:mm string.
 *
 * @param minutes {number}
 * @return {string}
 */
export const minutesToFormattedTime = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const rest = minutes - hours * 60;
  return `${hours}:${rest.toString().padStart(2, '0')}`;
};

/**
 * @param a {Date}
 * @param b {Date}
 * @return {boolean}
 */
export const haveSameDate = (a, b) => a.getFullYear() === b.getFullYear()
  && a.getMonth() === b.getMonth()
  && a.getDate() === b.getDate();

/**
 * Returns whether the day is available, partially-blocked or blocked.
 *
 * @param date {Date} date to check
 * @param blockedDays {{date: string, dateObj: Date, fullyBlocked: boolean}[]}
 * @return {string}
 */
export const getDayAvailability = (
  date,
  blockedDays,
) => {
  const blockedDay = blockedDays.find((bd) => haveSameDate(date, bd.dateObj));

  if (!blockedDay) {
    return 'available';
  }

  if (blockedDay.fullyBlocked) {
    return 'blocked';
  }

  return 'partially-blocked';
};

/**
 * Returns whether the day is today or selected by user in calendar.
 *
 * @param date {Date}
 * @param actualDate {Date}
 * @param selectedDate {Date}
 * @return {string}
 */
export const getDayActuality = (date, actualDate, selectedDate) => {
  if (haveSameDate(selectedDate, date)) {
    return 'selected';
  }
  if (haveSameDate(actualDate, date)) {
    return 'actual';
  }
  return '';
};

/**
 * Computes time steps between given start and end.
 * Returns them as array with ids and hh:mm formatted times.
 *
 * @param start {string} time in hh:mm string format
 * @param end {string} time in hh:mm string format
 * @param step {number} step in minutes
 * @return {{id: number, time: string}[]}
 */
export const computeAvailableTimes = (start, end, step) => {
  const startInMinutes = formattedTimeToMinutes(start);
  const endInMinutes = formattedTimeToMinutes(end);

  const availableTimes = new Array((endInMinutes - startInMinutes) / step).fill(0)
    .map((_, i) => ({
      id: i,
      time: minutesToFormattedTime(i * step + startInMinutes),
    }));

  availableTimes.push({
    id: availableTimes.length,
    time: minutesToFormattedTime(endInMinutes),
  });

  return availableTimes;
};

/**
 * Return a new date object with same date as given and time set to hh:mm:00.000.
 *
 * @param date {Date}
 * @param time {string} in hh:mm format
 */
export const setTimeFromStringToDate = (date, time) => {
  const result = new Date(date);
  const parts = time.split(':');
  result.setHours(Number(parts[0]), Number(parts[1]), 0, 0);
  return result;
};

/**
 * Return a new date object with same date as in the first given date and time
 * in the second given date.
 *
 * @param date {Date}
 * @param time {Date}
 */
export const setTimeFromDateToDate = (date, time) => {
  const result = new Date(date);
  result.setHours(time.getHours(), time.getMinutes(), time.getSeconds(), 0);
  return result;
};

/**
 * @param date {Date}
 * @return {number}
 */
export const getMinutesInDay = (date) => date.getHours() * 60 + date.getMinutes();

/**
 * @param platformBlocks {{ id: number, platformId: number, platformName: string,
 *   from: string, to: string, definitionId: number | null, note: string }[]}
 *   from and to are date-times in string format
 * @return {{ id: number, platformId: number, platformName: string, date: string,
 *   from: Date, to: Date, fromMinutes: number, toMinutes: number,
 *   definitionId: number | null, note: string }[]}
 *   date is date in en MM/DD/YYYY format, from and to are Date objects,
 *   fromMinutes and toMinutes are number of minutes
 */
export const parseFetchedPlatformBlocks = (platformBlocks) => platformBlocks.map((bd) => {
  const from = new Date(bd.from);
  const to = new Date(bd.to);

  return {
    id: bd.id,
    platformId: bd.platformId,
    platformName: bd.platformName,
    date: from.toLocaleDateString('en'),
    from,
    to,
    fromMinutes: getMinutesInDay(from),
    toMinutes: getMinutesInDay(to),
    definitionId: bd.definitionId,
    note: bd.note,
    groupId: bd.groupId,
  };
});

/**
 * @param platformBlocks {PlatformBlock[]}
 * @param date {Date}
 * @return {PlatformBlock[]}
 */
export const filterSelectedPlatformBlocks = (platformBlocks, date) => {
  const dateStr = date.toLocaleDateString('en');

  return platformBlocks.filter((pb) => pb.date === dateStr);
};

/**
 * Pre-creates a date object for each blocked day in the given array.
 */
export const addDateObjToBlockedDays = (blockedDays) => blockedDays.map((bd) => ({
  ...bd,
  dateObj: new Date(bd.date),
}));

/**
 * @param blockDefinitions {{
 *   id: number,
 *   useFrom: Date,
 *   useTo: Date,
 *   type: string,
 *   selectedDays: number[] | null,
 *   platformIds: number[],
 *   timeFrom: string,
 *   timeTo: string,
 *   note: string,
 * }[]}
 * @param platforms {{
 *   id: number,
 *   name: string,
 *   start: string,
 *   end: string,
 * }[]}
 * @return {{
 *   id: number,
 *   useFrom: string,
 *   useTo: string,
 *   useFromDateString: string,
 *   useToDateString: string,
 *   type: string,
 *   selectedDays: number[] | null,
 *   platformIds: number[],
 *   platformNames: string,
 *   timeFrom: string,
 *   timeTo: string,
 *   note: string,
 * }[]}
 */
export const parseFetchedPlatformBlockDefinitions = (
  blockDefinitions,
  platforms,
) => blockDefinitions.map((bd) => ({
  ...bd,
  useFromDateString: parseDateToHuman(new Date(bd.useFrom), false).replace(' ', ''),
  useToDateString: parseDateToHuman(new Date(bd.useTo), false).replace(' ', ''),
  platformNames: bd.platformIds
    .map((id) => {
      const platform = platforms.find((p) => p.id === id);
      return platform ? platform.name : '';
    })
    .filter((name) => name !== '')
    .join(', '),
}));

const WEEKDAYS = [ 'ne', 'po', 'út', 'st', 'čt', 'pá', 'so', ];

export const formatBlockDefinitionType = (definition) => {
  if (definition.type === 'daily') {
    return 'Denně';
  }

  if (definition.type === 'weekly') {
    return `Týdně (${definition.selectedDays.map((day) => WEEKDAYS[day]).join(', ')})`;
  }

  if (definition.type === 'monthly') {
    return `Měsíčně (${definition.selectedDays.join('., ')}.)`;
  }

  return '-';
};
