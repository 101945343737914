import React, { Component, } from 'react';
import { string, object, } from 'prop-types';

import { TRANSLATIONS_MONITORING, } from '../gql/translations';
import { withTranslations, } from '../../../logic/translations/withTranslations';
import MonitoringColumn from '../components/Monitoring/MonitoringColumn';
import TransportationEdit from '../../Transportation/components/TransportationEdit/TransportationEdit';
import TransportationAdmin from '../../Transportation/components/TransportationAdmin/TransportationAdmin';
import Breadcrumb from '../../../atoms/Breadcrumb/Breadcrumb';
import BreadcrumbItem from '../../../atoms/Breadcrumb/BreadcrumbItem';
import PageFullScreen from '../../../atoms/PageView/PageFullScreen';

import StyledMonitoring from '../components/Monitoring/styles/StyledMonitoring';


export const MONITORING_COLUMNS = [
  {
    state: 'plan',
    title: 'plan',
  },
  {
    state: 'register',
    title: 'register',
  },
  {
    state: 'waiting',
    title: 'waiting',
  },
  {
    state: 'clearing',
    title: 'clearing',
  },
  {
    state: 'cleared',
    title: 'cleared',
  },
  {
    state: 'finished',
    title: 'finished',
  },
];


class MonitoringPage extends Component {
  state = {
    editTransportationModal: {
      isOpen: false,
      id: null,
    },
    adminTransportationModal: {
      isOpen: false,
      id: null,
    },
  }


  openTransportationModal = (state = null, id = null) => {
    if (state === 'plan') {
      this.setState((prevState) => ({
        editTransportationModal: {
          ...prevState.editTransportationModal,
          isOpen: true,
          id,
        },
      }));
    } else {
      this.setState((prevState) => ({
        adminTransportationModal: {
          ...prevState.adminTransportationModal,
          isOpen: true,
          id,
        },
      }));
    }
  }


  closeTransportationModal = () => {
    this.setState((prevState) => ({
      adminTransportationModal: {
        ...prevState.adminTransportationModal,
        isOpen: false,
        id: null,
      },
      editTransportationModal: {
        ...prevState.editTransportationModal,
        isOpen: false,
        id: null,
      },
    }));
  }


  render() {
    const {
      editTransportationModal,
      adminTransportationModal,
    } = this.state;
    const {
      languageId,
      translations,
      translationsCommon,
    } = this.props;

    return (
      <PageFullScreen>

        <Breadcrumb className="monitoring--breadcrumb">
          <BreadcrumbItem to="/">{translations.common.home}</BreadcrumbItem>
          <BreadcrumbItem>{translations.common.monitoringModule}</BreadcrumbItem>
          <BreadcrumbItem>{translations.common.monitoring}</BreadcrumbItem>
        </Breadcrumb>

        {editTransportationModal.isOpen && (
          <TransportationEdit
            // data
            transportationId={editTransportationModal.id}
            // methods
            onToggle={this.closeTransportationModal}
          />
        )}

        {adminTransportationModal.isOpen && (
          <TransportationAdmin
            // data
            transportationId={adminTransportationModal.id}
            // methods
            onToggle={this.closeTransportationModal}
          />
        )}

        <StyledMonitoring>
          <ul className="monitoring--content-list">
            {MONITORING_COLUMNS.map((column) => (
              <MonitoringColumn
                key={column.state}
                // data
                columnData={column}
                languageId={languageId}
                translations={translations}
                translationsCommon={translationsCommon}
                // methods
                onDetail={this.openTransportationModal}
              />
            ))}
          </ul>
        </StyledMonitoring>

      </PageFullScreen>
    );
  }
}


MonitoringPage.propTypes = {
  languageId: string.isRequired,
  translations: object.isRequired,
  translationsCommon: object.isRequired,
};


export default withTranslations(TRANSLATIONS_MONITORING)(MonitoringPage);
