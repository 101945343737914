import React from 'react';
import { string, number, oneOf, } from 'prop-types';


const StatsNumberCard = ({ title, num, color, }) => (
  <div className={`stats--number-card ${color}`}>
    <div className="stats--number-card--title">{title}</div>
    <div className="stats--number-card--number">{num}</div>
  </div>
);


StatsNumberCard.propTypes = {
  title: string.isRequired,
  num: number.isRequired,
  color: oneOf([ 'green', 'orange', 'grey', ]).isRequired,
};


export default StatsNumberCard;
