import styled from 'styled-components';


const StyledApp = styled.div`
  font-size: 0.875rem;

  .noSelect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
  }

  fieldset {
    border: 1px solid ${(p) => p.theme.grey.t400};
  }

  legend {
    font-size: 1rem;
    font-weight: bold;
    padding: 0 0.5rem;
  }

  h1 {
    font-size: 1.5rem;
    margin-top: 0;
    margin-bottom: 1rem;
  }

  h2 {
    font-size: 1.25rem;
    margin-top: 0;
    margin-bottom: 1rem;
  }

  h3 {
    font-size: 1.15rem;
    margin-top: 0;
    margin-bottom: 0.875rem;
  }

  h4 {
    font-size: 1rem;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }

  h5 {
    font-size: 0.875rem;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }

  h6 {
    font-size: 0.67rem;
    margin-top: 0;
    margin-bottom: 0.25rem;
  }

  .app--maxHeight {
    height: 100%;
  }

  .app--padding {
    padding: ${(p) => p.theme.common.padding};
  }

  .color--primary {
    color: ${(p) => p.theme.primary.t800};
  }

  .color--secondary {
    color: ${(p) => p.theme.secondary.t800};

  }

  .color--tertiary {
    color: ${(p) => p.theme.tertiary.t800};
  }

  .color--error {
    color: ${(p) => p.theme.error.t800};
  }

  .color--warning {
    color: ${(p) => p.theme.warning.t800};
  }

  .color--success {
    color: ${(p) => p.theme.success.t800};
  }

  .color--disabled {
    color: ${(p) => p.theme.grey.t500};
  }

  .color--white {
    color: ${(p) => p.theme.white};
  }

  .color--watermark {
    color: ${(p) => p.theme.grey.t500};
  }
`;


export default StyledApp;
