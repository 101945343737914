import React from 'react';
import {
  shape, string, func, bool, object,
} from 'prop-types';

import InputCheckbox from '../../../../atoms/InputCheckbox/InputCheckbox';


const BlockMoreSegments = ({
  form,
  values,
  translations,
  disabled,
  onChange,
}) => (
  <fieldset>
    <legend>{translations.transportation.connectedTransports.titleSettings}</legend>
    <InputCheckbox
      value={values.autoStartOnCombinedTransportation}
      text={translations.transportation.connectedTransports.labelAutostartAfterFinishPrev}
      onChange={(newValue) => onChange(form.autoStartOnCombinedTransportation.name, newValue)}
      disabled={disabled}
    />
  </fieldset>
);


BlockMoreSegments.propTypes = {
  form: shape({
    autoStartOnCombinedTransportation: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
  }).isRequired,
  values: shape({
    autoStartOnCombinedTransportation: bool,
  }).isRequired,
  disabled: bool.isRequired,
  translations: object.isRequired,
  onChange: func.isRequired,
};


export default BlockMoreSegments;
