import React from 'react';
import { Query, } from 'react-apollo';
import { object, } from 'prop-types';

import { QUERY_LICENSE_DETAIL, QUERY_LICENSE_TRANSLATION, } from './gql/queries';
import { LicenseValidityStatus, } from './utils/licenseValidityStatus';
import { differenceInDays, } from '../../logic/date';
import { withTranslations, } from '../../logic/translations/withTranslations';
import StyledLoginLicenseWarning from './styles/StyledLoginLicenseWarning';


const isWarning = (data) => data
  && data.getLicenseDetail
  && data.getLicenseDetail.validityStatus === LicenseValidityStatus.warning;

const LoginLicenseWarning = ({ translations, }) => (
  <Query query={QUERY_LICENSE_DETAIL}>
    {({ data, }) => (isWarning(data)
      ? (
        <StyledLoginLicenseWarning>
          {`${translations.licensing.licenseExpiresSoon} (${translations.licensing.remainingDays}: `}
          {differenceInDays(new Date(data.getLicenseDetail.validTo), new Date())}
          {`) - ${translations.licensing.contactMsg} / `}
          <a href={`mailto:${translations.licensing.contactEmail}`}>{translations.licensing.contactEmail}</a>
          {' / '}
          <a href={`tel:${translations.licensing.contactPhone}`}>{translations.licensing.contactPhone}</a>
        </StyledLoginLicenseWarning>
      )
      : null
    )}
  </Query>
);

LoginLicenseWarning.propTypes = {
  translations: object.isRequired,
};


export default withTranslations(QUERY_LICENSE_TRANSLATION)(LoginLicenseWarning);
