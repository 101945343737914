import React from 'react';
import {
  func, object, shape, bool, string,
} from 'prop-types';
import { Query, Mutation, } from 'react-apollo';

import { QUERY_FORWARD_PLATFORMS, QUERY_FORWARD_TRANSPORTATION_TYPE, } from '../../../gql/queries';
import { MUTATION_FORWARD_TRANSPORTATION, MUTATION_FORWARD_TRANSPORTATION_TO_WAREHOUSE, } from '../../../gql/mutations';
import Row from '../../../../../atoms/Row/Row';
import Col from '../../../../../atoms/Col/Col';
import ButtonLoader from '../../../../../atoms/Button/ButtonLoader';
import Button from '../../../../../atoms/Button/Button';
import InputSelect from '../../../../../atoms/InputSelect/InputSelect';
import InputRadio from '../../../../../atoms/InputRadio/InputRadio';
import FormBackground from '../../../../../components/Form/FormBackground';
import FormError from '../../../../../components/Form/FormError';
import FormRow from '../../../../../components/Form/FormRow';
import InputRadioList from '../../../../../atoms/InputRadio/InputRadioList';
import { FORWARD_TYPES, } from './ModalForward';


const renderForwardToPlatform = ({
  transportationId,
  platformId,
  forwardForm: {
    isValid,
    form,
    values,
  },
  languageId,
  translations,
  // methods
  onToggle,
  onChangeForwardForm,
  onForward,
  onForwardCompleted,
  onForwardError,
}) => (
  <>
    <FormRow
      label={translations.form.platform}
      input={form.platform}
    >
      <Query
        query={QUERY_FORWARD_PLATFORMS}
        variables={{
          transportationId,
          languageId,
          platformId,
        }}
      >
        {({ loading, error, data, }) => (
          <InputSelect
            options={(data && data.fetchStoreKeeperForwardPossiblePlatforms)
              ? data.fetchStoreKeeperForwardPossiblePlatforms
              : []
            }
            value={values.platform}
            onChange={(newValue) => onChangeForwardForm(form.platform.name, newValue)}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            loading={loading}
            error={error}
            placeholder={translations.form.placeholderModalForwardPlatform}
          />
        )}
      </Query>
    </FormRow>
    <Mutation
      mutation={MUTATION_FORWARD_TRANSPORTATION}
      onCompleted={onForwardCompleted}
      onError={onForwardError}
    >
      {(mutationForward, { loading, error, }) => (
        <>
          <FormError
            isValid={isValid}
            error={error}
          />
          <Row>
            <Col XS={12} SM={12}>
              <Button
                onClick={onToggle}
              >
                {translations.common.close}
              </Button>
            </Col>
            <Col XS={12} SM={12} textAlign="right">
              <ButtonLoader
                className="buttonGrp--right"
                color="primary"
                disabled={!isValid}
                onClick={() => onForward(mutationForward)}
                isLoading={loading}
              >
                {translations.centralAppStorekeeper.btnForward}
              </ButtonLoader>
            </Col>
          </Row>
        </>
      )}
    </Mutation>
  </>
);

const renderForwardToWareHouse = ({
  transportationId,
  forwardForm: {
    isValid,
    form,
    values,
  },
  languageId,
  translations,
  // methods
  onToggle,
  onChangeForwardForm,
  onForward,
  onForwardCompleted,
  onForwardError,
}) => (
  <>
    <FormRow
      label={translations.form.transportationType}
      input={form.transportationType}
    >
      <Query
        query={QUERY_FORWARD_TRANSPORTATION_TYPE}
        fetchPolicy="no-cache"
        variables={{
          transportationId,
          languageId,
        }}
      >
        {({ loading, error, data, }) => (
          <InputSelect
            options={(data && data.fetchTransportationTypesForForwarding)
              ? data.fetchTransportationTypesForForwarding
              : []
            }
            value={values.transportationType}
            onChange={(newValue) => onChangeForwardForm(form.transportationType.name, newValue)}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            loading={loading}
            error={error}
            placeholder={
              translations.centralAppStorekeeper.forwarding.placeholderForwardToWarehouse
            }
          />
        )}
      </Query>
    </FormRow>
    <Mutation
      mutation={MUTATION_FORWARD_TRANSPORTATION_TO_WAREHOUSE}
      onCompleted={onForwardCompleted}
      onError={onForwardError}
    >
      {(mutationForward, { loading, error, }) => (
        <>
          <FormError
            isValid={isValid}
            error={error}
          />
          <Row>
            <Col XS={12} SM={12}>
              <Button
                onClick={onToggle}
              >
                {translations.common.close}
              </Button>
            </Col>
            <Col XS={12} SM={12} textAlign="right">
              <ButtonLoader
                className="buttonGrp--right"
                color="primary"
                disabled={!isValid}
                onClick={() => onForward(mutationForward)}
                isLoading={loading}
              >
                {translations.centralAppStorekeeper.btnForward}
              </ButtonLoader>
            </Col>
          </Row>
        </>
      )}
    </Mutation>
  </>
);

const ModalForwardView = ({
  // data
  transportationId,
  platformId,
  forwardType,
  forwardForm: {
    isValid,
    form,
    values,
  },
  forwardToWarehouseForm,
  languageId,
  translations,
  // methods
  onToggle,
  onChangeForwardType,
  onChangeForwardForm,
  onForward,
  onChangeForwardToWarehouseForm,
  onForwardToWarehouse,
  onForwardCompleted,
  onForwardError,
}) => (
  <FormBackground>

    <Row formGroup>
      <Col>
        {translations.centralAppStorekeeper.textModalForward}
      </Col>
    </Row>

    <FormRow
      label={translations.centralAppStorekeeper.forwarding.labelForwardType}
      input={form.forwardType}
    >
      <InputRadioList>
        <li>
          <InputRadio
            text={translations.centralAppStorekeeper.forwarding.labelForwardToPlatform}
            value={forwardType}
            name={FORWARD_TYPES.PLATFORM}
            onChange={onChangeForwardType}
          />
        </li>
        <li>
          <InputRadio
            text={translations.centralAppStorekeeper.forwarding.labelForwardToWarehouse}
            value={forwardType}
            name={FORWARD_TYPES.WAREHOUSE}
            onChange={onChangeForwardType}
          />
        </li>
      </InputRadioList>
    </FormRow>
    {
      forwardType === FORWARD_TYPES.PLATFORM
        ? renderForwardToPlatform({
          transportationId,
          platformId,
          forwardForm: {
            isValid,
            form,
            values,
          },
          languageId,
          translations,
          onToggle,
          onChangeForwardForm,
          onForward,
          onForwardCompleted,
          onForwardError,
        })
        : renderForwardToWareHouse({
          transportationId,
          forwardForm: forwardToWarehouseForm,
          languageId,
          translations,
          onToggle,
          onChangeForwardForm: onChangeForwardToWarehouseForm,
          onForward: onForwardToWarehouse,
          onForwardCompleted,
          onForwardError,
        })
    }

  </FormBackground>
);


ModalForwardView.propTypes = {
  // data
  transportationId: string.isRequired,
  platformId: string.isRequired,
  forwardType: string.isRequired,
  forwardForm: shape({
    isValid: bool.isRequired,
    form: shape({
      forwardType: object.isRequired,
      platform: object.isRequired,
    }).isRequired,
    values: shape({
      forwardType: string,
      platform: object,
    }).isRequired,
  }).isRequired,
  forwardToWarehouseForm: shape({
    isValid: bool.isRequired,
    form: shape({
      transportationType: object.isRequired,
    }).isRequired,
    values: shape({
      transportationType: object,
    }).isRequired,
  }).isRequired,
  languageId: string.isRequired,
  translations: object.isRequired,
  // methods
  onToggle: func.isRequired,
  onChangeForwardType: func.isRequired,
  onChangeForwardForm: func.isRequired,
  onForward: func.isRequired,
  onChangeForwardToWarehouseForm: func.isRequired,
  onForwardToWarehouse: func.isRequired,
  onForwardCompleted: func.isRequired,
  onForwardError: func.isRequired,
};


export default ModalForwardView;
