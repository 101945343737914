import React, { Fragment, } from 'react';
import {
  arrayOf,
  func, object,
} from 'prop-types';

import FormRow from '../../../../components/Form/FormRow';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import InputSelect from '../../../../atoms/InputSelect/InputSelect';
import { getYesNoOptions, } from '../InputTechnicalCheck/utils';
import Input from '../../../../atoms/Input/Input';
import Label from '../../../../components/Form/Label';


const TechnicalCheckLoadForm = ({
  form,
  translations,
  values,
  loadProductForms,
  onChange,
}) => (
  <Fragment>

    {loadProductForms.map((loadProductForm, index) => (
      <Fragment key={index}>
        <Row>
          <Col SM={24}>
            <Label label={`${loadProductForm.values.name}, ${loadProductForm.values.destination}`} />
          </Col>
        </Row>
        <Row>
          <Col SM={8}>
            <FormRow
              label={translations.form.lowerLimitQuantity}
              input={loadProductForm.form.lowerLimitQuantity}
            >
              <Input
                type="text"
                value={loadProductForm.values.lowerLimitQuantity}
                onChange={(e) => onChange(`loadProduct.${loadProductForm.form.lowerLimitQuantity.name}`, e.target.value, index)}
                status={loadProductForm.form.lowerLimitQuantity.status}
              />
            </FormRow>
          </Col>

          <Col SM={8}>
            <FormRow
              label={translations.form.recalculationOfTotalLimitQuantity}
              input={loadProductForm.form.recalculationOfTotalLimitQuantity}
            >
              <Input
                type="text"
                value={loadProductForm.values.recalculationOfTotalLimitQuantity}
                onChange={(e) => onChange(`loadProduct.${loadProductForm.form.recalculationOfTotalLimitQuantity.name}`, e.target.value, index)}
                status={loadProductForm.form.recalculationOfTotalLimitQuantity.status}
              />
            </FormRow>
          </Col>

          <Col SM={8}>
            <FormRow
              label={translations.form.transportationIn450LPackage}
              input={loadProductForm.form.transportationIn450LPackage}
            >
              <InputSelect
                value={loadProductForm.values.transportationIn450LPackage}
                options={getYesNoOptions(translations)}
                onChange={(option) => onChange(`loadProduct.${loadProductForm.form.transportationIn450LPackage.name}`, option, index)}
                status={loadProductForm.form.transportationIn450LPackage.status}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                placeholder={translations.common.select}
              />
            </FormRow>
          </Col>
        </Row>
      </Fragment>
    ))}

  </Fragment>
);


TechnicalCheckLoadForm.propTypes = {
  translations: object.isRequired,
  form: object.isRequired,
  values: object.isRequired,
  loadProductForms: arrayOf(object).isRequired,
  onChange: func.isRequired,
};


export default TechnicalCheckLoadForm;
