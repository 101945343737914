import React, { Component, } from 'react';
import { func, string, object, bool, } from 'prop-types';
import { Query, } from 'react-apollo';

import { QUERY_ALL_DRIVERS, } from '../../gql/queries';
import FilterStandardView from './FilterStandardView';


class DriverFilter extends Component {
  state = {
    filter: null,
  }


  handleChangeFilter = (value) => {
    this.setState({
      filter: value,
    });
  }


  handleApply = () => {
    const { filter, } = this.state;
    const { onApply, forPassenger, } = this.props;

    if (filter) {
      onApply([
        {
          name: forPassenger ? 'passengerName' : 'driverName',
          value: filter.name,
        },
        {
          name: forPassenger ? 'passengerPhoneNumber' : 'driverPhoneNumber',
          value: filter.phoneNumber,
        },
      ]);
    }
  }


  render() {
    const {
      filter,
    } = this.state;
    const { placeholder, translations, } = this.props;

    return (
      <Query
        query={QUERY_ALL_DRIVERS}
        fetchPolicy="cache-and-network"
      >
        {(filterData) => (
          <FilterStandardView
            // data
            filter={filter}
            filterData={filterData}
            options={filterData.data ? filterData.data.fetchAllDrivers : []}
            placeholder={placeholder}
            translations={translations}
            // methods
            onChange={this.handleChangeFilter}
            onApply={this.handleApply}
          />
        )}
      </Query>
    );
  }
}


DriverFilter.propTypes = {
  onApply: func.isRequired,
  placeholder: string,
  translations: object.isRequired,
  forPassenger: bool,
};

DriverFilter.defaultProps = {
  placeholder: '',
  forPassenger: false,
};


export default DriverFilter;
