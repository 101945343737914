import React from 'react';
import {
  shape, string, object, func, bool, number,
} from 'prop-types';
import { Mutation, } from 'react-apollo';

import { MUTATION_SET_PLATFORM_STATE, } from '../../../gql/mutations';
import Button from '../../../../../atoms/Button/Button';


const PlatformView = ({
  // data
  platform: {
    name,
    opened,
    capacity,
  },
  occupied,
  translations,
  // functions
  onSetPlatformState,
  onSetPlatformStateCompleted,
  onSetPlatformStateError,
}) => (
  <div className="storekeeperPlatform--platform-panel">

    <div className="storekeeperPlatform--platform-panel-top">
      <div className={`storekeeperPlatform--platform-panel-sign storekeeperPlatform--platform-panel-sign-${opened ? 'opened' : 'closed'}`} />
      <div className="storekeeperPlatform--platform-panel-title">
        {name}
      </div>
      <div className="storekeeperPlatform--platform-panel-btnWrapper">
        <Mutation
          mutation={MUTATION_SET_PLATFORM_STATE}
          onCompleted={onSetPlatformStateCompleted}
          nError={onSetPlatformStateError}
        >
          {(mutationOpen, { loading, }) => (
            <Button
              className="storekeeperPlatform--platform-panel-btnWrapper-btn"
              color="tertiary"
              size="sm"
              transparent
              onClick={() => onSetPlatformState(mutationOpen)}
              disabled={loading}
              paddingHalf
            >
              {opened
                ? translations.centralAppStorekeeper.btnClosePlatform
                : translations.centralAppStorekeeper.btnOpenPlatform
              }
            </Button>
          )}
        </Mutation>
      </div>
    </div>

    <div className="storekeeperPlatform--platform-panel-capacity">
      <span>{`${translations.centralAppStorekeeper.platformCapacity} (${occupied}/${capacity})`}</span>
    </div>

  </div>
);


PlatformView.propTypes = {
  platform: shape({
    id: string.isRequired,
    name: string.isRequired,
    opened: bool.isRequired,
  }).isRequired,
  occupied: number.isRequired,
  translations: object.isRequired,
  onSetPlatformState: func.isRequired,
  onSetPlatformStateCompleted: func.isRequired,
  onSetPlatformStateError: func.isRequired,
};


export default PlatformView;
