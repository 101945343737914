import React, { Component, } from 'react';
import {any, func, object, string,} from 'prop-types';

import { withNotifications, } from '../../../../logic/notifications/withNotifications';
import {
  changeAndValidateInput,
  validateAndMergeWholeForm,
  mergeValidationObjectIntoForm,
} from '../../../../logic/form/common';
import { initStandCallForm, } from './utils';
import StandCallView from './StandCallView';


class StandCallLogic extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: { ...initStandCallForm, options: props.options, },
    };
  }


  handleChangeForm = (name, value) => {
    const { form, } = this.state;

    this.setState({
      form: changeAndValidateInput(form, name, value),
    });
  }


  handleCall = (standCallMutation) => {
    const { form, form: { values, }, } = this.state;
    const { standId, transportationId, } = this.props;

    // validate form
    const newForm = validateAndMergeWholeForm(form);

    if (!newForm.isValid) {
      this.setState({
        form: newForm,
      });

    // if valid - create
    } else {
      const variables = {
        standId,
        transportationId,
        platform: values.platform.name,
      };

      standCallMutation({
        variables,
      });
    }
  }


  handleCallComplete = () => {
    const {
      translations, onToggle, addNotification,
    } = this.props;

    onToggle();
    addNotification({
      status: 'success',
      title: translations.stand.messageTitleCalled,
    });
  }


  handleCallError = (mutationError) => {
    try {
      const { form, } = this.state;

      const { graphQLErrors, } = mutationError;

      if (graphQLErrors && graphQLErrors.length > 0) {
        const { message, extensions, } = graphQLErrors[0];

        switch (message) {
          case 'UNPROCESSABLE_ENTITY': {
            if (extensions.exception.data) {
              this.setState({
                form: mergeValidationObjectIntoForm(form, extensions.exception.data),
              });
            }
            break;
          }

          default: {
            break;
          }
        }
      }
    } catch (err) {
      // continue regardless of error
    }
  }


  render() {
    const { form, } = this.state;
    const { translations, onToggle, } = this.props;

    return (
      <StandCallView
        // data
        form={form}
        translations={translations}
        // methods
        onChange={this.handleChangeForm}
        onToggle={onToggle}
        onCall={this.handleCall}
        onCallComplete={this.handleCallComplete}
        onCallError={this.handleCallError}
      />
    );
  }
}


StandCallLogic.propTypes = {
  // data
  translations: object.isRequired,
  standId: string.isRequired,
  transportationId: string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  options: any.isRequired,
  // methods
  onToggle: func.isRequired,
  addNotification: func.isRequired,
};


export default withNotifications(StandCallLogic);
