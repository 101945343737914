import styled from 'styled-components';


const StyledTimeline = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;

  /**
   * Left Panel 
   */
  .timeline--leftPanel {
    width: ${(p) => p.params.leftPanelWidth}rem;
    min-width: ${(p) => p.params.leftPanelWidth}rem;
    border-right: 1px solid ${(p) => p.theme.black};
  }

  .timeline--leftPanel-top {
    height: ${(p) => p.params.topPanelHeight}rem;
    width: 100%;
  }

  .timeline--leftPanel-rows {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .timeline--leftPanel-rows > li {
    display: block;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${(p) => p.theme.white};
    min-height: ${(p) => p.params.rowHeight}rem;
  }

  .timeline--leftPanel-rows > li:nth-child(odd) {
    background: ${(p) => p.theme.grey.t50};
  }

  /**
   * Main Panel 
   */
  .timeline--main {
    overflow-x: auto;
    overflow-y: hidden;
  }

  /**
   * Main Panel - Top
   */
  .timeline--top {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    border-bottom: 1px solid ${(p) => p.theme.black};

    height: ${(p) => p.params.topPanelHeight}rem;
    width: ${(p) => p.params.timelineWidth}rem;
  }

  .timeline--top-item {
    width: ${(p) => p.params.hourWidth}rem;
    height: 100%;
    padding-left: 4px;
  }

  /**
   * Main Panel - Dashed lines
   */
  .timeline--dashed {
    position: relative;
  }

  .timeline--dashed-column {
    position: absolute;
    width: ${(p) => p.params.hourWidth}rem;
    height: 100%;
    border-right: 1px dashed black;
  }

  /**
   * Main Panel - Sections
   */
  .timeline--sections {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .timeline--sections > li {
    width: ${(p) => p.params.timelineWidth}rem;
    background: ${(p) => p.theme.white};
    min-height: ${(p) => p.params.rowHeight}rem;
  }

  .timeline--sections > li:nth-child(odd) {
    background: ${(p) => p.theme.grey.t50};
  }

  .timeline--sections-row {
    position: relative;
    width: ${(p) => p.params.timelineWidth}rem;
    height: ${(p) => p.params.rowHeight}rem;
  }


  /**
   * Item
   */
  .timeline--item {
    position: absolute;
    height: ${(p) => p.params.rowHeight}rem;
  }

  .timeline--item-content {
    height: ${(p) => p.params.rowHeight}rem;
    margin: auto 1px;
    display: flex;
    align-items: center;
  }

  .timeline--item-view {
    height: 70%;
    width: 100%;
    background: ${(p) => (p.theme.primary.t300)};
    overflow: hidden;
    display: flex;
    box-shadow: 0px 2px 5px 0px rgba(105, 105, 105, 0.75);

    border-color: ${(p) => (p.theme.black)};
    border-top: 1px solid;
    border-bottom: 1px solid;

    ${(p) => p.isClickable && `
      cursor: pointer;
    `}
  }

  .timeline--item-view-col1 {
    width: 100%;
    overflow: hidden;
    padding-right: 0.2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .timeline--item-view-col2 {
    width: 2.5rem;
    min-width: 2.5rem;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff52;
  }

  .timeline--item-view-textRow {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-left: 0.25rem;
  }

  .timeline--item-view-textRow-title {
    font-weight: bold;
  }

  .timeline--item-view-textRow-text {

  }
`;


export default StyledTimeline;
