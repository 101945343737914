import React, { Component, } from 'react';
import {
  number, shape, object, func, bool, string,
} from 'prop-types';


const ItemView = ({
  width,
  hourWidth,
  from,
  overFlowLeft,
  overFlowRight,
  namesAndStyle: {
    style,
    text1,
    text2,
    text3,
    ...rest
  },
  // methods
  onClickItem,
}) => (
  <div
    className="timeline--item"
    style={{
      width: `${width * hourWidth}rem`,
      left: `${from * hourWidth}rem`,
    }}
    {...rest}
  >
    <div className="timeline--item-content">
      <div
        role="none"
        className="timeline--item-view"
        style={{
          borderLeft: overFlowLeft ? 'none' : '1px solid',
          borderRight: overFlowRight ? 'none' : '1px solid',
          ...style,
        }}
        onClick={onClickItem}
      >
        <div className="timeline--item-view-col1">
          <span className="timeline--item-view-textRow timeline--item-view-textRow-title">{text1}</span>
          <span className="timeline--item-view-textRow timeline--item-view-textRow-text">{text2}</span>
        </div>
        {text3 && (
          <div className="timeline--item-view-col2">
            {text3}
          </div>
        )}
      </div>
    </div>
  </div>
);


ItemView.propTypes = {
  from: number.isRequired,
  width: number.isRequired,
  overFlowLeft: bool.isRequired,
  overFlowRight: bool.isRequired,
  hourWidth: number.isRequired,
  namesAndStyle: shape({
    style: object,
    text1: string,
    text2: string,
    text3: string,
  }),
  onClickItem: func.isRequired,
};

ItemView.defaultProps = {
  namesAndStyle: {
    style: {},
    text1: '-',
  },
};


class Item extends Component {
  handleOnClick = (e) => {
    const { payload, onClickItem, } = this.props;

    if (onClickItem) {
      onClickItem(payload, e);
    }
  }


  render() {
    const {
      width,
      from,
      overFlowLeft,
      overFlowRight,
      payload,
      params: {
        hourWidth,
      },
      getItemNameAndStyle,
    } = this.props;

    const namesAndStyle = getItemNameAndStyle(payload);

    return (
      <ItemView
        width={width}
        hourWidth={hourWidth}
        from={from}
        overFlowLeft={overFlowLeft}
        overFlowRight={overFlowRight}
        namesAndStyle={namesAndStyle}
        // methods
        onClickItem={this.handleOnClick}
      />
    );
  }
}


Item.propTypes = {
  from: number.isRequired,
  width: number.isRequired,
  overFlowLeft: bool.isRequired,
  overFlowRight: bool.isRequired,
  payload: object.isRequired,
  params: shape({
    hourWidth: number.isRequired,
  }).isRequired,
  getItemNameAndStyle: func.isRequired,
  onClickItem: func.isRequired,
};


export default Item;
