import React, { Fragment, Component, } from 'react';
import {
  func, object, string, shape, bool, arrayOf,
} from 'prop-types';
import { Mutation, } from 'react-apollo';

import { MUTATION_UPDATE_SMS_TEMPLATE, } from '../../gql/mutations';
import Button from '../../../../atoms/Button/Button';
import ButtonLoader from '../../../../atoms/Button/ButtonLoader';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import FormError from '../../../../components/Form/FormError';
import FormBackground from '../../../../components/Form/FormBackground';
import FormRow from '../../../../components/Form/FormRow';
import Input from '../../../../atoms/Input/Input';
import InputSelect from '../../../../atoms/InputSelect/InputSelect';
import TextArea from '../../../../atoms/TextArea/TextArea';

const fakeInput = {
  status: 'OK',
  statusMsg: {
    code: '',
    params: {},
  },
  validation: {
    required: false,
  },
};

const TEXTAREA_ID = 'sms_text_textarea';

class SmsUpdateView extends Component {
  componentDidMount() {
    this.textarea = document.getElementById(TEXTAREA_ID);
    this.textarea.focus();
  }

  handlePlaceholderInsert = (placeholder) => {
    const { detailForm, onChange, } = this.props;
    const start = this.textarea.selectionStart;
    const end = this.textarea.selectionEnd;
    const beforeCursor = detailForm.values.text.slice(0, start);
    const afterCursor = detailForm.values.text.slice(end);
    const newSelection = start + placeholder.placeholder.length;
    const newValue = beforeCursor + placeholder.placeholder + afterCursor;

    onChange(detailForm.form.text.name, newValue, () => {
      this.textarea.focus();
      this.textarea.setSelectionRange(newSelection, newSelection);
    });
  }

  handleSelectBlur = () => {
    this.textarea.focus();
  }

  render = () => {
    const {
      // data
      resources,
      translations,
      staticSmsData,
      detailForm,
      // methods
      onChange,
      onToggle,
      onUpdate,
      onUpdateCompleted,
      onUpdateError,
    } = this.props;

    return (
      <FormBackground>
        <FormRow
          label={translations.sms.type}
          input={fakeInput}
        >
          <Input
            type="text"
            value={staticSmsData.typeTranslation}
            disabled
          />
        </FormRow>

        <FormRow
          label={translations.sms.language}
          input={fakeInput}
        >
          <Input
            type="text"
            value={staticSmsData.languageCode}
            disabled
          />
        </FormRow>

        <FormRow
          label={translations.sms.placeholders}
          input={fakeInput}
        >
          <InputSelect
            value={null}
            options={staticSmsData.placeholders}
            onChange={this.handlePlaceholderInsert}
            getOptionLabel={(option) => `${option.placeholderTranslation} (${option.placeholder})`}
            getOptionValue={(option) => option.id}
            placeholder={translations.sms.insertPlaceholder}
            onBlur={this.handleSelectBlur}
          />
        </FormRow>

        <FormRow
          label={translations.sms.text}
          input={detailForm.form.text}
        >
          <TextArea
            id={TEXTAREA_ID}
            value={detailForm.values.text}
            onChange={(e) => onChange(detailForm.form.text.name, e.target.value)}
            status={detailForm.form.text.status}
          />
        </FormRow>

        <Mutation
          mutation={MUTATION_UPDATE_SMS_TEMPLATE}
          onCompleted={onUpdateCompleted}
          onError={onUpdateError}
        >
          {(mutate, { loading, error, }) => (
            <Fragment>
              <FormError
                isValid={detailForm.isValid}
                error={error}
              />
              <Row>
                <Col XS={12} SM={12}>
                  <Button
                    type="button"
                    shape="outline"
                    onClick={onToggle}
                  >
                    {translations.common.back}
                  </Button>
                </Col>
                <Col XS={12} SM={12} textAlign="right">
                  {resources.settings_sms && (
                    <ButtonLoader
                      type="button"
                      color="success"
                      onClick={() => onUpdate(mutate)}
                      disabled={!detailForm.isValid}
                      isLoading={loading}
                    >
                      {translations.common.update}
                    </ButtonLoader>
                  )}
                </Col>
              </Row>
            </Fragment>
          )}
        </Mutation>
      </FormBackground>
    );
  }
}


SmsUpdateView.propTypes = {
  resources: object.isRequired,
  translations: object.isRequired,

  staticSmsData: shape({
    typeTranslation: string.isRequired,
    languageCode: string.isRequired,
    placeholders: arrayOf(shape({
      id: string.isRequired,
      placeholder: string.isRequired,
      placeholderTranslation: string.isRequired,
    })).isRequired,
  }).isRequired,
  detailForm: shape({
    form: shape({
      text: shape({
        name: string.isRequired,
        status: string.isRequired,
      }).isRequired,
    }).isRequired,
    values: shape({
      text: string,
    }).isRequired,
    isValid: bool.isRequired,
  }).isRequired,

  onChange: func.isRequired,
  onToggle: func.isRequired,
  onUpdate: func.isRequired,
  onUpdateError: func.isRequired,
  onUpdateCompleted: func.isRequired,
};


export default SmsUpdateView;
