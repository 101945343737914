import React from 'react';
import { string, } from 'prop-types';


const SideBarSubTitle = ({ title, }) => (
  <div className="sidebar--subtitle">
    {title}
  </div>
);


SideBarSubTitle.propTypes = {
  title: string.isRequired,
};

export default SideBarSubTitle;
