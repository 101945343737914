import React from 'react';


/**
 * Error Manager Context
 */
const Context = React.createContext();


export default Context;
