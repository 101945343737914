import React, { Component, } from 'react';
import { func, arrayOf, } from 'prop-types';
import styled, { withTheme, } from 'styled-components';

import { parseTimeToHuman, } from '../../../../logic/date';
import Table from '../../../../atoms/Table/Table';
import ButtonIcon from '../../../../atoms/Button/ButtonIcon';
import TableNoData from '../../../../atoms/Table/TableNoData';
import Bin from '../../../../styles/icons/Bin';
import { PlatformBlock, } from './propTypes';
import { SUBSCRIPTION_ADD_PLATFORM_BLOCK, } from '../../gql/subscriptions';


const COL_COUNT = 4;

const HeaderRow = () => (
  <thead>
    <tr className="header-row">
      <th className="col-platforms">
        Rampa
      </th>

      <th className="col-time">
        Čas blokace
      </th>

      <th className="col-time">
        Opakovaná
      </th>

      <th className="col-note">
        Poznámka
      </th>

      <th className="col-delete">
        Akce
      </th>
    </tr>
  </thead>
);

const Rows = ({
  // data
  platformBlocks,
  // methods
  onDeletePlatformBlock,
}) => {
  if (platformBlocks.length < 1) {
    return (
      <TableNoData
        colsCount={COL_COUNT}
        // TRANSLATION
        text="Žádné blokace na daný den"
      />
    );
  }

  return (
    <tbody>
      {platformBlocks.map((item) => (
        <tr key={item.id}>
          <td className="col-platform">
            {item.platformName}
          </td>

          <td className="col-time">
            {parseTimeToHuman(item.from)}
            -
            {parseTimeToHuman(item.to)}
          </td>

          <td className="col-time">
            {item.groupId ? 'Ano' : 'Ne'}
          </td>

          <td className="col-note">
            {item.note || ''}
          </td>

          <td className="col-delete">
            <ButtonIcon
              size="sm"
              color="error"
              onClick={() => onDeletePlatformBlock(item.id, item.definitionId, item.groupId)}
            >
              <Bin />
            </ButtonIcon>
          </td>
        </tr>
      ))}
    </tbody>
  );
};

Rows.propTypes = {
  platformBlocks: arrayOf(PlatformBlock.isRequired).isRequired,
  onDeletePlatformBlock: func.isRequired,
};


const StyledBlockingTable = styled.div`
  table {
    border-bottom: 1px solid ${(p) => p.theme.grey.t200};

    .header-row {
      height: 2rem;
      background-color: ${(p) => p.theme.grey.t200};
    }
    
    .col-platform {
      width: 16rem;
    }

    .col-time {
      width: 10rem;
    }

    .col-delete {
      width: 5rem;
    }
  }
`;

class SubscribeComponent extends Component {
  static propTypes = {
    subscribeToAddBlock: func.isRequired,
  }

  constructor(props) {
    super(props);

    this.subscribeToAddBlock = null;
  }


  componentDidMount() {
    const { subscribeToAddBlock, } = this.props;

    this.subscribeToAddBlock = subscribeToAddBlock();
  }


  componentWillUnmount() {
    if (this.subscribeToAddBlock !== null) {
      this.subscribeToAddBlock();
      this.subscribeToAddBlock = null;
    }
  }


  render() {
    return null;
  }
}

const BlockingTable = ({
  // data
  platformBlocks,
  // methods
  refetchPlatformBlocks,
  subscribeToMore,
  onDeletePlatformBlock,
}) => (
  <StyledBlockingTable>
    <SubscribeComponent
      subscribeToAddBlock={() => subscribeToMore({
        document: SUBSCRIPTION_ADD_PLATFORM_BLOCK,
        variables: { },
        updateQuery: (prev) => {
          refetchPlatformBlocks();
          return prev;
        },
      })}
    />
    <Table fillContent>
      <HeaderRow />
      <Rows
        // data
        platformBlocks={platformBlocks}
        // methods
        onDeletePlatformBlock={onDeletePlatformBlock}
      />
    </Table>
  </StyledBlockingTable>
);

BlockingTable.propTypes = {
  platformBlocks: arrayOf(PlatformBlock.isRequired).isRequired,
  onDeletePlatformBlock: func.isRequired,
  subscribeToMore: func.isRequired,
  refetchPlatformBlocks: func.isRequired,
};


export default withTheme(BlockingTable);
