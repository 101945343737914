// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
import React, { useCallback, useEffect, useState, } from 'react';

import ReportAssignBox from './ReportAssignBox';
import Button from '../../../atoms/Button/Button';
import FillNameModal from './FillNameModal';
import ReportNotificationGroupInterval from './ReportNotificationGroupInterval';
import ReportNotificationGroupParameters from './ReportNotificationGroupParameters';
import Card from '../../../atoms/Card/Card';


const getLabelById = (id, list, numericEqual) => {
  const item = list.find((item) => (numericEqual ? parseInt(item.id, 10) === parseInt(id, 10) : id === item.id));
  return item ? item.label : id.toString();
};

const ReportNotificationGroupDetail = (props) => {
  const [ modalVisible, setModalVisible, ] = useState(false);

  const [ report, setReport, ] = useState();
  const [ selectedSendInterval, setSelectedSendInterval, ] = useState('day');
  const [ selectedSendIntervalDay, setSelectedSendIntervalDay, ] = useState(0);
  const [ selectedSendIntervalHour, setSelectedSendIntervalHour, ] = useState(9);
  const [ selectedCompareInterval, setSelectedCompareInterval, ] = useState('day');
  const [ parameters, setParameters, ] = useState([]);

  const [ availableUsers, setAvailableUsers, ] = useState([]);
  const [ availableTransportationTypes, setAvailableTransportationTypes, ] = useState([]);

  const [ assignedUsers, setAssignedUsers, ] = useState([]);
  const [ assignedTransportationTypes, setAssignedTransportationTypes, ] = useState([]);

  useEffect(() => {
    const assignedReport = props.reports.find((report) => parseInt(report.id, 10) === props.reportNotificationGroup.reportId);
    const assignedParameters = assignedReport.parameters
      .filter((parameter) => parameter.type === 'integer')
      .map((parameter) => {
        const reportNotificationGroupParameter = props.reportNotificationGroup.parameters
          .find((rParameter) => rParameter.id === parameter.id);

        return ({ id: parameter.id, value: reportNotificationGroupParameter ? reportNotificationGroupParameter.value : parameter.defaultValue, });
      });

    setReport(assignedReport);
    setParameters(assignedParameters);
    setSelectedSendInterval(props.reportNotificationGroup.sendInterval ?? 'week');
    setSelectedSendIntervalDay(props.reportNotificationGroup.sendIntervalDay ?? 0);
    setSelectedSendIntervalHour(props.reportNotificationGroup.sendIntervalHour ?? 9);
    setSelectedCompareInterval(props.reportNotificationGroup.compareInterval ?? 'week');

    const userItems = props.users.filter((u) => u.email).map((u) => ({ id: u.email, label: u.email, }));
    const transportationTypeItems = props.transportationTypes.map((tt) => ({ id: tt.id, label: tt.name, }));

    const assignedUsersList = props.reportNotificationGroup.userEmails.map((u) => ({ id: u, label: getLabelById(u, userItems), }));
    const assignedTransportationTypesList = props.reportNotificationGroup.transportationTypeIds.map((tt) => ({ id: tt, label: getLabelById(tt, transportationTypeItems, true), }));

    setAvailableUsers(userItems.filter((u) => !props.reportNotificationGroup.userEmails.includes(u.id)));
    setAssignedUsers(assignedUsersList);

    setAvailableTransportationTypes(transportationTypeItems.filter((tt) => !props.reportNotificationGroup.transportationTypeIds.includes(parseInt(tt.id, 10))));
    setAssignedTransportationTypes(assignedTransportationTypesList);
  }, [ props.reportNotificationGroup, ]);

  const assignUserItem = useCallback((item) => {
    setAssignedUsers([ ...assignedUsers, item, ]);
    setAvailableUsers(availableUsers.filter((r) => r.id !== item.id));
  }, [ availableUsers, assignedUsers, ]);

  const unassignUserItem = useCallback((item) => {
    setAssignedUsers(assignedUsers.filter((r) => r.id !== item.id));
    setAvailableUsers([ ...availableUsers, item, ]);
  }, [ availableUsers, assignedUsers, ]);

  const assignTransportationTypeItem = useCallback((item) => {
    setAssignedTransportationTypes([ ...assignedTransportationTypes, item, ]);
    setAvailableTransportationTypes(availableTransportationTypes.filter((r) => r.id !== item.id));
  }, [ availableTransportationTypes, assignedTransportationTypes, ]);

  const unassignTransportationTypeItem = useCallback((item) => {
    setAssignedTransportationTypes(assignedTransportationTypes.filter((r) => r.id !== item.id));
    setAvailableTransportationTypes([ ...availableTransportationTypes, item, ]);
  }, [ availableTransportationTypes, assignedTransportationTypes, ]);

  const addCustomUserEmail = useCallback((userEmail) => {
    if (!userEmail) {
      return;
    }
    setAssignedUsers([ ...assignedUsers, { id: userEmail, label: userEmail, }, ]);
    setModalVisible(false);
  });

  const updateParameter = useCallback((parameterId, parameterValue) => {
    setParameters(parameters.filter((p) => p.id !== parameterId).concat([ { id: parameterId, value: parameterValue, }, ]));
  });

  const save = useCallback(() => {
    props.onUpdate(props.reportNotificationGroup.id, {
      name: props.reportNotificationGroup.name,
      reportId: props.reportNotificationGroup.reportId,
      transportationTypeIds: assignedTransportationTypes.map((tt) => parseInt(tt.id, 10)),
      userEmails: assignedUsers.map((u) => u.id),
      sendInterval: selectedSendInterval,
      sendIntervalDay: selectedSendInterval === 'week' ? parseInt(selectedSendIntervalDay, 10) : null,
      sendIntervalHour: selectedSendIntervalHour,
      compareInterval: selectedCompareInterval,
      parameters,
    });
  }, [ assignedTransportationTypes, assignedUsers, selectedSendInterval, selectedSendIntervalDay, selectedSendIntervalHour, selectedCompareInterval, parameters, ]);

  return (
    <>
      {modalVisible && (
        <FillNameModal
          title="Zadejte email"
          inputLabel="Email"
          onClose={() => { setModalVisible(false); }}
          onCreate={addCustomUserEmail}
        />
      )}

      <div className="report-user-group-detail">
        <h1>{props.reportNotificationGroup.name}</h1>
        {report && (
          <h2>Report: {report.name}</h2>
        )}
        <div className="report-user-group-detail--boxes">
          <Card className="report-user-group-detail--box">
            <ReportAssignBox
              title="Nastavení přístupů pro uživatele"
              addCustomEnabled
              addCustom={() => { setModalVisible(true); }}
              assignedItems={assignedUsers}
              availableItems={availableUsers}
              onAssign={assignUserItem}
              onUnassign={unassignUserItem}
            />
          </Card>
          <Card className="report-user-group-detail--box">
            <ReportAssignBox
              title="Nastavení přístupů ke službám"
              assignedItems={assignedTransportationTypes}
              availableItems={availableTransportationTypes}
              onAssign={assignTransportationTypeItem}
              onUnassign={unassignTransportationTypeItem}
            />
          </Card>
          <Card className="report-user-group-detail--box">
            <ReportNotificationGroupParameters
              title="Nastavení parameterů reportu"
              report={report}
              compareInterval={selectedCompareInterval}
              parameters={parameters}
              onUpdateParameter={updateParameter}
              onUpdateCompareInterval={(val) => setSelectedCompareInterval(val)}
            />
          </Card>
          <Card className="report-user-group-detail--box">
            <ReportNotificationGroupInterval
              title="Nastavení periody odesílání"
              sendInterval={selectedSendInterval}
              sendIntervalDay={selectedSendIntervalDay}
              sendIntervalHour={selectedSendIntervalHour}
              onUpdateSendInterval={(val) => setSelectedSendInterval(val)}
              onUpdateSendIntervalDay={(val) => setSelectedSendIntervalDay(val)}
              onUpdateSendIntervalHour={(val) => setSelectedSendIntervalHour(val)}
            />
          </Card>
        </div>
        <div>
          <Button
            color="success"
            type="button"
            onClick={save}
          >
            Uložit
          </Button>
        </div>
      </div>
    </>
  );
};


export default ReportNotificationGroupDetail;
