import gql from 'graphql-tag';


/**
 * Companies
 */
export const MUTATION_CREATE_COMPANY = gql`
  mutation CreateCompany($name: String!, $email: String!, $phoneNumber: String!, $identificationNumber: String!, $vatNumber: String!, $street: String!, $city: String!, $zip: String!, $state: String!, $info: String!, $contactPerson: String!) {
    createCompany(name: $name, email: $email, phoneNumber: $phoneNumber, identificationNumber: $identificationNumber, vatNumber: $vatNumber, street: $street, city: $city, zip: $zip, state: $state, info: $info, contactPerson: $contactPerson) {
      id
    } 
  }
`;


export const MUTATION_UPDATE_COMPANY = gql`
  mutation UpdateCompany($id: ID!, $name: String!, $email: String!, $phoneNumber: String!, $identificationNumber: String!, $vatNumber: String!, $street: String!, $city: String!, $zip: String!, $state: String!, $info: String!, $contactPerson: String!) {
    updateCompany(id: $id, name: $name, email: $email, phoneNumber: $phoneNumber, identificationNumber: $identificationNumber, vatNumber: $vatNumber, street: $street, city: $city, zip: $zip, state: $state, info: $info, contactPerson: $contactPerson) {
      id
    } 
  }
`;


export const MUTATION_DELETE_COMPANY = gql`
  mutation DeleteCompany($id: ID!) {
    deleteCompany(id: $id)
  }
`;


/**
 * Drivers
 */
export const MUTATION_CREATE_DRIVER = gql`
  mutation CreateDriver($name: String!, $phoneNumber: String!, $info: String!, $email: String, $identityDocumentNumber: String, $travelDocumentNumber: String, $identityDocumentNumberChecked: Boolean, $travelDocumentNumberChecked: Boolean, $oshTimeEnd: DateTime, $onBlacklist: Boolean) {
    createDriver(name: $name, phoneNumber: $phoneNumber, info: $info, email: $email, identityDocumentNumber: $identityDocumentNumber, travelDocumentNumber: $travelDocumentNumber, identityDocumentNumberChecked: $identityDocumentNumberChecked, travelDocumentNumberChecked: $travelDocumentNumberChecked, oshTimeEnd: $oshTimeEnd, onBlacklist: $onBlacklist) {
      id
    } 
  }
`;


export const MUTATION_UPDATE_DRIVER = gql`
  mutation UpdateDriver($id: ID!, $name: String!, $phoneNumber: String!, $info: String!, $email: String, $identityDocumentNumber: String, $travelDocumentNumber: String, $identityDocumentNumberChecked: Boolean, $travelDocumentNumberChecked: Boolean, $oshTimeEnd: DateTime, $onBlacklist: Boolean) {
    updateDriver(id: $id, name: $name, phoneNumber: $phoneNumber, info: $info, email: $email, identityDocumentNumber: $identityDocumentNumber, travelDocumentNumber: $travelDocumentNumber, identityDocumentNumberChecked: $identityDocumentNumberChecked, travelDocumentNumberChecked: $travelDocumentNumberChecked, oshTimeEnd: $oshTimeEnd, onBlacklist: $onBlacklist) {
      id
    } 
  }
`;


export const MUTATION_DELETE_DRIVER = gql`
  mutation DeleteDriver($id: ID!) {
    deleteDriver(id: $id)
  }
`;


/**
 * Trucks
 */
export const MUTATION_CREATE_TRUCK = gql`
  mutation CreateTruck($registrationNumber: String!, $vehicleGroupId: ID) {
    createTruck(registrationNumber: $registrationNumber, vehicleGroupId: $vehicleGroupId) {
      id
    } 
  }
`;


export const MUTATION_UPDATE_TRUCK = gql`
  mutation UpdateTruck($id: ID!, $registrationNumber: String!, $vehicleGroupId: ID) {
    updateTruck(id: $id, registrationNumber: $registrationNumber, vehicleGroupId: $vehicleGroupId) {
      id
    } 
  }
`;


export const MUTATION_DELETE_TRUCK = gql`
  mutation DeleteTruck($id: ID!) {
    deleteTruck(id: $id)
  }
`;


/**
 * VEHICLE GROUPS
 */
export const MUTATION_CREATE_VEHICLE_GROUP = gql`
  mutation CreateVehicleGroup($name: String!, $configuration: vehicleGroupConfigurationInputType, $languageId: ID!) {
    createVehicleGroup(name: $name, configuration: $configuration, languageId: $languageId) {
      id
    } 
  }
`;


export const MUTATION_UPDATE_VEHICLE_GROUP = gql`
  mutation UpdateVehicleGroup($id: ID!, $name: String!, $configuration: vehicleGroupConfigurationInputType, $languageId: ID!) {
    updateVehicleGroup(id: $id, name: $name, configuration: $configuration, languageId: $languageId) {
      id
    } 
  }
`;


export const MUTATION_DELETE_VEHICLE_GROUP = gql`
  mutation DeleteVehicleGroup($id: ID!) {
    deleteVehicleGroup(id: $id)
  }
`;
