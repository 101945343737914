import React, { Fragment, } from 'react';
import {
  arrayOf,
  func, object, string,
} from 'prop-types';

import FormRow from '../../../../components/Form/FormRow';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import InputSelect from '../../../../atoms/InputSelect/InputSelect';
import Input from '../../../../atoms/Input/Input';


const TechnicalCheckChamberFillingForm = ({
  data,
  formPrefixName,
  translations,
  chamberFillingForms,
  counterForm,
  counterValue,
  onChange,
}) => (
  <Fragment>
    <FormRow
      label={translations.form.chamberCount}
      input={counterForm}
    >
      <InputSelect
        value={counterValue}
        options={[ 0, 1, 2, 3, 4, ].map((n) => ({ id: n, name: n, }))}
        onChange={(option) => onChange(counterForm.name, option)}
        status={counterForm.status}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        placeholder={translations.common.select}
      />
    </FormRow>

    {chamberFillingForms.map((chamberFillingForm, index) => (
      <Row key={index}>
        <Col SM={4}>
          <FormRow
            label={translations.form.order}
            input={{ status: '', statusMsg: {}, validation: { required: false, }, }}
          >
            <Input
              type="text"
              value={index + 1}
              disabled
            />
          </FormRow>
        </Col>

        <Col SM={4}>
          <FormRow
            label={translations.form.chamber}
            input={chamberFillingForm.form.chamberSelect}
          >
            <InputSelect
              value={chamberFillingForm.values.chamberSelect}
              options={[ 0, 1, 2, 3, ].map((chamber) => ({
                id: chamber,
                name: chamber + 1,
              }))}
              onChange={(option) => onChange(`${formPrefixName}.${chamberFillingForm.form.chamberSelect.name}`, option, index)}
              status={chamberFillingForm.form.chamberSelect.status}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              placeholder={translations.common.select}
            />
          </FormRow>
        </Col>

        <Col SM={8}>
          <FormRow
            label={translations.form.product}
            input={chamberFillingForm.form.product}
          >
            <InputSelect
              value={chamberFillingForm.values.product}
              options={data.products}
              onChange={(option) => onChange(`${formPrefixName}.${chamberFillingForm.form.product.name}`, option, index)}
              status={chamberFillingForm.form.product.status}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              placeholder={translations.common.select}
            />
          </FormRow>
        </Col>

        <Col SM={8}>
          <FormRow
            label={translations.form.chamberQuantity}
            input={chamberFillingForm.form.quantity}
          >
            <Input
              type="text"
              value={chamberFillingForm.values.quantity}
              onChange={(e) => onChange(`${formPrefixName}.${chamberFillingForm.form.quantity.name}`, e.target.value, index)}
              status={chamberFillingForm.form.quantity.status}
            />
          </FormRow>
        </Col>

      </Row>

    ))}

  </Fragment>
);


TechnicalCheckChamberFillingForm.propTypes = {
  translations: object.isRequired,
  formPrefixName: string.isRequired,
  chamberFillingForms: arrayOf(object).isRequired,
  data: object.isRequired,
  counterForm: object.isRequired,
  counterValue: object.isRequired,
  onChange: func.isRequired,
};


export default TechnicalCheckChamberFillingForm;
