import React, { Component, } from 'react';
import {
  shape, number, func, string, object,
} from 'prop-types';
import { Query, } from 'react-apollo';

import { ORDER, } from '../../globals';


class FilteredTable extends Component {
  constructor(props) {
    super(props);

    this.filterTimer = null;

    this.state = {
      filter: props.initFilter,
      queryFilter: props.initFilter,
    };
  }


  componentWillUnmount() {
    this.clearFilterTimeout();
  }


  /* ******************* */
  /* Change Filter utils */
  /* ******************* */

  handleChangeSort = (name) => {
    const { filter, } = this.state;

    // init order
    let newOrder = ORDER.ASC;

    if (filter.sortBy === name && filter.order === ORDER.ASC) {
      newOrder = ORDER.DESC;
    }

    this.updateFilterState({
      ...filter,
      sortBy: name,
      order: newOrder,
    });
  }

  handleChangeFilter = (name, value) => {
    const { filter, } = this.state;

    // Force Update by cleaning timeout
    this.clearFilterTimeout();

    this.updateFilterState({
      ...filter,
      [name]: value,
    });
  }

  handleChangeParam = (name, value, resetTimeout = true) => {
    const { filter, } = this.state;

    this.clearFilterTimeout();
    if (resetTimeout) this.startFilterTimeout();

    this.updateFilterState({
      ...filter,
      offset: 0,
      params: {
        ...filter.params,
        [name]: value,
      },
    });
  }


  /**
   * Update Filter
   */
  updateFilterState = (filter) => {
    // timeout is not active => set variables and filter
    if (this.filterTimer === null) {
      this.setState({
        filter,
        queryFilter: filter,
      });

    // active timeout set only filter
    } else {
      this.setState({
        filter,
      });
    }
  }


  /* ******************** */
  /* Filter Timeout utils */
  /* ******************** */

  filterTimeoutDone = () => {
    const { filter, } = this.state;
    this.filterTimer = null;

    this.updateFilterState(filter);
  }

  startFilterTimeout = () => {
    this.clearFilterTimeout();

    this.filterTimer = setTimeout(this.filterTimeoutDone, 750);
  }

  clearFilterTimeout = () => {
    if (this.filterTimer !== null) {
      clearTimeout(this.filterTimer);
      this.filterTimer = null;
    }
  }


  render() {
    const { queryFilter, filter, } = this.state;
    const { query, children, filterToVariables, } = this.props;

    return (
      <Query
        query={query}
        variables={filterToVariables(queryFilter)}
        fetchPolicy="no-cache"
      >
        {(queryData) => children({
          queryData,
          filter,
          onChangeSort: this.handleChangeSort,
          onChangeFilter: this.handleChangeFilter,
          onChangeParam: this.handleChangeParam,
        })}
      </Query>
    );
  }
}


FilteredTable.propTypes = {
  query: object.isRequired,
  initFilter: shape({
    offset: number.isRequired,
    limit: number.isRequired,
    sortBy: string.isRequired,
    order: string.isRequired,
    params: object,
  }).isRequired,
  filterToVariables: func,
  children: func.isRequired,
};


FilteredTable.defaultProps = {
  filterToVariables: (filter) => (filter),
};


export default FilteredTable;
