import React, { Component, } from 'react';
import { func, object, } from 'prop-types';

import { withNotifications, } from '../../../../logic/notifications/withNotifications';
import {
  changeAndValidateInput,
  validateAndMergeWholeForm,
  mergeValidationObjectIntoForm,
} from '../../../../logic/form/common';
import { initVisitorForm, } from './utils';
import VisitorCreateView from './VisitorCreateView';


class VisitorCreateLogic extends Component {
  constructor(props) {
    super(props);

    this.state = {
      createForm: initVisitorForm,
    };
  }


  handleChangeTransportationForm = (name, value) => {
    const { createForm, } = this.state;

    this.setState({
      createForm: changeAndValidateInput(createForm, name, value),
    });
  }


  handleCreate = (createMutation) => {
    const { createForm, createForm: { values, }, } = this.state;

    // validate create form
    const newCreateForm = validateAndMergeWholeForm(createForm);

    if (!newCreateForm.isValid) {
      this.setState({
        createForm: newCreateForm,
      });

    // if valid - create
    } else {
      const variables = {
        name: values.name,
        licensePlate: values.licensePlate,
        notice: values.notice,
        active: true,
      };

      createMutation({
        variables,
      });
    }
  }


  handleCreateComplete = () => {
    const {
      translations, onToggle, addNotification,
    } = this.props;

    onToggle();
    addNotification({
      status: 'success',
      title: translations.common.created,
    });
  }


  handleCreateError = (mutationError) => {
    try {
      const { createForm, } = this.state;

      const { graphQLErrors, } = mutationError;

      if (graphQLErrors && graphQLErrors.length > 0) {
        const { message, extensions, } = graphQLErrors[0];

        switch (message) {
          case 'UNPROCESSABLE_ENTITY': {
            if (extensions.exception.data) {
              this.setState({
                createForm: mergeValidationObjectIntoForm(createForm, extensions.exception.data),
              });
            }
            break;
          }

          default: {
            break;
          }
        }
      }
    } catch (err) {
      // continue regardless of error
    }
  }


  render() {
    const { createForm, } = this.state;
    const { translations, onToggle, } = this.props;

    return (
      <VisitorCreateView
        // data
        createForm={createForm}
        translations={translations}
        // methods
        onChange={this.handleChangeTransportationForm}
        onToggle={onToggle}
        onCreate={this.handleCreate}
        onCreateComplete={this.handleCreateComplete}
        onCreateError={this.handleCreateError}
      />
    );
  }
}


VisitorCreateLogic.propTypes = {
  // data
  translations: object.isRequired,
  // methods
  onToggle: func.isRequired,
  addNotification: func.isRequired,
};


export default withNotifications(VisitorCreateLogic);
