import React from 'react';
import {
  func, shape, bool, object,
} from 'prop-types';

import { TRANSLATION_VISITOR, } from '../../gql/translations';
import { withTranslations, } from '../../../../logic/translations/withTranslations';
import Modal from '../../../../atoms/Modal/Modal';
import VisitorCreateLogic from './VisitorCreateLogic';


const VisitorCreate = ({
  // data
  modalData: {
    isOpen,
  },
  translations,
  // methods
  onToggle,
}) => (
  isOpen ? (
    <Modal
      title={translations.visitor.createModalVisitorTitle}
      isOpen
      size="SM"
      onClose={onToggle}
      disablePadding
    >
      <VisitorCreateLogic
        // data
        translations={translations}
        // methods
        onToggle={onToggle}
      />
    </Modal>
  ) : null
);


VisitorCreate.propTypes = {
  translations: object.isRequired,
  modalData: shape({
    isOpen: bool.isRequired,
  }).isRequired,
  onToggle: func.isRequired,
};


export default withTranslations(TRANSLATION_VISITOR)(VisitorCreate);
