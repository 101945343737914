import React, { Component, } from 'react';
import {
  arrayOf, bool, func, number, object,
} from 'prop-types';
import styled from 'styled-components';

import InputSelect from '../../../../atoms/InputSelect/InputSelect';
import { computeAvailableTimes, formattedTimeToMinutes, minutesToFormattedTime, } from './utils';
import Button from '../../../../atoms/Button/Button';
import Loader from '../../../../atoms/Loader/Loader';
import Input from '../../../../atoms/Input/Input';
import InputDateSelect from '../../../../atoms/InputDateSelect/InputDateSelect';
import { Platform, } from './propTypes';
import { FORM_DEFAULT, FORM_ERROR, } from '../../../../globals';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';


const StyledBlockingForm = styled.div`
  .title {
    font-weight: bold;
  }
  
  .row {
    margin: 20px 0;
  }
  
  .title-offset {
    padding-top: 20px;
    text-align: right;
  }
`;


const REPEAT_TYPES = [
  { id: 'daily', name: 'Denně', },
  { id: 'weekly', name: 'Týdně', },
  { id: 'monthly', name: 'Měsíčně', },
];

const WEEKLY_DAYS = [
  { id: 1, name: 'Pondělí', },
  { id: 2, name: 'Úterý', },
  { id: 3, name: 'Středa', },
  { id: 4, name: 'Čtvrtek', },
  { id: 5, name: 'Pátek', },
  { id: 6, name: 'Sobota', },
  { id: 0, name: 'Neděle', },
];

const MONTHLY_DAYS = [ ...Array(31).keys(), ].map((i) => ({ id: i + 1, name: `${i + 1}.`, }));


class BlockingForm extends Component {
  constructor(props) {
    super(props);

    const { timeStepInMinutes, } = this.props;

    const availableTimes = computeAvailableTimes('00:00', '24:00', timeStepInMinutes);

    this.state = {
      useFrom: new Date(),
      useTo: new Date(),
      repeatType: { id: 'daily', name: 'Denně', }, // daily, weekly, monthly
      selectedDays: [], // weekdays for weekly, dates for monthly
      selectedPlatforms: [],
      availableTimes,
      availableTimesForStart: availableTimes.slice(0, -1),
      availableTimesForEnd: availableTimes.slice(1),
      selectedStart: availableTimes[0],
      selectedEnd: availableTimes[availableTimes.length - 1],
      note: '',
      loading: false,
    };
  }

  handleChangeUseFrom = (useFrom) => {
    this.setState({ useFrom, });
  }

  handleChangeUseTo = (useTo) => {
    this.setState({ useTo, });
  }

  handleChangeRepeatType = (repeatType) => {
    this.setState({ repeatType, selectedDays: [], });
  }

  handleChangePlatforms = (selectedPlatforms) => {
    this.setState({ selectedPlatforms, });

    this.recomputeTimes(selectedPlatforms);
  }

  handleAddAllPlatforms = () => {
    const { platforms, } = this.props;

    this.setState({ selectedPlatforms: platforms, });

    this.recomputeTimes(platforms);
  }

  recomputeTimes = (selectedPlatforms) => {
    const { timeStepInMinutes, definitionMode, } = this.props;

    if (definitionMode || selectedPlatforms === null || selectedPlatforms.length === 0) {
      return;
    }

    const min = Math.min(...selectedPlatforms.map((p) => formattedTimeToMinutes(p.start)));
    const max = Math.max(...selectedPlatforms.map((p) => formattedTimeToMinutes(p.end)));

    const availableTimes = computeAvailableTimes(
      minutesToFormattedTime(min), minutesToFormattedTime(max), timeStepInMinutes,
    );

    // eslint-disable-next-line react/no-did-update-set-state
    this.setState({
      availableTimes,
      availableTimesForStart: availableTimes.slice(0, -1),
      availableTimesForEnd: availableTimes.slice(1),
      selectedStart: availableTimes[0],
      selectedEnd: availableTimes[availableTimes.length - 1],
    });
  }

  handleChangeSelectedDays = (selectedDays) => {
    this.setState({ selectedDays, });
  }

  handleChangeStart = (start) => {
    const { availableTimes, selectedEnd, } = this.state;

    this.setState({
      selectedStart: start,
      availableTimesForEnd: availableTimes.filter((at) => at.id > start.id),
    });

    if (selectedEnd.id <= start.id) {
      this.setState({
        selectedEnd: availableTimes[availableTimes.length - 1],
      });
    }
  }

  handleChangeEnd = (end) => {
    this.setState({ selectedEnd: end, });
  }

  handleChangeNote = (note) => {
    this.setState({ note, });
  }

  handleSubmitClick = async () => {
    const { onSubmit, } = this.props;
    const {
      useFrom, useTo, repeatType,
      selectedDays, selectedPlatforms,
      selectedStart, selectedEnd, note,
    } = this.state;

    this.setState({ loading: true, });

    await onSubmit({
      useFrom,
      useTo,
      type: repeatType.id,
      selectedDays: selectedDays.map((sd) => sd.id),
      platformIds: selectedPlatforms.map((p) => p.id),
      timeFrom: selectedStart.time,
      timeTo: selectedEnd.time,
      note,
    });

    this.setState({ loading: false, });
  };

  render() {
    const {
      platforms, translations,
    } = this.props;
    const {
      useFrom, useTo, repeatType, selectedDays,
      availableTimesForStart, availableTimesForEnd,
      selectedPlatforms, selectedStart, selectedEnd, note,
      loading,
    } = this.state;

    return (
      <StyledBlockingForm>
        <Row className="row">
          <Col MD={12}>
            <span className="title">
              Blokovat v termínu od
            </span>
            <InputDateSelect
              value={useFrom}
              onChange={this.handleChangeUseFrom}
            />
          </Col>
          <Col MD={12}>
            <span className="title">
              Blokovat v termínu do
            </span>
            <InputDateSelect
              value={useTo}
              onChange={this.handleChangeUseTo}
              status={(useFrom > useTo) ? FORM_ERROR : FORM_DEFAULT}
            />
          </Col>
        </Row>

        <Row className="row">
          <Col MD={21}>
            <span className="title">
              Výběr pozic
            </span>
            <InputSelect
              value={selectedPlatforms}
              options={platforms}
              onChange={this.handleChangePlatforms}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              size="sm"
              isMulti
              status={(selectedPlatforms && selectedPlatforms.length > 0) ? FORM_DEFAULT : FORM_ERROR}
            />
          </Col>
          <Col MD={3}>
            <div className="title-offset">
              <Button
                onClick={this.handleAddAllPlatforms}
                color="tertiary"
                size="sm"
              >
                Přidat všechny
              </Button>
            </div>
          </Col>
        </Row>

        <Row className="row">
          <Col MD={24}>
            <span className="title">
              Způsob opakování blokace
            </span>
            <InputSelect
              value={repeatType}
              options={REPEAT_TYPES}
              onChange={this.handleChangeRepeatType}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              size="sm"
            />
          </Col>
        </Row>

        {repeatType.id !== 'daily' && (
          <Row className="row">
            <Col MD={24}>
              <span className="title">
                {repeatType.id === 'weekly' ? 'Výběr dní pro týdenní opakování' : 'Výběr dat pro měsíční opakování'}
              </span>
              <InputSelect
                value={selectedDays}
                options={repeatType.id === 'weekly' ? WEEKLY_DAYS : MONTHLY_DAYS}
                onChange={this.handleChangeSelectedDays}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                size="sm"
                isMulti
                status={(selectedDays && selectedDays.length > 0) ? FORM_DEFAULT : FORM_ERROR}
              />
            </Col>
          </Row>
        )}

        <Row className="row">
          <Col MD={12}>
            <span className="title">
              {translations.rs.platformBlocks.blockFrom}
            </span>
            <InputSelect
              value={selectedStart}
              options={availableTimesForStart}
              onChange={this.handleChangeStart}
              getOptionLabel={(option) => option.time}
              getOptionValue={(option) => option.id}
              size="sm"
            />
          </Col>

          <Col MD={12}>
            <span className="title">
              {translations.rs.platformBlocks.blockTo}
            </span>
            <InputSelect
              value={selectedEnd}
              options={availableTimesForEnd}
              onChange={this.handleChangeEnd}
              getOptionLabel={(option) => option.time}
              getOptionValue={(option) => option.id}
              size="sm"
            />
          </Col>
        </Row>

        <Row className="row">
          <Col MD={21}>
            <span className="title">
              {translations.common.note}
            </span>
            <Input
              type="text"
              maxLength={250}
              value={note}
              onChange={(e) => this.handleChangeNote(e.target.value)}
            />
          </Col>
          <Col MD={3}>
            <div className="title-offset">
              <Button
                onClick={this.handleSubmitClick}
                color="success"
                size="md"
                disabled={
                  selectedStart > selectedEnd
                  || (repeatType.id !== 'daily' && (!selectedDays || selectedDays.length === 0))
                  || !selectedPlatforms || selectedPlatforms.length === 0
                }
              >
                {loading ? <Loader /> : (translations.rs.platformBlocks.blockButtonText)}
              </Button>
            </div>
          </Col>
        </Row>

      </StyledBlockingForm>
    );
  }
}

BlockingForm.propTypes = {
  platforms: arrayOf(Platform.isRequired).isRequired,
  timeStepInMinutes: number.isRequired,
  onSubmit: func.isRequired,
  translations: object.isRequired,
  definitionMode: bool.isRequired,
};

export default BlockingForm;
