import styled from 'styled-components';


const StyledLoginView = styled.div`
  
  background-image: url("../../../../../../images/login.jpg");
  background-repeat: no-repeat;
  background-size: cover;

  .login {
    min-height: 100vh;
    background-color: rgba(0,0,0,0.65);
  }

  .login--panel {
    padding: 2.5rem;
    background: ${(p) => p.theme.white};
    border-radius: ${(p) => p.theme.input.borderRadius}; 
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.06);
  }


  .login--title {
    font-size: 4.5rem;
    margin-bottom: 1rem;
    margin-left: -0.5rem;
    font-weight: bold;
    height: 2rem;
    display: flex;
    align-items: center;
  }

  .login--title-text {
    margin-left: 0.5rem;
  }


  .login--info {
    margin-bottom: 1.5rem;
  }

  .login--info-title {
    font-size: 1rem;
    font-weight: 500;
  }

  .login--info-text {
    font-weight: 300;
    color: ${(p) => p.theme.grey.t600};
  }


  .login--inputWrapper {
    margin-bottom: 1.5rem;
  }


  .login--msg-error {
    color: ${(p) => p.theme.error.t700};
  }


  .login--btn-login {
    width: 100%;
  }
`;


export default StyledLoginView;
