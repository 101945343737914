import React from 'react';
import { shape, string, object, } from 'prop-types';

import FormRowReadOnly from '../../../../components/Form/FormRowReadOnly';
import ValueText from '../../../../atoms/Values/ValueText';
import { parseDateToHuman, } from '../../../../logic/date';


const RecordBasicReadonly = ({ values, translations, }) => (
  <>
    <FormRowReadOnly label={translations.form.personName}>
      <ValueText>{values.driverName ? values.driverName : '-'}</ValueText>
    </FormRowReadOnly>

    <FormRowReadOnly label={translations.form.phoneNumber}>
      <ValueText>{values.driverPhoneNumber ? values.driverPhoneNumber : '-'}</ValueText>
    </FormRowReadOnly>

    <FormRowReadOnly label={translations.form.transportationType}>
      <ValueText>{(values.gateVehicleGroupId ? values.gateVehicleGroupId.name : values.transportationTypeName) || '-'}</ValueText>
    </FormRowReadOnly>

    <FormRowReadOnly label={translations.form.reservationNumber}>
      <ValueText>{values.transportationNo ? values.transportationNo : '-'}</ValueText>
    </FormRowReadOnly>

    <FormRowReadOnly label={translations.form.registrationNumber}>
      <ValueText>{values.registrationNumber ? values.registrationNumber : '-'}</ValueText>
    </FormRowReadOnly>

    <FormRowReadOnly label={translations.form.trailerRegistrationNo}>
      <ValueText>{values.trailerRegistrationNumber ? values.trailerRegistrationNumber : '-'}</ValueText>
    </FormRowReadOnly>

    <FormRowReadOnly label={translations.form.time}>
      <ValueText>{parseDateToHuman(values.time)}</ValueText>
    </FormRowReadOnly>
  </>
);


RecordBasicReadonly.propTypes = {
  translations: object.isRequired,
  values: shape({
    driverName: string,
    driverPhoneNumber: string,
    gateVehicleGroupId: object,
    registrationNumber: string,
    time: object,
  }).isRequired,
};


export default RecordBasicReadonly;
