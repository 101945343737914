import React, { Fragment, } from 'react';
import {
  func, object,
} from 'prop-types';

import InputCheckbox from '../../../../atoms/InputCheckbox/InputCheckbox';
import { vehicleMarkingsFields, } from '../InputTechnicalCheck/utils';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';

const TechnicalCheckVehicleMarkingsForm = ({
  form,
  translations,
  values,
  onChange,
}) => (
  <Fragment>
    {vehicleMarkingsFields.map((field, index) => (
      <Row key={index}>
        <Col SM={24}>
          <InputCheckbox
            value={values[field]}
            onChange={(newValue) => onChange(form[field].name, newValue)}
            status={form[field].status}
            text={translations.form.adr.vehicleMarkings[field]}
          />
        </Col>
      </Row>
    ))}

  </Fragment>
);


TechnicalCheckVehicleMarkingsForm.propTypes = {
  translations: object.isRequired,
  form: object.isRequired,
  values: object.isRequired,
  onChange: func.isRequired,
};


export default TechnicalCheckVehicleMarkingsForm;
