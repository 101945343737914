
export const imageToBase64 = async (file) => new Promise((resolve) => {
  let baseURL = '';
  const reader = new FileReader();

  reader.readAsDataURL(file);

  reader.onload = () => {
    baseURL = reader.result;
    resolve(baseURL);
  };
});
