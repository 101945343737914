import React, { Component, Fragment, } from 'react';
import { string, shape, object, } from 'prop-types';

import ButtonIcon from '../../../../atoms/Button/ButtonIcon';
import Collapse from '../../../../atoms/Collapse/Collapse';
import FormBackground from '../../../../components/Form/FormBackground';
import FlowChartGenForm from './FlowChartGenForm';

import Arrow from '../../../../styles/icons/Arrow';
import FlowChartTechnicalCheckAction from './FlowChartTechnicalCheckAction';


const actionsWithCustomForm = [ 'inputTechnicalCheck', 'outputTechnicalCheck', ];

class FlowChartAction extends Component {
  state = {
    isOpen: false,
  };


  handleToggle = () => {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen, }));
  }


  render() {
    const { isOpen, } = this.state;
    const {
      data: {
        title,
        genericFormId,
        actionId,
        action,
        start,
      },
      transportationId,
      translations,
      languageId,
      transportation,
    } = this.props;

    if (!genericFormId && !actionsWithCustomForm.includes(action)) {
      return (
        <h5>
          {title}
        </h5>
      );
    }

    return (
      <Fragment>
        <h5>
          {title}
          <ButtonIcon
            className="transpDetail--flow-right-toggle"
            onClick={this.handleToggle}
            size="sm"
            thin
            color="tertiary"
            disabled={!start}
          >
            <Arrow className={`transpDetail--flow-right-icon ${isOpen ? ' transpDetail--flow-right-icon-open' : 'transpDetail--flow-right-icon-close'}`} />
          </ButtonIcon>
        </h5>
        <Collapse isOpen={isOpen}>
          {isOpen && (action === 'inputTechnicalCheck' || action === 'outputTechnicalCheck') && (
            <FlowChartTechnicalCheckAction
              transportation={transportation}
              translations={translations}
              action={action}
            />
          )}
          {isOpen && !actionsWithCustomForm.includes(action) && (
            <FormBackground>
              <FlowChartGenForm
                genericFormId={genericFormId}
                actionId={actionId}
                transportationId={transportationId}
                translations={translations}
                languageId={languageId}
              />
            </FormBackground>
          )}
        </Collapse>
      </Fragment>
    );
  }
}


FlowChartAction.propTypes = {
  data: shape({
    actionId: string.isRequired,
    action: string.isRequired,
    genericFormId: string,
    title: string.isRequired,
    start: string,
  }).isRequired,
  translations: object.isRequired,
  transportationId: string.isRequired,
  languageId: string.isRequired,
  transportation: object.isRequired,
};


export default FlowChartAction;
