import React from 'react';
import { func, object, string, } from 'prop-types';

import Card from '../../../../atoms/Card/Card';
import HeadingWihButtons from '../../../../atoms/HeadingWihButtons/HeadingWihButtons';
import Button from '../../../../atoms/Button/Button';
import ParkingTable from './ParkingTable';


const ParkingView = ({
  // data
  resources,
  translations,
  languageId,
  createModal,
  updateModal,
  deleteModal,
  // methods
  onToggleCreate,
  onToggleUpdate,
  onToggleDelete,
}) => (
  <Card>
    <HeadingWihButtons>
      <h2>{translations.common.parkingHeader}</h2>
      <Button
        onClick={onToggleCreate}
        color="success"
        size="sm"
      >
        {translations.centralAppParking.btnAddNew}
      </Button>
    </HeadingWihButtons>

    <ParkingTable
      // data
      resources={resources}
      translations={translations}
      languageId={languageId}
      onToggleCreate={onToggleCreate}
      onToggleUpdate={onToggleUpdate}
      onToggleDelete={onToggleDelete}
      // methods
      createModal={createModal}
      updateModal={updateModal}
      deleteModal={deleteModal}
    />
  </Card>
);


ParkingView.propTypes = {
  resources: object.isRequired,
  translations: object.isRequired,
  languageId: string.isRequired,
  createModal: object.isRequired,
  updateModal: object.isRequired,
  deleteModal: object.isRequired,
  onToggleCreate: func.isRequired,
  onToggleUpdate: func.isRequired,
  onToggleDelete: func.isRequired,
};


export default ParkingView;
