import React from 'react';
import { NavLink, } from 'react-router-dom';
import {
  string, arrayOf, node, number, oneOfType,
} from 'prop-types';


const SidebarLink = ({
  to, level, children,
}) => (
  <NavLink
    exact
    className={`sidebar--item sidebar--item-level-${level}`}
    to={to}
    activeClassName="sidebar--item-active"
  >
    {children}
  </NavLink>
);


SidebarLink.propTypes = {
  to: string.isRequired,
  level: number,
  children: oneOfType([
    arrayOf(node),
    node,
  ]).isRequired,
};

SidebarLink.defaultProps = {
  level: 0,
};


export default SidebarLink;
