import styled from 'styled-components';


const StyledQueue = styled.div`
  min-width: 500px;
  width: 500px;
  padding-right: 3rem;

  .storekeeperQueue--platforms-select {
    padding-bottom: 0.75rem;
  }
`;


export default StyledQueue;
