import styled from 'styled-components';
import 'core-js/es6/number'; // eslint-disable-line

import media from '../../styles/media';


const COL_1 = 100 / 24;
const COL_2 = 100 / 12;
const COL_3 = 100 / 8;
const COL_4 = 100 / 6;
const COL_5 = 100 / (24 / 5);
const COL_6 = 100 / 4;
const COL_7 = 100 / (24 / 7);
const COL_8 = 100 / 3;
const COL_9 = 100 / (24 / 9);
const COL_10 = 100 / (24 / 10);
const COL_11 = 100 / (24 / 11);
const COL_12 = 100 / 2;
const COL_13 = 100 / (24 / 13);
const COL_14 = 100 / (24 / 14);
const COL_15 = 100 / (24 / 15);
const COL_16 = 100 / (24 / 16);
const COL_17 = 100 / (24 / 17);
const COL_18 = 100 / (24 / 18);
const COL_19 = 100 / (24 / 19);
const COL_20 = 100 / (24 / 20);
const COL_21 = 100 / (24 / 21);
const COL_22 = 100 / (24 / 22);
const COL_23 = 100 / (24 / 23);
const COL_24 = 100;

const css = {
  col: {
    true: `
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%;
    `,
    auto: `
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    `,
    1: `
      -webkit-box-flex: 0;
      -ms-flex: 0 0 ${COL_1}%;
      flex: 0 0 ${COL_1}%;
      max-width: ${COL_1}%;
    `,
    2: `
      -webkit-box-flex: 0;
      -ms-flex: 0 0 ${COL_2}%;
      flex: 0 0 ${COL_2}%;
      max-width: ${COL_2}%;
    `,
    3: `
      -webkit-box-flex: 0;
      -ms-flex: 0 0 ${COL_3}%;
      flex: 0 0 ${COL_3}%;
      max-width: ${COL_3}%;
    `,
    4: `
      -webkit-box-flex: 0;
      -ms-flex: 0 0 ${COL_4}%;
      flex: 0 0 ${COL_4}%;
      max-width: ${COL_4}%;
        `,
    5: `
      -webkit-box-flex: 0;
      -ms-flex: 0 0 ${COL_5}%;
      flex: 0 0 ${COL_5}%;
      max-width: ${COL_5}%;
        `,
    6: `
      -webkit-box-flex: 0;
      -ms-flex: 0 0 ${COL_6}%;
      flex: 0 0 ${COL_6}%;
      max-width: ${COL_6}%;
    `,
    7: `
      -webkit-box-flex: 0;
      -ms-flex: 0 0 ${COL_7}%;
      flex: 0 0 ${COL_7}%;
      max-width: ${COL_7}%;
    `,
    8: `
      -webkit-box-flex: 0;
      -ms-flex: 0 0 ${COL_8}%;
      flex: 0 0 ${COL_8}%;
      max-width: ${COL_8}%;
    `,
    9: `
      -webkit-box-flex: 0;
      -ms-flex: 0 0 ${COL_9}%;
      flex: 0 0 ${COL_9}%;
      max-width: ${COL_9}%;
    `,
    10: `
      -webkit-box-flex: 0;
      -ms-flex: 0 0 ${COL_10}%;
      flex: 0 0 ${COL_10}%;
      max-width: ${COL_10}%;
    `,
    11: `
      -webkit-box-flex: 0;
      -ms-flex: 0 0 ${COL_11}%;
      flex: 0 0 ${COL_11}%;
      max-width: ${COL_11}%;
    `,
    12: `
      -webkit-box-flex: 0;
      -ms-flex: 0 0 ${COL_12}%;
      flex: 0 0 ${COL_12}%;
      max-width: ${COL_12}%;
    `,
    13: `
      -webkit-box-flex: 0;
      -ms-flex: 0 0 ${COL_13}%;
      flex: 0 0 ${COL_13}%;
      max-width: ${COL_13}%;
    `,
    14: `
      -webkit-box-flex: 0;
      -ms-flex: 0 0 ${COL_14}%;
      flex: 0 0 ${COL_14}%;
      max-width: ${COL_14}%;
    `,
    15: `
      -webkit-box-flex: 0;
      -ms-flex: 0 0 ${COL_15}%;
      flex: 0 0 ${COL_15}%;
      max-width: ${COL_15}%;
    `,
    16: `
      -webkit-box-flex: 0;
      -ms-flex: 0 0 ${COL_16}%;
      flex: 0 0 ${COL_16}%;
      max-width: ${COL_16}%;
        `,
    17: `
      -webkit-box-flex: 0;
      -ms-flex: 0 0 ${COL_17}%;
      flex: 0 0 ${COL_17}%;
      max-width: ${COL_17}%;
        `,
    18: `
      -webkit-box-flex: 0;
      -ms-flex: 0 0 ${COL_18}%;
      flex: 0 0 ${COL_18}%;
      max-width: ${COL_18}%;
    `,
    19: `
      -webkit-box-flex: 0;
      -ms-flex: 0 0 ${COL_19}%;
      flex: 0 0 ${COL_19}%;
      max-width: ${COL_19}%;
    `,
    20: `
      -webkit-box-flex: 0;
      -ms-flex: 0 0 ${COL_20}%;
      flex: 0 0 ${COL_20}%;
      max-width: ${COL_20}%;
    `,
    21: `
      -webkit-box-flex: 0;
      -ms-flex: 0 0 ${COL_21}%;
      flex: 0 0 ${COL_21}%;
      max-width: ${COL_21}%;
    `,
    22: `
      -webkit-box-flex: 0;
      -ms-flex: 0 0 ${COL_22}%;
      flex: 0 0 ${COL_22}%;
      max-width: ${COL_22}%;
    `,
    23: `
      -webkit-box-flex: 0;
      -ms-flex: 0 0 ${COL_23}%;
      flex: 0 0 ${COL_23}%;
      max-width: ${COL_23}%;
    `,
    24: `
      -webkit-box-flex: 0;
      -ms-flex: 0 0 ${COL_24}%;
      flex: 0 0 ${COL_24}%;
      max-width: ${COL_24}%;
    `,
  },
  offset: {
    0: 'margin-left: 0;',
    1: `margin-left: ${COL_1}%;`,
    2: `margin-left: ${COL_2}%;`,
    3: `margin-left: ${COL_3}%;`,
    4: `margin-left: ${COL_4}%;`,
    5: `margin-left: ${COL_5}%;`,
    6: `margin-left: ${COL_6}%;`,
    7: `margin-left: ${COL_7}%;`,
    8: `margin-left: ${COL_8}%;`,
    9: `margin-left: ${COL_9}%;`,
    10: `margin-left: ${COL_10}%;`,
    11: `margin-left: ${COL_11}%;`,
    12: `margin-left: ${COL_12}%;`,
    13: `margin-left: ${COL_13}%;`,
    14: `margin-left: ${COL_14}%;`,
    15: `margin-left: ${COL_15}%;`,
    16: `margin-left: ${COL_16}%;`,
    17: `margin-left: ${COL_17}%;`,
    18: `margin-left: ${COL_18}%;`,
    19: `margin-left: ${COL_19}%;`,
    20: `margin-left: ${COL_20}%;`,
    21: `margin-left: ${COL_21}%;`,
    22: `margin-left: ${COL_22}%;`,
    23: `margin-left: ${COL_23}%;`,
  },
  order: {
    first: `
      -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
      order: -1;
    `,
    last: `
      -webkit-box-ordinal-group: 26;
      -ms-flex-order: 25;
      order: 25;
    `,
    0: `
      -webkit-box-ordinal-group: 1;
      -ms-flex-order: 0;
      order: 0;
    `,
    1: `
      -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
      order: 1;
    `,
    2: `
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      order: 2;
    `,
    3: `
      -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
      order: 3;
    `,
    4: `
      -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
      order: 4;
    `,
    5: `
      -webkit-box-ordinal-group: 6;
      -ms-flex-order: 5;
      order: 5;
    `,
    6: `
      -webkit-box-ordinal-group: 7;
      -ms-flex-order: 6;
      order: 6;
    `,
    7: `
      -webkit-box-ordinal-group: 8;
      -ms-flex-order: 7;
      order: 7;
    `,
    8: `
      -webkit-box-ordinal-group: 9;
      -ms-flex-order: 8;
      order: 8;
    `,
    9: `
      -webkit-box-ordinal-group: 10;
      -ms-flex-order: 9;
      order: 9;
    `,
    10: `
      -webkit-box-ordinal-group: 11;
      -ms-flex-order: 10;
      order: 10;
    `,
    11: `
      -webkit-box-ordinal-group: 12;
      -ms-flex-order: 11;
      order: 11;
    `,
    12: `
      -webkit-box-ordinal-group: 13;
      -ms-flex-order: 12;
      order: 12;
    `,
    13: `
      -webkit-box-ordinal-group: 14;
      -ms-flex-order: 13;
      order: 13;
    `,
    14: `
      -webkit-box-ordinal-group: 15;
      -ms-flex-order: 14;
      order: 14;
    `,
    15: `
      -webkit-box-ordinal-group: 16;
      -ms-flex-order: 15;
      order: 15;
    `,
    16: `
      -webkit-box-ordinal-group: 17;
      -ms-flex-order: 16;
      order: 16;
    `,
    17: `
      -webkit-box-ordinal-group: 18;
      -ms-flex-order: 17;
      order: 17;
    `,
    18: `
      -webkit-box-ordinal-group: 19;
      -ms-flex-order: 18;
      order: 18;
    `,
    19: `
      -webkit-box-ordinal-group: 20;
      -ms-flex-order: 19;
      order: 19;
    `,
    20: `
      -webkit-box-ordinal-group: 21;
      -ms-flex-order: 20;
      order: 20;
    `,
    21: `
      -webkit-box-ordinal-group: 22;
      -ms-flex-order: 21;
      order: 21;
    `,
    22: `
      -webkit-box-ordinal-group: 23;
      -ms-flex-order: 22;
      order: 22;
    `,
    23: `
      -webkit-box-ordinal-group: 24;
      -ms-flex-order: 23;
      order: 23;
    `,
    24: `
      -webkit-box-ordinal-group: 25;
      -ms-flex-order: 24;
      order: 24;
    `,
  },
  alignSelf: {
    auto: `
      -ms-flex-item-align: auto !important;
      align-self: auto !important;
    `,
    start: `
      -ms-flex-item-align: start !important;
      align-self: flex-start !important;
    `,
    end: `
      -ms-flex-item-align: end !important;
      align-self: flex-end !important;
    `,
    center: `
      -ms-flex-item-align: center !important;
      align-self: center !important;
    `,
    baseline: `
      -ms-flex-item-align: baseline !important;
      align-self: baseline !important;
    `,
    stretch: `
      -ms-flex-item-align: stretch !important;
      align-self: stretch !important;
    `,
  },
  textAlign: {
    center: 'text-align: center;',
    left: 'text-align: left',
    right: 'text-align: right;',
  },
};


const Col = styled.div`
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: ${(p) => p.theme.common.paddingHalf};
  padding-left: ${(p) => p.theme.common.paddingHalf};
  box-sizing: border-box;

  fieldset {
    margin: 0 -0.25rem 1rem -0.25rem;
  }

  /* Form Label */
  ${(p) => p.formLabel && `
    height: ${`calc(2.25em + ${p.theme.input.borderWidthTopBottom} + ${p.theme.input.borderWidthTopBottom})`};
    padding-top: 0.375em;
    padding-bottom: 0.375em;
    line-height: 1.5;
    font-style: italic;
  `}

  /* Form Value */
  ${(p) => p.formValue && `
    height: ${`calc(2.25em + ${p.theme.input.borderWidthTopBottom} + ${p.theme.input.borderWidthTopBottom})`};
    padding-top: 0.375em;
    padding-bottom: 0.375em;
    line-height: 1.5;
  `}

  /* Side Padding */
  ${(p) => p.noPadding && `
    padding-right: 0;
    padding-left: 0;
  `}

  /* All */
  ${(p) => p.col && css.col[p.col]}
  ${(p) => p.offset && css.offset[p.offset]}
  ${(p) => p.auto && css.col.auto}
  ${(p) => p.alignSelf && css.alignSelf[p.alignSelf]}
  ${(p) => p.order && css.order[p.order]}
  ${(p) => p.textAlign && css.textAlign[p.textAlign]}

  /* XS */
  ${(p) => p.XS && media.XS`${css.col[p.XS]}`}
  ${(p) => !Number.isNaN(parseInt(p.XS_offset, 10)) && media.XS`${css.offset[p.XS_offset]}`}
  ${(p) => p.XS_auto && media.XS`${css.col.auto}`}
  ${(p) => p.XS_alignSelf && media.XS`${css.alignSelf[p.XS_alignSelf]}`}
  ${(p) => p.XS_order && media.XS`${css.order[p.XS_order]}`}
  ${(p) => p.XS_textAlign && media.XS`${css.textAlign[p.XS_textAlign]}`}

  /* SM */
  ${(p) => p.SM && media.SM`${css.col[p.SM]}`}
  ${(p) => !Number.isNaN(parseInt(p.SM_offset, 10)) && media.SM`${css.offset[p.SM_offset]}`}
  ${(p) => p.SM_auto && media.SM`${css.col.auto}`}
  ${(p) => p.SM_alignSelf && media.SM`${css.alignSelf[p.SM_alignSelf]}`}
  ${(p) => p.SM_order && media.SM`${css.order[p.SM_order]}`}
  ${(p) => p.SM_textAlign && media.SM`${css.textAlign[p.SM_textAlign]}`}

  /* MD */
  ${(p) => p.MD && media.MD`${css.col[p.MD]}`}
  ${(p) => !Number.isNaN(parseInt(p.MD_offset, 10)) && media.MD`${css.offset[p.MD_offset]}`}
  ${(p) => p.MD_auto && media.MD`${css.col.auto}`}
  ${(p) => p.MD_alignSelf && media.MD`${css.alignSelf[p.MD_alignSelf]}`}
  ${(p) => p.MD_order && media.MD`${css.order[p.MD_order]}`}
  ${(p) => p.MD_textAlign && media.MD`${css.textAlign[p.MD_textAlign]}`}

  /* LG */
  ${(p) => p.LG && media.LG`${css.col[p.LG]}`}
  ${(p) => !Number.isNaN(parseInt(p.LG_offset, 10)) && media.LG`${css.offset[p.LG_offset]}`}
  ${(p) => p.LG_auto && media.LG`${css.col.auto}`}
  ${(p) => p.LG_alignSelf && media.LG`${css.alignSelf[p.LG_alignSelf]}`}
  ${(p) => p.LG_order && media.LG`${css.order[p.LG_order]}`}
  ${(p) => p.LG_textAlign && media.LG`${css.textAlign[p.LG_textAlign]}`}

  /* HD */
  ${(p) => p.HD && media.HD`${css.col[p.HD]}`}
  ${(p) => !Number.isNaN(parseInt(p.HD_offset, 10)) && media.HD`${css.offset[p.HD_offset]}`}
  ${(p) => p.HD_auto && media.HD`${css.col.auto}`}
  ${(p) => p.HD_alignSelf && media.HD`${css.alignSelf[p.HD_alignSelf]}`}
  ${(p) => p.HD_order && media.HD`${css.order[p.HD_order]}`}
  ${(p) => p.HD_textAlign && media.HD`${css.textAlign[p.HD_textAlign]}`}

  /* HDPlus */
  ${(p) => p.HDPlus && media.HDPlus`${css.col[p.HDPlus]}`}
  ${(p) => !Number.isNaN(parseInt(p.HDPlus_offset, 10)) && media.HDPlus`${css.offset[p.HDPlus_offset]}`}
  ${(p) => p.HDPlus_auto && media.HDPlus`${css.col.auto}`}
  ${(p) => p.HDPlus_alignSelf && media.HDPlus`${css.alignSelf[p.HDPlus_alignSelf]}`}
  ${(p) => p.HDPlus_order && media.HDPlus`${css.order[p.HDPlus_order]}`}
  ${(p) => p.HDPlus_textAlign && media.HDPlus`${css.textAlign[p.HDPlus_textAlign]}`}

  /* FHD */
  ${(p) => p.FHD && media.FHD`${css.col[p.FHD]}`}
  ${(p) => !Number.isNaN(parseInt(p.FHD_offset, 10)) && media.FHD`${css.offset[p.FHD_offset]}`}
  ${(p) => p.FHD_auto && media.FHD`${css.col.auto}`}
  ${(p) => p.FHD_alignSelf && media.FHD`${css.alignSelf[p.FHD_alignSelf]}`}
  ${(p) => p.FHD_order && media.FHD`${css.order[p.FHD_order]}`}
  ${(p) => p.FHD_textAlign && media.FHD`${css.textAlign[p.FHD_textAlign]}`}

  /* QHD */
  ${(p) => p.QHD && media.QHD`${css.col[p.QHD]}`}
  ${(p) => !Number.isNaN(parseInt(p.QHD_offset, 10)) && media.QHD`${css.offset[p.QHD_offset]}`}
  ${(p) => p.QHD_auto && media.QHD`${css.col.auto}`}
  ${(p) => p.QHD_alignSelf && media.QHD`${css.alignSelf[p.QHD_alignSelf]}`}
  ${(p) => p.QHD_order && media.QHD`${css.order[p.QHD_order]}`}
  ${(p) => p.QHD_textAlign && media.QHD`${css.textAlign[p.QHD_textAlign]}`}

  /* QHDPlus */
  ${(p) => p.QHDPlus && media.QHDPlus`${css.col[p.QHDPlus]}`}
  ${(p) => !Number.isNaN(parseInt(p.QHDPlus_offset, 10)) && media.QHDPlus`${css.offset[p.QHDPlus_offset]}`}
  ${(p) => p.QHDPlus_auto && media.QHDPlus`${css.col.auto}`}
  ${(p) => p.QHDPlus_alignSelf && media.QHDPlus`${css.alignSelf[p.QHDPlus_alignSelf]}`}
  ${(p) => p.QHDPlus_order && media.QHDPlus`${css.order[p.QHDPlus_order]}`}
  ${(p) => p.QHDPlus_textAlign && media.QHDPlus`${css.textAlign[p.QHDPlus_textAlign]}`}
 
  /* UHD */
  ${(p) => p.UHD && media.UHD`${css.col[p.UHD]}`}
  ${(p) => !Number.isNaN(parseInt(p.UHD_offset, 10)) && media.UHD`${css.offset[p.UHD_offset]}`}
  ${(p) => p.UHD_auto && media.UHD`${css.col.auto}`}
  ${(p) => p.UHD_alignSelf && media.UHD`${css.alignSelf[p.UHD_alignSelf]}`}
  ${(p) => p.UHD_order && media.UHD`${css.order[p.UHD_order]}`}
  ${(p) => p.UHD_textAlign && media.UHD`${css.textAlign[p.UHD_textAlign]}`}
`;


export default Col;
