import styled from 'styled-components';

import media from '../../../styles/media';
import { SIDEBAR_WIDTH, HEADER_HEIGHT, } from '../../../globals';


const StyledAppTemplate = styled.div`
  height: 100%;

  /* SIDEBAR */
  .app-temp--sidebar {
    transition: margin-left 350ms ease-in;
    margin-left: ${(p) => (p.isOpenSidebar ? 0 : -SIDEBAR_WIDTH)}px;
    z-index: 2;
    width: ${SIDEBAR_WIDTH}px;
    position: fixed;
    top: ${HEADER_HEIGHT}rem;
    height: ${`calc(100vh - ${HEADER_HEIGHT}rem)`};
  }


  /* HEADER */
  .app-temp--header {
    position: fixed;
    height: ${HEADER_HEIGHT}rem;
    width: 100%;
    z-index: 2;
  }

  /* PAGE */
  .app-temp--page {
    min-height: ${`calc(100vh - ${HEADER_HEIGHT}rem)`};
    overflow: hidden;
    margin-left: 0;
    transition: margin-left 350ms ease-in;
    position: relative;
    top: ${HEADER_HEIGHT}rem;
    background: ${(p) => p.theme.grey.t50};
  }

  ${() => media.LG`
    .app-temp--page {
      margin-left: ${(p) => (p.isOpenSidebar ? SIDEBAR_WIDTH : 0)}px;
    }
  `}
`;


export default StyledAppTemplate;
