import React, { Component, } from 'react';
import {
  node, oneOfType, arrayOf, object,
} from 'prop-types';

import { PARAM_PREFIX, PARAM_POSTFIX, MESSAGES, } from '../../globals';
import { parseMessage, } from '../messages/messages';
import { QUERY_ERRORS, } from './queries';
import { withTranslations, } from '../translations/withTranslations';
import Context from './Context';


class ErrorTranslator extends Component {
  handleInputError = (data) => {
    const { translations, } = this.props;

    const parsedData = parseMessage(data);

    if (parsedData.code === '') {
      return '';
    }

    if (!translations || !translations.errors) return '';

    if (!Object.prototype.hasOwnProperty.call(translations.errors, parsedData.code)) {
      return translations.errors.SOMETHING_HAPPENED;
    }

    return this.buildMessage(parsedData);
  }


  buildMessage = (parsedData) => {
    const { translations, } = this.props;
    try {
      let ret = translations.errors[parsedData.code];

      const keys = Object.keys(parsedData.params);
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        ret = ret.split(`${PARAM_PREFIX}${key}${PARAM_POSTFIX}`).join(parsedData.params[key]);
      }
      return ret;
    } catch (error) {
      return translations.errors.SOMETHING_HAPPENED;
    }
  }


  handleGraphQLError = (error, defaultMessage) => {
    let returnErrorCode = MESSAGES.SOMETHING_HAPPENED;

    try {
      const { graphQLErrors, networkError, } = error;

      if (networkError) {
        returnErrorCode = MESSAGES.NOT_CONNECTED;
      }

      if (graphQLErrors && graphQLErrors.length > 0) {
        const { message, } = graphQLErrors[0];

        switch (message) {
          case 'UNPROCESSABLE_ENTITY': {
            returnErrorCode = MESSAGES.INVALID_FORM;
            break;
          }

          case 'UNABLE_TO_START': {
            returnErrorCode = MESSAGES.UNABLE_TO_START;
            break;
          }

          case 'MAX_USER_COUNT_EXCEEDED': {
            returnErrorCode = MESSAGES.MAX_USER_COUNT_EXCEEDED;
            break;
          }

          case 'BAD_APP_VERSION': {
            if (message && message !== '') returnErrorCode = message;
            break;
          }

          default: {
            if (message && message !== '') returnErrorCode = message;
            break;
          }
        }
      }
    } catch (err) {
      returnErrorCode = MESSAGES.SOMETHING_HAPPENED;
    }

    return this.translateErrorCode(returnErrorCode, defaultMessage);
  }


  translateErrorCode = (code, defaultMessage) => {
    const { translations, } = this.props;

    if (!translations || !translations.errors) return '';
    if (!Object.prototype.hasOwnProperty.call(translations.errors, code)) {
      if (defaultMessage) {
        return defaultMessage;
      }
      return translations.errors.SOMETHING_HAPPENED;
    }

    return translations.errors[code];
  }


  handleErrorCode = (code = MESSAGES.SOMETHING_HAPPENED) => {
    const { translations, } = this.props;
    if (!translations || !translations.errors) return '';

    if (!Object.prototype.hasOwnProperty.call(translations.errors, code)) {
      return translations.errors.SOMETHING_HAPPENED;
    }

    return translations.errors[code];
  }


  render() {
    const { children, } = this.props;

    return (
      <Context.Provider
        value={{
          translateInputError: this.handleInputError,
          translateGraphQLError: this.handleGraphQLError,
          translateErrorCode: this.handleErrorCode,
        }}
      >
        {children}
      </Context.Provider>
    );
  }
}


ErrorTranslator.propTypes = {
  translations: object,
  children: oneOfType([
    arrayOf(node),
    node,
  ]).isRequired,
};


ErrorTranslator.defaultProps = {
  translations: undefined,
};


export default withTranslations(QUERY_ERRORS)(ErrorTranslator);
