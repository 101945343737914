import styled from 'styled-components';


const IncomingSmsStyledNotification = styled.div`
  position: relative;
  pointer-events: auto;
  overflow: hidden;
  width: 350px;
  height: 70px;
  display: flex;
  flex-direction: column;
  align-items: start;
  box-shadow: ${(p) => p.theme.common.shadow};
  color: black;
  margin-bottom: 4px;
  background-color: ${(p) => p.theme.warning.t50};
  border: 1px solid ${(p) => p.theme.warning.t700};
  
  .incoming-sms-notification--panel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 1.75rem;
    min-height: 1.75rem;
    background: ${(p) => p.theme.warning.t700};
    color: white;
    font-size: 11px;
  }
  
  .incoming-sms-notification--panel--exit-btn {
    color: white;
    background: transparent;
    border: none;
    line-height: 1.25rem;

    width: 1.25rem;
    height: 1.25rem;
    border-radius: 0%;
    padding: 0;

    cursor: pointer;
  }
  
  .incoming-sms-notification--content {
    font-size: 12px;
    width: 100%;
    height: 100%;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .incoming-sms-notification--content > *:first-child, .incoming-sms-notification--panel > *:first-child  {
    margin-left: 10px;
  }

  .incoming-sms-notification--content > *, .incoming-sms-notification--panel > * {
    margin-right: 10px;
  }
`;


export default IncomingSmsStyledNotification;
