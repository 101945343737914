import styled from 'styled-components';


const FormGroup = styled.div`
  display: inline-flex;
  width: 100%;


  input, .inputSelect__control {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }


  button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;


export default FormGroup;
