import React from 'react';
import {
  func, shape, bool, object, number, arrayOf, string,
} from 'prop-types';
import { Query, } from 'react-apollo';

import { QUERY_USER_DETAIL_TRANSLATIONS, } from '../../gql/translations';
import { pipe, } from '../../../../logic/utils';
import { withNotifications, } from '../../../../logic/notifications/withNotifications';
import { withTranslations, } from '../../../../logic/translations/withTranslations';
import ModalLoadingPart from '../../../../components/modal/ModalLoadingPart';
import ModalErrorPart from '../../../../components/modal/ModalErrorPart';
import Modal from '../../../../atoms/Modal/Modal';
import { QUERY_FETCH_RS_USER, } from '../../gql/queries';
import UserUpdateLogic from './UserUpdateLogic';
import { QUERY_ONE_COMPANY, } from '../../../Transportation/gql/queries';
import { getRoleText, } from '../UserForm/role';


const UserUpdate = ({
  translations,
  modalData: { isOpen, id, },
  onUpdated,
  onToggle,
  addNotification,
  languages,
}) => {
  if (!isOpen) return null;
  if (!translations) return null;

  return (
    <Modal
      title={translations.rs.users.updateModalUserTitle}
      isOpen
      onClose={onToggle}
      size="SM"
      disablePadding
    >
      <Query
        query={QUERY_FETCH_RS_USER}
        variables={{ id, }}
        fetchPolicy="network-only"
      >
        {({ data, loading, error, }) => {
          if (loading) {
            return (
              <div className="app--padding">
                <ModalLoadingPart
                  onBack={onToggle}
                  btnBack={translations.common.back}
                />
              </div>
            );
          }

          if (error || !data.fetchRsUser) {
            return (
              <div className="app--padding">
                <ModalErrorPart
                  onBack={onToggle}
                  error={error}
                  btnBack={translations.common.back}
                />
              </div>
            );
          }
          return (
            <Query
              query={QUERY_ONE_COMPANY}
              variables={{ id: data.fetchRsUser.companyId, }}
            >
              {(queryResult) => {
                if (queryResult.loading) {
                  return (
                    <div className="app--padding">
                      <ModalLoadingPart
                        onBack={onToggle}
                        btnBack={translations.common.back}
                      />
                    </div>
                  );
                }

                if (queryResult.error || !queryResult.data.fetchOneCompany) {
                  return (
                    <div className="app--padding">
                      <ModalErrorPart
                        onBack={onToggle}
                        error={error}
                        btnBack={translations.common.back}
                      />
                    </div>
                  );
                }
                return (
                  <UserUpdateLogic
                    data={{
                      fetchRsUser: {
                        ...data.fetchRsUser,
                        companyId: Number.parseInt(queryResult.data.fetchOneCompany.id, 10),
                        companyName: queryResult.data.fetchOneCompany.name,
                        role: {
                          id: data.fetchRsUser.role,
                          name: getRoleText(data.fetchRsUser.role, translations),
                        },
                      },
                    }}
                    onToggle={onToggle}
                    translations={translations}
                    onUpdated={onUpdated}
                    addNotification={addNotification}
                    languages={languages}
                  />
                );
              }}
            </Query>
          );
        }}
      </Query>
    </Modal>
  );
};


UserUpdate.propTypes = {
  translations: object.isRequired,
  modalData: shape({
    isOpen: bool.isRequired,
    id: number.isRequired,
  }).isRequired,
  onToggle: func.isRequired,
  addNotification: func.isRequired,
  onUpdated: func,
  languages: arrayOf(shape({
    id: string,
    code: string,
    name: string,
  })).isRequired,
};

UserUpdate.defaultProps = {
  onUpdated: undefined,
};


export default pipe(
  withNotifications,
  withTranslations(QUERY_USER_DETAIL_TRANSLATIONS),
)(UserUpdate);
