import gql from 'graphql-tag';

export const SUBSCRIPTION_ADD_RECORD_TO_GATE_ACTUAL = gql`
  subscription AddRecordToGateActual {
    addRecordToGateActual {
      id
    }
  }
`;

export const SUBSCRIPTION_REMOVE_RECORD_FROM_GATE_ACTUAL = gql`
  subscription RemoveRecordFromGateActual {
    removeRecordFromGateActual {
      id
    }
  }
`;

export const SUBSCRIPTION_ADD_RECORD_TO_GATE_HISTORY = gql`
  subscription AddRecordToGateHistory {
    addRecordToGateHistory {
      id
    }
  }
`;

export const SUBSCRIPTION_REMOVE_RECORD_FROM_GATE_HISTORY = gql`
  subscription RemoveRecordFromGateHistory {
    removeRecordFromGateHistory {
      id
    }
  }
`;

export const SUBSCRIPTION_ADD_RECORD_TO_GATE_IN_AREA = gql`
  subscription AddRecordToGateInArea {
    addRecordToGateInArea {
      id
    }
  }
`;

export const SUBSCRIPTION_REMOVE_RECORD_FROM_GATE_IN_AREA = gql`
  subscription RemoveRecordFromGateInArea {
    removeRecordFromGateInArea {
      id
    }
  }
`;

export const SUBSCRIPTION_ADD_RECORD_TO_GATE_NOTIFICATIONS = gql`
  subscription AddRecordToGateNotifications {
    addRecordToGateNotifications {
      id
    }
  }
`;

export const SUBSCRIPTION_REMOVE_RECORD_FROM_GATE_NOTIFICATIONS = gql`
  subscription RemoveRecordFromGateNotifications {
    removeRecordFromGateNotifications {
      id
    }
  }
`;
