import React, { Component, Fragment, } from 'react';
import { string, func, object, } from 'prop-types';
import { Query, Mutation, } from 'react-apollo';

import { QUERY_GENERIC_FORM, } from '../../gql/queries';
import { MUTATION_UPDATE_GEN_FORM, } from '../../gql/mutations';
import { withNotifications, } from '../../../../logic/notifications/withNotifications';
import { parseFromApi, parseToApi, } from '../../../../logic/form/genericForm';
import {
  initForm,
  changeAndValidateInput,
  validateAndMergeWholeForm,
  mergeValidationObjectIntoForm,
} from '../../../../logic/form/common';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import ButtonLoader from '../../../../atoms/Button/ButtonLoader';
import PartLoading from '../../../../components/Parts/PartLoading';
import PartError from '../../../../components/Parts/PartError';
import GenericForm from '../../../../components/GenericForm/GenericForm';
import FormError from '../../../../components/Form/FormError';


class FlowChartGenForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      genForm: {
        form: null,
        tree: null,
        values: null,
      },
    };
  }


  handleFetchGenericFormComplete = (data) => {
    const { genericForm, genericFormValues, } = data.fetchGenericFormConfiguration;

    const parsedGenForm = parseFromApi(genericForm, genericFormValues);
    this.setState({
      ...initForm,
      genForm: parsedGenForm,
    });
  }


  handleChangeValue = (name, value) => {
    const { genForm, } = this.state;
    const newState = changeAndValidateInput(genForm, name, value);

    this.setState({
      genForm: newState,
    });
  }


  handleSaveGenericForm = (mutate) => {
    const { genForm, } = this.state;
    const {
      transportationId,
      actionId,
      genericFormId,
    } = this.props;

    const newDetailForm = validateAndMergeWholeForm(genForm);

    if (!newDetailForm.isValid) {
      this.setState({
        genForm: newDetailForm,
      });
    } else {
      const variables = {
        transportationId,
        genericFormId,
        actionId,
        values: parseToApi(genForm),
      };
      mutate({
        variables,
      });
    }
  }


  handleSaveGenericFormCompleted = () => {
    const { translations, addNotification, } = this.props;

    addNotification({
      status: 'success',
      title: translations.common.updated,
    });
  }


  handleSaveGenericFormError = (mutationError) => {
    try {
      const { genForm, } = this.state;

      const { graphQLErrors, } = mutationError;

      if (graphQLErrors && graphQLErrors.length > 0) {
        const { message, extensions, } = graphQLErrors[0];

        switch (message) {
          case 'UNPROCESSABLE_ENTITY': {
            if (extensions.exception.data) {
              const newState = mergeValidationObjectIntoForm(genForm, extensions.exception.data);

              this.setState({
                genForm: newState,
              });
            }
            break;
          }

          default: {
            break;
          }
        }
      }
    } catch (err) {
      // continue regardless of error
    }
  }


  render() {
    const { genForm, } = this.state;
    const {
      genericFormId,
      actionId,
      transportationId,
      translations,
      languageId,
    } = this.props;

    return (
      <Query
        query={QUERY_GENERIC_FORM}
        variables={{
          languageId,
          actionId,
          genericFormId,
          transportationId,
        }}
        onCompleted={this.handleFetchGenericFormComplete}
        fetchPolicy="no-cache"
      >
        {({ loading, error, data, }) => {
          if (loading) {
            return <PartLoading minHeight={5} />;
          }

          if (error || !data || !data.fetchGenericFormConfiguration) {
            return <PartError error={error} minHeight={5} />;
          }

          return (
            <Fragment>

              <GenericForm
                genForm={genForm}
                nodeId="formRoot"
                languageId={languageId}
                onChange={this.handleChangeValue}
              />

              <Mutation
                mutation={MUTATION_UPDATE_GEN_FORM}
                onError={this.handleSaveGenericFormError}
                onCompleted={this.handleSaveGenericFormCompleted}
              >
                {(mutationSave, mutationData) => (
                  <Fragment>
                    <FormError error={mutationData.error} />
                    <Row>
                      <Col textAlign="right">
                        <ButtonLoader
                          onClick={() => this.handleSaveGenericForm(mutationSave)}
                          isLoading={mutationData.loading}
                          color="success"
                        >
                          {translations.common.update}
                        </ButtonLoader>
                      </Col>
                    </Row>
                  </Fragment>
                )}
              </Mutation>

            </Fragment>
          );
        }}
      </Query>
    );
  }
}


FlowChartGenForm.propTypes = {
  genericFormId: string.isRequired,
  actionId: string.isRequired,
  translations: object.isRequired,
  transportationId: string.isRequired,
  languageId: string.isRequired,
  addNotification: func.isRequired,
};


export default withNotifications(FlowChartGenForm);
