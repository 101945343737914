import React, { Component, } from 'react';
import {
  string, shape, bool, object, arrayOf,
} from 'prop-types';

import ButtonIcon from '../../../../atoms/Button/ButtonIcon';
import Collapse from '../../../../atoms/Collapse/Collapse';
import FormBackground from '../../../../components/Form/FormBackground';

import Arrow from '../../../../styles/icons/Arrow';

import GFText from '../../../../components/GenericForm/ReadOnly/GFText';
import FormRowReadOnly from '../../../../components/Form/FormRowReadOnly';
import StyledPictures from './styles/StyledPictures';

const { REACT_APP_REST, } = process.env;

const gateLabels = {};

class FlowChartGateAction extends Component {
  state = {
    isOpen: false,
  };

  renderForm = () => {
    const {
      data: {
        form,
      },
    } = this.props;

    return Object.keys(form).map((key) => (
      <GFText
        key={key}
        input={{
          name: key,
          type: 'text',
          disabled: false,
          translation: {
            label: gateLabels[key],
            placeholder: '',
            help: '',
          },
        }}
        value={form[key].toString()}
      />
    ));
  }

  renderPictures = () => {
    const {
      data: {
        pictures,
      },
    } = this.props;

    if (!pictures || pictures.length === 0) {
      return '';
    }

    return (
      <StyledPictures>
        <FormRowReadOnly label="Foto">
          <ul>
            { pictures.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <li key={index}>
                <a href={`${REACT_APP_REST}/file?filename=${item}`} target="_blank" rel="noopener noreferrer">
                  <img
                    src={`${REACT_APP_REST}/file?filename=${item}`}
                    alt={index}
                    width="100"
                    height="100"
                  />
                </a>
              </li>
            ))}
          </ul>
        </FormRowReadOnly>
      </StyledPictures>
    );
  }

  handleToggle = () => {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen, }));
  }

  render() {
    const { isOpen, } = this.state;
    const {
      data: {
        title,
        start,
        form,
        pictures,
      },
    } = this.props;


    return (
      <>
        <h5>
          {title}
          <ButtonIcon
            className="transpDetail--flow-right-toggle"
            onClick={this.handleToggle}
            size="sm"
            thin
            color="tertiary"
            disabled={!start}
          >
            <Arrow className={`transpDetail--flow-right-icon ${isOpen ? ' transpDetail--flow-right-icon-open' : 'transpDetail--flow-right-icon-close'}`} />
          </ButtonIcon>
        </h5>
        <Collapse isOpen={isOpen}>
          {isOpen && (
            <FormBackground>
                {form && (this.renderForm())}
                { pictures && (this.renderPictures())}
            </FormBackground>
          )}
        </Collapse>
      </>
    );
  }
}


FlowChartGateAction.propTypes = {
  data: shape({
    title: string.isRequired,
    start: bool.isRequired,
    form: object,
    pictures: arrayOf(string),
  }).isRequired,
};


export default FlowChartGateAction;
