import React, { Fragment, } from 'react';
import {
  bool,
  func, object,
} from 'prop-types';

import FormRow from '../../../../components/Form/FormRow';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import InputRadio from '../../../../atoms/InputRadio/InputRadio';
import Input from '../../../../atoms/Input/Input';


const TechnicalCheckResultForm = ({
  form,
  translations,
  values,
  isOutputTechnicalCheck,
  onChange,
}) => (
  <Fragment>

    {isOutputTechnicalCheck && (
      <>
        <Row>
          <Col SM={24}>
            <FormRow
              label={translations.form.valveCheck}
              input={form.valveCheck}
            >
              <Row>
                <Col SM={6}>
                  <InputRadio
                    text={translations.common.yes}
                    value={values.valveCheck}
                    name
                    onChange={(value) => onChange(form.valveCheck.name, value)}
                  />
                </Col>
                <Col SM={6}>
                  <InputRadio
                    text={translations.common.no}
                    value={values.valveCheck}
                    name={false}
                    onChange={(value) => onChange(form.valveCheck.name, value)}
                  />
                </Col>
              </Row>
            </FormRow>
          </Col>
        </Row>

        <Row>
          <Col SM={24}>
            <FormRow
              label={translations.form.outputTechnicalCheck}
              input={form.outputTechnicalCheck}
            >
              <Row>
                <Col SM={6}>
                  <InputRadio
                    text={translations.common.yes}
                    value={values.outputTechnicalCheck}
                    name
                    onChange={(value) => onChange(form.outputTechnicalCheck.name, value)}
                  />
                </Col>
                <Col SM={6}>
                  <InputRadio
                    text={translations.common.no}
                    value={values.outputTechnicalCheck}
                    name={false}
                    onChange={(value) => onChange(form.outputTechnicalCheck.name, value)}
                  />
                </Col>
              </Row>
            </FormRow>
          </Col>
        </Row>
      </>
    )}

    <Row>
      <Col SM={24}>
        <FormRow
          label={translations.form.companyEligibility}
          input={form.companyEligibility}
        >
          <Row>
            <Col SM={6}>
              <InputRadio
                text={translations.common.yes}
                value={values.companyEligibility}
                name
                onChange={(value) => onChange(form.companyEligibility.name, value)}
              />
            </Col>
            <Col SM={6}>
              <InputRadio
                text={translations.common.no}
                value={values.companyEligibility}
                name={false}
                onChange={(value) => onChange(form.companyEligibility.name, value)}
              />
            </Col>
          </Row>
        </FormRow>
      </Col>
    </Row>

    <Row>
      <Col SM={24}>
        <FormRow
          label={translations.form.technicalCheckResult}
          input={form.technicalCheckResult}
        >
          <Row>
            <Col SM={6}>
              <InputRadio
                text={translations.form.technicalCheckResultSuccess}
                value={values.technicalCheckResult}
                name
                onChange={(value) => onChange(form.technicalCheckResult.name, value)}
              />
            </Col>
            <Col SM={6}>
              <InputRadio
                text={translations.form.technicalCheckResultFail}
                value={values.technicalCheckResult}
                name={false}
                onChange={(value) => onChange(form.technicalCheckResult.name, value)}
              />
            </Col>
          </Row>
        </FormRow>
      </Col>
    </Row>

    <Row>
      <Col SM={24}>
        <FormRow
          label={translations.form.note}
          input={form.technicalCheckNote}
        >
          <Input
            type="text"
            value={values.technicalCheckNote}
            onChange={(e) => onChange(form.technicalCheckNote.name, e.target.value)}
            status={form.technicalCheckNote.status}
          />
        </FormRow>
      </Col>
    </Row>

  </Fragment>
);


TechnicalCheckResultForm.propTypes = {
  translations: object.isRequired,
  form: object.isRequired,
  values: object.isRequired,
  isOutputTechnicalCheck: bool.isRequired,
  onChange: func.isRequired,
};


export default TechnicalCheckResultForm;
