import React, { Component, } from 'react';
import { string, object, func, } from 'prop-types';

import { SCHEDULE_TABS, } from '../../../pages/SchedulePage';
import PageFullScreen from '../../../../../atoms/PageView/PageFullScreen';
import Pagination from '../../../../../components/Pagination/Pagination';
import ScheduleView from '../ScheduleView';
import TransportationCreate from '../../TransportationCreate/TransportationCreate';
import TransportationEdit from '../../TransportationEdit/TransportationEdit';
import TableView from './TableView';


class TabScheduleTableView extends Component {
  state = {
    createTransportationModal: {
      isOpen: false,
    },
    editTransportationModal: {
      isOpen: false,
      id: null,
    },
  }


  /**
   * Create Modal - Toggle
   */
  handleToggleTransportationCreate = () => {
    this.setState((prevState) => ({
      createTransportationModal: {
        isOpen: !prevState.createTransportationModal.isOpen,
      },
    }));
  }


  /**
   * Edit Modal - Toggle
   */
  handleToggleTransportationEdit = (id = null) => {
    this.setState((prevState) => ({
      editTransportationModal: {
        isOpen: !prevState.editTransportationModal.isOpen,
        id,
      },
    }));
  }


  /**
   * Table - Refetch
   */
  handleRefetchTable = () => {
    const { queryData, } = this.props;

    queryData.refetch();
  }


  render() {
    const {
      createTransportationModal,
      editTransportationModal,
    } = this.state;
    const {
      // data
      filter,
      queryData,
      languageId,
      translations,
      resources,
      // methods
      onChangePage,
      onChangeFilter,
      onChangeSort,
      onChangeParam,
    } = this.props;

    return (
      <PageFullScreen>

        {createTransportationModal.isOpen && (
          <TransportationCreate
            // data
            modalData={createTransportationModal}
            // methods
            onToggle={this.handleToggleTransportationCreate}
            onCreated={this.handleRefetchTable}
          />
        )}

        {editTransportationModal.isOpen && (
          <TransportationEdit
            // data
            transportationId={editTransportationModal.id}
            // methods
            onToggle={this.handleToggleTransportationEdit}
            onDeleted={this.handleRefetchTable}
            onEdited={this.handleRefetchTable}
          />
        )}

        <ScheduleView
          activeTab={SCHEDULE_TABS.ALL}
          translations={translations}
          resources={resources}
          // methods
          onChangePage={onChangePage}
          onCreateTransportation={this.handleToggleTransportationCreate}
        >

          <>
            <TableView
              // data
              filter={filter}
              queryData={queryData}
              translations={translations}
              languageId={languageId}
              // methods
              onEdit={this.handleToggleTransportationEdit}
              onChangeSort={onChangeSort}
              onChangeParam={onChangeParam}
            />

            <Pagination
              loading={queryData.loading}
              dataFilter={queryData.data && queryData.data.filterTransportationFuture
                ? queryData.data.filterTransportationFuture.filter
                : undefined
              }
              onChangePage={onChangeFilter}
            />
          </>

        </ScheduleView>
      </PageFullScreen>
    );
  }
}


TabScheduleTableView.propTypes = {
  // data
  filter: object.isRequired,
  queryData: object.isRequired,
  languageId: string.isRequired,
  translations: object.isRequired,
  resources: object.isRequired,
  // methods
  onChangePage: func.isRequired,
  onChangeFilter: func.isRequired,
  onChangeSort: func.isRequired,
  onChangeParam: func.isRequired,
};


export default TabScheduleTableView;
