import React, { Fragment, } from 'react';
import {func, object, string,} from 'prop-types';
import { Mutation, } from 'react-apollo';

import { MUTATION_CREATE_SMS_SETTINGS, } from '../../gql/mutations';
import Button from '../../../../atoms/Button/Button';
import ButtonLoader from '../../../../atoms/Button/ButtonLoader';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import FormError from '../../../../components/Form/FormError';
import SmsSettingsForm from '../forms/SmsSettingsForm';
import FormBackground from '../../../../components/Form/FormBackground';


const SmsSettingsCreateView = ({
  // data
  detailForm,
  translations,
  languageId,
  // methods
  onChange,
  onToggle,
  onCreate,
  onCreateError,
  onCreateComplete,
}) => (
  <FormBackground>
    <SmsSettingsForm
      {...detailForm}
      languageId={languageId}
      translations={translations}
      onChange={onChange}
    />

    <Mutation
      mutation={MUTATION_CREATE_SMS_SETTINGS}
      onCompleted={onCreateComplete}
      onError={onCreateError}
    >
      {(mutate, { loading, error, }) => (
        <Fragment>

          <FormError
            isValid={detailForm.isValid}
            error={error}
          />

          <Row>

            <Col XS={12} SM={12}>
              <Button
                shape="outline"
                onClick={onToggle}
              >
                {translations.common.back}
              </Button>
            </Col>

            <Col XS={12} SM={12} textAlign="right">
              <ButtonLoader
                color="success"
                onClick={() => onCreate(mutate)}
                disabled={!detailForm.isValid}
                isLoading={loading}
              >
                {translations.common.create}
              </ButtonLoader>
            </Col>
          </Row>

        </Fragment>
      )}
    </Mutation>
  </FormBackground>
);


SmsSettingsCreateView.propTypes = {
  translations: object.isRequired,
  detailForm: object.isRequired,
  languageId: string.isRequired,
  onChange: func.isRequired,
  onToggle: func.isRequired,
  onCreate: func.isRequired,
  onCreateError: func.isRequired,
  onCreateComplete: func.isRequired,
};


export default SmsSettingsCreateView;
