import { INPUT_TYPE, } from 'sofair-form-validation';

import { initForm, initInput, } from '../../../../logic/form/common';
import { regexPhone, } from '../../../../logic/form/regexes';

const initRecordBasicForm = {
  ...initForm,
  form: {
    driverName: initInput({
      name: 'driverName',
    }),
    driverPhoneNumber: initInput({
      name: 'driverPhoneNumber',
      validation: {
        regex: regexPhone,
      },
    }),
    gateVehicleGroupId: initInput({
      name: 'gateVehicleGroupId',
      type: INPUT_TYPE.SELECT,
      validation: {
        required: false,
      },
    }),
    registrationNumber: initInput({
      name: 'registrationNumber',
      validation: {
        required: true,
      },
    }),
    trailerRegistrationNumber: initInput({
      name: 'trailerRegistrationNumber',
      validation: {
        required: false,
      },
    }),
    gateType: initInput({
      name: 'gateType',
      type: INPUT_TYPE.SELECT,
      validation: {
        required: true,
      },
    }),
    time: initInput({
      name: 'time',
      type: INPUT_TYPE.DATE,
      validation: {
        required: true,
      },
    }),
    transportationId: initInput({
      name: 'transportationId',
      type: INPUT_TYPE.TEXT,
      validation: {
        required: false,
      },
    }),
    transportationNo: initInput({
      name: 'transportationNo',
      type: INPUT_TYPE.TEXT,
      validation: {
        required: false,
      },
    }),
  },
  values: {
    driverName: '',
    driverPhoneNumber: '',
    gateVehicleGroupId: null,
    registrationNumber: '',
    trailerRegistrationNumber: '',
    gateType: null,
    time: null,
    transportationId: null,
    transportationNo: '',
  },
};

const initRecordExtraForm = {
  ...initForm,
  form: {
    rnPicture: initInput({
      name: 'rnPicture',
    }),
    note: initInput({
      name: 'note',
    }),
  },
  values: {
    rnPicture: '',
    note: '',
  },
};

export const initRecordForm = {
  ...initForm,
  form: {
    ...initRecordBasicForm.form,
    ...initRecordExtraForm.form,
  },
  values: {
    ...initRecordBasicForm.values,
    ...initRecordExtraForm.values,
  },
};
