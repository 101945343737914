import React, { Component, } from 'react';
import {
  string, object, func, arrayOf,
} from 'prop-types';

import Tab from '../../../../atoms/TabPanel/Tab';


class TabWithCountLogic extends Component {
  componentDidMount() {
    const { queryData, subscriptions, } = this.props;

    subscriptions.forEach((subscription) => {
      queryData.subscribeToMore({
        document: subscription,
        updateQuery: () => {
          // refetch because of pagination
          queryData.refetch();
          return null;
        },
      });
    });
  }

  render() {
    const {
      // data
      name,
      activeTab,
      text,
      queryData,
      queryName,
      onChangePage,
    } = this.props;

    return (
      <Tab
        name={name}
        activeTab={activeTab}
        onClick={onChangePage}
        style={{ width: '8rem', }}
      >
        {text}
        {queryData && queryData.data && queryData.data[queryName]
          ? ` (${queryData.data[queryName]})`
          : ''
        }
      </Tab>
    );
  }
}

TabWithCountLogic.propTypes = {
  name: string.isRequired,
  activeTab: string.isRequired,
  text: string.isRequired,
  queryData: object.isRequired,
  queryName: string.isRequired,
  subscriptions: arrayOf(object).isRequired,
  onChangePage: func.isRequired,
};

export default TabWithCountLogic;
