import gql from 'graphql-tag';

export const SUBSCRIPTION_RECIEVE_SMS_BY_TRANSPORTATION_TYPE_IDS = gql`
  subscription RecieveSmsByTransportationTypeIds($transportationTypeIds: [ID!]) {
    recieveSmsByTransportationTypeIds(transportationTypeIds: $transportationTypeIds) {
      id
      type
      recipient
      from
      transportationId
      transportationNo
      text
      sent
      failed_count
      sentError
      createdAt
    }
  }
`;

export const SUBSCRIPTION_REMOVE_TRANSPORTATION_FROM_PLAN = gql`
  subscription RemoveTransportationFromPlan {
    removeTransportationFromPlan {
      id
    }
  }
`;
