import React from 'react';
import styled from 'styled-components';

import { FORM_WARNING, FORM_ERROR, } from '../../globals';


/**
 * TextArea
 *  - status: 'default'
 *  - size: 'md'
 */


const getColorInput = ({ status, theme, }) => {
  const colors = {
    default: `
      border-color: ${theme.grey.t200};

      &:focus {
        border-color: ${theme.tertiary.t500};
      }
    `,
    [FORM_WARNING]: `
      border-color: ${theme.warning.t800};

      &:focus {
        border-color: ${theme.tertiary.t500};
      }
      `,
    [FORM_ERROR]: `
      border-color: ${theme.error.t800};

      &:focus {
        border-color: ${theme.tertiary.t500};
      }
    `,
  };

  if (Object.prototype.hasOwnProperty.call(colors, status)) {
    return colors[status];
  }
  return colors.default;
};


const getSizeInput = ({ size, }) => {
  const sizes = {
    sm: `
      font-size: 0.75rem;
    `,
    md: `
      font-size: 0.875rem;
      `,
    lg: `
      font-size: 1rem;
    `,
  };

  if (Object.prototype.hasOwnProperty.call(sizes, size)) {
    return sizes[size];
  }
  return sizes.md;
};


const StyledTextArea = styled.textarea`
  resize: none;
  width: 100%;

  transition: all 0.3s ease;
  
  background: ${(p) => p.theme.white};
  color: ${(p) => p.theme.grey.t700};
  font-family: ${(p) => p.theme.input.fontFamily};
  padding: 0.5em 0.9em;

  border-radius: ${(p) => p.theme.input.borderRadius};
  border-color: ${(p) => p.theme.grey.t500};
  border-top-width: ${(p) => p.theme.input.borderWidthTopBottom};
  border-bottom-width: ${(p) => p.theme.input.borderWidthTopBottom};
  border-right-width: ${(p) => p.theme.input.borderWidthLeftRight};
  border-left-width: ${(p) => p.theme.input.borderWidthLeftRight};
  border-style: ${(p) => p.theme.input.borderStyle};

  &::placeholder {
    color: ${(p) => p.theme.grey.t400};
    opacity: 1; /* Firefox */
  }
  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: ${(p) => p.theme.grey.t400};
  }
  &:-ms-input-placeholder { /* Microsoft Edge */
    color: ${(p) => p.theme.grey.t400};;
  }

  &:focus {
    outline: unset;
  }
  &:disabled {
    background: ${(p) => p.theme.grey.t100};
  }

  ${getColorInput}
  ${getSizeInput}
`;


const TextArea = (props) => <StyledTextArea rows="8" {...props} />;


export default TextArea;
