import React from 'react';
import { object, func, number, } from 'prop-types';

import { withErrors, } from '../../logic/errorManager/withErrors';

import Error from '../../styles/icons/Error';


const TableError = ({ colsCount, error, translateGraphQLError, }) => {
  const parsedError = translateGraphQLError(error);

  return (
    <tbody>
      <tr>
        <td colSpan={colsCount}>
          <div className="table--error">
            <span className="table--error-icon">
              <Error />
            </span>
            <span className="table--error-text">
              {parsedError}
            </span>
          </div>
        </td>
      </tr>
    </tbody>
  );
};


TableError.propTypes = {
  colsCount: number,
  error: object,
  translateGraphQLError: func.isRequired,
};


TableError.defaultProps = {
  error: undefined,
  colsCount: 0,
};


export default withErrors(TableError);
