import React, { Fragment, } from 'react';
import {
  shape, string, func, object, any,
} from 'prop-types';

import FormRow from '../../../../components/Form/FormRow';
import InputSelect from '../../../../atoms/InputSelect/InputSelect';


const Form = ({
  form,
  options,
  translations,
  values,
  onChange,
}) => {
  return (
    <Fragment>
      <FormRow
        label={translations.stand.standCallPlatform}
        input={form.platform}
      >
        <InputSelect
          value={values.platform}
          options={options}
          onChange={(option) => onChange(form.platform.name, option)}
          status={form.platform.status}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          placeholder={translations.common.select}
        />
      </FormRow>

    </Fragment>
  );
};


Form.propTypes = {
  translations: object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  options: any.isRequired,
  form: shape({
    platform: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
  }).isRequired,
  values: shape({
    platform: object,
  }).isRequired,
  onChange: func.isRequired,
};


export default Form;
