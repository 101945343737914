import React, { Component, Fragment, } from 'react';
import {
  func, shape, string, object, bool,
} from 'prop-types';

import { CLEARANCE_STATES, } from '../../../../globals';
import { MONITORING_COLUMNS, } from '../../pages/MonitoringPage';
import { parseDateToHuman, parseTimeToHuman, } from '../../../../logic/date';
import Badge from '../../../../atoms/Badge/Badge';

import StyledMonitoringItem from './styles/StyledMonitoringItem';
import { getActionIconForRecord, } from '../../../Gatehouse/components/utils';
import {isLoadingType} from "../../../Transportation/components/utils";


class MonitoringItem extends Component {
  handleDetail = () => {
    const {
      columnData: {
        state,
      },
      data: {
        id,
      },
      onDetail,
    } = this.props;

    onDetail(state, id);
  }


  renderCol2 = () => {
    const {
      columnData,
      data: {
        reservationTypeName,
        reservationTimeFrom,
        reservationTimeTo,
        platformName,
        clearanceState,
        delayed,
      },
      translations,
      translationsCommon,
    } = this.props;

    if (delayed
      && (
        columnData.state === MONITORING_COLUMNS[0].state
        || columnData.state === MONITORING_COLUMNS[1].state
      )
    ) {
      return (
        <Badge color="error">
          {translations.monitoring.statusDelayed}
        </Badge>
      );
    }

    if (columnData.state === MONITORING_COLUMNS[2].state
      && (
        clearanceState === CLEARANCE_STATES.DELAYED
        || clearanceState === CLEARANCE_STATES.FORWARDED
      )
    ) {
      return (
        <Badge>
          {translationsCommon.storekeeperClearingStates[clearanceState]}
        </Badge>
      );
    }

    return (
      <>
        <div className="monitoring--item-col-text">
          {reservationTypeName}
        </div>
        {(reservationTimeFrom && reservationTimeTo) && (
          <div className="monitoring--item-col-text">
            {`${parseTimeToHuman(new Date(reservationTimeFrom))} - ${parseTimeToHuman(new Date(reservationTimeTo))}`}
          </div>
        )}
      </>
    );
  }


  renderDate = () => {
    const {
      data: {
        date,
        reservationTimeFrom,
        reservationTimeTo,
      },
    } = this.props;

    if (!reservationTimeFrom || !reservationTimeTo) {
      return (
        <Fragment>
          <span className="monitoring--item-col-time-date">
            {parseDateToHuman(new Date(date), false)}
          </span>
          {/*
            <span className="monitoring--item-col-time-time">
              {parseTimeToHuman(new Date(date))}
            </span>
          */}
        </Fragment>
      );
    }

    const fromDate = reservationTimeFrom ? parseDateToHuman(new Date(reservationTimeFrom), false) : '-';
    const fromTime = reservationTimeFrom ? parseTimeToHuman(new Date(reservationTimeFrom)) : '-';
    const toTime = reservationTimeTo ? parseTimeToHuman(new Date(reservationTimeTo)) : '-';

    return (
      <Fragment>
        <span className="monitoring--item-col-time-date">
          {fromDate}
        </span>
        <span className="monitoring--item-col-time-time">
          {`${fromTime} - ${toTime}`}
        </span>
      </Fragment>
    );
  }

  renderCheckpoints = () => {
    const { data, } = this.props;

    const actions = [
      ...(data.hasInputTechnicalCheck ? [ 'inputTechnicalCheck', ] : []),
      ...(data.hasInputSoe ? [ 'inputSoe', ] : []),
      ...(!isLoadingType(data.transportationTypeId) && data.hasQualityControl ? [ 'qualityControl', ] : []),
      ...[ 'clearance', ],
      ...(isLoadingType(data.transportationTypeId) && data.hasQualityControl ? [ 'qualityControl', ] : []),
      ...(data.hasOutputSoe ? [ 'outputSoe', ] : []),
      ...(data.hasOutputTechnicalCheck ? [ 'outputTechnicalCheck', ] : []),
    ];

    const clearanceActionCallback = () => !data.isProblematic && (data.state === 'cleared' || data.state === 'finished');

    return (
      <div className="monitoring--item-checkpoints">
        {actions.map((action) => {
          return (
            <div>
              {getActionIconForRecord(
                data.checkpoints,
                action,
                { width: '1.5em', height: '1.5em', marginRight: 0, },
                action === 'clearance' && data.isProblematic,
                action === 'clearance' ? clearanceActionCallback : null
              )}
            </div>
          );
        })}
      </div>
    );
  }


  render() {
    const {
      data: {
        no,
        transportationTypeName,
        truckRegistration,
        arrivalTime,
        reservationTimeFrom,
        reservationTypeId,
        productInfo,
        platformName,
      },
      columnData: {
        state,
      },
    } = this.props;

    let backgroundClassName = '';
    if (state === 'waiting' && [ '2', '3', ].indexOf(reservationTypeId) !== -1 && (new Date(arrivalTime)).getTime() > (new Date(reservationTimeFrom)).getTime()) {
      backgroundClassName += 'monitoring--item-late';
    }

    return (
      <StyledMonitoringItem
        className={`monitoring--item ${backgroundClassName}`}
        onClick={this.handleDetail}
      >
        <div className="monitoring--item-cols">
          <div className="monitoring--item-col1">
            <div className="monitoring--item-col-text monitoring--item-col-no">
              {no}
            </div>
            <div className="monitoring--item-col-text monitoring--item-col-truckNo">
              {truckRegistration}
            </div>
          </div>
          <div className="monitoring--item-col2">
            {this.renderCol2()}
          </div>
        </div>
        <div className="monitoring--item-col-text monitoring--item-col-type">
          {transportationTypeName}
        </div>
        <div className="monitoring--item-col-text">
          {platformName}
        </div>
        {productInfo && (productInfo.destination || productInfo.name) && (
          <div className="monitoring--item-product-info">
            {productInfo.destination && (
              <div className="monitoring--item-product-info-item">{productInfo.destination}</div>
            )}
            {productInfo.name && (
              <div className="monitoring--item-product-info-item">{productInfo.name}</div>
            )}
            {(productInfo.count - 1) > 0 && (
              <div className="monitoring--item-product-info-count">+{productInfo.count - 1}</div>
            )}
          </div>
        )}
        {this.renderCheckpoints()}

      </StyledMonitoringItem>
    );
  }
}


MonitoringItem.propTypes = {
  columnData: shape({
    state: string.isRequired,
  }).isRequired,
  data: shape({
    id: string.isRequired,
    no: string.isRequired,
    transportationTypeName: string.isRequired,
    truckRegistration: string.isRequired,
    reservationTypeName: string.isRequired,
    reservationTimeFrom: string,
    reservationTimeTo: string,
    delayed: bool.isRequired,
    platformName: string,
    clearanceState: string,
  }).isRequired,
  translations: object.isRequired,
  translationsCommon: object.isRequired,
  onDetail: func.isRequired,
};


export default MonitoringItem;
