import React from 'react';
import { Route, Redirect, } from 'react-router-dom';
import { Query, } from 'react-apollo';

import { QUERY_USER, } from '../../modules/Main/gql/queries';
import { getAccessToken, } from '../../logic/localStorage/auth';


const PrivateRoute = ({ component: Component, resource, ...rest }) => {
  const toRender = ({ location, ...props }) => (
    <Query
      query={QUERY_USER}
      variables={{ accessToken: getAccessToken(), }}
      fetchPolicy="cache-only"
    >
      {({ data: { getUser, }, }) => {
        if (!resource) return <Redirect to="/notauthorized" />;
        if (getUser) return <Component {...props} {...getUser} location={location} />;
        return (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location, },
            }}
          />
        );
      }}
    </Query>
  );

  return <Route {...rest} render={toRender} />;
};


export default PrivateRoute;
