import React from 'react';
import styled from 'styled-components';
import {
  string, object, shape, func,
} from 'prop-types';

import { withErrors, } from '../../logic/errorManager/withErrors';
import {
  FORM_DEFAULT, FORM_WARNING, FORM_ERROR,
} from '../../globals';


const StyledFormMessage = styled.div`
  min-height: 1.5rem;
  padding-left: 3px;
  font-weight: 400;
  font-size: 0.75rem;
  color: ${(p) => p.theme.grey.t400};

  /* Error */
    ${(p) => p.status && p.status === FORM_ERROR && `
    color: ${p.theme.error.t800};
  `}

  /* Warning */
  ${(p) => p.status && p.status === FORM_WARNING && `
    color: ${p.theme.warning.t800};
  `}
`;


const FormMessage = ({
  status,
  msg,
  info,
  translateInputError,
}) => (
  <StyledFormMessage status={status}>
    {status !== FORM_ERROR ? info : translateInputError(msg)}
  </StyledFormMessage>
);


FormMessage.propTypes = {
  status: string,
  msg: shape({
    code: string,
    params: object,
  }),
  info: string,
  translateInputError: func.isRequired,

};


FormMessage.defaultProps = {
  status: FORM_DEFAULT,
  msg: {
    code: '',
    params: {},
  },
  info: '',
};


export default withErrors(FormMessage);
