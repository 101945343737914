import React, { Fragment, } from 'react';
import {
  shape, string, func, object,
} from 'prop-types';

import { Query, } from 'react-apollo';
import FormRow from '../../../../components/Form/FormRow';
import Input from '../../../../atoms/Input/Input';
import InputSelect from '../../../../atoms/InputSelect/InputSelect';
import { QUERY_DD_PLATFORMS, QUERY_DD_TRANSPORTATION_TYPES, } from '../../../Transportation/gql/queries';


const SmsSettingsForm = ({
  form,
  languageId,
  translations,
  values,
  onChange,
}) => (
  <Fragment>

    <FormRow
      label={translations.smsSettings.recipient}
      input={form.recipient}
    >
      <Input
        type="text"
        value={values.recipient}
        onChange={(e) => onChange(form.recipient.name, e.target.value)}
        status={form.recipient.status}
      />
    </FormRow>

    <FormRow
      label={translations.smsSettings.type}
      input={form.type}
    >
      <InputSelect
        value={values.type}
        options={[
          { id: 'queue', name: translations.smsSettings.typeQueue, },
          { id: 'calling', name: translations.smsSettings.typeCalling, },
        ]}
        onChange={(option) => onChange(form.type.name, option)}
        status={form.type.status}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        placeholder={translations.common.select}
      />
    </FormRow>

    <FormRow
      label={translations.smsSettings.transportationType}
      input={form.transportationType}
    >
      <Query
        query={QUERY_DD_TRANSPORTATION_TYPES}
        variables={{
          languageId,
        }}
      >
        {(dropdownData) => (
          <InputSelect
            value={values.transportationType}
            options={(dropdownData.data && dropdownData.data.fetchAllTransportationTypes)
              ? dropdownData.data.fetchAllTransportationTypes
              : []
              }
            onChange={(option) => onChange('transportationType', option, false)}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            isLoading={dropdownData.loading}
            error={dropdownData.error}
            placeholder={translations.common.select}
          />
        )}
      </Query>
    </FormRow>

    {values.type && values.type.id === 'calling' && (
    <FormRow
      label={translations.smsSettings.platform}
      input={form.platform}
    >
      <Query
        query={QUERY_DD_PLATFORMS}
        variables={{
          languageId,
        }}
      >
        {(dropdownData) => (
          <InputSelect
            value={values.platform}
            options={(dropdownData.data && dropdownData.data.fetchAllPlatforms)
              ? dropdownData.data.fetchAllPlatforms
              : []
              }
            onChange={(option) => onChange('platform', option, false)}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            isLoading={dropdownData.loading}
            error={dropdownData.error}
            placeholder={translations.common.select}
          />
        )}
      </Query>
    </FormRow>
    )}

  </Fragment>
);


SmsSettingsForm.propTypes = {
  translations: object.isRequired,
  languageId: string.isRequired,
  form: shape({
    recipient: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    type: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    transportationType: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
    platform: shape({
      name: string.isRequired,
      status: string.isRequired,
    }).isRequired,
  }).isRequired,
  values: shape({
    recipient: string,
    type: object,
    transportationType: object,
    platform: object,
  }).isRequired,
  onChange: func.isRequired,
};


export default SmsSettingsForm;
