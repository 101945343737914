import React, { Component, } from 'react';
import { func, object, string, } from 'prop-types';

import { withApollo, } from 'react-apollo';
import { withTranslations, } from '../../../logic/translations/withTranslations';
import { TRANSLATIONS_SAMPLES, } from '../gql/translations';
import Breadcrumb from '../../../atoms/Breadcrumb/Breadcrumb';
import BreadcrumbItem from '../../../atoms/Breadcrumb/BreadcrumbItem';
import PageFullScreen from '../../../atoms/PageView/PageFullScreen';
import SamplesView from '../components/Samples/SamplesView';
import { pipe, } from '../../../logic/utils';
import { MUTATION_CONFIRM_TRANSPORTATION_SAMPLE, } from '../gql/mutations';
import { withNotifications, } from '../../../logic/notifications/withNotifications';


class SamplePage extends Component {
  state = {
    confirmModal: {
      isOpen: false,
      id: null,
    },
    detailModal: {
      isOpen: false,
      id: null,
    },
  }

  handleToggleConfirm = (id) => {
    this.setState((prevState) => ({
      confirmModal: {
        ...prevState.confirmModal,
        isOpen: !prevState.confirmModal.isOpen,
        id,
      },
    }));
  }

  handleToggleDetail = (id) => {
    this.setState((prevState) => ({
      detailModal: {
        ...prevState.detailModal,
        isOpen: !prevState.detailModal.isOpen,
        id,
      },
    }));
  }

  handleConfirmSample = async (modalResult) => {
    this.handleToggleConfirm(null);
    if (!modalResult) {
      return;
    }

    const { confirmModal, } = this.state;
    const { client, addNotification, translations, } = this.props;

    try {
      await client.mutate({
        mutation: MUTATION_CONFIRM_TRANSPORTATION_SAMPLE,
        variables: { transportationId: confirmModal.id, },
      });

      addNotification({
        status: 'success',
        title: translations.sample.confirmSuccessTitle,
      });
    } catch (e) {
      addNotification({
        status: 'error',
        title: translations.sample.confirmErrorTitle,
      });
    }
  }

  render() {
    const { confirmModal, detailModal, } = this.state;
    const { resources, translations, languageId, } = this.props;

    return (
      <PageFullScreen>

        <Breadcrumb>
          <BreadcrumbItem to="/">{translations.common.home}</BreadcrumbItem>
          <BreadcrumbItem>{translations.common.samples}</BreadcrumbItem>
        </Breadcrumb>

        <SamplesView
          translations={translations}
          resources={resources}
          languageId={languageId}
          detailModal={detailModal}
          confirmModal={confirmModal}
          onToggleDetail={this.handleToggleDetail}
          onToggleConfirm={this.handleToggleConfirm}
          onConfirmSample={this.handleConfirmSample}
        />

      </PageFullScreen>
    );
  }
}


SamplePage.propTypes = {
  client: object.isRequired,
  resources: object.isRequired,
  translations: object.isRequired,
  languageId: string.isRequired,
  addNotification: func.isRequired,
};


export default pipe(withApollo, withNotifications, withTranslations(TRANSLATIONS_SAMPLES))(SamplePage);
