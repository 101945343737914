import styled from 'styled-components';


const StyledLoginLicenseWarning = styled.div`
  font-weight: 300;
  color: ${(p) => p.theme.warning.t600};
  line-height: 1.2rem;
  
  p {
    margin: 0;
    
    &.centered {
      text-align: center;
    }
  }
  
  a {
    color: ${(p) => p.theme.warning.t600};
    
    &:hover {
      color: ${(p) => p.theme.warning.t800};
    }
  }
`;

export default StyledLoginLicenseWarning;
