import React from 'react';

import StyledSvg from '../styles/StyledSvg';


const Logistic = ({ ...props }) => (
  <StyledSvg viewBox="0 0 612.001 612" {...props}>
    <path d="M604.131,440.17h-19.12V333.237c0-12.512-3.776-24.787-10.78-35.173l-47.92-70.975
      c-11.725-17.311-31.238-27.698-52.169-27.698h-74.28c-8.734,0-15.737,7.082-15.737,15.738v225.043H262.475
      c11.567,9.992,19.514,23.92,21.796,39.658H412.53c4.563-31.238,31.475-55.396,63.972-55.396c32.498,0,59.33,24.158,63.895,55.396
      h63.735c4.328,0,7.869-3.541,7.869-7.869V448.04C612,443.713,608.46,440.17,604.131,440.17z M525.76,312.227h-98.044
      c-4.327,0-7.868-3.463-7.868-7.869v-54.372c0-4.328,3.541-7.869,7.868-7.869h59.724c2.597,0,4.957,1.259,6.452,3.305l38.32,54.451
      C535.831,305.067,532.133,312.227,525.76,312.227z M476.502,440.17c-27.068,0-48.943,21.953-48.943,49.021
      c0,26.99,21.875,48.943,48.943,48.943c26.989,0,48.943-21.953,48.943-48.943C525.445,462.125,503.491,440.17,476.502,440.17z
       M476.502,513.665c-13.535,0-24.472-11.016-24.472-24.471c0-13.535,10.937-24.473,24.472-24.473
      c13.533,0,24.472,10.938,24.472,24.473C500.974,502.649,490.036,513.665,476.502,513.665z M68.434,440.17
      c-4.328,0-7.869,3.543-7.869,7.869v23.922c0,4.328,3.541,7.869,7.869,7.869h87.971c2.282-15.738,10.229-29.666,21.718-39.658
      H68.434V440.17z M220.298,440.17c-26.989,0-48.943,21.953-48.943,49.021c0,26.99,21.954,48.943,48.943,48.943
      c27.068,0,48.943-21.953,48.943-48.943C269.242,462.125,247.367,440.17,220.298,440.17z M220.298,513.665
      c-13.534,0-24.471-11.016-24.471-24.471c0-13.535,10.937-24.473,24.471-24.473c13.534,0,24.472,10.938,24.472,24.473
      C244.77,502.649,233.832,513.665,220.298,513.665z M338.014,150.605h-91.198c4.485,13.298,6.846,27.54,6.846,42.255
      c0,74.28-60.431,134.711-134.711,134.711c-13.535,0-26.675-2.045-39.029-5.744v86.949c0,4.328,3.541,7.869,7.869,7.869h265.96
      c4.329,0,7.869-3.541,7.869-7.869V174.211C361.619,161.149,351.075,150.605,338.014,150.605z M118.969,73.866
      C53.264,73.866,0,127.129,0,192.834s53.264,118.969,118.969,118.969s118.97-53.264,118.97-118.969S184.674,73.866,118.969,73.866z
       M118.969,284.73c-50.752,0-91.896-41.143-91.896-91.896c0-50.753,41.144-91.896,91.896-91.896
      c50.753,0,91.896,41.144,91.896,91.896C210.865,243.587,169.722,284.73,118.969,284.73z M154.066,212.242
      c-1.014,0-2.052-0.131-3.082-0.407L112.641,201.5c-5.148-1.391-8.729-6.062-8.729-11.396v-59.015
      c0-6.516,5.287-11.803,11.803-11.803c6.516,0,11.803,5.287,11.803,11.803v49.971l29.614,7.983
      c6.294,1.698,10.02,8.177,8.322,14.469C164.033,208.776,159.269,212.242,154.066,212.242z"
    />
  </StyledSvg>
);

export default Logistic;
