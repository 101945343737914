import React, { Component, } from 'react';
import { string, object, } from 'prop-types';

import { QUERY_HISTORY_TRANSLATIONS, } from '../gql/translations';
import { withTranslations, } from '../../../logic/translations/withTranslations';
import Card from '../../../atoms/Card/Card';
import Breadcrumb from '../../../atoms/Breadcrumb/Breadcrumb';
import BreadcrumbItem from '../../../atoms/Breadcrumb/BreadcrumbItem';
import PageFullScreen from '../../../atoms/PageView/PageFullScreen';
import TransportationDetail from '../components/TransportationDetail/TransportationDetail';
import HistoryTable from '../components/History/HistoryTable';


class HistoryPage extends Component {
  state = {
    detailModal: {
      isOpen: false,
      id: null,
    },
  }


  handleToggleDetailModal = (transportation) => {
    this.setState((prevState) => ({
      detailModal: {
        ...prevState.detailModal,
        isOpen: !prevState.detailModal.isOpen,
        id: transportation ? transportation.id : null,
      },
    }));
  }


  render() {
    const { detailModal, } = this.state;
    const {
      translations, languageId,
    } = this.props;

    return (
      <PageFullScreen>
        {detailModal.isOpen && (
          <TransportationDetail
            // data
            transportationId={detailModal.id}
            // methods
            onToggle={this.handleToggleDetailModal}
          />
        )}

        <Breadcrumb>
          <BreadcrumbItem to="/">{translations.common.home}</BreadcrumbItem>
          <BreadcrumbItem>{translations.common.history}</BreadcrumbItem>
        </Breadcrumb>

        <Card>
          <h2>{translations.common.history}</h2>

          <HistoryTable
            // data
            translations={translations}
            languageId={languageId}
            // methods
            onDetail={this.handleToggleDetailModal}
          />
        </Card>
      </PageFullScreen>
    );
  }
}


HistoryPage.propTypes = {
  translations: object.isRequired,
  languageId: string.isRequired,
};


export default withTranslations(QUERY_HISTORY_TRANSLATIONS)(HistoryPage);
