import React from 'react';
import ReactDOM from 'react-dom';
import {
  oneOfType, arrayOf, node, bool, string, func,
} from 'prop-types';

import ModalContent from './ModalContent';
import StyledApp from '../../styles/StyledApp';


const ModalBase = ({ children, isOpen, ...props }) => {
  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal((
    <ModalContent
      {...props}
    >
      <StyledApp>
        {children}
      </StyledApp>
    </ModalContent>
  ),
  document.body);
};


ModalBase.propTypes = {
  disableClickOutside: bool,
  closeOnEsc: bool,
  size: string,
  isOpen: bool.isRequired,
  onClose: func,
  children: oneOfType([
    arrayOf(node),
    node,
  ]).isRequired,
};

ModalBase.defaultProps = {
  disableClickOutside: true,
  closeOnEsc: false,
  size: 'LG',
  onClose: () => {},
};


export default ModalBase;
