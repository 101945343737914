import React from 'react';

import StyledSvg from './styles/StyledSvg';


const Checklist = ({ ...props }) => (
  <StyledSvg viewBox="0 0 1024 1024" {...props}>
    {/* eslint-disable-next-line max-len */}
    <path d="M896 0H128a128 128 0 0 0-128 128v768a128 128 0 0 0 128 128h768a128 128 0 0 0 128-128V128a128 128 0 0 0-128-128z m42.666667 896a42.666667 42.666667 0 0 1-42.666667 42.666667H128a42.666667 42.666667 0 0 1-42.666667-42.666667V128a42.666667 42.666667 0 0 1 42.666667-42.666667h768a42.666667 42.666667 0 0 1 42.666667 42.666667z" />
    {/* eslint-disable-next-line max-len */}
    <path d="M479.957333 192a53.376 53.376 0 0 0-74.666666 10.666667L314.24 324.266667l-20.565333-20.522667a53.333333 53.333333 0 0 0-75.392 75.264l64 64a53.845333 53.845333 0 0 0 80.384-5.717333l128-170.666667a53.333333 53.333333 0 0 0-10.709334-74.624zM479.957333 576a53.376 53.376 0 0 0-74.666666 10.666667L314.24 708.266667l-20.565333-20.522667a53.333333 53.333333 0 1 0-75.392 75.434667l64 64a53.973333 53.973333 0 0 0 80.384-5.888l128-170.666667a53.333333 53.333333 0 0 0-10.709334-74.624zM789.333333 330.624H597.333333a53.333333 53.333333 0 0 0 0 106.666667h192a53.333333 53.333333 0 0 0 0-106.666667zM789.333333 671.957333H597.333333a53.333333 53.333333 0 0 0 0 106.666667h192a53.333333 53.333333 0 1 0 0-106.666667z" />
  </StyledSvg>
);

export default Checklist;
