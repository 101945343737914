import React from 'react';
import {
  string, shape, bool, func, object,
} from 'prop-types';

import FormRow from '../../Form/FormRow';
import InputDate from '../../../atoms/InputDate/InputDate';


const GFTimestamp = ({
  input,
  value,
  showTime,
  onChange,
}) => (
  <FormRow
    label={input.translation.label}
    input={input}
    info={input.translation.help}
  >
    <InputDate
      value={value}
      showTime={showTime}
      onChange={(newTimestamp) => onChange(input.name, newTimestamp)}
      placeholder={input.translation.placeholder}
      disabled={input.disabled}
    />
  </FormRow>
);


GFTimestamp.propTypes = {
  showTime: bool,
  input: shape({
    name: string.isRequired,
    type: string.isRequired,
    disabled: bool.isRequired,
    translation: shape({
      label: string,
      placeholder: string,
      help: string,
    }).isRequired,
  }).isRequired,
  value: object,
  onChange: func.isRequired,
};

GFTimestamp.defaultProps = {
  value: null,
  showTime: false,
};


export default GFTimestamp;
