import React from 'react';
import { object, } from 'prop-types';

import { withTranslations, } from '../../../logic/translations/withTranslations';
import { TRANSLATIONS_DASHBOARD, } from '../gql/translations';
import PageView from '../../../atoms/PageView/PageView';
import Breadcrumb from '../../../atoms/Breadcrumb/Breadcrumb';
import BreadcrumbItem from '../../../atoms/Breadcrumb/BreadcrumbItem';
import ModuleGrid from '../components/ModuleGrid/ModuleGrid';
import StatsGrid from '../components/StatsGrid/StatsGrid';


const DashboardPage = ({ translations, translationsCommon, }) => (
  <PageView>

    <Breadcrumb>
      <BreadcrumbItem>{translations.common.home}</BreadcrumbItem>
    </Breadcrumb>

    <ModuleGrid translations={translations} />

    <StatsGrid translations={translations} translationsCommon={translationsCommon} />
  </PageView>
);


DashboardPage.propTypes = {
  translations: object.isRequired,
  translationsCommon: object.isRequired,
};


export default withTranslations(TRANSLATIONS_DASHBOARD)(DashboardPage);
