import React from 'react';
import styled from 'styled-components';

import StyledInput from './StyledInput';
import Cross from '../../styles/icons/Cross';


const InputWrapper = styled.div`
  position: relative;
  
  .clear-btn {
    display: inline;
    position: absolute;

    height: 100%;
    top: 0;
    right: 4px;

    background: transparent;
    border: none;
    cursor: pointer;
  }

  .clear-btn-cross {
    color: ${(p) => p.theme.grey.t600};
    height: 8px;
    width: 8px;
  }
  
  .clear-btn:hover {
    .clear-btn-cross {
      color: ${(p) => p.theme.error.t800};
    }
  }
`;

const Input = ({ value, onClear, ...props }) => (
  <InputWrapper>
    <StyledInput value={value} {...props} style={onClear ? { paddingRight: '22px', } : {}} />
    {!!value && !!onClear && (
      <button
        className="clear-btn"
        type="button"
        onClick={onClear}
      >
        <Cross className="clear-btn-cross" />
      </button>
    )}
  </InputWrapper>
);

export default Input;
