import { Component, } from 'react';
import {
  node, oneOfType, arrayOf, func,
} from 'prop-types';


class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorInfo: null,
    };
  }


  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    this.setState({
      hasError: true,
      errorInfo: info,
    });
  }


  render() {
    const { hasError, errorInfo, } = this.state;
    const {
      renderError,
      children,
    } = this.props;

    if (hasError) {
      return renderError(errorInfo);
    }

    return children;
  }
}


ErrorBoundary.propTypes = {
  children: oneOfType([ node, arrayOf(node), ]).isRequired,
  renderError: func.isRequired,
};


export default ErrorBoundary;
