import React from 'react';
import {
  oneOfType, node, object, func, arrayOf, string,
} from 'prop-types';

import { SCHEDULE_TABS, } from '../../pages/SchedulePage';
import Breadcrumb from '../../../../atoms/Breadcrumb/Breadcrumb';
import BreadcrumbItem from '../../../../atoms/Breadcrumb/BreadcrumbItem';
import Card from '../../../../atoms/Card/Card';
import Button from '../../../../atoms/Button/Button';
import TabPanel from '../../../../atoms/TabPanel/TabPanel';
import Tab from '../../../../atoms/TabPanel/Tab';
import ErrorBoundary from '../../../../components/ErrorBoundary/ErrorBoundary';
import PartError from '../../../../components/Parts/PartError';
import HeadingWihButtons from '../../../../atoms/HeadingWihButtons/HeadingWihButtons';


const TabTimelinePlatforms = ({
  // data
  activeTab,
  translations,
  resources,
  children,
  // methods
  onChangePage,
  onCreateTransportation,
}) => (
  <>

    <Breadcrumb>
      <BreadcrumbItem to="/">{translations.common.home}</BreadcrumbItem>
      <BreadcrumbItem>{translations.common.schedule}</BreadcrumbItem>
    </Breadcrumb>

    <Card>

      <HeadingWihButtons>
        <h2>{translations.common.schedule}</h2>
        {resources.create_transportation && (
          <Button
            onClick={onCreateTransportation}
            color="success"
            size="sm"
          >
            {translations.transportation.btnNewTransportation}
          </Button>
        )}
      </HeadingWihButtons>

      <TabPanel>
        <Tab
          name={SCHEDULE_TABS.ALL}
          activeTab={activeTab}
          onClick={onChangePage}
        >
          {translations.transportation.tabScheduleAll}
        </Tab>
        <Tab
          name={SCHEDULE_TABS.TIMELINE_PLATFORMS}
          activeTab={activeTab}
          onClick={onChangePage}
        >
          {translations.transportation.tabScheduleTimelinePlatforms}
        </Tab>
        <Tab
          name={SCHEDULE_TABS.TIMELINE_TRANSPORTATION_TYPES}
          activeTab={activeTab}
          onClick={onChangePage}
        >
          {translations.transportation.tabScheduleTimelineTransportationTypes}
        </Tab>
        <Tab
          name={SCHEDULE_TABS.TABLE_DAY}
          activeTab={activeTab}
          onClick={onChangePage}
        >
          {translations.transportation.tabScheduleTableReservationDay}
        </Tab>
      </TabPanel>

      <ErrorBoundary
        renderError={() => <PartError />}
      >
        {children}
      </ErrorBoundary>

    </Card>

  </>
);


TabTimelinePlatforms.propTypes = {
  // data
  activeTab: string.isRequired,
  translations: object.isRequired,
  resources: object.isRequired,
  children: oneOfType([ node, arrayOf(node), ]).isRequired,
  // methods
  onChangePage: func.isRequired,
  onCreateTransportation: func.isRequired,
};


export default TabTimelinePlatforms;
