import React, { Fragment, } from 'react';
import {
  func, shape, bool, string, object, arrayOf,
} from 'prop-types';

import { QUERY_SMS_TEMPLATES, } from '../../gql/queries';
import Input from '../../../../atoms/Input/Input';
import Table from '../../../../atoms/Table/Table';
import Pagination from '../../../../components/Pagination/Pagination';
import TableLoading from '../../../../atoms/Table/TableLoading';
import TableError from '../../../../atoms/Table/TableError';
import TableNoData from '../../../../atoms/Table/TableNoData';
import FilteredTable from '../../../../components/FilteredTable/FilteredTable';
import SmsUpdate from '../SmsUpdate/SmsUpdate';
import Tooltip from '../../../../atoms/Tooltip/Tooltip';
import ButtonIcon from '../../../../atoms/Button/ButtonIcon';
import ButtonGrp from '../../../../atoms/Button/ButtonGrp';
import THSortable from '../../../../atoms/Table/THSortable';
import Search from '../../../../styles/icons/Search';


const COL_COUNT = 4;
const INIT_FILTER = {
  offset: 0,
  limit: 25,
  sortBy: '',
  order: '',
  params: {
    id: '',
    typeTranslation: '',
    languageCode: '',
  },
};


const Header = ({
  // data
  filter,
  translations,
  // methods
  onChangeSort,
  onChangeParam,
}) => (
  <thead>

    <tr>
      <THSortable
        title="#"
        name="id"
        isActiveFilter={filter.params.id !== ''}
        filter={filter}
        style={{ minWidth: '4rem', width: '4rem', }}
        onSort={onChangeSort}
      />

      <THSortable
        title={translations.sms.type}
        name="typeTranslation"
        isActiveFilter={filter.params.typeTranslation !== ''}
        filter={filter}
        style={{ minWidth: '24rem', width: '16rem', }}
        onSort={onChangeSort}
      />

      <THSortable
        title={translations.sms.language}
        name="languageCode"
        isActiveFilter={filter.params.languageCode !== ''}
        filter={filter}
        style={{ minWidth: '8rem', width: '8rem', }}
        onSort={onChangeSort}
      />

      <th
        className="table--text-align-right"
        style={{ minWidth: '5rem', width: '5rem', }}
      >
        {translations.sms.thActions}
      </th>
    </tr>

    <tr>
      <th>
        <Input
          type="text"
          placeholder={translations.common.filter}
          autoComplete="off"
          value={filter.params.id}
          active={filter.params.id !== ''}
          onChange={(e) => onChangeParam('id', e.target.value)}
          onClear={() => onChangeParam('id', '')}
          size="sm"
        />
      </th>

      <th>
        <Input
          type="text"
          placeholder={translations.common.filter}
          autoComplete="off"
          value={filter.params.typeTranslation}
          active={filter.params.typeTranslation !== ''}
          onChange={(e) => onChangeParam('typeTranslation', e.target.value)}
          onClear={() => onChangeParam('typeTranslation', '')}
          size="sm"
        />
      </th>

      <th>
        <Input
          type="text"
          placeholder={translations.common.filter}
          autoComplete="off"
          value={filter.params.languageCode}
          active={filter.params.languageCode !== ''}
          onChange={(e) => onChangeParam('languageCode', e.target.value)}
          onClear={() => onChangeParam('languageCode', '')}
          size="sm"
        />
      </th>

      <th />
    </tr>

  </thead>
);


Header.propTypes = {
  // data
  filter: object.isRequired,
  translations: object.isRequired,
  // methods
  onChangeSort: func.isRequired,
  onChangeParam: func.isRequired,
};


const Rows = ({
  // data
  loading,
  error,
  data,
  translations,
  // methods
  onToggleUpdate,
}) => {
  if (!data.filterSmsTemplates && loading) {
    return (
      <TableLoading
        colsCount={COL_COUNT}
        rowsCount={data && data.filterSmsTemplates && data.filterSmsTemplates.rows.length}
      />
    );
  }
  if (error || !data.filterSmsTemplates) {
    return (
      <TableError
        colsCount={COL_COUNT}
        error={error}
      />
    );
  }
  if (data.filterSmsTemplates.rows.length < 1) {
    return (
      <TableNoData
        colsCount={COL_COUNT}
        text={translations.sms.infoSmsEmpty}
      />
    );
  }

  return (
    <tbody>
      {data.filterSmsTemplates.rows.map((item) => (
        <tr key={item.id}>
          <td>
            {item.id}
          </td>
          <td>
            {item.typeTranslation}
          </td>
          <td>
            {item.languageCode}
          </td>
          <td className="table--text-align-right table--noPadding">
            <ButtonGrp>
              <Tooltip text={translations.common.detail}>
                {(events) => (
                  <ButtonIcon
                    size="sm"
                    color="tertiary"
                    onClick={(e) => onToggleUpdate(e, item.id)}
                    {...events}
                  >
                    <Search />
                  </ButtonIcon>
                )}
              </Tooltip>
            </ButtonGrp>
          </td>
        </tr>
      ))}
    </tbody>
  );
};


Rows.propTypes = {
  loading: bool.isRequired,
  error: object,
  data: shape({
    filterSmsTemplates: shape({
      rows: arrayOf(shape({
        id: string.isRequired,
        typeTranslation: string,
        languageCode: string,
      })).isRequired,
    }),
  }),
  translations: object.isRequired,
  onToggleUpdate: func.isRequired,
};

Rows.defaultProps = {
  error: undefined,
  data: undefined,
};


const SmsTable = ({
  // data
  resources,
  languageId,
  updateModal,
  translations,
  // methods
  onToggleUpdate,
}) => (
  <FilteredTable
    initFilter={INIT_FILTER}
    query={QUERY_SMS_TEMPLATES}
  >
    {({
      // filter
      queryData,
      filter,
      // filter handlers
      onChangeSort,
      onChangeFilter,
      onChangeParam,
    }) => (
      <Fragment>
        {updateModal.isOpen && (
          <SmsUpdate
            modalData={updateModal}
            resources={resources}
            languageId={languageId}
            onToggle={onToggleUpdate}
            onUpdated={() => queryData.refetch()}
          />
        )}

        <Table fillContent>
          <Header
            // data
            filter={filter}
            translations={translations}
            // methods
            onChangeSort={onChangeSort}
            onChangeParam={onChangeParam}
          />
          <Rows
            // data
            {...queryData}
            resources={resources}
            translations={translations}
            // methods
            onToggleUpdate={onToggleUpdate}
          />
        </Table>

        <Pagination
          loading={queryData.loading}
          dataFilter={queryData.data && queryData.data.filterSmsTemplates
            ? queryData.data.filterSmsTemplates.filter
            : undefined
          }
          onChangePage={onChangeFilter}
        />

      </Fragment>
    )}
  </FilteredTable>
);


SmsTable.propTypes = {
  resources: object.isRequired,
  translations: object.isRequired,
  languageId: string.isRequired,
  updateModal: shape({
    isOpen: bool.isRequired,
    id: string,
  }).isRequired,
  onToggleUpdate: func.isRequired,
};


export default SmsTable;
