import React, { Component, } from 'react';
import { object, string, } from 'prop-types';

import { TRANSLATIONS_NOTIFICATIONS, } from '../gql/translations';
import { MUTATION_ACKNOWLEDGE_NOTIFICATION, } from '../gql/mutations';
import { withTranslations, } from '../../../logic/translations/withTranslations';
import Breadcrumb from '../../../atoms/Breadcrumb/Breadcrumb';
import BreadcrumbItem from '../../../atoms/Breadcrumb/BreadcrumbItem';
import PageView from '../../../atoms/PageView/PageView';
import Row from '../../../atoms/Row/Row';
import Col from '../../../atoms/Col/Col';
import ModalDelete from '../../../components/modal/ModalDelete';
import TransportationDetail from '../../Transportation/components/TransportationDetail/TransportationDetail';
import Notifications from '../components/Notifications/Notifications';


class NotificationsPage extends Component {
  state = {
    detailModal: {
      isOpen: false,
      id: null,
    },
    removeModal: {
      isOpen: false,
      id: null,
    },
  }


  toggleModalDetail = (id = null) => {
    this.setState((prevState) => ({
      detailModal: {
        ...prevState.detailModal,
        isOpen: !prevState.detailModal.isOpen,
        id,
      },
    }));
  }


  toggleModalRemoveNotification = (id = null) => {
    this.setState((prevState) => ({
      removeModal: {
        ...prevState.removeModal,
        isOpen: !prevState.removeModal.isOpen,
        id,
      },
    }));
  }


  render() {
    const {
      removeModal,
      detailModal,
    } = this.state;
    const {
      languageId,
      translations,
    } = this.props;

    return (
      <PageView>

        <Breadcrumb>
          <BreadcrumbItem to="/">{translations.common.home}</BreadcrumbItem>
          <BreadcrumbItem>{translations.common.monitoringModule}</BreadcrumbItem>
          <BreadcrumbItem>{translations.common.notifications}</BreadcrumbItem>
        </Breadcrumb>

        {detailModal.isOpen && (
          <TransportationDetail
            // data
            transportationId={detailModal.id}
            // methods
            onToggle={this.toggleModalDetail}
          />
        )}

        {removeModal.isOpen && (
          <ModalDelete
            // data
            title={translations.monitoring.modalRemoveNotificationTitle}
            text={translations.monitoring.modalRemoveNotificationText}
            mutation={MUTATION_ACKNOWLEDGE_NOTIFICATION}
            variables={{ id: removeModal.id, }}
            // methods
            onToggle={this.toggleModalRemoveNotification}
          />
        )}

        <Row>
          <Col LG={24} HD={15} HDPlus={12}>
            <Notifications
              languageId={languageId}
              translations={translations}
              toggleTransportationDetail={this.toggleModalDetail}
              toggleRemove={this.toggleModalRemoveNotification}
            />
          </Col>
        </Row>

      </PageView>
    );
  }
}


NotificationsPage.propTypes = {
  translations: object.isRequired,
  languageId: string.isRequired,
};


export default withTranslations(TRANSLATIONS_NOTIFICATIONS)(NotificationsPage);
