import React, { Component, } from 'react';
import {
  func, object, string, arrayOf,
} from 'prop-types';


import { Mutation, } from 'react-apollo';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import FormBackground from '../../../../components/Form/FormBackground';
import TechnicalCheckBasicForm from '../forms/TechnicalCheckBasicForm';
import TechnicalCheckDriverForm from '../forms/TechnicalCheckDriverForm';
import TechnicalCheckTruckAndTrailerForm from '../forms/TechnicalCheckTruckAndTrailerForm';
import TechnicalCheckCisternForm from '../forms/TechnicalCheckCisternForm';
import TechnicalCheckEntranceForm from '../forms/TechnicalCheckEntranceForm';
import TechnicalCheckLoadForm from '../forms/TechnicalCheckLoadForm';
import TechnicalCheckChamberFillingForm from '../forms/TechnicalCheckChamberFillingForm';
import TechnicalCheckDangerousGoodsForm from '../forms/TechnicalCheckDangerousGoodsForm';
import TechnicalCheckEquipmentForm from '../forms/TechnicalCheckEquipmentForm';
import TechnicalCheckResultForm from '../forms/TechnicalCheckResultForm';
import ButtonLoader from '../../../../atoms/Button/ButtonLoader';
import Input from '../../../../atoms/Input/Input';
import Label from '../../../../components/Form/Label';
import { MUTATION_UPDATE_TECHNICAL_CHECK, } from '../../gql/mutations';
import FormError from '../../../../components/Form/FormError';
import { carTypeContainsOneOf, } from './utils';
import ConfirmModal from '../../../../components/modal/ConfirmModal';


class InputTechnicalCheckView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmModal: {
        isOpen: false,
        mutation: undefined,
      },
    };
  }

  handleUpdateAndContinueClick = (mutation) => {
    const { detailForm, onUpdateAndContinue, } = this.props;

    if (!detailForm.values.technicalCheckResult) {
      this.setState({ confirmModal: { isOpen: true, mutation, }, });
    } else {
      onUpdateAndContinue(mutation);
    }
  }

  handleUpdateAndContinueConfirmation = (confirmed) => {
    const { onUpdateAndContinue, } = this.props;
    const { confirmModal, } = this.state;

    if (confirmed) {
      onUpdateAndContinue(confirmModal.mutation);
    }

    this.setState({ confirmModal: { isOpen: false, mutation: undefined, }, });
  }

  render() {
    const {
      // data
      data,
      detailForm,
      chamberForms,
      chamberFillingForms,
      loadProductForms,
      chamberFillingInForms,
      translations,
      // methods
      onChangeForm,
      onUpdateAndClose,
      onUpdateError,
      onUpdateComplete,
    } = this.props;
    const { confirmModal, } = this.state;

    return (
      <FormBackground>
        {confirmModal.isOpen && (
          <ConfirmModal
            isOpen
            title="Potvrdit pokračování"
            text={'Pozor, výsledek kontroly je "Neprošel", opravdu si přejete pokračovat?'}
            cancelTextBtn="Zpět"
            confirmTextBtn="Pokračovat"
            translations={translations}
            onToggle={this.handleUpdateAndContinueConfirmation}
          />
        )}

        <Row>
          <Col SM={24}>

            <fieldset>
              <legend>{translations.transportation.technicalCheck.titleBasicForm}</legend>
              <TechnicalCheckBasicForm
                {...detailForm}
                translations={translations}
                onChange={onChangeForm}
              />
            </fieldset>

            <fieldset>
              <legend>{translations.transportation.technicalCheck.titleDriverForm}</legend>
              <TechnicalCheckDriverForm
                {...detailForm}
                translations={translations}
                onChange={onChangeForm}
              />
            </fieldset>

            <fieldset>
              <legend>{translations.transportation.technicalCheck.titleTruckAndTrailerForm}</legend>
              <TechnicalCheckTruckAndTrailerForm
                {...detailForm}
                translations={translations}
                onChange={onChangeForm}
              />
            </fieldset>

            {carTypeContainsOneOf(detailForm.values.carType, [ 'cistern', ]) && (
              <fieldset>
                <legend>{translations.transportation.technicalCheck.titleCisternForm}</legend>
                <TechnicalCheckCisternForm
                  {...detailForm}
                  chamberForms={chamberForms}
                  chamberFillingForms={chamberFillingForms}
                  translations={translations}
                  onChange={onChangeForm}
                />
              </fieldset>
            )}

            <fieldset>
              <legend>{translations.transportation.technicalCheck.titleEntranceForm}</legend>
              <TechnicalCheckEntranceForm
                {...detailForm}
                translations={translations}
                onChange={onChangeForm}
              />
            </fieldset>

            <fieldset>
              <legend>{translations.transportation.technicalCheck.titleLoadForm}</legend>
              <TechnicalCheckLoadForm
                {...detailForm}
                loadProductForms={loadProductForms}
                translations={translations}
                onChange={onChangeForm}
              />
            </fieldset>

            {carTypeContainsOneOf(detailForm.values.carType, [ 'cistern', ]) && (
              <fieldset>
                <legend>{translations.transportation.technicalCheck.titleChamberFillingForm}</legend>
                <TechnicalCheckChamberFillingForm
                  {...detailForm}
                  chamberFillingForms={chamberFillingInForms}
                  data={data}
                  formPrefixName="chamberFillingInForm"
                  translations={translations}
                  counterForm={detailForm.form.chamberFillingInCount}
                  counterValue={detailForm.values.chamberFillingInCount}
                  onChange={onChangeForm}
                />
              </fieldset>
            )}

            <fieldset>
              <legend>{translations.transportation.technicalCheck.titleDangerousGoodsForm}</legend>
              <TechnicalCheckDangerousGoodsForm
                {...detailForm}
                translations={translations}
                onChange={onChangeForm}
              />
            </fieldset>

            <fieldset>
              <legend>{translations.transportation.technicalCheck.titleEquipmentForm}</legend>
              <TechnicalCheckEquipmentForm
                {...detailForm}
                translations={translations}
                onChange={onChangeForm}
              />
            </fieldset>

            <fieldset>
              <legend>{translations.transportation.technicalCheck.titleResultForm}</legend>
              <TechnicalCheckResultForm
                {...detailForm}
                translations={translations}
                isOutputTechnicalCheck={false}
                onChange={onChangeForm}
              />
            </fieldset>

          </Col>

        </Row>

        <Mutation
          mutation={MUTATION_UPDATE_TECHNICAL_CHECK}
          onCompleted={onUpdateComplete}
          onError={onUpdateError}
        >
          {(updateMutation, { loading, error, }) => (
            <>
              <Row>
                <Col SM={24} textAlign="right">
                  <ButtonLoader
                    color="warning"
                    onClick={() => onUpdateAndClose(updateMutation)}
                    isLoading={loading}
                  >
                    {translations.form.technicalCheckSaveAndCloseBtn}
                  </ButtonLoader>
                </Col>
              </Row>

              <Row style={{ marginTop: '2rem', }}>
                <Col SM={24}>
                  <Label label={translations.form.technicalCheckPinInfo} />
                </Col>
              </Row>

              <Row style={{ marginTop: '1rem', }}>
                <Col SM={2}>
                  <Label label="PIN:" style={{ marginTop: '8px', display: 'block', }} />
                </Col>
                <Col SM={8}>
                  <Input
                    type="text"
                    value={detailForm.values.pin}
                    onChange={(e) => onChangeForm(detailForm.form.pin.name, e.target.value)}
                    status={detailForm.form.pin.status}
                  />
                </Col>
                <Col SM={14} textAlign="right">
                  <ButtonLoader
                    color="success"
                    onClick={() => this.handleUpdateAndContinueClick(updateMutation)}
                    isLoading={loading}
                  >
                    {translations.form.technicalCheckSaveAndContinueBtn}
                  </ButtonLoader>
                </Col>
              </Row>

              <FormError
                isValid={detailForm.isValid}
                customError={detailForm.customError}
                error={error}
              />
            </>
          )}
        </Mutation>
      </FormBackground>
    );
  }
}


InputTechnicalCheckView.propTypes = {
  // data
  data: object.isRequired,
  detailForm: object.isRequired,
  chamberForms: arrayOf(object).isRequired,
  chamberFillingForms: arrayOf(object).isRequired,
  loadProductForms: arrayOf(object).isRequired,
  chamberFillingInForms: arrayOf(object).isRequired,
  languageId: string.isRequired,
  translations: object.isRequired,
  resources: object.isRequired,
  // methods
  onChangeForm: func.isRequired,
  onUpdateAndClose: func.isRequired,
  onUpdateAndContinue: func.isRequired,
  onUpdateError: func.isRequired,
  onUpdateComplete: func.isRequired,
};


export default InputTechnicalCheckView;
