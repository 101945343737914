import React, { Fragment, } from 'react';
import {
  func, object,
} from 'prop-types';

import FormRow from '../../../../components/Form/FormRow';
import Input from '../../../../atoms/Input/Input';
import InputSelect from '../../../../atoms/InputSelect/InputSelect';
import InputDateSelect from '../../../../atoms/InputDateSelect/InputDateSelect';
import { carTypeContainsOneOf, getCarTypeOptions, } from '../InputTechnicalCheck/utils';


const TechnicalCheckTruckAndTrailerForm = ({
  form,
  translations,
  values,
  onChange,
}) => (
  <Fragment>
    <FormRow
      label={translations.form.carType}
      input={form.carType}
    >
      <InputSelect
        value={values.carType}
        options={getCarTypeOptions(translations)}
        onChange={(option) => onChange(form.carType.name, option)}
        status={form.carType.status}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        placeholder={translations.common.select}
      />

    </FormRow>

    <FormRow
      label={translations.form.truckRegistrationNumber}
      input={form.truckRegistrationNumber}
    >
      <Input
        type="text"
        value={values.truckRegistrationNumber}
        onChange={(e) => onChange(form.truckRegistrationNumber.name, e.target.value)}
        status={form.truckRegistrationNumber.status}
      />
    </FormRow>

    <FormRow
      label={translations.form.carRegistrationCountry}
      input={form.carRegistrationCountry}
    >
      <Input
        type="text"
        value={values.carRegistrationCountry}
        onChange={(e) => onChange(form.carRegistrationCountry.name, e.target.value)}
        status={form.carRegistrationCountry.status}
      />
    </FormRow>

    <FormRow
      label={translations.form.adrTruckNo}
      input={form.adrTruckNo}
    >
      <Input
        type="text"
        value={values.adrTruckNo}
        onChange={(e) => onChange(form.adrTruckNo.name, e.target.value)}
        status={form.adrTruckNo.status}
      />
    </FormRow>

    <FormRow
      label={translations.form.adrTruckDate}
      input={form.adrTruckDate}
    >
      <InputDateSelect
        value={values.adrTruckDate}
        onChange={(newDate) => onChange(form.adrTruckDate.name, newDate)}
        status={form.adrTruckDate.status}
      />
    </FormRow>

    <FormRow
      label={translations.form.trailerRegistrationNumber}
      input={form.trailerRegistrationNumber}
    >
      <Input
        type="text"
        value={values.trailerRegistrationNumber}
        onChange={(e) => onChange(form.trailerRegistrationNumber.name, e.target.value)}
        status={form.trailerRegistrationNumber.status}
      />
    </FormRow>

    <FormRow
      label={translations.form.carTrailerRegistrationCountry}
      input={form.carTrailerRegistrationCountry}
    >
      <Input
        type="text"
        value={values.carTrailerRegistrationCountry}
        onChange={(e) => onChange(form.carTrailerRegistrationCountry.name, e.target.value)}
        status={form.carTrailerRegistrationCountry.status}
      />
    </FormRow>

    <FormRow
      label={translations.form.adrTrailerNo}
      input={form.adrTrailerNo}
    >
      <Input
        type="text"
        value={values.adrTrailerNo}
        onChange={(e) => onChange(form.adrTrailerNo.name, e.target.value)}
        status={form.adrTrailerNo.status}
      />
    </FormRow>

    <FormRow
      label={translations.form.adrTrailerDate}
      input={form.adrTrailerDate}
    >
      <InputDateSelect
        value={values.adrTrailerDate}
        onChange={(newDate) => onChange(form.adrTrailerDate.name, newDate)}
        status={form.adrTrailerDate.status}
      />
    </FormRow>

    <FormRow
      label={translations.form.carConstruction}
      input={form.carConstruction}
    >
      <InputSelect
        value={values.carConstruction}
        options={[ { id: null, name: translations.form.carConstructionNone, }, { id: 'FL', name: 'FL', }, { id: 'AT', name: 'AT', }, ]}
        onChange={(option) => onChange(form.carConstruction.name, option)}
        status={form.carConstruction.status}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
      />
    </FormRow>

    {carTypeContainsOneOf(values.carType, [ 'containerTrailer', 'cistern', ]) && (
      <FormRow
        label={translations.form.tankerCode}
        input={form.tankerCode}
      >
        <Input
          type="text"
          value={values.tankerCode}
          onChange={(e) => onChange(form.tankerCode.name, e.target.value)}
          status={form.tankerCode.status}
        />
      </FormRow>
    )}

    <FormRow
      label={translations.form.tankerCheckExpiration}
      input={form.tankerCheckExpiration}
    >
      <InputDateSelect
        value={values.tankerCheckExpiration}
        onChange={(newDate) => onChange(form.tankerCheckExpiration.name, newDate)}
        status={form.tankerCheckExpiration.status}
      />
    </FormRow>

  </Fragment>
);


TechnicalCheckTruckAndTrailerForm.propTypes = {
  translations: object.isRequired,
  form: object.isRequired,
  values: object.isRequired,
  onChange: func.isRequired,
};


export default TechnicalCheckTruckAndTrailerForm;
