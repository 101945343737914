import React, { Fragment, } from 'react';
import { string, object, } from 'prop-types';

import { parseDateToHuman, } from '../../../logic/date';
import { FORM_TYPES, } from '../../../globals';
import GFText from './GFText';
import GFFieldSet from './GFFieldSet';
import GFArray from './GFArray';
import GFCheckbox from './GFCheckbox';


const GenericFormReadOnly = ({
  nodeId,
  genForm,
  genForm: {
    form,
    tree,
    values,
  },
  translations,
  languageId,
}) => (
  <Fragment>
    {tree[nodeId].childs.map((key) => {
      const item = tree[key];
      switch (item.type) {
        case FORM_TYPES.NUMBER:
        case FORM_TYPES.TEXT:
          return (
            <GFText
              key={key}
              input={form[key]}
              value={values[key]}
            />
          );

        case FORM_TYPES.RADIO:
        case FORM_TYPES.SELECT:
          return (
            <GFText
              key={key}
              input={form[key]}
              value={values[key] ? values[key].name : '-'}
            />
          );

        case FORM_TYPES.TIME:
          return (
            <GFText
              key={key}
              isMulti
              input={form[key]}
              value={values[key]
                ? `${values[key].hours}:${values[key].minutes}`
                : '-'}
            />
          );

        case FORM_TYPES.DATE:
        case FORM_TYPES.TIMESTAMP:
          return (
            <GFText
              key={key}
              isMulti
              input={form[key]}
              value={values[key] ? parseDateToHuman(new Date(values[key])) : '-'}
            />
          );

        case FORM_TYPES.MULTISELECT:
          return (
            <GFArray
              key={key}
              isMulti
              input={form[key]}
              value={values[key] ? values[key] : []}
            />
          );

        case FORM_TYPES.CHECKBOX:
          return (
            <GFCheckbox
              key={key}
              input={form[key]}
              value={values[key]}
              translations={translations}
            />
          );

        case FORM_TYPES.FIELDSET:
          return (
            <GFFieldSet
              key={key}
              genForm={genForm}
              nodeId={key}
              translations={translations}
              languageId={languageId}
            />
          );

        default:
          return null;
      }
    })}
  </Fragment>
);


GenericFormReadOnly.propTypes = {
  genForm: object.isRequired,
  nodeId: string.isRequired,
  translations: object.isRequired,
  languageId: string.isRequired,
};


export default GenericFormReadOnly;
