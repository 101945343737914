import React, { Component, } from 'react';
import { func, object, string, } from 'prop-types';
import { Query, withApollo, } from 'react-apollo';

import { QUERY_ALL_COMPANIES, QUERY_ONE_COMPANY, } from '../../gql/queries';
import { pipe, } from '../../../../logic/utils';
import { withNotifications, } from '../../../../logic/notifications/withNotifications';
import FilterStandardView from './FilterStandardView';


const initState = {
  filter: null,
  applyData: {
    loading: false,
  },
};


class CompanyFilter extends Component {
  state = { ...initState, }


  handleChangeFilter = (value) => {
    this.setState({
      filter: value,
    });
  }


  getOneCompany = async () => {
    const { filter, } = this.state;
    const {
      client, onApply, translations, addNotification,
    } = this.props;

    try {
      this.setState({
        applyData: {
          loading: true,
        },
      });

      const response = await client.query({
        query: QUERY_ONE_COMPANY,
        variables: { id: filter ? filter.id : undefined, },
      });

      this.setState({
        applyData: {
          loading: false,
        },
      });

      if (response && response.data && response.data.fetchOneCompany) {
        const company = response.data.fetchOneCompany;
        onApply([
          {
            name: 'compName',
            value: company.name,
          },
          {
            name: 'compIdentificationNumber',
            value: company.identificationNumber,
          },
          {
            name: 'compVatNumber',
            value: company.vatNumber,
          },
          {
            name: 'compStreet',
            value: company.street,
          },
          {
            name: 'compCity',
            value: company.city,
          },
          {
            name: 'compZip',
            value: company.zip,
          },
          {
            name: 'compState',
            value: company.state,
          },
          {
            name: 'compContactPerson',
            value: company.contactPerson,
          },
          {
            name: 'compEmail',
            value: company.email,
          },
          {
            name: 'compPhoneNumber',
            value: company.phoneNumber,
          },
          {
            name: 'compId',
            value: company.id,
          },
        ]);
      }
    } catch (error) {
      addNotification({
        status: 'error',
        title: translations.errors.SOMETHING_HAPPENED,
      });
    }
  }


  getOptionLabel = (option) => `${option.name} - ${option.identificationNumber}`


  render() {
    const {
      filter,
      applyData,
    } = this.state;
    const { placeholder, translations, } = this.props;

    return (
      <Query
        query={QUERY_ALL_COMPANIES}
        fetchPolicy="cache-and-network"
      >
        {(filterData) => (
          <FilterStandardView
            // data
            filter={filter}
            filterData={filterData}
            options={filterData.data ? filterData.data.fetchAllCompanies : []}
            applyData={applyData}
            placeholder={placeholder}
            translations={translations}
            // methods
            getOptionLabel={this.getOptionLabel}
            onChange={this.handleChangeFilter}
            onApply={this.getOneCompany}
          />
        )}
      </Query>
    );
  }
}


CompanyFilter.propTypes = {
  client: object.isRequired,
  onApply: func.isRequired,
  placeholder: string,
  translations: object.isRequired,
  addNotification: func.isRequired,
};

CompanyFilter.defaultProps = {
  placeholder: '',
};


export default pipe(
  withNotifications,
  withApollo
)(CompanyFilter);
