import styled from 'styled-components';

import { getColorModal, } from './utils';


const StyledModalHeader = styled.div`
  text-align: right;
  align-items: center;
  display: flex;
  border-top-left-radius: ${(p) => p.theme.input.borderRadius};
  border-top-right-radius: ${(p) => p.theme.input.borderRadius};
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: ${(p) => p.theme.common.padding};

  ${getColorModal}

  .modal-content--title {
    font-size: 1.25rem;
    font-weight: 500;
  }

  .modal-content--exit {
    margin-left: auto;
    background: transparent;
    border: none; 
    line-height: 1.25rem;

    width: 1.25rem;
    height: 1.25rem;
    border-radius: 0%;
    padding: 0;

    cursor: pointer;
  }
`;

export default StyledModalHeader;
