import React, { Fragment, } from 'react';
import { number, } from 'prop-types';


const RatioChartColumn = ({ ratio, }) => (
  <Fragment>
    <div
      className="ratio-chart-column--top"
      style={{ height: `${(1 - Math.min(ratio, 1)) * 100}%`, }}
    />
    <div
      className="ratio-chart-column--down"
      style={{ height: `${Math.min(ratio, 1) * 100}%`, }}
    />
    <div className="ratio-chart-column--tooltip">
      {Math.ceil(ratio * 1000) / 10}
      %
    </div>
  </Fragment>
);


RatioChartColumn.propTypes = {
  ratio: number.isRequired,
};


export default RatioChartColumn;
