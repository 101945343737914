import React, { Fragment, } from 'react';
import {
  func, object, string, shape, arrayOf, number, bool,
} from 'prop-types';
import { Mutation, } from 'react-apollo';

import { MUTATION_EDIT_TRANSPORTATION, } from '../../gql/mutations';
import Button from '../../../../atoms/Button/Button';
import ButtonLoader from '../../../../atoms/Button/ButtonLoader';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import ButtonGrp from '../../../../atoms/Button/ButtonGrp';
import FormError from '../../../../components/Form/FormError';
import FormBackground from '../../../../components/Form/FormBackground';
import TransportationTruckForm from '../forms/TransportationTruckForm';
import TransportationDriverForm from '../forms/TransportationDriverForm';
import TransportationCompanyForm from '../forms/TransportationCompanyForm';
import TransportationReservationForm from '../forms/TransportationReservationForm';
import TransportationTrailerForm from '../forms/TransportationTrailerForm';
import DriverFilter from '../filters/DriverFilter';
import TruckFilter from '../filters/TruckFilter';
import RelatedTransportations from '../RelatedTransportations';
import TransportationExtraForm from '../forms/TransportationExtraForm';
import TransportationDocumentEditForm from '../forms/TransportationDocumentEditForm';
import { isCollectionService, } from '../utils';
import OrderView from '../TransportationDetail/OrderView';
import TransportationPassengerForm from '../forms/TransportationPassengerForm';
import LoadingForm from '../forms/LoadingForm';
import FormRowReadOnly from '../../../../components/Form/FormRowReadOnly';
import ValueText from '../../../../atoms/Values/ValueText';


const TransportationEditView = ({
  // data
  data,
  detailForm,
  productForms,
  options,
  languageId,
  translations,
  resources,
  reservationTimeBlocks,
  reservationTimeDuration,
  isLoadingReservationTimeBlocks,
  // methods
  onToggle,
  onChangeForm,
  onEdit,
  onEditError,
  onEditComplete,
  onFillFormValues,
  onAddNewCompany,
  onDelete,
}) => (
  <FormBackground>

    <Row>
      <Col HD={8} LG={12} MD={24}>

        <fieldset>
          <legend>{translations.transportation.titleReservationForm}</legend>
          <TransportationReservationForm
            {...detailForm}
            optionsReservationTypes={options.reservationTypes}
            reservationTimeBlocks={reservationTimeBlocks}
            isLoadingReservationTimeBlocks={isLoadingReservationTimeBlocks}
            reservationTimeDuration={reservationTimeDuration}
            languageId={languageId}
            translations={translations}
            onChange={onChangeForm}
          />
        </fieldset>

        <RelatedTransportations
          prev={data.prevTransport}
          next={data.nextTransport}
          translations={translations}
        />

      </Col>
      <Col HD={8} LG={12} MD={24}>

        <fieldset>
          <legend>{translations.transportation.titleCompanyForm}</legend>
          <TransportationCompanyForm
            {...detailForm}
            translations={translations}
            onChange={onChangeForm}
          />

          <FormRowReadOnly label={translations.form.hasOwnTransport}>
            <ValueText>
              {data.hasOwnTransport ? translations.common.yes : translations.common.no}
            </ValueText>
          </FormRowReadOnly>
        </fieldset>

        <fieldset>
          <legend>{translations.transportation.titleTruckForm}</legend>
          <TruckFilter
            onApply={onFillFormValues}
            placeholder={translations.transportation.placeholderFilterTruck}
            translations={translations}
          />
          <TransportationTruckForm
            {...detailForm}
            translations={translations}
            onChange={onChangeForm}
          />
        </fieldset>

        <fieldset>
          <legend>{translations.transportation.titleTrailerForm}</legend>
          <TransportationTrailerForm
            {...detailForm}
            translations={translations}
            onChange={onChangeForm}
          />
        </fieldset>

        {isCollectionService(data.serviceType) && (<OrderView translations={translations} orders={data.orders} />)}

      </Col>
      <Col HD={8} LG={12} MD={24}>

        <fieldset>
          <legend>{translations.transportation.titleDriverForm}</legend>
          <DriverFilter
            onApply={onFillFormValues}
            placeholder={translations.transportation.placeholderFilterDriver}
            translations={translations}
          />
          <TransportationDriverForm
            {...detailForm}
            translations={translations}
            onChange={onChangeForm}
          />
        </fieldset>

        <fieldset>
          <legend>{translations.form.passenger}</legend>
          <DriverFilter
            onApply={onFillFormValues}
            placeholder={translations.transportation.placeholderFilterDriver}
            translations={translations}
            forPassenger
          />
          <TransportationPassengerForm
            {...detailForm}
            translations={translations}
            onChange={onChangeForm}
          />
        </fieldset>

        <fieldset>
          <legend>{translations.transportation.titleExtraForm}</legend>
          <TransportationExtraForm
            {...detailForm}
            translations={translations}
            reservationForms={[ detailForm, ]}
            isEdit
            onChange={onChangeForm}
          />
        </fieldset>

      </Col>

      <Col MD={24}>
        <fieldset>
          <legend>{translations.transportation.titleLoadingForm}</legend>
          <LoadingForm
            productForms={productForms}
            translations={translations}
            resources={resources}
          />
        </fieldset>
      </Col>

      <Col MD={24}>
        <fieldset>
          <legend>{translations.transportation.titleDocumentForm}</legend>
          <TransportationDocumentEditForm
            {...detailForm}
            translations={translations}
          />
        </fieldset>
      </Col>

    </Row>

    <Mutation
      mutation={MUTATION_EDIT_TRANSPORTATION}
      onCompleted={onEditComplete}
      onError={onEditError}
    >
      {(editMutation, { loading, error, }) => (
        <Fragment>
          <FormError
            isValid={detailForm.isValid}
            customError={detailForm.customError}
            error={error}
          />
          <ButtonGrp block>
            <Button
              shape="outline"
              onClick={onToggle}
            >
              {translations.common.back}
            </Button>
            {resources.delete_transportation && (
            <Button
              className="buttonGrp--right"
              onClick={onDelete}
              color="error"
            >
              {translations.common.delete}
            </Button>
            )}
            <ButtonLoader
              color="success"
              onClick={() => onEdit(editMutation)}
              disabled={!detailForm.isValid}
              isLoading={loading}
            >
              {translations.common.update}
            </ButtonLoader>
          </ButtonGrp>
        </Fragment>
      )}
    </Mutation>
  </FormBackground>
);


TransportationEditView.propTypes = {
  // data
  data: shape({
    prevTransport: string,
    nextTransport: string,
  }).isRequired,
  detailForm: object.isRequired,
  productForms: arrayOf(object),
  options: shape({
    reservationTypes: arrayOf(object).isRequired,
  }).isRequired,
  languageId: string.isRequired,
  translations: object.isRequired,
  resources: object.isRequired,
  reservationTimeBlocks: arrayOf(shape({
    id: number.isRequired,
    name: string.isRequired,
  })).isRequired,
  isLoadingReservationTimeBlocks: bool.isRequired,
  reservationTimeDuration: number,
  // methods
  onChangeForm: func.isRequired,
  onToggle: func.isRequired,
  onEdit: func.isRequired,
  onEditError: func.isRequired,
  onEditComplete: func.isRequired,
  onFillFormValues: func.isRequired,
  onAddNewCompany: func.isRequired,
  onDelete: func.isRequired,
};

TransportationEditView.defaultProps = {
  reservationTimeDuration: null,
};

export default TransportationEditView;
