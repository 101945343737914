import React, { Fragment, } from 'react';
import { func, object, } from 'prop-types';
import { Mutation, } from 'react-apollo';

import { MUTATION_SOE_STAND_CALL, } from '../../gql/mutations';
import Button from '../../../../atoms/Button/Button';
import ButtonLoader from '../../../../atoms/Button/ButtonLoader';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import FormError from '../../../../components/Form/FormError';
import FormBackground from '../../../../components/Form/FormBackground';
import StandCallForm from './StandCallForm';


const StandCallView = ({
  // data
  form,
  translations,
  // methods
  onChange,
  onToggle,
  onCall,
  onCallError,
  onCallComplete,
}) => (
  <FormBackground>
    <StandCallForm
      {...form}
      translations={translations}
      onChange={onChange}
    />

    <Mutation
      mutation={MUTATION_SOE_STAND_CALL}
      onCompleted={onCallComplete}
      onError={onCallError}
    >
      {(mutate, { loading, error, }) => (
        <Fragment>
          <FormError
            isValid={form.isValid}
            error={error}
          />
          <Row>
            <Col XS={12} SM={12}>
              <Button
                onClick={onToggle}
              >
                {translations.common.back}
              </Button>
            </Col>
            <Col XS={12} SM={12} textAlign="right">
              <ButtonLoader
                color="success"
                onClick={() => onCall(mutate)}
                disabled={!form.isValid}
                isLoading={loading}
              >
                {translations.stand.standCallBtn}
              </ButtonLoader>
            </Col>
          </Row>
        </Fragment>
      )}
    </Mutation>
  </FormBackground>
);


StandCallView.propTypes = {
  translations: object.isRequired,
  form: object.isRequired,
  onChange: func.isRequired,
  onToggle: func.isRequired,
  onCall: func.isRequired,
  onCallError: func.isRequired,
  onCallComplete: func.isRequired,
};


export default StandCallView;
