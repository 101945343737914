export const isValidArray = (array) => array && Array.isArray(array);

export const onlyUnique = (array) => [ ...new Set(array), ];

export const resizeArray = (arr, newSize, defaultValue) => {
  if (newSize < arr.length) {
    return arr.slice(0, newSize);
  }
  return [ ...arr, ...Array(Math.max(newSize - arr.length, 0)).fill(null).map(() => JSON.parse(JSON.stringify(defaultValue))), ];
};

export const sliceIntoChunks = (arr, chunkSize) => {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
};

export const removeFromArray = (arr, itemsToRemove) => arr.filter((item) => itemsToRemove.indexOf(item) === -1);

export const unique = (array, equality = (a, b) => a === b) => array.filter((item, index, arr) => {
  const size = arr.length;

  for (let i = 0; i < size; i++) {
    if (equality(item, arr[i])) {
      return i === index;
    }
  }

  return true;
});
