import { v4, } from 'uuid';

import { roundToLowerHour, } from '../../logic/date';


// TODO rewrite parseData.js and merge

const timeDiff = (t1, t2) => (t1 - t2);
const hoursDiff = (t1, t2) => (timeDiff(t1, t2) / (1000 * 60 * 60));


const compare = (a, b) => {
  if (a.from < b.from) return -1;
  if (a.from > b.from) return 1;
  return 0;
};


const parseFilter = (filter) => {
  const from = new Date(filter.from);
  const to = new Date(filter.to);
  const diff = timeDiff(to, from);
  const hours = Math.ceil(diff / (1000 * 60 * 60));

  if (diff < 0) throw new Error('invalid from, to');

  return {
    from: roundToLowerHour(from),
    to,
    hours,
  };
};


const parseItem = (item, fromTimeline, hours) => {
  if (!item.from || !item.to) return null;

  let from = hoursDiff(new Date(item.from), fromTimeline);
  let to = hoursDiff(new Date(item.to), fromTimeline);
  let diff = to - from;
  let overFlowLeft = false;
  let overFlowRight = false;

  if (diff <= 0) return null;

  // start before timeline
  if (from < 0) {
    diff = to;
    from = 0;
    overFlowLeft = true;
  }

  // end after timeline
  if (to > hours) {
    to = hours;
    diff = hours - from;
    overFlowRight = true;
  }

  return {
    id: v4(),
    from,
    to,
    overFlowLeft,
    overFlowRight,
    width: diff,
    payload: item,
  };
};


const createNewRowInRows = () => ({
  id: v4(),
  items: [],
});


const addItemToRows = (rows, item) => {
  let added = false;

  for (let i = 0; i < rows.length; i++) {
    const actualRow = rows[i];
    const lastRowItem = actualRow.items[actualRow.items.length - 1];

    if (lastRowItem.to <= item.from) {
      actualRow.items.push(item);
      added = true;
      break;
    }
  }
  // if not added - add to new row
  if (!added) {
    const newRow = createNewRowInRows();
    newRow.items.push(item);
    rows.push(newRow);
  }
};


/**
 * Timeline By TransportationTypes
 */
export const parseTimelineByTransportationType = ({
  filter,
  transportationTypes,
  reservation,
}) => {
  const sections = [];
  const mapIdToRow = {};

  // parse filter
  const { from, to, hours, } = parseFilter(filter);

  // create sections
  for (let i = 0; i < transportationTypes.length; i++) {
    const element = transportationTypes[i];
    // add to sections
    sections.push({
      id: v4(),
      payload: element,
      rows: [],
    });
    // add to map object
    mapIdToRow[element.id] = i;
  }

  // parse Items
  const items = [];
  for (let j = 0; j < reservation.length; j++) {
    const item = reservation[j];
    const parsedItem = parseItem({ ...item, isActive: true, }, from, hours);
    items.push(parsedItem);
  }

  // add Items to Sections
  const sortedItems = items.sort(compare);
  for (let k = 0; k < sortedItems.length; k++) {
    const item = sortedItems[k];
    if (Object.prototype.hasOwnProperty.call(mapIdToRow, item.payload.transportationTypeId)) {
      const sectionIndex = mapIdToRow[item.payload.transportationTypeId];
      addItemToRows(sections[sectionIndex].rows, item);
    }
  }

  return {
    from,
    to,
    hours,
    sections,
  };
};
