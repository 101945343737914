import React from 'react';
import {
  bool, object, arrayOf, shape, string, func,
} from 'prop-types';

import InputSelect from '../../../../atoms/InputSelect/InputSelect';
import ButtonLoader from '../../../../atoms/Button/ButtonLoader';
import FormGroup from '../../../../atoms/FormGroup/FormGroup';

import StyledFilterStandardView from './styles/StyledFilterStandardView';


const FilterStandardView = ({
  // data
  filter,
  filterData,
  options,
  applyData,
  placeholder,
  translations,
  // methods
  getOptionLabel,
  getOptionValue,
  onChange,
  onApply,
}) => (
  <StyledFilterStandardView>

    <FormGroup>
      <InputSelect
        size="sm"
        value={filter}
        options={options}
        isClearable
        onChange={onChange}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        isLoading={filterData.loading}
        error={filterData.error}
        placeholder={placeholder}
      />

      <ButtonLoader
        size="sm"
        color="tertiary"
        disabled={filterData.loading || filterData.error || !filter}
        isLoading={applyData.loading}
        onClick={onApply}
      >
        {translations.transportation.btnApplyFilter}
      </ButtonLoader>
    </FormGroup>

    <div className="filterStandard--divider" />

  </StyledFilterStandardView>
);


FilterStandardView.propTypes = {
  // data
  filter: object,
  filterData: shape({
    loading: bool.isRequired,
    error: object,
  }).isRequired,
  options: arrayOf(object),
  applyData: shape({
    loading: bool.isRequired,
  }),
  placeholder: string,
  translations: object.isRequired,
  // methods
  getOptionLabel: func,
  getOptionValue: func,
  onChange: func.isRequired,
  onApply: func.isRequired,
};

FilterStandardView.defaultProps = {
  // data
  options: [],
  filter: null,
  placeholder: '',
  applyData: {
    loading: false,
  },
  // methods
  getOptionLabel: (option) => (option.name),
  getOptionValue: (option) => (option.id),
};


export default FilterStandardView;
