import gql from 'graphql-tag';


export const SUBSCRIPTION_REFRESH_TRANSPORTATION_SAMPLES = gql`
  subscription RefreshTransportationSamples {
    refreshTransportationSamples {
      id
    }
  }
`;
