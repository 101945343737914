import gql from 'graphql-tag';


/**
 * Auth
 */
export const SUBSCRIPTION_USER = gql`
  subscription User($id: ID) {
    user(id: $id) {
      user {
        id
        username
      }
      resources
    }
  }
`;
