import styled from 'styled-components';

import { HEADER_HEIGHT, } from '../../../../globals';


const StyledNotFound = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  height: ${`calc(100vh - ${HEADER_HEIGHT}rem)`};


  .notAuthorized {
    margin-top: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(p) => p.theme.grey.t400};  
  }

  .notAuthorized--lock {
    font-size: 5rem;
  }

  .notAuthorized--info {
    margin-left: 1rem;
  }

  .notAuthorized--info-title {
    font-size: 1.15rem;
    font-weight: 500;
  }

  .notAuthorized--info-text {
    font-weight: 300;
  }


  .notAuthorized--redirect {

  }

  .notAuthorized--redirect-link {
    color: ${(p) => p.theme.tertiary.t700}
  }

  .notAuthorized--redirect-link:hover {
    color: ${(p) => p.theme.tertiary.t900}
  }
`;


export default StyledNotFound;
