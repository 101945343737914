import React, { Component, } from 'react';
import styled from 'styled-components';

import { Query, } from 'react-apollo';
import Breadcrumb from '../../../atoms/Breadcrumb/Breadcrumb';
import BreadcrumbItem from '../../../atoms/Breadcrumb/BreadcrumbItem';
import PageView from '../../../atoms/PageView/PageView';
import { EMPTY_LAYOUT_CONFIG, } from '../utils/config';
import { QUERY_REPORTS_CONFIG, } from '../gql/queries';
import ControlPanel from '../components/ControlPanel';
import { unique, } from '../../../logic/array';
import ReportRow from '../components/charts/ReportRow';


const StyledReportingChartsBody = styled.div`
  .report-row {
    background: ${(p) => p.theme.white};
    padding: 1rem;
  }
  
  .root-row {
    margin: 2rem 0;
  }
  
  .report-table {
    margin-bottom: 1rem;
  }
`;

class ReportingChartsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [], // PeriodMetrics[]
      dataState: 'none', // "none" | "loading" | "ready" | "error"
      layout: EMPTY_LAYOUT_CONFIG,
    };
  }

  handleDataStateChange = (dataState) => {
    this.setState({ dataState, });
  };

  handleFetchedData = (data, layout) => {
    this.setState({
      data,
      layout: (!layout || layout.rows === undefined) ? EMPTY_LAYOUT_CONFIG : layout,
    });
  }

  render() {
    const { data, dataState, layout, } = this.state;

    return (
      <PageView>

        <Breadcrumb>
          <BreadcrumbItem to="/">Domů</BreadcrumbItem>
          <BreadcrumbItem>Reporting</BreadcrumbItem>
        </Breadcrumb>

        <Query query={QUERY_REPORTS_CONFIG} fetchPolicy="network-only">
          {({ data: reportsConfigData, loading, error, }) => {
            if (loading || !reportsConfigData || error) {
              return <></>;
            }

            return (
              <>
                <ControlPanel
                  reports={unique(
                    reportsConfigData.getReportsByLoggedUser,
                    (a, b) => a.id === b.id,
                  )}
                  transportationTypes={reportsConfigData.fetchAllTransportationTypes}
                  onDataStateChange={this.handleDataStateChange}
                  onDataFetched={this.handleFetchedData}
                />

                {dataState === 'ready' && (
                  <StyledReportingChartsBody>
                    {layout.rows.map((row, i) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <ReportRow key={`row-${i}`} config={row} data={data} root />
                    ))}
                  </StyledReportingChartsBody>
                )}
              </>
            );
          }}
        </Query>
      </PageView>
    );
  }
}


export default ReportingChartsPage;
