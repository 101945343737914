import React, { Component, Fragment, } from 'react';
import { string, } from 'prop-types';

import StyledTooltip from './StyledTooltip';


class Tooltip extends Component {
  constructor(props) {
    super(props);

    this.attrs = {
      onMouseEnter: this.onMouseEnter,
      onMouseLeave: this.onMouseLeave,
    };

    this.state = {
      hover: false,
      coordinates: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
    };
  }


  onMouseEnter = (e) => {
    const coordinates = this.getTooltipPosition(e);
    if (!coordinates) return;

    this.setState({
      hover: true,
      coordinates,
    });
  }

  onMouseLeave = () => {
    this.setState({ hover: false, });
  }


  getTooltipPosition = (e) => {
    try {
      const target = e.currentTarget;
      const rect = target.getBoundingClientRect();

      const coordinates = {
        top: `${rect.bottom}px`,
        bottom: `calc(100vh - ${rect.top}px)`,
        left: `${rect.right}px`,
        right: `calc(100vw - ${rect.left}px)`,
      };

      return coordinates;
    } catch (error) {
      return null;
    }
  }


  render() {
    const { hover, coordinates, } = this.state;
    const { children, position, text, } = this.props;

    return (
      <Fragment>

        {hover && (
          <StyledTooltip position={position} {...coordinates}>
            {text}
          </StyledTooltip>
        )}

        {children(this.attrs)}

      </Fragment>
    );
  }
}


Tooltip.propTypes = {
  position: string,
  text: string.isRequired,
};

Tooltip.defaultProps = {
  position: 'topLeft',
};


export default Tooltip;
