import gql from 'graphql-tag';


export const MUTATION_UPDATE_REPORT_USER_GROUP = gql`
  mutation UpdateReportUserGroup($id: Int, $reportUserGroup: reportUserGroupInputType) {
    updateReportUserGroup(id: $id, reportUserGroup: $reportUserGroup) {
      id
    }
  }
`;

export const MUTATION_CREATE_REPORT_USER_GROUP = gql`
  mutation CreateReportUserGroup($reportUserGroup: reportUserGroupInputType) {
    createReportUserGroup(reportUserGroup: $reportUserGroup) {
      id
    }
  }
`;

export const MUTATION_DELETE_REPORT_USER_GROUP = gql`
  mutation DeleteReportUserGroup($reportUserGroupId: Int) {
    deleteReportUserGroup(reportUserGroupId: $reportUserGroupId)
  }
`;

export const MUTATION_UPDATE_REPORT_NOTIFICATION_GROUP = gql`
  mutation UpdateReportNotificationGroup($id: Int, $reportNotificationGroup: reportNotificationGroupInputType) {
    updateReportNotificationGroup(id: $id, reportNotificationGroup: $reportNotificationGroup) {
      id
    }
  }
`;

export const MUTATION_CREATE_REPORT_NOTIFICATION_GROUP = gql`
  mutation CreateReportNotificationGroup($reportNotificationGroup: reportNotificationGroupInputType) {
    createReportNotificationGroup(reportNotificationGroup: $reportNotificationGroup) {
      id
    }
  }
`;


export const MUTATION_DELETE_REPORT_NOTIFICATION_GROUP = gql`
  mutation DeleteReportNotificationGroup($reportNotificationGroupId: Int) {
    deleteReportNotificationGroup(reportNotificationGroupId: $reportNotificationGroupId)
  }
`;
