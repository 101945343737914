import React from 'react';
import { func, string, object, } from 'prop-types';
import { Query, } from 'react-apollo';

import { TRANSLATIONS_TRANSPORTATION, } from '../../gql/translations';
import { QUERY_TRANSPORTATION_DETAIL, } from '../../gql/queries';
import { withTranslations, } from '../../../../logic/translations/withTranslations';
import Modal from '../../../../atoms/Modal/Modal';
import PartLoading from '../../../../components/Parts/PartLoading';
import PartError from '../../../../components/Parts/PartError';
import TransportationEditLogic from './TransportationEditLogic';
import { withUser, } from '../../../../logic/auth/withUser';
import { pipe, } from '../../../../logic/utils';
import Header from '../TransportationDetail/Header';


const TransportationEdit = ({
  // data
  transportationId,
  languageId,
  translations,
  resources,
  // methods
  onToggle,
  onDeleted,
  onEdited,
}) => (
  <Modal
    isOpen
    title={translations.transportation.editModalTransportationTitle}
    size="XL"
    onClose={onToggle}
    headerChild={<Header transportationId={transportationId} translations={translations} />}
    disablePadding
  >
    <Query
      query={QUERY_TRANSPORTATION_DETAIL}
      fetchPolicy="network-only"
      variables={{
        id: transportationId,
        languageId,
      }}
    >
      {({ loading, error, data, }) => {
        if (loading) {
          return (
            <div className="app--padding">
              <PartLoading />
            </div>
          );
        }
        if (error || !data.fetchOneTransportation) {
          return (
            <div className="app--padding">
              <PartError error={error} />
            </div>
          );
        }
        return (
          <TransportationEditLogic
            // data
            id={transportationId}
            data={data.fetchOneTransportation}
            translations={translations}
            languageId={languageId}
            resources={resources}
            // methods
            onToggle={onToggle}
            onDeleted={onDeleted}
            onEdited={onEdited}
          />
        );
      }}
    </Query>
  </Modal>
);


TransportationEdit.propTypes = {
  // data
  transportationId: string.isRequired,
  languageId: string.isRequired,
  translations: object.isRequired,
  resources: object.isRequired,
  // methods
  onToggle: func.isRequired,
  onDeleted: func,
  onEdited: func,
};

TransportationEdit.defaultProps = {
  onDeleted: undefined,
  onEdited: undefined,
};


export default pipe(
  withTranslations(TRANSLATIONS_TRANSPORTATION),
  withUser,
)(TransportationEdit);
