import React, { Component, } from 'react';
import {
  string, shape, object, func,
} from 'prop-types';

import { SCHEDULE_TABS, } from '../../../pages/SchedulePage';
import PageView from '../../../../../atoms/PageView/PageView';
import TransportationCreate from '../../TransportationCreate/TransportationCreate';
import TransportationEdit from '../../TransportationEdit/TransportationEdit';
import TransportationAdmin from '../../TransportationAdmin/TransportationAdmin';
import ScheduleView from '../ScheduleView';
import TimelineView from './TimelineView';
import TimelineFilter from '../TimelineFilter';
import TimelineLegend from '../TimelineLegend';


class TabView extends Component {
  state = {
    createTransportationModal: {
      isOpen: false,
    },
    editTransportationModal: {
      isOpen: false,
      id: null,
    },
    adminTransportationModal: {
      isOpen: false,
      id: null,
    },
  }


  /**
   * Modal Create - Toggle
   */
  handleToggleTransportationCreate = () => {
    this.setState((prevState) => ({
      createTransportationModal: {
        isOpen: !prevState.createTransportationModal.isOpen,
      },
    }));
  }


  /**
   * Modal Edit / Admin - Open
   */
  handleOpenTransportationEdit = (item) => {
    const { id, arrivalState, } = item;

    if (
      !arrivalState
      || arrivalState === 'delayed'
      || arrivalState === 'notComing'
    ) {
      this.setState({
        editTransportationModal: {
          isOpen: true,
          id,
        },
      });
    } else {
      this.setState({
        adminTransportationModal: {
          isOpen: true,
          id,
        },
      });
    }
  }


  /**
   * Modal Edit / Admin - Close
   */
  handleCloseTransportationEdit = () => {
    this.setState({
      editTransportationModal: {
        isOpen: false,
        id: null,
      },
      adminTransportationModal: {
        isOpen: false,
        id: null,
      },
    });
  }


  handleRefetchTimelines = () => {
    const { timelineData: { refetch, }, } = this.props;

    refetch();
  }


  render() {
    const {
      createTransportationModal,
      editTransportationModal,
      adminTransportationModal,
    } = this.state;
    const {
      // data
      timeline,
      timelineData,
      initFilter,
      languageId,
      translations,
      resources,
      // methods
      onChangePage,
    } = this.props;

    return (
      <PageView>

        {createTransportationModal.isOpen && (
          <TransportationCreate
            // data
            modalData={createTransportationModal}
            // methods
            onToggle={this.handleToggleTransportationCreate}
            onCreated={this.handleRefetchTimelines}
          />
        )}

        {editTransportationModal.isOpen && (
          <TransportationEdit
            // data
            transportationId={editTransportationModal.id}
            // methods
            onToggle={this.handleCloseTransportationEdit}
            onDeleted={this.handleRefetchTimelines}
            onEdited={this.handleRefetchTimelines}
          />
        )}

        {adminTransportationModal.isOpen && (
          <TransportationAdmin
            // data
            transportationId={adminTransportationModal.id}
            // methods
            onToggle={this.handleCloseTransportationEdit}
            onDeleted={this.handleRefetchTimelines}
            onEdited={this.handleRefetchTimelines}
          />
        )}

        <ScheduleView
          activeTab={SCHEDULE_TABS.TIMELINE_PLATFORMS}
          translations={translations}
          resources={resources}
          // methods
          onChangePage={onChangePage}
          onCreateTransportation={this.handleToggleTransportationCreate}
        >

          <TimelineFilter
            initFilter={initFilter}
            languageId={languageId}
            translations={translations}
          />

          <TimelineView
            // data
            timeline={timeline}
            timelineData={timelineData}
            translations={translations}
            // methods
            onEdit={this.handleOpenTransportationEdit}
          />

          <TimelineLegend translations={translations} />

        </ScheduleView>
      </PageView>
    );
  }
}


TabView.propTypes = {
  // data
  timeline: object,
  timelineData: shape({
    refetch: func.isRequired,
  }).isRequired,
  initFilter: object.isRequired,
  languageId: string.isRequired,
  translations: object.isRequired,
  resources: object.isRequired,
  // methods
  onChangePage: func.isRequired,
};

TabView.defaultProps = {
  timeline: null,
};


export default TabView;
