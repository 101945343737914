import styled from 'styled-components';


const StyledTimelineLegendLegend = styled.div`
  padding: ${(p) => p.theme.common.padding};
  background: ${(p) => p.theme.grey.t50};

  h5 {
    padding: 0;
    margin: 0;
  }
  
  ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
  }
  
  li {
    display: block;
    display: flex;
    padding: ${(p) => p.theme.common.paddingHalf};
  }


  .timelineLegend--circle {
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
    color: red;
  }

  .timelineLegend--circle1 {
    color: ${(p) => p.theme.tertiary.t100}
  }

  .timelineLegend--circle2 {
    color: ${(p) => p.theme.success.t100}
  }

  .timelineLegend--circle3 {
    color: ${(p) => p.theme.success.t300}
  }

  .timelineLegend--circle4 {
    color: ${(p) => p.theme.warning.t600}
  }

  .timelineLegend--circle5 {
    color: ${(p) => p.theme.error.t500}
  }

  .timelineLegend--circle6 {
    color: ${(p) => p.theme.grey.t200}
  }
`;


export default StyledTimelineLegendLegend;
