import { initForm, initInput, } from '../../../../logic/form/common';


export const initVisitorForm = {
  ...initForm,
  form: {
    name: initInput({
      name: 'name',
      validation: {
        required: true,
        max: 255,
      },
    }),
    licensePlate: initInput({
      name: 'licensePlate',
    }),
    notice: initInput({
      name: 'notice',
    }),
  },
  values: {
    name: '',
    licensePlate: '',
    notice: '',
  },
};
