import { parseDayMonthFromISO, } from '../../../logic/date';


const EMPTY_METRICS = {
  name: '',

  reservationCount: 0,
  registrationBeforeReservationStartCount: 0,
  registrationInReservationWindowCount: 0,
  registrationAfterReservationEndCount: 0,
  startClearanceBeforeReservationWindowPlusIntervalCount: 0,
  startClearanceInReservationWindowPlusIntervalCount: 0,
  startClearanceAfterReservationEndCount: 0,
  endClearanceInReservationWindowCount: 0,
  endClearanceBeforeReservationWindowPlusIntervalCount: 0,
  endClearanceAfterReservationWindowPlusIntervalCount: 0,
  clearedCount: 0,
  notClearedCount: 0,
  avgWaitingTimeInMinutes: 0,
  waitingMoreThanMinutesCount: 0,
  avgClearingTimeInMinutes: 0,
  clearingMoreThanMinutesCount: 0,
  avgRegistrationToDepartureTimeInMinutes: 0,
  registrationToDepartureTimeMoreThanMinutesCount: 0,
  clearedCountByClearingUser: 0,
  avgClearingTimeInMinutesByClearingUser: 0,
  clearingMoreThanMinutesCountByClearingUser: 0,
  companyCount: 0,
  avgCallingToClearanceStartTimeInMinutes: 0,
  transportationCountByCompany: 0,
  reservationCountByCompany: 0,
  registrationBeforeReservationStartCountByCompany: 0,
  avgCallingToClearanceStartTimeInMinutesByCompany: 0,
  problematicTransportationCountByCompany: 0,

  reservationCountDiff: 0,
  registrationBeforeReservationStartCountDiff: 0,
  registrationInReservationWindowCountDiff: 0,
  registrationAfterReservationEndCountDiff: 0,
  startClearanceBeforeReservationWindowPlusIntervalCountDiff: 0,
  startClearanceInReservationWindowPlusIntervalCountDiff: 0,
  startClearanceAfterReservationEndCountDiff: 0,
  endClearanceInReservationWindowCountDiff: 0,
  endClearanceBeforeReservationWindowPlusIntervalCountDiff: 0,
  endClearanceAfterReservationWindowPlusIntervalCountDiff: 0,
  clearedCountDiff: 0,
  notClearedCountDiff: 0,
  avgWaitingTimeInMinutesDiff: 0,
  waitingMoreThanMinutesCountDiff: 0,
  avgClearingTimeInMinutesDiff: 0,
  clearingMoreThanMinutesCountDiff: 0,
  avgRegistrationToDepartureTimeInMinutesDiff: 0,
  registrationToDepartureTimeMoreThanMinutesCountDiff: 0,
  clearedCountByClearingUserDiff: 0,
  avgClearingTimeInMinutesByClearingUserDiff: 0,
  clearingMoreThanMinutesCountByClearingUserDiff: 0,
  companyCountDiff: 0,
  avgCallingToClearanceStartTimeInMinutesDiff: 0,
  transportationCountByCompanyDiff: 0,
  reservationCountByCompanyDiff: 0,
  registrationBeforeReservationStartCountByCompanyDiff: 0,
  avgCallingToClearanceStartTimeInMinutesByCompanyDiff: 0,
  problematicTransportationCountByCompanyDiff: 0,

  reservationCountPc: 0,
  registrationBeforeReservationStartCountPc: 0,
  registrationInReservationWindowCountPc: 0,
  registrationAfterReservationEndCountPc: 0,
  startClearanceBeforeReservationWindowPlusIntervalCountPc: 0,
  startClearanceInReservationWindowPlusIntervalCountPc: 0,
  startClearanceAfterReservationEndCountPc: 0,
  endClearanceInReservationWindowCountPc: 0,
  endClearanceBeforeReservationWindowPlusIntervalCountPc: 0,
  endClearanceAfterReservationWindowPlusIntervalCountPc: 0,
  clearedCountPc: 0,
  notClearedCountPc: 0,
  avgWaitingTimeInMinutesPc: 0,
  waitingMoreThanMinutesCountPc: 0,
  avgClearingTimeInMinutesPc: 0,
  clearingMoreThanMinutesCountPc: 0,
  avgRegistrationToDepartureTimeInMinutesPc: 0,
  registrationToDepartureTimeMoreThanMinutesCountPc: 0,
  clearedCountByClearingUserPc: 0,
  avgClearingTimeInMinutesByClearingUserPc: 0,
  clearingMoreThanMinutesCountByClearingUserPc: 0,
  companyCountPc: 0,
  avgCallingToClearanceStartTimeInMinutesPc: 0,
  transportationCountByCompanyPc: 0,
  reservationCountByCompanyPc: 0,
  registrationBeforeReservationStartCountByCompanyPc: 0,
  avgCallingToClearanceStartTimeInMinutesByCompanyPc: 0,
  problematicTransportationCountByCompanyPc: 0,

  reservationCountPrev: undefined,
  registrationBeforeReservationStartCountPrev: undefined,
  registrationInReservationWindowCountPrev: undefined,
  registrationAfterReservationEndCountPrev: undefined,
  startClearanceBeforeReservationWindowPlusIntervalCountPrev: undefined,
  startClearanceInReservationWindowPlusIntervalCountPrev: undefined,
  startClearanceAfterReservationEndCountPrev: undefined,
  endClearanceInReservationWindowCountPrev: undefined,
  endClearanceBeforeReservationWindowPlusIntervalCountPrev: undefined,
  endClearanceAfterReservationWindowPlusIntervalCountPrev: undefined,
  clearedCountPrev: undefined,
  notClearedCountPrev: undefined,
  avgWaitingTimeInMinutesPrev: undefined,
  waitingMoreThanMinutesCountPrev: undefined,
  avgClearingTimeInMinutesPrev: undefined,
  clearingMoreThanMinutesCountPrev: undefined,
  avgRegistrationToDepartureTimeInMinutesPrev: undefined,
  registrationToDepartureTimeMoreThanMinutesCountPrev: undefined,
  clearedCountByClearingUserPrev: undefined,
  avgClearingTimeInMinutesByClearingUserPrev: undefined,
  clearingMoreThanMinutesCountByClearingUserPrev: undefined,
  companyCountPrev: undefined,
  avgCallingToClearanceStartTimeInMinutesPrev: undefined,
  transportationCountByCompanyPrev: undefined,
  reservationCountByCompanyPrev: undefined,
  registrationBeforeReservationStartCountByCompanyPrev: undefined,
  avgCallingToClearanceStartTimeInMinutesByCompanyPrev: undefined,
  problematicTransportationCountByCompanyPrev: undefined,

  reservationCountDiffPrev: undefined,
  registrationBeforeReservationStartCountDiffPrev: undefined,
  registrationInReservationWindowCountDiffPrev: undefined,
  registrationAfterReservationEndCountDiffPrev: undefined,
  startClearanceBeforeReservationWindowPlusIntervalCountDiffPrev: undefined,
  startClearanceInReservationWindowPlusIntervalCountDiffPrev: undefined,
  startClearanceAfterReservationEndCountDiffPrev: undefined,
  endClearanceInReservationWindowCountDiffPrev: undefined,
  endClearanceBeforeReservationWindowPlusIntervalCountDiffPrev: undefined,
  endClearanceAfterReservationWindowPlusIntervalCountDiffPrev: undefined,
  clearedCountDiffPrev: undefined,
  notClearedCountDiffPrev: undefined,
  avgWaitingTimeInMinutesDiffPrev: undefined,
  waitingMoreThanMinutesCountDiffPrev: undefined,
  avgClearingTimeInMinutesDiffPrev: undefined,
  clearingMoreThanMinutesCountDiffPrev: undefined,
  avgRegistrationToDepartureTimeInMinutesDiffPrev: undefined,
  registrationToDepartureTimeMoreThanMinutesCountDiffPrev: undefined,
  clearedCountByClearingUserDiffPrev: undefined,
  avgClearingTimeInMinutesByClearingUserDiffPrev: undefined,
  clearingMoreThanMinutesCountByClearingUserDiffPrev: undefined,
  companyCountDiffPrev: undefined,
  avgCallingToClearanceStartTimeInMinutesDiffPrev: undefined,
  transportationCountByCompanyDiffPrev: undefined,
  reservationCountByCompanyDiffPrev: undefined,
  registrationBeforeReservationStartCountByCompanyDiffPrev: undefined,
  avgCallingToClearanceStartTimeInMinutesByCompanyDiffPrev: undefined,
  problematicTransportationCountByCompanyDiffPrev: undefined,

  reservationCountPcPrev: undefined,
  registrationBeforeReservationStartCountPcPrev: undefined,
  registrationInReservationWindowCountPcPrev: undefined,
  registrationAfterReservationEndCountPcPrev: undefined,
  startClearanceBeforeReservationWindowPlusIntervalCountPcPrev: undefined,
  startClearanceInReservationWindowPlusIntervalCountPcPrev: undefined,
  startClearanceAfterReservationEndCountPcPrev: undefined,
  endClearanceInReservationWindowCountPcPrev: undefined,
  endClearanceBeforeReservationWindowPlusIntervalCountPcPrev: undefined,
  endClearanceAfterReservationWindowPlusIntervalCountPcPrev: undefined,
  clearedCountPcPrev: undefined,
  notClearedCountPcPrev: undefined,
  avgWaitingTimeInMinutesPcPrev: undefined,
  waitingMoreThanMinutesCountPcPrev: undefined,
  avgClearingTimeInMinutesPcPrev: undefined,
  clearingMoreThanMinutesCountPcPrev: undefined,
  avgRegistrationToDepartureTimeInMinutesPcPrev: undefined,
  registrationToDepartureTimeMoreThanMinutesCountPcPrev: undefined,
  clearedCountByClearingUserPcPrev: undefined,
  avgClearingTimeInMinutesByClearingUserPcPrev: undefined,
  clearingMoreThanMinutesCountByClearingUserPcPrev: undefined,
  companyCountPcPrev: undefined,
  avgCallingToClearanceStartTimeInMinutesPcPrev: undefined,
  transportationCountByCompanyPcPrev: undefined,
  reservationCountByCompanyPcPrev: undefined,
  registrationBeforeReservationStartCountByCompanyPcPrev: undefined,
  avgCallingToClearanceStartTimeInMinutesByCompanyPcPrev: undefined,
  problematicTransportationCountByCompanyPcPrev: undefined,
};

export const REPORT_PERIOD_TIMEZONE = 'Europe/Prague';

const parseFetchedPeriodData = (data) => {
  const result = {
    ...EMPTY_METRICS,
    name: `${parseDayMonthFromISO(data.from)}-${parseDayMonthFromISO(data.to)}`,
  };

  data.metrics.forEach(({ name, value, }) => {
    result[name] = value;
  });

  return result;
};

export const parseFetchedData = (data) => {
  const metrics = data.map(parseFetchedPeriodData);
  metrics.forEach((metric, i) => {
    Object.keys(metric)
      .filter((key) => !key.endsWith('Prev'))
      .forEach((key) => {
        // eslint-disable-next-line no-param-reassign
        metric[`${key}Prev`] = metrics[i - 1]?.[key];
      });
  });

  return metrics;
};
