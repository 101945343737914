import styled from 'styled-components';


const StyledRoleTree = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;

  background: ${(p) => (p.background ? p.background : p.theme.white)};

  .settings--tree-group:not(:last-child) {
    margin-bottom: 1rem;
  }

  .settings--tree-list {
    padding-left: 16px;
    margin-left: 4px;
    border-left: 1px dashed ${(p) => p.theme.grey.t400};
    list-style: none;
  }

  li {
    position: relative;
  }

  .settings--tree-item:before {
    content: '';
    border-top: 1px dashed ${(p) => p.theme.grey.t400};
    position: absolute;
    left: -17px;
    top: 10px;
    width: 11px;
    height: 100%;
  }

  .settings--tree-item:last-child:before {
    background:  ${(p) => (p.background ? p.background : p.theme.white)};
  }
`;


export default StyledRoleTree;
