import React from 'react';
import { shape, string, object, } from 'prop-types';

import FormRowReadOnly from '../../../../components/Form/FormRowReadOnly';
import ValueText from '../../../../atoms/Values/ValueText';

const { REACT_APP_REST, } = process.env;

const RecordExtraReadonly = ({ translations, values, }) => (
  <>
    <FormRowReadOnly label={translations.gatehouse.rnPicture}>
      {values.picture && (
        <img width="346px" src={`${REACT_APP_REST}/file?filename=${values.picture}`} alt="" />
      )}
      {!values.picture && (
        <ValueText>-</ValueText>
      )}
    </FormRowReadOnly>

    <FormRowReadOnly label={translations.form.note}>
      <ValueText>{values.note ? values.note : '-'}</ValueText>
    </FormRowReadOnly>

    {values.transportationId && (
      <>
        <FormRowReadOnly label={translations.form.status}>
          <ValueText>{values.transportationStateId ? translations.monitoring[values.transportationStateId] : ''}</ValueText>
        </FormRowReadOnly>

        <FormRowReadOnly label={translations.form.platform}>
          <ValueText>{values.platform}</ValueText>
        </FormRowReadOnly>
      </>
    )}
  </>
);


RecordExtraReadonly.propTypes = {
  translations: object.isRequired,
  values: shape({
    rnPicture: string,
    note: string,
  }).isRequired,
};


export default RecordExtraReadonly;
