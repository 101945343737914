import { INPUT_TYPE, } from 'sofair-form-validation';
import { initForm, initInput, } from '../../../../logic/form/common';
import { regexEmail, regexPhone, } from '../../../../logic/form/regexes';

/**
 * User
 */
export const initUserForm = {
  detailForm: {
    ...initForm,
    form: {
      email: initInput({
        name: 'email',
        validation: {
          required: true,
          regex: regexEmail,
        },
      }),
      phoneNum: initInput({
        name: 'phoneNum',
        validation: {
          required: true,
          regex: regexPhone,
        },
      }),
      company: initInput({
        name: 'company',
        type: INPUT_TYPE.SELECT,
        validation: {
          required: true,
        },
      }),
      role: initInput({
        name: 'role',
        type: INPUT_TYPE.SELECT,
        validation: {
          required: true,
        },
      }),
      language: initInput({
        name: 'language',
        type: INPUT_TYPE.SELECT,
        validation: {
          required: true,
        },
      }),
    },
    values: {
      email: '',
      phoneNum: '',
      company: null,
      role: null,
      language: null,
    },
  },
};
