import React, { Component, Fragment, } from 'react';
import {
  shape, string, bool, func, object,
} from 'prop-types';
import { Mutation, } from 'react-apollo';

import { MUTATION_DEPARTURE_VISITOR, } from '../../gql/mutations';
import { TRANSLATION_VISITOR, } from '../../gql/translations';
import { pipe, } from '../../../../logic/utils';
import { withTranslations, } from '../../../../logic/translations/withTranslations';
import { withNotifications, } from '../../../../logic/notifications/withNotifications';
import Modal from '../../../../atoms/Modal/Modal';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import Button from '../../../../atoms/Button/Button';
import ButtonLoader from '../../../../atoms/Button/ButtonLoader';
import FormError from '../../../../components/Form/FormError';


class VisitorDeparture extends Component {
  handleDeparture = (mutationDeparture) => {
    const {
      // data
      modalData: {
        id,
      },
    } = this.props;

    mutationDeparture({
      variables: {
        id,
      },
    });
  }


  handleDepartureCompleted = () => {
    const {
      translations, onToggle, addNotification,
    } = this.props;

    onToggle();
    addNotification({
      status: 'success',
      title: translations.common.departed,
    });
  }


  render() {
    const {
      // data
      modalData: {
        isOpen,
      },
      translations,
      // methods
      onToggle,
    } = this.props;

    if (!isOpen) return null;

    return (
      <Modal
        isOpen
        onClose={onToggle}
        title={translations.visitor.visitorDepartureModalTitle}
        size="SM"
      >
        <Row formGroup>
          <Col>
            {translations.visitor.visitorDepartureModalText}
          </Col>
        </Row>

        <Mutation
          mutation={MUTATION_DEPARTURE_VISITOR}
          onCompleted={this.handleDepartureCompleted}
        >
          {(mutationDeparture, { loading, error, }) => (
            <Fragment>
              <FormError
                error={error}
              />
              <Row>
                <Col XS={12} SM={12}>
                  <Button
                    onClick={onToggle}
                  >
                    {translations.common.back}
                  </Button>
                </Col>
                <Col XS={12} SM={12} textAlign="right">
                  <ButtonLoader
                    onClick={() => this.handleDeparture(mutationDeparture)}
                    disabled={loading}
                    color="success"
                  >
                    {translations.common.confirm}
                  </ButtonLoader>
                </Col>
              </Row>
            </Fragment>
          )}
        </Mutation>
      </Modal>
    );
  }
}


VisitorDeparture.propTypes = {
  // data
  modalData: shape({
    isOpen: bool.isRequired,
    id: string.isRequired,
  }).isRequired,
  translations: object.isRequired,
  // methods
  onToggle: func.isRequired,
  addNotification: func.isRequired,
};


export default pipe(
  withTranslations(TRANSLATION_VISITOR),
  withNotifications,
)(VisitorDeparture);
