import React from 'react';

import StyledSvg from './styles/StyledSvg';


const Hourglass = ({ ...props }) => (
  <StyledSvg viewBox="0 0 283.000000 276.000000" {...props}>
    <g transform="translate(0.000000,276.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
      <path d="M386 2318 c-58 -51 -33 -142 42 -154 l39 -6 6 -87 c15 -200 52 -265
        286 -503 l184 -188 -174 -177 c-96 -98 -189 -200 -207 -227 -57 -87 -92 -212
        -92 -328 0 -46 -1 -48 -29 -48 -17 0 -42 -10 -58 -24 -39 -34 -39 -98 0 -132
        l28 -24 590 0 591 0 -52 41 c-190 150 -277 447 -201 689 l21 65 -82 82 -82 82
        180 178 c152 150 186 189 218 250 45 89 76 206 76 290 l0 63 31 0 c42 0 89 47
        89 90 0 22 -9 41 -29 61 l-29 29 -661 0 -660 0 -25 -22z m1102 -241 c-3 -66
        -11 -96 -36 -150 l-31 -67 -351 0 -351 0 -31 65 c-24 51 -31 84 -36 150 l-5
        85 423 0 423 0 -5 -83z"
      />
      <path d="M1846 1484 c-243 -59 -410 -272 -410 -524 0 -254 168 -466 415 -525
        182 -43 368 11 500 145 210 213 210 547 0 760 -132 134 -322 188 -505 144z
        m164 -244 c17 -17 20 -33 20 -120 l0 -100 68 0 c81 0 112 -17 112 -60 0 -48
        -29 -60 -150 -60 -154 0 -150 -5 -150 180 0 142 1 150 22 164 30 21 54 20 78
        -4z"
      />
    </g>
  </StyledSvg>
);

export default Hourglass;
