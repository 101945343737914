import React from 'react';
import { shape, string, object, } from 'prop-types';
import { Switch, Route, } from 'react-router-dom';

import PrivateRoute from '../../../components/PrivateRoute/PrivateRoute';
import UsersPage from './UsersPage';
import NotFoundPage from '../../Main/pages/NotFoundPage';
import PlatformBlocksPage from './PlatformBlocksPage';


const ReservationSystemLayout = ({ match, resources, }) => (
  <Switch>
    <PrivateRoute resource={resources.rs_user} path={`${match.path}/users`} component={UsersPage} />
    <PrivateRoute resource={resources.rs_blocking_day} path={`${match.path}/blocking-days`} component={PlatformBlocksPage} />
    <Route component={NotFoundPage} />
  </Switch>
);


ReservationSystemLayout.propTypes = {
  match: shape({
    path: string.isRequired,
  }).isRequired,
  resources: object,
};

ReservationSystemLayout.defaultProps = {
  resources: {},
};


export default ReservationSystemLayout;
