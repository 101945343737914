import React, { Component, } from 'react';
import {
  func, shape, object, string,
} from 'prop-types';

import { withNotifications, } from '../../../../../logic/notifications/withNotifications';
import { parseFromApi, parseToApi, } from '../../../../../logic/form/genericForm';
import {
  initForm,
  changeAndValidateInput,
  validateAndMergeWholeForm,
  mergeValidationObjectIntoForm,
} from '../../../../../logic/form/common';
import ModalDetailView from './ModalDetailView';


class ModalDetailLogic extends Component {
  constructor(props) {
    super(props);

    const { genFormData: { genericForm, genericFormValues, }, } = props;

    this.state = {
      genForm: {
        ...initForm,
        ...parseFromApi(genericForm, genericFormValues),
      },
    };
  }


  /**
   * Change Form
   */
  handleChangeGenericFormValue = (name, value) => {
    const { genForm, } = this.state;
    const newState = changeAndValidateInput(genForm, name, value);

    this.setState({
      genForm: newState,
    });
  }


  /**
   * Save
   */
  handleSave = (mutationSave) => {
    const { genForm, } = this.state;
    const { transportationId, } = this.props;

    const newGenForm = validateAndMergeWholeForm(genForm);
    if (!newGenForm.isValid) {
      this.setState({
        genForm: newGenForm,
      });
    } else {
      const variables = {
        values: parseToApi(genForm),
        transportationId,
        inProgress: false,
      };
      mutationSave({
        variables,
      });
    }
  }

  /**
   * Save - Completed
   */
  handleSaveCompleted = () => {
    const { translations, onToggle, addNotification, } = this.props;

    onToggle();
    addNotification({
      status: 'success',
      title: translations.centralAppStorekeeper.notifTitleFormSaved,
    });
  }

  /**
   * Save - Error
   */
  handleSaveError = (mutationError) => {
    const { translations, addNotification, } = this.props;
    try {
      const { genForm, } = this.state;

      const { graphQLErrors, } = mutationError;

      if (graphQLErrors && graphQLErrors.length > 0) {
        const { message, extensions, } = graphQLErrors[0];

        switch (message) {
          case 'UNPROCESSABLE_ENTITY': {
            if (extensions.exception.data) {
              const newState = mergeValidationObjectIntoForm(genForm, extensions.exception.data);
              this.setState({ genForm: newState, });
            }
            break;
          }

          default: {
            break;
          }
        }
      }
    } catch (err) {
      // continue regardless of error
    }

    addNotification({
      status: 'error',
      title: translations.storekeeperAppCommon.notifTitleSaveError,
    });
  }


  render() {
    const { genForm, } = this.state;
    const {
      languageId,
      translations,
      onToggle,
    } = this.props;

    return (
      <ModalDetailView
        // data
        genForm={genForm}
        languageId={languageId}
        translations={translations}
        // methods
        onGenericFormChanged={this.handleChangeGenericFormValue}
        onSave={this.handleSave}
        onSaveCompleted={this.handleSaveCompleted}
        onSaveError={this.handleSaveError}
        onClose={onToggle}
      />
    );
  }
}


ModalDetailLogic.propTypes = {
  // data
  genFormData: shape({
    genericForm: object.isRequired,
    genericFormValues: object.isRequired,
  }).isRequired,
  transportationId: string.isRequired,
  languageId: string.isRequired,
  translations: object.isRequired,
  // methods
  onToggle: func.isRequired,
  addNotification: func.isRequired,
};


export default withNotifications(ModalDetailLogic);
