import React, { Component, } from 'react';
import {
  shape,
  func,
  object,
  string,
  bool,
  arrayOf,
} from 'prop-types';

import TabAction from './TabAction';
import TabFinish from './TabFinish';


class WizardLogic extends Component {
  constructor(props) {
    super(props);

    const {
      configuration: { tabs, },
    } = this.props;

    // find activeTab and completedUntilTab
    let i = 0;
    for (i; i < tabs.length; i++) {
      const tab = tabs[i];
      if (!tab.completed) {
        break;
      }
    }

    this.state = {
      tabs,
      activeTab: i,
      completedUntilTab: i,
    };
  }


  /**
   * Change Tab and set Tab data
   */
  handleChangeTab = ({ from, to, completed, }) => this.setState((prevState) => {
    // FinishTab - don't need to change tabs data
    if (from === prevState.tabs.length) {
      return ({
        activeTab: to,
      });
    }
    // ActionTab
    return ({
      tabs: prevState.tabs.map((item, index) => (index === from
        ? ({
          ...item,
          completed,
        })
        : item
      )),
      activeTab: (completed && prevState.completedUntilTab === from) ? to + 1 : to,
      completedUntilTab: (completed && prevState.completedUntilTab === from)
        ? prevState.completedUntilTab + 1
        : prevState.completedUntilTab,
    });
  })


  render() {
    const {
      tabs,
      activeTab,
      completedUntilTab,
    } = this.state;
    const {
      // data
      configuration: { manualMoveToNextStandsPossible, actionType, },
      standId,
      languageId,
      transportationId,
      isLoading,
      translations,
      // methods
      onToggle,
    } = this.props;

    if (activeTab < tabs.length) {
      return (
        <TabAction
          key={activeTab}
          // data
          tabs={tabs}
          tabData={tabs[activeTab]}
          activeTab={activeTab}
          completedUntilTab={completedUntilTab}
          standId={standId}
          transportationId={transportationId}
          languageId={languageId}
          isLoading={isLoading}
          actionType={actionType}
          translations={translations}
          // methods
          onToggle={onToggle}
          onChangeTab={this.handleChangeTab}
        />
      );
    }

    return (
      <TabFinish
        // data
        standId={standId}
        languageId={languageId}
        transportationId={transportationId}
        manualMoveToNextStandsPossible={manualMoveToNextStandsPossible}
        tabs={tabs}
        activeTab={activeTab}
        completedUntilTab={completedUntilTab}
        translations={translations}
        // methods
        onToggle={onToggle}
        onChangeTab={this.handleChangeTab}
      />
    );
  }
}


WizardLogic.propTypes = {
  // data
  configuration: shape({
    manualMoveToNextStandsPossible: bool.isRequired,
    tabs: arrayOf(shape({
      genericFormId: string,
      title: string,
      completed: bool.isRequired,
      actionId: string.isRequired,
    })).isRequired,
  }).isRequired,
  transportationId: string.isRequired,
  isLoading: bool,
  standId: string.isRequired,
  translations: object.isRequired,
  languageId: string.isRequired,
  // data
  onToggle: func.isRequired,
};


export default WizardLogic;
