export const getColorBadge = ({ color, theme, }) => {
  const colors = {
    grey: `
      color: ${theme.white};
      background: ${theme.grey.t700};
    `,
    primary: `
      color: ${theme.white};
      background: ${theme.primary.t700};
    `,
    secondary: `
      color: ${theme.white};
      background: ${theme.secondary.t700};
    `,
    tertiary: `
      color: ${theme.white};
      background: ${theme.tertiary.t700};
    `,
    error: `
      color: ${theme.white};
      background: ${theme.error.t700};
    `,
    warning: `
      color: ${theme.white};
      background: ${theme.warning.t700};
    `,
    success: `
      color: ${theme.white};
      background: ${theme.success.t700};
    `,
  };

  if (Object.prototype.hasOwnProperty.call(colors, color)) {
    return colors[color];
  }
  return colors.grey;
};
