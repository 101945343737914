import React, { Fragment, } from 'react';
import { number, } from 'prop-types';


const RegistrationMethodColumn = ({
  automatic, kiosk, manual, total,
}) => (
  (total > 0) ? (
    <Fragment>
      <div
        className="registration-method-column column-auto"
        style={{ height: `${automatic / total * 100}%`, }}
      />
      <div
        className="registration-method-column--tooltip tooltip-auto"
        style={{ top: `calc(${(automatic / 2) / total * 100}% - 0.5rem)`, }}
      >
        {Math.round(automatic / total * 100)}
        %
      </div>
      <div
        className="registration-method-column column-kiosk"
        style={{ height: `${kiosk / total * 100}%`, }}
      />
      <div
        className="registration-method-column--tooltip tooltip-kiosk"
        style={{ top: `calc(${(automatic + kiosk / 2) / total * 100}% - 0.5rem)`, }}
      >
        {Math.round(kiosk / total * 100)}
        %
      </div>
      <div
        className="registration-method-column column-manual"
        style={{ height: `${manual / total * 100}%`, }}
      />
      <div
        className="registration-method-column--tooltip tooltip-manual"
        style={{ top: `calc(${(automatic + kiosk + manual / 2) / total * 100}% - 0.5rem)`, }}
      >
        {Math.round(manual / total * 100)}
        %
      </div>
    </Fragment>
  ) : null
);


RegistrationMethodColumn.propTypes = {
  automatic: number.isRequired,
  kiosk: number.isRequired,
  manual: number.isRequired,
  total: number.isRequired,
};


export default RegistrationMethodColumn;
