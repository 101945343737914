import React from 'react';
import {
  func, bool, object, string, any,
} from 'prop-types';

import { TRANSLATION_STAND_CALL, } from '../../gql/translations';
import { withTranslations, } from '../../../../logic/translations/withTranslations';
import Modal from '../../../../atoms/Modal/Modal';
import StandCallLogic from './StandCallLogic';


const StandCall = ({
  // data
  isOpen,
  standId,
  transportationId,
  translations,
  options,
  // methods
  onToggle,
}) => (
  isOpen ? (
    <Modal
      title={translations.stand.standCallModalTitle}
      isOpen
      size="SM"
      onClose={onToggle}
      disablePadding
    >
      <StandCallLogic
        // data
        translations={translations}
        standId={standId}
        transportationId={transportationId}
        options={options}
        // methods
        onToggle={onToggle}
      />
    </Modal>
  ) : null
);


StandCall.propTypes = {
  translations: object.isRequired,
  standId: string.isRequired,
  transportationId: string.isRequired,
  isOpen: bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  options: any.isRequired,
  onToggle: func.isRequired,
};


export default withTranslations(TRANSLATION_STAND_CALL)(StandCall);
