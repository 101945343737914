export const isValidInputOption = (value, id = 'id', name = 'name') => !!(value
    && Object.prototype.hasOwnProperty.call(value, id)
    && Object.prototype.hasOwnProperty.call(value, name));

export const fieldOrDefault = (
  obj,
  key,
  defaultObj,
) => ((obj?.[key] !== undefined) ? obj[key] : defaultObj[key]);

export const createFieldOrDefault = (
  obj,
  defaultObj,
) => (key) => fieldOrDefault(obj, key, defaultObj);
