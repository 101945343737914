import styled from 'styled-components';

const backgroundColor = (p) => p.theme.grey.t400;
const selectedColor = (p) => p.theme.warning.t400;
const unselectedColor = (p) => p.theme.grey.t50;
const tooltipColor = (p) => p.theme.grey.t900;

export const StyledDurationChart = styled.div`
  .duration-chart--actual-duration {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    
    margin-bottom: 1rem;
    
    .duration-chart--actual-duration-text {
      margin-right: 0.5rem;
      color: ${backgroundColor};
    }
    
    .duration-chart--actual-duration-value {
      color: ${selectedColor};
    }
  }
    
  .duration-chart-column--top {
    background: transparent;
    
    &:hover~.duration-chart-column--tooltip {
      visibility: visible;
    }
  }
  
  .duration-chart-column--down {
    background: ${unselectedColor};
    
    &.selected {
      background: ${selectedColor};
    }
    
    &.clickable {
      cursor: pointer;
    }
    
    &:hover~.duration-chart-column--tooltip {
      visibility: visible;
    }
  }
   
  .duration-chart-column--tooltip {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    visibility: hidden;
    color: ${tooltipColor}
    
    &:hover {
      visibility: visible;
    }
  }
`;
