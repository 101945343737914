import React from 'react';
import AnimateHeight from 'react-animate-height';
import {
  arrayOf, oneOfType, node, bool,
} from 'prop-types';


const Collapse = ({
  children, isOpen, ...rest
}) => (
  <AnimateHeight
    duration={200}
    height={isOpen ? 'auto' : 0}
    {...rest}
  >
    {children}
  </AnimateHeight>
);


Collapse.propTypes = {
  isOpen: bool.isRequired,
  children: oneOfType([
    arrayOf(node),
    node,
  ]).isRequired,
};


export default Collapse;
