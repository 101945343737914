import React from 'react';
import styled from 'styled-components';
import {
  shape, bool, object, func, string,
} from 'prop-types';

import { MESSAGES, } from '../../globals';
import { withErrors, } from '../../logic/errorManager/withErrors';
import Row from '../../atoms/Row/Row';
import Col from '../../atoms/Col/Col';


const StyledMessage = styled.span`
  color: ${(p) => p.theme.error.t800};
`;


const FormError = ({
  /**
   * GraphQL Error
   */
  error,
  /**
   * If Form is valid
   */
  isValid,
  /**
   * { code, params, }
   */
  customError,
  // methods
  translateGraphQLError,
  translateErrorCode,
  translateInputError,
}) => {
  const parsedError = customError // eslint-disable-line
    ? translateInputError(customError)
    : !isValid // eslint-disable-line
      ? translateErrorCode(MESSAGES.INVALID_FORM)
      : error
        ? translateGraphQLError(error)
        : '';

  if (parsedError === '') return null;

  return (
    <Row>
      <Col textAlign="right">
        <StyledMessage>{parsedError}</StyledMessage>
      </Col>
    </Row>
  );
};


FormError.propTypes = {
  error: object,
  customError: shape({
    code: string.isRequired,
    params: object,
  }),
  isValid: bool,
  translateGraphQLError: func.isRequired,
  translateErrorCode: func.isRequired,
  translateInputError: func.isRequired,
};


FormError.defaultProps = {
  error: undefined,
  isValid: true,
  customError: undefined,
};


export default withErrors(FormError);
