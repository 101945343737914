import {
  arrayOf, bool, number, object, shape, string,
} from 'prop-types';

/**
 * type PlatformBlock = {
 *   id: number,
 *   platformId: number,
 *   platformName: string,
 *   date: string,
 *   from: Date,
 *   to: Date,
 *   definitionId: number | undefined | null,
 *   note: string | undefined | null,
 * }
 */
export const PlatformBlock = shape({
  id: number.isRequired,
  platformId: number.isRequired,
  platformName: string.isRequired,
  date: string.isRequired,
  from: object.isRequired,
  to: object.isRequired,
  definitionId: number,
  note: string,
});

/**
 * type Platform = {
 *   id: number.isRequired,
 *   name: string.isRequired,
 *   start: string.isRequired,
 *   end: string.isRequired,
 * }
 */
export const Platform = shape({
  id: number.isRequired,
  name: string.isRequired,
  start: string.isRequired,
  end: string.isRequired,
});

/**
 * type PlatformBlockDefinition = {
 *   id: number,
 *   useFrom: string, // ISO format
 *   useTo: string, // ISO format
 *   useFromDateString: string, // DD.MM.YYYY
 *   useToDateString: string, // DD.MM.YYYY
 *   type: string,
 *   selectedDays: number[] | undefined | null,
 *   platformIds: number[],
 *   platformNames: string,
 *   timeFrom: Date, // HH:MM
 *   timeTo: Date, // HH:MM
 *   note: string | undefined | null,
 * }
 */
export const PlatformBlockDefinition = shape({
  id: number.isRequired,
  useFrom: string.isRequired,
  useTo: string.isRequired,
  useFromDateString: string.isRequired,
  useToDateString: string.isRequired,
  type: string.isRequired,
  selectedDays: arrayOf(number.isRequired),
  platformIds: arrayOf(number.isRequired).isRequired,
  platformNames: string.isRequired,
  timeFrom: string.isRequired,
  timeTo: string.isRequired,
  note: string,
});

/**
 * type BlockedDay = {
 *   date: string,
 *   fullyBlocked: boolean,
 * }
 */
export const BlockedDay = shape({
  date: string.isRequired,
  fullyBlocked: bool.isRequired,
});
