import styled from 'styled-components';


const StyledSidebarLicenseInfo = styled.div`
  font-weight: 300;
  line-height: 1.2rem;

  p {
    margin: 0;

    &.centered {
      text-align: center;
    }
  }

  .info {
    color: ${(p) => p.theme.sidebar.color};
  }
  
  .warning {
    color: ${(p) => p.theme.warning.t600};

    .warning-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
      margin-bottom: 0.5rem;
    }
    
    .icon {
      height: 1.6rem;
      width: 1.6rem;
    }
    
    a {
      color: ${(p) => p.theme.warning.t600};
      
      &::before {
        content: '';
        display: inline-block;
        background: ${(p) => p.theme.warning.t600};
        height: 0.3rem;
        width: 0.3rem;
        margin: 0 0.5rem 0.1rem 0.1rem;
        border-radius: 50%;
      }
    }
  }
`;

export default StyledSidebarLicenseInfo;
