import gql from 'graphql-tag';


export const SUBSCRIPTION_DASHBOARD = gql`
  subscription SubscribeToDashboardUpdates {
    subscribeToDashboardUpdates {
      actualSystemStateStats {
        registered
        clearing
        done
      }
      averageClearanceTimeStats {
        clearanceTime
        day
      }
      averageWaitingTimeStats {
        waitingTime
        day
      }
      rampLoadingStats {
        loadingRatio
        ramp
      }
      todayPlanStats {
        planned
        remaining
        cleared
      }
      registrationMethodStats {
        day
        automatic
        kiosk
        manual
      }
    }
  }
`;
