import React, { Component, } from 'react';
import {
  arrayOf, node, string, oneOfType, func, number, bool,
} from 'prop-types';

import StyledWizardTab from './styles/StyledWizardTab';


class WizardTab extends Component {
  handleClick = () => {
    const { disabled, name, onClick, } = this.props;

    if (disabled) return;

    onClick(name);
  }


  render() {
    const {
      preText, name, activeTab, children, onClick, disabled, ...rest
    } = this.props;

    return (
      <StyledWizardTab
        {...rest}
        isActive={name === activeTab}
        onClick={this.handleClick}
        disabled={disabled}
      >
        {preText && (
          <span className="wizard--span-big">{preText}</span>
        )}
        <span>{children}</span>
      </StyledWizardTab>
    );
  }
}


WizardTab.propTypes = {
  preText: oneOfType([
    number,
    string,
  ]),
  disabled: bool,
  name: oneOfType([ string, number, ]).isRequired,
  activeTab: oneOfType([ string, number, ]).isRequired,
  onClick: func.isRequired,
  children: oneOfType([
    arrayOf(node),
    node,
  ]).isRequired,
};

WizardTab.defaultProps = {
  preText: undefined,
  disabled: false,
};


export default WizardTab;
