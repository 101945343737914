import React, { Component, } from 'react';
import FocusTrap from 'focus-trap-react';
import {
  oneOfType, arrayOf, node, string, func, bool,
} from 'prop-types';

import { withModalManager, } from './withModalManager';

import StyledModal from './styles/StyledModal';


class ModalContent extends Component {
  constructor(props) {
    super(props);
    this.refModal = React.createRef();

    this.timer = null;

    this.state = { fadeIn: false, };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeydown);
    setTimeout(() => this.setState({ fadeIn: true, }), 10);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeydown);
  }

  handleKeydown = (e) => {
    const { isTopModal, onClose, closeOnEsc, } = this.props;

    if (e.keyCode !== 27 || !isTopModal || !closeOnEsc) {
      return;
    }

    onClose();
  }

  handleClickAway = (e) => {
    const { disableClickOutside, onClose, } = this.props;

    e.stopPropagation();

    if (disableClickOutside) {
      return;
    }

    if (this.refModal.current && this.refModal.current.contains(e.target)) {
      return;
    }

    onClose();
  }

  render() {
    const { fadeIn, } = this.state;
    const {
      children, size, isTopModal,
    } = this.props;

    return (
      <FocusTrap
        tag="aside"
        active={isTopModal}
      >
        <StyledModal
          aria-modal="true"
          tabIndex="-1"
          role="dialog"
          onClick={this.handleClickAway}
          size={size}
          fadeIn={fadeIn}
        >
          <div
            className="modal--content"
            ref={this.refModal}
          >
            {children}
          </div>
        </StyledModal>
      </FocusTrap>
    );
  }
}


ModalContent.propTypes = {
  disableClickOutside: bool.isRequired,
  isTopModal: bool.isRequired,
  closeOnEsc: bool.isRequired,
  size: string.isRequired,
  onClose: func.isRequired,
  children: oneOfType([
    arrayOf(node),
    node,
  ]).isRequired,
};


export default withModalManager(ModalContent);
