// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
import React from 'react';

import ReportUserGroupsList from './ReportUserGroupsList';
import ReportUserGroupDetail from './ReportUserGroupDetail';


const ReportUserGroupsSettings = (props) => {
  return (
    <div className="report-user-groups-settings">
      <ReportUserGroupsList
        reportUserGroups={props.reportUserGroups}
        selectedId={props.selectedReportUserGroup ? props.selectedReportUserGroup.id : 0}
        onSelect={props.onSelect}
        onCreate={props.onCreate}
        onDelete={props.onDelete}
        title="Seznam nastavených oprávnění"
      />
      {props.selectedReportUserGroup && (
        <ReportUserGroupDetail
          reportUserGroup={props.selectedReportUserGroup}
          reports={props.reports}
          users={props.users}
          transportationTypes={props.transportationTypes}
          onUpdate={props.onUpdate}
        />
      )}
    </div>
  );
};

export default ReportUserGroupsSettings;
