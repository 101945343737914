import React from 'react';
import {
  arrayOf, shape, number, object,
} from 'prop-types';

import StatsGridCard from './StatsGridCard';
import DurationChart from '../../../../atoms/DurationChart/DurationChart';
import Hourglass from '../../../../styles/icons/Hourglass';


const AverageWaitingTimeCard = (
  { averageWaitingTimeStats, translations, translationsCommon, }
) => (
  <StatsGridCard>
    <header>
      <span>
        {translations.dashboard.stats.averageWaitingTimeTitle}
        &nbsp;(hh:mm)
      </span>
      <Hourglass />
    </header>
    <div className="stats--average-waiting-time-card--body">
      <DurationChart
        data={averageWaitingTimeStats.map(({ waitingTime, day, }) => ({
          duration: waitingTime,
          label: Object.values(translationsCommon.calendar)[day],
        }))}
        selectedLabel={translations.dashboard.stats.actual}
        staticIndex={averageWaitingTimeStats.length - 1}
      />
    </div>
  </StatsGridCard>
);

AverageWaitingTimeCard.propTypes = {
  averageWaitingTimeStats: arrayOf(shape({
    waitingTime: number.isRequired,
    day: number.isRequired,
  })).isRequired,
  translations: object.isRequired,
  translationsCommon: object.isRequired,
};

export default AverageWaitingTimeCard;
