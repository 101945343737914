import { FORM_ERROR, FORM_SUCCESS, } from '../../globals';
import { parseMessage, } from '../messages/messages';


export const mergeFormValidation = (form, validation) => {
  const data = { ...form, };
  let invalidInputs = 0;

  const dataKeys = Object.keys(data);
  for (let i = 0; i < dataKeys.length; i++) {
    const key = dataKeys[i];

    if (Object.prototype.hasOwnProperty.call(validation, key)) {
      invalidInputs++;
      data[key] = {
        ...data[key],
        status: FORM_ERROR,
        statusMsg: validation[key],
      };
    } else {
      data[key] = {
        ...data[key],
        status: FORM_SUCCESS,
        statusMsg: parseMessage(),
      };
    }
  }

  return {
    form: data,
    invalidInputs,
  };
};
