import React, { Component, } from 'react';
import { object, func, string, } from 'prop-types';

import { GraphQLErrorsGlobal, } from '../../../../globals';
import { TRANSLATIONS_ROLE, } from '../../gql/translations';
import { initRoleForm, } from '../utils';
import {
  changeAndValidateInput, validateAndMergeWholeForm, mergeValidationObjectIntoForm,
} from '../../../../logic/form/common';
import { pipe, } from '../../../../logic/utils';
import { withNotifications, } from '../../../../logic/notifications/withNotifications';
import { withTranslations, } from '../../../../logic/translations/withTranslations';
import Modal from '../../../../atoms/Modal/Modal';
import RoleCreateView from './RoleCreateView';


class RoleCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      roleForm: initRoleForm,
      resourcesValue: {},
    };
  }


  handleChangeForm = (name, value) => {
    this.setState((prevState) => ({
      roleForm: changeAndValidateInput(prevState.roleForm, name, value),
    }));
  }


  handleChangeResourceValue = (name, value) => {
    this.setState((prevState) => ({
      resourcesValue: {
        ...prevState.resourcesValue,
        [name]: value,
      },
    }));
  }


  parseResourcesValue = () => {
    const { resourcesValue, } = this.state;

    const ret = [];
    const keys = Object.keys(resourcesValue);

    for (let i = 0; i < keys.length; i++) {
      const {
        id, all, constraint, payload,
      } = resourcesValue[keys[i]];

      if (constraint) {
        if (all) {
          ret.push({
            resourceId: id,
            constraint: [],
          });
        } else {
          const addedConstraints = Object.keys(payload).reduce(
            (accumulator, key) => {
              if (payload[key]) accumulator.push(key);
              return accumulator;
            },
            [],
          );

          if (addedConstraints.length > 0) {
            ret.push({
              resourceId: id,
              constraint: addedConstraints,
            });
          }
        }
      } else {
        if (payload) { // eslint-disable-line
          ret.push({
            resourceId: id,
            constraint: undefined,
          });
        }
      }
    }

    return ret;
  }


  handleCreate = (mutationCreate) => {
    const { roleForm, } = this.state;
    const newRoleForm = validateAndMergeWholeForm(roleForm);

    if (!newRoleForm.isValid) {
      this.setState({
        roleForm: newRoleForm,
      });
    } else {
      const variables = {
        values: {
          name: roleForm.values.name,
          resources: this.parseResourcesValue(),
        },
      };
      mutationCreate({
        variables,
      });
    }
  }


  handleCreateComplete = () => {
    const {
      translations, onToggle, addNotification,
    } = this.props;

    onToggle();
    addNotification({
      status: 'success',
      title: translations.common.created,
    });
  }


  handleCreateError = (mutationError) => {
    try {
      const { roleForm, } = this.state;

      const { graphQLErrors, } = mutationError;

      if (graphQLErrors && graphQLErrors.length > 0) {
        const { message, extensions, } = graphQLErrors[0];

        switch (message) {
          case GraphQLErrorsGlobal.UNPROCESSABLE_ENTITY: {
            if (extensions.exception.data) {
              const newFormData = mergeValidationObjectIntoForm(
                roleForm, extensions.exception.data
              );

              this.setState({
                roleForm: newFormData,
              });
            }
            break;
          }

          default: {
            break;
          }
        }
      }
    } catch (err) {
      // continue regardless of error
    }
  }


  render() {
    const {
      roleForm,
      resourcesValue,
    } = this.state;
    const { translations, languageId, onToggle, } = this.props;

    return (
      <Modal
        isOpen
        title={translations.settings.createRoleTitle}
        size="SM"
        onClose={onToggle}
        disablePadding
      >
        <RoleCreateView
          // data
          roleForm={roleForm}
          resourcesValue={resourcesValue}
          languageId={languageId}
          translations={translations}
          // methods
          onChangeForm={this.handleChangeForm}
          onChangeResourceValue={this.handleChangeResourceValue}
          onCreate={this.handleCreate}
          onClose={onToggle}
          onCreateCompleted={this.handleCreateComplete}
          onCreateError={this.handleCreateError}
        />
      </Modal>
    );
  }
}


RoleCreate.propTypes = {
  translations: object.isRequired,
  languageId: string.isRequired,
  addNotification: func.isRequired,
  onToggle: func.isRequired,
};


export default pipe(
  withNotifications,
  withTranslations(TRANSLATIONS_ROLE),
)(RoleCreate);
