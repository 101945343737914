import styled from 'styled-components';


const ValueText = styled.span`
  margin-left: 0.5rem;
  font-weight: 500;
`;


export default ValueText;
