import gql from 'graphql-tag';


export const SUBSCRIPTION_ADD_PLATFORM_BLOCK = gql`
  subscription AddPlatformBlock {
    addPlatformBlock {
      id
    }
  }
`;
