import React, { Fragment, } from 'react';
import {
  func, shape, bool, string, object, arrayOf,
} from 'prop-types';

import { QUERY_COMPANIES, } from '../../gql/queries';
import { MUTATION_DELETE_COMPANY, } from '../../gql/mutations';
import Input from '../../../../atoms/Input/Input';
import Table from '../../../../atoms/Table/Table';
import Pagination from '../../../../components/Pagination/Pagination';
import TableLoading from '../../../../atoms/Table/TableLoading';
import TableError from '../../../../atoms/Table/TableError';
import TableNoData from '../../../../atoms/Table/TableNoData';
import FilteredTable from '../../../../components/FilteredTable/FilteredTable';
import CompanyCreate from '../CompanyCreate/CompanyCreate';
import CompanyUpdate from '../CompanyUpdate/CompanyUpdate';
import ModalDelete from '../../../../components/modal/ModalDelete';
import ButtonIcon from '../../../../atoms/Button/ButtonIcon';
import ButtonGrp from '../../../../atoms/Button/ButtonGrp';
import Tooltip from '../../../../atoms/Tooltip/Tooltip';
import THSortable from '../../../../atoms/Table/THSortable';

import Bin from '../../../../styles/icons/Bin';
import Search from '../../../../styles/icons/Search';


const COL_COUNT = 7;
const INIT_FILTER = {
  offset: 0,
  limit: 20,
  sortBy: '',
  order: '',
  params: {
    id: '',
    name: '',
    email: '',
    phoneNumber: '',
    identificationNumber: '',
    vatNumber: '',
    street: '',
    city: '',
    zip: '',
    state: '',
  },
};


const Header = ({
  // data
  filter,
  translations,
  // methods
  onChangeSort,
  onChangeParam,
}) => (
  <thead>

    <tr>
      <THSortable
        title="#"
        name="id"
        isActiveFilter={filter.params.id !== ''}
        filter={filter}
        style={{ minWidth: '4rem', width: '4rem', }}
        onSort={onChangeSort}
      />

      <THSortable
        title={translations.form.name}
        name="name"
        isActiveFilter={filter.params.name !== ''}
        filter={filter}
        style={{ minWidth: '10rem', width: '10rem', }}
        onSort={onChangeSort}
      />

      <THSortable
        title={translations.form.email}
        name="email"
        isActiveFilter={filter.params.email !== ''}
        filter={filter}
        style={{ minWidth: '10rem', width: '10rem', }}
        onSort={onChangeSort}
      />

      <THSortable
        title={translations.form.identificationNumber}
        name="identificationNumber"
        isActiveFilter={filter.params.identificationNumber !== ''}
        filter={filter}
        style={{ minWidth: '10rem', width: '10rem', }}
        onSort={onChangeSort}
      />

      <THSortable
        title={translations.form.state}
        name="state"
        isActiveFilter={filter.params.state !== ''}
        filter={filter}
        style={{ minWidth: '10rem', width: '10rem', }}
        onSort={onChangeSort}
      />

      <THSortable
        title={translations.form.city}
        name="city"
        isActiveFilter={filter.params.city !== ''}
        filter={filter}
        style={{ minWidth: '10rem', width: '10rem', }}
        onSort={onChangeSort}
      />

      <th
        className="table--text-align-right"
        style={{ minWidth: '5rem', width: '5rem', }}
      >
        {translations.directory.thActions}
      </th>
    </tr>

    <tr>
      <th>
        <Input
          type="text"
          placeholder={translations.common.filter}
          autoComplete="off"
          value={filter.params.id}
          active={filter.params.id !== ''}
          onChange={(e) => onChangeParam('id', e.target.value)}
          onClear={() => onChangeParam('id', '')}
          size="sm"
        />
      </th>

      <th>
        <Input
          type="text"
          placeholder={translations.common.filter}
          autoComplete="off"
          value={filter.params.name}
          active={filter.params.name !== ''}
          onChange={(e) => onChangeParam('name', e.target.value)}
          onClear={() => onChangeParam('name', '')}
          size="sm"
        />
      </th>

      <th>
        <Input
          type="text"
          placeholder={translations.common.filter}
          autoComplete="off"
          value={filter.params.email}
          active={filter.params.email !== ''}
          onChange={(e) => onChangeParam('email', e.target.value)}
          onClear={() => onChangeParam('email', '')}
          size="sm"
        />
      </th>

      <th>
        <Input
          type="text"
          placeholder={translations.common.filter}
          autoComplete="off"
          value={filter.params.identificationNumber}
          active={filter.params.identificationNumber !== ''}
          onChange={(e) => onChangeParam('identificationNumber', e.target.value)}
          onClear={() => onChangeParam('identificationNumber', '')}
          size="sm"
        />
      </th>

      <th>
        <Input
          type="text"
          placeholder={translations.common.filter}
          autoComplete="off"
          value={filter.params.state}
          active={filter.params.state !== ''}
          onChange={(e) => onChangeParam('state', e.target.value)}
          onClear={() => onChangeParam('state', '')}
          size="sm"
        />
      </th>

      <th>
        <Input
          type="text"
          placeholder={translations.common.filter}
          autoComplete="off"
          value={filter.params.city}
          active={filter.params.city !== ''}
          onChange={(e) => onChangeParam('city', e.target.value)}
          onClear={() => onChangeParam('city', '')}
          size="sm"
        />
      </th>

      <th />
    </tr>

  </thead>
);


Header.propTypes = {
  // data
  filter: object.isRequired,
  translations: object.isRequired,
  // methods
  onChangeSort: func.isRequired,
  onChangeParam: func.isRequired,
};


const Rows = ({
  // data
  loading,
  error,
  data,
  resources,
  translations,
  // methods
  onToggleUpdate,
  onToggleDelete,
}) => {
  if (!data.filterCompanies && loading) {
    return (
      <TableLoading
        colsCount={COL_COUNT}
        rowsCount={data && data.filterCompanies && data.filterCompanies.rows.length}
      />
    );
  }
  if (error || !data.filterCompanies) {
    return (
      <TableError
        colsCount={COL_COUNT}
        error={error}
      />
    );
  }
  if (data.filterCompanies.rows.length < 1) {
    return (
      <TableNoData
        colsCount={COL_COUNT}
        text={translations.directory.companiesEmpty}
      />
    );
  }

  return (
    <tbody>
      {data.filterCompanies.rows.map((item) => (
        <tr key={item.id}>
          <td>
            {item.id}
          </td>
          <td>
            {item.name}
          </td>
          <td>
            {item.email}
          </td>
          <td>
            {item.identificationNumber}
          </td>
          <td>
            {item.state}
          </td>
          <td>
            {item.city}
          </td>
          <td className="table--text-align-right table--noPadding">
            <ButtonGrp>
              <Tooltip text={translations.common.detail}>
                {(events) => (
                  <ButtonIcon
                    size="sm"
                    color="tertiary"
                    onClick={(e) => onToggleUpdate(e, item.id)}
                    {...events}
                  >
                    <Search />
                  </ButtonIcon>
                )}
              </Tooltip>
              <Tooltip text={translations.common.delete}>
                {(events) => (
                  <ButtonIcon
                    size="sm"
                    color="error"
                    disabled={!resources.directory_company_write}
                    onClick={(e) => onToggleDelete(e, item.id)}
                    {...events}
                  >
                    <Bin />
                  </ButtonIcon>
                )}
              </Tooltip>
            </ButtonGrp>
          </td>
        </tr>
      ))}
    </tbody>
  );
};


Rows.propTypes = {
  loading: bool.isRequired,
  error: object,
  data: shape({
    filterCompanies: shape({
      rows: arrayOf(shape({
        id: string.isRequired,
        name: string,
        email: string,
        identificationNumber: string,
        state: string,
        city: string,
      })).isRequired,
    }),
  }),
  resources: object.isRequired,
  translations: object.isRequired,
  onToggleUpdate: func.isRequired,
  onToggleDelete: func.isRequired,
};

Rows.defaultProps = {
  error: undefined,
  data: undefined,
};


const CompaniesTable = ({
  // data
  resources,
  createModal,
  updateModal,
  deleteModal,
  translations,
  // methods
  onToggleCreate,
  onToggleUpdate,
  onToggleDelete,
}) => (
  <FilteredTable
    initFilter={INIT_FILTER}
    query={QUERY_COMPANIES}
  >
    {({
      // filter
      queryData,
      filter,
      // filter handlers
      onChangeSort,
      onChangeFilter,
      onChangeParam,
    }) => (
      <Fragment>

        {createModal.isOpen && (
          <CompanyCreate
            modalData={createModal}
            onToggle={onToggleCreate}
            onCreated={() => queryData.refetch()}
          />
        )}

        {updateModal.isOpen && (
          <CompanyUpdate
            modalData={updateModal}
            resources={resources}
            onToggle={onToggleUpdate}
            onUpdated={() => queryData.refetch()}
          />
        )}

        {deleteModal.isOpen && (
          <ModalDelete
            // data
            title={translations.directory.deleteModalCompanyTitle}
            text={translations.directory.deleteCompanyModalText}
            mutation={MUTATION_DELETE_COMPANY}
            variables={{ id: deleteModal.id, }}
            // methods
            onToggle={onToggleDelete}
            onCompleted={queryData.refetch}
          />
        )}

        <Table fillContent>
          <Header
            // data
            filter={filter}
            translations={translations}
            // methods
            onChangeSort={onChangeSort}
            onChangeParam={onChangeParam}
          />
          <Rows
            // data
            {...queryData}
            resources={resources}
            translations={translations}
            // methods
            onToggleUpdate={onToggleUpdate}
            onToggleDelete={onToggleDelete}
          />
        </Table>

        <Pagination
          loading={queryData.loading}
          dataFilter={queryData.data && queryData.data.filterCompanies
            ? queryData.data.filterCompanies.filter
            : undefined
          }
          onChangePage={onChangeFilter}
        />

      </Fragment>
    )}
  </FilteredTable>
);


CompaniesTable.propTypes = {
  resources: object.isRequired,
  translations: object.isRequired,
  createModal: shape({
    isOpen: bool.isRequired,
  }).isRequired,
  updateModal: shape({
    isOpen: bool.isRequired,
    id: string,
  }).isRequired,
  deleteModal: shape({
    isOpen: bool.isRequired,
    id: string,
  }).isRequired,
  onToggleCreate: func.isRequired,
  onToggleUpdate: func.isRequired,
  onToggleDelete: func.isRequired,
};


export default CompaniesTable;
