import styled from 'styled-components';


const InputRadioList = styled.ul`
  padding-left: 0.75rem;
  list-style: none;
`;


export default InputRadioList;
