// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
import React, { Component, } from 'react';
import {
  arrayOf, func, number, object,
} from 'prop-types';

import List from '../../../styles/icons/List';


const WRAPPER_ID = 'transportation-type-select-box';

const ALL_TYPES_INPUT_ID = -1;

class TransportationTypeSelectBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: false, // boolean
      availableTransportationTypes: props.options.filter(
        (t) => props.availableTransportationTypeIds.includes(t.id)
      ), // IdNamePair[]
    };
  }

  componentDidMount() {
    window.addEventListener('click', this.toggleListener);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.toggleListener);
  }

  toggleListener = (ev) => {
    const { options, } = this.props;

    if (options.length === 0) {
      this.setState({ opened: false, });
    } else if (ev.target) {
      const wrapperElem = document.getElementById(WRAPPER_ID);

      if (wrapperElem === ev.target) {
        this.setState((state) => ({ opened: !state.opened, }));
      } else if (!wrapperElem?.contains(ev.target)) {
        this.setState({ opened: false, });
      }
    }
  }

  handleSelect = (ev) => {
    const { selected, onSelect, options, } = this.props;
    const { availableTransportationTypes, } = this.state;

    const id = parseInt(ev.target.value, 10);

    if (id === ALL_TYPES_INPUT_ID) {
      if (selected.length === options.length) {
        onSelect([]);
      } else {
        onSelect(availableTransportationTypes.map(({ id: i, }) => i));
      }
      return;
    }

    if (selected.includes(id)) {
      onSelect(selected.filter((i) => i !== id));
    } else {
      onSelect([ ...selected, id, ]);
    }
  }

  render() {
    const { selected, } = this.props;
    const { opened, availableTransportationTypes, } = this.state;

    return (
      <div
        id={WRAPPER_ID}
        className={`
        control-panel-item
        transportation-type-select-box
        ${availableTransportationTypes.length === 0 ? 'disabled' : ''}
        ${opened ? 'opened' : ''}
      `}
      >
        <List
          className="control-panel-icon"
        />
        Volba služeb
        <div
          className={`menu-pop-up ${opened ? 'opened' : ''}`}
        >
          <div
            className="transportation-type-select-box-item"
          >
            {/* eslint-disable-next-line max-len */}
            {/* eslint-disable-next-line jsx-a11y/label-has-for,jsx-a11y/label-has-associated-control */}
            <label
              htmlFor={`transportation-type-select-box-checkbox-${ALL_TYPES_INPUT_ID}`}
            >
              Vše
            </label>
            <input
              id={`transportation-type-select-box-checkbox-${ALL_TYPES_INPUT_ID}`}
              type="checkbox"
              value={`${ALL_TYPES_INPUT_ID}`}
              name="transportation-type-select-box-checkbox"
              onChange={this.handleSelect}
              checked={availableTransportationTypes.length === selected.length}
            />
          </div>
          {availableTransportationTypes.map((t) => (
            <div
              key={t.id}
              className="transportation-type-select-box-item"
            >
              {/* eslint-disable-next-line jsx-a11y/label-has-for */}
              <label
                htmlFor={`transportation-type-select-box-checkbox-${t.id}`}
              >
                {t.name}
              </label>
              <input
                id={`transportation-type-select-box-checkbox-${t.id}`}
                type="checkbox"
                value={t.id}
                name="transportation-type-select-box-checkbox"
                onChange={this.handleSelect}
                checked={selected.includes(t.id)}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }
}


TransportationTypeSelectBox.propTypes = {
  options: arrayOf(object.isRequired),
  availableTransportationTypeIds: arrayOf(number.isRequired).isRequired,
  selected: arrayOf(number.isRequired).isRequired,
  onSelect: func.isRequired,
};

TransportationTypeSelectBox.defaultProps = {
  options: [],
};

export default TransportationTypeSelectBox;
