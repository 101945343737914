import styled from 'styled-components';

const greenDark = (p) => p.theme.success.t700;
const greyLight = (p) => p.theme.grey.t50;
const greyDark = (p) => p.theme.grey.t400;
const orangeDark = (p) => p.theme.warning.t700;

const StyledStatsGrid = styled.div`
  .stats--list {
    max-width: 100rem;
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin-right: -${(p) => p.theme.common.paddingHalf};
    margin-left: -${(p) => p.theme.common.paddingHalf};
  }

  .stats--grid-card {
    padding-right: ${(p) => p.theme.common.paddingHalf};
    padding-left: ${(p) => p.theme.common.paddingHalf};
  }

  .stats--grid-card-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    width: 30.5rem;
    height: 12rem;
    margin-bottom: ${(p) => p.theme.common.padding};
    padding: 1rem;
    overflow: hidden;
    align-items: center;
    box-sizing: border-box;

    text-decoration: none;
    background: ${(p) => p.theme.white};
    box-shadow: 0 2px 2px rgba(0,0,0,.24), 0 0 2px rgba(0,0,0,.3);
    
    header {
      width: 100%;

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      
      span {
        font-size: 1rem;
        font-weight: 400;
        margin-bottom: 0.15rem;
        color: ${(p) => p.theme.black};
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden; 
        white-space: nowrap;
      }
      
      svg {
        height: 1.5rem;
        width: 1.5rem;
      }
    }
  }
  
  .stats--actual-system-state-card--body, .stats--today-plan-card--body {
    width: 100%;
    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .stats--number-card {
    height: 7rem;
    width: 8rem;
    border: 1px solid;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    
    .stats--number-card--title {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      height: 2rem;
      color: ${(p) => p.theme.white};
      background-color: black;
      font-size: 0.8rem;
    }
    
    .stats--number-card--number {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      flex-grow: 1;
      color: ${(p) => p.theme.black};
      font-weight: bold;
      font-size: 2.5rem;
    }
    
    &.grey {
      border-color: ${greyDark};
      
      .stats--number-card--title {
        background-color: ${greyDark};
      }
      
      .stats--number-card--number {
        background-color: ${greyLight};
      }
    }
    
    &.orange {
      border-color: ${orangeDark};
      
      .stats--number-card--title {
        background-color: ${orangeDark};
      }
      
      .stats--number-card--number {
        background-color: ${(p) => p.theme.warning.t50};
      }
    }
    
    &.green {
      border-color: ${greenDark};
      
      .stats--number-card--title {
        background-color: ${greenDark};
      }
      
      .stats--number-card--number {
        background-color: ${(p) => p.theme.success.t50};
      }
    }
  }
  
  .stats--ramp-loading-card--body,
  .stats--average-waiting-time-card--body,
  .stats--average-clearance-time-card--body {
    width: 100%;
  }
  
  .stats--registration-method-card--body {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    
    .stats--registration-method-card--chart {
      flex-grow: 1;
      
      .column-chart {
        height: 7rem;
        margin-bottom: 0;
      }
    }
  }
  
  .registration-method-column--gap {
    background: transparent;
  }
  
  .registration-method-column {
    // next sibling (i.e. tooltip) is visible
    &:hover+.registration-method-column--tooltip {
      visibility: visible;
    }
  }
  
  .column-auto {
    background: ${greenDark};
  }
  
  .column-kiosk {
    background: ${greyLight};
  }
  
  .column-manual {
    background: ${orangeDark};
  }
  
  .registration-method-column--tooltip {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
    visibility: hidden;
    color: black;
    
    &:hover {
      visibility: visible;
    }
  }
  
  .stats--registration-method-card--legend {
    height: 100%;
  
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    
    .stats--registration-method-card--legend-row {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    
      color: ${greyDark};
    }
    
    .stats--registration-method-card--legend-color {
      height: 1.5rem;
      width: 0.5rem;
      margin-right: 0.5rem;
      
      &.auto {
        background: ${greenDark};
      }
      
      &.kiosk {
        background: ${greyLight};
      }
      
      &.manual {
        background: ${orangeDark};
      }
    }
  }
  
  .ramp-loading-pagination-button {
    margin-left: 0.25rem;
  }
`;


export default StyledStatsGrid;
