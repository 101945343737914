import styled from 'styled-components';


const StyledPictures = styled.div`
  
  ul {
    padding-left: 0.5rem;
  }

  li {
    display: inline-block;
    margin-right: 10px;
    width: 102px;
    height: 102px;
    border: 1px solid #ccc;
  }
`;


export default StyledPictures;
