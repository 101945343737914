import React, { Component, } from 'react';

import Button from './Button';
import Loader from '../Loader/Loader';


class ButtonLoader extends Component {
  constructor(props) {
    super(props);

    this.ref = React.createRef();
    this.state = { loadingWidth: null, };
  }


  componentDidUpdate(prevProps) {
    const { isLoading, } = this.props;

    if (isLoading !== prevProps.isLoading) {
      if (isLoading) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ loadingWidth: this.ref.current.offsetWidth, });
      } else {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ loadingWidth: null, });
      }
    }
  }


  render() {
    const { loadingWidth, } = this.state;
    const {
      isLoading, disabled, children, ...props
    } = this.props;

    return (
      <Button
        ref={this.ref}
        disabled={disabled || isLoading}
        {...props}
        loadingWidth={loadingWidth}
      >
        {(loadingWidth)
          ? <Loader />
          : children
        }
      </Button>
    );
  }
}


export default ButtonLoader;
