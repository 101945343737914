import React, { Fragment, Component, } from 'react';
import {
  func, shape, bool, string, object, arrayOf,
} from 'prop-types';

import styled from 'styled-components';

import { QUERY_PARKING, } from '../../gql/queries';
import { SUBSCRIPTION_PARKING_CARS_ADD, SUBSCRIPTION_PARKING_CARS_REMOVE, } from '../../gql/subscriptions';

import { MUTATION_PARKING_DELETE_CAR, } from '../../gql/mutations';
import Input from '../../../../atoms/Input/Input';
import Table from '../../../../atoms/Table/Table';
import TableLoading from '../../../../atoms/Table/TableLoading';
import TableError from '../../../../atoms/Table/TableError';
import TableNoData from '../../../../atoms/Table/TableNoData';
import FilteredTable from '../../../../components/FilteredTable/FilteredTable';

import ModalDelete from '../../../../components/modal/ModalDelete';
import Tooltip from '../../../../atoms/Tooltip/Tooltip';
import ButtonIcon from '../../../../atoms/Button/ButtonIcon';
import ButtonGrp from '../../../../atoms/Button/ButtonGrp';
import THSortable from '../../../../atoms/Table/THSortable';

import Bin from '../../../../styles/icons/Bin';
import Search from '../../../../styles/icons/Search';

import AddCarToParking from '../AddCarToParking/AddCarToParking';
import UpdateCarOnParking from '../UpdateCarOnParking/UpdateCarOnParking';

import {
  parseDateToHuman,
  parseTimeToHuman,
} from '../../../../logic/date';

const COL_COUNT = 6;
const INIT_FILTER = {
  offset: 0,
  limit: 100,
  sortBy: '',
  order: '',
  params: {
    id: '',
    registrationNumber: '',
    status: '',
    arrival: '',
  },
};

const StyledDiv = styled.div`
  float: right;
  margin-bottom: 0.5rem;
`;

const statuses = {
  unknown: 'Neznámý',
  registered: 'Registrovaný',
  planned: 'Avizovaný',
};

const Header = ({
  // data
  filter,
  translations,
  // methods
  onChangeSort,
  onChangeParam,
}) => (
  <thead>
    <tr>
      <THSortable
        title="#"
        name="id"
        isActiveFilter={filter.params.id !== ''}
        filter={filter}
        style={{ minWidth: '4rem', width: '4rem', }}
        onSort={onChangeSort}
      />

      <THSortable
        title={translations.form.parkingArrival}
        name="arrival"
        isActiveFilter={filter.params.arrival !== ''}
        filter={filter}
        style={{ minWidth: '10rem', width: '12rem', }}
        onSort={onChangeSort}
      />

      <THSortable
        title={translations.form.truckRegistrationNumber}
        name="registrationNumber"
        isActiveFilter={filter.params.registrationNumber !== ''}
        filter={filter}
        style={{ minWidth: '10rem', width: '12rem', }}
        onSort={onChangeSort}
      />

      <THSortable
        title={translations.form.parkingStatus}
        name="status"
        isActiveFilter={filter.params.status !== ''}
        filter={filter}
        style={{ minWidth: '10rem', width: '12rem', }}
        onSort={onChangeSort}
      />

      <th
        className="table--text-align-right"
        style={{ minWidth: '8rem', width: '10rem', }}
      >
        {translations.directory.thActions}
      </th>
    </tr>

    <tr>
      <th>
        <Input
          type="text"
          placeholder={translations.common.filter}
          autoComplete="off"
          value={filter.params.id}
          active={filter.params.id !== ''}
          onChange={(e) => onChangeParam('id', e.target.value)}
          onClear={() => onChangeParam('id', '')}
          size="sm"
        />
      </th>

      <th />

      <th>
        <Input
          type="text"
          placeholder={translations.common.filter}
          autoComplete="off"
          value={filter.params.registrationNumber}
          active={filter.params.registrationNumber !== ''}
          onChange={(e) => onChangeParam('registrationNumber', e.target.value)}
          onClear={() => onChangeParam('registrationNumber', '')}
          size="sm"
        />
      </th>

      <th>
        <Input
          type="text"
          placeholder={translations.common.filter}
          autoComplete="off"
          value={filter.params.status}
          active={filter.params.status !== ''}
          onChange={(e) => onChangeParam('status', e.target.value)}
          onClear={() => onChangeParam('status', '')}
          size="sm"
        />
      </th>

      <th />
    </tr>

  </thead>
);


Header.propTypes = {
  // data
  filter: object.isRequired,
  translations: object.isRequired,
  // methods
  onChangeSort: func.isRequired,
  onChangeParam: func.isRequired,
};

class Rows extends Component {
  componentDidMount() {
    const { subscribeToAddCar, subscribeToRemoveCar, } = this.props;
    subscribeToAddCar();
    subscribeToRemoveCar();
  }

  render() {
    const {
      // data
      loading,
      error,
      data,
      translations,
      // methods
      onToggleUpdate,
      onToggleDelete,
    } = this.props;
    if (!data.filterCarsInParking && loading) {
      return (
        <TableLoading
          colsCount={COL_COUNT}
          rowsCount={data && data.filterCarsInParking && data.filterCarsInParking.rows.length}
        />
      );
    }
    if (error || !data.filterCarsInParking) {
      return (
        <TableError
          colsCount={COL_COUNT}
          error={error}
        />
      );
    }
    if (data.filterCarsInParking.rows.length < 1) {
      return (
        <TableNoData
          colsCount={COL_COUNT}
          text={translations.centralAppParking.infoParkingEmpty}
        />
      );
    }

    return (
      <tbody>
        {data.filterCarsInParking.rows.map((item) => (
          <tr key={item.id}>
            <td>
              {item.id}
            </td>
            <td>
              {item.arrival ? `${parseDateToHuman(new Date(item.arrival), false)} ${parseTimeToHuman(new Date(item.arrival), false)}` : ''}
            </td>
            <td>
              {item.registrationNumber}
            </td>
            <td>
              {statuses[item.status]}
            </td>
            <td className="table--text-align-right table--noPadding">
              <ButtonGrp>
                <Tooltip text={translations.common.detail}>
                  {(events) => (
                    <ButtonIcon
                      size="sm"
                      color="tertiary"
                      onClick={(e) => onToggleUpdate(e, item.id)}
                      {...events}
                    >
                      <Search />
                    </ButtonIcon>
                  )}
                </Tooltip>
                <Tooltip text={translations.centralAppParking.removeButtonTip}>
                  {(events) => (
                    <ButtonIcon
                      size="sm"
                      color="error"
                      disabled={false}
                      onClick={(e) => onToggleDelete(e, item.id)}
                      {...events}
                    >
                      <Bin />
                    </ButtonIcon>
                  )}
                </Tooltip>
              </ButtonGrp>
            </td>
          </tr>
        ))}
      </tbody>
    );
  }
}

Rows.propTypes = {
  loading: bool.isRequired,
  error: object,
  data: shape({
    filterCarsInParking: shape({
      rows: arrayOf(shape({
        id: string.isRequired,
        registrationNumber: string,
        status: string,
        arrival: string,
      })).isRequired,
    }),
  }),
  resources: object.isRequired,
  translations: object.isRequired,
  onToggleUpdate: func.isRequired,
  onToggleDelete: func.isRequired,
  subscribeToAddCar: func.isRequired,
  subscribeToRemoveCar: func.isRequired,
};

Rows.defaultProps = {
  error: undefined,
  data: undefined,
};


const ParkingTable = ({
  // data
  resources,
  createModal,
  updateModal,
  deleteModal,
  translations,
  languageId,
  // methods
  onToggleCreate,
  onToggleUpdate,
  onToggleDelete,
}) => (
  <FilteredTable
    initFilter={INIT_FILTER}
    query={QUERY_PARKING}
  >
    {({
      // filter
      queryData,
      filter,
      // filter handlers
      onChangeSort,
      onChangeParam,
    }) => (
      <Fragment>

        {createModal.isOpen && (
          <AddCarToParking
            languageId={languageId}
            modalData={createModal}
            onToggle={onToggleCreate}
            onCreated={() => queryData.refetch()}
          />
        )}

        {updateModal.isOpen && (
          <UpdateCarOnParking
            modalData={updateModal}
            languageId={languageId}
            resources={resources}
            onToggle={onToggleUpdate}
            onUpdated={() => queryData.refetch()}
          />
        )}

        {deleteModal.isOpen && (
          <ModalDelete
            // data
            title={translations.centralAppParking.titleRemoveCarModal}
            text={translations.centralAppParking.textRemoveCarModal}
            mutation={MUTATION_PARKING_DELETE_CAR}
            variables={{ id: deleteModal.id, }}
            // methods
            onToggle={onToggleDelete}
            onCompleted={queryData.refetch}
          />
        )}

        {queryData && queryData.data && queryData.data.filterCarsInParking
          && queryData.data.filterCarsInParking.filter
          && (
            <StyledDiv>
              <span>
                {`${translations.centralAppParking.countHeader}: `}
                {queryData.data.filterCarsInParking.actualCount}
                /
                {queryData.data.filterCarsInParking.maxCount}
              </span>
            </StyledDiv>
          )
        }

        <Table fillContent>
          <Header
            // data
            filter={filter}
            translations={translations}
            // methods
            onChangeSort={onChangeSort}
            onChangeParam={onChangeParam}
          />
          <Rows
            // data
            {...queryData}
            resources={resources}
            translations={translations}
            // methods
            onToggleUpdate={onToggleUpdate}
            onToggleDelete={onToggleDelete}
            subscribeToAddCar={() => queryData.subscribeToMore({
              document: SUBSCRIPTION_PARKING_CARS_ADD,
              variables: {
              },
              updateQuery: (prev, { subscriptionData, }) => {
                if (!subscriptionData.data) return prev;
                const newCar = subscriptionData.data.addCarToParking;
                queryData.refetch();
                return {
                  filterCarsInParking: {
                    ...prev.filterCarsInParking,
                    filter: {
                      ...prev.filterCarsInParking.filter,
                      count: prev.filterCarsInParking.filter.count + 1,
                    },
                    rows: [
                      newCar,
                      ...prev.filterCarsInParking.rows,
                    ],
                  },
                };
              },
            })}
            subscribeToRemoveCar={() => queryData.subscribeToMore({
              document: SUBSCRIPTION_PARKING_CARS_REMOVE,
              variables: {
              },
              updateQuery: (prev, { subscriptionData, }) => {
                if (!subscriptionData.data) return prev;
                const { id, } = subscriptionData.data.removeCarFromParking;
                queryData.refetch();
                return {
                  filterCarsInParking: {
                    ...prev.filterCarsInParking,
                    rows: [
                      ...prev.filterCarsInParking.rows.filter(
                        (item) => item.id !== id
                      ),
                    ],
                  },
                };
              },
            })}
          />
        </Table>

      </Fragment>
    )}
  </FilteredTable>
);


ParkingTable.propTypes = {
  resources: object.isRequired,
  translations: object.isRequired,
  languageId: string.isRequired,
  createModal: shape({
    isOpen: bool.isRequired,
  }).isRequired,
  updateModal: shape({
    isOpen: bool.isRequired,
    id: string,
  }).isRequired,
  deleteModal: shape({
    isOpen: bool.isRequired,
    id: string,
  }).isRequired,
  onToggleCreate: func.isRequired,
  onToggleUpdate: func.isRequired,
  onToggleDelete: func.isRequired,
};


export default ParkingTable;
