import React from 'react';
import { shape, string, } from 'prop-types';
import { Switch, Route, } from 'react-router-dom';

import NotificationsPage from './NotificationsPage';
import MonitoringPage from './MonitoringPage';
import NotFoundPage from '../../Main/pages/NotFoundPage';


const MonitoringLayout = ({ match, }) => (
  <Switch>
    <Route path={`${match.path}/notifications`} component={NotificationsPage} />
    <Route exact path={`${match.path}`} component={MonitoringPage} />
    <Route component={NotFoundPage} />
  </Switch>
);


MonitoringLayout.propTypes = {
  match: shape({
    path: string.isRequired,
  }).isRequired,
};


export default MonitoringLayout;
