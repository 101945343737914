import React, { Fragment, } from 'react';
import {
  func, object,
} from 'prop-types';

import InputCheckbox from '../../../../atoms/InputCheckbox/InputCheckbox';
import { technicalDocumentsfields, } from '../InputTechnicalCheck/utils';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';

const TechnicalCheckTechnicalDocumentsForm = ({
  form,
  translations,
  values,
  onChange,
}) => (
  <Fragment>
    {technicalDocumentsfields.map((field, index) => (
      <Row key={index}>
        <Col SM={24}>
          <InputCheckbox
            value={values[field]}
            onChange={(newValue) => onChange(form[field].name, newValue)}
            status={form[field].status}
            text={translations.form.adr.technicalDocuments[field]}
          />
        </Col>
      </Row>
    ))}

  </Fragment>
);


TechnicalCheckTechnicalDocumentsForm.propTypes = {
  translations: object.isRequired,
  form: object.isRequired,
  values: object.isRequired,
  onChange: func.isRequired,
};


export default TechnicalCheckTechnicalDocumentsForm;
