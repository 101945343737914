import styled from 'styled-components';


const TabPanel = styled.div`
  border-bottom: 1px solid ${(p) => p.theme.grey.t300};
  padding: 0 0.2rem;
  margin-bottom: 1.5rem;
`;


export default TabPanel;
