export const EMPTY_LAYOUT_CONFIG = {
  rows: [],
};

/**
 * COMMON CHART CONFIG
 */

export const GridDefault = {
  type: 'horizontal',
  dashed: undefined,
  color: '#9aa3b8',
};

export const XAxisDefault = {
  hidden: true,
  dataKey: undefined,
};

export const YAxisDefault = {
  hidden: false,
  axisLine: false,
};

export const ConditionalDefault = {
  type: 'DEFAULT',
  exactValues: NaN,
  fromValues: -Infinity,
  toValues: Infinity,
  sameAsPrevious: true,
  lessThanPrevious: true,
  moreThanPrevious: true,
};

/**
 * LINE CHART CONFIG
 */

export const LineChartLineDotDefault = {
  ...ConditionalDefault,
  hidden: false,
  color: '#9aa3b8',
  size: 12,
};

export const LineChartLineLabelDefault = {
  hidden: true,
  color: '#1d3050',
  fontSize: 12,
};

export const LineChartLineDefault = {
  type: 'linear',
  dataKey: '',
  color: '#1d3050',
  lineWidth: 3,
  dot: [],
  label: LineChartLineLabelDefault,
};

export const LineChartDefault = {
  componentType: 'LINE_CHART',
  title: '',
  height: 250,
  lines: [],
  xAxis: XAxisDefault,
  yAxis: YAxisDefault,
  grid: GridDefault,
};

/**
 * BAR CHART CONFIG
 */

export const BarDefault = {
  ...ConditionalDefault,
  color: '#9aa3b8',
};

export const BarChartDefault = {
  componentType: 'BAR_CHART',
  title: '',
  height: 250,
  barGroups: [],
  xAxis: {
    ...XAxisDefault,
    hidden: false,
    dataKey: 'group',
  },
  yAxis: YAxisDefault,
  grid: GridDefault,
};

/**
 * REPORT TABLE CONFIG
 */

export const ColumnStyleDefault = {
  ...ConditionalDefault,
  textColor: 'black',
  backgroundColor: 'transparent',
  icon: 'NONE',
};

/**
 * UTILS FUNCTIONS
 */

export const findConditionalConfig = (
  configs,
  value,
  prevValue,
) => configs?.find((c) => {
  if (c.type === 'DEFAULT') {
    return true;
  }

  if (c.type === 'COMPARE_TO_PREVIOUS') {
    if (prevValue !== undefined) {
      if (c.sameAsPrevious === true && value === prevValue) {
        return true;
      }
      if (c.lessThanPrevious === true && value < prevValue) {
        return true;
      }
      if (c.moreThanPrevious === true && value > prevValue) {
        return true;
      }
    }
    return false;
  }

  // c.type === 'RANGE'
  if (c.exactValues !== undefined) {
    return c.exactValues === value;
  }
  return (c.fromValues === undefined || c.fromValues <= value)
    && (c.toValues === undefined || c.toValues >= value);
});
