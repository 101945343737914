import React, { Fragment, Component, } from 'react';
import { string, func, object, } from 'prop-types';
import { Mutation, } from 'react-apollo';

import { withNotifications, } from '../../../../logic/notifications/withNotifications';
import { pipe, } from '../../../../logic/utils';
import Modal from '../../../../atoms/Modal/Modal';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import Button from '../../../../atoms/Button/Button';
import ButtonLoader from '../../../../atoms/Button/ButtonLoader';
import FormError from '../../../../components/Form/FormError';

import StyledModalDelete from '../../../../components/modal/styles/StyledModalDelete';
import Error from '../../../../styles/icons/Error';


class ModalLock extends Component {
  onLock = (mutate) => {
    const { variables, } = this.props;
    mutate({ variables: { id: variables.id, active: !variables.active, }, });
  }

  handleCompleted = () => {
    const {
      onToggle,
      addNotification,
      onCompleted,
      completedText,
    } = this.props;

    if (onCompleted) onCompleted();
    onToggle();
    addNotification({
      status: 'success',
      title: completedText,
    });
  }


  render() {
    const {
      // data
      title,
      text,
      buttonText,
      mutation,
      translations,
      // methods
      onToggle,
    } = this.props;
    return (
      <Modal
        isOpen
        size="SM"
        title={title}
        onClose={onToggle}
      >

        <StyledModalDelete>
          <div className="errorModal--info">
            <span className="errorModal--icon">
              <Error />
            </span>
            <span className="errorModal--text">
              {text}
            </span>
          </div>
        </StyledModalDelete>

        <Mutation
          mutation={mutation}
          onCompleted={this.handleCompleted}
        >
          {(mutate, { loading, error, }) => (
            <Fragment>
              <FormError error={error} />
              <Row>
                <Col XS={12} SM={12}>
                  <Button
                    type="button"
                    shape="outline"
                    onClick={onToggle}
                  >
                    {translations.common.back}
                  </Button>
                </Col>
                <Col XS={12} SM={12} textAlign="right">
                  <ButtonLoader
                    type="button"
                    color="error"
                    onClick={() => this.onLock(mutate)}
                    isLoading={loading}
                  >
                    {buttonText}
                  </ButtonLoader>
                </Col>
              </Row>
            </Fragment>
          )}
        </Mutation>

      </Modal>
    );
  }
}


ModalLock.propTypes = {
  // data
  title: string.isRequired,
  text: string.isRequired,
  buttonText: string.isRequired,
  completedText: string.isRequired,
  mutation: object.isRequired,
  variables: object,
  translations: object.isRequired,
  // methods
  onToggle: func.isRequired,
  onCompleted: func,
  addNotification: func.isRequired,
};

ModalLock.defaultProps = {
  variables: undefined,
  onCompleted: undefined,
};


export default pipe(
  withNotifications,
)(ModalLock);
