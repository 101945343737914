import styled from 'styled-components';


const DDList = styled.ul`
  padding-left: 0;
  list-style: none;
  ${(p) => p.minWidth && `
    min-width: ${p.minWidth}rem;
  `}
`;


export default DDList;
