import gql from 'graphql-tag';


export const QUERY_FETCH_TRANSPORTATIONS_WITH_NO_SAMPLE_CONFIRMED = gql`
  query FetchTransportationsWithNoSampleConfirmed {
    fetchTransportationsWithNoSampleConfirmed {
      id
      no
      date
      truckRegistrationNumber
      companyName
      transportationTypeName
    }
  }
`;
