import React, { Fragment, Component, } from 'react';
import { string, func, object, } from 'prop-types';
import { Mutation, } from 'react-apollo';

import { TRANSLATIONS_MODAL_DELETE, } from './translations';
import { withTranslations, } from '../../logic/translations/withTranslations';
import { withNotifications, } from '../../logic/notifications/withNotifications';
import { pipe, } from '../../logic/utils';
import Modal from '../../atoms/Modal/Modal';
import Row from '../../atoms/Row/Row';
import Col from '../../atoms/Col/Col';
import Button from '../../atoms/Button/Button';
import ButtonLoader from '../../atoms/Button/ButtonLoader';
import FormError from '../Form/FormError';

import StyledModalDelete from './styles/StyledModalDelete';
import Error from '../../styles/icons/Error';


class ModalDelete extends Component {
  handleCompleted = () => {
    const {
      translations,
      onToggle,
      addNotification,
      onCompleted,
    } = this.props;

    if (onCompleted) onCompleted();
    onToggle();
    addNotification({
      status: 'success',
      title: translations.common.deleted,
    });
  }


  render() {
    const {
      // data
      title,
      text,
      mutation,
      variables,
      translations,
      // methods
      onToggle,
    } = this.props;
    return (
      <Modal
        isOpen
        size="SM"
        title={title}
        onClose={onToggle}
      >

        <StyledModalDelete>
          <div className="errorModal--info">
            <span className="errorModal--icon">
              <Error />
            </span>
            <span className="errorModal--text">
              {text}
            </span>
          </div>
        </StyledModalDelete>

        <Mutation
          mutation={mutation}
          variables={variables}
          onCompleted={this.handleCompleted}
        >
          {(mutateRemove, { loading, error, }) => (
            <Fragment>
              <FormError error={error} />
              <Row>
                <Col XS={12} SM={12}>
                  <Button
                    type="button"
                    shape="outline"
                    onClick={onToggle}
                  >
                    {translations.common.back}
                  </Button>
                </Col>
                <Col XS={12} SM={12} textAlign="right">
                  <ButtonLoader
                    type="button"
                    color="error"
                    onClick={mutateRemove}
                    isLoading={loading}
                  >
                    {translations.common.delete}
                  </ButtonLoader>
                </Col>
              </Row>
            </Fragment>
          )}
        </Mutation>

      </Modal>
    );
  }
}


ModalDelete.propTypes = {
  // data
  title: string.isRequired,
  text: string.isRequired,
  mutation: object.isRequired,
  variables: object,
  translations: object.isRequired,
  // methods
  onToggle: func.isRequired,
  onCompleted: func,
  addNotification: func.isRequired,
};

ModalDelete.defaultProps = {
  variables: undefined,
  onCompleted: undefined,
};


export default pipe(
  withTranslations(TRANSLATIONS_MODAL_DELETE),
  withNotifications,
)(ModalDelete);
