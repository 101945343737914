import React, { Fragment, } from 'react';
import {
  func, shape, bool, string, object, arrayOf, number,
} from 'prop-types';

import { Query, } from 'react-apollo';
import Table from '../../../../atoms/Table/Table';
import TableLoading from '../../../../atoms/Table/TableLoading';
import TableError from '../../../../atoms/Table/TableError';
import TableNoData from '../../../../atoms/Table/TableNoData';
import UserCreate from '../UserCreate/UserCreate';
import Tooltip from '../../../../atoms/Tooltip/Tooltip';
import ButtonIcon from '../../../../atoms/Button/ButtonIcon';
import ButtonGrp from '../../../../atoms/Button/ButtonGrp';

import {
  QUERY_FETCH_ALL_RS_LANGUAGES,
  QUERY_FILTER_RS_USERS,
  QUERY_FETCH_TRANSPORTATIONS_BY_RS_USER,
} from '../../gql/queries';
import Badge from '../../../../atoms/Badge/Badge';
import Search from '../../../../styles/icons/Search';
import Bin from '../../../../styles/icons/Bin';
import ModalDelete from '../../../../components/modal/ModalDelete';
import { MUTATION_DELETE_RS_USER, MUTATION_LOCK_RS_USER, } from '../../gql/mutations';
import UserUpdate from '../UserUpdate/UserUpdate';
import ModalLock from './ModalLock';
import { getRoleText, } from '../UserForm/role';
import LockClosed from '../../../../styles/icons/LockClosed';
import LockOpened from '../../../../styles/icons/LockOpened';
import FilteredTable from '../../../../components/FilteredTable/FilteredTable';
import Pagination from '../../../../components/Pagination/Pagination';


const COL_COUNT = 4;
const INIT_FILTER = {
  offset: 0,
  limit: 100,
  sortBy: undefined,
  order: undefined,
  params: {},
};

const getActiveText = (item, translations) => {
  if (item.active) {
    return translations.common.no;
  }
  return translations.common.yes;
};

const Header = ({ translations, }) => (
  <thead>
    <tr>

      <th>
        {translations.rs.users.user}
      </th>

      <th>
        {translations.rs.users.blocked}
      </th>

      <th>
        {translations.rs.users.role}
      </th>

      <th>
        {translations.rs.users.language}
      </th>

      <th className="table--text-align-right">
        {translations.common.actions}
      </th>

    </tr>
  </thead>
);

Header.propTypes = {
  translations: object.isRequired,
};


const Rows = ({
  // data
  loading,
  error,
  data,
  translations,
  languages,
  // methods
  toggleUpdate,
  toggleDelete,
  toggleLock,
}) => {
  const ROLE_BADGE_COLOR = {
    color: 'white',
    background: '#666E80',
  };

  const LANGUAGE_BADGE_COLOR = {
    color: 'white',
    background: '#F57C00',
  };

  const ACTIVE_BADGE_COLOR = {
    color: 'white',
    background: '#378E3C',
  };

  if (loading) {
    return (
      <TableLoading
        colsCount={COL_COUNT}
        rowsCount={1}
      />
    );
  }
  if (error || !data.filterRsUsers) {
    return (
      <TableError
        colsCount={COL_COUNT}
        error={error}
      />
    );
  }
  if (data.filterRsUsers.rows.length < 1) {
    return (
      <TableNoData
        colsCount={COL_COUNT}
        text={translations.rs.users.noUsers}
      />
    );
  }
  return (
    <tbody>
      {data.filterRsUsers.rows.map((item) => (
        <tr key={item.id}>
          <td>
            {item.email}
          </td>

          <td>
            <Badge customColor={ACTIVE_BADGE_COLOR}>
              {getActiveText(item, translations)}
            </Badge>
          </td>

          <td>
            <Badge customColor={ROLE_BADGE_COLOR}>
              {getRoleText(item.role, translations)}
            </Badge>
          </td>

          <td>
            <Badge customColor={LANGUAGE_BADGE_COLOR}>
              {languages.find((language) => parseInt(language.id, 10) === item.languageId).name}
            </Badge>
          </td>

          <td
            className="table--text-align-right table--noPadding"
            style={{ minWidth: '5rem', }}
          >
            <ButtonGrp>
              <Tooltip text={translations.common.update}>
                {(events) => (
                  <ButtonIcon
                    size="sm"
                    color="tertiary"
                    onClick={() => toggleUpdate(parseInt(item.id, 10))}
                    {...events}
                  >
                    <Search />
                  </ButtonIcon>
                )}
              </Tooltip>
              <Tooltip text={translations.rs.users.lockButtonTitle}>
                {(events) => (
                  <ButtonIcon
                    size="sm"
                    color="primary"
                    onClick={() => toggleLock(parseInt(item.id, 10), item.active)}
                    {...events}
                  >
                    { item.active ? <LockClosed /> : <LockOpened /> }
                  </ButtonIcon>
                )}
              </Tooltip>
              <Tooltip text={translations.common.delete}>
                {(events) => (
                  <ButtonIcon
                    size="sm"
                    color="error"
                    onClick={() => toggleDelete(parseInt(item.id, 10))}
                    {...events}
                  >
                    <Bin />
                  </ButtonIcon>
                )}
              </Tooltip>
            </ButtonGrp>
          </td>
        </tr>
      ))}
    </tbody>
  );
};


Rows.propTypes = {
  loading: bool.isRequired,
  error: object,
  data: shape({
    filterDrivers: shape({
      rows: arrayOf(shape({
        id: string.isRequired,
        name: string,
        phoneNumber: string,
      })).isRequired,
    }),
  }),
  languages: arrayOf(shape({
    id: string,
    code: string,
    name: string,
  })).isRequired,
  translations: object.isRequired,
  toggleUpdate: func.isRequired,
  toggleDelete: func.isRequired,
  toggleLock: func.isRequired,
};

Rows.defaultProps = {
  error: undefined,
  data: undefined,
};


const UsersTable = ({
  // data
  createModal,
  updateModal,
  deleteModal,
  lockModal,
  translations,
  // methods
  onToggleCreate,
  onToggleUpdate,
  onToggleDelete,
  onToggleLock,
}) => (
  <Query query={QUERY_FETCH_ALL_RS_LANGUAGES}>
    {(languagesData) => {
      if (!languagesData.data.languages || !languagesData.data.languages.rsLangs) {
        return <></>;
      }
      return (
        <FilteredTable
          query={QUERY_FILTER_RS_USERS}
          initFilter={INIT_FILTER}
        >
          {({
            // filter
            queryData,
            // filter handlers
            onChangeFilter,
          }) => (
            <Fragment>
              {createModal.isOpen && (
                <UserCreate
                  languages={languagesData.data.languages.rsLangs}
                  modalData={createModal}
                  onToggle={onToggleCreate}
                  onCreated={() => queryData.refetch()}
                />
              )}

              {updateModal.isOpen && (
                <UserUpdate
                  languages={languagesData.data.languages.rsLangs}
                  modalData={updateModal}
                  onToggle={onToggleUpdate}
                  onUpdated={() => queryData.refetch()}
                />
              )}

              {deleteModal.isOpen && (
                <Query query={QUERY_FETCH_TRANSPORTATIONS_BY_RS_USER} variables={{ rsUserId: deleteModal.id, }}>
                  {(transportationsData) => {
                    if (transportationsData.loading || transportationsData.error) {
                      return <></>;
                    }
                    const transportations = transportationsData.data.fetchTransportationsByRsUser;
                    const plannedTransportations = transportations
                      .filter((transportation) => transportation.state === 'plan');
                    const notFinishedTransportations = transportations
                      .filter((transportation) => transportation.state !== 'finished' && transportation.state !== 'plan');
                    let text = translations.rs.users.deleteModalText;
                    if (notFinishedTransportations.length > 0) {
                      text = `${translations.rs.users.deleteNotFinishedTransportationModalText} ${text}`;
                    }
                    if (plannedTransportations.length > 0) {
                      text = `${translations.rs.users.deletePlanTransportationModalText} ${text}`;
                    }
                    return (
                      <ModalDelete
                        // data
                        title={translations.rs.users.deleteModalTitle}
                        text={text}
                        mutation={MUTATION_DELETE_RS_USER}
                        variables={{ id: deleteModal.id, }}
                        // methods
                        onToggle={onToggleDelete}
                        onCompleted={queryData.refetch}
                      />
                    );
                  }}
                </Query>
              )}

              {lockModal.isOpen && (
                <ModalLock
            // data
                  title={translations.rs.users.lockModalTitle}
                  text={translations.rs.users.lockModalText}
                  buttonText={translations.rs.users.lockModalButtonText}
                  completedText={translations.rs.users.lockModalButtonCompletedText}
                  mutation={MUTATION_LOCK_RS_USER}
                  variables={lockModal}
            // methods
                  onToggle={onToggleLock}
                  onCompleted={queryData.refetch}
                  translations={translations}
                />
              )}

              <Table fillContent>
                <Header
          // data
                  translations={translations}
                />
                <Rows
          // data
                  {...queryData}
                  languages={languagesData.data.languages.rsLangs}
                  translations={translations}
                  toggleUpdate={onToggleUpdate}
                  toggleDelete={onToggleDelete}
                  toggleLock={onToggleLock}
                />
              </Table>

              <Pagination
                loading={queryData.loading}
                dataFilter={queryData.data && queryData.data.filterRsUsers
                  ? queryData.data.filterRsUsers.filter
                  : undefined
                }
                onChangePage={onChangeFilter}
              />
            </Fragment>
          )}
        </FilteredTable>
      );
    }}
  </Query>
);


UsersTable.propTypes = {
  resources: object.isRequired,
  translations: object.isRequired,
  languageId: string.isRequired,
  createModal: shape({
    isOpen: bool.isRequired,
  }).isRequired,
  updateModal: shape({
    isOpen: bool.isRequired,
    id: number,
  }).isRequired,
  deleteModal: shape({
    isOpen: bool.isRequired,
    id: number,
  }).isRequired,
  lockModal: shape({
    isOpen: bool.isRequired,
    id: number,
  }).isRequired,
  onToggleCreate: func.isRequired,
  onToggleUpdate: func.isRequired,
  onToggleDelete: func.isRequired,
  onToggleLock: func.isRequired,
};


export default UsersTable;
