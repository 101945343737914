import React, { Fragment, } from 'react';
import {
  shape, string, func, object, arrayOf, bool,
} from 'prop-types';
import { Query, } from 'react-apollo';

import { QUERY_ROLES, } from '../../gql/queries';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import FormRow from '../../../../components/Form/FormRow';
import Input from '../../../../atoms/Input/Input';
import InputSelect from '../../../../atoms/InputSelect/InputSelect';


const UserForm = ({
  // data
  isCreate,
  userForm: {
    form,
    values,
  },
  translations,
  // methods
  onChangeForm,
}) => (
  <Fragment>
    <FormRow
      label={translations.form.username}
      input={form.username}
    >
      <Input
        type="text"
        value={values.username}
        onChange={(e) => onChangeForm(form.username.name, e.target.value)}
        status={form.username.status}
      />
    </FormRow>

    <Row>
      <Col SM={12}>
        <FormRow
          label={isCreate ? translations.form.password : translations.form.newPassword}
          input={form.password}
        >
          <Input
            type="password"
            value={values.password}
            onChange={(e) => onChangeForm(form.password.name, e.target.value)}
            status={form.password.status}
          />
        </FormRow>
      </Col>
    </Row>

    <Row>
      <Col SM={12}>
        <FormRow
          label={translations.form.email}
          input={form.email}
        >
          <Input
            type="text"
            value={values.email}
            onChange={(e) => onChangeForm(form.email.name, e.target.value)}
            status={form.email.status}
          />
        </FormRow>
      </Col>
    </Row>

    <Row>
      <Col SM={12}>
        <FormRow
          label={translations.form.chip}
          input={form.chip}
        >
          <Input
            type="text"
            value={values.chip}
            onChange={(e) => onChangeForm(form.chip.name, e.target.value)}
            status={form.chip.status}
          />
        </FormRow>
      </Col>
      <Col SM={12}>
        <FormRow
          label={translations.form.pin}
          input={form.pin}
        >
          <Input
            type="text"
            value={values.pin}
            onChange={(e) => onChangeForm(form.pin.name, e.target.value)}
            status={form.pin.status}
          />
        </FormRow>
      </Col>
    </Row>

    <FormRow
      label={translations.form.roles}
      input={form.roles}
    >
      <Query query={QUERY_ROLES}>
        {({ data, loading, error, }) => (
          <InputSelect
            value={values.roles}
            options={data.fetchRoles ? data.fetchRoles : []}
            onChange={(options) => onChangeForm(form.roles.name, options === null ? [] : options)}
            status={form.roles.status}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            isMulti
            isLoading={loading}
            error={error}
            placeholder={translations.common.select}
          />
        )}
      </Query>
    </FormRow>

  </Fragment>
);


UserForm.propTypes = {
  isCreate: bool.isRequired,
  translations: object.isRequired,
  userForm: shape({
    form: shape({
      username: shape({
        name: string.isRequired,
        status: string.isRequired,
      }).isRequired,
      password: shape({
        name: string.isRequired,
        status: string.isRequired,
      }).isRequired,
      email: shape({
        name: string.isRequired,
        status: string.isRequired,
      }).isRequired,
      chip: shape({
        name: string.isRequired,
        status: string.isRequired,
      }).isRequired,
      pin: shape({
        name: string.isRequired,
        status: string.isRequired,
      }).isRequired,
      roles: shape({
        name: string.isRequired,
        status: string.isRequired,
      }).isRequired,
    }).isRequired,
    values: shape({
      username: string.isRequired,
      password: string.isRequired,
      chip: string.isRequired,
      pin: string.isRequired,
      roles: arrayOf(shape({
        id: string.isRequired,
        name: string.isRequired,
      })).isRequired,
    }).isRequired,
  }).isRequired,
  onChangeForm: func.isRequired,
};


export default UserForm;
