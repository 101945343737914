import React from 'react';
import { number, } from 'prop-types';


const TableLoading = ({ colsCount, rowsCount, }) => {
  const rows = rowsCount > 0 ? rowsCount : 1;

  return (
    <tbody>
      {Array.from(Array(rows).keys()).map((item) => (
        <tr key={item}>
          <td colSpan={colsCount}>
            <div className="table--loading" />
          </td>
        </tr>
      ))}
    </tbody>
  );
};


TableLoading.propTypes = {
  rowsCount: number,
  colsCount: number.isRequired,
};

TableLoading.defaultProps = {
  rowsCount: 0,
};


export default TableLoading;
