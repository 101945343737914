import React, { Fragment, } from 'react';
import { number, bool, } from 'prop-types';
import { parseSecondsToTime, } from './utils';


const DurationChartColumn = ({
  duration, maxDuration, selected, clickable,
}) => (
  <Fragment>
    <div
      className="duration-chart-column--top"
      style={{ height: `${(maxDuration - duration) / maxDuration * 100}%`, }}
    />
    <div
      className={`duration-chart-column--down ${selected ? 'selected' : ''} ${clickable ? 'clickable' : ''}`}
      style={{ height: `${duration / maxDuration * 100}%`, }}
    />
    <div className="duration-chart-column--tooltip">
      {parseSecondsToTime(duration)}
    </div>
  </Fragment>
);


DurationChartColumn.propTypes = {
  duration: number.isRequired,
  maxDuration: number.isRequired,
  selected: bool.isRequired,
  clickable: bool.isRequired,
};


export default DurationChartColumn;
