import React, { Fragment, } from 'react';
import {
  string, object, func, shape, arrayOf, bool,
} from 'prop-types';
import { Query, } from 'react-apollo';

import {
  QUERY_DD_GATE_VEHICLE_GROUPS_WITH_TRANSPORTATION_TYPES,
} from '../../gql/queries';
import { parseDateToHuman, } from '../../../../logic/date';
import Input from '../../../../atoms/Input/Input';
import InputSelect from '../../../../atoms/InputSelect/InputSelect';
import InputDateSelect from '../../../../atoms/InputDateSelect/InputDateSelect';
import Table from '../../../../atoms/Table/Table';
import Tooltip from '../../../../atoms/Tooltip/Tooltip';
import ButtonIcon from '../../../../atoms/Button/ButtonIcon';
import ButtonGrp from '../../../../atoms/Button/ButtonGrp';
import THSortable from '../../../../atoms/Table/THSortable';
import Search from '../../../../styles/icons/Search';
import Check from '../../../../styles/icons/Check';
import Bin from '../../../../styles/icons/Bin';
import TableLoading from '../../../../atoms/Table/TableLoading';
import TableError from '../../../../atoms/Table/TableError';
import TableNoData from '../../../../atoms/Table/TableNoData';
import { getGateTypesByGatehouseType, gateTypeToTranslationKey, } from '../enums/gateTypeEnum';
import { getActionIconForRecord, isTransportationWithoutReservation, showActionButtons, } from '../utils';


const COL_COUNT = 6;


const Header = ({
  // data
  filter,
  translations,
  languageId,
  gatehouseType,
  // methods
  onChangeSort,
  onChangeParam,
}) => (
  <thead>
    <tr>
      <th
        className="table--header-title"
        style={{ minWidth: '10rem', width: '10rem', }}
      >
        {translations.gatehouse.direction}
      </th>

      <THSortable
        title={translations.form.time}
        name="time"
        isActiveFilter={!!filter.params.time}
        filter={filter}
        style={{ minWidth: '10rem', width: '10rem', }}
        onSort={onChangeSort}
      />

      <THSortable
        title={translations.form.registrationNumber}
        name="registrationNumber"
        isActiveFilter={!!filter.params.registrationNumber}
        filter={filter}
        style={{ minWidth: '10rem', width: '10rem', }}
        onSort={onChangeSort}
      />

      <THSortable
        title={translations.form.transportationType}
        name="gateVehicleGroupId"
        isActiveFilter={filter.params.gateVehicleGroupId !== null}
        filter={filter}
        style={{ minWidth: '10rem', width: '10rem', }}
        onSort={onChangeSort}
      />

      <th />

      <th
        className="table--header-title table--text-align-right"
        style={{ minWidth: '10rem', width: '10rem', }}
      >
        {translations.transportation.thActions}
      </th>
    </tr>

    <tr>
      <th>
        <InputSelect
          value={filter.params.gateType}
          active={!!filter.params.gateType}
          options={getGateTypesByGatehouseType(gatehouseType)}
          onChange={(option) => onChangeParam('gateType', option, false)}
          getOptionLabel={(option) => translations.form[option.translationKey]}
          getOptionValue={(option) => option.id}
          isClearable
          placeholder={translations.common.filter}
          size="sm"
        />
      </th>

      <th>
        <InputDateSelect
          placeholder={translations.common.from}
          size="sm"
          value={filter.params.timeFrom}
          active={!!filter.params.timeFrom}
          clearable
          onChange={(newDate) => onChangeParam('timeFrom', newDate, false)}
        />
      </th>

      <th>
        <Input
          type="text"
          placeholder={translations.common.filter}
          autoComplete="off"
          value={filter.params.registrationNumber}
          active={filter.params.registrationNumber !== ''}
          onChange={(e) => onChangeParam('registrationNumber', e.target.value)}
          onClear={() => onChangeParam('registrationNumber', '')}
          size="sm"
        />
      </th>

      <th>
        <Query
          query={QUERY_DD_GATE_VEHICLE_GROUPS_WITH_TRANSPORTATION_TYPES}
          variables={{
            languageId,
          }}
        >
          {(dropdownData) => (
            <InputSelect
              value={filter.params.gateVehicleGroupId}
              active={!!filter.params.gateVehicleGroupId}
              options={(dropdownData.data && dropdownData.data.fetchGateVehicleGroupsWithTransportationTypes)
                ? dropdownData.data.fetchGateVehicleGroupsWithTransportationTypes
                : []
              }
              onChange={(option) => onChangeParam('gateVehicleGroupId', option, false)}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              isClearable
              isLoading={dropdownData.loading}
              error={dropdownData.error}
              placeholder={translations.common.filter}
              size="sm"
            />
          )}
        </Query>
      </th>

      <th />
      <th />
    </tr>

    <tr>
      <th />

      <th>
        <InputDateSelect
          placeholder={translations.common.to}
          size="sm"
          value={filter.params.timeTo}
          active={!!filter.params.timeTo}
          clearable
          onChange={(newDate) => onChangeParam('timeTo', newDate, false)}
        />
      </th>

      <th />

      <th />

      <th />

      <th />
    </tr>
  </thead>
);


Header.propTypes = {
  // data
  filter: object.isRequired,
  translations: object.isRequired,
  languageId: string.isRequired,
  gatehouseType: string.isRequired,
  // methods
  onChangeSort: func.isRequired,
  onChangeParam: func.isRequired,
};

const CheckpointIcons = ({ item, }) => {
  if (!item.transportationId) {
    return <></>;
  }

  const actions = [ 'inputSoe', 'clearance', ];
  if (item.hasQualityControl) {
    actions.push('qualityControl');
  }
  if (item.hasOutputSoe) {
    actions.push('outputSoe');
  }
  if (item.hasOutputTechnicalCheck) {
    actions.push('outputTechnicalCheck');
  }

  const isIzd = (item.inputSoe || '').toLowerCase() === 'izd' && item.gateType === 'entrance';
  const checkpoints = isIzd ? [] : item.checkpoints;
  return actions.map((action) => {
    let use = true;
    if (item.gateType === 'exit') {
      use = true;
    } else if (item.gateType === 'entrance' && (isTransportationWithoutReservation(item.transportationTypeId) || isIzd)) {
      use = false;
    } else if (item.gateType === 'entrance' && action === 'inputSoe') {
      use = true;
    }
    return getActionIconForRecord(checkpoints, action, {}, use);
  });
};


const Rows = ({
  // data
  loading,
  error,
  data,
  translations,
  gatehouseType,
  // methods
  onEdit,
  onConfirmEntrance,
  onRejectEntrance,
}) => {
  if (!data.filterGateRecords && loading) {
    return (
      <TableLoading
        colsCount={COL_COUNT}
        rowsCount={
          data && data.filterGateRecords && data.filterGateRecords.rows.length
        }
      />
    );
  }
  if (error || !data.filterGateRecords) {
    return (
      <TableError
        colsCount={COL_COUNT}
        error={error}
      />
    );
  }
  if (data.filterGateRecords.rows.length < 1) {
    return (
      <TableNoData
        colsCount={COL_COUNT}
        text={translations.gatehouse.noRecords}
      />
    );
  }

  return (
    <tbody>
      {data.filterGateRecords.rows.map((item) => (
        <tr key={item.id}>
          <td>
            {translations.form[gateTypeToTranslationKey(item.gateType)]}
          </td>
          <td>
            {parseDateToHuman(new Date(item.time))}
          </td>
          <td>
            {item.registrationNumber}
          </td>
          <td>
            {item.gateVehicleGroupName ? item.gateVehicleGroupName : item.transportationTypeName}
          </td>
          <td>
            <CheckpointIcons item={item} />
          </td>
          <td className="table--text-align-right table--noPadding">
            <ButtonGrp>
              <Tooltip text={translations.common.detail}>
                {(events) => (
                  <ButtonIcon
                    size="sm"
                    color="tertiary"
                    onClick={() => onEdit(item)}
                    {...events}
                  >
                    <Search />
                  </ButtonIcon>
                )}
              </Tooltip>

              {showActionButtons(gatehouseType, item) && (
                <>
                  <Tooltip text={translations.gatehouse.confirmEntrance}>
                    {(events) => (
                      <ButtonIcon
                        size="sm"
                        color="success"
                        onClick={() => onConfirmEntrance(item)}
                        {...events}
                      >
                        <Check />
                      </ButtonIcon>
                    )}
                  </Tooltip>
                </>
              )}

              <Tooltip
                text={translations.gatehouse.rejectEntrance}
              >
                {(events) => (
                  <ButtonIcon
                    size="sm"
                    color="error"
                    onClick={() => (onRejectEntrance(item.id))}
                    {...events}
                  >
                    <Bin />
                  </ButtonIcon>
                )}
              </Tooltip>
            </ButtonGrp>
          </td>
        </tr>
      ))}
    </tbody>
  );
};


Rows.propTypes = {
  loading: bool.isRequired,
  error: object,
  data: shape({
    filterGateRecords: shape({
      rows: arrayOf(shape({
        id: string.isRequired,
        gateType: string.isRequired,
        time: string.isRequired,
        registrationNumber: string.isRequired,
        transportationTypeName: string,
        gateVehicleGroupName: string,
        gateVehicleGroupId: string,
      })).isRequired,
    }),
  }),
  translations: object.isRequired,
  gatehouseType: string.isRequired,
  onEdit: func.isRequired,
  onConfirmEntrance: func.isRequired,
  onRejectEntrance: func.isRequired,
};

Rows.defaultProps = {
  error: undefined,
  data: undefined,
};


const TableView = ({
  // data
  filter,
  queryData,
  translations,
  languageId,
  gatehouseType,
  // methods
  onEdit,
  onConfirmEntrance,
  onRejectEntrance,
  onChangeSort,
  onChangeParam,
}) => (
  <Fragment>
    <Table fillContent>
      <Header
        // data
        filter={filter}
        translations={translations}
        languageId={languageId}
        gatehouseType={gatehouseType}
        // methods
        onChangeSort={onChangeSort}
        onChangeParam={onChangeParam}
      />
      <Rows
        // data
        {...queryData}
        translations={translations}
        gatehouseType={gatehouseType}
        // methods
        onEdit={onEdit}
        onConfirmEntrance={onConfirmEntrance}
        onRejectEntrance={onRejectEntrance}
      />
    </Table>
  </Fragment>
);


TableView.propTypes = {
  filter: shape({
    params: object.isRequired,
  }).isRequired,
  queryData: shape({
    loading: bool.isRequired,
    error: object,
    data: object,
  }).isRequired,
  translations: object.isRequired,
  languageId: string.isRequired,
  gatehouseType: string.isRequired,
  onEdit: func.isRequired,
  onConfirmEntrance: func.isRequired,
  onRejectEntrance: func.isRequired,
  onChangeSort: func.isRequired,
  onChangeParam: func.isRequired,
};


export default TableView;
