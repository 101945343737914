const TRANSLATIONS = {
  reservationCount: 'Počet rezervací',
  registrationBeforeReservationStartCount: 'Registrováno před začátkem rezervace',
  registrationInReservationWindowCount: 'Registrováno v době rezervace',
  registrationAfterReservationEndCount: 'Registrováno po konci rezervace',
  startClearanceBeforeReservationWindowPlusIntervalCount: 'Start odbavení před začátkem rezervace',
  startClearanceInReservationWindowPlusIntervalCount: 'Start odbavení v době rezervace',
  startClearanceAfterReservationEndCount: 'Start odbavení po konci rezervace',
  endClearanceInReservationWindowCount: 'Konec odbavení v době rezervace',
  endClearanceBeforeReservationWindowPlusIntervalCount: 'Konec odbavení před začátkem rezervace',
  endClearanceAfterReservationWindowPlusIntervalCount: 'Konec odbavení po konci rezervace',
  clearedCount: 'Odbaveno',
  notClearedCount: 'Nedokončeno',
  avgWaitingTimeInMinutes: 'Čekání před odbavením',
  waitingMoreThanMinutesCount: 'Čekajících nad dané minuty',
  avgClearingTimeInMinutes: 'Průměrná délka odbavení',
  clearingMoreThanMinutesCount: 'S časem odbavení nad dané minuty',
  avgRegistrationToDepartureTimeInMinutes: 'Od registrace po odjezd',
  registrationToDepartureTimeMoreThanMinutesCount: 'S celkovým časem odbavení nad dané minuty',
  clearedCountByClearingUser: 'Odbaveno manipulantem',
  avgClearingTimeInMinutesByClearingUser: 'Průměrná délka odbavení manipulantem',
  clearingMoreThanMinutesCountByClearingUser: 'S časem odbavení manipulantem nad dané minuty',
  companyCount: 'Počet společností',
  avgCallingToClearanceStartTimeInMinutes: 'Průměrné čekání po vyvolání',
  transportationCountByCompany: 'Počet přeprav společnosti',
  reservationCountByCompany: 'Počet rezervací společnosti',
  registrationBeforeReservationStartCountByCompany: 'Registrováno společností před začátkem rezervace',
  avgCallingToClearanceStartTimeInMinutesByCompany: 'Průměrné čekání společnosti po vyvolání',
  problematicTransportationCountByCompany: 'Počet problematických přeprav společnosti',

  diff: 'Vývoj',
  pc: 'Poměr',
};

export const getMetricTranslation = (
  group,
  useLongDiffName = true,
) => {
  if (group.endsWith('Diff')) {
    if (useLongDiffName) {
      return `${TRANSLATIONS[group.slice(0, -4)]} - ${TRANSLATIONS.diff}`;
    }
    return TRANSLATIONS.diff;
  }

  if (group.endsWith('Pc')) {
    if (useLongDiffName) {
      return `${TRANSLATIONS[group.slice(0, -2)]} - ${TRANSLATIONS.pc}`;
    }
    return TRANSLATIONS.pc;
  }

  return TRANSLATIONS[group];
};
