import React from 'react';

import { clearSecondsAndMilliseconds, } from '../../../logic/date';
import FileImportSolid from '../../../styles/icons/FileImportSolid';
import WarehouseSolid from '../../../styles/icons/WarehouseSolid';
import FileSignitureSolid from '../../../styles/icons/FileSignitureSolid';
import VialCircleCheckSolid from '../../../styles/icons/VialCircleCheckSolid';
import TruckFieldUnSolid from '../../../styles/icons/TruckFieldUnSolid';
import TruckArrowRightSolid from '../../../styles/icons/TruckArrowRightSolid';

export const parseConfirmEntranceMutationVariables = (values, isEditMode) => {
  const baseValues = {
    driverName: values.driverName,
    driverPhoneNumber: values.driverPhoneNumber,
    gateVehicleGroupId: values.transportationId ? null : (values.gateVehicleGroupId ? values.gateVehicleGroupId.id : null),
    registrationNumber: values.registrationNumber,
    trailerRegistrationNumber: values.trailerRegistrationNumber,
    note: values.note,
    transportationId: values.transportationId,
  };
  if (isEditMode) {
    return {
      ...baseValues,
      id: values.id,
    };
  }
  return {
    ...baseValues,
    gateType: values.gateType.id,
    time: clearSecondsAndMilliseconds(values.time),
  };
};

export const isTransportationWithoutReservation = (transportationTypeId) => [ 12, 13, ].includes(parseInt(transportationTypeId, 10));

const unchecked = {
  width: '2em',
  height: '2em',
  marginRight: '0.5em',
  color: '#a2a2a2',
};

const checkedStyle = { ...unchecked, color: '#008000', };
const alternativeUncheckedStyle = { ...unchecked, color: '#800000', };

export const getActionIconForRecord = (
  checkpoints,
  action,
  extraStyle = {},
  useAlternativeUncheckedStyle = false,
  checkedCallback = null,
) => {
  const checked = checkedCallback ? checkedCallback() : checkpoints.find((c) => c.action === action);
  const baseStyle = checked
    ? checkedStyle
    : (useAlternativeUncheckedStyle ? alternativeUncheckedStyle : unchecked);
  const style = { ...baseStyle, ...extraStyle, };

  if (action === 'inputSoe') {
    return <FileImportSolid style={style} />;
  }
  if (action === 'clearance') {
    return <WarehouseSolid style={style} />;
  }
  if (action === 'outputSoe') {
    return <FileSignitureSolid style={style} />;
  }
  if (action === 'qualityControl') {
    return <VialCircleCheckSolid style={style} />;
  }
  if (action === 'outputTechnicalCheck') {
    return <TruckFieldUnSolid style={style} />;
  }
  if (action === 'inputTechnicalCheck') {
    return <TruckArrowRightSolid style={style} />;
  }
  return null;
};

export const GATEHOUSE_TYPE_EAST = 'east';
export const GATEHOUSE_TYPE_WEST = 'west';

export const showActionButtons = (gatehouseType, item) => (gatehouseType !== GATEHOUSE_TYPE_EAST || (gatehouseType === GATEHOUSE_TYPE_EAST && item.gateVehicleGroupId));
