import React, { Component, Fragment, } from 'react';
import {
  object, shape, string, func, bool, oneOfType,
} from 'prop-types';
import { Query, } from 'react-apollo';

import { QUERY_DROPDOWN, } from '../../gql/queries';
import InputCheckbox from '../../../../atoms/InputCheckbox/InputCheckbox';
import PartLoading from '../../../../components/Parts/PartLoading';
import PartError from '../../../../components/Parts/PartError';


class RoleResource extends Component {
  handleChangeValueConstraint = (name, newValue) => {
    const {
      resource: {
        id,
      },
      value,
      onChangeResourceValue,
    } = this.props;

    const allValue = value === undefined ? false : value.all;
    const payloadValue = value === undefined ? {} : value.payload;

    onChangeResourceValue(
      id,
      {
        id,
        all: allValue === true && newValue === true,
        constraint: true,
        payload: {
          ...payloadValue,
          [name]: newValue,
        },
      },
    );
  }


  allSubResourcesToPayload = (allSubResources) => {
    const ret = {};
    for (let i = 0; i < allSubResources.length; i++) {
      const { id, } = allSubResources[i];
      ret[id] = true;
    }
    return ret;
  }


  handleChangeAll = (newValue, allSubResources) => {
    const {
      resource: {
        id,
      },
      onChangeResourceValue,
    } = this.props;

    onChangeResourceValue(
      id,
      {
        id,
        all: newValue,
        constraint: true,
        payload: newValue === true
          ? this.allSubResourcesToPayload(allSubResources)
          : {},
      },
    );
  }


  handleChangeValue = (newValue) => {
    const {
      resource: {
        id,
      },
      onChangeResourceValue,
    } = this.props;

    onChangeResourceValue(
      id,
      {
        id,
        all: false,
        constraint: false,
        payload: newValue,
      },
    );
  }


  render() {
    const {
      resource: {
        name,
        constraint,
        dropDownType,
      },
      value,
      translations,
      languageId,
    } = this.props;


    if (constraint) {
      const allValue = (value === undefined) ? false : value.all;
      const payloadValue = (value === undefined) ? {} : value.payload;

      return (
        <li className="settings--tree-item">
          <Query
            query={QUERY_DROPDOWN}
            variables={{
              type: dropDownType,
              languageId,
            }}
            fetchPolicy="no-cache"
          >
            {({ loading, error, data, }) => {
              if (loading) return <PartLoading />;
              if (error || !data.fetchDropDown) return <PartError error={error} />;
              return (
                <Fragment>
                  <div>
                    <b>{name}</b>
                  </div>
                  <ul className="settings--tree-list">
                    <li key="all" className="settings--tree-item">
                      <InputCheckbox
                        value={allValue}
                        text={translations.settings.allResources}
                        onChange={(newValue) => this.handleChangeAll(
                          newValue, data.fetchDropDown.data
                        )}
                      />
                    </li>
                    {data.fetchDropDown.data.map((item) => (
                      <li key={item.id} className="settings--tree-item">
                        <InputCheckbox
                          value={!!(payloadValue[item.id]) || allValue}
                          text={item.name}
                          onChange={(newValue) => this.handleChangeValueConstraint(
                            item.id, newValue
                          )}
                        />
                      </li>
                    ))}
                  </ul>
                </Fragment>
              );
            }}
          </Query>
        </li>
      );
    }

    const checkboxValue = (value === undefined) ? false : value.payload;

    return (
      <li className="settings--tree-item">
        <InputCheckbox
          value={checkboxValue}
          text={name}
          onChange={(newValue) => this.handleChangeValue(newValue)}
        />
      </li>
    );
  }
}


RoleResource.propTypes = {
  value: shape({
    id: string.isRequired,
    all: bool.isRequired,
    payload: oneOfType([ bool, object, ]).isRequired,
  }),
  resource: shape({
    id: string.isRequired,
    name: string.isRequired,
    constraint: bool.isRequired,
    dropDownType: string,
  }).isRequired,
  translations: object.isRequired,
  languageId: string.isRequired,
  onChangeResourceValue: func.isRequired,
};

RoleResource.defaultProps = {
  value: undefined,
};


export default RoleResource;
