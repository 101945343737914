import React, { Fragment, } from 'react';
import { func, string, } from 'prop-types';

import Row from '../../atoms/Row/Row';
import Col from '../../atoms/Col/Col';
import Button from '../../atoms/Button/Button';
import PartLoading from '../Parts/PartLoading';


const ModalLoadingPart = ({ onBack, btnBack, }) => (
  <Fragment>
    <PartLoading />
    <Row>
      <Col textAlign="right">
        <Button
          onClick={onBack}
        >
          {btnBack}
        </Button>
      </Col>
    </Row>
  </Fragment>
);


ModalLoadingPart.propTypes = {
  onBack: func.isRequired,
  btnBack: string.isRequired,
};


export default ModalLoadingPart;
