import React from 'react';
import {
  arrayOf, string, shape, object, func,
} from 'prop-types';

import Row from './Row';


const Section = ({
  rows,
  params,
  getItemNameAndStyle,
  onClickItem,
}) => (
  <li>
    {rows.map((item) => (
      <Row
        key={item.id}
        {...item}
        params={params}
        getItemNameAndStyle={getItemNameAndStyle}
        onClickItem={onClickItem}
      />
    ))}
  </li>
);


Section.propTypes = {
  params: object.isRequired,
  rows: arrayOf(shape({
    id: string.isRequired,
    items: arrayOf(object).isRequired,
  })).isRequired,
  getItemNameAndStyle: func.isRequired,
  onClickItem: func.isRequired,
};


export default Section;
