import React, { Component, } from 'react';
import { object, } from 'prop-types';

import { withTranslations, } from '../../../logic/translations/withTranslations';
import { QUERY_COMPANIES_VIEW_TRANSLATIONS, } from '../gql/translations';
import Breadcrumb from '../../../atoms/Breadcrumb/Breadcrumb';
import BreadcrumbItem from '../../../atoms/Breadcrumb/BreadcrumbItem';
import PageFullScreen from '../../../atoms/PageView/PageFullScreen';
import CompaniesView from '../components/Companies/CompaniesView';


class CompaniesPage extends Component {
  state = {
    createModal: {
      isOpen: false,
    },
    updateModal: {
      isOpen: false,
      id: null,
    },
    deleteModal: {
      isOpen: false,
      id: null,
    },
  }


  handleToggleCreate = () => {
    this.setState((prevState) => ({
      createModal: {
        ...prevState.createModal,
        isOpen: !prevState.createModal.isOpen,
      },
    }));
  }


  handleToggleUpdate = (e, id = null) => {
    this.setState((prevState) => ({
      updateModal: {
        ...prevState.updateModal,
        isOpen: !prevState.updateModal.isOpen,
        id,
      },
    }));
  }


  handleToggleDelete = (e, id = null) => {
    this.setState((prevState) => ({
      deleteModal: {
        ...prevState.deleteModal,
        isOpen: !prevState.deleteModal.isOpen,
        id,
      },
    }));
  }


  handleDelete = (e, id) => {
    e.stopPropagation();

    this.setState({
      deleteModal: {
        isOpen: true,
        id,
      },
    });
  }


  render() {
    const { createModal, updateModal, deleteModal, } = this.state;
    const { translations, resources, } = this.props;

    return (
      <PageFullScreen>

        <Breadcrumb>
          <BreadcrumbItem to="/">{translations.common.home}</BreadcrumbItem>
          <BreadcrumbItem>{translations.common.companies}</BreadcrumbItem>
        </Breadcrumb>

        <CompaniesView
          // data
          translations={translations}
          resources={resources}
          createModal={createModal}
          updateModal={updateModal}
          deleteModal={deleteModal}
          // methods
          onToggleCreate={this.handleToggleCreate}
          onToggleUpdate={this.handleToggleUpdate}
          onToggleDelete={this.handleToggleDelete}
        />

      </PageFullScreen>
    );
  }
}


CompaniesPage.propTypes = {
  translations: object.isRequired,
  resources: object,
};

CompaniesPage.defaultProps = {
  resources: {},
};


export default withTranslations(QUERY_COMPANIES_VIEW_TRANSLATIONS)(CompaniesPage);
