import React from 'react';
import {
  string, shape, bool, func,
} from 'prop-types';
import { Query, } from 'react-apollo';

import { QUERY_GENERIC_FORM_DIAL, } from '../queries';
import FormRow from '../../Form/FormRow';
import InputRadio from '../../../atoms/InputRadio/InputRadio';
import InputRadioList from '../../../atoms/InputRadio/InputRadioList';
import PartLoading from '../../Parts/PartLoading';
import PartError from '../../Parts/PartError';


const GFRadio = ({
  input,
  value,
  languageId,
  onChange,
}) => (
  <FormRow
    label={input.translation.label}
    input={input}
    info={input.translation.help}
  >
    <Query
      query={QUERY_GENERIC_FORM_DIAL}
      variables={{
        languageId,
        genericFormDialId: input.genericFormDialId,
      }}
    >
      {({ loading, error, data, }) => {
        if (loading) {
          return <PartLoading />;
        }
        if (error || !data.fetchGenericFormDial) {
          return <PartError error={error} />;
        }
        return (
          <InputRadioList>
            {data.fetchGenericFormDial.map((option) => (
              <li key={option.id}>
                <InputRadio
                  value={value ? value.id : null}
                  text={option.name}
                  name={option.id}
                  disabled={input.disabled}
                  onChange={() => onChange(input.name, option)}
                />
              </li>
            ))}
          </InputRadioList>
        );
      }}
    </Query>
  </FormRow>
);


GFRadio.propTypes = {
  languageId: string.isRequired,
  input: shape({
    genericFormDialId: string.isRequired,
    name: string.isRequired,
    type: string.isRequired,
    disabled: bool.isRequired,
    translation: shape({
      label: string,
      placeholder: string,
      help: string,
    }).isRequired,
  }).isRequired,
  value: shape({
    id: string.isRequired,
    name: string.isRequired,
  }),
  onChange: func.isRequired,
};

GFRadio.defaultProps = {
  value: null,
};


export default GFRadio;
