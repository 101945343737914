import gql from 'graphql-tag';

export const MUTATION_CREATE_TRANSPORTATION_FROM_GATE_RECORD = gql`
  mutation CreateTransportationFromGateRecord($record: transportationFromGateRecordInputType) {
    createTransportationFromGateRecord(record: $record)
  }
`;

export const MUTATION_CONFIRM_ENTRANCE = gql`
  mutation ConfirmEntrance($record: confirmEntranceInputType) {
    confirmEntrance(record: $record)
  }
`;

export const MUTATION_REJECT_ENTRANCE = gql`
  mutation RejectEntrance($id: ID!) {
    rejectEntrance(id: $id)
  }
`;

export const MUTATION_CONFIRM_DEPARTURE = gql`
  mutation ConfirmDeparture($id: ID!) {
    confirmDeparture(id: $id)
  }
`;

export const MUTATION_ASSIGN_TRANSPORTATION_TO_GATE_RECORD = gql`
  mutation AssignTransportationToGateRecord($id: ID!, $transportationId: ID!) {
    assignTransportationToGateRecord(id: $id, transportationId: $transportationId)
  }
`;
