import React from 'react';
import { BrowserRouter as Router, Route, } from 'react-router-dom';
import { ThemeProvider, } from 'styled-components';
import { ApolloProvider, } from 'react-apollo';

import { client, } from './logic/graphql/apollo';
import ModalProvider from './atoms/Modal/ModalProvider';
import MainLayout from './modules/Main/pages/MainLayout';

import theme from './styles/theme';


const App = () => (
  <ApolloProvider client={client}>
    <ThemeProvider theme={theme}>
      <ModalProvider>
        <Router>
          <Route component={MainLayout} />
        </Router>
      </ModalProvider>
    </ThemeProvider>
  </ApolloProvider>
);


export default App;
