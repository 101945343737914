import React, { Fragment, } from 'react';
import {
  func, object, arrayOf,
} from 'prop-types';

import FormRow from '../../../../components/Form/FormRow';
import Input from '../../../../atoms/Input/Input';
import InputSelect from '../../../../atoms/InputSelect/InputSelect';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import InputCheckbox from '../../../../atoms/InputCheckbox/InputCheckbox';


const TechnicalCheckCisternForm = ({
  form,
  translations,
  values,
  chamberForms,
  chamberFillingForms,
  onChange,
}) => (
  <Fragment>
    <FormRow
      label={translations.form.chamberCount}
      input={form.chamberCount}
    >
      <InputSelect
        value={values.chamberCount}
        options={[ 1, 2, 3, 4, ].map((n) => ({ id: n, name: n, }))}
        onChange={(option) => onChange(form.chamberCount.name, option)}
        status={form.chamberCount.status}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        placeholder={translations.common.select}
      />
    </FormRow>

    {chamberForms.map((chamberForm, index) => (
      <Row key={index}>
        <Col SM={12}>
          <FormRow
            label={`${index + 1}${translations.form.chamberVolume}`}
            input={chamberForm.form.volume}
          >
            <Input
              type="text"
              value={chamberForm.values.volume}
              onChange={(e) => onChange(`chambers.${chamberForm.form.volume.name}`, e.target.value, index)}
              status={chamberForm.form.volume.status}
            />
          </FormRow>
        </Col>
        <Col SM={12}>
          <FormRow
            label={translations.form.breakwaterCount}
            input={chamberForm.form.breakwaterCount}
          >
            <Input
              type="number"
              value={chamberForm.values.breakwaterCount}
              onChange={(e) => onChange(`chambers.${chamberForm.form.breakwaterCount.name}`, parseInt(e.target.value, 10), index)}
              status={chamberForm.form.breakwaterCount.status}
            />
          </FormRow>
        </Col>
      </Row>
    ))}

    <FormRow
      label={translations.form.chamberFillingCount}
      input={form.chamberFillingCount}
    >
      <InputSelect
        value={values.chamberFillingCount}
        options={[ 0, 1, 2, 3, 4, ].map((n) => ({ id: n, name: n, }))}
        onChange={(option) => onChange(form.chamberFillingCount.name, option)}
        status={form.chamberFillingCount.status}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        placeholder={translations.common.select}
      />
    </FormRow>

    {chamberFillingForms.map((chamberFillingForm, index) => (
      <Row key={index}>
        <Col SM={8}>
          <FormRow
            label={`${index + 1}${translations.form.chamberFillingProduct}`}
            input={chamberFillingForm.form.product}
          >
            <Input
              type="text"
              value={chamberFillingForm.values.product}
              onChange={(e) => onChange(`chamberFilling.${chamberFillingForm.form.product.name}`, e.target.value, index)}
              status={chamberFillingForm.form.product.status}
            />
          </FormRow>
        </Col>
        <Col SM={8}>
          <FormRow
            label={translations.form.chamberFillingCleared}
            input={chamberFillingForm.form.cleared}
          >
            <InputCheckbox
              value={chamberFillingForm.values.cleared}
              onChange={(newValue) => onChange(`chamberFilling.${chamberFillingForm.form.cleared.name}`, newValue, index)}
              status={chamberFillingForm.form.cleared.status}
            />
          </FormRow>
        </Col>
      </Row>
    ))}

  </Fragment>
);


TechnicalCheckCisternForm.propTypes = {
  translations: object.isRequired,
  form: object.isRequired,
  values: object.isRequired,
  chamberForms: arrayOf(object).isRequired,
  chamberFillingForms: arrayOf(object).isRequired,
  onChange: func.isRequired,
};


export default TechnicalCheckCisternForm;
