import React, { Component, } from 'react';
import {
  bool, string, arrayOf, object, number, func,
} from 'prop-types';

import { withNotifications, } from '../../../../logic/notifications/withNotifications';
import WizardView from './WizardView';
import TabFinishView from './TabFinishView';


export const FINISH_TYPES = {
  AUTO: 'auto',
  SELECT: 'select',
};


class TabFinish extends Component {
  state = {
    finishType: FINISH_TYPES.AUTO,
    targetStand: null,
  }


  handleChangeFinishType = (finishType) => {
    this.setState({
      finishType,
    });
  }


  handleChangeTargetStand = (targetStand) => {
    this.setState({
      targetStand,
    });
  }


  handleCloseModal = () => {
    const { onToggle, } = this.props;

    onToggle();
  }


  handleChangeTab = (to) => {
    const { activeTab, onChangeTab, } = this.props;

    onChangeTab({
      from: activeTab,
      to,
      completed: false,
    });
  }


  handleMoveToNextStand = (mutationMove) => {
    const { targetStand, finishType, } = this.state;
    const {
      transportationId,
      standId,
    } = this.props;

    mutationMove({
      variables: {
        transportationId,
        sourceStandId: standId,
        targetStandId: (targetStand === null || finishType === FINISH_TYPES.AUTO)
          ? undefined
          : targetStand.id,
      },
    });
  }


  handleMoveToNextStandCompleted = () => {
    const {
      onToggle, addNotification, translations,
    } = this.props;

    onToggle();
    addNotification({
      status: 'success',
      title: translations.stand.transportWasMoved,
    });
  }


  render() {
    const { finishType, targetStand, } = this.state;
    const {
      standId,
      languageId,
      transportationId,
      manualMoveToNextStandsPossible,
      tabs,
      activeTab,
      completedUntilTab,
      translations,
    } = this.props;

    return (
      <WizardView
        // data
        tabs={tabs}
        activeTab={activeTab}
        completedUntilTab={completedUntilTab}
        translations={translations}
        // methods
        onClose={this.handleCloseModal}
        onChangeTab={this.handleChangeTab}
      >
        <TabFinishView
          // data
          finishType={finishType}
          targetStand={targetStand}
          standId={standId}
          languageId={languageId}
          transportationId={transportationId}
          manualMoveToNextStandsPossible={manualMoveToNextStandsPossible}
          translations={translations}
          // methods
          onChangeFinishType={this.handleChangeFinishType}
          onChangeTargetStand={this.handleChangeTargetStand}
          onMoveToNextStand={this.handleMoveToNextStand}
          onMoveToNextStandCompleted={this.handleMoveToNextStandCompleted}
        />
      </WizardView>
    );
  }
}


TabFinish.propTypes = {
  // data
  standId: string.isRequired,
  languageId: string.isRequired,
  transportationId: string.isRequired,
  manualMoveToNextStandsPossible: bool.isRequired,
  tabs: arrayOf(object).isRequired,
  activeTab: number.isRequired,
  completedUntilTab: number.isRequired,
  translations: object.isRequired,
  // methods
  onToggle: func.isRequired,
  onChangeTab: func.isRequired,
  addNotification: func.isRequired,
};


export default withNotifications(TabFinish);
