import React, { Component, } from 'react';
import { object, func, bool, } from 'prop-types';

import { pipe, } from '../../logic/utils';
import { withTranslations, } from '../../logic/translations/withTranslations';
import { withErrors, } from '../../logic/errorManager/withErrors';
import { QUERY_INPUT_SELECT_TRANSLATIONS, } from './queries';
import InputSelectStyle from './InputSelectStyle';


class InputSelect extends Component {
  handleChange = (newValue) => {
    const { isMulti, onChange, } = this.props;

    if (newValue === null && isMulti) onChange([]);
    onChange(newValue);
  }


  render() {
    const {
      translations,
      error,
      translateGraphQLError,
      disabled,
      onChange,
      isMulti,
      active,
      ...rest
    } = this.props;

    return (
      <InputSelectStyle
        errorMessage={translateGraphQLError(error)}
        error={!!error}
        loadingMessage={translations.common.loading}
        noOptionsMessage={translations.common.noOptions}
        isDisabled={disabled}
        isMulti={isMulti}
        active={active}
        onChange={this.handleChange}
        {...rest}
      />
    );
  }
}


InputSelect.propTypes = {
  translations: object.isRequired,
  error: object,
  disabled: bool,
  isMulti: bool,
  active: bool,
  translateGraphQLError: func.isRequired,
  onChange: func,
  filterLabelOnly: bool,
};

InputSelect.defaultProps = {
  error: undefined,
  disabled: false,
  isMulti: false,
  active: false,
  filterLabelOnly: false,
  onChange: () => {},
};


export default pipe(
  withErrors,
  withTranslations(QUERY_INPUT_SELECT_TRANSLATIONS),
)(InputSelect);
