import styled from 'styled-components';


const StyledTransportationDetail = styled.div`
  .transportationDetail--form {
    margin: -${(p) => p.theme.common.padding} -${(p) => p.theme.common.padding} ${(p) => p.theme.common.padding} -${(p) => p.theme.common.padding};
  }
`;


export default StyledTransportationDetail;
