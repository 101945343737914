import React, { Component, } from 'react';
import {
  shape, string, func, object, number,
} from 'prop-types';
import { withApollo, } from 'react-apollo';

import { MUTATION_DELETE_TRANSPORTATION, } from '../../gql/mutations';
import { pipe, } from '../../../../logic/utils';
import { RESERVATION_TYPES, } from '../../../../globals';
import { withNotifications, } from '../../../../logic/notifications/withNotifications';
import {
  changeFormValidations,
  changeAndValidateInput,
  validateAndMergeWholeForm,
  changeMultipleValues,
  mergeValidationObjectIntoForm,
} from '../../../../logic/form/common';
import { getReservationTimeBlocks, getReservationTypesOptions, parseTransportationMutationVariables, } from '../utils';
import {
  initTransportationForm,
  initProductForm,
  customValidationReservationFormForEdit,
} from '../forms/structure';
import { QUERY_ALL_COMPANIES, } from '../../gql/queries';
import CompanyCreate from '../../../Directory/components/CompanyCreate/CompanyCreate';
import TransportationEditView from './TransportationEditView';
import ModalDelete from '../../../../components/modal/ModalDelete';
import { getDateOnlyString, } from '../../../../logic/date';


class TransportationEditLogic extends Component {
  constructor(props) {
    super(props);

    const {
      translations,
      data: {
        reservation,
        company,
        driver,
        passenger,
        truck,
        trailer,
        id,
        extra,
        products,
      },
    } = props;


    this.state = {
      detailForm: {
        ...initTransportationForm,
        values: {
          ...initTransportationForm.values,
          ...reservation,
          resDate: reservation.resDate ? new Date(reservation.resDate) : null,
          resTimeFrom: reservation.resTimeFrom ? new Date(reservation.resTimeFrom) : null,
          resTimeTo: reservation.resTimeTo ? new Date(reservation.resTimeTo) : null,
          note: reservation.note ? reservation.note : '',
          ...company,
          ...driver,
          ...passenger,
          ...truck,
          ...trailer,
          ...extra,
          id,
          languageId: driver.languageId ? {
            id: driver.languageId,
            name: driver.languageName,
          } : null,
          compId: company.compId ? { id: company.compId, name: company.compName, } : null,
          multipleOrderExpiration: extra.multipleOrderExpiration
            ? new Date(extra.multipleOrderExpiration)
            : null,
        },
      },
      productForms: (Array.isArray(products) ? products : []).map((product) => ({
        ...initProductForm,
        values: {
          ...initProductForm.values,
          ...product,
        },
      })),
      options: {
        reservationTypes: getReservationTypesOptions(translations),
      },
      reservationTimeBlocks: [],
      reservationTimeDuration: null,
      isLoadingReservationTimeBlocks: false,
      modalNewCompany: {
        isOpen: false,
        presetValues: null,
      },
      modalDelete: {
        isOpen: false,
      },
    };
  }

  componentDidMount() {
    const { detailForm, } = this.state;
    const { client, } = this.props;

    if (detailForm.values.resTimeFrom && detailForm.values.resTransportationType && detailForm.values.resReservationType && detailForm.values.resReservationType.id !== RESERVATION_TYPES.DAY) {
      this.handleTimeBlocks(client, detailForm.values.resTransportationType.id, detailForm.values.resTimeFrom);
    }
  }

  /**
   * Form - onChange
   */
  handleChangeForm = (name, value) => {
    const { detailForm, reservationTimeDuration, } = this.state;
    const { client, } = this.props;

    let newDetailForm;
    let reloadReservationTimeBlocks = false;

    switch (name) {
      // Transportation Type - reset Platform
      case 'resTransportationType': {
        newDetailForm = changeMultipleValues(detailForm, [
          {
            name,
            value,
          },
          {
            name: 'resPlatform',
            value: null,
          },
        ]);
        this.setState({ detailForm: newDetailForm, });
        reloadReservationTimeBlocks = true;
        break;
      }

      // Reservation Type - change validation
      case 'resReservationType': {
        const resTypeDay = value && value.id === RESERVATION_TYPES.DAY;
        const resTypeInterval = value && (
          value.id === RESERVATION_TYPES.INTERVAL
          || value.id === RESERVATION_TYPES.INTERVAL_PLATFORM
        );
        const resTypeIntervalPlatform = value && value.id === RESERVATION_TYPES.INTERVAL_PLATFORM;

        const newForm = changeFormValidations(
          detailForm,
          [
            {
              name: 'resPlatform',
              validation: { required: resTypeIntervalPlatform, },
            },
            {
              name: 'resDate',
              validation: { required: resTypeDay, },
            },
            {
              name: 'resTimeFrom',
              validation: { required: resTypeInterval, },
            },
            {
              name: 'resTimeTo',
              validation: { required: resTypeInterval, },
            },
          ],
        );

        newDetailForm = changeAndValidateInput(newForm, name, value);
        this.setState({ detailForm: newDetailForm, });
        reloadReservationTimeBlocks = true;
        break;
      }

      case 'resTimeFrom': {
        newDetailForm = changeMultipleValues(detailForm, [
          {
            name,
            value,
          },
          {
            name: 'resTimeTo',
            value: new Date(value.getTime() + reservationTimeDuration),
          },
        ]);

        this.setState({ detailForm: newDetailForm, });

        if (!detailForm.values.resTimeFrom || getDateOnlyString(value) !== getDateOnlyString(detailForm.values.resTimeFrom)) {
          reloadReservationTimeBlocks = true;
        }
        break;
      }

      // Default
      default: {
        newDetailForm = changeAndValidateInput(detailForm, name, value);
        this.setState({ detailForm: newDetailForm, });
        break;
      }
    }

    if (reloadReservationTimeBlocks && newDetailForm && newDetailForm.values.resTimeFrom && newDetailForm.values.resTransportationType && newDetailForm.values.resReservationType && newDetailForm.values.resReservationType.id !== RESERVATION_TYPES.DAY) {
      this.handleTimeBlocks(client, newDetailForm.values.resTransportationType.id, newDetailForm.values.resTimeFrom);
    }
  }

  handleTimeBlocks = async (client, resTransportationTypeId, resTimeFrom) => {
    this.setState({
      isLoadingReservationTimeBlocks: true,
    }, async () => {
      const timeBlockInfo = await getReservationTimeBlocks(client, resTransportationTypeId, getDateOnlyString(resTimeFrom));
      this.setState({
        reservationTimeDuration: timeBlockInfo.duration,
        reservationTimeBlocks: timeBlockInfo.timeBlocks.map((tb) => ({ id: tb.time, name: tb.value, })),
        isLoadingReservationTimeBlocks: false,
      }, () => {
        const currentTimeFrom = resTimeFrom.getTime();
        if (!timeBlockInfo.timeBlocks.map((tb) => tb.time).includes(currentTimeFrom)) {
          this.handleChangeForm('resTimeFrom', new Date(timeBlockInfo.timeBlocks[0].time));
        }
      });
    });
  }


  /**
   * Fill form values
   */
  handleFillFormValues = (neValues) => {
    const { detailForm, } = this.state;

    const newDetailForm = changeMultipleValues(detailForm, neValues);
    this.setState({ detailForm: newDetailForm, });
  }


  /**
   * Modal New Company - Close
   */
  closeModalAddNewCompany = () => {
    this.setState((prevState) => ({
      modalNewCompany: {
        ...prevState.modalNewCompany,
        isOpen: false,
        presetValues: null,
      },
    }));
  }

  /**
   * Modal New Company - Open
   */
  handleAddNewCompany = () => {
    const { detailForm: { values, }, } = this.state;

    const parsedCompanyData = {
      name: values.compName,
      identificationNumber: values.compIdentificationNumber,
      vatNumber: values.compVatNumber,
      street: values.compStreet,
      city: values.compCity,
      zip: values.compZip,
      state: values.compState,
      info: values.compInfo,
      contactPerson: values.compContactPerson,
      email: values.compEmail,
      phoneNumber: values.compPhoneNumber,
    };

    this.setState((prevState) => ({
      modalNewCompany: {
        ...prevState.modalNewCompany,
        isOpen: true,
        presetValues: parsedCompanyData,
      },
    }));
  }


  /**
   * Modal New Company - Company Added
   */
  handleAddNewCompanyCompleted = () => {
    const { client, } = this.props;

    client.query({
      query: QUERY_ALL_COMPANIES,
      fetchPolicy: 'network-only',
    });
  }


  /**
   * onEdit
   */
  handleEdit = (editMutation) => {
    const { detailForm, detailForm: { values, }, productForms, } = this.state;
    const newDetailForm = validateAndMergeWholeForm(
      detailForm,
      customValidationReservationFormForEdit,
    );

    if (!newDetailForm.isValid) {
      this.setState({
        detailForm: newDetailForm,
      });
    } else {
      const parsedValues = parseTransportationMutationVariables(
        { ...values, products: productForms.map((pf) => pf.values), },
      );
      editMutation({
        variables: {
          ...parsedValues,
          id: values.id,
          // TODO I don't like this - probably make two queries - monitoring and edit
          isMonitoring: false,
        },
      });
    }
  }


  /**
   * onEdit - Completed
   */
  handleEditComplete = (responseData) => {
    const {
      translations,
      onToggle,
      addNotification,
      onEdited,
    } = this.props;

    onToggle();
    addNotification({
      status: 'success',
      title: translations.common.updated,
    });

    if (onEdited) onEdited(responseData);
  }


  /**
   * onEdit - Error
   */
  handleEditError = (mutationError) => {
    try {
      const { detailForm, } = this.state;
      const { graphQLErrors, } = mutationError;

      if (graphQLErrors && graphQLErrors.length > 0) {
        const { message, extensions, } = graphQLErrors[0];

        switch (message) {
          case 'UNPROCESSABLE_ENTITY': {
            if (extensions.exception.data) {
              this.setState({
                detailForm: mergeValidationObjectIntoForm(detailForm, extensions.exception.data),
              });
            }
            break;
          }

          default: {
            break;
          }
        }
      }
    } catch {
      // continue regardless of error
    }
  }


  /**
   * onDelete
   */
  handleToggleDeleteModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      modalDelete: {
        ...prevState.modalDelete,
        isOpen: !prevState.modalDelete.isOpen,
      },
    }));
  }


  /**
   * onDelete - Completed
   */
  handleDeleteCompleted = (responseData) => {
    const {
      onDeleted,
      onToggle,
    } = this.props;

    onToggle();
    if (onDeleted) onDeleted(responseData);
  }


  render() {
    const {
      detailForm,
      productForms,
      options,
      modalNewCompany,
      modalDelete,
      reservationTimeBlocks,
      reservationTimeDuration,
      isLoadingReservationTimeBlocks,
    } = this.state;
    const {
      data, languageId, translations, onToggle, resources,
    } = this.props;


    return (
      <>
        {modalNewCompany.isOpen && (
          <CompanyCreate
            // data
            modalData={{ isOpen: true, }}
            presetValues={modalNewCompany.presetValues}
            // methods
            onToggle={this.closeModalAddNewCompany}
            onCreated={this.handleAddNewCompanyCompleted}
          />
        )}

        {resources.delete_transportation && modalDelete.isOpen && (
          <ModalDelete
            // data
            title={translations.transportation.titleDeleteTransportation}
            text={translations.transportation.textDeleteTransportation}
            mutation={MUTATION_DELETE_TRANSPORTATION}
            variables={{ id: detailForm.values.id, }}
            // methods
            onToggle={this.handleToggleDeleteModal}
            onCompleted={this.handleDeleteCompleted}
          />
        )}

        <TransportationEditView
          // data
          data={data}
          detailForm={detailForm}
          productForms={productForms}
          options={options}
          languageId={languageId}
          translations={translations}
          resources={resources}
          reservationTimeBlocks={reservationTimeBlocks}
          reservationTimeDuration={reservationTimeDuration}
          isLoadingReservationTimeBlocks={isLoadingReservationTimeBlocks}
          // methods
          onToggle={onToggle}
          onChangeForm={this.handleChangeForm}
          onEdit={this.handleEdit}
          onEditComplete={this.handleEditComplete}
          onEditError={this.handleEditError}
          onFillFormValues={this.handleFillFormValues}
          onAddNewCompany={this.handleAddNewCompany}
          onDelete={this.handleToggleDeleteModal}
        />

      </>
    );
  }
}


TransportationEditLogic.propTypes = {
  // data
  data: shape({
    id: string.isRequired,
    reservation: shape({
      resNo: string.isRequired,
      resTransportationType: shape({
        id: string.isRequired,
        name: string.isRequired,
      }).isRequired,
      resReservationType: shape({
        id: string.isRequired,
        name: string.isRequired,
      }).isRequired,
      resPlatform: shape({
        id: string.isRequired,
        name: string.isRequired,
      }),
      resTimeFrom: string,
      resTimeTo: string,
      resDate: string,
      note: string,
    }).isRequired,
    company: shape({
      compName: string.isRequired,
      compIdentificationNumber: string.isRequired,
      compVatNumber: string.isRequired,
      compStreet: string.isRequired,
      compCity: string.isRequired,
      compZip: string.isRequired,
      compState: string.isRequired,
      compContactPerson: string.isRequired,
      compEmail: string.isRequired,
      compPhoneNumber: string.isRequired,
    }).isRequired,
    driver: shape({
      driverName: string.isRequired,
      driverPhoneNumber: string.isRequired,
      languageId: number.isRequired,
    }).isRequired,
    truck: shape({
      truckRegistrationNumber: string.isRequired,
    }).isRequired,
    trailer: shape({
      trailerRegistrationNumber: string.isRequired,
    }).isRequired,
    extra: object.isRequired,
  }).isRequired,
  translations: object.isRequired,
  languageId: string.isRequired,
  client: object.isRequired,
  resources: object.isRequired,
  // methods
  onToggle: func.isRequired,
  addNotification: func.isRequired,
  onDeleted: func,
  onEdited: func,
};

TransportationEditLogic.defaultProps = {
  onDeleted: undefined,
  onEdited: undefined,
};


export default pipe(
  withNotifications,
  withApollo,
)(TransportationEditLogic);
