import React from 'react';
import { shape, number, object, } from 'prop-types';

import StatsGridCard from './StatsGridCard';
import StatsNumberCard from './StatsNumberCard';


const TodayPlanCard = ({ todayPlanStats, translations, }) => (
  <StatsGridCard>
    <header>
      <span>{translations.dashboard.stats.todayPlanTitle}</span>
    </header>
    <div className="stats--today-plan-card--body">
      <StatsNumberCard
        num={todayPlanStats.planned}
        title={translations.dashboard.stats.planned}
        color="grey"
      />
      <StatsNumberCard
        num={todayPlanStats.remaining}
        title={translations.dashboard.stats.remaining}
        color="orange"
      />
      <StatsNumberCard
        num={todayPlanStats.cleared}
        title={translations.dashboard.stats.cleared}
        color="green"
      />
    </div>
  </StatsGridCard>
);

TodayPlanCard.propTypes = {
  todayPlanStats: shape({
    planned: number.isRequired,
    remaining: number.isRequired,
    cleared: number.isRequired,
  }).isRequired,
  translations: object.isRequired,
};

export default TodayPlanCard;
