import styled from 'styled-components';


const ValueLink = styled.a`
  margin-left: 0.5rem;
  font-weight: 500;
  color: ${(p) => p.theme.black};
  
  &:visited {
    color: ${(p) => p.theme.black};
  }
  
  &:hover {
    color: ${(p) => p.theme.grey.t800};
  }
`;


export default ValueLink;
