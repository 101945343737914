import React, { Component, } from 'react';
import { func, } from 'prop-types';
import { Query, } from 'react-apollo';

import { APP_LANGUAGE_ID, } from '../../../Main/gql/queries';
import { QUERY_NOTIFICATIONS, } from '../../gql/queries';
import { SUBSCRIPTION_NOTIFICATIONS_ADD, SUBSCRIPTION_NOTIFICATIONS_REMOVE, } from '../../gql/subscriptions';


class SubscribeComponent extends Component {
  static propTypes = {
    subscribeToAddNotification: func.isRequired,
    subscribeToRemoveNotification: func.isRequired,
  }


  constructor(props) {
    super(props);

    this.unsubscribeAddNotification = null;
    this.unsubscribeRemoveNotification = null;
  }


  componentDidMount() {
    const { subscribeToAddNotification, subscribeToRemoveNotification, } = this.props;

    this.unsubscribeAddNotification = subscribeToAddNotification();
    this.unsubscribeRemoveNotification = subscribeToRemoveNotification();
  }


  componentWillUnmount() {
    if (this.unsubscribeAddNotification !== null) {
      this.unsubscribeAddNotification();
      this.unsubscribeAddNotification = null;
    }
    if (this.unsubscribeRemoveNotification !== null) {
      this.unsubscribeRemoveNotification();
      this.unsubscribeRemoveNotification = null;
    }
  }


  render() {
    return null;
  }
}


const SubscriberNotifications = () => (
  <Query query={APP_LANGUAGE_ID}>
    {({ data: { languageId, }, }) => (
      <Query
        query={QUERY_NOTIFICATIONS}
        variables={{ languageId, }}
      >
        {(monitoringData) => (
          <SubscribeComponent
            subscribeToAddNotification={() => monitoringData.subscribeToMore({
              document: SUBSCRIPTION_NOTIFICATIONS_ADD,
              variables: { languageId, },
              updateQuery: (prev, { subscriptionData, }) => {
                if (!subscriptionData.data) return null;
                const newNotification = subscriptionData.data.addNotification;
                return {
                  ...prev,
                  fetchNotifications: [
                    newNotification,
                    ...prev.fetchNotifications,
                  ],
                };
              },
            })}
            subscribeToRemoveNotification={() => monitoringData.subscribeToMore({
              document: SUBSCRIPTION_NOTIFICATIONS_REMOVE,
              updateQuery: (prev, { subscriptionData, }) => {
                if (!subscriptionData.data) return null;
                const removeId = subscriptionData.data.removeNotification;
                return {
                  ...prev,
                  fetchNotifications: prev.fetchNotifications.filter(
                    (item) => item.id !== removeId
                  ),
                };
              },
            })}
          />
        )}
      </Query>
    )}
  </Query>
);


export default SubscriberNotifications;
