import React from 'react';
import { Query, } from 'react-apollo';
import { object, shape, string, } from 'prop-types';

import { QUERY_LICENSE_DETAIL, QUERY_LICENSE_TRANSLATION, } from './gql/queries';
import { LicenseValidityStatus, } from './utils/licenseValidityStatus';
import { differenceInDays, parseDateToHuman, } from '../../logic/date';
import { withTranslations, } from '../../logic/translations/withTranslations';
import StyledSidebarLicenseInfo from './styles/StyledSidebarLicenseInfo';
import WarningIcon from '../../styles/icons/Warning';


const Info = ({ license, translations, }) => (
  <div className="info">
    <p>
      {`${translations.licensing.licenseTo}: ${parseDateToHuman(new Date(license.validTo), false)}`}
    </p>
    <p>
      {`(${translations.licensing.remainingDays}: ${differenceInDays(new Date(license.validTo), new Date())})`}
    </p>
  </div>
);

Info.propTypes = {
  license: shape({
    validTo: string.isRequired,
  }).isRequired,
  translations: object.isRequired,
};

const Warning = ({ license, translations, }) => (
  <div className="warning">
    <div className="warning-header">
      <WarningIcon className="icon" />
      <div>
        <p>
          {translations.licensing.licenseExpiresSoon}
        </p>
        <p>
          {`(${translations.licensing.remainingDays}: ${differenceInDays(new Date(license.validTo), new Date())})`}
        </p>
      </div>
    </div>
    <p>
      {`${translations.licensing.contactMsg}:`}
    </p>
    <p>
      <a href={`mailto:${translations.licensing.contactEmail}`}>{translations.licensing.contactEmail}</a>
    </p>
    <p>
      <a href={`tel:${translations.licensing.contactPhone}`}>{translations.licensing.contactPhone}</a>
    </p>
  </div>
);

Warning.propTypes = {
  license: shape({
    validTo: string.isRequired,
  }).isRequired,
  translations: object.isRequired,
};

const SidebarLicenseInfo = ({ translations, }) => (
  <Query query={QUERY_LICENSE_DETAIL}>
    {({ data, }) => (
      (data && data.getLicenseDetail) ? (
        <StyledSidebarLicenseInfo>
          {data.getLicenseDetail.validityStatus === LicenseValidityStatus.warning
            ? <Warning license={data.getLicenseDetail} translations={translations} />
            : <Info license={data.getLicenseDetail} translations={translations} />
          }
        </StyledSidebarLicenseInfo>
      ) : null
    )}
  </Query>
);

SidebarLicenseInfo.propTypes = {
  translations: object.isRequired,
};


export default withTranslations(QUERY_LICENSE_TRANSLATION)(SidebarLicenseInfo);
