import React from 'react';
import {
  bool, func, object, string,
} from 'prop-types';

import Modal from '../../atoms/Modal/Modal';
import Row from '../../atoms/Row/Row';
import Col from '../../atoms/Col/Col';
import Button from '../../atoms/Button/Button';
import { withTranslations, } from '../../logic/translations/withTranslations';
import { TRANSLATIONS_YES_NO_MODAL, } from './translations';


const ConfirmModal = ({
  // data
  isOpen,
  title,
  text,
  cancelTextBtn,
  confirmTextBtn,
  translations,
  // methods
  onToggle,
}) => (
  <Modal
    isOpen={isOpen}
    onClose={() => { onToggle(false); }}
    title={title}
    size="SM"
  >
    <Row formGroup>
      <Col>
        {text}
      </Col>
    </Row>
    <Row>
      <Col XS={12} SM={12}>
        <Button
          color="error"
          onClick={() => { onToggle(false); }}
          shape="outline"
        >
          {cancelTextBtn || translations.common.no}
        </Button>
      </Col>

      <Col XS={12} SM={12} textAlign="right">
        <Button
          color="success"
          onClick={() => { onToggle(true); }}
          shape="outline"
        >
          {confirmTextBtn || translations.common.yes}
        </Button>
      </Col>
    </Row>
  </Modal>
);


ConfirmModal.propTypes = {
  // data
  isOpen: bool.isRequired,
  title: string.isRequired,
  text: string.isRequired,
  cancelTextBtn: string,
  confirmTextBtn: string,
  translations: object.isRequired,
  // methods
  onToggle: func.isRequired,
};


ConfirmModal.defaultProps = {
  cancelTextBtn: null,
  confirmTextBtn: null,
};


export default withTranslations(TRANSLATIONS_YES_NO_MODAL)(ConfirmModal);
