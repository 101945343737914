import React, { Fragment, } from 'react';
import { func, object, } from 'prop-types';

import ModalBody from '../../../../atoms/Modal/ModalBody';
import ModalHeader from '../../../../atoms/Modal/ModalHeader';
import ModalLoadingPart from '../../../../components/modal/ModalLoadingPart';


const LoadingView = ({
  // data
  translations,
  // methods
  onToggle,
}) => (
  <Fragment>
    <ModalHeader onClose={onToggle}>
      {translations.stand.actionWizardTitle}
    </ModalHeader>
    <ModalBody>
      <ModalLoadingPart
        onBack={onToggle}
        btnBack={translations.common.back}
      />
    </ModalBody>
  </Fragment>
);


LoadingView.propTypes = {
  translations: object.isRequired,
  onToggle: func.isRequired,
};


export default LoadingView;
