import React from 'react';
import styled from 'styled-components';

import Row from '../../../atoms/Row/Row';
import Col from '../../../atoms/Col/Col';

import Error from '../../../styles/icons/Error';


const StyledLoading = styled.div`
  background-image: url("../../../../../../images/login.jpg");
  background-repeat: no-repeat;
  background-size: cover;

  .errorPage--view {
    min-height: 100vh;
    background-color: rgba(0,0,0,0.65);
  }

  .errorPage--panel {
    padding: ${(p) => p.theme.common.padding};
    background: ${(p) => p.theme.white};
    border-radius: ${(p) => p.theme.input.borderRadius}; 
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.06);

    display: flex;
    align-items: center;
  }

  .errorPage--panel-icon {
    font-size: 2.5rem;
    color: ${(p) => p.theme.error.t700};
  }

  .errorPage--panel-info {
    margin-left: ${(p) => p.theme.common.padding};
  }

  .errorPage--panel-error {
    font-size: 1.5rem;
    font-weight: bold;
    color: ${(p) => p.theme.error.t700};
  }

  .errorPage--panel-text {

  }
`;


const LoadingPage = () => (
  <StyledLoading>
    <Row
      className="errorPage--view"
      alignItems="center"
      justifyContent="center"
    >
      <Col XS={20} SM={10} MD={10} LG={8} HD={6}>
        <div className="errorPage--panel">

          <div className="errorPage--panel-icon">
            <Error />
          </div>

          <div className="errorPage--panel-info">
            <div className="errorPage--panel-error">
              Error
            </div>
            <div className="errorPage--panel-text">
              Contact support
            </div>
          </div>

        </div>
      </Col>
    </Row>
  </StyledLoading>
);


export default LoadingPage;
