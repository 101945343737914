import React, { Component, Fragment, } from 'react';
import {
  func, object, shape, string,
} from 'prop-types';

import { MUTATION_DELETE_TRANSPORTATION, } from '../../gql/mutations';
import ActionsView from './ActionsView';
import ModalDelete from '../../../../components/modal/ModalDelete';


class Actions extends Component {
  state = {
    modalDelete: {
      isOpen: false,
    },
  }


  handleToggleDeleteModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      modalDelete: {
        ...prevState.modalDelete,
        isOpen: !prevState.modalDelete.isOpen,
      },
    }));
  }


  handleOnCompletedRemove = () => {
    const { onToggle, onRemoveCompleted, } = this.props;

    onToggle();
    if (onRemoveCompleted) onRemoveCompleted();
  }


  render() {
    const {
      modalDelete,
    } = this.state;
    const {
      detail,
      translations,
      resources,
    } = this.props;

    return (
      <Fragment>

        {resources.delete_transportation && modalDelete.isOpen && (
          <ModalDelete
            // data
            title={translations.transportation.titleDeleteTransportation}
            text={translations.transportation.textDeleteTransportation}
            mutation={MUTATION_DELETE_TRANSPORTATION}
            variables={{ id: detail.id, }}
            // methods
            onToggle={this.handleToggleDeleteModal}
            onCompleted={this.handleOnCompletedRemove}
          />
        )}

        {resources.delete_transportation && (<ActionsView
          translations={translations}
          resources={resources}
          onDelete={this.handleToggleDeleteModal}
        />)}

      </Fragment>
    );
  }
}


Actions.propTypes = {
  detail: shape({
    id: string.isRequired,
  }).isRequired,
  onToggle: func.isRequired,
  translations: object.isRequired,
  resources: object.isRequired,
  onRemoveCompleted: func,
};

Actions.defaultProps = {
  onRemoveCompleted: undefined,
};


export default Actions;
