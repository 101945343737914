import React, { Component, } from 'react';
import { object, string, } from 'prop-types';

import { QUERY_SCHEDULE_VIEW_TRANSLATIONS, } from '../gql/translations';
import { withTranslations, } from '../../../logic/translations/withTranslations';
import TabAll from '../components/Schedule/TabAll/TabAll';
import TabTimelinePlatforms from '../components/Schedule/TabTimelinePlatforms/TabTimelinePlatforms';
import TabTimelineTransportationTypes from '../components/Schedule/TabTimelineTransportationTypes/TabTimelineTransportationTypes';
import TabTableDay from '../components/Schedule/TabTableDay/TabTableDay';


export const SCHEDULE_TABS = {
  ALL: 'all',
  TIMELINE_PLATFORMS: 'timelinePlatforms',
  TIMELINE_TRANSPORTATION_TYPES: 'timelineTransportationTypes',
  TABLE_DAY: 'tableDay',
};


class SchedulePage extends Component {
  state = {
    activeTab: SCHEDULE_TABS.ALL,
  };


  /**
   * onChangeTab
   */
  handleChangePage = (page) => {
    this.setState({
      activeTab: page,
    });
  }


  render() {
    const {
      activeTab,
    } = this.state;
    const {
      resources,
      translations,
      languageId,
    } = this.props;

    return (
      <>
        {activeTab === SCHEDULE_TABS.ALL && (
          <TabAll
            // data
            resources={resources}
            languageId={languageId}
            translations={translations}
            // methods
            onChangePage={this.handleChangePage}
          />
        )}
        {activeTab === SCHEDULE_TABS.TIMELINE_PLATFORMS && (
          <TabTimelinePlatforms
            // data
            resources={resources}
            languageId={languageId}
            translations={translations}
            // methods
            onChangePage={this.handleChangePage}
          />
        )}
        {activeTab === SCHEDULE_TABS.TIMELINE_TRANSPORTATION_TYPES && (
          <TabTimelineTransportationTypes
            // data
            resources={resources}
            languageId={languageId}
            translations={translations}
            // methods
            onChangePage={this.handleChangePage}
          />
        )}
        {activeTab === SCHEDULE_TABS.TABLE_DAY && (
          <TabTableDay
            // data
            resources={resources}
            languageId={languageId}
            translations={translations}
            // methods
            onChangePage={this.handleChangePage}
          />
        )}
      </>
    );
  }
}


SchedulePage.propTypes = {
  resources: object.isRequired,
  translations: object.isRequired,
  languageId: string.isRequired,
};


export default withTranslations(QUERY_SCHEDULE_VIEW_TRANSLATIONS)(SchedulePage);
