import styled from 'styled-components';

import { getColorBtn, getColorBtnOutline, getColorBtnTransparent, } from './utils';


/**
 * Button Icon
 *  - color: 'grey'
 *  - outline: false
 *  - size: 'md'
 *  - round: false
 *  - transparent: false
 *  - thin: false
 *  - grow: false
 */


const getButtonStyle = ({ outline, transparent, ...rest }) => {
  if (transparent) return getColorBtnTransparent(rest);
  if (outline) return getColorBtnOutline(rest);
  return getColorBtn(rest);
};


const getSizeBtnIcon = ({ size, }) => {
  const sizes = {
    xs: `
      font-size: 0.6rem;
    `,
    sm: `
      font-size: 0.75rem;
    `,
    md: `
      font-size: 0.875rem;
    `,
    lg: `
      font-size: 1rem;
    `,
  };

  if (Object.prototype.hasOwnProperty.call(sizes, size)) {
    return sizes[size];
  }
  return sizes.md;
};

const getThin = ({ thin, }) => {
  if (thin) {
    return `
      height: 1.8em;
      width: 1.8em;
    `;
  }

  return `
    height: 2.5em;
    width: 2.5em;
  `;
};


const ButtonIcon = styled.button`
  margin: 0;
  padding: 0;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  border-radius: ${(p) => p.theme.input.borderRadius}; 
  border-width: 1px;
  border-style: solid;
  border-color: transparent;

  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  ${getThin}

  text-transform: none;
  outline: 0;
  outline-offset: 0;
  user-select: none;
  cursor: pointer;


  ${(p) => p.round && `
    border-radius: 50%;
  `}
  ${getSizeBtnIcon}
  ${getButtonStyle}

  &:disabled {
    cursor: default;
    color: ${(p) => p.theme.white};
    background: ${(p) => p.theme.grey.t400};
    border-color: transparent;
  }

  ${(p) => p.grow && `
    padding: 0 0.75rem;
    width: unset;
  `}
`;


export default ButtonIcon;
