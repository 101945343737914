// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
import React, { Component, } from 'react';
import styled from 'styled-components';
import { arrayOf, func, object, } from 'prop-types';

import { parseFetchedData, } from '../utils/data';
import ReportSelectBox from './ReportSelectBox';
import Button from '../../../atoms/Button/Button';
import ReportPeriodInput from './ReportPeriodInput';
import TransportationTypeSelectBox from './TransportationTypeSelectBox';
import ReportParametersMenu from './ReportParametersMenu';
import { client, } from '../../../logic/graphql/apollo';
import { QUERY_REPORT_DATA, } from '../gql/queries';
import { jsonToQuery, } from '../../../logic/url';
import { getAccessToken, } from '../../../logic/localStorage/auth';


const StyledControlPanel = styled.div`
  display: flex;
  flex-direction: row;

  padding: 0.25rem;
  
  background: ${(p) => p.theme.white};
  border-radius: ${(p) => p.theme.input.borderRadius};
  
  .control-panel-item {
    flex: 1 0 0;
    padding: 0.25rem;
    height: 2rem;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    border-radius: ${(p) => p.theme.input.borderRadius};
    color: ${(p) => p.theme.grey.t900};
  }

  .control-panel-item.disabled {
    color: ${(p) => p.theme.grey.t200};
    cursor: default;
  }
  
  .control-panel-item.opened {
    background: ${(p) => p.theme.grey.t200};
  }

  .control-panel-item:not(:first-child) {
    border-left: ${(p) => p.theme.grey.t200} solid 2px;
  }
  
  .control-panel-icon {
    fill: currentColor;
    height: 1.5rem;
    width: 1.5rem;
    margin: 0 1rem 0 0.5rem;
  }
  
  .export-btn {
    margin-left: 1rem;
  }
  
  .menu-pop-up {
    position: absolute;
    top: calc(100% + 2px);
    left: 0;
    display: none;
    width: 100%;
    box-sizing: border-box;
    z-index: 1;
    cursor: default;

    padding: 0.5rem;

    border-radius: ${(p) => p.theme.input.borderRadius};
    background: ${(p) => p.theme.white};
    box-shadow: 0 0 5px ${(p) => p.theme.grey.t500};
  }
  
  .menu-pop-up.opened {
    display: unset;
  }
  
  .menu-label {
    font-size: 0.75rem;
    color: ${(p) => p.theme.grey.t700};
  }
  
  // REPORT SELECT BOX + TRANSPORTATION TYPE SELECT BOX
  
  .report-select-box-item, .transportation-type-select-box-item {
    padding: 0.25rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    box-sizing: content-box;
  }
  
  .no-options {
    cursor: default;
  }
  
  .report-select-box-item:not(:first-child) , .transportation-type-select-box-item:not(:first-child) {
    border-top: ${(p) => p.theme.grey.t200} solid 2px;
  }

  .report-select-box-item label, .transportation-type-select-box-item label {
    height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
  }

  .report-select-box-item input, .transportation-type-select-box-item input {
    border: 0;
    margin: 0;
    width: 1rem;
    height: 2rem;
    cursor: pointer;
  }
  
  // REPORT PERIOD INPUT
  
  .report-period-pop-up {
    min-width: 240px;
  }
  
  .period-type-selector {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .period-type-selector-item {
    flex: 1 0 0;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 1.5rem;
    border-radius: 0.75rem;
  }
  
  .period-type-selector-item:hover {
    background: ${(p) => p.theme.grey.t50};
  }
  
  .period-type-selector-item.selected {
    background: ${(p) => p.theme.grey.t200};
  }
  
  // REPORT PARAMETERS MENU
  
  .report-parameter-input {
    margin: 0.5rem;
  }
`;

class ControlPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedReport: props.reports[0] || undefined, // Report
      selectedPeriods: [], // ReportPeriodWithDateObjs[]
      selectedTransportationTypeIds: [], // number[]
      parameters: props.reports[0]
        ? props.reports[0].parameters.map(({ defaultValue, ...rest }) => ({
          ...rest,
          value: defaultValue,
        }))
        : [], // ReportParameterValue[]
    };
  }

  fetchData = () => {
    const { onDataStateChange, onDataFetched, } = this.props;
    const {
      selectedReport, selectedPeriods, selectedTransportationTypeIds, parameters,
    } = this.state;

    if (
      !selectedReport || selectedPeriods.length === 0 || selectedTransportationTypeIds.length === 0
    ) {
      return;
    }

    onDataStateChange('loading');

    const filter = {
      reportId: parseInt(selectedReport.id, 10),
      transportationTypeIds: [ ...selectedTransportationTypeIds, ],
      filledParameters: parameters.map(({ id, value, }) => ({ id, value, })),
      intervals: selectedPeriods.map(({ from, to, }) => ({ from, to, })),
    };

    client.query({ query: QUERY_REPORT_DATA, variables: { filter, }, fetchPolicy: 'network-only', })
      .then(({ data, }) => {
        if (data) {
          onDataStateChange('ready');
          onDataFetched(parseFetchedData(data.getReportData.data), data.getReportData.layout);
        } else {
          onDataStateChange('error');
        }
      })
      .catch((err) => {
        console.error(err);
        onDataStateChange('error');
      });
  }

  exportData = () => {
    const {
      selectedReport, selectedPeriods, selectedTransportationTypeIds, parameters,
    } = this.state;

    if (
      !selectedReport || selectedPeriods.length === 0 || selectedTransportationTypeIds.length === 0
    ) {
      return;
    }

    const filter = {
      reportId: parseInt(selectedReport.id, 10),
      transportationTypeIds: [ ...selectedTransportationTypeIds, ],
      filledParameters: parameters.map(({ id, value, }) => ({ id, value, })),
      intervals: selectedPeriods.map(({ from, to, }) => ({ from, to, })),
      token: getAccessToken(),
    };

    const encodedFilter = jsonToQuery(filter);

    window.location.href = `${process.env.REACT_APP_REST}/reporting/export-to-excel?${encodedFilter}`;
  }

  handleSelectReport = (report) => {
    this.setState({
      selectedReport: report,
      parameters: report.parameters.map(({ defaultValue, ...rest }) => ({
        ...rest,
        value: defaultValue,
      })),
    });
  }

  handleSelectPeriods = (periods) => {
    this.setState({
      selectedPeriods: periods,
    });
  }

  handleSelectTransportationTypes = (types) => {
    this.setState({
      selectedTransportationTypeIds: types,
    });
  }

  handleParametersChange = (parameters) => {
    this.setState({
      parameters,
    });
  }

  render() {
    const { reports, transportationTypes, } = this.props;
    const {
      selectedReport, selectedPeriods,
      selectedTransportationTypeIds, parameters,
    } = this.state;

    return (
      <StyledControlPanel>
        <ReportSelectBox
          options={reports}
          selected={selectedReport}
          onSelect={this.handleSelectReport}
        />
        <ReportPeriodInput
          disabled={selectedReport === undefined}
          selected={selectedPeriods}
          onChange={this.handleSelectPeriods}
        />
        <TransportationTypeSelectBox
          options={transportationTypes.map((tt) => ({ ...tt, id: parseInt(tt.id, 10), }))}
          availableTransportationTypeIds={
            selectedReport ? selectedReport.transportationTypeIds : []
          }
          selected={selectedTransportationTypeIds}
          onSelect={this.handleSelectTransportationTypes}
        />
        <ReportParametersMenu
          parameters={parameters}
          onChange={this.handleParametersChange}
        />
        <Button
          color="success"
          onClick={this.fetchData}
          disabled={
            !selectedReport || selectedPeriods.length === 0
            || selectedTransportationTypeIds.length === 0
          }
        >
          Použít
        </Button>
        <Button
          className="export-btn"
          color="success"
          onClick={this.exportData}
          disabled={
            !selectedReport || selectedPeriods.length === 0
            || selectedTransportationTypeIds.length === 0
          }
        >
          Export
        </Button>
      </StyledControlPanel>
    );
  }
}

ControlPanel.propTypes = {
  reports: arrayOf(object.isRequired).isRequired,
  transportationTypes: arrayOf(object.isRequired).isRequired,
  onDataStateChange: func.isRequired,
  onDataFetched: func.isRequired,
};


export default ControlPanel;
