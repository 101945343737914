import React from 'react';
import {
  func, object, arrayOf,
} from 'prop-types';

import ProductForm from './ProductForm';


const LoadingForm = ({
  productForms,
  translations,
  resources,
  onChange,
}) => {
  return <>
    {productForms.map((productForm, index) => (
      <ProductForm
        key={index}
        translations={translations}
        resources={resources}
        onChange={onChange ? (name, value) => onChange(index, name, value) : undefined}
        {...productForm}
      />
    ))}
  </>;
};


LoadingForm.propTypes = {
  translations: object.isRequired,
  productForms: arrayOf(object),
  resources: object.isRequired,
  onChange: func,
};

LoadingForm.defaultProps = {
  onChange: undefined,
};


export default LoadingForm;
