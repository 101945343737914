import React, { Fragment, } from 'react';
import {
  func, object,
} from 'prop-types';

import InputCheckbox from '../../../../atoms/InputCheckbox/InputCheckbox';
import { equipmentFields, } from '../InputTechnicalCheck/utils';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import { sliceIntoChunks, } from '../../../../logic/array';

const TechnicalCheckEquipmentForm = ({
  form,
  translations,
  values,
  onChange,
}) => {
  const chunks = sliceIntoChunks(equipmentFields, 2);
  return (
    <Fragment>
      {chunks.map((chunk, chunkIndex) => (
        <Row key={chunkIndex}>
          {chunk.map((field, fieldIndex) => (
            <Col SM={12} key={fieldIndex}>
              <InputCheckbox
                value={values[field]}
                onChange={(newValue) => onChange(form[field].name, newValue)}
                status={form[field].status}
                text={translations.form.adr.equipment[field]}
              />
            </Col>
          ))}
        </Row>
      ))}

    </Fragment>
  );
};


TechnicalCheckEquipmentForm.propTypes = {
  translations: object.isRequired,
  form: object.isRequired,
  values: object.isRequired,
  onChange: func.isRequired,
};


export default TechnicalCheckEquipmentForm;
