import React from 'react';
import {
  string, func, object,
} from 'prop-types';
import { Query, } from 'react-apollo';

import { QUERY_RESOURCES, } from '../../gql/queries';
import PartLoading from '../../../../components/Parts/PartLoading';
import PartError from '../../../../components/Parts/PartError';
import RoleGroup from './RoleGroup';

import StyledRoleTree from './styles/StyledRoleTree';


const RoleCreateView = ({
  // data
  background,
  translations,
  languageId,
  resourcesValue,
  // methods
  onChangeResourceValue,
}) => (
  <Query
    query={QUERY_RESOURCES}
    variables={{
      languageId,
    }}
  >
    {({ loading, error, data, }) => {
      if (loading) return <PartLoading />;
      if (error || !data.fetchResources) return <PartError error={error} />;
      return (
        <StyledRoleTree background={background}>
          {data.fetchResources.map((group) => (
            <RoleGroup
              // data
              key={group.id}
              group={group}
              translations={translations}
              languageId={languageId}
              resourcesValue={resourcesValue}
              // methods
              onChangeResourceValue={onChangeResourceValue}
            />
          ))}
        </StyledRoleTree>
      );
    }}
  </Query>
);


RoleCreateView.propTypes = {
  background: string,
  languageId: string.isRequired,
  translations: object.isRequired,
  resourcesValue: object.isRequired,
  onChangeResourceValue: func.isRequired,
};

RoleCreateView.defaultProps = {
  background: undefined,
};


export default RoleCreateView;
