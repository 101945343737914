import React, { Component, } from 'react';
import { object, string, } from 'prop-types';

import { withTranslations, } from '../../../logic/translations/withTranslations';
import { QUERY_USERS_TRANSLATIONS, } from '../gql/translations';
import Breadcrumb from '../../../atoms/Breadcrumb/Breadcrumb';
import BreadcrumbItem from '../../../atoms/Breadcrumb/BreadcrumbItem';
import PageFullScreen from '../../../atoms/PageView/PageFullScreen';
import UsersView from '../components/Users/UsersView';


class UsersPage extends Component {
  state = {
    createModal: {
      isOpen: false,
    },
    updateModal: {
      isOpen: false,
      id: null,
    },
    deleteModal: {
      isOpen: false,
      id: null,
    },
    lockModal: {
      isOpen: false,
      id: null,
      active: null,
    },
  }


  handleToggleCreate = () => {
    this.setState((prevState) => ({
      createModal: {
        ...prevState.createModal,
        isOpen: !prevState.createModal.isOpen,
      },
    }));
  }


  handleToggleUpdate = (id = null) => {
    this.setState((prevState) => ({
      updateModal: {
        ...prevState.updateModal,
        isOpen: !prevState.updateModal.isOpen,
        id: typeof id === 'number' ? id : null,
      },
    }));
  }


  handleToggleDelete = (id = null) => {
    this.setState((prevState) => ({
      deleteModal: {
        ...prevState.deleteModal,
        isOpen: !prevState.deleteModal.isOpen,
        id: typeof id === 'number' ? id : null,
      },
    }));
  }

  handleToggleLock = (id = null, active = null) => {
    this.setState((prevState) => ({
      lockModal: {
        ...prevState.lockModal,
        isOpen: !prevState.lockModal.isOpen,
        id: typeof id === 'number' ? id : null,
        active,
      },
    }));
  }


  render() {
    const {
      createModal, updateModal, deleteModal, lockModal,
    } = this.state;
    const { resources, translations, languageId, } = this.props;

    return (
      <PageFullScreen>

        <Breadcrumb>
          <BreadcrumbItem to="/">{translations.common.home}</BreadcrumbItem>
          <BreadcrumbItem>{translations.common.rsUsers}</BreadcrumbItem>
        </Breadcrumb>

        <UsersView
          translations={translations}
          resources={resources}
          languageId={languageId}

          onToggleCreate={this.handleToggleCreate}
          onToggleUpdate={this.handleToggleUpdate}
          onToggleDelete={this.handleToggleDelete}
          onToggleLock={this.handleToggleLock}
          createModal={createModal}
          updateModal={updateModal}
          deleteModal={deleteModal}
          lockModal={lockModal}
        />

      </PageFullScreen>
    );
  }
}


UsersPage.propTypes = {
  resources: object,
  translations: object.isRequired,
  languageId: string.isRequired,
};

UsersPage.defaultProps = {
  resources: {},
};


export default withTranslations(QUERY_USERS_TRANSLATIONS)(UsersPage);
