// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
import React, { useEffect, useState, } from 'react';

import ButtonIcon from '../../../atoms/Button/ButtonIcon';
import Bin from '../../../styles/icons/Bin';
import Plus from '../../../styles/icons/Plus';
import Card from '../../../atoms/Card/Card';


const ReportUserGroupsListItem = (props) => (
  <li
    className={`report-user-groups-list-item ${props.selected ? 'selected' : ''}`}
    onClick={() => props.onSelect(props.reportUserGroup.id)}
  >
    <span>
      {props.reportUserGroup.name}
    </span>
    <ButtonIcon
      className="report-user-groups-list-btn"
      color="error"
      onClick={() => props.onDelete(props.reportUserGroup.id)}
    >
      <Bin />
    </ButtonIcon>
  </li>
);

const ReportUserGroupsList = (props) => {
  const [ filter, setFilter, ] = useState('');
  const [ filteredReportUserGroups, setFilteredReportUserGroups, ] = useState([]);

  useEffect(() => {
    if (filter === '') {
      setFilteredReportUserGroups(props.reportUserGroups);
    } else {
      setFilteredReportUserGroups(props.reportUserGroups.filter((reportUserGroup) => reportUserGroup.name.includes(filter)));
    }
  }, [ props.reportUserGroups, filter, ]);

  return (
    <Card className="report-user-groups-list">
      <div className="report-user-groups-list-header">
        <span className="report-user-groups-list-title">{props.title}</span>
        <ButtonIcon
          className="report-user-groups-list-btn"
          color="success"
          onClick={props.onCreate}
        >
          <Plus />
        </ButtonIcon>
      </div>


      <ul className="report-user-groups-list-items">
        {filteredReportUserGroups.map((reportUserGroup) => (
          <ReportUserGroupsListItem
            key={reportUserGroup.id}
            reportUserGroup={reportUserGroup}
            selected={props.selectedId === reportUserGroup.id}
            onSelect={props.onSelect}
            onDelete={props.onDelete}
          />
        ))}
      </ul>
    </Card>
  );
};


export default ReportUserGroupsList;
