import React, { Component, } from 'react';
import {
  func, shape, bool, string, arrayOf, number, object,
} from 'prop-types';
import { Query, } from 'react-apollo';

import { QUERY_STAND_WIZARD_GENERIC_FORM, } from '../../gql/queries';
import { parseFromApi, parseToApi, } from '../../../../logic/form/genericForm';
import {
  initForm,
  changeAndValidateInput,
  validateAndMergeWholeForm,
  // mergeValidationObjectIntoForm,
} from '../../../../logic/form/common';
import {
  parseFromApiTransportationEditForm,
  parseToApiTransportationEditForm,
} from '../../../../logic/form/transportationEditForm';
import ErrorBoundary from '../../../../components/ErrorBoundary/ErrorBoundary';
import ModalLoadingPart from '../../../../components/modal/ModalLoadingPart';
import ModalErrorPart from '../../../../components/modal/ModalErrorPart';
import WizardView from './WizardView';
import ModalSaveChanges from './ModalSaveChanges';
import TabActionView from './TabActionView';
import InputTechnicalCheck from '../../../Transportation/components/InputTechnicalCheck/InputTechnicalCheck';
import OutputTechnicalCheck from '../../../Transportation/components/OutputTechnicalCheck/OutputTechnicalCheck';


export const WIZARD_ACTION_TYPES = {
  CHANGE_TAB: 'changeTab',
  CLOSE_WIZARD: 'closeWizard',
};


class TabAction extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formChanged: false,
      transportationEditInfo: {
        form: null,
        tree: null,
        values: null,
      },
      genForm: {
        form: null,
        tree: null,
        values: null,
      },
      modalSaveChanges: {
        isOpen: false,
        actionType: WIZARD_ACTION_TYPES.CLOSE_WIZARD,
        changeTabData: {
          from: null,
          to: null,
          completed: false,
        },
      },
    };
  }


  handleCloseModalSaveChanges = () => {
    this.setState((prevState) => ({
      modalSaveChanges: {
        isOpen: false,
        ...prevState.modalSaveChanges,
      },
    }));
  }


  /* *************** */
  /*      Forms      */
  /* *************** */

  /**
   * Fetch Data - Completed
   */
  handleFetchDataCompleted = (fetchedData) => {
    const { genericForm, genericFormValues, } = fetchedData.fetchGenericFormConfiguration;

    this.setState({
      transportationEditInfo: {
        ...initForm,
        ...parseFromApiTransportationEditForm(
          fetchedData.fetchTransportationEditForm.transportationEditForm,
          fetchedData.fetchTransportationEditForm.transportationEditFormValues,
        ),
      },
      genForm: {
        ...initForm,
        ...parseFromApi(genericForm, genericFormValues),
      },
    });
  }


  /**
   * Change Transportation Edit Info
   */
  handleChangeTransportationEditInfo = (name, value) => {
    const { transportationEditInfo, } = this.state;
    const newState = changeAndValidateInput(transportationEditInfo, name, value);

    this.setState({
      formChanged: true,
      transportationEditInfo: newState,
    });
  }


  /**
   * Change Generic Form
   */
  handleChangeGenForm = (name, value) => {
    const { genForm, } = this.state;
    const newState = changeAndValidateInput(genForm, name, value);

    this.setState({
      formChanged: true,
      genForm: newState,
    });
  }


  /* *************** */
  /*  Click Actions  */
  /* *************** */

  /**
   * Btn Next
   */
  handleBtnNext = (mutation) => {
    const { formChanged, } = this.state;
    const {
      tabData: { completed, },
      activeTab,
      completedUntilTab,
      onChangeTab,
    } = this.props;

    // not changed and competed -> go to last possible tab
    if (!formChanged && completed) {
      onChangeTab({
        from: activeTab,
        to: completedUntilTab,
        completed,
      });

    // save tab changes, complete action and go to last possible tab
    } else {
      this.handleSaveForm(mutation, false);
    }
  }

  /**
   * Btn Next Completed
   */
  handleBtnNextCompleted = () => {
    const {
      activeTab,
      completedUntilTab,
      onChangeTab,
    } = this.props;
    onChangeTab({
      from: activeTab,
      to: completedUntilTab,
      completed: true,
    });
  }

  /**
   * Change Tab
   */
  handleChangeTab = (to) => {
    const { formChanged, } = this.state;
    const {
      tabData: { completed, },
      activeTab,
      completedUntilTab,
      onChangeTab,
    } = this.props;

    // changed -> modal save changes
    if (formChanged) {
      this.setState((prevState) => ({
        modalSaveChanges: {
          ...prevState.modalSaveChanges,
          isOpen: true,
          actionType: WIZARD_ACTION_TYPES.CHANGE_TAB,
          changeTabData: {
            from: activeTab,
            to: completedUntilTab,
            completed,
          },
        },
      }));

    // change tab
    } else {
      onChangeTab({
        from: activeTab,
        to,
        completed,
      });
    }
  }

  /**
   * Close Modal
   */
  handleCloseModal = () => {
    const { formChanged, } = this.state;
    const {
      onToggle,
    } = this.props;

    // changed -> modal save changes
    if (formChanged) {
      this.setState((prevState) => ({
        modalSaveChanges: {
          ...prevState.modalSaveChanges,
          isOpen: true,
          actionType: WIZARD_ACTION_TYPES.CLOSE_WIZARD,
        },
      }));

    // change tab
    } else {
      onToggle();
    }
  }


  /* *************** */
  /*     Actions     */
  /* *************** */

  /**
   * Save Form
   */
  handleSaveForm = (mutationSave, inProgress) => {
    const {
      transportationEditInfo,
      genForm,
    } = this.state;
    const {
      tabData: {
        genericFormId,
        actionId,
      },
      standId,
      transportationId,
    } = this.props;

    const newTransportationEditInfo = validateAndMergeWholeForm(transportationEditInfo);
    const newGenForm = validateAndMergeWholeForm(genForm);

    if (!newTransportationEditInfo.isValid || !newGenForm.isValid) {
      this.setState((prevState) => ({
        transportationEditInfo: newTransportationEditInfo,
        genForm: newGenForm,
        modalSaveChanges: {
          ...prevState.modalSaveChanges,
          isOpen: false,
        },
      }));
    } else {
      const variables = {
        values: parseToApi(genForm),
        transportationEditFormValues: parseToApiTransportationEditForm(transportationEditInfo),
        transportationId,
        genericFormId,
        actionId,
        inProgress,
        standId,
      };

      mutationSave({ variables, });
    }
  }

  handleSaveFormError = (mutationError) => {
    try {
      // const { transportationEditInfo, genForm, modalSaveChanges, } = this.state;

      const { graphQLErrors, } = mutationError;

      if (graphQLErrors && graphQLErrors.length > 0) {
        const { message, extensions, } = graphQLErrors[0];
        switch (message) {
          case 'UNPROCESSABLE_ENTITY': {
            if (extensions.exception.data) {
              // TODO
              // transportation edit form - need to parse errors first :-(
              // mergeValidationObjectIntoForm(genForm, extensions.exception.data.???);
              this.setState((prevState) => ({
                modalSaveChanges: {
                  ...prevState.modalSaveChanges,
                  isOpen: false,
                },
              }));
            }
            break;
          }

          default: {
            break;
          }
        }
      }
    } catch (err) {
      // continue regardless of error
    }
  }


  render() {
    const {
      transportationEditInfo,
      genForm,
      modalSaveChanges,
    } = this.state;

    const {
      // data
      tabs,
      activeTab,
      completedUntilTab,
      tabData: {
        genericFormId,
        actionId,
        completed,
      },
      standId,
      transportationId,
      translations,
      languageId,
      isLoading,
      actionType,
      // methods
      onToggle,
      onChangeTab,
    } = this.props;

    if (actionType === 'inputTechnicalCheck' && isLoading) {
      return (
        <InputTechnicalCheck
          transportationId={transportationId}
          translations={translations}
          languageId={languageId}
          onToggle={onToggle}
        />
      );
    }

    if (actionType === 'outputTechnicalCheck' && isLoading) {
      return (
        <OutputTechnicalCheck
          transportationId={transportationId}
          translations={translations}
          languageId={languageId}
          onToggle={onToggle}
        />
      );
    }

    return (
      <WizardView
        // data
        tabs={tabs}
        activeTab={activeTab}
        completedUntilTab={completedUntilTab}
        translations={translations}
        // methods
        onClose={this.handleCloseModal}
        onChangeTab={this.handleChangeTab}
      >
        {modalSaveChanges.isOpen && (
          <ModalSaveChanges
            // data
            modalData={modalSaveChanges}
            translations={translations}
            // methods
            onToggle={this.handleCloseModalSaveChanges}
            onChangeTab={onChangeTab}
            onCloseWizard={onToggle}
            onCompleteAction={this.handleSaveForm}
            onCompleteActionError={this.handleSaveFormError}
          />
        )}
        <Query
          query={QUERY_STAND_WIZARD_GENERIC_FORM}
          variables={{
            languageId,
            genericFormId,
            actionId,
            transportationId,
            standId,
          }}
          onCompleted={this.handleFetchDataCompleted}
          fetchPolicy="no-cache"
        >
          {({ loading, data, error, }) => {
            if (loading) {
              return (
                <ModalLoadingPart
                  onBack={onToggle}
                  btnBack={translations.common.back}
                />
              );
            }

            if (error || !data || !data.fetchGenericFormConfiguration) {
              return (
                <ModalErrorPart
                  onBack={onToggle}
                  error={error}
                  btnBack={translations.common.back}
                />
              );
            }

            return (
              <ErrorBoundary
                renderError={() => (
                  <ModalErrorPart
                    onBack={onToggle}
                    error={error}
                    btnBack={translations.common.back}
                  />
                )}
              >
                <TabActionView
                  // data
                  completed={completed}
                  transportationEditInfo={transportationEditInfo}
                  genForm={genForm}
                  languageId={languageId}
                  translations={translations}
                  // methods
                  onChangeGenForm={this.handleChangeGenForm}
                  onChangeTransportationEditInfo={this.handleChangeTransportationEditInfo}
                  onBtnNext={this.handleBtnNext}
                  onBtnNextCompleted={this.handleBtnNextCompleted}
                  onBtnNextError={this.handleSaveFormError}
                />
              </ErrorBoundary>
            );
          }}
        </Query>
      </WizardView>
    );
  }
}


TabAction.propTypes = {
  // data
  tabs: arrayOf(object).isRequired,
  tabData: shape({
    genericFormId: string,
    completed: bool.isRequired,
    actionId: string.isRequired,
  }).isRequired,
  activeTab: number.isRequired,
  completedUntilTab: number.isRequired,
  standId: string.isRequired,
  transportationId: string.isRequired,
  languageId: string.isRequired,
  translations: object.isRequired,
  actionType: string,
  // methods
  onToggle: func.isRequired,
  onChangeTab: func.isRequired,
};

TabAction.defaultProps = {
  actionType: null,
};


export default TabAction;
