import React from 'react';

import { withTranslations, } from '../../logic/translations/withTranslations';
import { TRANSLATION_GEN_FORM, } from './queries';
import RenderValue from './ReadOnly/RenderValue';


const GenericFormReadOnly = (props) => (
  <RenderValue
    {...props}
  />
);


export default withTranslations(TRANSLATION_GEN_FORM)(GenericFormReadOnly);
