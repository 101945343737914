import React, { Fragment, } from 'react';
import {
  func, string, bool, object,
} from 'prop-types';
import { Mutation, } from 'react-apollo';

import { MUTATION_COMPLETE_ACTION, } from '../../gql/mutations';
import ButtonLoader from '../../../../atoms/Button/ButtonLoader';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import GenericForm from '../../../../components/GenericForm/GenericForm';
import PartInfo from '../../../../components/Parts/PartInfo';
import FormError from '../../../../components/Form/FormError';


const TabActionView = ({
  // data
  completed,
  transportationEditInfo,
  genForm,
  languageId,
  translations,
  // methods
  onChangeTransportationEditInfo,
  onChangeGenForm,
  onBtnNext,
  onBtnNextCompleted,
  onBtnNextError,
}) => {
  const isFormValid = transportationEditInfo.isValid && genForm.isValid;
  const hasGenForm = genForm.tree && genForm.tree.formRoot && genForm.tree.formRoot.childs.length > 0;

  return (
    <Fragment>
      {(!transportationEditInfo.tree && !hasGenForm) && (
        <PartInfo>
          {translations.stand.textTabActionNoForm}
        </PartInfo>
      )}

      {transportationEditInfo.tree && (
        <GenericForm
          languageId={languageId}
          genForm={transportationEditInfo}
          nodeId="formRoot"
          onChange={onChangeTransportationEditInfo}
        />
      )}

      {genForm.tree && (
        <GenericForm
          languageId={languageId}
          genForm={genForm}
          nodeId="formRoot"
          onChange={onChangeGenForm}
        />
      )}

      <Mutation
        mutation={MUTATION_COMPLETE_ACTION}
        onCompleted={onBtnNextCompleted}
        onError={onBtnNextError}
      >
        {(mutateNextStep, { error, loading, }) => (
          <Fragment>
            <FormError
              isValid={isFormValid}
              error={error}
            />
            <Row>
              <Col textAlign="right">
                <ButtonLoader
                  type="button"
                  color="success"
                  disabled={!isFormValid}
                  isLoading={loading}
                  onClick={() => onBtnNext(mutateNextStep)}
                >
                  {completed ? translations.common.update : translations.common.confirm}
                </ButtonLoader>
              </Col>
            </Row>
          </Fragment>
        )}
      </Mutation>
    </Fragment>
  );
};


TabActionView.propTypes = {
  // data
  completed: bool.isRequired,
  transportationEditInfo: object.isRequired,
  genForm: object.isRequired,
  languageId: string.isRequired,
  translations: object.isRequired,
  // methods
  onChangeGenForm: func.isRequired,
  onChangeTransportationEditInfo: func.isRequired,
  onBtnNext: func.isRequired,
  onBtnNextCompleted: func.isRequired,
  onBtnNextError: func.isRequired,
};


export default TabActionView;
