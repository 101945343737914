import React from 'react';
import {
  func, string, object,
} from 'prop-types';

import Card from '../../../../atoms/Card/Card';
import NotificationsTable from './NotificationsTable';


const Notifications = ({
  // data
  languageId,
  translations,
  // methods
  toggleTransportationDetail,
  toggleRemove,
}) => (
  <Card>

    <h2>{translations.monitoring.titleNotifications}</h2>

    <NotificationsTable
      // data
      languageId={languageId}
      translations={translations}
      // methods
      toggleTransportationDetail={toggleTransportationDetail}
      toggleRemove={toggleRemove}
    />

  </Card>
);


Notifications.propTypes = {
  languageId: string.isRequired,
  translations: object.isRequired,
  toggleTransportationDetail: func.isRequired,
  toggleRemove: func.isRequired,
};


export default Notifications;
