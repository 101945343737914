import React from 'react';
import { string, shape, } from 'prop-types';

import FormRowReadOnly from '../../Form/FormRowReadOnly';
import ValueLink from '../../../atoms/Values/ValueLink';


const GFDownload = ({
  input, link, linkLabel,
}) => (
  <FormRowReadOnly label={input.translation.label}>
    <ValueLink
      href={link}
      target="_blank"
      rel="noopener noreferrer"
    >
      {linkLabel}
    </ValueLink>
  </FormRowReadOnly>
);


GFDownload.propTypes = {
  input: shape({
    translation: shape({
      label: string,
    }).isRequired,
  }).isRequired,
  link: string.isRequired,
  linkLabel: string.isRequired,
};


export default GFDownload;
