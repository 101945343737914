import React, { Component, } from 'react';
import { arrayOf, func, object, } from 'prop-types';

import Settings from '../../../styles/icons/app/Settings';
import Input from '../../../atoms/Input/Input';
import InputSelect from '../../../atoms/InputSelect/InputSelect';


class ParameterNumberInput extends Component {
  handleChange = (event) => {
    const { parameter, onChange, } = this.props;
    const { value, } = event.target;

    const num = parseInt(value, 10);

    if (value.length === 0 || num >= 0) {
      onChange({
        ...parameter,
        value,
      });
    }
  }

  render() {
    const { parameter, } = this.props;

    return (
      <Input
        type="number"
        value={parameter.value}
        onChange={this.handleChange}
        min={0}
      />
    );
  }
}

ParameterNumberInput.propTypes = {
  parameter: object.isRequired,
  onChange: func.isRequired,
};

class ParameterSelectInput extends Component {
  handleChange = (option) => {
    const { onChange, parameter, } = this.props;
    if (option) {
      onChange({
        ...parameter,
        value: option.id,
      });
    }
  };

  render() {
    const { parameter, } = this.props;

    return (
      <InputSelect
        // TODO - fix parameters.options on BE
        value={(parameter.options ?? []).find((o) => o.id === parameter.value) ?? null}
        onChange={this.handleChange}
        options={parameter.options}
        getOptionLabel={(p) => p.name}
        getOptionValue={(p) => p.id}
        status="FORM_DEFAULT"
      />
    );
  }
}

ParameterSelectInput.propTypes = {
  parameter: object.isRequired,
  onChange: func.isRequired,
};

const ParameterInput = ({ parameter, onChange, }) => (
  <div className="report-parameter-input">
    <span className="menu-label">{parameter.label}</span>
    {parameter.type === 'integer' && <ParameterNumberInput parameter={parameter} onChange={onChange} />}
    {parameter.type === 'select' && <ParameterSelectInput parameter={parameter} onChange={onChange} />}
  </div>
);

ParameterInput.propTypes = {
  parameter: object.isRequired,
  onChange: func.isRequired,
};

const WRAPPER_ID = 'report-parameters-menu';

class ReportParametersMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: false, // boolean
    };
  }

  componentDidMount() {
    window.addEventListener('click', this.toggleListener);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.toggleListener);
  }

  toggleListener = (ev) => {
    const { parameters, } = this.props;

    if (parameters.length === 0) {
      this.setState({ opened: false, });
    } else if (ev.target) {
      const wrapperElem = document.getElementById(WRAPPER_ID);

      if (wrapperElem === ev.target) {
        this.setState((state) => ({ opened: !state.opened, }));
      } else if (!wrapperElem?.contains(ev.target)) {
        this.setState({ opened: false, });
      }
    }
  }

  handleChange = (parameter) => {
    const { parameters, onChange, } = this.props;
    onChange(parameters.map((p) => ((p.id === parameter.id) ? parameter : p)));
  }

  render() {
    const { parameters, } = this.props;
    const { opened, } = this.state;

    return (
      <div
        id={WRAPPER_ID}
        className={`
        control-panel-item
        report-parameters-menu
        ${parameters.length === 0 ? 'disabled' : ''}
        ${opened ? 'opened' : ''}
      `}
      >
        <Settings
          className="control-panel-icon"
        />
        Proměnné reportu
        <div
          className={`menu-pop-up ${(opened && parameters.length > 0) ? 'opened' : ''}`}
        >
          {parameters.map((p) => (
            <ParameterInput
              key={p.id}
              parameter={p}
              onChange={this.handleChange}
            />
          ))}
        </div>
      </div>
    );
  }
}

ReportParametersMenu.propTypes = {
  parameters: arrayOf(object.isRequired).isRequired,
  onChange: func.isRequired,
};

export default ReportParametersMenu;
