import React, { Component, } from 'react';
import {
  oneOfType, arrayOf, node, func, object,
} from 'prop-types';

import Sidebar from '../Sidebar/Sidebar';
import Header from '../Header/Header';
import Container from '../../atoms/Container/Container';

import StyledAppTemplate from './styles/StyledAppTemplate';


class AppTemplate extends Component {
  constructor(props) {
    super(props);

    this.unsubscribeUser = null;

    this.state = {
      sidebar: {
        isOpen: true,
      },
    };
  }


  componentDidMount() {
    const { subscribeToUser, } = this.props;

    this.unsubscribeUser = subscribeToUser();
  }


  componentWillUnmount = () => {
    if (this.unsubscribeUser !== null) {
      this.unsubscribeUser();
      this.unsubscribeUser = null;
    }
  }


  /**
   * Sidebar - toggle
   */
  handleToggleSidebar = () => {
    this.setState((prevState) => ({
      sidebar: {
        ...prevState.sidebar,
        isOpen: !prevState.sidebar.isOpen,
      },
    }));
  }


  render() {
    const { sidebar, } = this.state;
    const {
      resources, children,
    } = this.props;

    return (
      <StyledAppTemplate isOpenSidebar={sidebar.isOpen}>

        <div className="app-temp--sidebar">
          <Sidebar
            resources={resources}
          />
        </div>

        <div className="app-temp--header">
          <Header
            onToggleSidebar={this.handleToggleSidebar}
            isOpenSidebar={sidebar.isOpen}
          />
        </div>

        <div className="app-temp--page">
          <Container fluid>
            {children}
          </Container>
        </div>

      </StyledAppTemplate>
    );
  }
}


AppTemplate.propTypes = {
  resources: object,
  children: oneOfType([
    arrayOf(node),
    node,
  ]).isRequired,
  subscribeToUser: func.isRequired,
};


AppTemplate.defaultProps = {
  resources: {},
};


export default AppTemplate;
