import styled from 'styled-components';
import { bool, } from 'prop-types';


/**
 * Table
 */
const Table = styled.table`
  font-size: 0.875rem;
  display: table;
  border-collapse: separate;
  border-spacing: 0;
  border-color: unset;
  text-align: left;
  word-break: break-word;

  ${(p) => p.fillContent && `
    width: 100%;
  `}

  /**
   * Head
   */
  thead {
    background: ${(p) => p.theme.grey.t50};
    user-select: none;
  }

  .table--sortable {
    cursor: pointer;
  }

  .table--sortable:hover {
    color: ${(p) => p.theme.grey.t700};
  }

  .table--sortable-active {
    color: ${(p) => p.theme.tertiary.t700};
  }

  .table--sortable-active:hover {
    color: ${(p) => p.theme.tertiary.t800};
  }

  th {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }

  thead tr:not(:first-child) th {
    padding-right: 0.33rem;
    padding-left: 0.33rem;
  }

  thead tr:first-child th {
    padding-top: 0.875rem;
    vertical-align: bottom;
  }

  thead tr:last-child th {
    padding-bottom: 0.875rem;

    border-bottom: 1px solid ${(p) => p.theme.grey.t300};
  }

  .table--header-title-wrapper {
    display: flex;
  }

  .table--header-title-text-active {
    color: ${(p) => p.theme.tertiary.t700};
  }

  .table--header-title-text-active:hover {
    color: ${(p) => p.theme.tertiary.t800};
  }

  .table--header-title-icon {
    margin-left: auto;
  }


  /**
   * Body
   */
  tbody {

  }

  tbody tr:nth-child(even) {
    background: ${(p) => p.theme.grey.t50};
  }

  tbody tr:hover {
    background: ${(p) => p.theme.tertiary.t50};
  }

  tbody tr td {
    padding: 0.875rem 0.75rem;
  }

  .table--text-align-right {
    text-align: right;
  }

  .table--noPadding {
    padding-top: 0;
    padding-bottom: 0;
  }


  .table--loading {
    background: ${(p) => p.theme.grey.t200};
    height: 1.5em;
  }


  .table--error {
    display: flex;
    min-height: 5rem;
    align-items: center;
    justify-content: center;
  }

  .table--error-icon {
    width: 3rem;
    font-size: 1.5rem;
    color: ${(p) => p.theme.error.t700};
    display: flex;
  }

  .table--error-text {
    
  }


  .table--noData {
    display: flex;
    min-height: 5rem;
    align-items: center;
    justify-content: center;
  }

  .table--noData-icon {
    width: 3rem;
    font-size: 1.5rem;
    display: flex;
  }

  .table--noData-text {
    
  }
`;


Table.propTypes = {
  fillContent: bool,
};

Table.defaultProps = {
  fillContent: false,
};


export default Table;
