import React from 'react';
import {
  object, shape, string, func, arrayOf,
} from 'prop-types';
import { Query, } from 'react-apollo';

import { QUERY_DD_TRANSPORTATION_TYPES, } from '../../gql/queries';
import FormRowSimple from '../../../../components/Form/FormRowSimple';
import InputSelect from '../../../../atoms/InputSelect/InputSelect';
import InputDateSelect from '../../../../atoms/InputDateSelect/InputDateSelect';
import InputTimeSelect from '../../../../atoms/InputTimeSelect/InputTimeSelect';
import Button from '../../../../atoms/Button/Button';

import StyledTimelineFilter from './styles/StyledTimelineFilter';


const TimelineFilterView = ({
  // data
  translations,
  languageId,
  transportationType,
  from,
  range,
  optionsRange,
  // methods
  onChange,
  onConfirm,
}) => (
  <StyledTimelineFilter>

    <div className="timelineFilter--form">

      <div className="timelineFilter--input timelineFilter--input-select">
        <FormRowSimple
          label={translations.form.transportationType}
          showInfo={false}
        >
          <Query
            query={QUERY_DD_TRANSPORTATION_TYPES}
            variables={{ languageId, }}
          >
            {({ loading, error, data, }) => (
              <InputSelect
                style={{
                  width: '8rem',
                }}
                value={transportationType}
                options={(data && data.fetchAllTransportationTypes)
                  ? data.fetchAllTransportationTypes
                  : []
                }
                onChange={(selected) => onChange('transportationType', selected)}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                isClearable
                isLoading={loading}
                error={error}
                placeholder={translations.transportation.placeholderTransportationType}
              />
            )}
          </Query>
        </FormRowSimple>
      </div>

      <div className="timelineFilter--input">
        <FormRowSimple
          label={translations.common.from}
          showInfo={false}
        >
          <div className="timelineFilter--input-date timeline--input-date-date">
            <InputDateSelect
              value={from}
              onChange={(selected) => onChange('from', selected)}
              clearable={false}
            />
          </div>
          <div className="timelineFilter--input-date">
            <InputTimeSelect
              value={from}
              onChange={(selected) => onChange('from', selected)}
              clearable={false}
            />
          </div>
        </FormRowSimple>
      </div>

      <div className="timelineFilter--input timelineFilter--input-select">
        <FormRowSimple
          label={translations.transportation.labelRange}
          showInfo={false}
        >
          <InputSelect
            value={range}
            options={optionsRange}
            onChange={(selected) => onChange('range', selected)}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            clearable={false}
          />
        </FormRowSimple>
      </div>

    </div>

    <div>
      <Button
        onClick={onConfirm}
        color="tertiary"
      >
        {translations.transportation.btnApplyFilter}
      </Button>
    </div>

  </StyledTimelineFilter>
);


TimelineFilterView.propTypes = {
  // data
  translations: object.isRequired,
  languageId: string.isRequired,
  transportationType: shape({
    id: string.isRequired,
    name: string.isRequired,
  }),
  from: object.isRequired,
  range: shape({
    id: string.isRequired,
    name: string.isRequired,
  }).isRequired,
  optionsRange: arrayOf(shape({
    id: string.isRequired,
    name: string.isRequired,
  })).isRequired,
  // methods
  onChange: func.isRequired,
  onConfirm: func.isRequired,
};

TimelineFilterView.defaultProps = {
  transportationType: null,
};


export default TimelineFilterView;
