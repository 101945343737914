// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
import React from 'react';
import { CartesianGrid, } from 'recharts';

import { GridDefault, } from '../../utils/config';
import { createFieldOrDefault, } from '../../../../logic/object';


/**
 * Do not use it as component <Grid {...config} /> but as a function Grid(config).
 * Custom components does not work in Recharts.
 */
const Grid = (config) => {
  const getField = createFieldOrDefault(config, GridDefault);

  const type = getField('type');

  if (type === 'hidden') {
    return null;
  }

  const dashed = getField('dashed');
  const strokeDasharray = dashed ? `${dashed[0]} ${dashed[1]}` : undefined;

  return (
    <CartesianGrid
      horizontal={type === 'horizontal' || type === 'both'}
      vertical={type === 'vertical' || type === 'both'}
      stroke={getField('color')}
      strokeDasharray={strokeDasharray}
    />
  );
};


export default Grid;
