import styled from 'styled-components';


const DDItem = styled.li`
  padding: 0.5rem 0.75rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  &:hover {
    background: ${(p) => p.theme.primary.t200};
  }


  ${(p) => p.isSelected && `
      background: ${p.theme.primary.t200};
  `}
`;


export default DDItem;
