import styled from 'styled-components';

import { SIDEBAR_WIDTH, } from '../../../globals';


const StyledHeader = styled.div`
  display: flex;
  height: 100%;
  background: ${(p) => p.theme.white};


  /* TITLE */
  .header--title {
    min-width: 235px;
    flex: 0 1 ${SIDEBAR_WIDTH}px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;    
    color: ${(p) => p.theme.white};
    background: ${(p) => p.theme.sidebar.background};
    transition: margin-left 350ms ease-in;
    margin-left: ${(p) => (p.isOpenSidebar ? 0 : -SIDEBAR_WIDTH)}px;
  }

  .header--title-logo {
    font-size: 4.5rem;
  }


  /* CONTENT */
  .header--content {
    flex: 1 0 auto;
    height: 100%;
    box-shadow: 0 5px 18px 0 rgba(0, 0, 0, 0.1);
  }

  .header--content-container {
    height: 100%;
  }

  .header--content--list {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    list-style: none;
    height: 100%;

    padding-left: 1rem;
    padding-right: 1rem;
  }

  .header--content--list > li:not(:last-child) {
    margin-right: 0.125rem;
  }

  .header--content--button:hover, .header--content--button:focus, .header--content--button:active {
    background: ${(p) => p.theme.primary.t700};
    color: ${(p) => p.theme.white};
  }

  .header--content-list--right {
    margin-left: auto;
  }
`;


export default StyledHeader;
