import React, { Component, } from 'react';
import { number, shape, object, } from 'prop-types';


class TopPanel extends Component {
  getTime = (fromHour, i) => {
    const actualHour = fromHour + i;

    return `${actualHour % 24}:00`;
  }


  render() {
    const {
      params: {
        from,
        hours,
      },
    } = this.props;

    const parts = [];
    const startHour = from.getHours();

    for (let i = 0; i < hours; i++) {
      parts.push(
        <div
          key={i}
          className="timeline--top-item"
        >
          {this.getTime(startHour, i)}
        </div>
      );
    }

    return (
      <div className="timeline--top">
        {parts}
      </div>
    );
  }
}


TopPanel.propTypes = {
  params: shape({
    from: object.isRequired,
    hours: number.isRequired,
  }).isRequired,
};


export default TopPanel;
