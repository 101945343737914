import React from 'react';
import {
  arrayOf, number, string, shape, object, func,
} from 'prop-types';


const LeftPanel = ({
  sections,
  getSectionName,
  params: {
    rowHeight,
  },
}) => (
  <div className="timeline--leftPanel">
    <div className="timeline--leftPanel-top" />
    <ul className="timeline--leftPanel-rows">
      {sections.map((item, index) => (
        <li
          key={item.id}
          style={{ height: `${rowHeight * item.rows.length}rem`, }}
        >
          {getSectionName(item.payload, index)}
        </li>
      ))}
    </ul>
  </div>
);


LeftPanel.propTypes = {
  params: shape({
    rowHeight: number.isRequired,
  }).isRequired,
  sections: arrayOf(shape({
    id: string.isRequired,
    payload: object.isRequired,
    rows: arrayOf(object).isRequired,
  })).isRequired,
  getSectionName: func.isRequired,
};


export default LeftPanel;
