import gql from 'graphql-tag';


export const TRANSLATIONS_SAMPLES = gql`
  query Dictionary($languageId: ID) {
    dictionary(languageId: $languageId) {
      dictionary {
        centralApp {
          sample {
            confirmSuccessTitle
            confirmErrorTitle
            confirmModalTitle
            confirmModalText
            noSamples
            thActions
          }
          form {
            no
            date
            truckRegistrationNo
            carrier
            transportationType
          }
          common {
            settings
            delete
            yes
            no
            detail
            home
            samples
          }
        }
      }
    }
  }
`;
