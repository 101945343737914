import React from 'react';

import StyledSvg from '../styles/StyledSvg';


const Settings = ({ ...props }) => (
  <StyledSvg viewBox="0 0 490.175 490.175" {...props}>
    <path d="M221.675,167.838h17.8c2.8,14.2,8.4,27.3,16.1,38.9l-12.6,12.6
      c-4.5,4.5-4.5,11.8,0,16.3l13.7,13.7c4.5,4.5,11.8,4.5,16.3,0l12.6-12.6c11.6,7.8,24.8,13.3,38.9,16.1v17.8
      c0,6.4,5.2,11.5,11.5,11.5h19.4c6.4,0,11.5-5.2,11.5-11.5v-17.8c14.2-2.8,27.3-8.4,38.9-16.1l12.6,12.6c4.5,4.5,11.8,4.5,16.3,0
      l13.7-13.7c4.5-4.5,4.5-11.8,0-16.3l-12.6-12.6c7.8-11.6,13.3-24.8,16.1-38.9h17.8c6.4,0,11.5-5.2,11.5-11.5v-19.3
      c0-6.4-5.2-11.5-11.5-11.5h-17.8c-2.8-14.2-8.4-27.3-16.1-38.9l12.6-12.6c4.5-4.5,4.5-11.8,0-16.3l-13.8-13.7
      c-4.5-4.5-11.8-4.5-16.3,0l-12.6,12.6c-11.6-7.8-24.8-13.3-38.9-16.1v-17.8c0-6.4-5.2-11.5-11.5-11.5h-19.4
      c-6.4,0-11.5,5.2-11.5,11.5v17.8c-14.2,2.8-27.3,8.4-38.9,16.1l-12.5-12.6c-4.5-4.5-11.8-4.5-16.3,0l-13.7,13.7
      c-4.5,4.5-4.5,11.8,0,16.3l12.6,12.6c-7.8,11.6-13.3,24.8-16.1,38.9h-17.8c-6.4,0-11.5,5.2-11.5,11.5v19.4
      C210.175,162.738,215.275,167.838,221.675,167.838z M345.575,97.638c27.1,0,49.1,22,49.1,49.1s-22,49.1-49.1,49.1
      s-49.1-22-49.1-49.1S318.475,97.638,345.575,97.638z"
    />
    <path d="M346.275,380.538c-1.4,3.5-5.4,5.1-8.8,3.7l-22.6-9.4c-4-1.7-8.6-0.3-11,3.3c-10.2,15.3-23.2,28.3-38.5,38.5
      c-3.6,2.4-5,7-3.3,11l9.3,22.6c1.4,3.4-0.2,7.4-3.7,8.8l-24.6,10.3c-3.4,1.4-7.4-0.2-8.8-3.7l-9.4-22.6c-1.7-4-5.9-6.3-10.1-5.4
      c-18,3.6-36.3,3.6-54.4,0c-4.2-0.8-8.5,1.4-10.1,5.4l-9.4,22.6c-1.4,3.4-5.4,5.1-8.8,3.7l-24.6-10.2c-1.7-0.7-3-2-3.7-3.7
      s-0.7-3.5,0-5.2l9.4-22.6c1.7-4,0.3-8.6-3.3-11c-15.3-10.2-28.3-23.2-38.5-38.5c-2.4-3.6-7-5-11-3.3l-22.6,9.4
      c-3.5,1.4-7.4-0.2-8.8-3.7l-10.2-24.6c-1.4-3.4,0.2-7.4,3.7-8.8l22.6-9.4c4-1.7,6.3-5.9,5.4-10.1c-3.6-18-3.6-36.3,0-54.4
      c0.8-4.2-1.4-8.5-5.4-10.1l-22.6-9.4c-1.7-0.7-3-2-3.7-3.7s-0.7-3.5,0-5.2l10.2-24.6c0.7-1.7,2-3,3.7-3.7s3.5-0.7,5.2,0l22.6,9.4
      c4,1.7,8.6,0.3,11-3.3c10.2-15.3,23.2-28.3,38.5-38.5c3.6-2.4,5-7,3.3-11l-9.4-22.6c-0.7-1.7-0.7-3.5,0-5.2s2-3,3.7-3.7l24.6-10.2
      c3.4-1.4,7.4,0.2,8.8,3.7l9.4,22.6c1.9,4.6,7.2,6.8,11.8,4.9c4.6-1.9,6.8-7.2,4.9-11.8l-9.4-22.6c-5.3-12.7-19.8-18.7-32.5-13.5
      l-24.6,10.2c-6.1,2.5-10.9,7.3-13.5,13.5c-2.5,6.1-2.5,12.9,0,19.1l6.6,15.9c-12.6,9.3-23.7,20.4-33.1,33.1l-15.9-6.6
      c-6.1-2.5-12.9-2.5-19.1,0c-6.1,2.5-10.9,7.3-13.5,13.5l-10.2,24.6c-2.5,6.1-2.5,12.9,0,19.1c2.5,6.1,7.3,10.9,13.5,13.5l15.9,6.6
      c-2.4,15.6-2.4,31.2,0,46.8l-15.9,6.6c-12.7,5.3-18.7,19.8-13.5,32.5l10.2,24.3c5.2,12.7,19.8,18.7,32.5,13.5l15.9-6.6
      c9.3,12.6,20.4,23.7,33.1,33.1l-6.6,15.9c-2.5,6.1-2.5,12.9,0,19.1c2.5,6.1,7.3,10.9,13.5,13.5l24.6,10.2
      c12.7,5.2,27.3-0.8,32.5-13.5l6.6-15.9c15.6,2.4,31.2,2.4,46.8,0l6.6,15.9c4,9.6,13.3,15.4,23,15.4c3.2,0,6.4-0.6,9.5-1.9
      l24.6-10.2c12.7-5.3,18.7-19.8,13.5-32.5l-6.6-15.9c12.6-9.3,23.7-20.4,33.1-33.1l15.9,6.6c12.7,5.3,27.3-0.8,32.5-13.5l10.2-24.6
      c2.5-6.1,2.5-12.9,0-19.1c-2.5-6.1-7.3-10.9-13.5-13.5l-22.6-9.4c-4.6-1.9-9.9,0.3-11.8,4.9s0.3,9.9,4.9,11.8l22.6,9.4
      c1.7,0.7,3,2,3.7,3.7s0.7,3.5,0,5.2L346.275,380.538z"
    />
    <path d="M187.075,238.538c5,0,9-4.1,9-9.1s-4.1-9-9.1-9c0,0,0,0-0.1,0c-21.4,0.2-41.4,8.6-56.4,23.8c-15,15.2-23.2,35.4-23,56.7
      c0.2,21.4,8.6,41.4,23.8,56.4c15.1,14.9,35,23,56.1,23c0.2,0,0.4,0,0.6,0c21.4-0.2,41.4-8.6,56.4-23.8c15-15.2,23.2-35.4,23-56.7
      c0-5-4.1-9-9.1-9c0,0,0,0-0.1,0c-5,0-9,4.1-9,9.1c0.1,16.5-6.2,32.1-17.8,43.9s-27.1,18.3-43.6,18.4c-0.2,0-0.3,0-0.5,0
      c-16.3,0-31.8-6.3-43.4-17.8c-11.8-11.6-18.3-27.1-18.4-43.6s6.2-32.1,17.8-43.9C155.075,245.238,170.575,238.638,187.075,238.538
      z"
    />
    <path d="M469.575,176.938c11.3,0,20.6-9.2,20.6-20.6v-19.3c0-11.3-9.2-20.6-20.6-20.6h-10.5c-2.7-10-6.6-19.6-11.8-28.5l7.5-7.5
      c8-8,8-21.1,0-29.1l-13.7-13.7c-3.9-3.9-9.1-6-14.5-6c-5.5,0-10.7,2.1-14.5,6l-7.5,7.5c-9-5.2-18.5-9.2-28.5-11.8v-10.6
      c0-11.3-9.2-20.6-20.6-20.6h-19.4c-11.3,0-20.6,9.2-20.6,20.6v10.6c-10,2.7-19.6,6.6-28.5,11.8l-7.5-7.5c-3.9-3.9-9.1-6-14.5-6
      c-5.5,0-10.7,2.1-14.5,6l-13.7,13.7c-8,8-8,21.1,0,29.1l7.5,7.5c-5.2,9-9.2,18.5-11.8,28.5h-10.6c-11.3,0-20.6,9.2-20.6,20.6v19.4
      c0,11.3,9.2,20.6,20.6,20.6h10.6c2.7,10,6.6,19.6,11.8,28.5l-7.5,7.5c-8,8-8,21.1,0,29.1l13.7,13.7c3.9,3.9,9.1,6,14.5,6
      c5.5,0,10.7-2.1,14.5-6l7.5-7.5c9,5.2,18.5,9.2,28.5,11.8v10.6c0,11.3,9.2,20.6,20.6,20.6h19.4c11.3,0,20.6-9.2,20.6-20.6v-10.7
      c10-2.7,19.6-6.6,28.5-11.8l7.5,7.5c3.9,3.9,9.1,6,14.5,6c5.5,0,10.7-2.1,14.5-6l13.7-13.7c8-8,8-21.1,0-29.1l-7.5-7.5
      c5.2-9,9.2-18.5,11.8-28.5L469.575,176.938L469.575,176.938z M442.975,166.138c-2.5,12.8-7.5,24.8-14.8,35.7
      c-2.4,3.6-1.9,8.4,1.1,11.4l12.6,12.6c1,1,1,2.5,0,3.5l-13.7,13.7c-0.6,0.6-1.3,0.7-1.7,0.7c-0.5,0-1.1-0.1-1.7-0.7l-12.6-12.6
      c-3.1-3.1-7.9-3.5-11.4-1.1c-10.9,7.3-22.9,12.2-35.7,14.8c-4.2,0.8-7.3,4.6-7.3,8.9v17.8c0,1.3-1.1,2.4-2.4,2.4h-19.4
      c-1.3,0-2.4-1.1-2.4-2.4v-17.8c0-4.3-3.1-8-7.3-8.9c-12.8-2.5-24.8-7.5-35.7-14.8c-1.5-1-3.3-1.5-5-1.5c-2.3,0-4.7,0.9-6.4,2.7
      l-12.6,12.6c-0.6,0.6-1.3,0.7-1.7,0.7c-0.5,0-1.1-0.1-1.7-0.7l-13.7-13.7c-1-1-1-2.5,0-3.5l12.6-12.6c3.1-3.1,3.5-7.9,1.1-11.4
      c-7.3-10.9-12.2-22.9-14.8-35.7c-0.8-4.2-4.6-7.3-8.9-7.3h-17.8c-1.3,0-2.4-1.1-2.4-2.4v-19.5c0-1.3,1.1-2.4,2.4-2.4h17.8
      c4.3,0,8-3.1,8.9-7.3c2.5-12.8,7.5-24.8,14.8-35.7c2.4-3.6,1.9-8.4-1.1-11.4l-12.6-12.6c-0.6-0.6-0.7-1.3-0.7-1.7
      c0-0.5,0.1-1.1,0.7-1.7l13.6-13.8c0.6-0.6,1.3-0.7,1.7-0.7c0.5,0,1.1,0.1,1.7,0.7l12.6,12.6c3.1,3.1,7.9,3.5,11.4,1.1
      c10.9-7.3,22.9-12.2,35.7-14.8c4.2-0.8,7.3-4.6,7.3-8.9v-17.7c0-1.3,1.1-2.4,2.4-2.4h19.4c1.3,0,2.4,1.1,2.4,2.4v17.8
      c0,4.3,3.1,8,7.3,8.9c12.8,2.5,24.8,7.5,35.7,14.8c3.6,2.4,8.4,1.9,11.4-1.1l12.6-12.6c0.6-0.6,1.3-0.7,1.7-0.7
      c0.5,0,1.1,0.1,1.7,0.7l13.7,13.7c1,1,1,2.5,0,3.5l-12.6,12.6c-3.1,3.1-3.5,7.9-1.1,11.4c7.3,10.9,12.2,22.9,14.8,35.7
      c0.8,4.2,4.6,7.3,8.9,7.3h17.8c1.3,0,2.4,1.1,2.4,2.4v19.4c0,1.3-1.1,2.4-2.4,2.4h-17.8
      C447.475,158.838,443.775,161.838,442.975,166.138z"
    />
    <path d="M345.675,88.538c-32.1,0-58.1,26.1-58.1,58.1c0,32.1,26.1,58.1,58.1,58.1c32.1,0,58.1-26.1,58.1-58.1
      S377.675,88.538,345.675,88.538z M345.675,186.638c-22.1,0-40-17.9-40-40s17.9-40,40-40s40,17.9,40,40
      S367.675,186.638,345.675,186.638z"
    />
  </StyledSvg>
);

export default Settings;
