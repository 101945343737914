import React from 'react';
import { oneOfType, arrayOf, node, } from 'prop-types';

import StyledPart from './styles/StyledPart';


const PartInfo = ({ children, ...rest }) => (
  <StyledPart {...rest}>
    {children}
  </StyledPart>
);


PartInfo.propTypes = {
  children: oneOfType([
    arrayOf(node),
    node,
  ]).isRequired,
};


export default PartInfo;
