import React, { useState, } from 'react';

import Modal from '../../../atoms/Modal/Modal';
import FormBackground from '../../../components/Form/FormBackground';
import FormRow from '../../../components/Form/FormRow';
import Input from '../../../atoms/Input/Input';
import Row from '../../../atoms/Row/Row';
import Button from '../../../atoms/Button/Button';
import Col from '../../../atoms/Col/Col';
import InputSelect from "../../../atoms/InputSelect/InputSelect";


const ReportNotificationGroupCreateModal = (props) => {
  const [ name, setName, ] = useState('');
  const [ reportId, setReportId ] = useState(props.reports[0].id);

  return (
    <Modal
      title="Zadejte název"
      isOpen
      onClose={props.onClose}
      size="XS"
      disablePadding
    >
      <FormBackground>
        <FormRow
          label="Název"
          input={{ validation: { required: true, }, }}
        >
          <Input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </FormRow>
        <FormRow
          label="Report"
          input={{ validation: { required: true, }, }}
        >
          <InputSelect
            value={{ id: reportId, name: props.reports.find(report => report.id === reportId).name, }}
            options={props.reports}
            onChange={(option) => setReportId(option.id)}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
          />
        </FormRow>
        <Row>
          <Col XS={12} SM={12}>
            <Button
              type="button"
              shape="outline"
              onClick={props.onClose}
            >
              Zpět
            </Button>
          </Col>

          <Col XS={12} SM={12} textAlign="right">
            <Button
              type="button"
              color="success"
              onClick={() => props.onCreate(name, reportId)}
            >
              Vytvořit
            </Button>
          </Col>
        </Row>
      </FormBackground>
    </Modal>
  );
};


export default ReportNotificationGroupCreateModal;
