import styled from 'styled-components';


const StyledInputDateSelect = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;


  .inputDateSelect--inputWrapper {
    
  }


  .inputDateSelect--dropdown {
    position: absolute;
    z-index: 999;
    background: white;
    margin-top: 10px;
    margin-bottom: 10px;

    box-shadow: 0px 0px 30px 0px rgba(20, 20, 20, 0.25);

    ${(p) => (p.verticalPosition && p.verticalPosition === 'top') && `
      bottom: 2rem;
    `}

    ${(p) => (p.horizontalPosition && p.horizontalPosition === 'left') && `
      right: 0;
    `}
  }
`;


export default StyledInputDateSelect;
