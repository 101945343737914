import styled from 'styled-components';


const StyledPart = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  min-height: ${(p) => p.minHeight || '10'}rem;

  .parts-error {
    color: ${(p) => p.theme.error.t700};
  }

  .parts-watermark {
    color: ${(p) => p.theme.grey.t500};
  }
`;


export default StyledPart;
