import React, { Component, } from 'react';
import { func, string, object, } from 'prop-types';
import { Query, } from 'react-apollo';

import { QUERY_ALL_TRUCKS, } from '../../gql/queries';
import FilterStandardView from './FilterStandardView';


class TruckFilter extends Component {
  state = {
    filter: null,
  }


  handleChangeFilter = (value) => {
    this.setState({
      filter: value,
    });
  }


  handleApply = () => {
    const { filter, } = this.state;
    const { onApply, } = this.props;

    if (filter) {
      onApply([
        {
          name: 'truckRegistrationNumber',
          value: filter.registrationNumber,
        },
      ]);
    }
  }


  getOptionLabel = (option) => option.registrationNumber


  render() {
    const {
      filter,
    } = this.state;
    const { placeholder, translations, } = this.props;

    return (
      <Query
        query={QUERY_ALL_TRUCKS}
        fetchPolicy="cache-and-network"
      >
        {(filterData) => (
          <FilterStandardView
            // data
            filter={filter}
            filterData={filterData}
            options={filterData.data ? filterData.data.fetchAllTrucks : []}
            placeholder={placeholder}
            getOptionLabel={this.getOptionLabel}
            translations={translations}
            // methods
            onChange={this.handleChangeFilter}
            onApply={this.handleApply}
          />
        )}
      </Query>
    );
  }
}


TruckFilter.propTypes = {
  onApply: func.isRequired,
  placeholder: string,
  translations: object.isRequired,
};

TruckFilter.defaultProps = {
  placeholder: '',
};


export default TruckFilter;
