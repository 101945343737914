import React from 'react';

import StyledSvg from './styles/StyledSvg';


const List = ({ ...props }) => (
  // eslint-disable-next-line react/prop-types
  <StyledSvg viewBox="3 3 18 18" fill={(props?.fill) ?? 'black'} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 3a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3V6a3 3 0 0 0-3-3H6zm4 5a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2h-6a1
      1 0 0 1-1-1zm0 4a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2h-6a1 1 0 0 1-1-1zm0 4a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2h-6a1 1 0 0
      1-1-1zM7 7a1 1 0 0 0 0 2h.001a1 1 0 0 0 0-2H7zm-1 5a1 1 0 0 1 1-1h.001a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1zm1 3a1 1 0
      1 0 0 2h.001a1 1 0 1 0 0-2H7z"
    />
  </StyledSvg>
);

export default List;
