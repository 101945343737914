import React from 'react';
import {
  arrayOf, oneOfType, node,
} from 'prop-types';


const FlowLineText = ({ children, }) => (
  <div className="flowLine--mid-text">
    {children}
  </div>
);


FlowLineText.propTypes = {
  children: oneOfType([
    arrayOf(node),
    node,
  ]),
};

FlowLineText.defaultProps = {
  children: null,
};


export default FlowLineText;
