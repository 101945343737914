import styled from 'styled-components';


const StyledInputTimeSelect = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;


  .inputTimeSelect--inputWrapper {
    
  }


  .inputTimeSelect--dropdown {
    position: absolute;
    z-index: 999;
    background: white;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;

    box-shadow: 0px 0px 30px 0px rgba(20, 20, 20, 0.25);

    ${(p) => (p.verticalPosition && p.verticalPosition === 'top') && `
      bottom: 2rem;
    `}

    ${(p) => (p.horizontalPosition && p.horizontalPosition === 'left') && `
      right: 0;
    `}
  }


  ul {
    padding: 0;
    margin: 0;
    height: 10rem;
    overflow-y: auto;
  }

  li {
    padding: 0.25rem 0.5rem;
    cursor: pointer;
  }

  li:hover {
    background: ${(p) => p.theme.tertiary.t100};
  }

  .inputTimeSelect--option-selected, .inputTimeSelect--option-selected:hover {
    background: ${(p) => p.theme.tertiary.t700};
    color: ${(p) => p.theme.white};
  }
`;


export default StyledInputTimeSelect;
