import styled from 'styled-components';


const StyledTransportationAdmin = styled.div`
  .transportationAdmin--form {
    margin: 0 -${(p) => p.theme.common.padding} ${(p) => p.theme.common.padding} -${(p) => p.theme.common.padding};
  }
`;


export default StyledTransportationAdmin;
