import styled from 'styled-components';

import { getColorTransportation, getColorBackgroundTransportation, } from './utils';


const StyledTransportation = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid  ${(p) => getColorTransportation(p)};
  box-shadow: 0 2px 2px rgba(0,0,0,.24), 0 0 2px rgba(0,0,0,.12);
  height: 6.5rem;
  margin-bottom: 1rem;
  background: ${(p) => getColorBackgroundTransportation(p)};


  .storekeeperTransportation--panel {
    display: flex;
    align-items: center;
    width: 100%;
    height: 1.75rem;
    min-height: 1.75rem;
    padding: 0 0.5rem;
    background: ${(p) => getColorTransportation(p)};
    color: ${(p) => p.theme.white};
  }

  .storekeeperTransportation--panel-state {
    text-overflow: ellipsis;
    overflow: hidden; 
    white-space: nowrap;
  }

  .storekeeperTransportation--panel-time {
    margin-left: auto;
    font-size: 0.8rem;
  }


  .storekeeperTransportation--main {
    display: flex;
    height: 100%;
    margin-left: 0.5rem;
  }

  .storekeeperTransportation--main-info {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    justify-content: center;
  }

  .storekeeperTransportation--main-info-no {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden; 
    white-space: nowrap;
    font-weight: bold;
  }

  .storekeeperTransportation--main-info-truck {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden; 
    white-space: nowrap;
    font-size: 0.8rem;
  }

  .storekeeperTransportation--main-info-transportationType {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden; 
    white-space: nowrap;
    font-size: 0.8rem;
  }

  .storekeeperTransportation--main-actions {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
  }

  .storekeeperTransportation--main-actions-btn {
    width: 50%;
    height: 50%;
    display: flex;
    padding: 0.1rem;
  }

  .storekeeperTransportation--main-actions-btn-top {
    align-items: flex-end;
  }

  .storekeeperTransportation--main-actions-btn-bottom {
    align-items: flex-start;
  }


  .storekeeperTransportation--main-actions-btn > button {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden; 
    white-space: nowrap;
  }
`;


export default StyledTransportation;
