import React from 'react';
import {
  func, shape, object, bool, string,
} from 'prop-types';
import { Mutation, } from 'react-apollo';

import { MUTATION_SAVE_GENERIC_FORM_TRANSPORTATION, } from '../../../gql/mutations';
import Row from '../../../../../atoms/Row/Row';
import Col from '../../../../../atoms/Col/Col';
import Button from '../../../../../atoms/Button/Button';
import ButtonLoader from '../../../../../atoms/Button/ButtonLoader';
import FormBackground from '../../../../../components/Form/FormBackground';
import GenericForm from '../../../../../components/GenericForm/GenericForm';
import FormError from '../../../../../components/Form/FormError';


const ModalDetailView = ({
  // data
  genForm,
  languageId,
  translations,
  // methods,
  onGenericFormChanged,
  onSave,
  onSaveCompleted,
  onSaveError,
  onClose,
}) => (
  <FormBackground>

    <GenericForm
      genForm={genForm}
      nodeId="formRoot"
      languageId={languageId}
      onChange={onGenericFormChanged}
    />

    <Mutation
      mutation={MUTATION_SAVE_GENERIC_FORM_TRANSPORTATION}
      onCompleted={onSaveCompleted}
      onError={onSaveError}
    >
      {(mutationSave, { loading, error, }) => (
        <>
          <FormError
            isValid={genForm.isValid}
            error={error}
          />
          <Row>
            <Col XS={12} SM={12}>
              <Button
                onClick={onClose}
              >
                {translations.common.close}
              </Button>
            </Col>
            <Col XS={12} SM={12} textAlign="right">
              <ButtonLoader
                onClick={() => onSave(mutationSave)}
                isLoading={loading}
                disabled={!genForm.isValid}
                color="primary"
              >
                {translations.common.btnSave}
              </ButtonLoader>
            </Col>
          </Row>
        </>
      )}
    </Mutation>

  </FormBackground>
);


ModalDetailView.propTypes = {
  genForm: shape({
    isValid: bool.isRequired,
  }).isRequired,
  languageId: string.isRequired,
  translations: object.isRequired,
  onGenericFormChanged: func.isRequired,
  onSave: func.isRequired,
  onSaveCompleted: func.isRequired,
  onSaveError: func.isRequired,
  onClose: func.isRequired,
};


export default ModalDetailView;
