import React from 'react';
import {
  arrayOf, number, object, func,
} from 'prop-types';

import MainPanel from './MainPanel';
import LeftPanel from './LeftPanel';

import StyledTimeline from './styles/StyledTimeline';


const Timeline = ({
  from,
  to,
  hours,
  sections,
  hourWidth,
  getItemNameAndStyle,
  getSectionName,
  onClickItem,
}) => {
  const leftPanelWidth = 10;
  const topPanelHeight = 1.5;
  const timelineWidth = hours * hourWidth;
  const rowHeight = 4.5;
  const itemHeight = 2.5;

  const params = {
    from,
    to,
    hourWidth,
    timelineWidth,
    leftPanelWidth,
    topPanelHeight,
    rowHeight,
    itemHeight,
    hours,
  };

  return (
    <StyledTimeline
      params={params}
      isClickable={!!onClickItem}
    >

      <LeftPanel
        params={params}
        sections={sections}
        getSectionName={getSectionName}
      />

      <MainPanel
        params={params}
        sections={sections}
        getItemNameAndStyle={getItemNameAndStyle}
        onClickItem={onClickItem}
      />

    </StyledTimeline>
  );
};


Timeline.propTypes = {
  hourWidth: number.isRequired,
  from: object.isRequired,
  to: object.isRequired,
  hours: number.isRequired,
  sections: arrayOf(object).isRequired,
  getItemNameAndStyle: func.isRequired,
  getSectionName: func.isRequired,
  onClickItem: func.isRequired,
};


export default Timeline;
