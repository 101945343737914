import React from 'react';
import { func, object, string, } from 'prop-types';
import { Query, } from 'react-apollo';

import { QUERY_FETCH_SMS_FOR_TRANSPORT, } from '../../../gql/queries';
import { SUBSCRIPTION_STOREKEEPER_RECIEVE_SMS, } from '../../../gql/subscriptions';
import Modal from '../../../../../atoms/Modal/Modal';
import ModalLoadingPart from '../../../../../components/modal/ModalLoadingPart';
import ModalErrorPart from '../../../../../components/modal/ModalErrorPart';
import ModalSmsChatLogic from './ModalSmsChatLogic';

// PRIDAT SMS

const ModalSmsChat = ({
  transportationId,
  languageId,
  translations,
  onToggle,
  ...rest
}) => (
  <Modal
    title="Chat" // TODO translation
    isOpen
    size="SM"
    onClose={onToggle}
    disablePadding
  >
    <Query
      query={QUERY_FETCH_SMS_FOR_TRANSPORT}
      variables={{
        transportationId,
      }}
      fetchPolicy="network-only"
    >
      {({
        loading, error, data, refetch, subscribeToMore,
      }) => {
        if (loading) {
          return (
            <ModalLoadingPart
              onBack={onToggle}
              btnBack={translations.common.close}
            />
          );
        }
        if (error || !data.fetchSmsForTransport) {
          return (
            <ModalErrorPart
              error={error}
              onBack={onToggle}
              btnBack={translations.common.close}
            />
          );
        }
        return (
          <ModalSmsChatLogic
            smsData={data.fetchSmsForTransport}
            refetchChat={refetch}
            transportationId={transportationId}
            languageId={languageId}
            translations={translations}
            onToggle={onToggle}
            subscribeToRecieveSms={() => subscribeToMore({
              document: SUBSCRIPTION_STOREKEEPER_RECIEVE_SMS,
              variables: {
                transportationId,
              },
              updateQuery: (prev, { subscriptionData, }) => {
                if (!subscriptionData.data) return null;
                const newSms = subscriptionData.data.recieveSms;
                return {
                  fetchSmsForTransport: [
                    newSms,
                    ...prev.fetchSmsForTransport.filter(
                      (item) => item.id !== newSms.id
                    ), // filter if adding sameId
                  ],
                };
              },
            })}
            {...rest}
          />
        );
      }}
    </Query>
  </Modal>
);


ModalSmsChat.propTypes = {
  // data
  transportationId: string.isRequired,
  languageId: string.isRequired,
  translations: object.isRequired,
  // methods
  onToggle: func.isRequired,
};


export default ModalSmsChat;
