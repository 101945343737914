import React from 'react';
import {
  arrayOf, func, object, string,
} from 'prop-types';
import { Query, } from 'react-apollo';

import TabWithCountLogic from './TabWithCountLogic';


const TabWithCount = ({
  name,
  activeTab,
  text,
  query,
  queryName,
  variables,
  subscriptions,
  onChangePage,
}) => (
  <Query
    query={query}
    variables={variables}
    fetchPolicy="network-only"
  >
    {(queryData) => (
      <TabWithCountLogic
        name={name}
        activeTab={activeTab}
        text={text}
        queryData={queryData}
        queryName={queryName}
        subscriptions={subscriptions}
        onChangePage={onChangePage}
      />
    )}
  </Query>
);

TabWithCount.propTypes = {
  name: string.isRequired,
  activeTab: string.isRequired,
  text: string.isRequired,
  query: object.isRequired,
  queryName: string.isRequired,
  variables: object,
  subscriptions: arrayOf(object).isRequired,
  onChangePage: func.isRequired,
};

export default TabWithCount;
