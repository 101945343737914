/**
 * Generate Time Select Options
 */
export const generateTimes = (localization) => {
  const ret = [];

  for (let i = 0; i < 96; i++) {
    const hours = Math.floor(i / 4);
    const minutes = (i % 4) * 15;
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);

    ret.push({
      id: i,
      label: date.toLocaleTimeString(localization, { hour: '2-digit', minute: '2-digit', }),
      hours,
      minutes,
    });
  }

  return ret;
};


/**
 * Get scroll to time
 */
export const calculateScrollTo = (value) => {
  let minutes = 0;
  let hours = 0;

  const date = value || new Date();
  hours = date.getHours();
  minutes = (parseInt((date.getMinutes() + 7.5) / 15, 10) * 15) % 60;

  return {
    minutes,
    hours,
  };
};
