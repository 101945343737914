import React from 'react';

import StyledSvg from '../styles/StyledSvg';


const LiveMonitor = ({ ...props }) => (
  <StyledSvg viewBox="0 0 512 512.00039" {...props}>
    <path d="m150.015625 242.808594v-30.839844h15.003906c24.8125 0 45.003907-20.1875
      45.003907-45.003906s-20.191407-45.003906-45.003907-45.003906h-60.007812c-24.816407 0-45.003907 20.1875-45.003907
      45.003906s20.1875 45.003906 45.003907 45.003906h15v30.839844c-67.410157 7.488281-120.011719 64.800781-120.011719
      134.175781 0 74.449219 60.566406 135.015625 135.015625 135.015625 74.445313 0 135.011719-60.566406 135.011719-135.015625
      0-69.375-52.597656-126.6875-120.011719-134.175781zm-45.003906-60.84375c-8.273438 0-15.003907-6.730469-15.003907-15 0-8.273438
      6.730469-15.003906 15.003907-15.003906h60.007812c8.269531 0 15 6.730468 15 15.003906 0 8.269531-6.730469 15-15 15zm30.003906
      300.03125c-57.90625 0-105.011719-47.105469-105.011719-105.011719 0-57.902344 47.105469-105.007813 105.011719-105.007813
      57.902344 0 105.011719 47.105469 105.011719 105.007813 0 57.90625-47.109375 105.011719-105.011719 105.011719zm0 0"
    />
    <path d="m94.117188 314.875-21.214844 21.214844 55.898437 55.898437h61.21875v-30.003906h-48.792969zm0 0" />
    <path d="m478.367188 33.632812c-44.839844-44.84375-117.808594-44.84375-162.652344 0-44.839844 44.84375-44.839844
      117.808594 0 162.652344l81.328125 81.324219 81.324219-81.324219c44.84375-44.84375 44.84375-117.808594 0-162.652344zm-21.214844
      141.4375-60.109375 60.109376-60.109375-60.109376c-33.144532-33.148437-33.144532-87.078124 0-120.222656 33.144531-33.144531
      87.074218-33.144531 120.21875 0 33.144531 33.144532 33.144531 87.078125 0 120.222656zm0 0"
    />
    <path d="m397.042969 69.953125c-24.816407 0-45.003907 20.191406-45.003907 45.003906 0 24.816407 20.1875 45.007813 45.003907
      45.007813 24.816406 0 45.003906-20.191406 45.003906-45.007813 0-24.8125-20.1875-45.003906-45.003906-45.003906zm0
      60.007813c-8.273438 0-15.003907-6.730469-15.003907-15.003907 0-8.269531 6.730469-15 15.003907-15 8.269531 0 15 6.730469 15
      15 0 8.273438-6.726563 15.003907-15 15.003907zm0 0"
    />
    <path d="m292.03125 497c0 8.285156-6.714844 15-15 15s-15.003906-6.714844-15.003906-15 6.71875-15.003906 15.003906-15.003906
      15 6.71875 15 15.003906zm0 0"
    />
    <path d="m352.039062 497c0 8.285156-6.71875 15-15.003906 15s-15-6.714844-15-15
      6.714844-15.003906 15-15.003906 15.003906 6.71875 15.003906 15.003906zm0 0"
    />
    <path d="m412.042969 497c0 8.285156-6.714844 15-15 15-8.285157 0-15.003907-6.714844-15.003907-15s6.71875-15.003906
      15.003907-15.003906c8.285156 0 15 6.71875 15 15.003906zm0 0"
    />
    <path d="m412.042969 376.984375c0 8.285156-6.714844 15.003906-15 15.003906-8.285157 0-15.003907-6.71875-15.003907-15.003906s6.71875-15
      15.003907-15c8.285156 0 15 6.714844 15 15zm0 0"
    />
    <path d="m412.042969 436.992188c0 8.285156-6.714844 15-15 15-8.285157 0-15.003907-6.714844-15.003907-15 0-8.285157 6.71875-15
      15.003907-15 8.285156 0 15 6.714843 15 15zm0 0"
    />
    <path d="m412.042969 316.980469c0 8.285156-6.714844 15-15 15-8.285157 0-15.003907-6.714844-15.003907-15 0-8.285157
      6.71875-15.003907 15.003907-15.003907 8.285156 0 15 6.71875 15 15.003907zm0 0"
    />
  </StyledSvg>
);

export default LiveMonitor;
