import React, { Component, Fragment, } from 'react';
import { object, func, } from 'prop-types';

import { MUTATION_REMOVE_USER, } from '../../gql/mutations';
import { pipe, } from '../../../../logic/utils';
import { withErrors, } from '../../../../logic/errorManager/withErrors';
import { withNotifications, } from '../../../../logic/notifications/withNotifications';
import UserCreate from '../UserCreate/UserCreate';
import UserUpdate from '../UserUpdate/UserUpdate';
import ModalDelete from '../../../../components/modal/ModalDelete';
import UsersView from './UsersView';


class Users extends Component {
  state = {
    detailModal: {
      isOpen: false,
      id: null,
    },
    deleteModal: {
      isOpen: false,
      id: null,
    },
    createModal: {
      isOpen: false,
    },
  }


  handleToggleDetailModal = (id = null) => {
    this.setState((prevState) => ({
      detailModal: {
        ...prevState.detailModal,
        isOpen: !prevState.detailModal.isOpen,
        id,
      },
    }));
  }


  handleToggleDeleteModal = (id = null) => {
    this.setState((prevState) => ({
      deleteModal: {
        ...prevState.deleteModal,
        isOpen: !prevState.deleteModal.isOpen,
        id,
      },
    }));
  }


  handleToggleCreateModal = () => {
    this.setState((prevState) => ({
      createModal: {
        ...prevState.createModal,
        isOpen: !prevState.createModal.isOpen,
      },
    }));
  }


  handleToggleBlockUser = (mutationUpdateUser, user) => {
    mutationUpdateUser({
      variables: {
        id: user.id,
        values: {
          block: !user.block,
        },
      },
    });
  }


  handleToggleBlockUserCompleted = (data, user) => {
    const { translations, addNotification, } = this.props;

    addNotification({
      status: 'success',
      title: user.block
        ? translations.settings.userUnlocked
        : translations.settings.userBlocked,
    });
  }


  handleToggleBlockUserError = (graphQLError) => {
    const { translateGraphQLError, addNotification, } = this.props;

    addNotification({
      status: 'error',
      text: translateGraphQLError(graphQLError),
    });
  }


  render() {
    const { detailModal, deleteModal, createModal, } = this.state;
    const { translations, } = this.props;

    return (
      <Fragment>

        {createModal.isOpen && (
          <UserCreate
            onToggle={this.handleToggleCreateModal}
          />
        )}

        {detailModal.isOpen && (
          <UserUpdate
            userId={detailModal.id}
            onToggle={this.handleToggleDetailModal}
          />
        )}

        {deleteModal.isOpen && (
          <ModalDelete
            // data
            title={translations.settings.removeUserTitle}
            text={translations.settings.removeUserText}
            mutation={MUTATION_REMOVE_USER}
            variables={{ id: deleteModal.id, }}
            // methods
            onToggle={this.handleToggleDeleteModal}
          />
        )}

        <UsersView
          // data
          translations={translations}
          // methods
          onCreate={this.handleToggleCreateModal}
          onDetail={this.handleToggleDetailModal}
          onDelete={this.handleToggleDeleteModal}
          onBlock={this.handleToggleBlockUser}
          onBlockCompleted={this.handleToggleBlockUserCompleted}
          onBlockError={this.handleToggleBlockUserError}
        />

      </Fragment>
    );
  }
}


Users.propTypes = {
  translations: object.isRequired,
  addNotification: func.isRequired,
  translateGraphQLError: func.isRequired,
};


export default pipe(
  withErrors,
  withNotifications,
)(Users);
