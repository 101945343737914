import React from 'react';
import { func, string, object, } from 'prop-types';
import { Query, } from 'react-apollo';

import { TRANSLATIONS_TRANSPORTATION, } from '../../gql/translations';
import { QUERY_FETCH_TECHNICAL_CHECK, } from '../../gql/queries';
import { withTranslations, } from '../../../../logic/translations/withTranslations';
import PartLoading from '../../../../components/Parts/PartLoading';
import PartError from '../../../../components/Parts/PartError';
import { withUser, } from '../../../../logic/auth/withUser';
import { pipe, } from '../../../../logic/utils';
import InputTechnicalCheckLogic from './InputTechnicalCheckLogic';
import ModalHeader from '../../../../atoms/Modal/ModalHeader';
import ModalBody from '../../../../atoms/Modal/ModalBody';


const InputTechnicalCheck = ({
  // data
  transportationId,
  languageId,
  translations,
  resources,
  // methods
  onToggle,
}) => (
  <>
    <ModalHeader onClose={onToggle}>
      {translations.transportation.technicalCheck.inputTechnicalCheckTitle}
    </ModalHeader>
    <ModalBody disablePadding>

      <Query
        query={QUERY_FETCH_TECHNICAL_CHECK}
        fetchPolicy="network-only"
        variables={{
          transportationId,
          languageId,
        }}
      >
        {({ loading, error, data, }) => {
          if (loading) {
            return (
              <div className="app--padding">
                <PartLoading />
              </div>
            );
          }
          if (error || !data.fetchTechnicalCheck) {
            return (
              <div className="app--padding">
                <PartError error={error} />
              </div>
            );
          }
          return (
            <InputTechnicalCheckLogic
          // data
              id={transportationId}
              data={data.fetchTechnicalCheck}
              translations={translations}
              languageId={languageId}
              resources={resources}
              onToggle={onToggle}
            />
          );
        }}
      </Query>
    </ModalBody>
  </>
);


InputTechnicalCheck.propTypes = {
  // data
  transportationId: string.isRequired,
  languageId: string.isRequired,
  translations: object.isRequired,
  resources: object.isRequired,
  // methods
  onToggle: func.isRequired,
};

export default pipe(
  withTranslations(TRANSLATIONS_TRANSPORTATION),
  withUser,
)(InputTechnicalCheck);
