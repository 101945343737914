import React, { Fragment, } from 'react';
import {
  shape, func, object, bool,
} from 'prop-types';
import { Mutation, } from 'react-apollo';

import { MUTATION_CREATE_USER, } from '../../gql/mutations';
import Button from '../../../../atoms/Button/Button';
import ButtonLoader from '../../../../atoms/Button/ButtonLoader';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import FormError from '../../../../components/Form/FormError';
import UserForm from '../UserForm/UserForm';
import FormBackground from '../../../../components/Form/FormBackground';


const RoleCreateView = ({
  // data
  userForm,
  translations,
  // methods
  onChangeForm,
  onClose,
  onCreate,
  onCreateCompleted,
  onCreateError,
}) => (
  <FormBackground>
    <UserForm
      // data
      isCreate
      userForm={userForm}
      translations={translations}
      // methods
      onChangeForm={onChangeForm}
    />

    <Mutation
      mutation={MUTATION_CREATE_USER}
      onCompleted={onCreateCompleted}
      onError={onCreateError}
    >
      {(mutate, { loading, error, }) => (
        <Fragment>
          <FormError
            isValid={userForm.isValid}
            error={error}
          />
          <Row>
            <Col SM={12} MD={12} textAlign="left">
              <Button
                onClick={onClose}
              >
                {translations.common.back}
              </Button>
            </Col>
            <Col SM={12} MD={12} textAlign="right">
              <ButtonLoader
                color="success"
                onClick={() => onCreate(mutate)}
                disabled={!userForm.isValid}
                isLoading={loading}
              >
                {translations.common.create}
              </ButtonLoader>
            </Col>
          </Row>
        </Fragment>
      )}
    </Mutation>
  </FormBackground>
);


RoleCreateView.propTypes = {
  translations: object.isRequired,
  userForm: shape({
    isValid: bool.isRequired,
  }).isRequired,
  onChangeForm: func.isRequired,
  onClose: func.isRequired,
  onCreate: func.isRequired,
  onCreateCompleted: func.isRequired,
  onCreateError: func.isRequired,
};


export default RoleCreateView;
