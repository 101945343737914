import React, { Fragment, } from 'react';
import {
  bool, string, object, func, shape,
} from 'prop-types';
import { Query, Mutation, } from 'react-apollo';

import { QUERY_STAND_WIZARD_NEXT_STANDS, } from '../../gql/queries';
import { MUTATION_MOVE_TO_NEXT_STAND, } from '../../gql/mutations';
import { FINISH_TYPES, } from './TabFinish';
import ButtonLoader from '../../../../atoms/Button/ButtonLoader';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import FormError from '../../../../components/Form/FormError';
import FormRowSimple from '../../../../components/Form/FormRowSimple';
import InputRadio from '../../../../atoms/InputRadio/InputRadio';
import InputSelect from '../../../../atoms/InputSelect/InputSelect';
import InputRadioList from '../../../../atoms/InputRadio/InputRadioList';


const TabFinish = ({
  finishType,
  targetStand,
  standId,
  languageId,
  transportationId,
  manualMoveToNextStandsPossible,
  translations,
  // methods
  onChangeFinishType,
  onChangeTargetStand,
  onMoveToNextStand,
  onMoveToNextStandCompleted,
}) => (
  <Fragment>

    <Row formGroup>
      <Col>
        {manualMoveToNextStandsPossible ? (
          <FormRowSimple
            label={translations.stand.labelMoveToNextStand}
            showInfo={false}
          >
            <InputRadioList>
              <li>
                <InputRadio
                  text={translations.common.automatic}
                  value={finishType}
                  name={FINISH_TYPES.AUTO}
                  onChange={onChangeFinishType}
                />
              </li>
              <li className="standWizard--finish-form">
                <div>
                  <InputRadio
                    text={translations.common.manual}
                    value={finishType}
                    name={FINISH_TYPES.SELECT}
                    onChange={onChangeFinishType}
                  />
                </div>
                <div className="standWizard--finish-manual">
                  <Query
                    query={QUERY_STAND_WIZARD_NEXT_STANDS}
                    fetchPolicy="no-cache"
                    variables={{
                      languageId: languageId === null ? undefined : languageId,
                      standId,
                      transportationId,
                    }}
                  >
                    {({ loading, error, data, }) => (
                      <InputSelect
                        value={targetStand}
                        options={(data && data.fetchStandsForNextAction)
                          ? data.fetchStandsForNextAction
                          : []
                        }
                        onChange={(newValue) => onChangeTargetStand(newValue)}
                        isDisabled={finishType === FINISH_TYPES.AUTO}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        placeholder={translations.stand.automaticSelectText}
                        isLoading={loading}
                        error={error}
                      />
                    )}
                  </Query>
                </div>
              </li>
            </InputRadioList>
          </FormRowSimple>
        ) : (
          <span>{translations.common.automatic}</span>
        )}
      </Col>
    </Row>

    <Mutation
      mutation={MUTATION_MOVE_TO_NEXT_STAND}
      onCompleted={onMoveToNextStandCompleted}
    >
      {(mutateMoveNextStand, { error, loading, }) => (
        <Fragment>
          <FormError
            error={error}
          />
          <Row>
            <Col textAlign="right">
              <ButtonLoader
                type="button"
                color="success"
                isLoading={loading}
                onClick={() => onMoveToNextStand(mutateMoveNextStand)}
              >
                {translations.stand.moveToNextStand}
              </ButtonLoader>
            </Col>
          </Row>
        </Fragment>
      )}
    </Mutation>

  </Fragment>
);


TabFinish.propTypes = {
  // data
  finishType: string.isRequired,
  targetStand: shape({
    id: string.isRequired,
    name: string.isRequired,
  }),
  standId: string.isRequired,
  languageId: string.isRequired,
  transportationId: string.isRequired,
  manualMoveToNextStandsPossible: bool.isRequired,
  translations: object.isRequired,
  // methods
  onChangeFinishType: func.isRequired,
  onChangeTargetStand: func.isRequired,
  onMoveToNextStand: func.isRequired,
  onMoveToNextStandCompleted: func.isRequired,
};

TabFinish.defaultProps = {
  targetStand: null,
};


export default TabFinish;
