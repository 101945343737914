import React, { Component, Fragment, } from 'react';
import { func, string, object, } from 'prop-types';
import { Mutation, } from 'react-apollo';

import { GraphQLErrorsGlobal, } from '../../../../globals';
import { MUTATION_REMOVE_ROLE, } from '../../gql/mutations';
import { TRANSLATIONS_ROLE_DELETE, } from '../../gql/translations';
import { pipe, } from '../../../../logic/utils';
import { withNotifications, } from '../../../../logic/notifications/withNotifications';
import { withTranslations, } from '../../../../logic/translations/withTranslations';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import Modal from '../../../../atoms/Modal/Modal';
import Button from '../../../../atoms/Button/Button';
import ButtonLoader from '../../../../atoms/Button/ButtonLoader';
import FormError from '../../../../components/Form/FormError';
import RoleDeleteForce from '../RoleDeleteForce/RoleDeleteForce';

import StyledModalDelete from '../../../../components/modal/styles/StyledModalDelete';
import Error from '../../../../styles/icons/Error';


class RoleDelete extends Component {
  state = {
    deleteForceModal: {
      isOpen: false,
      users: [],
    },
  }


  handleDeleteCompleted = () => {
    const { translations, addNotification, onToggle, } = this.props;

    addNotification({
      status: 'success',
      title: translations.common.deleted,
    });
    onToggle();
  }


  handleDeleteError = (mutationError) => {
    try {
      const { graphQLErrors, } = mutationError;

      if (graphQLErrors && graphQLErrors.length > 0) {
        const { message, extensions, } = graphQLErrors[0];

        switch (message) {
          case GraphQLErrorsGlobal.SETTINGS_ROLE_USED: {
            if (extensions.exception.data) {
              this.setState((prevState) => ({
                deleteForceModal: {
                  ...prevState.deleteForceModal,
                  isOpen: !prevState.deleteForceModal.isOpen,
                  users: extensions.exception.data.users,
                },
              }));
            }
            break;
          }

          default: {
            break;
          }
        }
      }
    } catch (err) {
      // continue regardless of error
    }
  }


  render() {
    const { deleteForceModal, } = this.state;
    const {
      // data
      roleId,
      translations,
      // methods
      onToggle,
    } = this.props;

    return (
      <Fragment>
        {deleteForceModal.isOpen && (
          <RoleDeleteForce
            onToggle={onToggle}
            roleId={roleId}
            users={deleteForceModal.users}
          />
        )}

        <Modal
          title={translations.settings.removeRoleTitle}
          isOpen
          onClose={onToggle}
          size="SM"
        >

          <StyledModalDelete>
            <div className="errorModal--info">
              <span className="errorModal--icon">
                <Error />
              </span>
              <span className="errorModal--text">
                {translations.settings.removeRoleText}
              </span>
            </div>
          </StyledModalDelete>

          <Mutation
            mutation={MUTATION_REMOVE_ROLE}
            variables={{
              id: roleId,
            }}
            onCompleted={this.handleDeleteCompleted}
            onError={this.handleDeleteError}
          >
            {(mutateDelete, { loading, error, }) => (
              <Fragment>
                <FormError
                  error={error}
                />
                <Row>
                  <Col XS={12} SM={12} textAlign="left">
                    <Button
                      type="button"
                      shape="outline"
                      onClick={onToggle}
                    >
                      {translations.common.back}
                    </Button>
                  </Col>
                  <Col XS={12} SM={12} textAlign="right">
                    <ButtonLoader
                      type="button"
                      color="error"
                      onClick={mutateDelete}
                      isLoading={loading}
                    >
                      {translations.common.delete}
                    </ButtonLoader>
                  </Col>
                </Row>
              </Fragment>
            )}
          </Mutation>
        </Modal>
      </Fragment>
    );
  }
}


RoleDelete.propTypes = {
  roleId: string.isRequired,
  translations: object.isRequired,
  onToggle: func.isRequired,
  addNotification: func.isRequired,
};


export default pipe(
  withNotifications,
  withTranslations(TRANSLATIONS_ROLE_DELETE),
)(RoleDelete);
