import React, { Component, } from 'react';
import { string, object, func, } from 'prop-types';
import { Query, } from 'react-apollo';

import { QUERY_SCHEDULE_TIMELINE_FILTER, QUERY_FILTER_TIMELINE_BY_PLATFORMS, } from '../../../gql/queries';
import { getInitTimelineFilter, getFilterHoursOptions, } from '../utils';
import { parseBySections, } from '../../../../../components/Timeline/parseData';
import TabView from './TabView';
import SubscribeComponent from '../../../../../atoms/SubscribeComponent/SubscribeComponent';
import { SUBSCRIPTION_REMOVE_TRANSPORTATION_FROM_PLAN, } from '../../../gql/subscriptions';


class TabTimelinePlatforms extends Component {
  constructor(props) {
    super(props);

    const { translations, } = this.props;

    const rangeOptions = getFilterHoursOptions(translations);

    this.state = {
      timeline: null,
      initFilter: getInitTimelineFilter({}, rangeOptions),
    };
  }


  handleTimelineCompleted = (response) => {
    try {
      const timeline = response.filterTimelineByPlatforms
        ? parseBySections(response.filterTimelineByPlatforms)
        : null;

      this.setState({
        timeline,
      });
    } catch (err) {
      this.handleTimelineError();
    }
  }


  handleTimelineError = () => {
    this.setState({
      timeline: null,
    });
  }


  render() {
    const { timeline, initFilter, } = this.state;
    const {
      // data
      languageId,
      translations,
      resources,
      // methods
      onChangePage,
    } = this.props;

    return (
      <Query query={QUERY_SCHEDULE_TIMELINE_FILTER}>
        {({ data: { scheduleFilter, }, }) => (
          <Query
            query={QUERY_FILTER_TIMELINE_BY_PLATFORMS}
            variables={{
              languageId,
              from: scheduleFilter.from || initFilter.from,
              to: scheduleFilter.to || initFilter.to,
              platformIds: undefined,
              transportationTypeId: scheduleFilter.transportationType
                ? scheduleFilter.transportationType.id
                : undefined,
            }}
            onCompleted={this.handleTimelineCompleted}
            onError={this.handleTimelineError}
            fetchPolicy="no-cache"
          >
            {(timelineData) => (
              <>
                <SubscribeComponent
                  subscribe={() => timelineData.subscribeToMore({
                    document: SUBSCRIPTION_REMOVE_TRANSPORTATION_FROM_PLAN,
                    variables: { },
                    updateQuery: (prev) => {
                      timeline.refetch();
                      return prev;
                    },
                  })}
                />
                <TabView
                  // data
                  timeline={timeline}
                  timelineData={timelineData}
                  initFilter={scheduleFilter}
                  languageId={languageId}
                  translations={translations}
                  resources={resources}
                  // methods
                  onChangePage={onChangePage}
                />
              </>
            )}
          </Query>
        )}
      </Query>
    );
  }
}


TabTimelinePlatforms.propTypes = {
  // data
  languageId: string.isRequired,
  translations: object.isRequired,
  resources: object.isRequired,
  // methods
  onChangePage: func.isRequired,
};


export default TabTimelinePlatforms;
