import React from 'react';
import { func, object, string, } from 'prop-types';

import Card from '../../../../atoms/Card/Card';
import HeadingWihButtons from '../../../../atoms/HeadingWihButtons/HeadingWihButtons';
import InternalParkingTable from './InternalParkingTable';


const InternalParkingView = ({
  // data
  resources,
  translations,
  languageId,
  deleteModal,
  // methods
  onToggleDelete,
}) => (
  <Card>

    <HeadingWihButtons>
      <h2>{translations.common.internalParkingHeader}</h2>
    </HeadingWihButtons>


    <InternalParkingTable
      // data
      resources={resources}
      translations={translations}
      languageId={languageId}
      onToggleDelete={onToggleDelete}
      // methods
      deleteModal={deleteModal}
    />

  </Card>
);


InternalParkingView.propTypes = {
  resources: object.isRequired,
  translations: object.isRequired,
  languageId: string.isRequired,
  deleteModal: object.isRequired,
  onToggleDelete: func.isRequired,
};


export default InternalParkingView;
