// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
import React from 'react';
import ButtonIcon from '../../../atoms/Button/ButtonIcon';
import ArrowLeft from '../../../styles/icons/ArrowLeft';
import ArrowRight from '../../../styles/icons/ArrowRight';
import Plus from '../../../styles/icons/Plus';


const ReportAssignList = (props) => (
  <ul className="ral-items">
    {props.items.map((item) => (
      <li key={item.id} className={`ral-item ${props.rightSide ? 'ral-item-right-side' : ''}`}>
        {props.rightSide && (
        <ButtonIcon
          className="ral-btn ral-mr-1"
          color={props.buttonColor}
          onClick={() => { props.onUnassign(item); }}
        >
          {props.icon}
        </ButtonIcon>
        )}
        <span>
          {item.label}
        </span>
        {!props.rightSide && (
        <ButtonIcon
          className="ral-btn ral-ml-1"
          color={props.buttonColor}
          onClick={() => { props.onAssign(item); }}
        >
          {props.icon}
        </ButtonIcon>
        )}
      </li>
    ))}
  </ul>
);

const ReportAssignBox = (props) => (
  <div className="report-assign-box">
    <span className="ral-title">{props.title}</span>
    <div className="ral--columns">
      <div className="ral--list">
        <div className="ral--list-title">Dostupní</div>
        <ReportAssignList items={props.availableItems} buttonColor="success" rightSide={false} icon={<ArrowRight />} onAssign={props.onAssign} onUnassign={props.onUnassign} />
      </div>
      <div className="ral--list">
        <div className="ral--list-title">
          Přiřazení
          {props.addCustomEnabled && props.addCustom && (
            <ButtonIcon
              className="ral-btn ral-ml-1"
              color="success"
              onClick={props.addCustom}
            >
              <Plus />
            </ButtonIcon>
          )}
        </div>
        <ReportAssignList items={props.assignedItems} buttonColor="error" rightSide icon={<ArrowLeft />} onAssign={props.onAssign} onUnassign={props.onUnassign} />
      </div>
    </div>
  </div>
);


export default ReportAssignBox;
