import React from 'react';

import StyledSvg from './styles/StyledSvg';


const Circle = ({ ...props }) => (
  <StyledSvg viewBox="0 0 10 10" {...props}>
    <circle cx="5" cy="5" r="5" />
  </StyledSvg>
);

export default Circle;
