import React, { Component, } from 'react';
import {
  func, shape, string, object,
} from 'prop-types';

import {
  changeAndValidateInput, validateAndMergeWholeForm, mergeValidationObjectIntoForm,
} from '../../../../logic/form/common';
import { initVehicleGroupForm, } from '../forms/structure';
import VehicleGroupUpdateView from './VehicleGroupUpdateView';


class VehicleGroupUpdateLogic extends Component {
  constructor(props) {
    super(props);

    const {
      id,
      name,
      configuration,
    } = props.data.fetchOneVehicleGroup;

    this.state = {
      ...initVehicleGroupForm,
      id,
      detailForm: {
        ...initVehicleGroupForm.detailForm,
        values: {
          ...initVehicleGroupForm.values,
          name,
          ...configuration,
        },
      },
    };
  }


  /**
   * Form - onChange
   */
  handleValueChange = (name, value) => {
    const { detailForm, } = this.state;
    const newState = changeAndValidateInput(detailForm, name, value);

    this.setState({
      detailForm: newState,
    });
  }


  /**
   * onUpdate
   */
  handleUpdate = (mutate) => {
    const { id, detailForm, detailForm: { values, }, } = this.state;
    const { languageId, } = this.props;
    const newDetailForm = validateAndMergeWholeForm(detailForm);

    if (!newDetailForm.isValid) {
      this.setState({
        detailForm: newDetailForm,
      });
    } else {
      const variables = {
        id,
        name: values.name,
        configuration: {
          residents: values.residents === null ? false : values.residents,
        },
        languageId,
      };
      mutate({
        variables,
      });
    }
  }


  /**
   * onUpdate - Completed
   */
  handleUpdateComplete = (responseData) => {
    const {
      translations, onToggle, onUpdated, addNotification,
    } = this.props;

    onToggle();
    addNotification({
      status: 'success',
      title: translations.common.updated,
    });

    if (onUpdated) onUpdated(responseData);
  }


  /**
   * onUpdate - Error
   */
  handleUpdateError = (mutationError) => {
    try {
      const { detailForm, } = this.state;

      const { graphQLErrors, } = mutationError;

      if (graphQLErrors && graphQLErrors.length > 0) {
        const { message, extensions, } = graphQLErrors[0];

        switch (message) {
          case 'UNPROCESSABLE_ENTITY': {
            if (extensions.exception.data) {
              const newState = mergeValidationObjectIntoForm(detailForm, extensions.exception.data);

              this.setState({
                detailForm: newState,
              });
            }
            break;
          }

          default: {
            break;
          }
        }
      }
    } catch {
      // continue regardless of error
    }
  }


  render() {
    const { detailForm, } = this.state;
    const { resources, onToggle, translations, } = this.props;

    return (
      <VehicleGroupUpdateView
        // data
        resources={resources}
        detailForm={detailForm}
        translations={translations}
        // methods
        onChange={this.handleValueChange}
        onToggle={onToggle}
        onUpdate={this.handleUpdate}
        onUpdateCompleted={this.handleUpdateComplete}
        onUpdateError={this.handleUpdateError}
      />
    );
  }
}


VehicleGroupUpdateLogic.propTypes = {
  resources: object.isRequired,
  languageId: string.isRequired,
  data: shape({
    fetchOneVehicleGroup: shape({
      id: string.isRequired,
      name: string.isRequired,
    }).isRequired,
  }).isRequired,
  translations: object.isRequired,
  onToggle: func.isRequired,
  onUpdated: func,
  addNotification: func.isRequired,
};

VehicleGroupUpdateLogic.defaultProps = {
  onUpdated: undefined,
};


export default VehicleGroupUpdateLogic;
