import React, { Component, Fragment, } from 'react';
import {
  node, oneOfType, arrayOf, func,
} from 'prop-types';
import { v4, } from 'uuid';

import { NOTIFICATIONS, } from '../../globals';
import Context from './Context';
import NotificationsArray from './NotificationsArray';


const initNotification = {
  status: NOTIFICATIONS.ERROR,
  title: '',
  text: '',
  showIcon: true,
};


class NotificationsProvider extends Component {
  state = {
    notifications: [],
  }


  handleAddNotification = (newItem) => {
    const id = v4();
    const item = {
      id,
      ...initNotification,
      ...newItem,
    };

    this.setState((prevState) => ({
      notifications: [
        ...prevState.notifications,
        item,
      ],
    }));
  }


  handleRemoveNotifications = (removeId) => {
    const { notifications, } = this.state;

    this.setState({
      notifications: notifications.filter((item) => item.id !== removeId),
    });
  }

  render() {
    const { notifications, } = this.state;
    const { children, onToggleSmsChatModal, } = this.props;
    return (
      <Fragment>

        <NotificationsArray
          notifications={notifications}
          onRemove={this.handleRemoveNotifications}
          onShowChat={onToggleSmsChatModal}
        />

        <Context.Provider
          value={{
            addNotification: this.handleAddNotification,
          }}
        >
          {children}
        </Context.Provider>

      </Fragment>
    );
  }
}


NotificationsProvider.propTypes = {
  children: oneOfType([
    arrayOf(node),
    node,
  ]).isRequired,
  onToggleSmsChatModal: func.isRequired,
};


export default NotificationsProvider;
