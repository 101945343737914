import React from 'react';
import {
  object, func, arrayOf, string,
} from 'prop-types';


const YearMonthForm = ({
  date,
  localeUtils,
  onChange,
  monthsNames,
}) => {
  const currentYear = date.getFullYear();
  const fromMonth = new Date(currentYear - 5, 0);
  const toMonth = new Date(currentYear + 5, 11);

  const months = localeUtils.getMonths();
  const years = [];
  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i);
  }

  const handleChange = function handleChange(e) {
    const { year, month, } = e.target.form;
    onChange(new Date(year.value, month.value));
  };

  return (
    <form className="DayPicker-Caption">
      <select name="month" onChange={handleChange} value={date.getMonth()}>
        {months.map((month, i) => (
          <option key={month} value={i}>
            {monthsNames[i]}
          </option>
        ))}
      </select>
      <select name="year" onChange={handleChange} value={date.getFullYear()}>
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </form>
  );
};


YearMonthForm.propTypes = {
  date: object.isRequired,
  localeUtils: object.isRequired,
  monthsNames: arrayOf(string).isRequired,
  onChange: func.isRequired,
};


export default YearMonthForm;
