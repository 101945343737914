import styled from 'styled-components';

import media from '../../../styles/media';


const StyledSidebar = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;

  /* width */
  ::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(p) => p.theme.sidebar.color};
    border-right: 2px solid ${(p) => p.theme.primary.t800};
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${(p) => p.theme.sidebar.color};
  }

  box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);
  background: ${(p) => p.theme.sidebar.background};
  color: ${(p) => p.theme.sidebar.color};
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  line-height: 1;
  font-weight: 500;

  ul {
    padding-left: 0;
    list-style: none;
    flex-grow: 1;
  }


  /*
   * Subtitle
   */
  .sidebar--subtitle {
    padding: .375rem 1.25rem;
    font-size: .75rem;
  }


  /*
   * Divider
   */
  .sidebar--divider {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }

  
  /*
   * Item - Collapse, Link
   */

  /* Item */
  li {
    margin-top: .625rem;
  }

  .sidebar--item {
    display: flex;
    align-items: center;
    color: inherit;
    transition: background-color .3s ease-in-out,color .3s ease-in-out;
    position: relative;
    text-decoration: none;
    cursor: pointer;

    border-radius: ${(p) => p.theme.input.borderRadius};
    margin: 0 .75rem;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
  }

  .sidebar--item-active {
    background: ${(p) => p.theme.primary.t900};
    color: ${(p) => p.theme.primary.t700};
  }

  .sidebar--item:hover {
    background: ${(p) => p.theme.primary.t900};
    color: ${(p) => p.theme.primary.t700};
  }

  .sidebar--item-level-0 {

  }

  .sidebar--item-level-1 {
    margin-left: 2rem;
  }

  .sidebar--item-icon {
    padding-right: 1.125rem;
    fill: currentColor;
    font-size: 1.5rem;
  }

  /* Collapse */
  .sidebar--item-collapse-icon {
    margin-left: auto;
    width: 10px;
    text-align: center;
    font-size: 0.65rem;
    transition: all .2s ease-out;
  }

  .sidebar--item-collapse-icon-open {
    transform: rotate(0deg);
  }

  .sidebar--item-collapse-icon-close {
    transform: rotate(-180deg);
  }

  .sidebar--footer {
    width: 100%;
    padding: 0.5rem;
    box-sizing: border-box;
  }


  /*
   * Media sizes
   */
  ${() => media.LG`
    .sidebar--header-icon-close {
      display: none;
    }
  `}
`;


export default StyledSidebar;
