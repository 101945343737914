import React, { Fragment, } from 'react';
import {
  string, func, object,
} from 'prop-types';

import { FORM_TYPES, } from '../../globals';
import GFText from './Editable/GFText';
import GFNumber from './Editable/GFNumber';
import GFSelect from './Editable/GFSelect';
import GFCheckbox from './Editable/GFCheckbox';
import GFFieldSet from './Editable/GFFieldSet';
import GFRadio from './Editable/GFRadio';
import GFTime from './Editable/GFTime';
import GFTimestamp from './Editable/GFTimestamp';


const GenericForm = ({
  nodeId,
  genForm,
  genForm: {
    form,
    tree,
    values,
  },
  languageId,
  // methods
  onChange,
}) => (
  <Fragment>
    {tree[nodeId].childs.map((key) => {
      const item = tree[key];
      switch (item.type) {
        case FORM_TYPES.NUMBER:
          return (
            <GFNumber
              key={key}
              input={form[key]}
              value={values[key]}
              onChange={onChange}
            />
          );

        case FORM_TYPES.TEXT:
          return (
            <GFText
              key={key}
              input={form[key]}
              value={values[key]}
              onChange={onChange}
            />
          );

        case FORM_TYPES.MULTISELECT:
          return (
            <GFSelect
              key={key}
              isMulti
              input={form[key]}
              value={values[key]}
              languageId={languageId}
              onChange={onChange}
            />
          );

        case FORM_TYPES.SELECT:
          return (
            <GFSelect
              key={key}
              input={form[key]}
              value={values[key]}
              languageId={languageId}
              onChange={onChange}
            />
          );

        case FORM_TYPES.CHECKBOX:
          return (
            <GFCheckbox
              key={key}
              input={form[key]}
              value={values[key]}
              languageId={languageId}
              onChange={onChange}
            />
          );

        case FORM_TYPES.RADIO:
          return (
            <GFRadio
              key={key}
              input={form[key]}
              value={values[key]}
              languageId={languageId}
              onChange={onChange}
            />
          );

        case FORM_TYPES.TIME:
          return (
            <GFTime
              key={key}
              input={form[key]}
              value={values[key]}
              languageId={languageId}
              onChange={onChange}
            />
          );

        case FORM_TYPES.DATE:
          return (
            <GFTimestamp
              key={key}
              input={form[key]}
              value={values[key]}
              languageId={languageId}
              onChange={onChange}
            />
          );

        case FORM_TYPES.TIMESTAMP:
          return (
            <GFTimestamp
              key={key}
              input={form[key]}
              showTime
              value={values[key]}
              languageId={languageId}
              onChange={onChange}
            />
          );

        case FORM_TYPES.FIELDSET:
          return (
            <GFFieldSet
              key={key}
              genForm={genForm}
              nodeId={key}
              languageId={languageId}
              onChange={onChange}
            />
          );

        default:
          return null;
      }
    })}
  </Fragment>
);


GenericForm.propTypes = {
  genForm: object.isRequired,
  nodeId: string.isRequired,
  onChange: func.isRequired,
  languageId: string.isRequired,
};


export default GenericForm;
