import styled from 'styled-components';


const BadgeList = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;

  div {
    margin: 0.1rem;
  }

  ${(p) => p.isValue && `
    margin-left: 0.5rem;
  `}
`;


export default BadgeList;
