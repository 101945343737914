import React, { Component, } from 'react';
import { object, string, } from 'prop-types';
import { Query, } from 'react-apollo';

import { QUERY_STAND_TRANSLATION, } from '../gql/translations';
import { QUERY_STANDS, } from '../gql/queries';
import { withTranslations, } from '../../../logic/translations/withTranslations';
import Card from '../../../atoms/Card/Card';
import Breadcrumb from '../../../atoms/Breadcrumb/Breadcrumb';
import BreadcrumbItem from '../../../atoms/Breadcrumb/BreadcrumbItem';
import PageFullScreen from '../../../atoms/PageView/PageFullScreen';
import PartLoading from '../../../components/Parts/PartLoading';
import PartError from '../../../components/Parts/PartError';
import InputSelect from '../../../atoms/InputSelect/InputSelect';
import Stand from '../components/Stand/Stand';
import FormRowSimple from '../../../components/Form/FormRowSimple';

import StyledSelectStand from '../components/Stand/styles/StyledSelectStand';


class StandPage extends Component {
  state = {
    stand: null,
  }


  handleChangeStand = (newStand) => {
    this.setState({
      stand: newStand,
    });
  }


  handleCompletedStands = (data) => {
    if (data.fetchStands.length === 1) {
      this.setState({
        stand: data.fetchStands[0],
      });
    }
  }


  render() {
    const { stand, } = this.state;
    const { resources, translations, languageId, } = this.props;

    return (
      <PageFullScreen>

        <Breadcrumb>
          <BreadcrumbItem to="/">{translations.common.home}</BreadcrumbItem>
          <BreadcrumbItem>{translations.common.stands}</BreadcrumbItem>
        </Breadcrumb>

        <StyledSelectStand>
          <Card>

            <h2>{translations.stand.standHeader}</h2>

            <Query
              query={QUERY_STANDS}
              variables={{
                languageId: languageId === null ? undefined : languageId,
              }}
              fetchPolicy="no-cache"
              onCompleted={this.handleCompletedStands}
            >
              {({ loading, error, data, }) => {
                if (loading) {
                  return <PartLoading minHeight="3rem" />;
                }

                if (error || !data || !data.fetchStands) {
                  return <PartError error={error} minHeight="3rem" />;
                }

                if (data.fetchStands.length === 0) {
                  return (
                    <div>
                      {translations.stand.noStandsText}
                    </div>
                  );
                }

                return (
                  <FormRowSimple
                    label={translations.stand.labelSelectStand}
                    showInfo={false}
                  >
                    <InputSelect
                      value={stand}
                      options={data.fetchStands}
                      onChange={(newValue) => this.handleChangeStand(newValue)}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      placeholder={translations.stand.selectStandPlaceholder}
                    />
                  </FormRowSimple>
                );
              }}
            </Query>

          </Card>
        </StyledSelectStand>

        <div>
          {(stand !== null) && (
            <Stand
              key={stand.id}
              // data
              resources={resources}
              stand={stand}
              languageId={languageId}
              translations={translations}
            />
          )}
        </div>

      </PageFullScreen>
    );
  }
}


StandPage.propTypes = {
  resources: object,
  translations: object.isRequired,
  languageId: string.isRequired,
};

StandPage.defaultProps = {
  resources: {},
};


export default withTranslations(QUERY_STAND_TRANSLATION)(StandPage);
