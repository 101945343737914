import React from 'react';
import {
  string, object,
} from 'prop-types';

import ValueText from '../../../atoms/Values/ValueText';
import FormRowReadOnly from '../../../components/Form/FormRowReadOnly';


const RelatedTransportations = ({
  next,
  prev,
  translations,
}) => {
  if (!next && !prev) return null;

  return (
    <fieldset>
      <legend>{translations.transportation.connectedTransports.titleDetail}</legend>
      {prev && (
        <FormRowReadOnly label={translations.transportation.connectedTransports.titlePrevTransport}>
          <ValueText>
            {prev}
          </ValueText>
        </FormRowReadOnly>
      )}
      {next && (
        <FormRowReadOnly label={translations.transportation.connectedTransports.titleNextTransport}>
          <ValueText>
            {next}
          </ValueText>
        </FormRowReadOnly>
      )}
    </fieldset>
  );
};


RelatedTransportations.propTypes = {
  next: string,
  prev: string,
  translations: object.isRequired,
};

RelatedTransportations.defaultProps = {
  next: string,
  prev: string,
};


export default RelatedTransportations;
