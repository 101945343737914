import React from 'react';
import { Query, } from 'react-apollo';

import { QUERY_USER, } from '../../modules/Main/gql/queries';
import { getAccessToken, } from '../localStorage/auth';


export const withUser = (Component) => (props) => (
  <Query
    query={QUERY_USER}
    variables={{ accessToken: getAccessToken(), }}
    fetchPolicy="cache-only"
  >
    {({ data, }) => (
      <Component
        {...props}
        user={(data && data.getUser)
          ? data.getUser.user
          : undefined
        }
        resources={(data && data.getUser)
          ? data.getUser.resources
          : undefined
        }
      />
    )}
  </Query>
);
