import styled from 'styled-components';


const TimelineWrapper = styled.div`
  padding: ${(p) => p.theme.common.padding};
  border: 2px solid ${(p) => p.theme.grey.t50};
`;


export default TimelineWrapper;
