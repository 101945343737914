import styled from 'styled-components';


const Breadcrumb = styled.ol`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  height: 2.5rem;
  margin: 0;
  padding: 0;
  align-items: center;

  a {
    color: ${(p) => p.theme.tertiary.t800};
    font-weight: 500;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  li:not(:first-child)::before {
    display: inline-block;
    padding: 0 .5rem;
    content: "/";
    font-weight: bold;
  }
`;


export default Breadcrumb;
