import React, { Component, } from 'react';
import styled from 'styled-components';
import { bool, string, func, } from 'prop-types';


const StyledInputCheckbox = styled.label`
  .checkbox--container {
    display: inline-block;
    vertical-align: middle;
  }

  .checkbox--icon {
    fill: none;
    stroke: white;
    stroke-width: 3px;
    vertical-align: top;
    visibility: ${(p) => (p.checked ? 'visible' : 'hidden')};
  }

  .checkbox--hidden-checkbox {
    border: 0;
    clip: rect(0 0 0 0);
    clippath: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  .checkbox--checkbox {
    cursor: pointer;
    display: inline-block;
    width: 1.25rem;
    height: 1.25rem;
    border: 1px solid ${(p) => (p.checked ? p.theme.tertiary.t700 : p.theme.grey.t500)};
    background: ${(p) => (p.checked ? p.theme.tertiary.t700 : p.theme.white)};
    transition: all 150ms;
  }

  .checkbox--text {
    margin-left: 8px;
    user-select: none;
  }

  ${(p) => p.disabled && `
    .checkbox--checkbox {
      background: ${p.theme.grey.t300};
      cursor: unset;
    }
  `}
`;


class InputCheckbox extends Component {
  handle = (e) => {
    const { disabled, onChange, } = this.props;

    if (disabled) return;

    onChange(e.target.checked);
  }


  render() {
    const { disabled, value, text, } = this.props;

    return (
      <StyledInputCheckbox
        disabled={disabled}
        checked={value}
      >
        <div className="checkbox--container">
          <input className="checkbox--hidden-checkbox" type="checkbox" checked={value} onChange={this.handle} />
          <div className="checkbox--checkbox">
            <svg className="checkbox--icon" viewBox="0 0 24 24">
              <polyline points="20 6 9 17 4 12" />
            </svg>
          </div>
        </div>
        {text && (
          <span className="checkbox--text">{text}</span>
        )}
      </StyledInputCheckbox>
    );
  }
}


InputCheckbox.propTypes = {
  value: bool,
  text: string,
  disabled: bool,
  onChange: func,
};

InputCheckbox.defaultProps = {
  value: false,
  text: undefined,
  disabled: false,
  onChange: () => {},
};


export default InputCheckbox;
