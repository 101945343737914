import React from 'react';
import styled from 'styled-components';
import { oneOfType, arrayOf, node, } from 'prop-types';

import { HEADER_HEIGHT, } from '../../globals';


const StyledPageFullScreen = styled.div`
  display: flex;
  min-height: 100%;
  height: ${`calc(100vh - ${HEADER_HEIGHT}rem)`};
  overflow-x: auto;
  overflow-y: auto;
  box-sizing: border-box;
  padding: ${(p) => p.theme.common.padding};

  .page--fullscreen {
    
  }
`;


const PageFullScreen = ({ children, }) => (
  <StyledPageFullScreen>
    <div className="page--fullscreen">
      {children}
    </div>
  </StyledPageFullScreen>
);


PageFullScreen.propTypes = {
  children: oneOfType([
    node,
    arrayOf(node),
  ]).isRequired,
};


export default PageFullScreen;
