// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
import React, { Component, } from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import {
  arrayOf, func, object, string,
} from 'prop-types';
import {
  areSameDates, createEndOfMonth, MILLIS_IN_DAY, toTimezone,
} from '../../../logic/date';
import { REPORT_PERIOD_TIMEZONE, } from '../utils/data';


const daysShort = {
  Sunday: 'Ne',
  Monday: 'Po',
  Tuesday: 'Út',
  Wednesday: 'St',
  Thursday: 'Čt',
  Friday: 'Pá',
  Saturday: 'So',
};

const formatWeekdayFromString = (day) => daysShort[day];

const getDayClassName = (dateLocal, periods) => {
  const isoDateLocal = dateLocal.toISOString();

  let className = 'tile';

  const period = periods.find((p) => p.fromLocal <= isoDateLocal && isoDateLocal <= p.toLocal);

  if (period) {
    className += ' selected';

    let isMargin = false;
    if (isoDateLocal === period.fromLocal) {
      className += ' start';
      isMargin = true;
    }
    if (areSameDates(dateLocal, period.toDateLocal)) {
      className += ' end';
      isMargin = true;
    }
    if (!isMargin) {
      className += ' middle';
    }
  }

  return className;
};

const getMonthClassName = (dateLocal, periods) => {
  const isoDateLocal = dateLocal.toISOString();

  let className = 'tile';

  const period = periods.find((p) => p.fromLocal === isoDateLocal);

  if (period) {
    className += ' selected start end';
  }

  return className;
};

const createPeriodEnd = (type, start) => {
  if (type === 'day') {
    return new Date(start.getTime() + MILLIS_IN_DAY - 1);
  } if (type === 'week') {
    const date = new Date(start);
    date.setDate(date.getDate() + 7);
    date.setTime(date.getTime() - 1);
    return date;
  }
  return createEndOfMonth(start);
};

const TILE_MARGIN = '2px';
const BORDER_RADIUS = '0.75rem';

const StyledReportPeriodCalendar = styled.div`
  width: 100%;

  .react-datepicker {
    width: 100%;
    border: 0;
    margin-top: 1rem;
  }
  
  .react-datepicker__month-container {
    width: 100%;
  }
  
  .react-datepicker__header {
    background: ${(p) => p.theme.white};
  }

  .react-datepicker__day-names, .react-datepicker__week, .react-datepicker__month-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .react-datepicker__day-names {
    margin-top: 0.5rem;
  }

  .tile {
    flex: 1 0 0;
    line-height: 1.5rem;
    height: 1.5rem;
    background: ${(p) => p.theme.white};
    color: ${(p) => p.theme.grey.t900};
    margin: ${TILE_MARGIN};
    border-radius: 0;
    box-sizing: content-box;
  }
  
  .tile:hover {
    background: ${(p) => p.theme.grey.t50};
  }
  
  .tile.react-datepicker__day--outside-month {
    color: ${(p) => p.theme.grey.t200};
  }
  
  .tile.selected {
    background: ${(p) => p.theme.grey.t500};
    color: ${(p) => p.theme.white};
  }
  
  .tile.start {
    border-top-left-radius: ${BORDER_RADIUS};
    border-bottom-left-radius: ${BORDER_RADIUS};
  }
  
  .tile.start:not(.end) {
    margin-right: 0;
    padding-right: ${TILE_MARGIN};
  }
  
  .tile.middle {
    margin-left: 0;
    margin-right: 0;
    padding-left: ${TILE_MARGIN};
    padding-right: ${TILE_MARGIN};
  }
  
  .tile.end {
    border-top-right-radius: ${BORDER_RADIUS};
    border-bottom-right-radius: ${BORDER_RADIUS};
  }

  .tile.end:not(.start) {
    margin-left: 0;
    padding-left: ${TILE_MARGIN};
  }
`;


class ReportPeriodCalendar extends Component {
  handleSelect = (fromDateLocal) => {
    const { periodType, periods, onPeriodsChange, } = this.props;

    const fromLocal = fromDateLocal.toISOString();
    const fromDateReport = toTimezone(REPORT_PERIOD_TIMEZONE, fromDateLocal);
    const from = fromDateReport.toISOString();

    const toDateLocal = createPeriodEnd(periodType, fromDateLocal);
    const toLocal = toDateLocal.toISOString();
    const toDateReport = toTimezone(REPORT_PERIOD_TIMEZONE, toDateLocal);
    const to = toDateReport.toISOString();

    // if clicked before some period
    const overlappingPeriod = periods.find((p) => p.from <= to && to <= p.to);
    // if clicked on some period
    const clickedPeriod = periods.find((p) => p.from <= from && from <= p.to);

    if (overlappingPeriod && !clickedPeriod) {
      return;
    }

    if (clickedPeriod) {
      onPeriodsChange(periods.filter((p) => p.from !== clickedPeriod.from));
    } else {
      onPeriodsChange([
        ...periods,
        {
          from, fromLocal, fromDateLocal, to, toLocal, toDateLocal,
        },
      ]);
    }
  }

  render() {
    const { periodType, periods, } = this.props;
    return (
      <StyledReportPeriodCalendar>
        <DatePicker
          formatWeekDay={(day) => formatWeekdayFromString(day)}
          dayClassName={(date) => getDayClassName(date, periods)}
          monthClassName={(date) => getMonthClassName(date, periods)}
          onSelect={this.handleSelect}
          showMonthYearPicker={periodType === 'month'}
          selected={undefined}
          onChange={() => undefined}
          calendarStartDay={1}
          inline
          isClearable
        />
      </StyledReportPeriodCalendar>
    );
  }
}

ReportPeriodCalendar.propTypes = {
  periodType: string.isRequired,
  periods: arrayOf(object.isRequired).isRequired,
  onPeriodsChange: func.isRequired,
};

export default ReportPeriodCalendar;
