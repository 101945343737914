import React, { Component, } from 'react';
import {
  string, shape, bool, object,
} from 'prop-types';

import ValueText from '../../../atoms/Values/ValueText';
import FormRowReadOnly from '../../Form/FormRowReadOnly';


class GFCheckbox extends Component {
  getValue = () => {
    const {
      value,
      translations,
    } = this.props;

    if (value === null || value === undefined) return '-';
    if (value === true) return translations.common.yes;
    return translations.common.no;
  }


  render() {
    const {
      input,
    } = this.props;

    return (
      <FormRowReadOnly label={`${input.translation.label}?`}>
        <ValueText>
          {this.getValue()}
        </ValueText>
      </FormRowReadOnly>
    );
  }
}


GFCheckbox.propTypes = {
  input: shape({
    name: string.isRequired,
    type: string.isRequired,
    disabled: bool.isRequired,
    translation: shape({
      label: string,
      placeholder: string,
      help: string,
    }).isRequired,
  }).isRequired,
  value: bool,
  translations: object.isRequired,
};

GFCheckbox.defaultProps = {
  value: undefined,
};


export default GFCheckbox;
