import gql from 'graphql-tag';


export const QUERY_FETCH_TRANSPORTATION_BY_SCANNED_ID = gql`
  query FetchTransportationByScannedId($uuid: String) {
    fetchTransportationByScannedId(uuid: $uuid) {
      transportation {
        id
        no
        clearanceTimeStart
        transportationTypeId
        gateInTime
        clearanceState
        platformId
      }
    }
  }
`;
