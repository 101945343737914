import React from 'react';
import {
  func, object, shape, bool, string,
} from 'prop-types';
import { Query, Mutation, } from 'react-apollo';

import { QUERY_NEXT_STANDS, } from '../../../gql/queries';
import {
  MUTATION_FINISH_TRANSPORTATION,
} from '../../../gql/mutations';
import ButtonLoader from '../../../../../atoms/Button/ButtonLoader';
import Button from '../../../../../atoms/Button/Button';
import Row from '../../../../../atoms/Row/Row';
import Col from '../../../../../atoms/Col/Col';
import InputCheckbox from '../../../../../atoms/InputCheckbox/InputCheckbox';
import InputSelect from '../../../../../atoms/InputSelect/InputSelect';
import FormBackground from '../../../../../components/Form/FormBackground';
import FormError from '../../../../../components/Form/FormError';
import FormRow from '../../../../../components/Form/FormRow';


const ModalFinishView = ({
  // data
  transportationId,
  finishForm: {
    isValid,
    form,
    values,
  },
  languageId,
  translations,
  // methods
  onToggle,
  onChangeForm,
  onAction,
  onActionCompleted,
  onActionError,
}) => (
  <FormBackground>

    <FormRow
      label={translations.centralAppStorekeeper.labelManual}
      input={form.stand}
    >
      <InputCheckbox
        value={values.manualRedirect}
        onChange={(checked) => onChangeForm(form.manualRedirect.name, checked)}
        text={translations.centralAppStorekeeper.textManual}
      />
      {values.manualRedirect && (
        <Query
          query={QUERY_NEXT_STANDS}
          fetchPolicy="no-cache"
          variables={{
            transportationId,
            languageId,
          }}
        >
          {({ loading, error, data, }) => (
            <InputSelect
              options={(data && data.fetchStoreKeeperStandsForNextAction)
                ? data.fetchStoreKeeperStandsForNextAction
                : []
              }
              value={values.stand}
              onChange={(newValue) => onChangeForm(form.stand.name, newValue)}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              disabled={!values.manualRedirect}
              loading={loading}
              error={error}
              placeholder={translations.centralAppStorekeeper.placeholderModalFinishPlatforms}
            />
          )}
        </Query>
      )}
    </FormRow>

    <Mutation
      mutation={MUTATION_FINISH_TRANSPORTATION}
      onCompleted={onActionCompleted}
      onError={onActionError}
    >
      {(mutationAction, { loading, error, }) => (
        <>
          <FormError
            isValid={isValid}
            error={error}
          />
          <Row>
            <Col XS={12} SM={12}>
              <Button
                onClick={onToggle}
              >
                {translations.common.close}
              </Button>
            </Col>
            <Col XS={12} SM={12} textAlign="right">
              <ButtonLoader
                className="buttonGrp--right"
                color="error"
                disabled={!isValid}
                onClick={() => onAction(mutationAction)}
                isLoading={loading}
              >
                {translations.centralAppStorekeeper.btnFinish}
              </ButtonLoader>
            </Col>
          </Row>
        </>
      )}
    </Mutation>

  </FormBackground>
);


ModalFinishView.propTypes = {
  // data
  transportationId: string.isRequired,
  finishForm: shape({
    form: shape({
      manualRedirect: object.isRequired,
      stand: object.isRequired,
    }).isRequired,
    values: shape({
      manualRedirect: bool,
      stand: object,
    }).isRequired,
  }).isRequired,
  languageId: string.isRequired,
  translations: object.isRequired,
  // methods
  onToggle: func.isRequired,
  onChangeForm: func.isRequired,
  onAction: func.isRequired,
  onActionCompleted: func.isRequired,
  onActionError: func.isRequired,
};


export default ModalFinishView;
