import React from 'react';
import {
  string, shape, bool, arrayOf,
} from 'prop-types';

import Badge from '../../../atoms/Badge/Badge';
import BadgeList from '../../../atoms/Badge/BadgeList';
import FormRowReadOnly from '../../Form/FormRowReadOnly';


const GFArray = ({
  input, value,
}) => (
  <FormRowReadOnly label={input.translation.label}>
    <BadgeList isValue>
      {(value && value.length > 0) ? value.map((item) => (
        <Badge key={item.id}>
          {item.name}
        </Badge>
      )) : '-'}
    </BadgeList>
  </FormRowReadOnly>
);


GFArray.propTypes = {
  input: shape({
    name: string.isRequired,
    type: string.isRequired,
    disabled: bool.isRequired,
    translation: shape({
      label: string,
      placeholder: string,
      help: string,
    }).isRequired,
  }).isRequired,
  value: arrayOf(shape({
    id: string.isRequired,
    name: string.isRequired,
  })),
};

GFArray.defaultProps = {
  value: [],
};


export default GFArray;
