import styled from 'styled-components';


/**
 * Tooltip
 */
const Tooltip = styled.span`
  visibility: hidden;
  font-size: 0.66rem;

  pointer-events: none;
  padding: 0.25rem 0.5rem;
  background-color: ${(p) => p.theme.black};
  color: ${(p) => p.theme.white};
  text-align: center;
  border-radius: ${(p) => p.theme.input.borderRadius};;
  position: fixed;
  z-index: 100;

  ${(p) => p.position === 'topLeft' && `
    bottom: ${p.bottom};
    right: ${p.right};
    visibility: visible;
  `}

  ${(p) => p.position === 'topRight' && `
    bottom: ${p.bottom};
    left: ${p.left};
    visibility: visible;
  `}

  ${(p) => p.position === 'bottomLeft' && `
    top: ${p.top};
    right: ${p.right};
    visibility: visible;
  `}

  ${(p) => p.position === 'bottomRight' && `
    top: ${p.top};
    left: ${p.left};
    visibility: visible;
  `}

  transition: all 0.3s ease 0s
`;


export default Tooltip;
