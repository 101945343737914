import React, { Fragment, } from 'react';
import { func, string, object, } from 'prop-types';

import Row from '../../atoms/Row/Row';
import Col from '../../atoms/Col/Col';
import Button from '../../atoms/Button/Button';

import PartError from '../Parts/PartError';


const ModalErrorPart = ({ onBack, btnBack, error, }) => (
  <Fragment>
    <PartError error={error} />
    <Row>
      <Col textAlign="right">
        <Button
          onClick={onBack}
        >
          {btnBack}
        </Button>
      </Col>
    </Row>
  </Fragment>
);


ModalErrorPart.propTypes = {
  error: object,
  onBack: func.isRequired,
  btnBack: string.isRequired,
};

ModalErrorPart.defaultProps = {
  error: undefined,
};


export default ModalErrorPart;
