import React from 'react';
import {
  string, shape, bool, func, number,
} from 'prop-types';

import FormRow from '../../Form/FormRow';
import InputTime from '../../../atoms/InputTime/InputTime';


const GFTime = ({
  input, value, onChange,
}) => {
  const date = new Date();
  if (value) {
    date.setHours(value.hours);
    date.setMinutes(value.minutes);
  }

  return (
    <FormRow
      label={input.translation.label}
      input={input}
      info={input.translation.help}
    >
      <InputTime
        value={value ? date : null}
        clearable
        onChange={(newDate) => onChange(
          input.name,
          newDate ? {
            hours: newDate.getHours(),
            minutes: newDate.getMinutes(),
          } : null,
        )}
        status={input.status}
        disabled={input.disabled}
      />
    </FormRow>
  );
};


GFTime.propTypes = {
  input: shape({
    name: string.isRequired,
    type: string.isRequired,
    disabled: bool.isRequired,
    translation: shape({
      label: string,
      placeholder: string,
      help: string,
    }).isRequired,
  }).isRequired,
  value: shape({
    hours: number.isRequired,
    minutes: number.isRequired,
  }),
  onChange: func.isRequired,
};


GFTime.defaultProps = {
  value: null,
};

export default GFTime;
