import React from 'react';
import {
  arrayOf, func,
  object, shape, string,
} from 'prop-types';

import { parseDateToHuman, } from '../../../../logic/date';
import { RESERVATION_TYPES, } from '../../../../globals';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import ValueText from '../../../../atoms/Values/ValueText';
import FormRowReadOnly from '../../../../components/Form/FormRowReadOnly';
import FormBackground from '../../../../components/Form/FormBackground';
import RelatedTransportations from '../RelatedTransportations';
import TransportationDocumentEditForm from '../forms/TransportationDocumentEditForm';
import { isCollectionService, isLoadingType, } from '../utils';
import OrderView from './OrderView';
import LoadingForm from '../forms/LoadingForm';


const InfoView = ({
  translations,
  reservation: {
    resNo,
    resTransportationType,
    resReservationType,
    resPlatform,
    resTimeFrom,
    resTimeTo,
    resDate,
    note,
  },
  company: {
    compName,
  },
  driver: {
    driverName,
    driverPhoneNumber,
    driverNationality,
  },
  passenger: {
    passengerName,
    passengerPhoneNumber,
    passengerNationality,
  },
  truck,
  trailer,
  extra,
  prevTransport,
  nextTransport,
  orders,
  productForms,
  resources,
  serviceType,
  sampleConfirmedAt,
  hasOwnTransport,
  //
  onLoadingFormChange,
}) => (
  <FormBackground className="transportationDetail--form">
    <Row>
      <Col HD={8} LG={12} MD={24}>

        <fieldset>
          <legend>{translations.transportation.titleReservationForm}</legend>

          <FormRowReadOnly label={translations.form.reservationNumber}>
            <ValueText>
              {resNo || '-'}
            </ValueText>
          </FormRowReadOnly>

          <FormRowReadOnly label={translations.form.transportationType}>
            <ValueText>
              {resTransportationType ? resTransportationType.name : '-'}
            </ValueText>
          </FormRowReadOnly>

          <FormRowReadOnly label={translations.form.reservationType}>
            <ValueText>
              {resReservationType ? resReservationType.name : '-'}
            </ValueText>
          </FormRowReadOnly>

          {(resReservationType
            && resReservationType.id === RESERVATION_TYPES.INTERVAL_PLATFORM
          ) && (
            <FormRowReadOnly label={translations.form.platform}>
              <ValueText>
                {resPlatform ? resPlatform.name : '-'}
              </ValueText>
            </FormRowReadOnly>
          )}

          {(resReservationType && resReservationType.id === RESERVATION_TYPES.DAY) && (
            <FormRowReadOnly label={translations.form.date}>
              <ValueText>
                {resDate ? parseDateToHuman(new Date(resDate), false) : '-'}
              </ValueText>
            </FormRowReadOnly>
          )}

          {(resReservationType && resReservationType.id !== RESERVATION_TYPES.DAY) && (
            <>
              <FormRowReadOnly label={translations.form.timeWindow}>
                <ValueText>
                  {`${resTimeFrom ? parseDateToHuman(new Date(resTimeFrom)) : '-'} - ${resTimeTo ? parseDateToHuman(new Date(resTimeTo)) : '-'}`}
                </ValueText>
              </FormRowReadOnly>
            </>
          )}

          <FormRowReadOnly label={translations.form.note}>
            <ValueText>
              {note || '-'}
            </ValueText>
          </FormRowReadOnly>

          <FormRowReadOnly label={translations.transportation.sampleText}>
            <ValueText>
              {sampleConfirmedAt ? parseDateToHuman(new Date(sampleConfirmedAt)) : '-'}
            </ValueText>
          </FormRowReadOnly>

        </fieldset>

        <RelatedTransportations
          prev={prevTransport}
          next={nextTransport}
          translations={translations}
        />

      </Col>
      <Col HD={8} LG={12} MD={24}>

        <fieldset>
          <legend>{translations.transportation.titleCompanyForm}</legend>

            <FormRowReadOnly label={translations.form.name}>
              <ValueText>
                {compName || '-'}
              </ValueText>
            </FormRowReadOnly>

            <FormRowReadOnly label={translations.form.hasOwnTransport}>
              <ValueText>
                {hasOwnTransport ? translations.common.yes : translations.common.no}
              </ValueText>
            </FormRowReadOnly>

        </fieldset>

        <fieldset>
          <legend>{translations.transportation.titleTruckForm}</legend>

          <FormRowReadOnly label={translations.form.truckRegistrationNumber}>
            <ValueText>
              {truck.truckRegistrationNumber || '-'}
            </ValueText>
          </FormRowReadOnly>

          {isLoadingType(resTransportationType.id) && (
            <>
              <FormRowReadOnly label={translations.form.carRegistrationCountry}>
                <ValueText>
                  {truck.carRegistrationCountry || '-'}
                </ValueText>
              </FormRowReadOnly>

              <FormRowReadOnly label={translations.form.tankerCode}>
                <ValueText>
                  {truck.tankerCode || '-'}
                </ValueText>
              </FormRowReadOnly>

              <FormRowReadOnly label={translations.form.tankerCheckExpiration}>
                <ValueText>
                  {truck.tankerCheckExpiration ? parseDateToHuman(new Date(truck.tankerCheckExpiration), false) : '-'}
                </ValueText>
              </FormRowReadOnly>

              <FormRowReadOnly label={translations.form.carConstruction}>
                <ValueText>
                  {truck.carConstruction || translations.form.carConstructionNone}
                </ValueText>
              </FormRowReadOnly>

              <FormRowReadOnly label={translations.form.chamberCount}>
                <ValueText>
                  {truck.chamberCount || '-'}
                </ValueText>
              </FormRowReadOnly>

              {Array.isArray(truck.chambers) && truck.chambers.map((chamber, index) => (
                <FormRowReadOnly label={`${translations.form.chamber} #${index + 1}`}>
                  {translations.form.chamberVolume}
                  <ValueText>
                    {chamber.volume}
                  </ValueText>
                  {translations.form.breakwaterCount}
                  <ValueText>
                    {chamber.breakwaterCount}
                  </ValueText>
                </FormRowReadOnly>
              ))}
            </>
          )}

        </fieldset>

        <fieldset>
          <legend>{translations.transportation.titleTrailerForm}</legend>

          <FormRowReadOnly label={translations.form.truckRegistrationNumber}>
            <ValueText>
              {trailer.trailerRegistrationNumber || '-'}
            </ValueText>
          </FormRowReadOnly>

          <FormRowReadOnly label={translations.form.carRegistrationCountry}>
            <ValueText>
              {trailer.carTrailerRegistrationCountry || '-'}
            </ValueText>
          </FormRowReadOnly>

        </fieldset>

        {isCollectionService(serviceType) && (<OrderView translations={translations} orders={orders} />)}

      </Col>
      <Col HD={8} LG={12} MD={24}>

        <fieldset>
          <legend>{translations.transportation.titleDriverForm}</legend>

          <FormRowReadOnly label={translations.form.personName}>
            <ValueText>
              {driverName || '-'}
            </ValueText>
          </FormRowReadOnly>

          <FormRowReadOnly label={translations.form.phoneNumber}>
            <ValueText>
              {driverPhoneNumber || '-'}
            </ValueText>
          </FormRowReadOnly>

          <FormRowReadOnly label={translations.form.nationality}>
            <ValueText>
              {driverNationality || '-'}
            </ValueText>
          </FormRowReadOnly>

        </fieldset>

        <fieldset>
          <legend>{translations.form.passenger}</legend>

          <FormRowReadOnly label={translations.form.personName}>
            <ValueText>
              {passengerName || '-'}
            </ValueText>
          </FormRowReadOnly>

          <FormRowReadOnly label={translations.form.phoneNumber}>
            <ValueText>
              {passengerPhoneNumber || '-'}
            </ValueText>
          </FormRowReadOnly>

          <FormRowReadOnly label={translations.form.nationality}>
            <ValueText>
              {passengerNationality || '-'}
            </ValueText>
          </FormRowReadOnly>

        </fieldset>

        <fieldset>
          <legend>{translations.transportation.titleExtraForm}</legend>

          <FormRowReadOnly label={translations.form.isAdr}>
            <ValueText>
              {extra.isAdr ? translations.common.yes : translations.common.no }
            </ValueText>
          </FormRowReadOnly>

          {!isLoadingType(resTransportationType.id) && (
            <>
              <FormRowReadOnly label={translations.form.isMultipleOrder}>
                <ValueText>
                  {extra.isMultipleOrder || '-'}
                </ValueText>
              </FormRowReadOnly>

              <FormRowReadOnly label={translations.form.multipleOrderExpiration}>
                <ValueText>
                  {extra.multipleOrderExpiration ? parseDateToHuman(new Date(extra.multipleOrderExpiration), false) : '-'}
                </ValueText>
              </FormRowReadOnly>
            </>
          )}

        </fieldset>

      </Col>

      <Col MD={24}>
        <fieldset>
          <legend>{translations.transportation.titleLoadingForm}</legend>
          <LoadingForm
            productForms={productForms}
            translations={translations}
            resources={resources}
            onChange={onLoadingFormChange}
          />
        </fieldset>
      </Col>

      <Col MD={24}>
        <fieldset>
          <legend>{translations.transportation.titleDocumentForm}</legend>
          <TransportationDocumentEditForm
            values={extra}
            translations={translations}
          />
        </fieldset>
      </Col>
    </Row>
  </FormBackground>
);


InfoView.propTypes = {
  translations: object.isRequired,
  reservation: shape({
    resNo: string.isRequired,
    resTransportationType: shape({
      name: string.isRequired,
    }).isRequired,
    resReservationType: shape({
      id: string.isRequired,
      name: string.isRequired,
    }).isRequired,
    resPlatform: shape({
      name: string.isRequired,
    }),
    resTimeFrom: string,
    resTimeTo: string,
    resDate: string,
    note: string,
  }).isRequired,
  company: shape({
    compName: string.isRequired,
    compIdentificationNumber: string,
    compVatNumber: string,
    compStreet: string,
    compCity: string,
    compZip: string,
    compState: string,
    compContactPerson: string,
    compEmail: string,
    compPhoneNumber: string,
  }).isRequired,
  driver: shape({
    driverName: string,
    driverPhoneNumber: string,
    driverNationality: string,
  }).isRequired,
  passenger: shape({
    passengerName: string,
    passengerPhoneNumber: string,
    passengerNationality: string,
  }).isRequired,
  truck: shape({
    truckRegistrationNumber: string,
  }).isRequired,
  trailer: shape({
    trailerRegistrationNumber: string,
    carTrailerRegistrationCountry: string,
  }).isRequired,
  extra: object.isRequired,
  prevTransport: string,
  nextTransport: string,
  orders: arrayOf(object),
  productForms: arrayOf(object),
  resources: object.isRequired,
  //
  onLoadingFormChange: func,
};


InfoView.defaultProps = {
  prevTransport: null,
  nextTransport: null,
  orders: [],
  productForms: [],
  onLoadingFormChange: () => {},
};


export default InfoView;
