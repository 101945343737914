import { SyncValidator, } from 'sofair-form-validation';
import { parseMessage, } from '../messages/messages';


/**
 * Validate input
 * @param {Object} input object of input
 * @param {*} value value of input
 */
export const validateInput = (input, value) => {
  const valErr = SyncValidator.validateInput(input, value);

  if (valErr === undefined) {
    return {};
  }
  return {
    [valErr.name]: parseMessage(valErr),
  };
};


/**
 * Validate Whole Form
 * @param {*} form
 * @param {*} values
 */
export const validateWholeForm = (form, values) => {
  const valErr = SyncValidator.validateForm(form, values);
  if (valErr === undefined) {
    return {};
  }

  const keys = Object.keys(valErr);
  const ret = {};

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    ret[key] = parseMessage(valErr[key]);
  }
  return ret;
};
