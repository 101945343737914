import React from 'react';
import { object, } from 'prop-types';
import StyledModuleGrid from './StyledModuleGrid';
import Planning from '../../../../styles/icons/app/Planning';
import LiveMonitor from '../../../../styles/icons/app/LiveMonitor';
import Stand from '../../../../styles/icons/app/Stand';
import Storekeeper from '../../../../styles/icons/app/Storekeeper';
import ModuleGridCard from './ModuleGridCard';


const ModuleGrid = ({ translations, }) => (
  <StyledModuleGrid>
    <ul className="module--list">
      <ModuleGridCard
        icon={<Planning />}
        title={translations.common.schedule}
        text={translations.dashboard.planText}
        to="/transportation/schedule"
      />

      <ModuleGridCard
        icon={<LiveMonitor />}
        title={translations.common.monitoring}
        text={translations.dashboard.monitoringText}
        to="/monitoring"
      />

      <ModuleGridCard
        icon={<Stand />}
        title={translations.common.stands}
        text={translations.dashboard.standText}
        to="/stands"
      />

      <ModuleGridCard
        icon={<Storekeeper />}
        title={translations.common.storekeeper}
        text={translations.dashboard.clearanceText}
        to="/storekeeper"
      />
    </ul>
  </StyledModuleGrid>
);

ModuleGrid.propTypes = {
  translations: object.isRequired,
};

export default ModuleGrid;
