export const jsonToQuery = (obj, prefix = undefined) => {
  const str = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const p in obj) {
    if (Object.hasOwn(obj, p)) {
      const k = prefix ? `${prefix}[${p}]` : p;
      const v = obj[p];
      str.push(
        (v !== null && typeof v === 'object')
          ? jsonToQuery(v, k)
          : `${encodeURIComponent(k)}=${encodeURIComponent(v)}`
      );
    }
  }
  return str.filter((s) => s !== '').join('&');
};
