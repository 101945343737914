// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
import React from 'react';
import { arrayOf, object, } from 'prop-types';

import ReportComponent from './ReportComponent';
import Col from '../../../../atoms/Col/Col';


const ReportColumn = (props) => {
  const { config, data, } = props;
  return (
    <Col SM={12}>
      {config.content.map((c, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <ReportComponent key={i} config={c} data={data} />
      ))}
    </Col>
  );
};

ReportColumn.propTypes = {
  config: object.isRequired,
  data: arrayOf(object.isRequired).isRequired,
};

export default ReportColumn;
