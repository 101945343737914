import { INPUT_TYPE, } from 'sofair-form-validation';
import { initForm, initInput, } from '../../../../logic/form/common';
import { regexEmail, regexPhone, } from '../../../../logic/form/regexes';


/**
 * Truck
 */
export const initTruckForm = {
  detailForm: {
    ...initForm,
    form: {
      registrationNumber: initInput({
        name: 'registrationNumber',
        validation: {
          required: true,
          min: 3,
          max: 20,
        },
      }),
      vehicleGroup: initInput({
        name: 'vehicleGroup',
        type: INPUT_TYPE.SELECT,
        validation: {
          required: true,
        },
      }),
    },
    values: {
      registrationNumber: '',
      vehicleGroup: null,
    },
  },
};


/**
 * Driver
 */
export const initDriverForm = {
  detailForm: {
    ...initForm,
    form: {
      name: initInput({
        name: 'name',
        validation: {
          required: true,
          min: 2,
          max: 255,
        },
      }),
      phoneNumber: initInput({
        name: 'phoneNumber',
        validation: {
          regex: regexPhone,
        },
      }),
      email: initInput({
        name: 'email',
        validation: {
          regex: regexEmail,
        },
      }),
      identityDocumentNumber: initInput({
        name: 'identityDocumentNumber',
        validation: {},
      }),
      identityDocumentNumberChecked: initInput({
        name: 'identityDocumentNumberChecked',
        type: INPUT_TYPE.CHECKBOX,
        validation: {},
      }),
      travelDocumentNumber: initInput({
        name: 'travelDocumentNumber',
        validation: {},
      }),
      travelDocumentNumberChecked: initInput({
        name: 'travelDocumentNumberChecked',
        type: INPUT_TYPE.CHECKBOX,
        validation: {},
      }),
      oshTimeEnd: initInput({
        name: 'oshTimeEnd',
        type: INPUT_TYPE.DATE,
        validation: {},
      }),
      onBlacklist: initInput({
        name: 'onBlacklist',
        type: INPUT_TYPE.CHECKBOX,
        validation: {},
      }),
      info: initInput({
        name: 'info',
      }),
    },
    values: {
      name: '',
      phoneNumber: '',
      email: '',
      identityDocumentNumber: '',
      identityDocumentNumberChecked: false,
      travelDocumentNumber: '',
      travelDocumentNumberChecked: false,
      oshTimeEnd: null,
      onBlacklist: false,
      info: '',
    },
  },
};


/**
 * Company
 */
export const initCompanyForm = {
  detailForm: {
    ...initForm,
    form: {
      name: initInput({
        name: 'name',
        validation: {
          required: true,
          min: 2,
          max: 255,
        },
      }),
      identificationNumber: initInput({
        name: 'identificationNumber',
        validation: {
          required: true,
        },
      }),
      vatNumber: initInput({
        name: 'vatNumber',
        validation: {
          required: true,
        },
      }),
      street: initInput({
        name: 'street',
        validation: {
          required: true,
        },
      }),
      city: initInput({
        name: 'city',
        validation: {
          required: true,
        },
      }),
      zip: initInput({
        name: 'zip',
        validation: {
          required: true,
        },
      }),
      state: initInput({
        name: 'state',
        validation: {
          required: true,
        },
      }),
      info: initInput({
        name: 'info',
      }),
      contactPerson: initInput({
        name: 'contactPerson',
      }),
      email: initInput({
        name: 'email',
        validation: {
          regex: regexEmail,
          required: true,
        },
      }),
      phoneNumber: initInput({
        name: 'phoneNumber',
        validation: {
          regex: regexPhone,
        },
      }),
    },
    values: {
      name: '',
      identificationNumber: '',
      vatNumber: '',
      street: '',
      city: '',
      zip: '',
      state: '',
      info: '',
      contactPerson: '',
      email: '',
      phoneNumber: '',
    },
  },
};


/**
 * Vehicle Group
 */
export const initVehicleGroupForm = {
  detailForm: {
    ...initForm,
    form: {
      name: initInput({
        name: 'name',
        validation: {
          required: true,
          min: 3,
          max: 50,
        },
      }),
      residents: initInput({
        name: 'residents',
        type: INPUT_TYPE.RADIO,
        validation: {
          required: false,
          min: null,
          max: null,
        },
      }),
    },
    values: {
      name: '',
      residents: null,
    },
  },
};
