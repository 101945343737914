import React from 'react';
import {
  func, shape, bool, string, object,
} from 'prop-types';
import { Query, } from 'react-apollo';

import { QUERY_VEHICLE_GROUPS_DETAIL_TRANSLATIONS, } from '../../gql/translations';
import { QUERY_VEHICLE_GROUPS_DETAIL, } from '../../gql/queries';
import { pipe, } from '../../../../logic/utils';
import { withNotifications, } from '../../../../logic/notifications/withNotifications';
import { withTranslations, } from '../../../../logic/translations/withTranslations';
import ModalLoadingPart from '../../../../components/modal/ModalLoadingPart';
import ModalErrorPart from '../../../../components/modal/ModalErrorPart';
import Modal from '../../../../atoms/Modal/Modal';
import VehicleGroupUpdateLogic from './VehicleGroupUpdateLogic';


const VehicleGroupUpdate = ({
  resources,
  translations,
  languageId,
  modalData: { isOpen, id, },
  onUpdated,
  onToggle,
  addNotification,
}) => {
  if (!isOpen) return null;
  if (!translations) return null;

  return (
    <Modal
      title={translations.directory.titleUpdateModalVehicleGroup}
      isOpen
      onClose={onToggle}
      size="SM"
      disablePadding
    >
      <Query
        query={QUERY_VEHICLE_GROUPS_DETAIL}
        variables={{ id, languageId, }}
        fetchPolicy="network-only"
      >
        {({ data, loading, error, }) => {
          if (loading) {
            return (
              <div className="app--padding">
                <ModalLoadingPart
                  onBack={onToggle}
                  btnBack={translations.common.back}
                />
              </div>
            );
          }

          if (error || !data.fetchOneVehicleGroup) {
            return (
              <div className="app--padding">
                <ModalErrorPart
                  onBack={onToggle}
                  error={error}
                  btnBack={translations.common.back}
                />
              </div>
            );
          }

          return (
            <VehicleGroupUpdateLogic
              resources={resources}
              languageId={languageId}
              data={data}
              onToggle={onToggle}
              translations={translations}
              onUpdated={onUpdated}
              addNotification={addNotification}
            />
          );
        }}
      </Query>
    </Modal>
  );
};


VehicleGroupUpdate.propTypes = {
  resources: object.isRequired,
  translations: object.isRequired,
  languageId: string.isRequired,
  modalData: shape({
    isOpen: bool.isRequired,
    id: string.isRequired,
  }).isRequired,
  onToggle: func.isRequired,
  addNotification: func.isRequired,
  onUpdated: func,
};

VehicleGroupUpdate.defaultProps = {
  onUpdated: undefined,
};


export default pipe(
  withNotifications,
  withTranslations(QUERY_VEHICLE_GROUPS_DETAIL_TRANSLATIONS),
)(VehicleGroupUpdate);
