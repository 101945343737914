// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
import React from 'react';
import { XAxis as XReAxis, } from 'recharts';

import { XAxisDefault, } from '../../utils/config';
import { createFieldOrDefault, } from '../../../../logic/object';


/**
 * Do not use it as component <XAxis {...config} /> but as a function XAxis(config).
 * Custom components does not work in Recharts.
 */
const XAxis = (config) => {
  const getField = createFieldOrDefault(config, XAxisDefault);

  return (
    <XReAxis
      hide={getField('hidden')}
      dataKey={getField('dataKey')}
    />
  );
};


export default XAxis;
