import React from 'react';

import StyledSvg from './styles/StyledSvg';


const Graph = ({ ...props }) => (
  <StyledSvg viewBox="0 0 122.88 81.14" {...props}>
    <path d="M4.73,48.37H16.35a1.35,1.35,0,0,1,1.34,1.35V79.8a1.34,1.34,0,0,1-1.34,1.34H4.73A1.35,1.35,0,0,1,3.38,79.8V49.72a1.36,1.36,0,0,1,1.35-1.35ZM116,17.59a6.86,6.86,0,1,1-6.84,7.22L91.44,12.36a6.88,6.88,0,0,1-4.09,1.34,6.81,6.81,0,0,1-4.24-1.47L68.23,23.55a6.54,6.54,0,0,1,.06.89,6.85,6.85,0,1,1-13.58-1.28l-15.18-11a6.87,6.87,0,0,1-8.44.1L13.7,24.68a6.86,6.86,0,1,1-2.16-5.23l16.88-12c0-.2,0-.39,0-.59a6.85,6.85,0,1,1,13.7,0c0,.14,0,.28,0,.41L57.82,18.62a6.86,6.86,0,0,1,3.62-1,6.78,6.78,0,0,1,3.91,1.23L80.52,7.27c0-.14,0-.28,0-.42a6.86,6.86,0,0,1,13.71,0,5.12,5.12,0,0,1,0,.69l17.09,12A6.8,6.8,0,0,1,116,17.59Zm-9.5,28h11.62A1.36,1.36,0,0,1,119.5,47V79.8a1.35,1.35,0,0,1-1.35,1.34H106.53a1.34,1.34,0,0,1-1.34-1.34V47a1.35,1.35,0,0,1,1.34-1.35ZM81.08,28.49H92.7a1.36,1.36,0,0,1,1.35,1.35v50a1.35,1.35,0,0,1-1.35,1.34H81.08a1.34,1.34,0,0,1-1.34-1.34v-50a1.34,1.34,0,0,1,1.34-1.35ZM55.63,45.61H67.25A1.35,1.35,0,0,1,68.59,47V79.8a1.34,1.34,0,0,1-1.34,1.34H55.63a1.34,1.34,0,0,1-1.34-1.34V47a1.35,1.35,0,0,1,1.34-1.35ZM30.18,28.49H41.8a1.35,1.35,0,0,1,1.34,1.35v50a1.34,1.34,0,0,1-1.34,1.34H30.18a1.35,1.35,0,0,1-1.35-1.34v-50a1.35,1.35,0,0,1,1.35-1.35Z"/>
  </StyledSvg>
);

export default Graph;
