import React from 'react';
import { func, object, string, } from 'prop-types';

import Card from '../../../../atoms/Card/Card';
import HeadingWihButtons from '../../../../atoms/HeadingWihButtons/HeadingWihButtons';
import Button from '../../../../atoms/Button/Button';
import VehicleGroupsTable from './VehicleGroupsTable';


const VehicleGroupsView = ({
  // data
  resources,
  translations,
  languageId,
  createModal,
  updateModal,
  deleteModal,
  // methods
  onToggleCreate,
  onToggleUpdate,
  onToggleDelete,
}) => (
  <Card>

    <HeadingWihButtons>
      <h2>{translations.common.vehicleGroups}</h2>
      {resources.directory_truck_write && (
        <Button
          onClick={onToggleCreate}
          color="success"
          size="sm"
        >
          {translations.directory.btnNewGroup}
        </Button>
      )}
    </HeadingWihButtons>


    <VehicleGroupsTable
      // data
      resources={resources}
      translations={translations}
      languageId={languageId}
      onToggleCreate={onToggleCreate}
      onToggleUpdate={onToggleUpdate}
      onToggleDelete={onToggleDelete}
      // methods
      createModal={createModal}
      updateModal={updateModal}
      deleteModal={deleteModal}
    />

  </Card>
);


VehicleGroupsView.propTypes = {
  resources: object.isRequired,
  translations: object.isRequired,
  languageId: string.isRequired,
  createModal: object.isRequired,
  updateModal: object.isRequired,
  deleteModal: object.isRequired,
  onToggleCreate: func.isRequired,
  onToggleUpdate: func.isRequired,
  onToggleDelete: func.isRequired,
};


export default VehicleGroupsView;
