import React from 'react';
import { string, } from 'prop-types';

import { QUERY_GATE_RECORDS, } from '../../gql/queries';
import FilteredTable from '../../../../components/FilteredTable/FilteredTable';
import TableLogic from './TableLogic';
import { setDateTodayMaxTime, setDateTodayMinTime, } from '../../../../logic/date';


const now = new Date();

const INIT_FILTER = {
  offset: 0,
  limit: 20,
  sortBy: '',
  order: '',
  params: {
    arrivalTimeFrom: setDateTodayMinTime(now),
    arrivalTimeTo: setDateTodayMaxTime(now),
    departureTimeFrom: setDateTodayMinTime(now),
    departureTimeTo: setDateTodayMaxTime(now),
    registrationNumber: '',
    gateVehicleGroupId: null,
  },
};


const TabHistory = ({
  languageId,
  ...rest
}) => (
  <FilteredTable
    initFilter={INIT_FILTER}
    query={QUERY_GATE_RECORDS}
    filterToVariables={(filter) => ({
      ...filter,
      languageId,
      section: 'history',
      params: {
        timeFrom: filter.params.departureTimeFrom
          ? setDateTodayMinTime(filter.params.departureTimeFrom)
          : null,
        timeTo: filter.params.departureTimeTo
          ? setDateTodayMaxTime(filter.params.departureTimeTo)
          : null,
        arrivalTimeFrom: filter.params.arrivalTimeFrom
          ? setDateTodayMinTime(filter.params.arrivalTimeFrom)
          : null,
        arrivalTimeTo: filter.params.arrivalTimeTo
          ? setDateTodayMaxTime(filter.params.arrivalTimeTo)
          : null,
        gateVehicleGroupId: filter.params.gateVehicleGroupId
          ? filter.params.gateVehicleGroupId.id
          : null,
        registrationNumber: filter.params.registrationNumber
          ? filter.params.registrationNumber
          : null,
      },
    })}
  >
    {(tableData) => (
      <TableLogic
        languageId={languageId}
        {...tableData}
        {...rest}
      />
    )}
  </FilteredTable>
);


TabHistory.propTypes = {
  languageId: string.isRequired,
};


export default TabHistory;
