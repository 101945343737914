import gql from 'graphql-tag';


export const QUERY_FILTER_RS_USERS = gql`
  query FilterRsUsers($offset: Int, $limit: Int) {
    filterRsUsers(offset: $offset, limit: $limit) {
      filter {
        offset
        limit
        count
      }
      rows {
        id
        email
        phoneNum
        companyId
        role
        active
        languageId
      }
    }  
  }
`;

export const QUERY_CONFIG_FOR_PLATFORM_BLOCKS = gql`
  query FetchConfigForPlatformBlocks {
    fetchConfigForPlatformBlocks {
      id
      name
      start
      end
    }
  }
`;

export const QUERY_BLOCKS_IN_MONTH = gql`
  query FetchBlocksInMonth($year: Int!, $month: Int!) {
    fetchBlocksInMonth(year: $year, month: $month) {
      platformBlocks {
        id
        platformId
        platformName
        from
        to
        definitionId
        note
        groupId
      }
      blockedDays {
        date
        fullyBlocked
      }
    }
  }
`;

export const QUERY_RESERVATIONS_BY_DAY = gql`
  query ReservationsFromRSByDay (
    $date: DateTime!, $languageId: ID, $limit: Int, $offset: Int, $sortBy: String,
    $order: String, $params: reservationsFromRSByDayFilterParamsInput
  ) {
    fetchReservationsFromRSByDay(
      date: $date, languageId: $languageId, limit: $limit, offset: $offset, sortBy: $sortBy,
      order: $order, params: $params
    ) {
      rows {
        id
        transportationType
        timeFrom
        timeTo
        driverPhoneNumber
        truckRegistrationNumber
      }
      filter {
        limit
        offset
        sortBy
        order
        count
        languageId
        params {
          id
        }
      }
    }
  }
`;

export const QUERY_FETCH_RS_USER = gql`
  query FetchRsUser($id: Int!) {
    fetchRsUser(id: $id) {
      id
      email
      phoneNum
      companyId
      role
      active
      languageId
    }
  }
`;

export const QUERY_TRANSPORTATION_DETAIL_ADMIN = gql`
  query fetchOneTransportation($id: ID, $languageId: ID) {
    fetchOneTransportation(id: $id, languageId: $languageId) {
      id
      reservation {
        resNo: no
        resTransportationType: transportationType {
          id
          name
        }
        resReservationType: reservationType {
          id
          name
        }
        resPlatform: platform {
          id
          name
        }
        resTimeFrom: timeFrom
        resTimeTo: timeTo
        resDate: date
      }
      company {
        compName: name
        compIdentificationNumber: identificationNumber
        compVatNumber: vatNumber
        compStreet: street
        compCity: city
        compZip: zip
        compState: state
        compContactPerson: contactPerson
        compEmail: email
        compPhoneNumber: phoneNumber
      }
      driver {
        driverName: name
        driverPhoneNumber: phoneNumber
      }
      truck {
        truckRegistrationNumber: registrationNumber
      }
      trailer {
        trailerRegistrationNumber: registrationNumber
      }
      prevTransport
      nextTransport
      orders
    }
    fetchTransportationActionsTabsConfiguration(languageId: $languageId, transportationId: $id) {
      actionId
      action
      genericFormId
      title
      completed
      start
      end
    }
  }
`;

export const QUERY_FETCH_ALL_RS_LANGUAGES = gql`
  query FetchAllRsLanguages {
    languages {
      rsLangs {
        id
        name
        code
      }
    }
  }
`;

export const QUERY_FETCH_TRANSPORTATIONS_BY_RS_USER = gql`
  query FetchTransportationsByRsUser($rsUserId: ID) {
    fetchTransportationsByRsUser(rsUserId: $rsUserId) {
      id
      state
    }
  }
`;
