/**
 * Get Hours and Minutes from string (user input)
 */
export const parseInputTime = (inputText, dateValue) => {
  const ret = {
    isValid: false,
    text: inputText,
    date: null,
  };

  try {
    let hasMeridianAm = false;
    let hasMeridianPm = false;
    let minutes = 0;
    let hours = 0;
    const time = inputText.trim();

    if (time === '') {
      return {
        ...ret,
        isValid: true,
      };
    }

    // basic regex
    const regValidInput = /^((\d{1,2}[\/\-:,._\s]\d{1,2})|(\d{1,4}))([\/\-:,._\s]?[aApP][mM]?)?$/; // eslint-disable-line no-useless-escape
    if (!regValidInput.test(time)) return ret;

    // meridian am/pm
    if (time.toLowerCase().indexOf('p') !== -1) { hasMeridianPm = true; }
    if (time.toLowerCase().indexOf('a') !== -1) { hasMeridianAm = true; }

    // TODO: REFACTOR this section
    // parse hours and minutes
    const divided = time.split(/[\/\-:,._\s]/); // eslint-disable-line no-useless-escape
    const divided0 = parseInt(divided[0], 10);
    const divided1 = parseInt(divided[1], 10);

    if (divided[0].length < 3) {
      hours = parseInt(divided[0], 10) || 0;
      minutes = divided1 || 0;
    } else if (divided[0].length < 4) {
      hours = parseInt(divided[0][0], 10) || 0;
      minutes = parseInt(`${divided[0][1]}${divided[0][2]}`, 10) || 0;
    } else {
      minutes = divided0 % 100;
      hours = Math.floor(divided0 / 100);
    }

    // check limits
    if (
      hours > 23
      || minutes > 59
      || ((hasMeridianAm || hasMeridianPm) && hours > 12)
    ) return ret;

    // handle overflow am/pm
    if (hasMeridianPm) hours = (hours + 12) % 24;

    const newDate = new Date(dateValue || new Date());
    newDate.setHours(hours);
    newDate.setMinutes(minutes);
    newDate.setSeconds(0);
    newDate.setMilliseconds(0);

    return {
      ...ret,
      isValid: true,
      date: newDate,
    };
  } catch {
    return ret;
  }
};
