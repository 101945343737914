import React from 'react';

import StyledSvg from '../styles/StyledSvg';


const Drivers = ({ ...props }) => (
  <StyledSvg viewBox="0 0 511.79 511.79" {...props}>
    <path d="M255.895,387.981c-10.876,0-19.724,8.848-19.724,19.723c0,10.876,8.848,19.724,19.724,19.724s19.724-8.848,19.724-19.724   C275.619,396.829,266.771,387.981,255.895,387.981z" />
    <path d="M255.895,302.55c-28.204,0-53.844,11.167-72.746,29.306l25.372,7.259l-17.458,53.589h17.426   c6.377-20.108,25.215-34.723,47.406-34.723s41.029,14.615,47.406,34.723h17.426l-17.458-53.589l25.381-7.262   C309.747,313.714,284.101,302.55,255.895,302.55z" />
    <path d="M208.489,422.704H181.29l-7.792,23.919l-16.308-2.672c13.134,35.655,45.057,62.296,83.705,67.84v-56.68   C225.522,450.235,213.364,438.077,208.489,422.704z" />
    <path d="M436.834,324.073l-42.181-104.327c-6.124-15.146-20.638-24.934-36.977-24.934H154.114   c-16.339,0-30.853,9.787-36.977,24.934L74.957,324.073c-5.457,13.499-1.646,28.875,9.482,38.263l57.81,48.766l10.698,1.754   l17.414-53.455l-15.561-4.451l-18.645,0.517l-0.832-29.988L149,325.098c24.742-31.944,63.455-52.548,106.895-52.548   c43.436,0,82.135,20.609,106.877,52.548l13.694,0.38l-0.832,29.988l-18.645-0.517l-15.561,4.451l17.414,53.455l10.698-1.754   l57.81-48.766C438.48,352.948,442.291,337.572,436.834,324.073z" />
    <path d="M330.5,422.704h-27.199c-4.875,15.373-17.033,27.531-32.406,32.406v56.679c38.646-5.544,70.568-32.184,83.701-67.838   l-16.304,2.672L330.5,422.704z" />
    <circle cx="255.895" cy="89.906" r="89.906" />
  </StyledSvg>
);

export default Drivers;
