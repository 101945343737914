import React from 'react';
import {
  func, arrayOf, shape, string, object, bool,
} from 'prop-types';
import { withTheme, } from 'styled-components';
import { Query, } from 'react-apollo';

import { QUERY_NOTIFICATIONS, } from '../../gql/queries';
import { parseDateToHuman, } from '../../../../logic/date';
import Table from '../../../../atoms/Table/Table';
import ButtonIcon from '../../../../atoms/Button/ButtonIcon';
import ButtonGrp from '../../../../atoms/Button/ButtonGrp';
import Badge from '../../../../atoms/Badge/Badge';
import Tooltip from '../../../../atoms/Tooltip/Tooltip';
import TableLoading from '../../../../atoms/Table/TableLoading';
import TableError from '../../../../atoms/Table/TableError';
import TableNoData from '../../../../atoms/Table/TableNoData';

import Bin from '../../../../styles/icons/Bin';
import Search from '../../../../styles/icons/Search';


const COL_COUNT = 6;


const NOTIFICATIONS_TYPES = {
  STAND: 'stand',
  TRANSPORTATION: 'transportation',
  PRINTER: 'printer',
};


const Header = ({ translations, }) => (
  <thead>
    <tr>

      <th>
        #
      </th>

      <th>
        {translations.monitoring.thNotificationType}
      </th>

      <th>
        {translations.monitoring.thType}
      </th>

      <th>
        {translations.monitoring.thInfo}
      </th>

      <th>
        {translations.monitoring.thTime}
      </th>

      <th className="table--text-align-right">
        {translations.monitoring.thActions}
      </th>

    </tr>
  </thead>
);


Header.propTypes = {
  translations: object.isRequired,
};


const getNotificationTypeText = (item, translations) => {
  const type = translations.monitoring[item.notificationType];

  if (item.notificationType === NOTIFICATIONS_TYPES.TRANSPORTATION) {
    return `${type} - ${item.transportation.no ? item.transportation.no : ''}`;
  }
  if (item.notificationType === NOTIFICATIONS_TYPES.STAND) {
    return `${type} - ${item.stand.name ? item.stand.name : ''}`;
  }
  if (item.notificationType === NOTIFICATIONS_TYPES.PRINTER) {
    return type;
  }
  return '-';
};


const Rows = ({
  // data
  theme,
  loading,
  error,
  data,
  translations,
  // methods
  toggleTransportationDetail,
  toggleRemove,
}) => {
  const NOTIFICATION_TYPE_COLOR = {
    stand: {
      color: 'white',
      background: '#3462ca',
    },
    transportation: {
      color: 'white',
      background: '#32ab63',
    },
    printer: {
      color: 'white',
      background: '#AB3232',
    },
  };

  const TYPE_COLOR = {
    delayedTransportationArrival: {
      color: theme.white,
      background: theme.error.t700,
    },
    delayedTransportationClearance: {
      color: theme.white,
      background: theme.warning.t700,
    },
    transportationStuck: {
      color: theme.white,
      background: theme.warning.t700,
    },
    help: {
      color: theme.white,
      background: theme.error.t700,
    },
    printerEmptyPaperError: {
      color: theme.white,
      background: theme.warning.t700,
    },
    printerError: {
      color: theme.white,
      background: theme.warning.t700,
    },
    printerOpenCaseError: {
      color: theme.white,
      background: theme.warning.t700,
    },
    printerAlmostEmptyPaperError: {
      color: theme.white,
      background: theme.warning.t700,
    },
    printerCutterError: {
      color: theme.white,
      background: theme.warning.t700,
    },
  };

  if (loading) {
    return (
      <TableLoading
        colsCount={COL_COUNT}
        rowsCount={data && data.notifications && data.fetchNotifications.length}
      />
    );
  }
  if (error || !data.fetchNotifications) {
    return (
      <TableError
        colsCount={COL_COUNT}
        error={error}
      />
    );
  }
  if (data.fetchNotifications.length < 1) {
    return (
      <TableNoData
        colsCount={COL_COUNT}
        text={translations.monitoring.notificationsEmpty}
      />
    );
  }

  return (
    <tbody>
      {data.fetchNotifications.map((item) => (
        <tr key={item.id}>

          <td>
            {item.id}
          </td>

          <td>
            <Badge
              customColor={NOTIFICATION_TYPE_COLOR[item.notificationType]}
            >
              {getNotificationTypeText(item, translations)}
            </Badge>
          </td>

          <td>
            <Badge
              customColor={TYPE_COLOR[item.type]}
            >
              {translations.monitoring.notificationTypes[item.type]}
            </Badge>
          </td>

          <td>
            {!item.info || item.info === '' ? '-' : item.info}
          </td>

          <td>
            {item.timestamp ? parseDateToHuman(new Date(item.timestamp)) : '-'}
          </td>

          <td
            className="table--text-align-right table--noPadding"
            style={{ minWidth: '5rem', }}
          >
            <ButtonGrp>
              {item.transportationId && (
                <Tooltip text={translations.common.detail}>
                  {(events) => (
                    <ButtonIcon
                      size="sm"
                      color="tertiary"
                      onClick={() => toggleTransportationDetail(item.transportationId)}
                      {...events}
                    >
                      <Search />
                    </ButtonIcon>
                  )}
                </Tooltip>
              )}
              <Tooltip text={translations.common.delete}>
                {(events) => (
                  <ButtonIcon
                    size="sm"
                    color="error"
                    onClick={() => toggleRemove(item.id)}
                    {...events}
                  >
                    <Bin />
                  </ButtonIcon>
                )}
              </Tooltip>
            </ButtonGrp>
          </td>

        </tr>
      ))}
    </tbody>
  );
};


Rows.propTypes = {
  loading: bool.isRequired,
  error: object,
  data: shape({
    fetchNotifications: arrayOf(shape({
      id: string.isRequired,
      type: string.isRequired,
      timestamp: string.isRequired,
      transportationId: string,
      standId: string,
    })),
  }),
  translations: object.isRequired,
  theme: shape({
    error: object.isRequired,
    warning: object.isRequired,
  }).isRequired,
  toggleTransportationDetail: func.isRequired,
  toggleRemove: func.isRequired,
};

Rows.defaultProps = {
  error: undefined,
  data: undefined,
};


const NotificationsTable = ({
  // data
  languageId,
  translations,
  theme,
  // methods
  toggleTransportationDetail,
  toggleRemove,
}) => (
  <Query
    query={QUERY_NOTIFICATIONS}
    variables={{ languageId, }}
  >
    {(tableData) => (
      <Table fillContent>
        <Header
          // data
          translations={translations}
        />
        <Rows
          // data
          theme={theme}
          {...tableData}
          translations={translations}
          // methods
          toggleTransportationDetail={toggleTransportationDetail}
          toggleRemove={toggleRemove}
        />
      </Table>
    )}
  </Query>
);


NotificationsTable.propTypes = {
  languageId: string.isRequired,
  translations: object.isRequired,
  toggleTransportationDetail: func.isRequired,
  toggleRemove: func.isRequired,
  theme: shape({
    error: object.isRequired,
    warning: object.isRequired,
  }).isRequired,
};


export default withTheme(NotificationsTable);
