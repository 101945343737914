import styled from 'styled-components';


const StyledDropdown = styled.div`
  position: relative;
  text-align: left;

  .dropdown--collapse {
    position: absolute;
    z-index: 10;
    top: 10px;
    ${(p) => p.position}: 0px;
    line-height: 1.5;

    background: ${(p) => p.theme.white};
    box-shadow: ${(p) => p.theme.common.shadow};
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .dropdown--loader {
    text-align: center;
    color: ${(p) => p.theme.grey.t400};
    cursor: default;
  }

  .dropdown--error {
    text-align: center;
    color: ${(p) => p.theme.error.t700};
    cursor: default;
  }


  .appear-dropdown-enter {
    opacity: 0;
    margin-top: -15px;
  }

  .appear-dropdown-enter-active {
    opacity: 1;
    margin-top: 0px;
    transition: all 350ms ease-in;
  }

  .appear-dropdown-exit {
    opacity: 1;
    margin-top: 0px;
  }

  .appear-dropdown-exit-active {
    opacity: 0;
    margin-top: -15px;
  }
`;


export default StyledDropdown;
