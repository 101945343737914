import { GATEHOUSE_TYPE_EAST, GATEHOUSE_TYPE_WEST, } from '../utils';

export const getGateTypesByGatehouseType = (gatehouseType) => {
  const eastTypes = [
    { id: 'entrance_east', translationKey: 'gateTypeEntrance', },
    { id: 'exit_east', translationKey: 'gateTypeExit', },
  ];
  const westTypes = [
    { id: 'entrance', translationKey: 'gateTypeEntrance', },
    { id: 'exit', translationKey: 'gateTypeExit', },
  ];

  if (gatehouseType === GATEHOUSE_TYPE_EAST) {
    return eastTypes;
  }
  if (gatehouseType === GATEHOUSE_TYPE_WEST) {
    return westTypes;
  }

  return [
    ...eastTypes,
    ...westTypes,
  ];
};

export const gateTypeToTranslationKey = (id) => {
  const gateType = getGateTypesByGatehouseType().find((d) => d.id === id);
  return gateType ? gateType.translationKey : '';
};
