import React from 'react';
import {
  arrayOf, number, shape, node, oneOfType,
} from 'prop-types';


const DashedTimeline = ({ params, children, }) => {
  const { hourWidth, hours, } = params;

  const dashedLines = [];

  for (let i = 0; i < hours - 1; i++) {
    dashedLines.push(
      <div
        key={i}
        className="timeline--dashed-column"
        style={{ left: `${i * hourWidth}rem`, }}
      />
    );
  }

  return (
    <div className="timeline--dashed">
      {dashedLines}
      {children}
    </div>
  );
};


DashedTimeline.propTypes = {
  params: shape({
    hours: number.isRequired,
    hourWidth: number.isRequired,
  }).isRequired,
  children: oneOfType([
    arrayOf(node),
    node,
  ]).isRequired,
};


export default DashedTimeline;
