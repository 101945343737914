import styled from 'styled-components';

import { getColorBtn, getColorBtnOutline, getColorBtnTransparent, } from './utils';


/**
 * Button
 *  - color: 'grey'
 *  - outline: false
 *  - size: 'md'
 *  - round: false
 *  - transparent: false
 */


const getButtonStyle = ({
  outline, transparent, theme, ...rest
}) => {
  if (transparent) {
    return `
      ${getColorBtnTransparent({ theme, ...rest, })}
      text-decoration: underline;
      font-weight: bold;
      padding-left: 0;
      padding-right: 0;

      &:disabled {
        cursor: default;
        color: ${theme.grey.t400};
      }
    `;
  }
  if (outline) {
    return `
      ${getColorBtnOutline({ theme, ...rest, })}

      &:disabled {
        cursor: default;
        color: ${theme.white};
        background: ${theme.grey.t400};
        border-color: transparent;
      }
    `;
  }
  return `
    ${getColorBtn({ theme, ...rest, })}

    &:disabled {
      cursor: default;
      color: ${theme.white};
      background: ${theme.grey.t400};
      border-color: transparent;
    }
  `;
};


const getSizeBtn = ({ size, }) => {
  const sizes = {
    xs: `
      font-size: 0.6rem;
    `,
    sm: `
      font-size: 0.75rem;
    `,
    md: `
      font-size: 0.875rem;
      `,
    lg: `
      font-size: 1rem;
    `,
  };

  if (Object.prototype.hasOwnProperty.call(sizes, size)) {
    return sizes[size];
  }
  return sizes.md;
};


const ButtonIcon = styled.button`
  margin: 0;
  box-sizing: border-box;
  display: inline-block;
  text-align: center;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  outline: 0;
  outline-offset: 0;
  user-select: none;
  cursor: pointer;

  text-transform: none;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.5em 0.9em;
  ${(p) => p.paddingHalf && `
    padding: 0.25em 0.45em;
  `}

  border-radius: ${(p) => p.theme.input.borderRadius}; 
  border-width: 1px;
  border-style: solid;
  border-color: transparent;


  ${(p) => p.round && `
    border-radius: 3rem;
  `}

  ${getSizeBtn}
  ${getButtonStyle}
  ${(p) => p.loadingWidth && `
    width: ${p.loadingWidth}px;
  `}
`;


export default ButtonIcon;
