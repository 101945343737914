import { INPUT_TYPE, } from 'sofair-form-validation';
import { initForm, initInput, } from '../../../../logic/form/common';


export const initStandCallForm = {
  ...initForm,
  form: {
    platform: initInput({
      name: 'platform',
      type: INPUT_TYPE.SELECT,
      validation: {
        required: true,
        max: 255,
      },
    }),
  },
  values: {
    platform: null,
  },
};
