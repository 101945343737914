// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
import React from 'react';
import { arrayOf, object, } from 'prop-types';

import ReportRow from './ReportRow';
import ReportColumn from './ReportColumn';
import LineChart from './LineChart';
import BarChart from './BarChart';
import ReportTable from './ReportTable';


const ReportComponent = ({ config, data, }) => {
  if (config.componentType === 'ROW') {
    return <ReportRow config={config} data={data} />;
  }
  if (config.componentType === 'COLUMN') {
    return <ReportColumn config={config} data={data} />;
  }
  if (config.componentType === 'LINE_CHART') {
    return <LineChart config={config} data={data} />;
  }
  if (config.componentType === 'BAR_CHART') {
    return <BarChart config={config} data={data} />;
  }
  if (config.componentType === 'TABLE') {
    return <ReportTable config={config} data={data} />;
  }

  return null;
};

ReportComponent.propTypes = {
  config: object.isRequired,
  data: arrayOf(object.isRequired).isRequired,
};


export default ReportComponent;
