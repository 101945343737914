import React from 'react';
import { shape, func, object, } from 'prop-types';

import { ADR_ATTACHMENTS, ATTACHMENTS, } from '../utils';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import { parseDateToHuman, } from '../../../../logic/date';
import InputCheckbox from '../../../../atoms/InputCheckbox/InputCheckbox';
import Input from '../../../../atoms/Input/Input';


const { REACT_APP_REST, } = process.env;

const getLastPart = (url) => {
  const parts = url.split('/');
  return parts[parts.length - 1];
};

export const escapeForUrl = (str) => str
  .replace(/\//g, '%2F')
  .replace(/&/g, '%26');

const renderDate = (values, attachment) => {
  const value = values[`${attachment.replace('File', '')}Date`];
  return value ? parseDateToHuman(new Date(value), false) : '-';
};

const renderNo = (values, attachment) => {
  const value = values[`${attachment.replace('File', '')}No`];
  return value || '-';
};

const downloadFile = async (filename) => {
  const result = await fetch(
    `${REACT_APP_REST}/file?filename=${escapeForUrl(filename)}`,
    { method: 'GET', },
  );
  const res = await result.blob();
  const aElement = document.createElement('a');
  aElement.setAttribute('download', getLastPart(filename));
  const href = URL.createObjectURL(res);
  aElement.href = href;
  aElement.setAttribute('target', '_blank');
  aElement.click();
  URL.revokeObjectURL(href);
};

const renderLink = (values, attachment) => (
  <span onClick={() => downloadFile(values[attachment])} style={{ textDecoration: 'underline', cursor: 'pointer', }}>
    {getLastPart(values[attachment])}
  </span>
);

const TransportationDocumentEditForm = ({
  translations,
  values,
  form,
  onChange,
}) => <>

  <div>
    {ATTACHMENTS.map((attachment) => (
        <>
          <Row style={{ marginTop: '20px', }}>
            <Col MD={6} style={{ textAlign: 'right', }}>{translations.form.attachments[attachment]}</Col>
            <Col MD={18}>
              {values[attachment] && (renderLink(values, attachment))}
              {!values[attachment] && (<>-</>)}
            </Col>
          </Row>
          {!!onChange && (
            <div style={{ marginTop: '5px', }}>
              <Row>
                <Col MD={6} />
                <Col MD={18}>
                  <InputCheckbox
                    text={translations.common.overwrite}
                    value={values[`${attachment}Checkbox`]}
                    onChange={(newValue) => onChange(form[`${attachment}Checkbox`].name, newValue)}
                  />
                </Col>
              </Row>
              {values[`${attachment}Checkbox`] && (
              <Row>
                <Col MD={6} />
                <Col MD={3} style={{ display: 'flex', alignItems: 'center', }}>
                  {translations.form.file}
:
                </Col>
                <Col MD={15}>
                  <Input
                    type="file"
                    onChange={(e) => onChange(form[`${attachment}File`].name, e.target.files[0])}
                    status={form[`${attachment}File`].status}
                    style={{ fontSize: '12px', }}
                  />
                </Col>
              </Row>
              )}
            </div>
          )}

          {ADR_ATTACHMENTS.indexOf(attachment) !== -1 && (
            <>
              <Row>
                <Col MD={6} />
                <Col MD={18}>
                  {`${translations.form.certificateNumber}: `}
                  {renderNo(values, attachment)}
                </Col>
              </Row>
              <Row>
                <Col MD={6} />
                <Col MD={18}>
                  {`${translations.form.expirationDate}: `}
                  {renderDate(values, attachment)}
                </Col>
              </Row>
            </>
          )}
        </>
    ))}

  </div>

  </>;


TransportationDocumentEditForm.propTypes = {
  translations: object.isRequired,
  values: shape({}).isRequired,
  onChange: func,
  form: object,
};


export default TransportationDocumentEditForm;
