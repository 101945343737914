import { INPUT_TYPE, } from 'sofair-form-validation';
import { initForm, initInput, } from '../../../../logic/form/common';
import { regexPhone, } from '../../../../logic/form/regexes';


export const initSmsSettingsForm = {
  detailForm: {
    ...initForm,
    form: {
      recipient: initInput({
        name: 'recipient',
        validation: {
          required: true,
          regex: regexPhone,
        },
      }),
      type: initInput({
        name: 'type',
        type: INPUT_TYPE.SELECT,
        validation: {
          required: true,
        },
      }),
      transportationType: initInput({
        name: 'transportationType',
        type: INPUT_TYPE.SELECT,
        validation: {
          required: true,
        },
      }),
      platform: initInput({
        name: 'platform',
        type: INPUT_TYPE.SELECT,
        validation: {
          required: false,
        },
      }),
    },
    values: {
      recipient: '',
      type: null,
      transportationType: null,
      platform: null,
    },
  },
};
