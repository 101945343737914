import React, { Fragment, } from 'react';
import {
  string, object, func, shape, arrayOf, bool,
} from 'prop-types';
import { Query, } from 'react-apollo';

import { QUERY_DD_TRANSPORTATION_TYPES, } from '../../../gql/queries';
import {
  parseDateToHuman,
} from '../../../../../logic/date';
import Input from '../../../../../atoms/Input/Input';
import InputSelect from '../../../../../atoms/InputSelect/InputSelect';
import InputDateSelect from '../../../../../atoms/InputDateSelect/InputDateSelect';
import Table from '../../../../../atoms/Table/Table';
import TableLoading from '../../../../../atoms/Table/TableLoading';
import TableError from '../../../../../atoms/Table/TableError';
import TableNoData from '../../../../../atoms/Table/TableNoData';
import Tooltip from '../../../../../atoms/Tooltip/Tooltip';
import ButtonIcon from '../../../../../atoms/Button/ButtonIcon';
import ButtonGrp from '../../../../../atoms/Button/ButtonGrp';
import THSortable from '../../../../../atoms/Table/THSortable';

import Search from '../../../../../styles/icons/Search';


const COL_COUNT = 7;


const Header = ({
  // data
  filter,
  translations,
  languageId,
  // methods
  onChangeSort,
  onChangeParam,
}) => (
  <thead>

    <tr>
      <THSortable
        title="#"
        name="id"
        filter={filter}
        style={{ minWidth: '4rem', width: '4rem', }}
        onSort={onChangeSort}
      />

      <THSortable
        title={translations.form.no}
        name="no"
        isActiveFilter={filter.params.no !== ''}
        filter={filter}
        style={{ minWidth: '10rem', width: '10rem', }}
        onSort={onChangeSort}
      />

      <THSortable
        title={translations.form.transportationType}
        name="transportationTypeName"
        isActiveFilter={!!filter.params.transportationType}
        filter={filter}
        style={{ minWidth: '10rem', width: '10rem', }}
        onSort={onChangeSort}
      />

      <THSortable
        title={translations.form.truckRegistrationNo}
        name="truckRegistrationNumber"
        isActiveFilter={filter.params.truckRegistrationNumber !== ''}
        filter={filter}
        style={{ minWidth: '7rem', width: '7rem', }}
        onSort={onChangeSort}
      />

      <THSortable
        title={translations.form.carrier}
        name="companyName"
        isActiveFilter={filter.params.companyName !== ''}
        filter={filter}
        style={{ minWidth: '10rem', width: '10rem', }}
        onSort={onChangeSort}
      />

      <THSortable
        title={translations.form.date}
        name="date"
        isActiveFilter={!!filter.params.from || !!filter.params.to}
        filter={filter}
        style={{ minWidth: '8rem', width: '8rem', }}
        onSort={onChangeSort}
      />

      <th
        className="table--header-title table--text-align-right"
        style={{ minWidth: '4rem', width: '4rem', }}
      >
        {translations.transportation.thActions}
      </th>
    </tr>

    <tr>
      <th />

      <th>
        <Input
          type="text"
          placeholder={translations.common.filter}
          autoComplete="off"
          value={filter.params.no}
          onChange={(e) => onChangeParam('no', e.target.value)}
          onClear={() => onChangeParam('no', '')}
          size="sm"
          active={filter.params.no !== ''}
        />
      </th>

      <th>
        <Query
          query={QUERY_DD_TRANSPORTATION_TYPES}
          variables={{
            languageId,
          }}
        >
          {(dropdownData) => (
            <InputSelect
              value={filter.params.transportationType}
              options={(dropdownData.data && dropdownData.data.fetchAllTransportationTypes)
                ? dropdownData.data.fetchAllTransportationTypes
                : []
              }
              active={!!filter.params.transportationType}
              onChange={(option) => onChangeParam('transportationType', option, false)}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              isClearable
              isLoading={dropdownData.loading}
              error={dropdownData.error}
              placeholder={translations.common.filter}
              size="sm"
            />
          )}
        </Query>
      </th>

      <th>
        <Input
          type="text"
          placeholder={translations.common.filter}
          autoComplete="off"
          value={filter.params.truckRegistrationNumber}
          onChange={(e) => onChangeParam('truckRegistrationNumber', e.target.value)}
          onClear={() => onChangeParam('truckRegistrationNumber', '')}
          size="sm"
          active={filter.params.truckRegistrationNumber !== ''}
        />
      </th>

      <th>
        <Input
          type="text"
          placeholder={translations.common.filter}
          autoComplete="off"
          value={filter.params.companyName}
          onChange={(e) => onChangeParam('companyName', e.target.value)}
          onClear={() => onChangeParam('companyName', '')}
          size="sm"
          active={filter.params.companyName !== ''}
        />
      </th>

      <th>
        <InputDateSelect
          placeholder={translations.common.from}
          size="sm"
          value={filter.params.from}
          onChange={(newDate) => onChangeParam('from', newDate, false)}
          active={!!filter.params.from}
          clearable
        />
      </th>

      <th />

    </tr>

    <tr>

      <th />

      <th />

      <th />

      <th />

      <th />

      <th>
        <InputDateSelect
          placeholder={translations.common.to}
          size="sm"
          value={filter.params.to}
          onChange={(newDate) => onChangeParam('to', newDate, false)}
          active={!!filter.params.to}
          clearable
        />
      </th>

      <th />

    </tr>

  </thead>
);


Header.propTypes = {
  // data
  filter: object.isRequired,
  translations: object.isRequired,
  languageId: string.isRequired,
  // methods
  onChangeSort: func.isRequired,
  onChangeParam: func.isRequired,
};


const Rows = ({
  // data
  loading,
  error,
  data,
  translations,
  // methods
  onEdit,
}) => {
  if (!data.filterTransportationFuture && loading) {
    return (
      <TableLoading
        colsCount={COL_COUNT}
        rowsCount={
          data && data.filterTransportationFuture && data.filterTransportationFuture.rows.length
        }
      />
    );
  }
  if (error || !data.filterTransportationFuture) {
    return (
      <TableError
        colsCount={COL_COUNT}
        error={error}
      />
    );
  }
  if (data.filterTransportationFuture.rows.length < 1) {
    return (
      <TableNoData
        colsCount={COL_COUNT}
        text={translations.transportation.scheduleEmpty}
      />
    );
  }

  return (
    <tbody>
      {data.filterTransportationFuture.rows.map((item) => (
        <tr key={item.id}>
          <td>
            {item.id}
          </td>
          <td>
            {item.no}
          </td>
          <td>
            {item.transportationTypeName}
          </td>
          <td>
            {item.truckRegistrationNumber}
          </td>
          <td>
            {item.companyName}
          </td>
          <td>
            {item.date ? parseDateToHuman(new Date(item.date), false) : '-'}
          </td>
          <td className="table--text-align-right table--noPadding">
            <ButtonGrp>
              <Tooltip text={translations.common.detail}>
                {(events) => (
                  <ButtonIcon
                    size="sm"
                    color="tertiary"
                    onClick={() => onEdit(item.id)}
                    {...events}
                  >
                    <Search />
                  </ButtonIcon>
                )}
              </Tooltip>
            </ButtonGrp>
          </td>
        </tr>
      ))}
    </tbody>
  );
};


Rows.propTypes = {
  loading: bool.isRequired,
  error: object,
  data: shape({
    filterTransportationFuture: shape({
      rows: arrayOf(shape({
        id: string.isRequired,
        no: string,
        transportationTypeName: string,
        truckRegistrationNumber: string,
        arrivalTime: string,
        companyName: string,
      })).isRequired,
    }),
  }),
  translations: object.isRequired,
  onEdit: func.isRequired,
};

Rows.defaultProps = {
  error: undefined,
  data: undefined,
};


const TabTable = ({
  // data
  filter,
  queryData,
  translations,
  languageId,
  // methods
  onEdit,
  onChangeSort,
  onChangeParam,
}) => (
  <Fragment>

    <Table fillContent>
      <Header
        // data
        filter={filter}
        translations={translations}
        languageId={languageId}
        // methods
        onChangeSort={onChangeSort}
        onChangeParam={onChangeParam}
      />
      <Rows
        // data
        {...queryData}
        translations={translations}
        // methods
        onEdit={onEdit}
      />
    </Table>

  </Fragment>
);


TabTable.propTypes = {
  filter: shape({
    params: object.isRequired,
  }).isRequired,
  queryData: shape({
    loading: bool.isRequired,
    error: object,
    data: object,
  }).isRequired,
  translations: object.isRequired,
  languageId: string.isRequired,
  onEdit: func.isRequired,
  onChangeSort: func.isRequired,
  onChangeParam: func.isRequired,
};


export default TabTable;
