import { v4, } from 'uuid';

import { roundToLowerHour, } from '../../logic/date';

// TODO rewrite parseData.js and merge with parseDataV2.js


const timeDiff = (t1, t2) => (t1 - t2);
const hoursDiff = (t1, t2) => (timeDiff(t1, t2) / (1000 * 60 * 60));


const parseItem = (item, fromTimeline, hours, transportationTypeId) => {
  if (!item.from || !item.to) return null;

  let isActive = true;
  if (transportationTypeId) {
    if (
      !item.transportationTypeId
      || item.transportationTypeId.toString() !== transportationTypeId.toString()
    ) isActive = false;
  }

  let from = hoursDiff(new Date(item.from), fromTimeline);
  let to = hoursDiff(new Date(item.to), fromTimeline);
  let diff = to - from;
  let overFlowLeft = false;
  let overFlowRight = false;

  if (diff <= 0) return null;

  // start before timeline
  if (from < 0) {
    diff = to;
    from = 0;
    overFlowLeft = true;
  }

  // end after timeline
  if (to > hours) {
    to = hours;
    diff = hours - from;
    overFlowRight = true;
  }

  return {
    id: v4(),
    from,
    to,
    overFlowLeft,
    overFlowRight,
    width: diff,
    payload: {
      ...item,
      isActive,
    },
  };
};


const createNewRow = () => ({
  id: v4(),
  items: [],
});


const compare = (a, b) => {
  if (a.from < b.from) {
    return -1;
  }
  if (a.from > b.from) {
    return 1;
  }

  return 0;
};


/**
 * Parse Rows
 * 1. parse row items to timeline objects
 * 2. sort row items
 * 3. add them to timeline row
 * @param {ArrayOf(Object)} arrayOfItems - Row
 * @param {Number} from - when timeline starts
 * @param {Number} hours - count of timeline hours
 */
const parseRows = (arrayOfItems, from, hours, transportationTypeId) => {
  const parsedItems = [];

  // 1. parse row items to timeline objects
  for (let j = 0; j < arrayOfItems.length; j++) {
    const parsedItem = parseItem(arrayOfItems[j], from, hours, transportationTypeId);
    if (parsedItem) parsedItems.push(parsedItem);
  }

  // 2. sort row items
  const items = parsedItems.sort(compare);
  const rows = [];

  // 3. add them to timeline row
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    let added = false;

    for (let j = 0; j < rows.length; j++) {
      const actualRow = rows[j];

      const lastRowItem = actualRow.items[actualRow.items.length - 1];
      if (lastRowItem.to <= item.from) {
        actualRow.items.push(item);
        added = true;
        break;
      }
    }
    if (!added) {
      const newRow = createNewRow();
      newRow.items.push(item);
      rows.push(newRow);
    }
  }

  return rows;
};


const parseFilter = (filter) => {
  const from = new Date(filter.from);
  const to = new Date(filter.to);
  const diff = timeDiff(to, from);
  const hours = Math.ceil(diff / (1000 * 60 * 60));

  if (diff < 0) throw new Error('invalid from, to');

  return {
    from: roundToLowerHour(from),
    to,
    hours,
  };
};


const parseSections = (data, from, hours, transportationTypeId) => {
  const sections = [];
  for (let i = 0; i < data.length; i++) {
    const { reservation, ...rest } = data[i];
    const newSection = {
      id: v4(),
      payload: rest,
      rows: parseRows(reservation === null ? [] : reservation, from, hours, transportationTypeId),
    };

    sections.push(newSection);
  }
  return sections;
};


/**
 * Parse data by sections
 * @param {Object} { filter, sections, }
 */
export const parseBySections = ({
  filter,
  platforms = [],
}) => {
  const { transportationTypeId, } = filter;
  const { from, to, hours, } = parseFilter(filter);
  const sectionsParsed = parseSections(platforms, from, hours, transportationTypeId);

  return {
    from,
    to,
    hours,
    sections: sectionsParsed,
  };
};


/**
 * Parse data one section
 * @param {Object} { filter, sections, transportation, }
 */
export const mergeRowsAndReservations = ({
  filter,
  platforms = [],
  reservation = [],
}) => (
  parseBySections({
    filter,
    platforms: [
      {
        platforms,
        reservation,
      },
    ],
  })
);
