import React from 'react';
import { Query, } from 'react-apollo';

import { APP_LANGUAGE_ID, } from '../../modules/Main/gql/queries';


export const withTranslations = (query) => (Component) => (props) => (
  <Query query={APP_LANGUAGE_ID}>
    {({ data: { languageId, }, }) => (
      <Query
        query={query}
        variables={{ languageId: languageId === null ? undefined : languageId, }}
        fetchPolicy="cache-only"
      >
        {({ data, }) => (
          <Component
            {...props}
            language={(data && data.dictionary) ? data.dictionary.language : undefined}
            languageId={languageId}
            translationsCommon={(
              data
              && data.dictionary
              && data.dictionary.dictionary)
              ? data.dictionary.dictionary.shared
              : undefined
            }
            translations={(
              data
              && data.dictionary
              && data.dictionary.dictionary)
              ? data.dictionary.dictionary.centralApp
              : undefined
            }
          />
        )}
      </Query>
    )}
  </Query>
);
