import React, { Component, } from 'react';
import {
  func, string, object, objectOf,
} from 'prop-types';
import { Query, } from 'react-apollo';

import { ORDER, } from '../../globals';


export const PARAMS_TYPES = {
  TEXT: 'text',
  SELECT: 'select',
  DATE: 'date',
  TIME: 'time',
};

export const VALUE_TYPES = {
  TEXT: 'text',
  DATE: 'date',
  DATETIME: 'dateTime',
};


class FilteredTableLocal extends Component {
  constructor(props) {
    super(props);

    this.filterTimer = null;

    this.state = this.getInitState(props);
  }


  componentWillUnmount() {
    this.clearFilterTimeout();
  }


  getInitState = (props) => {
    const { filter, } = props;
    return {
      filter,
      rowsFilter: filter,
      count: 0,
      rows: [],
      filteredRows: [],
    };
  }


  handleSubscribe = (subscribeToMore, subscribeVariables) => {
    const { getRowsSubscription, } = this.props;
    const {
      document,
      variables,
    } = subscribeVariables;
    subscribeToMore({
      document,
      variables,
      updateQuery: (prev, { subscriptionData, }) => {
        if (!subscriptionData.data) return null;
        const newData = getRowsSubscription(subscriptionData.data);
        if (newData) this.handleAfterReceivedData(newData);
        return null;
      },
    });
  }


  handleFetchedNewData = (data) => {
    const { getRows, } = this.props;

    const receivedRows = getRows(data);
    this.handleAfterReceivedData(receivedRows);
  }


  handleAfterReceivedData = (receivedRows) => {
    const { filter, } = this.state;
    const { parseRows, } = this.props;

    if (!receivedRows) return;
    const parsedRow = parseRows(receivedRows);
    this.setState({
      rows: parsedRow,
      filteredRows: this.applyOrder(
        this.applyFilter(parsedRow, filter.params),
        filter.sortBy,
        filter.order
      ),
      count: parsedRow.length,
    });
  }


  handleErrorNewData = () => {
    this.setState({
      ...(this.getInitState(this.props)),
    });
  }


  /* *********** */
  /* Change Rows */
  /*  - sort     */
  /*  - filter   */
  /* *********** */

  applyOrder = (rows, name, order) => {
    const { valueTypes, } = this.props;

    return rows.sort((a, b) => {
      const undefA = a[name] === null || a[name] === undefined;
      const undefB = b[name] === null || b[name] === undefined;

      if (undefA && undefB) return 1;
      if (undefA) return order === ORDER.ASC ? -1 : 1;
      if (undefB) return order === ORDER.ASC ? 1 : -1;

      switch (valueTypes[name]) {
        case VALUE_TYPES.TEXT: {
          if (order === ORDER.ASC) return (a[name].toLowerCase() > b[name].toLowerCase()) ? 1 : -1;
          return (a[name].toLowerCase() < b[name].toLowerCase()) ? 1 : -1;
        }

        default: {
          if (order === ORDER.ASC) return (a[name] > b[name]) ? 1 : -1;
          return (a[name] < b[name]) ? 1 : -1;
        }
      }
    });
  }

  applyFilter = (rows, params) => {
    const ret = [];
    const paramKeys = Object.keys(params);

    for (let r = 0; r < rows.length; r++) {
      const row = rows[r];
      let isValid = true;

      for (let p = 0; p < paramKeys.length; p++) {
        const param = paramKeys[p];
        const {
          value, type, from, to,
        } = params[param];

        switch (type) {
          case PARAMS_TYPES.TEXT: {
            if (value === '') break;
            if (row[param] === null
              || row[param] === undefined
              || !row[param].toLowerCase().includes(value.toLowerCase())) isValid = false;
            break;
          }

          case PARAMS_TYPES.SELECT: {
            if (value === null) break;
            if (row[param] === null
              || row[param] === undefined
              || row[param] !== value.name) isValid = false;
            break;
          }

          case PARAMS_TYPES.DATE: {
            if (from && (!row[param] || row[param] < from)) {
              isValid = false;
            }
            if (to && (!row[param] || row[param] > to)) {
              isValid = false;
            }
            break;
          }

          default:
            break;
        }
        if (!isValid) break;
      }

      if (isValid) ret.push(row);
    }

    return ret;
  }


  /* ******** */
  /* Handlers */
  /* ******** */

  handleChangeSort = (name) => {
    const { filter, filteredRows, } = this.state;

    // init order
    let newOrder = ORDER.ASC;
    if (filter.sortBy === name && filter.order === ORDER.ASC) {
      newOrder = ORDER.DESC;
    }
    const newFilter = {
      ...filter,
      sortBy: name,
      order: newOrder,
    };

    // rowsFilter
    if (this.isTimeoutActive()) {
      this.updateFilterRows(newFilter);
    // filter - set offset
    } else {
      this.setState({
        filter: newFilter,
        rowsFilter: newFilter,
        filteredRows: this.applyOrder(filteredRows, name, newOrder),
      });
    }
  }

  handleChangeFilter = (name, value) => {
    const { filter, } = this.state;
    const newFilter = {
      ...filter,
      [name]: value,
    };

    // rowsFilter
    if (this.isTimeoutActive()) {
      this.updateFilterRows(newFilter);
    // filter - set offset
    } else {
      this.setState({
        filter: newFilter,
        rowsFilter: newFilter,
      });
    }
  }

  handleChangeParam = (name, atr, value, isDelayed = true) => {
    const { filter, } = this.state;

    const newFilter = {
      ...filter,
      offset: 0,
      params: {
        ...filter.params,
        [name]: {
          ...filter.params[name],
          [atr]: value,
        },
      },
    };

    if (isDelayed) {
      this.setState({
        filter: newFilter,
      });
      this.startFilterTimeout();
    } else {
      this.updateFilterRows(newFilter);
    }
  }


  /**
   * Update Filters
   */
  updateFilterRows = (receivedFilter) => {
    const { rows, } = this.state;
    this.clearFilterTimeout();

    const parsedRows = this.applyFilter(rows, receivedFilter.params);

    this.setState({
      filter: receivedFilter,
      rowsFilter: receivedFilter,
      count: parsedRows.length,
      filteredRows: parsedRows,
    });
  }


  /* ******************** */
  /* Filter Timeout utils */
  /* ******************** */

  filterTimeoutDone = () => {
    const { filter, } = this.state;
    this.filterTimer = null;
    this.updateFilterRows(filter);
  }

  isTimeoutActive = () => this.filterTimer !== null;

  startFilterTimeout = () => {
    this.clearFilterTimeout();

    this.filterTimer = setTimeout(this.filterTimeoutDone, 750);
  }

  clearFilterTimeout = () => {
    if (this.filterTimer !== null) {
      clearTimeout(this.filterTimer);
      this.filterTimer = null;
    }
  }


  /* ***** */
  /* Utils */
  /* ***** */

  getActualPageRows = () => {
    const {
      filteredRows,
      filter: {
        offset,
        limit,
      },
    } = this.state;

    return filteredRows.slice(offset, offset + limit);
  }


  render() {
    const { filter, rowsFilter, count, } = this.state;
    const { query, variables, children, } = this.props;

    return (
      <Query
        query={query}
        variables={variables}
        onCompleted={this.handleFetchedNewData}
        onError={this.handleErrorNewData}
        fetchPolicy="no-cache"
      >
        {({ loading, error, subscribeToMore, }) => children({
          // data
          rows: this.getActualPageRows(),
          loading,
          error,
          filter,
          rowsFilter: {
            ...rowsFilter,
            count,
          },
          /**
           * Change filter
           */
          onChangeFilter: this.handleChangeFilter,
          onChangeSort: this.handleChangeSort,
          onChangeParam: this.handleChangeParam,
          /**
           * Subscribe
           *  - subscribeToMore
           *  - subscribeVariables: variables, document
           */
          subscribeToMore: (subscribeVariables) => this.handleSubscribe(
            subscribeToMore,
            subscribeVariables
          ),
        })}
      </Query>
    );
  }
}


FilteredTableLocal.propTypes = {
  /**
   * Query
   *  - query
   *  - variables
   *  - getRows: parse data from query
   */
  query: object.isRequired,
  variables: object,
  getRows: func.isRequired,
  /**
   * Subscription
   *  - getRowsSubscription: parse data from subscription
   */
  getRowsSubscription: func.isRequired,
  /**
   * Parse data
   *  - parseRows: after received data from query or subscription is applied this parser
   *  - valueTypes: types of order filters
   */
  parseRows: func,
  valueTypes: objectOf(
    string,
  ).isRequired,
  /**
   * Children
   */
  children: func.isRequired,
};

FilteredTableLocal.defaultProps = {
  variables: undefined,
  parseRows: (row) => row,
};


export default FilteredTableLocal;
