import React, { Component, Fragment, } from 'react';
import { object, } from 'prop-types';

import RoleCreate from '../RoleCreate/RoleCreate';
import RoleUpdate from '../RoleUpdate/RoleUpdate';
import RoleDelete from '../RoleDelete/RoleDelete';
import RolesView from './RolesView';


class Roles extends Component {
  state = {
    detailModal: {
      isOpen: false,
      id: null,
    },
    deleteModal: {
      isOpen: false,
      id: null,
    },
    createModal: {
      isOpen: false,
    },
  }


  handleToggleDetailModal = (id = null) => {
    this.setState((prevState) => ({
      detailModal: {
        ...prevState.detailModal,
        isOpen: !prevState.detailModal.isOpen,
        id,
      },
    }));
  }


  handleToggleDeleteModal = (id = null) => {
    const { resources, } = this.props;

    if (resources.settings_users) {
      this.setState((prevState) => ({
        deleteModal: {
          ...prevState.deleteModal,
          isOpen: !prevState.deleteModal.isOpen,
          id,
        },
      }));
    }
  }


  handleToggleCreateModal = () => {
    const { resources, } = this.props;

    if (resources.settings_users) {
      this.setState((prevState) => ({
        createModal: {
          ...prevState.createModal,
          isOpen: !prevState.createModal.isOpen,
        },
      }));
    }
  }


  render() {
    const { detailModal, deleteModal, createModal, } = this.state;
    const { resources, translations, } = this.props;

    return (
      <Fragment>

        {createModal.isOpen && (
          <RoleCreate
            onToggle={this.handleToggleCreateModal}
          />
        )}

        {detailModal.isOpen && (
          <RoleUpdate
            resources={resources}
            roleId={detailModal.id}
            onToggle={this.handleToggleDetailModal}
          />
        )}

        {deleteModal.isOpen && (
          <RoleDelete
            roleId={deleteModal.id}
            onToggle={this.handleToggleDeleteModal}
          />
        )}

        <RolesView
          // data
          resources={resources}
          translations={translations}
          // methods
          onCreate={this.handleToggleCreateModal}
          onDetail={this.handleToggleDetailModal}
          onDelete={this.handleToggleDeleteModal}
        />

      </Fragment>
    );
  }
}


Roles.propTypes = {
  resources: object.isRequired,
  translations: object.isRequired,
};


export default Roles;
