import React from 'react';
import { string, object, } from 'prop-types';
import { Query, } from 'react-apollo';

import { QUERY_PLATFORMS, } from '../../gql/queries';
import { TRANSLATIONS_STOREKEEPER, } from '../../gql/translations';
import { withTranslations, } from '../../../../logic/translations/withTranslations';
import Card from '../../../../atoms/Card/Card';
import PartLoading from '../../../../components/Parts/PartLoading';
import PartError from '../../../../components/Parts/PartError';
import Platform from './Platform/Platform';
import StyledPlatforms from './styles/StyledPlatforms';


const Platforms = ({
  languageId,
  translations,
  translationsCommon,
  resources,
}) => (
  <StyledPlatforms>
    <div className="storekeeperPlatforms--list">
      <Query
        query={QUERY_PLATFORMS}
        variables={{
          languageId,
          platformIds: resources.storekeeper_access_by_platform_id
            ? resources.storekeeper_access_by_platform_id.constraint
            : undefined,
        }}
        fetchPolicy="cache-and-network"
      >
        {({
          data,
          loading,
          error,
          subscribeToMore,
        }) => {
          if (loading) {
            return (
              <Card className="storekeeperPlatforms--item">
                <PartLoading />
              </Card>
            );
          }
          if (error || !data.fetchCentralPlatforms) {
            return (
              <Card className="storekeeperPlatforms--item">
                <PartError error={error} />
              </Card>
            );
          }

          return data.fetchCentralPlatforms.map((p) => (
            <div
              className="storekeeperPlatforms--item"
              key={p.id}
            >
              <Platform
                // data
                platform={p}
                resources={resources}
                languageId={languageId}
                translations={translations}
                translationsCommon={translationsCommon}
                // functions
                subscribeToMore={subscribeToMore}
              />
            </div>
          ));
        }}
      </Query>
    </div>
  </StyledPlatforms>
);


Platforms.propTypes = {
  languageId: string.isRequired,
  translations: object.isRequired,
  translationsCommon: object.isRequired,
  resources: object.isRequired,
};


export default withTranslations(TRANSLATIONS_STOREKEEPER)(Platforms);
