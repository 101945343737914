import React from 'react';

import StyledSvg from './styles/StyledSvg';


const ListAdd = ({ ...props }) => (
  // eslint-disable-next-line react/prop-types
  <StyledSvg viewBox="0 0 1000 1000" fill={(props?.fill) ?? 'black'} {...props}>
    <path d="M295.5,295.7H704c22.6,0,40.9-18.3,40.9-40.9c0-22.6-18.3-40.9-40.9-40.9H295.5c-22.6,0-40.9,18.3-40.9,40.9C254.6,277.4,272.9,295.7,295.5,295.7z" />
    <path d="M295.5,459.7H704c22.6,0,40.9-18.3,40.9-40.9c0-22.6-18.3-40.9-40.9-40.9H295.5c-22.6,0-40.9,18.3-40.9,40.9C254.6,441.3,272.9,459.7,295.5,459.7z" />
    <path d="M884.2,33.9C868.3,18,847.4,10,826.5,10h-653c-20.9,0-41.8,8-57.7,23.9C99.8,49.9,91.8,70.8,91.8,91.7v816.5c0,20.9,8,41.8,23.9,57.7c15.9,15.9,36.8,23.9,57.7,23.9h247.1c1,0.1,2,0.1,3,0.1c22.6,0,40.9-18.3,40.9-40.9c0-22.6-18.3-40.9-40.9-40.9H210.5c-9.5,0-19-3.6-26.2-10.8c-7.2-7.2-10.8-16.7-10.8-26.2V128.7c0-9.5,3.6-19,10.8-26.2c7.2-7.2,16.7-10.8,26.2-10.8h578.9c9.5,0,19,3.6,26.2,10.8c7.2,7.2,10.8,16.7,10.8,26.2v376.7c0,22.6,18.3,40.8,40.8,40.8c22.6,0,40.8-18.3,40.8-40.8V91.7C908.2,70.8,900.2,49.9,884.2,33.9z" />
    <path d="M690.5,554.5c-120.2,0-217.7,97.4-217.7,217.7c0,120.2,97.4,217.7,217.7,217.7c120.2,0,217.7-97.4,217.7-217.7C908.2,651.9,810.7,554.5,690.5,554.5z M798.9,799.7H718v80.9c0,15.3-12.3,27.7-27.5,27.7c-15.2,0-27.5-12.4-27.5-27.7v-80.9h-80.9c-15.3,0-27.7-12.3-27.7-27.5c0-15.2,12.4-27.5,27.7-27.5H663v-80.9c0-15.3,12.3-27.7,27.5-27.7c15.2,0,27.5,12.4,27.5,27.7v80.9h80.9c15.3,0,27.7,12.3,27.7,27.5C826.6,787.4,814.2,799.7,798.9,799.7z" />
  </StyledSvg>
);

export default ListAdd;
