import React, { Fragment, } from 'react';
import {
  string, object, func, shape, arrayOf, bool,
} from 'prop-types';
import { Query, } from 'react-apollo';

import { parseDateToHuman, } from '../../../../logic/date';
import Input from '../../../../atoms/Input/Input';
import InputSelect from '../../../../atoms/InputSelect/InputSelect';
import InputDateSelect from '../../../../atoms/InputDateSelect/InputDateSelect';
import Table from '../../../../atoms/Table/Table';
import TableLoading from '../../../../atoms/Table/TableLoading';
import TableError from '../../../../atoms/Table/TableError';
import TableNoData from '../../../../atoms/Table/TableNoData';
import Tooltip from '../../../../atoms/Tooltip/Tooltip';
import ButtonIcon from '../../../../atoms/Button/ButtonIcon';
import ButtonGrp from '../../../../atoms/Button/ButtonGrp';
import THSortable from '../../../../atoms/Table/THSortable';
import Search from '../../../../styles/icons/Search';
import { QUERY_DD_TRANSPORTATION_TYPES, } from '../../../Transportation/gql/queries';


const COL_COUNT = 6;


const Header = ({
  // data
  filter,
  translations,
  languageId,
  // methods
  onChangeSort,
  onChangeParam,
}) => (
  <thead>
    <tr>
      <th style={{ minWidth: '10rem', width: '10rem', }} />

      <THSortable
        title={translations.form.time}
        name="createdAt"
        isActiveFilter={!!filter.params.timeFrom}
        filter={filter}
        style={{ minWidth: '10rem', width: '10rem', }}
        onSort={onChangeSort}
      />

      <THSortable
        title={translations.form.registrationNumber}
        name="registrationNumber"
        isActiveFilter={!!filter.params.registrationNumber}
        filter={filter}
        style={{ minWidth: '10rem', width: '10rem', }}
        onSort={onChangeSort}
      />

      <THSortable
        title={translations.form.transportationType}
        name="transportationTypeId"
        isActiveFilter={filter.params.transportationTypeId !== null}
        filter={filter}
        style={{ minWidth: '10rem', width: '10rem', }}
        onSort={onChangeSort}
      />

      <THSortable
        title={translations.form.status}
        name="state"
        isActiveFilter={!!filter.params.message}
        filter={filter}
        style={{ minWidth: '10rem', width: '10rem', }}
        onSort={onChangeSort}
      />

      <th
        className="table--header-title table--text-align-right"
        style={{ minWidth: '10rem', width: '10rem', }}
      >
        {translations.transportation.thActions}
      </th>
    </tr>

    <tr>
      <th />

      <th>
        <InputDateSelect
          placeholder={translations.common.from}
          size="sm"
          value={filter.params.timeFrom}
          active={!!filter.params.timeFrom}
          clearable
          onChange={(newDate) => onChangeParam('timeFrom', newDate, false)}
        />
      </th>

      <th>
        <Input
          type="text"
          placeholder={translations.common.filter}
          autoComplete="off"
          value={filter.params.registrationNumber}
          active={filter.params.registrationNumber !== ''}
          onChange={(e) => onChangeParam('registrationNumber', e.target.value)}
          onClear={() => onChangeParam('registrationNumber', '')}
          size="sm"
        />
      </th>

      <th>
        <Query
          query={QUERY_DD_TRANSPORTATION_TYPES}
          variables={{
            languageId,
          }}
        >
          {(dropdownData) => (
            <InputSelect
              value={filter.params.transportationTypeId}
              active={!!filter.params.transportationTypeId}
              options={(dropdownData.data && dropdownData.data.fetchAllTransportationTypes)
                ? dropdownData.data.fetchAllTransportationTypes
                : []
              }
              onChange={(option) => onChangeParam('transportationTypeId', option, false)}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              isClearable
              isLoading={dropdownData.loading}
              error={dropdownData.error}
              placeholder={translations.common.filter}
              size="sm"
            />
          )}
        </Query>
      </th>

      <th>
        <Input
          type="text"
          placeholder={translations.common.filter}
          autoComplete="off"
          value={filter.params.message}
          active={filter.params.message !== ''}
          onChange={(e) => onChangeParam('message', e.target.value)}
          onClear={() => onChangeParam('message', '')}
          size="sm"
        />
      </th>

      <th />
    </tr>

    <tr>
      <th />

      <th>
        <InputDateSelect
          placeholder={translations.common.to}
          size="sm"
          value={filter.params.timeTo}
          active={!!filter.params.timeTo}
          clearable
          onChange={(newDate) => onChangeParam('timeTo', newDate, false)}
        />
      </th>

      <th />

      <th />

      <th />

      <th />
    </tr>
  </thead>
);


Header.propTypes = {
  // data
  filter: object.isRequired,
  translations: object.isRequired,
  languageId: string.isRequired,
  // methods
  onChangeSort: func.isRequired,
  onChangeParam: func.isRequired,
};


const Rows = ({
  // data
  loading,
  error,
  data,
  translations,
  // methods
  onDetail,
}) => {
  if (!data.filterGateNotifications && loading) {
    return (
      <TableLoading
        colsCount={COL_COUNT}
        rowsCount={
          data && data.filterGateNotifications && data.filterGateNotifications.rows.length
        }
      />
    );
  }
  if (error || !data.filterGateNotifications) {
    return (
      <TableError
        colsCount={COL_COUNT}
        error={error}
      />
    );
  }
  if (data.filterGateNotifications.rows.length < 1) {
    return (
      <TableNoData
        colsCount={COL_COUNT}
        text={translations.gatehouse.noRecords}
      />
    );
  }

  return (
    <tbody>
      {data.filterGateNotifications.rows.map((item) => (
        <tr key={item.id}>
          <td />
          <td>
            {parseDateToHuman(new Date(item.createdAt))}
          </td>
          <td>
            {item.registrationNumber}
          </td>
          <td>
            {item.transportationTypeName}
          </td>
          <td>
            {item.message}
          </td>
          <td className="table--text-align-right table--noPadding">
            <ButtonGrp>
              <Tooltip text={translations.common.detail}>
                {(events) => (
                  <ButtonIcon
                    size="sm"
                    color="tertiary"
                    onClick={() => onDetail(item.transportationId)}
                    {...events}
                  >
                    <Search />
                  </ButtonIcon>
                )}
              </Tooltip>
            </ButtonGrp>
          </td>
        </tr>
      ))}
    </tbody>
  );
};


Rows.propTypes = {
  loading: bool.isRequired,
  error: object,
  data: shape({
    filterGateNotifications: shape({
      rows: arrayOf(shape({
        id: string,
        message: string,
        transportationId: string,
        transportationTypeName: string,
        registrationNumber: string,
        createdAt: string,
      })).isRequired,
    }),
  }),
  translations: object.isRequired,
  onDetail: func.isRequired,
};

Rows.defaultProps = {
  error: undefined,
  data: undefined,
};


const TableView = ({
  // data
  filter,
  queryData,
  translations,
  languageId,
  // methods
  onDetail,
  onChangeSort,
  onChangeParam,
}) => (
  <Fragment>
    <Table fillContent>
      <Header
        // data
        filter={filter}
        translations={translations}
        languageId={languageId}
        // methods
        onChangeSort={onChangeSort}
        onChangeParam={onChangeParam}
      />
      <Rows
        // data
        {...queryData}
        translations={translations}
        // methods
        onDetail={onDetail}
      />
    </Table>
  </Fragment>
);


TableView.propTypes = {
  filter: shape({
    params: object.isRequired,
  }).isRequired,
  queryData: shape({
    loading: bool.isRequired,
    error: object,
    data: object,
  }).isRequired,
  translations: object.isRequired,
  languageId: string.isRequired,
  onDetail: func.isRequired,
  onChangeSort: func.isRequired,
  onChangeParam: func.isRequired,
};


export default TableView;
