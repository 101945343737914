import React, { Fragment, } from 'react';
import {
  shape, string, func, object, bool,
} from 'prop-types';
import { withTheme, } from 'styled-components';
import { Mutation, } from 'react-apollo';

import { MUTATION_CREATE_ROLE, } from '../../gql/mutations';
import FormRow from '../../../../components/Form/FormRow';
import Input from '../../../../atoms/Input/Input';
import Button from '../../../../atoms/Button/Button';
import ButtonLoader from '../../../../atoms/Button/ButtonLoader';
import Row from '../../../../atoms/Row/Row';
import Col from '../../../../atoms/Col/Col';
import FormError from '../../../../components/Form/FormError';
import RoleTree from '../RoleTree/RoleTree';
import FormBackground from '../../../../components/Form/FormBackground';


const RoleCreateView = ({
  // data
  roleForm: {
    isValid,
    form,
    values,
  },
  theme,
  resourcesValue,
  translations,
  languageId,
  // methods
  onChangeForm,
  onChangeResourceValue,
  onClose,
  onCreate,
  onCreateCompleted,
  onCreateError,
}) => (
  <FormBackground>
    <FormRow
      label={translations.form.name}
      input={form.name}
    >
      <Input
        type="text"
        value={values.name}
        onChange={(e) => onChangeForm(form.name.name, e.target.value)}
        status={form.name.status}
      />
    </FormRow>

    <Row formGroup>
      <Col>
        <RoleTree
          // data
          background={theme.grey.t50}
          languageId={languageId}
          translations={translations}
          resourcesValue={resourcesValue}
          // methods
          onChangeResourceValue={onChangeResourceValue}
        />
      </Col>
    </Row>

    <Mutation
      mutation={MUTATION_CREATE_ROLE}
      onCompleted={onCreateCompleted}
      onError={onCreateError}
    >
      {(mutate, { loading, error, }) => (
        <Fragment>
          <FormError
            isValid={isValid}
            error={error}
          />
          <Row>
            <Col SM={12} MD={12} textAlign="left">
              <Button
                onClick={onClose}
              >
                {translations.common.back}
              </Button>
            </Col>
            <Col SM={12} MD={12} textAlign="right">
              <ButtonLoader
                color="success"
                onClick={() => onCreate(mutate)}
                disabled={!isValid}
                isLoading={loading}
              >
                {translations.common.create}
              </ButtonLoader>
            </Col>
          </Row>
        </Fragment>
      )}
    </Mutation>
  </FormBackground>
);


RoleCreateView.propTypes = {
  theme: shape({
    grey: object.isRequired,
  }).isRequired,
  translations: object.isRequired,
  languageId: string.isRequired,
  resourcesValue: object.isRequired,
  roleForm: shape({
    isValid: bool.isRequired,
    form: shape({
      name: shape({
        name: string.isRequired,
        status: string.isRequired,
      }).isRequired,
    }).isRequired,
    values: shape({
      name: string,
    }).isRequired,
  }).isRequired,
  onChangeForm: func.isRequired,
  onChangeResourceValue: func.isRequired,
  onClose: func.isRequired,
  onCreate: func.isRequired,
  onCreateCompleted: func.isRequired,
  onCreateError: func.isRequired,
};


export default withTheme(RoleCreateView);
