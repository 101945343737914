import React, { Component, } from 'react';
import {
  func, arrayOf, shape, string, object, bool,
} from 'prop-types';
import { Mutation, Query, } from 'react-apollo';

import { QUERY_USERS, } from '../../gql/queries';
import { SUBSCRIPTION_USERS, } from '../../gql/subscriptions';
import { MUTATION_UPDATE_USER, } from '../../gql/mutations';
import Table from '../../../../atoms/Table/Table';
import ButtonIcon from '../../../../atoms/Button/ButtonIcon';
import ButtonGrp from '../../../../atoms/Button/ButtonGrp';
import Badge from '../../../../atoms/Badge/Badge';
import BadgeList from '../../../../atoms/Badge/BadgeList';
import Tooltip from '../../../../atoms/Tooltip/Tooltip';
import TableLoading from '../../../../atoms/Table/TableLoading';
import TableError from '../../../../atoms/Table/TableError';
import TableNoData from '../../../../atoms/Table/TableNoData';

import Bin from '../../../../styles/icons/Bin';
import Search from '../../../../styles/icons/Search';
import LockOpened from '../../../../styles/icons/LockOpened';
import LockClosed from '../../../../styles/icons/LockClosed';


const COL_COUNT = 4;


const Header = ({ translations, }) => (
  <thead>
    <tr>
      <th>
        {translations.settings.thUsername}
      </th>
      <th>
        {translations.settings.thBlocked}
      </th>
      <th>
        {translations.settings.thRoles}
      </th>
      <th className="table--text-align-right">
        {translations.settings.thActions}
      </th>
    </tr>
  </thead>
);


Header.propTypes = {
  translations: object.isRequired,
};


class Rows extends Component {
  componentDidMount() {
    const { subscribeToUsers, } = this.props;

    subscribeToUsers();
  }


  render() {
    const {
      // data
      loading,
      error,
      data,
      translations,
      // methods
      onDetail,
      onDelete,
      onBlock,
      onBlockCompleted,
      onBlockError,
    } = this.props;

    if (loading) {
      return (
        <TableLoading
          colsCount={COL_COUNT}
          rowsCount={data && data.fetchUsers && data.fetchUsers.length}
        />
      );
    }
    if (error || !data.fetchUsers) {
      return (
        <TableError
          colsCount={COL_COUNT}
          error={error}
        />
      );
    }
    if (data.fetchUsers.length < 1) {
      return (
        <TableNoData
          colsCount={COL_COUNT}
          text={translations.settings.usersEmpty}
        />
      );
    }

    return (
      <tbody>
        {data.fetchUsers.map((row) => (
          <tr key={row.id}>
            <td>
              {row.username}
            </td>
            <td className="table--noPadding">
              {row.block
                ? <Badge color="error">{translations.common.yes}</Badge>
                : <Badge color="success">{translations.common.no}</Badge>
              }
            </td>
            <td className="table--noPadding">
              <BadgeList>
                {row.roles && row.roles.map((role) => (
                  <Badge key={role.id}>
                    {role.name}
                  </Badge>
                ))}
              </BadgeList>
            </td>
            <td
              className="table--text-align-right table--noPadding"
              style={{ minWidth: '7.5rem', }}
            >
              <ButtonGrp>
                <Tooltip text={translations.common.detail}>
                  {(events) => (
                    <ButtonIcon
                      size="sm"
                      color="tertiary"
                      onClick={() => onDetail(row.id)}
                      {...events}
                    >
                      <Search />
                    </ButtonIcon>
                  )}
                </Tooltip>
                <Tooltip
                  text={row.block
                    ? translations.settings.unblock
                    : translations.settings.block
                  }
                >
                  {(events) => (
                    <Mutation
                      mutation={MUTATION_UPDATE_USER}
                      onCompleted={(completedData) => onBlockCompleted(completedData, row)}
                      onError={onBlockError}
                    >
                      {(mutationUpdateUser, updateData) => (
                        <ButtonIcon
                          size="sm"
                          color="warning"
                          onClick={() => onBlock(mutationUpdateUser, row)}
                          disabled={updateData.loading}
                          {...events}
                        >
                          {row.block
                            ? <LockOpened />
                            : <LockClosed />
                          }
                        </ButtonIcon>
                      )}
                    </Mutation>
                  )}
                </Tooltip>
                <Tooltip text={translations.common.delete}>
                  {(events) => (
                    <ButtonIcon
                      size="sm"
                      color="error"
                      onClick={() => onDelete(row.id)}
                      {...events}
                    >
                      <Bin />
                    </ButtonIcon>
                  )}
                </Tooltip>
              </ButtonGrp>
            </td>
          </tr>
        ))}
      </tbody>
    );
  }
}


Rows.propTypes = {
  loading: bool.isRequired,
  error: object,
  data: shape({
    fetchUsers: arrayOf(shape({
      id: string.isRequired,
      username: string.isRequired,
      roles: arrayOf(shape({
        id: string.isRequired,
        name: string.isRequired,
      })).isRequired,
    })),
  }),
  translations: object.isRequired,
  subscribeToUsers: func.isRequired,
  onDetail: func.isRequired,
  onDelete: func.isRequired,
  onBlock: func.isRequired,
  onBlockCompleted: func.isRequired,
  onBlockError: func.isRequired,
};

Rows.defaultProps = {
  error: undefined,
  data: undefined,
};


const UsersTable = ({
  // data
  translations,
  // methods
  onDetail,
  onDelete,
  onBlock,
  onBlockCompleted,
  onBlockError,
}) => (
  <Query query={QUERY_USERS}>
    {({ subscribeToMore, ...tableData }) => (
      <Table fillContent>
        <Header
          translations={translations}
        />
        <Rows
          // data
          {...tableData}
          translations={translations}
          // methods
          onDetail={onDetail}
          onDelete={onDelete}
          onBlock={onBlock}
          onBlockCompleted={onBlockCompleted}
          onBlockError={onBlockError}
          subscribeToUsers={() => subscribeToMore({
            document: SUBSCRIPTION_USERS,
            updateQuery: (prev, { subscriptionData, }) => {
              if (!subscriptionData.data) return null;
              const newTransportation = subscriptionData.data.users;
              return {
                fetchUsers: newTransportation,
              };
            },
          })}
        />
      </Table>
    )}
  </Query>
);


UsersTable.propTypes = {
  translations: object.isRequired,
  onDetail: func.isRequired,
  onDelete: func.isRequired,
  onBlock: func.isRequired,
  onBlockCompleted: func.isRequired,
  onBlockError: func.isRequired,
};


export default UsersTable;
