import React from 'react';
import { string, } from 'prop-types';
import { Query, } from 'react-apollo';

import { QUERY_NOTIFICATIONS, } from '../../modules/Monitoring/gql/queries';
import BadgeIcon from '../../atoms/Badge/BadgeIcon';


const NotificationBadge = ({ languageId, }) => (
  <Query
    query={QUERY_NOTIFICATIONS}
    variables={{ languageId, }}
  >
    {({ data, }) => {
      if (!data || !data.fetchNotifications) return null;
      return (
        <BadgeIcon
          style={{ marginLeft: '0.75rem', }}
          color="error"
        >
          {data.fetchNotifications.length > 9 ? '9+' : data.fetchNotifications.length}
        </BadgeIcon>
      );
    }}
  </Query>
);


NotificationBadge.propTypes = {
  languageId: string.isRequired,
};


export default NotificationBadge;
