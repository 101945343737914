import React from 'react';
import { object, } from 'prop-types';
import { NavLink, } from 'react-router-dom';

import { TRANSLATIONS_NOT_FOUND, } from '../gql/translations';
import { withTranslations, } from '../../../logic/translations/withTranslations';

import StyledNotFound from '../components/NotFound/StyledNotFound';


const NotFoundPage = ({ translations, }) => (
  <StyledNotFound>

    <div className="notFound">
      <div className="notFound--404">
        404
      </div>
      <div className="notFound--info">
        <div className="notFound--info-title">
          {translations.notFound.infoTitle}
        </div>
        <div className="notFound--info-text">
          {translations.notFound.infoText}
        </div>
      </div>
    </div>

    <div className="notFound--redirect">
      <NavLink
        className="notFound--redirect-link"
        to="/"
      >
        {translations.notFound.linkText}
      </NavLink>
    </div>

  </StyledNotFound>
);


NotFoundPage.propTypes = {
  translations: object.isRequired,
};


export default withTranslations(TRANSLATIONS_NOT_FOUND)(NotFoundPage);
