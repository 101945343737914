import React, { Component, } from 'react';
import { func, } from 'prop-types';

import { withApollo, } from 'react-apollo';
import { withNotifications, } from '../../logic/notifications/withNotifications';
import { QUERY_FETCH_TRANSPORTATION_BY_SCANNED_ID, } from './gql/queries';
import ConfirmModal from '../modal/ConfirmModal';
import { withTranslations, } from '../../logic/translations/withTranslations';
import { TRANSLATIONS_SCANNER_INPUT, } from './gql/translations';
import { pipe, } from '../../logic/utils';
import { MUTATION_CLEAR_TRANSPORTATION, MUTATION_FINISH_TRANSPORTATION, } from '../../modules/Storekeeper/gql/mutations';
import { AUDIO_NOTIFICATION, audioNotification, } from '../../logic/audio/notification';
import { MUTATION_CONFIRM_TRANSPORTATION_SAMPLE, } from '../../modules/Sample/gql/mutations';


const TRANSLATIONS = {
  qrCodeSuccessRead: 'Úspěšně načtené ID z QR kódu, zpracovává se',
  processingError: 'Chyba při zpracovaní přepravy',
  invalidQrCode: 'Načtený QR kód je neplatný, prosím zkuste ho načíst znovu',
  resultNotFound: 'Načtená přeprava nebyla nalezena',
  resultNotCalled: 'Přeprava ještě není vyvolána',
  resultAlreadyFinished: 'Přeprava je již ukončena',
  resultClearing: 'Přeprava se začala odbavovat',
  resultCleared: 'Odbavování přepravy se ukončilo',
  resultInvalidState: 'Přeprava je v přerusena, nebo v jiném nepodporovaném stavu',
  resultUnauthorized: 'Na tuto přepravu nemáte opravnění',
};

const MARGIN_CHAR_CODE = 'Semicolon';
const MARGIN_SIZE = 3;
const CANCEL_TIMEOUT = 15;
const CLEARING_INTERVAL = 15;

const getChar = (event) => {
  if (event.code.startsWith('Digit')) {
    return event.code[5];
  }
  if (event.code === 'Minus') {
    return '-';
  }
  if (event.code === 'Slash') {
    return '-';
  }
  return event.key;
};

class ScannerInput extends Component {
  constructor(props) {
    super(props);

    this.marginCharsRead = 0;
    this.status = 'WAITING'; // WAITING | READING_UUID
    this.uuid = '';

    this.countdownTimer = null;
    this.remainingTime = null;
    this.readingTimer = null;

    this.state = {
      transportation: null,
      countdownModal: {
        isOpen: false,
      },
      confirmStartClearingModal: {
        isOpen: false,
      },
      confirmFinishClearingModal: {
        isOpen: false,
      },
    };
  }

  componentDidMount() {
    document.addEventListener('keypress', this.handleKeyPress);
  }

  componentWillUnmount() {
    document.removeEventListener('keypress', this.handleKeyPress);
  }

  handleKeyPress = (event) => {
    console.log(event, this.status);
    if (this.status === 'WAITING_FOR_ACTION') {
      return;
    }

    if (event.code === MARGIN_CHAR_CODE) {
      if (this.status === 'WAITING') {
        this.marginCharsRead++;

        // reading the last margin char
        if (this.marginCharsRead === MARGIN_SIZE) {
          this.marginCharsRead = 0;
          this.status = 'READING_UUID';
          this.uuid = '';
          this.readingTimer = setTimeout(() => { this.reset(); }, 3000);
        }
      } else /* if (state === 'READING_UUID') */ {
        this.marginCharsRead++;

        // reading the last margin char
        if (this.marginCharsRead === MARGIN_SIZE) {
          if (this.readingTimer) {
            clearTimeout(this.readingTimer);
          }
          this.marginCharsRead = 0;
          this.status = 'WAITING_FOR_ACTION';

          this.handleScannedUuid();
        }
      }
    } else if (this.status === 'READING_UUID') {
      this.uuid += getChar(event);
    } else if (this.status === 'WAITING') {
      this.marginCharsRead = 0;
    }
  }

  reset() {
    console.log('reset', this.status);
    this.marginCharsRead = 0;
    this.status = 'WAITING';
    this.uuid = '';
  }

  async handleScannedUuid() {
    const { client, } = this.props;

    console.log('SEND UUID:', this.uuid.trim(), typeof this.uuid);

    const result = await client.query({
      query: QUERY_FETCH_TRANSPORTATION_BY_SCANNED_ID,
      fetchPolicy: 'network-only',
      variables: { uuid: this.uuid, },
    });

    const { transportation, } = result.data.fetchTransportationByScannedId;
    if (!transportation) {
      this.addNotificationResult('NOT_FOUND');
      this.reset();
      return;
    }

    if (transportation.waitingForSample) {
      await client.mutate({
        mutation: MUTATION_CONFIRM_TRANSPORTATION_SAMPLE,
        variables: { transportationId: transportation.id, },
      });
      audioNotification(AUDIO_NOTIFICATION.SCANNER_MOVE_TO_NEXT_STAND);
      this.reset();
      return;
    }

    if (!transportation.clearanceState) {
      this.addNotificationResult('NOT_CALLED');
      this.reset();
      return;
    }

    if (transportation.clearanceState === 'finished') {
      this.addNotificationResult('ALREADY_FINISHED');
      this.reset();
      return;
    }

    if (![ 'called', 'clearing', ].includes(transportation.clearanceState)) {
      this.addNotificationResult('INVALID_STATE');
      this.reset();
      return;
    }

    this.setState({
      transportation,
    });

    if (transportation.clearanceState === 'called') {
      if (!transportation.gateInTime) {
        this.setState((prevState) => ({
          confirmStartClearingModal: {
            ...prevState.confirmStartClearingModal,
            isOpen: true,
          },
        }));
        return;
      }
      this.openCountdownModal();
    } else {
      if ((Date.now() - new Date(transportation.clearanceTimeStart).getTime()) / (60 * 1000) < CLEARING_INTERVAL) {
        this.setState((prevState) => ({
          confirmFinishClearingModal: {
            ...prevState.confirmFinishClearingModal,
            isOpen: true,
          },
        }));
        return;
      }
      this.openCountdownModal();
    }
  }

  handleToggleConfirmStartClearingModal = (result) => {
    this.setState((prevState) => ({
      confirmStartClearingModal: {
        ...prevState.confirmStartClearingModal,
        isOpen: false,
      },
    }));

    if (result) {
      this.openCountdownModal();
    } else {
      this.reset();
    }
  }

  handleToggleConfirmFinishClearingModal = (result) => {
    this.setState((prevState) => ({
      confirmFinishClearingModal: {
        ...prevState.confirmFinishClearingModal,
        isOpen: false,
      },
    }));

    if (result) {
      this.openCountdownModal();
    } else {
      this.reset();
    }
  }

  openCountdownModal = () => {
    this.remainingTime = CANCEL_TIMEOUT;
    this.setState((prevState) => ({
      countdownModal: {
        ...prevState.countdownModal,
        isOpen: true,
        start: new Date(),
      },
    }));
    this.countdownTimer = setInterval(() => {
      const { countdownModal, } = this.state;
      if (countdownModal.start) {
        this.remainingTime = CANCEL_TIMEOUT - Math.floor((Date.now() - countdownModal.start.getTime()) / 1000);
        if (this.remainingTime < 0) {
          this.closeCountdownModal(true);
        }
        this.setState({});
      }
    }, 1000);
  }

  closeCountdownModal = async (result) => {
    const { transportation, } = this.state;
    const { client, translations, addNotification, } = this.props;

    clearInterval(this.countdownTimer);
    this.setState((prevState) => ({
      countdownModal: {
        ...prevState.countdownModal,
        isOpen: false,
        start: null,
      },
    }));

    if (result) {
      try {
        await client.mutate({
          mutation: transportation.clearanceState === 'called' ? MUTATION_CLEAR_TRANSPORTATION : MUTATION_FINISH_TRANSPORTATION,
          variables: { transportationId: transportation.id, platformId: transportation.platformId, },
        });
        addNotification({
          status: 'success',
          title: transportation.clearanceState === 'called' ? translations.centralAppStorekeeper.notifTitleClearedSuccess : translations.centralAppStorekeeper.notifTitleFinishSuccess,
        });
      } catch (e) {
        addNotification({
          status: 'error',
          title: transportation.clearanceState === 'called' ? translations.centralAppStorekeeper.notifTitleClearedError : translations.centralAppStorekeeper.notifTitleFinishError,
        });
      }
    }
    this.reset();
  }

  addNotificationResult(status) {
    switch (status) {
      case 'NOT_FOUND':
        return this.addNotification('warning', TRANSLATIONS.resultNotFound);
      case 'UNAUTHORIZED':
        return this.addNotification('warning', TRANSLATIONS.resultUnauthorized);
      case 'NOT_CALLED':
        return this.addNotification('warning', TRANSLATIONS.resultNotCalled);
      case 'ALREADY_FINISHED':
        return this.addNotification('warning', TRANSLATIONS.resultAlreadyFinished);
      case 'CLEARING':
        return this.addNotification('success', TRANSLATIONS.resultClearing);
      case 'CLEARED':
        return this.addNotification('success', TRANSLATIONS.resultCleared);
      case 'INVALID_STATE':
      default:
        return this.addNotification('warning', TRANSLATIONS.resultInvalidState);
    }
  }

  addNotification(status, title, text = '') {
    const { addNotification, } = this.props;
    addNotification({ status, title, text, });
  }

  render() {
    const {
      countdownModal, confirmStartClearingModal, confirmFinishClearingModal, transportation,
    } = this.state;
    const { translations, } = this.props;

    return (
      <>
        {confirmStartClearingModal.isOpen && (
          <ConfirmModal
            isOpen
            title={translations.common.confirm}
            text={translations.centralAppStorekeeper.noGateInText}
            cancelTextBtn={translations.centralAppStorekeeper.noGateInCancelBtn}
            confirmTextBtn={translations.centralAppStorekeeper.noGateInConfirmBtn}
            translations={translations}
            onToggle={this.handleToggleConfirmStartClearingModal}
          />
        )}
        {confirmFinishClearingModal.isOpen && (
          <ConfirmModal
            isOpen
            title={translations.common.confirm}
            text={translations.centralAppStorekeeper.shortIntervalText}
            cancelTextBtn={translations.centralAppStorekeeper.shortIntervalCancelBtn}
            confirmTextBtn={translations.centralAppStorekeeper.shortIntervalConfirmBtn}
            translations={translations}
            onToggle={this.handleToggleConfirmFinishClearingModal}
          />
        )}
        {countdownModal.isOpen && (
          <ConfirmModal
            isOpen
            title={translations.common.confirm}
            text={(transportation.clearanceState === 'called' ? translations.centralAppStorekeeper.countdownModalClearText : translations.centralAppStorekeeper.countdownModalFinishText)
              .replace('__no__', transportation.no)
              .replace('__time__', this.remainingTime)}
            cancelTextBtn={translations.centralAppStorekeeper.countdownModalCancelBtn}
            confirmTextBtn={translations.centralAppStorekeeper.countdownModalConfirmBtn}
            translations={translations}
            onToggle={this.closeCountdownModal}
          />
        )}
      </>
    );
  }
}


ScannerInput.propTypes = {
  addNotification: func.isRequired,
};


export default pipe(withTranslations(TRANSLATIONS_SCANNER_INPUT), withNotifications, withApollo)(ScannerInput);
