// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
import React from 'react';
import { LineChart as LineReChart, ResponsiveContainer, } from 'recharts';
import { arrayOf, object, } from 'prop-types';

import { LineChartDefault, } from '../../utils/config';
import LineChartLine from './LineChartLine';
import XAxis from './XAxis';
import YAxis from './YAxis';
import Grid from './Grid';
import { createFieldOrDefault, } from '../../../../logic/object';


function LineChart(props) {
  const { config, data, } = props;
  const getField = createFieldOrDefault(config, LineChartDefault);

  return (
    <>
      {config.title ? <h3>{config.title}</h3> : null}
      <ResponsiveContainer height={getField('height')}>
        <LineReChart
          data={data}
          margin={{
            top: 10, bottom: 10, left: 10, right: 10,
          }}
        >
          {Grid(getField('grid'))}
          {XAxis(getField('xAxis'))}
          {YAxis(getField('yAxis'))}
          {config.lines.map((line) => LineChartLine(line))}
        </LineReChart>
      </ResponsiveContainer>
    </>
  );
}

LineChart.propTypes = {
  config: object.isRequired,
  data: arrayOf(object.isRequired).isRequired,
};


export default LineChart;
