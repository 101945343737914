import gql from 'graphql-tag';


/**
 * Platform
 */
export const MUTATION_SET_PLATFORM_STATE = gql`
  mutation SetPlatformState($platformId: ID!, $opened: Boolean!) {
    setPlatformState(platformId: $platformId, opened: $opened)
  }
`;


/**
 * Transportation
 */
export const MUTATION_CALL_TRANSPORTATION = gql`
  mutation CallTransportation($platformId: ID!, $transportationId: ID, $toPlatformQueue: Boolean) {
    callTransportation(platformId: $platformId, transportationId: $transportationId, toPlatformQueue: $toPlatformQueue)
  }
`;

export const MUTATION_CANCEL_TRANSPORTATION = gql`
  mutation CancelTransportation($platformId: ID!, $transportationId: ID!) {
    cancelTransportation(platformId: $platformId, transportationId: $transportationId)
  }
`;

export const MUTATION_CLEAR_TRANSPORTATION = gql`
  mutation ClearTransportation($platformId: ID!, $transportationId: ID!) {
    clearTransportation(platformId: $platformId, transportationId: $transportationId)
  }
`;

export const MUTATION_DELAY_TRANSPORTATION = gql`
  mutation DelayTransportation($platformId: ID!, $transportationId: ID!) {
    delayTransportation(platformId: $platformId, transportationId: $transportationId)
  }
`;

export const MUTATION_FINISH_TRANSPORTATION = gql`
  mutation FinishTransportation($platformId: ID!, $standId: ID, $transportationId: ID!) {
    finishTransportation(platformId: $platformId, standId: $standId, transportationId: $transportationId)
  }
`;

export const MUTATION_FORWARD_TRANSPORTATION = gql`
  mutation ForwardTransportation($targetPlatformId: ID!, $sourcePlatformId: ID!, $transportationId: ID!) {
    forwardTransportation(targetPlatformId: $targetPlatformId, sourcePlatformId: $sourcePlatformId, transportationId: $transportationId)
  }
`;

export const MUTATION_FORWARD_TRANSPORTATION_TO_WAREHOUSE = gql`
  mutation ForwardTransportationToWarehouse($targetTransportationTypeId: ID!, $sourcePlatformId: ID!, $transportationId: ID!) {
    forwardTransportationToWarehouse(targetTransportationTypeId: $targetTransportationTypeId, sourcePlatformId: $sourcePlatformId, transportationId: $transportationId)
  }
`;

export const MUTATION_INTERRUPT_TRANSPORTATION = gql`
  mutation InterruptTransportation($platformId: ID!, $transportationId: ID!) {
    interruptTransportation(platformId: $platformId, transportationId: $transportationId)
  }
`;

export const MUTATION_REPEAT_CALL_TRANSPORTATION = gql`
  mutation RepeatCallTransportation($platformId: ID!, $transportationId: ID!) {
    repeatCallTransportation(platformId: $platformId, transportationId: $transportationId)
  }
`;

export const MUTATION_SOLVE_TRANSPORTATION = gql`
  mutation SolveTransportation($platformId: ID!, $standId: ID, $transportationId: ID!) {
    solveTransportation(platformId: $platformId, standId: $standId, transportationId: $transportationId)
  }
`;

export const MUTATION_CONTINUE_TRANSPORTATION = gql`
  mutation ContinueTransportation($platformId: ID!, $transportationId: ID!) {
    continueTransportation(platformId: $platformId, transportationId: $transportationId)
  }
`;

export const MUTATION_STOP_CLEARING_TRANSPORTATION = gql`
  mutation StopClearingTransportation($platformId: ID!, $transportationId: ID!, $note: String!) {
    stopClearingTransportation(platformId: $platformId, transportationId: $transportationId, note: $note)
  }
`;


/**
 * Common
 */
export const MUTATION_SAVE_GENERIC_FORM_TRANSPORTATION = gql`
  mutation FillStoreKeeperGenericForm($values: JSON, $transportationId: ID!, $inProgress: Boolean) {
    fillStoreKeeperGenericForm(values: $values, transportationId: $transportationId, inProgress: $inProgress)
  }
`;


/**
 * Queue
 */
export const MUTATION_CHANGE_STOREKEEPER_QUEUE = gql`
  mutation ChangeStorekeeperQueue($tab: String!, $id: String!, $name: String!) {
    changeStorekeeperQueue(tab: $tab, id: $id, name: $name) @client
  }
`;

export const MUTATION_CHANGE_TAB_STOREKEEPER_QUEUE = gql`
  mutation ChangeTabStorekeeperQueue($tab: String!) {
    changeTabStorekeeperQueue(tab: $tab) @client
  }
`;

export const CHANGE_PLATFORM_STOREKEEPER_QUEUE = gql`
  mutation ChangePlatformStorekeeperQueue($id: String!, $name: String!) {
    changePlatformStorekeeperQueue(id: $id, name: $name) @client
  }
`;

export const MUTATION_SEND_SMS = gql`
  mutation SendSms($transportationId: ID!, $text: String!) {
    sendSms(transportationId: $transportationId, text: $text)
  }
`;
