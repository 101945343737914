import React from 'react';
import {
  shape, bool, object, func,
} from 'prop-types';
import { withTheme, } from 'styled-components';

import { getTimelineSettings, } from '../utils';
import PartLoading from '../../../../../components/Parts/PartLoading';
import PartError from '../../../../../components/Parts/PartError';
import TimelineWrapper from '../../../../../components/Timeline/TimelineWrapper';
import Timeline from '../../../../../components/Timeline/Timeline';

const HOUR_WIDTH = 12;


const TimelineView = ({
  timeline,
  timelineData: {
    loading,
    error,
  },
  theme,
  // methods
  onEdit,
}) => {
  if (loading) return <PartLoading />;
  if (error || !timeline) return <PartError error={error} />;
  return (
    <TimelineWrapper formGroup>
      <Timeline
        hourWidth={HOUR_WIDTH}
        {...timeline}
        getItemNameAndStyle={(payload) => getTimelineSettings(payload, theme, true)}
        getSectionName={(section) => section.name}
        onClickItem={onEdit}
      />
    </TimelineWrapper>
  );
};


TimelineView.propTypes = {
  timeline: object,
  timelineData: shape({
    loading: bool.isRequired,
    error: object,
  }).isRequired,
  theme: object.isRequired,
  onEdit: func.isRequired,
};

TimelineView.defaultProps = {
  timeline: null,
};


export default withTheme(TimelineView);
