import React, { Fragment, } from 'react';
import { func, object, } from 'prop-types';

import ModalBody from '../../../../atoms/Modal/ModalBody';
import ModalHeader from '../../../../atoms/Modal/ModalHeader';
import ModalErrorPart from '../../../../components/modal/ModalErrorPart';


const ErrorView = ({
  // data
  error,
  translations,
  // methods
  onToggle,
}) => (
  <Fragment>
    <ModalHeader onClose={onToggle}>
      {translations.stand.actionWizardTitle}
    </ModalHeader>
    <ModalBody>
      <ModalErrorPart
        onBack={onToggle}
        error={error}
        btnBack={translations.common.back}
      />
    </ModalBody>
  </Fragment>
);


ErrorView.propTypes = {
  error: object,
  translations: object.isRequired,
  onToggle: func.isRequired,
};

ErrorView.defaultProps = {
  error: undefined,
};


export default ErrorView;
