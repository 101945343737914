import React from 'react';
import { string, node, } from 'prop-types';
import { Link, } from 'react-router-dom';


const ModuleGrid = ({
  icon,
  title,
  text,
  to,
}) => (
  <li className="module--grid noSelect">
    <Link
      className="module--grid-content"
      to={to}
    >
      <div className="module--grid-col1">
        {icon}
      </div>
      <div className="module--grid-col2">
        <div className="module--grid-title">
          {title}
        </div>
        <div className="module--grid-text">
          {text}
        </div>
      </div>
    </Link>
  </li>
);


ModuleGrid.propTypes = {
  icon: node.isRequired,
  title: string.isRequired,
  text: string.isRequired,
  to: string.isRequired,
};


export default ModuleGrid;
