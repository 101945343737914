import gql from 'graphql-tag';

export const SUBSCRIPTION_PARKING_CARS_ADD = gql`
 subscription AddCarToParking {
  addCarToParking {
    id
    registrationNumber
    arrival
    status
   }
 }
`;

export const SUBSCRIPTION_PARKING_CARS_REMOVE = gql`
 subscription RemoveCarFromParking {
  removeCarFromParking {
    id
  }
 }
`;

export const SUBSCRIPTION_INTERNAL_PARKING_CARS_ADD = gql`
 subscription AddCarToInternalParking {
  addCarToInternalParking {
    id
    registrationNumber
    arrival
   }
 }
`;

export const SUBSCRIPTION_INTERNAL_PARKING_CARS_REMOVE = gql`
 subscription RemoveCarFromInternalParking {
  removeCarFromInternalParking {
    id
  }
 }
`;
