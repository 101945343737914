import { INPUT_TYPE, } from 'sofair-form-validation';

import { initForm, initInput, } from '../../../../logic/form/common';
import { regexEmail, regexPhone, } from '../../../../logic/form/regexes';
import { ADR_ATTACHMENTS, ATTACHMENTS, } from '../utils';
import {
  dangerousGoodsFields,
  equipmentFields,
  technicalDocumentsfields,
  vehicleMarkingsFields,
} from '../InputTechnicalCheck/utils';


/**
 * Transportation - Reservation
 */
export const initTransportationReservationForm = {
  ...initForm,
  form: {
    resNo: initInput({
      name: 'resNo',
      validation: {
        required: true,
      },
    }),
    resTransportationType: initInput({
      name: 'resTransportationType',
      type: INPUT_TYPE.SELECT,
      validation: {
        required: true,
      },
    }),
    resReservationType: initInput({
      name: 'resReservationType',
      type: INPUT_TYPE.SELECT,
      validation: {
        required: true,
      },
    }),
    resPlatform: initInput({
      name: 'resPlatform',
      type: INPUT_TYPE.SELECT,
    }),
    resDate: initInput({
      name: 'resDate',
      type: INPUT_TYPE.DATE,
    }),
    resTimeFrom: initInput({
      name: 'resTimeFrom',
      type: INPUT_TYPE.DATE,
    }),
    resTimeTo: initInput({
      name: 'resTimeTo',
      type: INPUT_TYPE.DATE,
    }),
    note: initInput({
      name: 'note',
      type: INPUT_TYPE.TEXT,
    }),
  },
  values: {
    resNo: '',
    resTransportationType: null,
    resReservationType: null,
    resPlatform: null,
    resDate: null,
    resTimeFrom: null,
    resTimeTo: null,
    note: '',
  },
};


const initMoreSegmentsForm = {
  ...initForm,
  form: {
    autoStartOnCombinedTransportation: initInput({
      name: 'autoStartOnCombinedTransportation',
      type: INPUT_TYPE.CHECKBOX,
    }),
  },
  values: {
    autoStartOnCombinedTransportation: false,
  },
};


/**
 * Transportation - Company
 */
const initTransportationCompanyForm = {
  ...initForm,
  form: {
    compId: initInput({
      name: 'compId',
      type: INPUT_TYPE.TEXT,
    }),
    compName: initInput({
      name: 'compName',
      validation: {
        min: 2,
        max: 255,
      },
    }),
    compIdentificationNumber: initInput({
      name: 'compIdentificationNumber',
    }),
    compVatNumber: initInput({
      name: 'compVatNumber',
    }),
    compStreet: initInput({
      name: 'compStreet',
    }),
    compCity: initInput({
      name: 'compCity',
    }),
    compZip: initInput({
      name: 'compZip',
    }),
    compState: initInput({
      name: 'compState',
    }),
    compContactPerson: initInput({
      name: 'compContactPerson',
    }),
    compEmail: initInput({
      name: 'compEmail',
    }),
    compPhoneNumber: initInput({
      name: 'compPhoneNumber',
    }),
  },
  values: {
    compId: null,
    compName: '',
    compIdentificationNumber: '',
    compVatNumber: '',
    compStreet: '',
    compCity: '',
    compZip: '',
    compState: '',
    compContactPerson: '',
    compEmail: '',
    compPhoneNumber: '',
  },
};


/**
 * Transportation - Driver
 */
const initTransportationDriverForm = {
  ...initForm,
  form: {
    driverName: initInput({
      name: 'driverName',
    }),
    driverPhoneNumber: initInput({
      name: 'driverPhoneNumber',
      validation: {
        regex: regexPhone,
      },
    }),
    driverNationality: initInput({
      name: 'driverNationality',
    }),
    languageId: initInput({
      name: 'languageId',
      type: INPUT_TYPE.SELECT,
      validation: {
        required: true,
      },
    }),
  },
  values: {
    driverName: '',
    driverPhoneNumber: '',
    driverNationality: '',
    languageId: null,
  },
};


/**
 * Transportation - Passenger
 */
const initTransportationPassengerForm = {
  ...initForm,
  form: {
    passengerName: initInput({
      name: 'passengerName',
    }),
    passengerPhoneNumber: initInput({
      name: 'passengerPhoneNumber',
      validation: {
        regex: regexPhone,
      },
    }),
    passengerNationality: initInput({
      name: 'passengerNationality',
    }),
  },
  values: {
    passengerName: '',
    passengerPhoneNumber: '',
    passengerNationality: '',
  },
};


/**
 * Transportation - Truck
 */
const initTransportationTruckForm = {
  ...initForm,
  form: {
    truckRegistrationNumber: initInput({
      name: 'truckRegistrationNumber',
      validation: {
        max: 20,
      },
    }),
  },
  values: {
    truckRegistrationNumber: '',
  },
};


const initTrailerTruckForm = {
  ...initForm,
  form: {
    trailerRegistrationNumber: initInput({
      name: 'trailerRegistrationNumber',
      validation: {
        max: 20,
      },
    }),
  },
  values: {
    trailerRegistrationNumber: '',
  },
};

const initExtraForm = {
  ...initForm,
  form: {
    isAdr: initInput({
      name: 'isAdr',
      type: INPUT_TYPE.CHECKBOX,
    }),
    isMultipleOrder: initInput({
      name: 'isMultipleOrder',
      type: INPUT_TYPE.CHECKBOX,
    }),
    multipleOrderExpiration: initInput({
      name: 'multipleOrderExpiration',
      type: INPUT_TYPE.DATE,
    }),
  },
  values: {
    isAdr: false,
    isMultipleOrder: false,
    multipleOrderExpiration: null,
  },
};

const attachmentForm = ATTACHMENTS.reduce((accumulator, attachment) => {
  const form = {
    ...accumulator,
    [`${attachment}Checkbox`]: initInput({
      name: `${attachment}Checkbox`,
      type: INPUT_TYPE.CHECKBOX,
    }),
    [`${attachment}File`]: initInput({
      name: `${attachment}File`,
    }),
  };
  if (ADR_ATTACHMENTS.indexOf(attachment) !== -1) {
    return {
      ...form,
      [`${attachment}No`]: initInput({
        name: `${attachment}No`,
        type: INPUT_TYPE.TEXT,
      }),
      [`${attachment}Date`]: initInput({
        name: `${attachment}Date`,
      }),
    };
  }
  return form;
}, {});

const attachmentFormValues = ATTACHMENTS.reduce((accumulator, attachment) => {
  const values = {
    ...accumulator,
    [`${attachment}Checkbox`]: false,
    [`${attachment}File`]: null,
  };
  if (ADR_ATTACHMENTS.indexOf(attachment) !== -1) {
    return {
      ...values,
      [`${attachment}No`]: '',
      [`${attachment}Date`]: null,
    };
  }
  return values;
}, {});

const initDocumentForm = {
  ...initForm,
  form: attachmentForm,
  values: attachmentFormValues,
};

export const initProductForm = {
  ...initForm,
  form: {
    name: initInput({
      name: 'name',
      type: INPUT_TYPE.TEXT,
    }),
    destination: initInput({
      name: 'destination',
      type: INPUT_TYPE.TEXT,
    }),
    quantity: initInput({
      name: 'quantity',
      type: INPUT_TYPE.NUMBER,
    }),
    unit: initInput({
      name: 'unit',
      type: INPUT_TYPE.TEXT,
    }),
    package: initInput({
      name: 'package',
      type: INPUT_TYPE.TEXT,
    }),
    adrClass: initInput({
      name: 'adrClass',
      type: INPUT_TYPE.TEXT,
    }),
    un: initInput({
      name: 'un',
      type: INPUT_TYPE.TEXT,
    }),
    weightIn: initInput({
      name: 'weightIn',
      type: INPUT_TYPE.TEXT,
    }),
    weightInUnit: initInput({
      name: 'weightInUnit',
      type: INPUT_TYPE.TEXT,
    }),
    weightOut: initInput({
      name: 'weightOut',
      type: INPUT_TYPE.TEXT,
    }),
    weightOutUnit: initInput({
      name: 'weightOutUnit',
      type: INPUT_TYPE.TEXT,
    }),
    transportationTypeId: initInput({
      name: 'transportationTypeId',
      type: INPUT_TYPE.TEXT,
    }),
  },
  values: {
    name: '',
    destination: '',
    quantity: 0,
    unit: '',
    package: '',
    adrClass: '',
    un: '',
    weightIn: 0,
    weightInUnit: '',
    weightOut: 0,
    weightOutUnit: '',
    transportationTypeId: '',
  },
};

export const initCreateTransportationForm = {
  ...initForm,
  form: {
    ...initMoreSegmentsForm.form,
    ...initTransportationCompanyForm.form,
    ...initTransportationDriverForm.form,
    ...initTransportationPassengerForm.form,
    ...initTransportationTruckForm.form,
    ...initTrailerTruckForm.form,
    ...initExtraForm.form,
    ...initDocumentForm.form,
  },
  values: {
    ...initTransportationCompanyForm.values,
    ...initMoreSegmentsForm.values,
    ...initTransportationDriverForm.values,
    ...initTransportationPassengerForm.values,
    ...initTransportationTruckForm.values,
    ...initTrailerTruckForm.values,
    ...initExtraForm.values,
    ...initDocumentForm.values,
  },
};


export const initTransportationForm = {
  ...initForm,
  form: {
    ...initTransportationReservationForm.form,
    ...initTransportationCompanyForm.form,
    ...initTransportationDriverForm.form,
    ...initTransportationPassengerForm.form,
    ...initTransportationTruckForm.form,
    ...initTrailerTruckForm.form,
    ...initExtraForm.form,
    ...initDocumentForm.form,
  },
  values: {
    ...initTransportationReservationForm.values,
    ...initTransportationCompanyForm.values,
    ...initTransportationDriverForm.values,
    ...initTransportationPassengerForm.values,
    ...initTransportationTruckForm.values,
    ...initTrailerTruckForm.values,
    ...initExtraForm.values,
    ...initDocumentForm.values,
  },
};

const validateMultipleOrder = (values) => {
  if (!values.isMultipleOrder && (!values.compId || !values.compId.id)) {
    return {
      code: 'CUSTOM_ERROR_NO_COMPANY',
      params: {},
    };
  }

  return null;
};

export const customValidationReservationFormForEdit = (form, values) => {
  const { resTimeFrom, resTimeTo, } = values;

  if (resTimeFrom && resTimeTo) {
    if (resTimeFrom >= resTimeTo) {
      return {
        code: 'CUSTOM_ERROR_FROM_GREATER_THAN_TO',
        params: {},
      };
    }
  }

  return validateMultipleOrder(values);
};

export const customValidationReservationForm = (form, values) => {
  const { resTimeFrom, resTimeTo, } = values;

  if (resTimeFrom && resTimeTo) {
    if (resTimeFrom >= resTimeTo) {
      return {
        code: 'CUSTOM_ERROR_FROM_GREATER_THAN_TO',
        params: {},
      };
    }
  }

  return null;
};

export const customValidationDetailForm = (form, values) => {
  for (let i = 0; i < ATTACHMENTS.length; i++) {
    const attachment = ATTACHMENTS[i];
    const checkboxValue = values[`${attachment}Checkbox`];
    const fileValue = values[`${attachment}File`];
    if (checkboxValue && !fileValue && !attachment.startsWith('adr')) {
      return {
        code: 'CUSTOM_ERROR_MISSING_FILE',
        params: {
          attachment,
        },
      };
    }
  }

  return validateMultipleOrder(values);
};

export const initTechnicalCheckBasicForm = {
  ...initForm,
  form: {
    transportationNo: initInput({
      name: 'transportationNo',
      type: INPUT_TYPE.TEXT,
    }),
    transportationTypeName: initInput({
      name: 'transportationTypeName',
      type: INPUT_TYPE.TEXT,
    }),
    transportationDate: initInput({
      name: 'transportationDate',
      type: INPUT_TYPE.TEXT,
    }),
    transportationTime: initInput({
      name: 'transportationTime',
      type: INPUT_TYPE.TEXT,
    }),
    transportCompany: initInput({
      name: 'transportCompany',
      type: INPUT_TYPE.TEXT,
    }),
    transportCompanyAddress: initInput({
      name: 'transportCompanyAddress',
      type: INPUT_TYPE.TEXT,
    }),
    driverPhoneNumber: initInput({
      name: 'driverPhoneNumber',
      type: INPUT_TYPE.TEXT,
    }),
    chamberFillingInCount: initInput({
      name: 'chamberFillingInCount',
      type: INPUT_TYPE.TEXT,
    }),
    chamberFillingOutCount: initInput({
      name: 'chamberFillingOutCount',
      type: INPUT_TYPE.TEXT,
    }),
  },
  values: {
    transportationNo: '',
    transportationTypeName: '',
    transportationDate: '',
    transportCompany: '',
    transportCompanyAddress: '',
    driverPhoneNumber: '',
    chamberFillingInCount: 0,
    chamberFillingOutCount: 0,
  },
};

export const initTechnicalCheckDriverForm = {
  ...initForm,
  form: {
    driverName: initInput({
      name: 'driverName',
      type: INPUT_TYPE.TEXT,
    }),
    driverNationality: initInput({
      name: 'driverNationality',
      type: INPUT_TYPE.TEXT,
    }),
    driverIdentityDocumentNumber: initInput({
      name: 'driverIdentityDocumentNumber',
      type: INPUT_TYPE.TEXT,
    }),
    adrDriverNo: initInput({
      name: 'adrDriverNo',
      type: INPUT_TYPE.TEXT,
    }),
    adrDriverDate: initInput({
      name: 'adrDriverDate',
      type: INPUT_TYPE.DATE,
    }),
    passengerName: initInput({
      name: 'passengerName',
      type: INPUT_TYPE.TEXT,
    }),
    passengerNationality: initInput({
      name: 'passengerNationality',
      type: INPUT_TYPE.TEXT,
    }),
    passengerIdentityDocumentNumber: initInput({
      name: 'passengerIdentityDocumentNumber',
      type: INPUT_TYPE.TEXT,
    }),
    adrPassengerNo: initInput({
      name: 'adrPassengerNo',
      type: INPUT_TYPE.TEXT,
    }),
    adrPassengerDate: initInput({
      name: 'adrPassengerDate',
      type: INPUT_TYPE.DATE,
    }),
  },
  values: {
    driverName: '',
    driverNationality: '',
    driverIdentityDocumentNumber: '',
    adrDriverNo: '',
    adrDriverDate: '',
    passengerName: '',
    passengerNationality: '',
    passengerIdentityDocumentNumber: '',
    adrPassengerNo: '',
    adrPassengerDate: '',
  },
};

export const initTechnicalCheckTruckAndTrailerForm = {
  ...initForm,
  form: {
    carType: initInput({
      name: 'carType',
      type: INPUT_TYPE.SELECT,
    }),
    truckRegistrationNumber: initInput({
      name: 'truckRegistrationNumber',
      type: INPUT_TYPE.TEXT,
    }),
    carRegistrationCountry: initInput({
      name: 'carRegistrationCountry',
      type: INPUT_TYPE.TEXT,
    }),
    trailerRegistrationNumber: initInput({
      name: 'trailerRegistrationNumber',
      type: INPUT_TYPE.TEXT,
    }),
    carTrailerRegistrationCountry: initInput({
      name: 'carTrailerRegistrationCountry',
      type: INPUT_TYPE.DATE,
    }),
    carConstruction: initInput({
      name: 'carConstruction',
      type: INPUT_TYPE.SELECT,
    }),
    tankerCode: initInput({
      name: 'tankerCode',
      type: INPUT_TYPE.TEXT,
    }),
    tankerCheckExpiration: initInput({
      name: 'tankerCheckExpiration',
      type: INPUT_TYPE.DATE,
    }),
    adrTruckNo: initInput({
      name: 'adrTruckNo',
      type: INPUT_TYPE.TEXT,
    }),
    adrTruckDate: initInput({
      name: 'adrTruckDate',
      type: INPUT_TYPE.DATE,
    }),
    adrTrailerNo: initInput({
      name: 'adrTrailerNo',
      type: INPUT_TYPE.TEXT,
    }),
    adrTrailerDate: initInput({
      name: 'adrTrailerDate',
      type: INPUT_TYPE.DATE,
    }),
  },
  values: {
    carType: '',
    truckRegistrationNumber: '',
    carRegistrationCountry: '',
    trailerRegistrationNumber: '',
    carTrailerRegistrationCountry: '',
    carConstruction: '',
    tankerCode: '',
    tankerCheckExpiration: '',
    adrTruckNo: '',
    adrTruckDate: '',
    adrTrailerNo: '',
    adrTrailerDate: '',
  },
};

export const initTechnicalCheckCisternForm = {
  ...initForm,
  form: {
    chamberCount: initInput({
      name: 'chamberCount',
      type: INPUT_TYPE.SELECT,
    }),
    chamberFillingCount: initInput({
      name: 'chamberFillingCount',
      type: INPUT_TYPE.TEXT,
    }),
  },
  values: {
    chamberCount: 0,
    chamberFillingCount: 0,
  },
};

export const initTechnicalCheckChamberForm = {
  ...initForm,
  form: {
    volume: initInput({
      name: 'volume',
      type: INPUT_TYPE.TEXT,
    }),
    breakwaterCount: initInput({
      name: 'breakwaterCount',
      type: INPUT_TYPE.TEXT,
    }),
  },
  values: {
    volume: '',
    breakwaterCount: '',
  },
};

export const initTechnicalCheckChamberFillingForm = {
  ...initForm,
  form: {
    product: initInput({
      name: 'product',
      type: INPUT_TYPE.TEXT,
    }),
    cleared: initInput({
      name: 'cleared',
      type: INPUT_TYPE.CHECKBOX,
    }),
  },
  values: {
    product: '',
    cleared: false,
  },
};

export const initTechnicalCheckEntranceForm = {
  ...initForm,
  form: {
    otherSenderAtEntrance: initInput({
      name: 'otherSenderAtEntrance',
      type: INPUT_TYPE.SELECT,
    }),
    goodsLoaded: initInput({
      name: 'goodsLoaded',
      type: INPUT_TYPE.SELECT,
    }),
    goodsLoadedUn: initInput({
      name: 'goodsLoadedUn',
      type: INPUT_TYPE.TEXT,
    }),
  },
  values: {
    otherSenderAtEntrance: null,
    goodsLoaded: null,
    goodsLoadedUn: null,
  },
};

export const initTechnicalCheckResultForm = {
  ...initForm,
  form: {
    technicalCheckResult: initInput({
      name: 'technicalCheckResult',
      type: INPUT_TYPE.RADIO,
    }),
    companyEligibility: initInput({
      name: 'companyEligibility',
      type: INPUT_TYPE.RADIO,
    }),
    valveCheck: initInput({
      name: 'valveCheck',
      type: INPUT_TYPE.RADIO,
    }),
    outputTechnicalCheck: initInput({
      name: 'outputTechnicalCheck',
      type: INPUT_TYPE.RADIO,
    }),
    technicalCheckNote: initInput({
      name: 'technicalCheckNote',
      type: INPUT_TYPE.TEXT,
    }),
    pin: initInput({
      name: 'pin',
      type: INPUT_TYPE.TEXT,
    }),
  },
  values: {
    technicalCheckResult: false,
    companyEligibility: false,
    valveCheck: false,
    outputTechnicalCheck: false,
    technicalCheckNote: '',
    pin: '',
  },
};

export const initTechnicalCheckPlombForm = {
  ...initForm,
  form: {
    hasPlombs: initInput({
      name: 'hasPlombs',
      type: INPUT_TYPE.RADIO,
    }),
    plombCount: initInput({
      name: 'plombCount',
      type: INPUT_TYPE.TEXT,
    }),
    plombs: initInput({
      name: 'plombs',
      type: INPUT_TYPE.TEXT,
    }),
  },
  values: {
    hasPlombs: true,
    plombCount: 0,
    plombs: [],
  },
};

export const initTechnicalCheckDangerousGoodsForm = {
  ...initForm,
  form: Object.fromEntries(new Map(dangerousGoodsFields.map((field) => [ field, initInput({
    name: field,
    type: INPUT_TYPE.CHECKBOX,
  }), ]))),
  values: Object.fromEntries(new Map(dangerousGoodsFields.map((field) => [ field, false, ]))),
};

export const initTechnicalCheckEquipmentForm = {
  ...initForm,
  form: Object.fromEntries(new Map(equipmentFields.map((field) => [ field, initInput({
    name: field,
    type: INPUT_TYPE.CHECKBOX,
  }), ]))),
  values: Object.fromEntries(new Map(equipmentFields.map((field) => [ field, false, ]))),
};

export const initTechnicalCheckVehicleMarkingsForm = {
  ...initForm,
  form: Object.fromEntries(new Map(vehicleMarkingsFields.map((field) => [ field, initInput({
    name: field,
    type: INPUT_TYPE.CHECKBOX,
  }), ]))),
  values: Object.fromEntries(new Map(vehicleMarkingsFields.map((field) => [ field, false, ]))),
};

export const initTechnicalCheckTechnicalDocumentsForm = {
  ...initForm,
  form: Object.fromEntries(new Map(technicalDocumentsfields.map((field) => [ field, initInput({
    name: field,
    type: INPUT_TYPE.CHECKBOX,
  }), ]))),
  values: Object.fromEntries(new Map(technicalDocumentsfields.map((field) => [ field, false, ]))),
};

export const initTechnicalCheckLoadProductForm = {
  ...initForm,
  form: {
    lowerLimitQuantity: initInput({
      name: 'lowerLimitQuantity',
      type: INPUT_TYPE.TEXT,
    }),
    recalculationOfTotalLimitQuantity: initInput({
      name: 'recalculationOfTotalLimitQuantity',
      type: INPUT_TYPE.TEXT,
    }),
    transportationIn450LPackage: initInput({
      name: 'transportationIn450LPackage',
      type: INPUT_TYPE.SELECT,
    }),
  },
  values: {
    id: '',
    lowerLimitQuantity: '',
    recalculationOfTotalLimitQuantity: '',
  },
};

export const initTechnicalCheckFillingProductForm = {
  ...initForm,
  form: {
    product: initInput({
      name: 'product',
      type: INPUT_TYPE.SELECT,
    }),
    chamberSelect: initInput({
      name: 'chamberSelect',
      type: INPUT_TYPE.SELECT,
    }),
    quantity: initInput({
      name: 'quantity',
      type: INPUT_TYPE.TEXT,
    }),
  },
  values: {
    product: '',
    chamberSelect: '',
    quantity: '',
  },
};

export const initInputTechnicalCheckForm = {
  ...initForm,
  form: {
    ...initTechnicalCheckBasicForm.form,
    ...initTechnicalCheckDriverForm.form,
    ...initTechnicalCheckTruckAndTrailerForm.form,
    ...initTechnicalCheckCisternForm.form,
    ...initTechnicalCheckEntranceForm.form,
    ...initTechnicalCheckDangerousGoodsForm.form,
    ...initTechnicalCheckEquipmentForm.form,
    ...initTechnicalCheckResultForm.form,
  },
  values: {
    ...initTechnicalCheckBasicForm.values,
    ...initTechnicalCheckDriverForm.values,
    ...initTechnicalCheckTruckAndTrailerForm.values,
    ...initTechnicalCheckCisternForm.values,
    ...initTechnicalCheckEntranceForm.values,
    ...initTechnicalCheckDangerousGoodsForm.values,
    ...initTechnicalCheckEquipmentForm.values,
    ...initTechnicalCheckResultForm.values,
  },
};

export const initOutputTechnicalCheckForm = {
  ...initForm,
  form: {
    ...initTechnicalCheckBasicForm.form,
    ...initTechnicalCheckDriverForm.form,
    ...initTechnicalCheckTruckAndTrailerForm.form,
    ...initTechnicalCheckPlombForm.form,
    ...initTechnicalCheckVehicleMarkingsForm.form,
    ...initTechnicalCheckTechnicalDocumentsForm.form,
    ...initTechnicalCheckResultForm.form,
  },
  values: {
    ...initTechnicalCheckBasicForm.values,
    ...initTechnicalCheckDriverForm.values,
    ...initTechnicalCheckTruckAndTrailerForm.values,
    ...initTechnicalCheckPlombForm.values,
    ...initTechnicalCheckVehicleMarkingsForm.values,
    ...initTechnicalCheckTechnicalDocumentsForm.values,
    ...initTechnicalCheckResultForm.values,
  },
};
