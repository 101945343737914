import React from 'react';
import { func, object, string, } from 'prop-types';

import { TRANSLATIONS_TRANSPORTATION, } from '../../gql/translations';
import { withTranslations, } from '../../../../logic/translations/withTranslations';
import { withUser, } from '../../../../logic/auth/withUser';
import { pipe, } from '../../../../logic/utils';
import Modal from '../../../../atoms/Modal/Modal';
import TransportationCreateLogic from './TransportationCreateLogic';


const TransportationCreate = ({
  // data
  translations,
  languageId,
  resources,
  // methods
  onToggle,
  onCreated,
  // custom
  extraCreateButton,
  onCreatedExtraCreateBtn,
}) => (
  <Modal
    title={translations.transportation.createModalTransportationTitle}
    isOpen
    size="XL"
    onClose={onToggle}
    disablePadding
  >
    <TransportationCreateLogic
      // data
      languageId={languageId}
      translations={translations}
      resources={resources}
      // methods
      onToggle={onToggle}
      onCreated={onCreated}
      // custom
      extraCreateButton={extraCreateButton}
      onCreatedExtraCreateBtn={onCreatedExtraCreateBtn}
    />
  </Modal>
);


TransportationCreate.propTypes = {
  extraCreateButton: object,
  translations: object.isRequired,
  languageId: string.isRequired,
  resources: object.isRequired,
  onToggle: func.isRequired,
  onCreated: func,
  onCreatedExtraCreateBtn: func,
};

TransportationCreate.defaultProps = {
  extraCreateButton: undefined,
  onCreated: undefined,
  onCreatedExtraCreateBtn: undefined,
};


export default pipe(
  withTranslations(TRANSLATIONS_TRANSPORTATION),
  withUser,
)(TransportationCreate);
