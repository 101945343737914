import styled from 'styled-components';

const StyledWizardTab = styled.div`
  margin-bottom: -1px;
  cursor: pointer;
  padding: 0 0.3rem 2px 0.3rem;
  font-weight: 500;
  color: ${(p) => p.theme.grey.t400};
  font-size: 1rem;
  
  &:hover {
    color: ${(p) => p.theme.grey.t700};
  }

  ${(p) => p.disabled && `
    color: ${p.theme.grey.t200};
    cursor: not-allowed;
  `}

  ${(p) => p.isActive && `
    border-bottom: 2px solid ${p.theme.tertiary.t700};
    color: ${p.theme.tertiary.t700};
    &:hover {
      color: ${p.theme.tertiary.t700};
    }
  `}

  .wizard--span-big {
    margin-right: 0.25rem;
    font-size: 1.25rem;
    font-weight: bold;
  }
`;


export default StyledWizardTab;
