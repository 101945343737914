import styled from 'styled-components';

const backgroundColor = (p) => p.theme.grey.t400;
const topColor = (p) => p.theme.grey.t50;
const downColor = (p) => p.theme.success.t600;

export const StyledRatioChart = styled.div`
  .ratio-chart--legend {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    
    margin-bottom: 1rem;
    
    .ratio-chart--legend-color {
      height: 1.5rem;
      width: 0.5rem;
      margin-right: 0.5rem;
      
      &.top {
        background: ${topColor};
      }
      
      &.down {
        background: ${downColor};
      }
    }
    
    .ratio-chart--legend-text {
      margin-right: 1.5rem;
      color: ${backgroundColor};
    }
  }
  
  .ratio-chart--chart {
    display: flex;
    flex-direction: row;
    
    height: 5rem;
    width: 100%;
    
    margin-bottom: 1rem;
  }
  
  .ratio-chart-column--down {
    background: ${downColor};
    
    &:hover~.ratio-chart-column--tooltip {
      visibility: visible;
    }
  }
  
  .ratio-chart-column--top {
    background: ${topColor};
    
    &:hover~.ratio-chart-column--tooltip {
      visibility: visible;
    }
  }
   
  .ratio-chart-column--tooltip {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    margin-top: -0.5rem;
    text-align: center;
    visibility: hidden;
    z-index: 1;
    
    &:hover {
      visibility: visible;
    }
  }
`;
