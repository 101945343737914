import styled from 'styled-components';


const StyledModalInterrupt = styled.div`
  .storekeeperModalInterrupt--btn {
    width: 50%;
  }
`;


export default StyledModalInterrupt;
