import { Component, } from 'react';
import { func, } from 'prop-types';

class SubscribeComponent extends Component {
    static propTypes = {
      subscribe: func.isRequired,
    }

    constructor(props) {
      super(props);

      this.subscribe = null;
    }


    componentDidMount() {
      const { subscribe, } = this.props;

      this.subscribe = subscribe();
    }


    componentWillUnmount() {
      if (this.subscribe !== null) {
        this.subscribe();
        this.subscribe = null;
      }
    }


    render() {
      return null;
    }
}

export default SubscribeComponent;
