// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
import React from 'react';

import ReportUserGroupsList from './ReportUserGroupsList';
import ReportNotificationGroupDetail from './ReportNotificationGroupDetail';


const ReportNotificationGroupsSettings = (props) => {
  return (
    <div className="report-user-groups-settings">
      <ReportUserGroupsList
        reportUserGroups={props.reportNotificationGroups}
        selectedId={props.selectedReportNotificationGroup ? props.selectedReportNotificationGroup.id : 0}
        onSelect={props.onSelect}
        onCreate={props.onCreate}
        onDelete={props.onDelete}
        title="Seznam notifikací"
      />
      {props.selectedReportNotificationGroup && (
        <ReportNotificationGroupDetail
          reportNotificationGroup={props.selectedReportNotificationGroup}
          users={props.users}
          transportationTypes={props.transportationTypes}
          reports={props.reports}
          onUpdate={props.onUpdate}
        />
      )}
    </div>
  );
};

export default ReportNotificationGroupsSettings;
