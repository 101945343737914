import { removeFromArray, } from '../../../../logic/array';
import {
  initTechnicalCheckChamberFillingForm,
  initTechnicalCheckChamberForm, initTechnicalCheckFillingProductForm, initTechnicalCheckLoadProductForm,
} from '../forms/structure';

export const getTranslationFromOptions = (value, options) => {
  const option = options.find((o) => o.id === value);
  if (option) {
    return option.name;
  }
  return '';
};

export const getGoodsLoadedOptions = (translations) => [
  { id: 'dangerous', name: translations.form.goodsLoadedDangerous, },
  { id: 'safe', name: translations.form.goodsLoadedSafe, },
  { id: 'combined', name: translations.form.goodsLoadedBoth, },
];

export const getCarTypeOptions = (translations) => [
  { id: 'tarping', name: translations.form.carTypeTarping, },
  { id: 'cabinet', name: translations.form.carTypeCabinet, },
  { id: 'refrigerator', name: translations.form.carTypeRefrigerator, },
  { id: 'containerTrailer', name: translations.form.carTypeContainerTrailer, },
  { id: 'cistern', name: translations.form.carTypeCistern, },
  { id: 'silo', name: translations.form.carTypeSilo, },
  { id: 'van', name: translations.form.carTypeVan, },
  { id: 'passengerCar', name: translations.form.carTypePassengerCar, },
  { id: 'other', name: translations.form.carTypeOther, },
];

export const getYesNoOptions = (translations) => [
  { id: false, name: translations.common.no, }, { id: true, name: translations.common.yes, },
];

export const dangerousGoodsFields = [
  'isAdr',
  'lowerLimitQuantity',
  'combinedTransport',
  'technicalConditionsOfContainer',
  'warningOrangeADRTable',
  'warningOrangeIMOTable',
  'safetyPictograms',
];

export const equipmentFields = [
  'fireExtinguisher2KG',
  'gloves',
  'fireExtinguisher6KG',
  'appropriateShoes',
  'wedges',
  'apron',
  'standingWarningDevices',
  'shovel',
  'warningVest',
  'faceguard',
  'handFlashlight',
  'collectionContainer',
  'eyewash',
  'firstAidKit',
  'sealingGlasses',
  'writtenInstructions',
  'stopperSewerage',
];

export const technicalDocumentsfields = [ 'soe', 'customs', 'delivery', 'cmr', 'atests', 'imo', 'weight', 'proformDelivery', 'security', ];

export const vehicleMarkingsFields = [
  'orangeTables',
  'chamber0',
  'chamber1',
  'chamber2',
  'chamber3',
  'safetySignsContainer',
  'unNumberContainer',
];

export const carTypeContainsOneOf = (carType, items) => {
  if (carType === null) {
    return false;
  }

  return items.indexOf(carType.id) !== -1;
};

export const parseVariablesForUpdateMutation = (
  originalData,
  detailForm,
  chamberForms,
  chamberFillingForms,
  loadProductForms,
  chamberFillingInForms,
  chamberFillingOutForms,
  newTechnicalCheckPictures,
) => ({
  isAdr: detailForm.values.isAdr,

  driverPhoneNumber: detailForm.values.driverPhoneNumber,
  driverName: detailForm.values.driverName,
  driverNationality: detailForm.values.driverNationality,
  driverIdentityDocumentNumber: detailForm.values.driverIdentityDocumentNumber,
  adrDriverNo: detailForm.values.adrDriverNo,
  adrDriverDate: detailForm.values.adrDriverDate,
  passengerName: detailForm.values.passengerName,
  passengerNationality: detailForm.values.passengerNationality,
  passengerIdentityDocumentNumber: detailForm.values.passengerIdentityDocumentNumber,
  adrPassengerNo: detailForm.values.adrPassengerNo,
  adrPassengerDate: detailForm.values.adrPassengerDate,

  carType: detailForm.values.carType !== null ? detailForm.values.carType.id : null,
  truckRegistrationNumber: detailForm.values.truckRegistrationNumber,
  carRegistrationCountry: detailForm.values.carRegistrationCountry,
  trailerRegistrationNumber: detailForm.values.trailerRegistrationNumber,
  carTrailerRegistrationCountry: detailForm.values.carTrailerRegistrationCountry,
  carConstruction: detailForm.values.carConstruction !== null ? detailForm.values.carConstruction.id : null,
  tankerCode: detailForm.values.tankerCode,
  tankerCheckExpiration: detailForm.values.tankerCheckExpiration,
  adrTruckNo: detailForm.values.adrTruckNo,
  adrTruckDate: detailForm.values.adrTruckDate,
  adrTrailerNo: detailForm.values.adrTrailerNo,
  adrTrailerDate: detailForm.values.adrTrailerDate,
  chamberCount: detailForm.values.chamberCount !== null ? detailForm.values.chamberCount.id : null,
  chambers: (Array.isArray(chamberForms) ? chamberForms : [])
    .map((cf) => ({ volume: parseFloat(cf.values.volume) || 0, breakwaterCount: parseInt(cf.values.breakwaterCount, 10) || 0, })),
  chamberFillingCount: detailForm.values.chamberFillingCount !== null ? detailForm.values.chamberFillingCount.id : null,
  chamberFilling: (Array.isArray(chamberFillingForms) ? chamberFillingForms : [])
    .map((cff) => ({ product: cff.values.product, cleared: cff.values.cleared, })),

  chamberFillingIn: (Array.isArray(chamberFillingInForms) ? chamberFillingInForms : [])
    .filter((fpf) => fpf.values.product && fpf.values.chamberSelect)
    .map((fpf) => ({ productId: parseInt(fpf.values.product.id, 10), chamber: parseInt(fpf.values.chamberSelect.id, 10), quantity: parseFloat(fpf.values.quantity) || 0, })),

  chamberFillingOut: (Array.isArray(chamberFillingOutForms) ? chamberFillingOutForms : [])
    .filter((fpf) => fpf.values.product && fpf.values.chamberSelect)
    .map((fpf) => ({ productId: parseInt(fpf.values.product.id, 10), chamber: parseInt(fpf.values.chamberSelect.id, 10), quantity: parseFloat(fpf.values.quantity) || 0, })),

  otherSenderAtEntrance: detailForm.values.otherSenderAtEntrance !== null ? detailForm.values.otherSenderAtEntrance.id : null,
  goodsLoaded: detailForm.values.goodsLoaded !== null ? detailForm.values.goodsLoaded.id : null,
  goodsLoadedUn: detailForm.values.goodsLoadedUn,

  products: (Array.isArray(originalData.products) ? originalData.products : []).map((product, index) => ({
    id: product.id,
    lowerLimitQuantity: parseFloat(loadProductForms[index].values.lowerLimitQuantity) || null,
    recalculationOfTotalLimitQuantity: parseFloat(loadProductForms[index].values.recalculationOfTotalLimitQuantity) || null,
    transportationIn450LPackage: loadProductForms[index].values.transportationIn450LPackage !== null
      ? loadProductForms[index].values.transportationIn450LPackage.id
      : null,
  })),

  equipment: Object.fromEntries(new Map(equipmentFields.map((field) => [ field, detailForm.values[field], ]))),
  dangerousGoods: Object.fromEntries(new Map(removeFromArray(dangerousGoodsFields, [ 'isAdr', ]).map((field) => [ field, detailForm.values[field], ]))),
  technicalDocuments: Object.fromEntries(new Map(technicalDocumentsfields.map((field) => [ field, detailForm.values[field], ]))),
  vehicleMarkings: Object.fromEntries(new Map(vehicleMarkingsFields.map((field) => [ field, detailForm.values[field], ]))),

  hasPlombs: detailForm.values.hasPlombs !== null ? detailForm.values.hasPlombs.id : null,
  plombCount: parseInt(detailForm.values.plombCount, 10) || 0,
  plombs: Array.isArray(detailForm.values.plombs) && parseInt(detailForm.values.plombCount, 10) > 0 ? detailForm.values.plombs.filter((p) => !!p) : [],

  transportCompany: detailForm.values.transportCompany,
  transportCompanyAddress: detailForm.values.transportCompanyAddress,

  companyEligibility: detailForm.values.companyEligibility,
  valveCheck: detailForm.values.valveCheck,
  outputTechnicalCheck: detailForm.values.outputTechnicalCheck,
  technicalCheckResult: detailForm.values.technicalCheckResult,
  technicalCheckNote: detailForm.values.technicalCheckNote,

  technicalCheckPictureContents: newTechnicalCheckPictures.map((pictureInBase64) => pictureInBase64.substring(pictureInBase64.indexOf('base64') + 7)),
});

export const getInitialState = (data, form, translations) => {
  const chamberFillingIn = (Array.isArray(data.chamberFillingIn) ? data.chamberFillingIn : []).length;
  const chamberFillingOut = (Array.isArray(data.chamberFillingOut) ? data.chamberFillingOut : []).length;
  return ({
    detailForm: {
      ...form,
      values: {
        ...form.values,
        ...data,
        ...(data.equipment !== null ? data.equipment : {}),
        ...(data.dangerousGoods !== null ? data.dangerousGoods : {}),
        ...(data.technicalDocuments !== null ? data.technicalDocuments : {}),
        ...(data.vehicleMarkings !== null ? data.vehicleMarkings : {}),
        transportationDate: new Date(data.transportationDate),
        adrDriverDate: data.adrDriverDate ? new Date(data.adrDriverDate) : null,
        adrPassengerDate: data.adrPassengerDate ? new Date(data.adrPassengerDate) : null,
        adrTruckDate: data.adrTruckDate ? new Date(data.adrTruckDate) : null,
        adrTrailerDate: data.adrTrailerDate ? new Date(data.adrTrailerDate) : null,
        tankerCheckExpiration: data.tankerCheckExpiration ? new Date(data.tankerCheckExpiration) : null,
        carType: data.carType ? {
          id: data.carType,
          name: getTranslationFromOptions(data.carType, getCarTypeOptions(translations)),
        } : null,
        carConstruction: data.carConstruction ? { id: data.carConstruction, name: data.carConstruction, } : { id: null, name: translations.form.carConstructionNone, },
        chamberCount: data.chamberCount ? { id: data.chamberCount, name: data.chamberCount, } : null,
        chamberFillingCount: data.chamberFillingCount ? {
          id: data.chamberFillingCount,
          name: data.chamberFillingCount,
        } : null,
        otherSenderAtEntrance: data.otherSenderAtEntrance !== null ? {
          id: data.otherSenderAtEntrance,
          name: getTranslationFromOptions(data.otherSenderAtEntrance, getYesNoOptions(translations)),
        } : null,
        goodsLoaded: data.goodsLoaded ? {
          id: data.goodsLoaded,
          name: getTranslationFromOptions(data.goodsLoaded, getGoodsLoadedOptions(translations)),
        } : null,
        hasPlombs: data.hasPlombs !== null
          ? { id: data.hasPlombs, name: getTranslationFromOptions(data.hasPlombs, getYesNoOptions(translations)), }
          : { id: true, name: getTranslationFromOptions(true, getYesNoOptions(translations)), },
        plombCount: data.plombCount !== null ? data.plombCount : 0,
        technicalCheckPictures: Array.isArray(data.technicalCheckPictures) ? data.technicalCheckPictures : [],
        chamberFillingInCount: { id: chamberFillingIn, name: chamberFillingIn, },
        chamberFillingOutCount: { id: chamberFillingOut, name: chamberFillingOut, },
      },
    },
    chamberForms: getChamberForms(data.chambers),
    chamberFillingForms: getChamberFillingForms(data.chamberFilling),
    loadProductForms: getLoadProductForms(data.products, translations),
    chamberFillingInForms: getFillingProductFormsForInit(data.chamberFillingIn, data.products),
    chamberFillingOutForms: getFillingProductFormsForInit(data.chamberFillingOut, data.products),
    newTechnicalCheckPictures: [],
  });
};

export const getChamberForms = (chambers) => (Array.isArray(chambers) ? chambers : []).map((chamber) => ({
  ...initTechnicalCheckChamberForm,
  values: {
    ...initTechnicalCheckChamberForm.values,
    ...chamber,
  },
}));

export const getChamberFillingForms = (chamberFillings) => (Array.isArray(chamberFillings) ? chamberFillings : []).map((chamberFilling) => ({
  ...initTechnicalCheckChamberFillingForm,
  values: {
    ...initTechnicalCheckChamberFillingForm.values,
    ...chamberFilling,
  },
}));

export const getLoadProductForms = (products, translations) => (Array.isArray(products) ? products : []).map((product) => ({
  ...initTechnicalCheckLoadProductForm,
  values: {
    ...initTechnicalCheckLoadProductForm.values,
    ...product,
    transportationIn450LPackage: product.transportationIn450LPackage !== null
      ? { id: product.transportationIn450LPackage, name: getTranslationFromOptions(product.transportationIn450LPackage, getYesNoOptions(translations)), }
      : null,
  },
}));

export const getFillingProductForms = (x) => (Array.isArray(x) ? x : []).map((x) => ({
  ...initTechnicalCheckFillingProductForm,
  values: {
    ...initTechnicalCheckFillingProductForm.values,
    ...x,
  },
}));

export const getFillingProductFormsForInit = (filling, products) => {
  if (!Array.isArray(filling)) {
    return [];
  }

  return filling.map((row) => {
    let productValue = null;
    if (row.productId) {
      const product = products.find((p) => parseInt(p.id, 10) === row.productId);
      if (product) {
        productValue = { id: row.productId, name: product.name, };
      }
    }
    return {
      ...initTechnicalCheckFillingProductForm,
      values: {
        ...initTechnicalCheckFillingProductForm.values,
        product: productValue,
        chamberSelect: row.chamber ? { id: row.chamber, name: row.chamber + 1, } : { id: 0, name: 1, },
        quantity: row.quantity,
      },
    };
  });
};
