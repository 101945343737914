import gql from 'graphql-tag';


/**
 * Auth
 */
export const MUTATION_LOGIN = gql`
  mutation Login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      accessToken
      refreshToken
      expires
    }
  }
`;
