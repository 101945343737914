export const getColorBtn = ({ color, theme, }) => {
  const colors = {
    grey: `
      color: ${theme.white};
      background: ${theme.grey.t700};
      border-color: ${theme.grey.t700};
      &:focus:not([disabled]) {
      }
      &:hover:not([disabled]) , &:active:not([disabled]) {
        background: ${theme.grey.t800};
      }
    `,
    primary: `
      color: ${theme.white};
      background: ${theme.primary.t700};
      border-color: ${theme.primary.t700};
      &:focus:not([disabled]) {
      }
      &:hover:not([disabled]) , &:active:not([disabled]) {
        background: ${theme.primary.t800};
      }
    `,
    secondary: `
      color: ${theme.white};
      background: ${theme.secondary.t700};
      border-color: ${theme.secondary.t700};
      &:focus:not([disabled]) {
      }
      &:hover:not([disabled]) , &:active:not([disabled]) {
        background: ${theme.secondary.t800};
      }
    `,
    tertiary: `
      color: ${theme.white};
      background: ${theme.tertiary.t700};
      border-color: ${theme.tertiary.t700};
      &:focus:not([disabled]) {
      }
      &:hover:not([disabled]) , &:active:not([disabled]) {
        background: ${theme.tertiary.t800};
      }
    `,
    error: `
      color: ${theme.white};
      background: ${theme.error.t700};
      border-color: ${theme.error.t700};
      &:focus:not([disabled]) {
      }
      &:hover:not([disabled]) , &:active:not([disabled]) {
        background: ${theme.error.t800};
      }
    `,
    warning: `
      color: ${theme.white};
      background: ${theme.warning.t700};
      border-color: ${theme.warning.t700};
      &:focus:not([disabled]) {
      }
      &:hover:not([disabled]) , &:active:not([disabled]) {
        background: ${theme.warning.t800};
      }
    `,
    success: `
      color: ${theme.white};
      background: ${theme.success.t700};
      border-color: ${theme.success.t700};
      &:focus:not([disabled]) {
      }
      &:hover:not([disabled]) , &:active:not([disabled]) {
        background: ${theme.success.t800};
      }
    `,
  };

  if (Object.prototype.hasOwnProperty.call(colors, color)) {
    return colors[color];
  }
  return colors.grey;
};


export const getColorBtnOutline = ({ color, theme, }) => {
  const colors = {
    grey: `
      color: ${theme.grey.t700};
      background: transparent;
      border-color: ${theme.grey.t700};
      &:focus:not([disabled]) {
      }
      &:hover:not([disabled]) , &:active:not([disabled]) {
        background: ${theme.grey.t800};
        color: ${theme.white};
      }
    `,
    primary: `
      color: ${theme.primary.t700};
      background: transparent;
      border-color: ${theme.primary.t700};
      &:focus:not([disabled]) {
      }
      &:hover:not([disabled]) , &:active:not([disabled]) {
        background: ${theme.primary.t800};
        color: ${theme.white};
      }
    `,
    secondary: `
      color:  ${theme.secondary.t700};
      background: transparent;
      border-color: ${theme.secondary.t700};
      &:focus:not([disabled]) {
      }
      &:hover:not([disabled]) , &:active:not([disabled]) {
        background: ${theme.secondary.t800};
        color: ${theme.white};
      }
    `,
    tertiary: `
      color: ${theme.tertiary.t700};
      background: transparent;
      border-color: ${theme.tertiary.t700};
      &:focus:not([disabled]) {
      }
      &:hover:not([disabled]) , &:active:not([disabled]) {
        background: ${theme.tertiary.t800};
        color: ${theme.white};
      }
    `,
    error: `
      color: ${theme.error.t700};
      background: transparent;
      border-color: ${theme.error.t700};
      &:focus:not([disabled]) {
      }
      &:hover:not([disabled]) , &:active:not([disabled]) {
        background: ${theme.error.t800};
        color: ${theme.white};
      }
    `,
    warning: `
      color: ${theme.warning.t700};
      background: transparent;
      border-color: ${theme.warning.t700};
      &:focus:not([disabled]) {
      }
      &:hover:not([disabled]) , &:active:not([disabled]) {
        background: ${theme.warning.t800};
        color: ${theme.white};
      }
    `,
    success: `
      color: ${theme.success.t700};
      background: transparent;
      border-color: ${theme.success.t700};
      &:focus:not([disabled]) {
      }
      &:hover:not([disabled]) , &:active:not([disabled]) {
        background: ${theme.success.t800};
        color: ${theme.white};
      }
    `,
  };

  if (Object.prototype.hasOwnProperty.call(colors, color)) {
    return colors[color];
  }
  return colors.grey;
};


export const getColorBtnTransparent = ({ color, theme, }) => {
  const colors = {
    grey: `
      color: ${theme.grey.t900};
      background: transparent;
      &:focus:not([disabled]) {
        box-shadow: none;
        color: ${theme.grey.t300};
      }
      &:hover:not([disabled]) {
        color: ${theme.grey.t300};
      }
    `,
    primary: `
      color: ${theme.primary.t700};
      background: transparent;
      &:focus:not([disabled]) {
        box-shadow: none;
        color: ${theme.primary.t300};
      }
      &:hover:not([disabled]) {
        color: ${theme.primary.t300};
      }
    `,
    secondary: `
      color: ${theme.secondary.t700};
      background: transparent;
      &:focus:not([disabled]) {
        box-shadow: none;
        color: ${theme.secondary.t300};
      }
      &:hover:not([disabled]) {
        color: ${theme.secondary.t300};
      }
    `,
    tertiary: `
      color: ${theme.tertiary.t700};
      background: transparent;
      &:focus:not([disabled]) {
        box-shadow: none;
        color: ${theme.tertiary.t300};
      }
      &:hover:not([disabled]) {
        color: ${theme.tertiary.t300};
      }
    `,
    error: `
      color: ${theme.error.t700};
      background: transparent;
      &:focus:not([disabled]) {
        box-shadow: none;
        color: ${theme.error.t300};
      }
      &:hover:not([disabled]) {
        color: ${theme.error.t300};
      }
    `,
    warning: `
      color: ${theme.warning.t700};
      background: transparent;
      &:focus:not([disabled]) {
        box-shadow: none;
        color: ${theme.warning.t300};
      }
      &:hover:not([disabled]) {
        color: ${theme.warning.t300};
      }
    `,
    success: `
      color: ${theme.success.t700};
      background: transparent;
      &:focus:not([disabled]) {
        box-shadow: none;
        color: ${theme.success.t300};
      }
      &:hover:not([disabled]) {
        color: ${theme.success.t300};
      }
    `,
  };

  if (Object.prototype.hasOwnProperty.call(colors, color)) {
    return colors[color];
  }
  return colors.grey;
};
