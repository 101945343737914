// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
import React from 'react';
import { arrayOf, bool, object, } from 'prop-types';

import ReportComponent from './ReportComponent';
import Row from '../../../../atoms/Row/Row';


const ReportRow = (props) => {
  const { config, data, root, } = props;
  return (
    <Row className={`report-row ${root ? 'root-row' : ''}`}>
      {config.content.map((c, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <ReportComponent key={i} config={c} data={data} />
      ))}
    </Row>
  );
};


ReportRow.propTypes = {
  config: object.isRequired,
  data: arrayOf(object.isRequired).isRequired,
  root: bool,
};

ReportRow.defaultProps = {
  root: false,
};


export default ReportRow;
