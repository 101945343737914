import React from 'react';
import { object, shape, string, } from 'prop-types';
import { Route, Switch, } from 'react-router-dom';

import SettingsPage from './UserSettingsPage';
import PrivateRoute from '../../../components/PrivateRoute/PrivateRoute';
import NotFoundPage from '../../Main/pages/NotFoundPage';
import SmsAdministrationPage from './SmsAdministrationPage';
import SmsSettingsPage from './SmsSettingsPage';


const SettingsLayout = ({ match, resources, }) => (
  <Switch>
    <PrivateRoute resource={resources.settings} path={`${match.path}/users`} component={SettingsPage} />
    <PrivateRoute resource={resources.settings} path={`${match.path}/sms`} component={SmsAdministrationPage} />
    <PrivateRoute resource={resources.sms_settings_module} path={`${match.path}/sms-settings`} component={SmsSettingsPage} />
    <Route component={NotFoundPage} />
  </Switch>
);

SettingsLayout.propTypes = {
  match: shape({
    path: string.isRequired,
  }).isRequired,
  resources: object,
};

SettingsLayout.defaultProps = {
  resources: {},
};


export default SettingsLayout;
