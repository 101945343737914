import React from 'react';
import {
  node, oneOfType, arrayOf, string,
} from 'prop-types';
import { Link, } from 'react-router-dom';


const BreadcrumbItem = ({
  to,
  children,
}) => (to
  ? (
    <li>
      <Link to={to}>
        {children}
      </Link>
    </li>
  ) : (
    <li>
      {children}
    </li>
  )
);


BreadcrumbItem.propTypes = {
  to: string,
  children: oneOfType([
    arrayOf(node),
    node,
  ]).isRequired,
};

BreadcrumbItem.defaultProps = {
  to: undefined,
};


export default BreadcrumbItem;
