import { ApolloClient, } from 'apollo-client';
import { InMemoryCache, } from 'apollo-cache-inmemory';
import { WebSocketLink, } from 'apollo-link-ws';
import { SubscriptionClient, } from 'subscriptions-transport-ws';

import { QUERY_SCHEDULE_TIMELINE_FILTER, } from '../../modules/Transportation/gql/queries';
import { GET_STOREKEEPER_QUEUE_DATA, } from '../../modules/Storekeeper/gql/queries';
import { initData, typeDefs, resolvers, } from './initData';
import { clearTokens, setTokens, getAccessToken, } from '../localStorage/auth';
import { APP_VERSION, } from '../../globals';


const subscriptionClient = new SubscriptionClient(
  process.env.REACT_APP_WS_LINK,
  {
    reconnect: true,
    timeout: 60000,
    reconnectionAttempts: Infinity,
    connectionParams: () => ({
      authToken: getAccessToken(),
      appVersion: APP_VERSION,
    }),
  }
);


subscriptionClient.maxConnectTimeGenerator.duration = () => subscriptionClient.maxConnectTimeGenerator.max; // eslint-disable-line


const wsLink = new WebSocketLink(
  subscriptionClient,
);


const cache = new InMemoryCache({
  addTypename: false,
});
cache.writeData({ data: initData, });


export const loginSetTokenResetConnection = (newAccessToken) => {
  setTokens({ accessToken: newAccessToken, });
  wsLink.subscriptionClient.close(false, false);
  wsLink.subscriptionClient.connect();
};


const reInitStore = () => {
  cache.writeQuery({
    query: QUERY_SCHEDULE_TIMELINE_FILTER,
    data: { scheduleFilter: initData.scheduleFilter, },
  });
  cache.writeQuery({
    query: GET_STOREKEEPER_QUEUE_DATA,
    data: { storekeeperQueue: initData.storekeeperQueue, },
  });
};


export const logoutClearTokenResetConnection = () => {
  clearTokens();
  client.resetStore() // eslint-disable-line
    .then(() => {
      wsLink.subscriptionClient.close(false, false);
      wsLink.subscriptionClient.connect();
      reInitStore();
    })
    .catch(() => {
      wsLink.subscriptionClient.close(false, false);
      wsLink.subscriptionClient.connect();
      reInitStore();
    });
};


/**
 * Apollo GraphQL client
 */
export const client = new ApolloClient({
  link: wsLink,
  cache,
  resolvers,
  typeDefs,
});
