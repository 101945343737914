import React from 'react';
import { object, shape, string, } from 'prop-types';
import { Route, Switch, } from 'react-router-dom';

import PrivateRoute from '../../../components/PrivateRoute/PrivateRoute';
import NotFoundPage from '../../Main/pages/NotFoundPage';
import ReportingAclPage from './ReportingAclPage';
import ReportingNotificationPage from './ReportingNotificationPage';
import ReportingChartsPage from './ReportingChartsPage';


const ReportingLayout = ({ match, resources, }) => (
  <Switch>
    <PrivateRoute resource={resources.reporting} path={`${match.path}/charts`} component={ReportingChartsPage} />
    <PrivateRoute resource={resources.reporting} path={`${match.path}/acl`} component={ReportingAclPage} />
    <PrivateRoute resource={resources.reporting} path={`${match.path}/notifications`} component={ReportingNotificationPage} />
    <Route component={NotFoundPage} />
  </Switch>
);

ReportingLayout.propTypes = {
  match: shape({
    path: string.isRequired,
  }).isRequired,
  resources: object,
};

ReportingLayout.defaultProps = {
  resources: {},
};


export default ReportingLayout;
