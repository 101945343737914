import React from 'react';
import {
  string, shape, bool, func,
} from 'prop-types';

import FormRow from '../../Form/FormRow';
import InputCheckbox from '../../../atoms/InputCheckbox/InputCheckbox';


const GFCheckbox = ({
  input, value, onChange,
}) => (
  <FormRow
    label=""
    input={input}
    info={input.translation.help}
  >
    <InputCheckbox
      value={value === true}
      text={input.translation.label}
      onChange={(newValue) => onChange(input.name, newValue)}
      status={input.status}
      disabled={input.disabled}
    />
  </FormRow>
);


GFCheckbox.propTypes = {
  input: shape({
    name: string.isRequired,
    type: string.isRequired,
    disabled: bool.isRequired,
    translation: shape({
      label: string,
      help: string,
    }).isRequired,
  }).isRequired,
  value: bool,
  onChange: func.isRequired,
};

GFCheckbox.defaultProps = {
  value: false,
};


export default GFCheckbox;
